import React from "react";
import {
  Typography,
  Box,
  useMediaQuery,
  useTheme,
  Button,
  Grid,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Pagination, PaginationItem } from "@material-ui/lab";
import DownloadIcon from "@material-ui/icons/GetApp";
const configJSON = require("../../blocks/catalogue/src/config");

interface IProps {
  page: number | any;
  count: number | any;
  classes: any;
  arrayLength: number | any;
  per_page: number;
  handlePageChange: (e: any, p: any) => void;
  isTopPagination?: boolean;
  handleDownloadCatalog?: () => void;
}
const CustomPagination = (props: IProps) => {
  const { page, count, classes, arrayLength, per_page, isTopPagination, handleDownloadCatalog } = props;
  console.log(count, 'count')
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  return (
    <CustomBox
      display="flex"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      className={classes.paginationResponsive}
    >
    {!isNaN(count) && (
      <>
      <Grid container alignItems="center">
        {!isTopPagination && <Typography
          className={classes.paginationText}
          style={webStyle.paginationText}
        >
          {configJSON.ShowingText} {per_page * (page - 1) + 1} -{" "}
          {per_page * page > arrayLength ? arrayLength : per_page * page} of{" "}
          {arrayLength} {configJSON.resultsText}
        </Typography>}

        {isTopPagination && handleDownloadCatalog &&
          <Button
            style={webStyle.cardButton}
            onClick={() => handleDownloadCatalog()}
            variant="contained"
            className="knowMoreButton"
          >
            {" "}
            <Typography style={webStyle.downloadIconBox}>
              {" "}
              <DownloadIcon style={webStyle.downloadIcon} /> &nbsp; <span style={webStyle.productCatalogue}> Product Catalogue</span>
            </Typography>
          </Button>}
      </Grid>
      <Grid container style={webStyle.paginationContainer} data-testid='paginationcontainer'>
        <Pagination
          renderItem={(params) => {
            if (isMobile) {
              if (params.type === "page" && params.selected) {
                return <PaginationItem {...params} />;
              }
              if (params.type === "next" || params.type === "previous") {
                return <PaginationItem {...params} />;
              }
            } else {
              return <PaginationItem {...params} />;
            }
          }}
          data-testid='page'
          count={count}
          siblingCount={0}
          page={page}
          variant="outlined"
          shape="round"
          color="primary"
          onChange={(e, p) => props.handlePageChange(e, p)}
          classes={{
            ul: classes.paginationList,
          }}
        />
      </Grid>
      </>
    )}
    </CustomBox>
  );
};

const webStyle = {
  downloadIconBox: {
    font: "caption",
    display: "flex", 
    alignItems: "center"
  },
  productCatalogue: {
    textDecoration: "underline",
    color: "rgba(4, 60, 116, 1)",
    fontWeight: 700,
    fontSize: '14px',
    fontFamily: 'SFProDisplay'
  },
  downloadIcon: {
    backgroundColor: "rgba(4, 60, 116, 1)",
    color: "#fff",
    fontSize: "small",
    borderRadius: "6px",
    padding: "4.5px"
  },
  cardButton: {
    borderRadius: "8px",
    fontFamily: "SFProDisplay",
    fontWeight: 500,
    boxShadow: "none",
    backgroundColor: "#fff",
    padding: "0px"
  },
  paginationText: {
    marginLeft: "15px",
    fontSize: "14px",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    color: "#6a74a5",
    lineHeight: 1.25,
    letterSpacing: "normal",
  },
  paginationContainer: {
    display: "flex",
    alignContent: "center",
    justifyContent: "end",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
  },
};

const styles : any = () => ({
  paginationList: {
    justifyContent: "flex-end",
    "@media (max-width: 600px)": {
      '& button': {
        marginBottom: '0px !important',
      }
    },
  },
  paginationResponsive: {
    "@media (max-width: 440px)": {
      flexWrap: 'wrap',
      '& button': {
        marginBottom: '10px',
      }
    },
  },
  paginationText: {
    "@media (max-width: 600px)": {
      marginLeft: '1px !important',
    },
  },
});

const CustomBox : any = withStyles({
  root: {
  }
})(Box)

export default withStyles(styles)(CustomPagination);
