import React from "react";
import {
    Box,
    Grid,
    Button,
    TextField,
    InputLabel,
    IconButton,
    Typography,
    InputAdornment,
    FormControlLabel,
    Checkbox,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Container,
    Divider,
} from "@material-ui/core";
import {
    bioconLogo,
    backgroundImg,
    ConnectLogo,
} from "./assets";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector";
import CloseIcon from '@material-ui/icons/Close';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { withStyles } from "@material-ui/core/styles";
import { handlePrivacyPolicyRedirection, handleTermsOfUseRedirection } from "../../../components/src/common";
import SignupWebController, { Props } from "./SignupController.web";
import Loader from "../../../components/src/Loader.web";
import CompanyNameSelector from "../../../components/src/CompanyNameSelectorAutocomplete";

export class SignUpRegistrationZeplin2 extends SignupWebController {
    constructor(props: Props) {
        super(props);
    }

    TermsPopUp() {
        const { classes } = this.props
        return (
            <TermsDialog
                open={this.state.termsPopUp}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    <IconBox onClick={this.handleTermsConditionClick}>
                        <CloseIcon style={webStyle.closeIconStyle}></CloseIcon>
                    </IconBox>
                </DialogTitle>
                <DialogContent>
                    <Typography className={classes.termsPopUpText}>
                        {this.state.termsPopUpDetailsText}
                        <br />
                        <br />
                        {this.state.termsPopUpDetailsText2}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <AcceptButton onClick={this.setTermsandCondition}>
                        {this.state.AcceptButtonText}
                    </AcceptButton>
                </DialogActions>
            </TermsDialog>
        )
    }

    ProfileConfirmationPopup() {
        const { classes } = this.props
        return (
            <TermsDialog
                open={this.state.isConfirmation}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    <IconBox onClick={this.onConfirmationClick}>
                        <CloseIcon style={webStyle.closeIconStyle} ></CloseIcon>
                    </IconBox>
                </DialogTitle>
                <DialogContent>
                    <Typography className={classes.ConfirmationPopUpText}>
                        {this.state.thanksTextAfterSignup}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <AcceptButton onClick={this.onConfirmationClick}>
                        {this.state.OkButtonText}
                    </AcceptButton>
                </DialogActions>
            </TermsDialog>
        )
    }

    setCompanyDetails() {
        const { classes } = this.props;
        return (
            <>
                <CustomBox className="compnaySelect">
                    <Grid container className="signupCompanyName">
                        <Grid item sm={12} style={webStyle.companyGrid}>
                            <InputLabel htmlFor={this.state.companynameHTML} style={webStyle.inputLabelCompanyName} className={classes.companyNameLabelText}>
                                {this.state.labelCompanyName}<span style={webStyle.errorText}>*</span>
                            </InputLabel>
                            <CompanyNameSelector
                                data-testid="companyNameInput"
                                dataSource={this.state.companyList}
                                selectedValue={this.state.companyId}
                                onFocus={this.handleCompanySelect}
                                handleChange={this.handleCompanyChange}
                                style={{ ...webStyle.select, noOptionsMessage: (base: any, state: any) => ({ ...base, ...webStyle.companyErrorText }) }}
                                isDisabled={false}
                                placeHolder={this.state.selectCompanyPlaceholder}
                                isCustomFilter={true}
                            />
                        </Grid>
                    </Grid>
                </CustomBox>
                <Grid container >
                    <Grid item sm={12} style={webStyle.commonGridStyle}>
                        <InputLabel htmlFor={this.state.phoneNumFieldHTML} style={webStyle.inputLabel}>
                            {this.state.phoneNum}
                        </InputLabel>
                        <Grid container justifyContent="space-between" style={webStyle.phoneGrid} className={classes.phoneDesign}>
                            <CustomGrid item className={this.state.companyIsFocued ? "countryCodeIndex" : "countryCodeIndex2"}>
                                <CountryCodeSelector
                                    data-testid="CountryCodeSelector"
                                    navigation={""}
                                    style={{}}
                                    id={this.state.countryCodeFieldID}
                                    disable={false}
                                    allowPropChange={false}
                                    onFocus={this.handleCompanySelect}
                                    onChange={this.handlePhoneCountryCode}
                                    value={this.state.phoneCountryCode}
                                    placeHolder={this.state.countryCodePlaceholder}
                                />
                            </CustomGrid>
                            <Grid item lg={7} md={7} sm={12} xs={12}>
                                <PhoneInputField
                                    data-testid="phoneNumberInput"
                                    id={this.state.phoneNumberFieldId}
                                    variant="outlined"
                                    inputMode="numeric"
                                    inputProps={{
                                        maxLength: 14,
                                    }}
                                    style={webStyle.PhoneInputFieldStyle}
                                    type="tel"
                                    value={this.state.phone}
                                    onChange={this.handleChange(this.state.phoneFieldId).bind(this)}
                                    placeholder={this.state.phoneNumberPlaceholder}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container >
                    <Grid item lg={12} md={12} sm={12} style={{ ...webStyle.marginTopGrid, ...webStyle.commonGridStyle }}>
                        <InputLabel htmlFor={this.state.countryFieldId} style={webStyle.inputLabel} className={classes.countryLabelText}>
                            {this.state.labelCountry}<span style={webStyle.errorText}>*</span>
                        </InputLabel>
                        <CustomGrid2 item className={this.state.companyIsFocued ? "countryCodeIndex" : "countryCodeIndex2"}>
                            <CountryCodeSelector
                                navigation={""}
                                style={{}}
                                id={this.state.countryFieldId}
                                disable={false}
                                allowPropChange={false}
                                onFocus={this.handleCompanySelect}
                                onChange={this.handlePhoneCountryCode}
                                value={this.state.phoneCountryCode}
                                placeHolder={this.state.countryPlaceholder}
                                isOnlyCountryName={true}
                            />
                        </CustomGrid2>
                    </Grid>
                </Grid>
                <CustomBox display={"flex"} style={webStyle.maxWidthStyle}>
                    <CheckBoxLabel
                        data-testid="btnLegalTermsAndCondition"
                        control={
                            <Checkbox
                                color="default"
                                disableRipple
                                checked={this.state.isTermsCondition}
                                onChange={this.handleTermsConditionClick}
                                checkedIcon={<span style={webStyle.checkedIcon} />}
                                icon={<span style={webStyle.icon} />}
                                inputProps={{ "aria-label": "decorative checkbox" }}
                            />
                        }
                        label={""}
                    />
                    <Typography style={webStyle.termsText} component={"span"}>
                        {this.state.privacyPolicyLabel}
                        <Typography
                            data-testid="btnLegalPrivacyPolicy"
                            onClick={handlePrivacyPolicyRedirection}
                            component="span"
                            style={webStyle.privacyText}
                        >
                            {" "}
                            {this.state.privacyPolicyText}
                        </Typography>{" "}
                        {this.state.andText}
                        <Typography
                            onClick={handleTermsOfUseRedirection}
                            component="span"
                            style={webStyle.privacyText}
                        >
                            {" "}
                            {this.state.termslabelText}
                        </Typography>
                        <Typography component="span"> {this.state.termsLabel}</Typography>
                    </Typography>
                    {this.state.termsPopUp && (this.TermsPopUp())}
                    {this.state.isConfirmation && this.ProfileConfirmationPopup()}
                </CustomBox>
            </>
        );
    }

    render() {
        const { navigation } = this.props;
        console.log('this.state', this.state.email, this.state.password)
        return (
            <>
                <MainContainer container className="no_wrap_text">
                    <Grid item lg={6} md={6} sm={6} id="drop-area">
                        <CustomBox sx={webStyle.backImageSpace} className="leftImg">
                            <img
                                data-testid="Background"
                                style={webStyle.signupImage}
                                src={this.state.loginImage}
                                alt=""
                                className="left-side-imgages"
                            />
                        </CustomBox>
                    </Grid>
                    <Grid className="testGrid" item lg={6} md={6} sm={6}>
                        <RightBox item lg={6} md={6} sm={6} xs={12} style={this.state.IscompanyDetails ? webStyle.companyCenterBox : {}}>
                            <Container style={webStyle.noPadding}>
                                <RightGrid item lg={12} md={12} sm={12} xs={12}>
                                    {this.state.IscompanyDetails && (
                                        <CustomBox style={webStyle.backBtnBox} display="flex" justifyContent={"end"}>
                                            <Typography style={webStyle.backOption} onClick={this.handleBackClick}>
                                                {this.state.backBtnText}
                                            </Typography>
                                        </CustomBox>
                                    )}
                                    <Grid container style={webStyle.biconLogoBox}>
                                        <img
                                            src={bioconLogo}
                                            style={webStyle.bioconLogo}
                                            onClick={this.goToHome}
                                        />
                                        <Divider orientation="vertical" style={webStyle.divider} />
                                        <img
                                            src={ConnectLogo}
                                            style={webStyle.connectLogo}
                                            className="blendLogo"
                                        />
                                    </Grid>
                                    <Typography style={webStyle.Header}>
                                        {this.state.newUserSignOption}
                                    </Typography>
                                    <Typography style={webStyle.subHeader}>
                                        {this.state.signUpSubHeader}
                                    </Typography>
                                    <form onSubmit={(e) => this.onSignUpRequest(e)} style={webStyle.noMargin}>
                                        <>
                                            <Grid container justifyContent="space-between">
                                                <Grid item md={6} sm={12} style={webStyle.commonGridStyle}>
                                                    <InputLabel htmlFor={this.state.firstNameFieldID} style={webStyle.inputLabelFirstName}>
                                                        {this.state.labelFirstName}<span style={webStyle.errorText}>*</span>
                                                    </InputLabel>
                                                    <InputField
                                                        data-testid="txtInputFirstName"
                                                        variant="outlined"
                                                        type="text"
                                                        required
                                                        placeholder={this.state.firstNamePlaceholder}
                                                        value={this.state.firstName}
                                                        onChange={this.handleChange(this.state.firstNameFieldID).bind(this)}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item md={6} sm={12} style={webStyle.commonGridStyle}>
                                                    <InputLabel htmlFor="firstName" style={webStyle.inputLabel2}>
                                                        {this.state.labelLastName}<span style={webStyle.errorText}>*</span>
                                                    </InputLabel>
                                                    <InputField
                                                        data-testid="txtInputLastName"
                                                        variant="outlined"
                                                        type="text"
                                                        value={this.state.lastName}
                                                        onChange={this.handleChange(this.state.lastNameFieldLabel).bind(this)}
                                                        required
                                                        placeholder={this.state.lastnamePlaceholder}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container >
                                                <Grid item sm={12} style={webStyle.commonGridStyle}>
                                                    <InputLabel data-testid="labelEmail" htmlFor={this.state.emailFieldID} style={webStyle.inputLabel}>
                                                        {this.state.labelEmail}<span style={webStyle.errorText}>*</span>
                                                    </InputLabel>
                                                    <InputField
                                                        data-testid="txtInputEmail"
                                                        id={this.state.emailFieldID}
                                                        variant="outlined"
                                                        onChange={this.handleChange(this.state.emailFieldLabel).bind(this)}
                                                        value={this.state.email}
                                                        required
                                                        placeholder={this.state.emailIdPlaceholder}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container justifyContent="space-between">
                                                <Grid item sm={6} style={webStyle.commonGridStyle}>
                                                    <InputLabel htmlFor={this.state.createPassFieldText} style={webStyle.inputLabel}>
                                                        {this.state.labelPassword}<span style={webStyle.errorText}>*</span>
                                                    </InputLabel>
                                                    <InputField
                                                        data-testid="txtInputPassword"
                                                        id={this.state.createPassFieldText}
                                                        variant="outlined"
                                                        required
                                                        onChange={this.handleChange(this.state.passwordFieldLabel).bind(this)}
                                                        value={this.state.password}
                                                        placeholder={this.state.strongPassPlaceholder}
                                                        fullWidth
                                                        error={this.state.isPasswordError}
                                                        type={this.state.showPassword ? "text" : "password"}
                                                        InputProps={{
                                                            autoComplete: "new-password",
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        data-testid="btnPasswordShowHide"
                                                                        aria-label={this.state.togglePasswordlabel}
                                                                        onClick={this.handleClickShowPassword}
                                                                        edge="end"
                                                                    >
                                                                        {this.state.showPassword ? (
                                                                            <VisibilityOffOutlinedIcon />
                                                                        ) : (
                                                                            <VisibilityOutlinedIcon />
                                                                        )}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item sm={6} style={webStyle.commonGridStyle}>
                                                    <InputLabel htmlFor={this.state.confirmPassFieldId} style={webStyle.inputLabel}>
                                                        {this.state.confirmPass}<span style={webStyle.errorText}>*</span>
                                                    </InputLabel>
                                                    <InputField
                                                        data-testid="txtInputConfirmPassword"
                                                        id={this.state.confirmPassFieldId}
                                                        variant="outlined"
                                                        required
                                                        type={this.state.confirmShowPassword ? "text" : "password"}
                                                        placeholder={this.state.confirmPassPlaceholder}
                                                        value={this.state.retypePassword}
                                                        onChange={this.handleChange(this.state.retypePasswordFieldName).bind(this)}
                                                        fullWidth
                                                        error={this.state.isPasswordError}
                                                        InputProps={{
                                                            autoComplete: "new-password",
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label={this.state.togglePasswordlabel}
                                                                        onClick={this.handleConfirmShowPassword}
                                                                        edge="end"
                                                                    >
                                                                        {this.state.confirmShowPassword ? (
                                                                            <VisibilityOffOutlinedIcon />
                                                                        ) : (
                                                                            <VisibilityOutlinedIcon />
                                                                        )}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Typography
                                                data-testid="body2"
                                                variant="body2"
                                                style={this.state.isPasswordError ? webStyle.passwordTypoError : webStyle.passwordTypo}
                                                className="passwordTypo"
                                            >
                                                {this.state.passwordValidationtext}
                                            </Typography>
                                            {this.setCompanyDetails()}
                                        </>

                                        <Grid style={webStyle.maxWidthStyle} data-testid="btnSignUp">
                                            <Buttons type="submit" variant="contained" className='signupBtnRes'>{this.state.SignUpBtnText}</Buttons>
                                        </Grid>
                                    </form>
                                    <CustomBox sx={webStyle.haveAccount} className='accountLineRes'>
                                        <Typography style={webStyle.AccountText}>
                                            {this.state.AccountLabel}
                                        </Typography>
                                        <Typography style={webStyle.login}
                                            data-testid="loginText"
                                            onClick={() => {
                                                navigation.navigate("Login");
                                            }}
                                        >
                                            {this.state.loginText}
                                        </Typography>
                                    </CustomBox>
                                </RightGrid>
                            </Container>
                        </RightBox>
                    </Grid>
                    <Loader loading={this.state.imageLoading} data-testid="loader" />
                </MainContainer>
            </>
        );
    }
}

// Customizable Area Start
const webStyle = {
    biconLogoBox: {
        // maxWidth: '100%', 
        padding: "15px 0px",
        paddingBottom: '10px',
        alignItems: 'center'
    },
    backBtnBox: {
        marginTop: '0px',
        maxWidth: '100%'
    },
    noPadding: {
        padding: 0
    },
    maxWidthStyle: {
        maxWidth: '100%'
    },
    PhoneInputFieldStyle: {
        height: '100%',
        minWidth: '100%'
    },
    phoneGrid: {
        maxWidth: '100%',
        height: 38
    },
    marginTopGrid: {
        marginTop: '15px'
    },
    commonGridStyle: {
        maxWidth: "100%",
        width: '100%',
        padding: '0px 8px'
    },
    companyErrorText: {
        color: "red",
        backgroundColor: "white"
    },
    errorText: {
        color: "red"
    },
    companyGrid: {
        maxWidth: "100%",
        width: '100%',
        padding: '0px 8px',
        marginTop: '13px'
    },
    closeIconStyle: {
        fill: '#1A3B71',
        cursor: 'pointer'
    },
    noMargin: {
        margin: 0
    },
    backImageSpace: {
        padding: '0 1.9% 0 0',
        height: '100vh',
        overflow: 'hidden',
    },
    select: {
        height: "35px",
        marginTop: "5px",
        borderRadius: "8px",
        maxWidth: '100%',
    },
    selectPlaceholder: {
        fontFamily: "SFProDisplay",
        fontWeight: 400,
        fontSize: "14px",
        color: '#d6d6d6',
        fontStyle: 'normal',
    },
    signupImage: {
        width: '95.9%',
        height: '100%',
        objectPosition: 'center',
    },
    welcomeText: {
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '48px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#fff',
    },
    startedText: {
        margin: '1.3% 0px 6.7px 0px',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '24px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        color: '#fff',
    },
    serviceText: {
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '14px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        color: '#fff',
    },
    bioconLogo: {
        width: '28%',
        height: '100%',
        margin: '0px 0px 2% 0',
        cursor: 'pointer',
    },
    Header: {
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '18px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#043c74',
        paddingBottom: "5px"
    },
    subHeader: {
        margin: '0% 28px 5px 0',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '14px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#6a74a5',
    },
    inputLabel: {
        margin: '0 20px 5px 0',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '14px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#3a3f63',
        paddingTop: "14px",
    },
    inputLabel2: {
        margin: '0 20px 5px 0',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '14px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#3a3f63',
        paddingTop: "10px"
    },
    inputLabelFirstName: {
        margin: '0 20px 5px 0',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '14px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#3a3f63',
        paddingTop: "10px"
    },
    inputLabelCompanyName: {
        margin: "0px 61px 5px 0px",
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '14px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#3a3f63',
        paddingTop: "14px"
    },
    passwordTypo: {
        maxWidth: '100%',
        margin: '5px 2px 0% 0',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '12px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#6a74a5',
        padding: '0px 8px',
    },
    passwordTypoError: {
        maxWidth: '100%',
        margin: '5px 2px 0% 0',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '12px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#e74c3c',
        padding: '0px 8px',
    },
    haveAccount: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '100%',
        marginTop: "26px"
    },
    AccountText: {
        margin: '2% 0px 0 0px',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '14px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#3a3f63',
    },
    login: {
        margin: '2% 8px 0 8px',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '14px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '-0.35px',
        color: '#459eff',
        cursor: 'pointer',
    },
    backOption: {
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '18px',
        color: "rgba(4, 60, 116, 1)",
        cursor: "pointer",
        padding: '0px 0px 6.6% 0px',
    },
    checkedIcon: {
        borderRadius: '6px',
        border: 'solid 1px #3a3f63',
        backgroundColor: '#fbfcff',
        width: 20,
        height: 20,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='black'/%3E%3C/svg%3E\")",
        "input:hover ~ &": {},
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    icon: {
        borderRadius: '6px',
        border: 'solid 1px #3a3f63',
        backgroundColor: '#fbfcff',
        width: 20,
        height: 20,
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2,
        },
        "input:hover ~ &": {
            backgroundColor: "#fbfcff",
        },
        "&:hover": {
            backgroundColor: "#fbfcff",
        },
    },
    termsText: {
        color: "rgba(58, 63, 99, 1)",
        fontFamily: "SFProDisplay",
        fontWeight: 400,
        fontSize: "14px",
        margin: '15px 0 0px 6px',
    },
    privacyText: {
        color: "rgba(69, 158, 255, 1)",
        cursor: 'pointer',
    },
    companyCenterBox: {
        display: 'flex',
        alignItems: 'center',
    },
    divider: {
        height: '50px',
        width: '1px',
        backgroundColor: '#6a74a5',
        margin: '2px 5.9% 2.2%',
    },
    connectLogo: {
        width: '33.8%',
        height: '5.7%',
        margin: '0 0px 2% 0',
    }
}

const RightBox: any = withStyles({
    root: {
        "& .MuiTypography-body1": {
            fontSize: '14px',
            fontFamily: 'SFProDisplay',
        },
        backgroundImage: `url(${backgroundImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '98% 100%',
        margin: " 16px 0px 16px 43px",
        maxWidth: "80%",
        display: 'flex',
        alignItems: 'center',
        '@media (max-width: 1070px)': {
            maxWidth: "100%",
            paddingRight: '15px',
            margin: '0px',
        },
        '@media (max-width: 600px)': {
            paddingLeft: '15px',
        },
        "& .PrivateSwitchBase-root-14": {
            padding: '0px',
        },
        "& .compnaySelect .css-yk16xz-control": {
            maxWidth: '100%',
            borderRadius: '8px'
        },
        "& .compnaySelect .css-1pahdxg-control": {
            maxWidth: '100%',
        },
        "& .compnaySelect .css-26l3qy-menu": {
            maxWidth: '100%',
        }
    }
})(Grid);

const RightGrid: any = withStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        paddingRight: '0px',
    }
})(Grid);

const InputField: any = withStyles({
    root: {
        maxWidth: '100%',
        borderRadius: '8px',
        backgroundColor: '#fff',
        "& ::placeholder": {
            color: '#d6d6d6',
            fontSize: '14px',
            opacity: 1,
            fontFamily: 'SFProDisplay',
            fontWeight: 500,
        },
        "& .MuiInputBase-input": {
            height: '11px',
        },
        "& .MuiOutlinedInput-input": {
            padding: '12px'
        },
        "& .MuiOutlinedInput-root": {
            fontWeight: 500,
            fontFamily: "SFProDisplay",
            backgroundColor: "rgba(255, 255, 255, 1)",
            fontSize: "14px",
            borderRadius: "8px",
            height: '100%',
            "& fieldset": {
                border: 'solid 1px #d6d6d6',
                borderRadius: '8px',
            },
        },
    },
})(TextField);

const PhoneInputField: any = withStyles({
    root: {
        maxWidth: '100%',
        borderRadius: '8px',
        backgroundColor: '#fff',
        "& ::placeholder": {
            color: '#d6d6d6',
            fontSize: '14px',
            opacity: 1,
            fontFamily: 'SFProDisplay',
            fontWeight: 500,
        },
        "& .MuiInputBase-input": {
            height: '38px',
        },
        "& .MuiOutlinedInput-input": {
            padding: '0px 14px !important'
        },
        "& .MuiOutlinedInput-root": {
            fontWeight: 500,
            fontFamily: "SFProDisplay",
            backgroundColor: "rgba(255, 255, 255, 1)",
            fontSize: "14px",
            borderRadius: "8px",
            "& fieldset": {
                border: 'solid 1px #d6d6d6',
                borderRadius: '8px',
            },
        },
    },
})(TextField);

const Buttons: any = withStyles({
    root: {
        marginTop: '40px',
        maxWidth: '100%',
        height: '5.7%',
        margin: '0 0 0px',
        padding: '1.2% 0px 1.2% 0px',
        borderRadius: '8px',
        backgroundColor: '#459eff',
        fontFamily: 'DMSans-Bold',
        fontSize: '20px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#fff',
        textTransform: "none",
        minWidth: '100%',
        boxShadow: 'none',
        "&:hover": {
            backgroundColor: "rgba(69, 158, 255, 1)",
            boxShadow: "none",
        },
    },
})(Button);

export const CustomGrid: any = withStyles({
    root: {
        minWidth: '40%',
        marginRight: '9px',
        "@media (min-width: 960px) and (max-width: 1510px)": {
            minWidth: `39% !important`,
            maxWidth: `39% !important`,
        },
        "@media (min-width: 380px) and (max-width: 960px)": {
            minWidth: `100% !important`,
            maxWidth: `100% !important`,
            marginBottom: '8px'
        },
        "& .css-tlfecz-indicatorContainer": {
            color: '#000000',
            paddingRight: '12px',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
        },
        "& .css-1okebmr-indicatorSeparator": {
            display: 'none',
            fontFamily: 'SFProDisplay',
            fontWeight: 700,
        },
        "& .css-1wa3eu0-placeholder": {
            display: 'flex',
            color: `rgba(214, 214, 214, 1) !important`,
            fontFamily: 'SFProDisplay',
            fontSize: '14px',
            fontWeight: 500,
            opacity: 1,
        },
        "& .css-1uccc91-singleValue": {
            fontFamily: 'SFProDisplay',
            fontSize: '14px',
            fontWeight: 500,
        },
        "& .css-yk16xz-control": {
            borderRadius: "8px",
            borderColor: "rgba(214, 214, 214, 1)",
            fontWeight: 500,
            fontFamily: "SFProDisplay",
            fontSize: "14px",
            width: "100%",
            marginRight: '8px',
        },
        "& .css-g1d714-ValueContainer": {
            position: "revert",
        },
        "& .css-1pahdxg-control:hover": {
            borderColor: '#3F51B5',
            boxShadow: 'none',
            borderWidth: 2
        },
        "& .css-1pahdxg-control": {
            borderColor: '#3F51B5',
            boxShadow: 'none',
            borderWidth: 2
        },
        "& .css-1hb7zxy-IndicatorsContainer": {
            borderRadius: "8px",
            borderColor: "rgba(214, 214, 214, 1)",
            fontWeight: 500,
            fontFamily: "SFProDisplay",
            fontSize: "14px",
            with: "100%",
        },
        "& .css-26l3qy-menu": {
            fontFamily: "SFProDisplay",
            fontSize: "14px",
            fontWeight: 500,
        },
        "& .css-view-1dbjc4n": {
            margin: `0px !important`,
        }
    },
})(Grid);

export const CustomGrid2: any = withStyles({
    root: {
        minWidth: '100%',
        marginRight: '9px',
        "@media (min-width: 960px) and (max-width: 1510px)": {
            minWidth: `100% !important`,
            maxWidth: `100% !important`,
        },
        "@media (min-width: 380px) and (max-width: 960px)": {
            minWidth: `100% !important`,
            maxWidth: `100% !important`,
            marginBottom: '8px'
        },
        "& .css-tlfecz-indicatorContainer": {
            color: '#000000',
            paddingRight: '12px',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
        },
        "& .css-1okebmr-indicatorSeparator": {
            display: 'none',
            fontFamily: 'SFProDisplay',
            fontWeight: 700,
        },
        "& .css-1wa3eu0-placeholder": {
            display: 'flex',
            color: `rgba(214, 214, 214, 1) !important`,
            fontFamily: 'SFProDisplay',
            fontSize: '14px',
            fontWeight: 500,
            opacity: 1,
        },
        "& .css-1uccc91-singleValue": {
            fontFamily: 'SFProDisplay',
            fontSize: '14px',
            fontWeight: 500,
        },
        "& .css-yk16xz-control": {
            borderRadius: "8px",
            borderColor: "rgba(214, 214, 214, 1)",
            fontWeight: 500,
            fontFamily: "SFProDisplay",
            fontSize: "14px",
            width: "100%",
            marginRight: '8px',
        },
        "& .css-g1d714-ValueContainer": {
            position: "revert",
        },
        "& .css-1pahdxg-control:hover": {
            borderColor: '#3F51B5',
            boxShadow: 'none',
            borderWidth: 2
        },
        "& .css-1pahdxg-control": {
            borderColor: '#3F51B5',
            boxShadow: 'none',
            borderWidth: 2
        },
        "& .css-1hb7zxy-IndicatorsContainer": {
            borderRadius: "8px",
            borderColor: "rgba(214, 214, 214, 1)",
            fontWeight: 500,
            fontFamily: "SFProDisplay",
            fontSize: "14px",
            with: "100%",
        },
        "& .css-26l3qy-menu": {
            fontFamily: "SFProDisplay",
            fontSize: "14px",
            fontWeight: 500,
        },
        "& .css-view-1dbjc4n": {
            margin: `0px !important`,
        }
    },
})(Grid);

const CheckBoxLabel: any = withStyles({
    root: {
        alignItems: "center",
        margin: '5px 0px 0px 0px',
        "& .MuiTypography-body1": {
            fontFamily: "SFProDisplay",
            fontWeight: 400,
            fontSize: "14px",
            color: "rgba(58, 63, 99, 1)",
        },
    },
})(FormControlLabel);

const MainContainer: any = withStyles({
    root: {
        overflowX: 'inherit',
        "& .css-1pahdxg-control": {
            borderColor: `rgba(218, 224, 241, 1)!important`,
            boxShadow: 'none',
            borderRadius: '8px',
            height: '40px',
        },
        "@media (max-width: 600px)": {
            "& .signupImg": {
                width: `100% !important`,
                height: `95% !important`,
                display: 'none'
            },
            "& .leftImg": {
                padding: `0px !important`,
            },
        },
        "@media (max-height: 1030px)": {
            '& .signupBtnRes': {
                marginTop: '10px !important'
            },
            '& .accountLineRes': {
                marginTop: '0px !important'
            },
            "@media (max-width: 600px)": {
                height: '100%',
                overflowY: 'inherit',
            },
        },
    }
})(Grid);

const TermsDialog: any = withStyles({
    root: {
        "& .MuiDialog-paperScrollPaper": {
            padding: '0px 0px 0px 30px',
            borderRadius: '10px'
        },
        "& .MuiDialogActions-root": {
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0px',
            paddingBottom: '8px',
        },
        "& .WithStyles(ForwardRef(Button)-root-10": {
            "@media (max-width: 600px)": {
                width: 300
            }
        }
    }
})(Dialog);

const AcceptButton: any = withStyles({
    root: {
        width: "125px",
        color: "rgba(255, 255, 255, 1)",
        backgroundColor: "#1A3B71",
        opacity: 1,
        boxShadow: "none",
        height: "42px",
        borderRadius: "4px",
        fontFamily: "SFProDisplay",
        fontWeight: 700,
        fontSize: "18px",
        margin: "30px 30px 30px 0px",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#1A3B71",
            boxShadow: "none",
        },
    },
})(Button);

const IconBox: any = withStyles({
    root: {
        background: '#DAE0EC',
        height: '26px',
        width: '26px',
        borderRadius: '50%',
        float: 'right',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 0px 0px 0px',
        "& .MuiSvgIcon-root": {
            height: '20px',
            width: '20px'
        }
    }
})(Box)

const CustomBox: any = withStyles({
    root: {

    }
})(Box)

const styles = () => ({
    phoneDesign: {
        '& .countryCodeIndex2.MuiGrid-item': {
            "@media (max-width: 380px)": {
                width: '100%',
                marginRight: '0px',
                marginBottom: '10px'
            }
        }
    },
    companyNameLabelText: {
        paddingTop: '0px !important',
    },
    countryLabelText: {
        paddingTop: '0px !important',
        "@media (max-width: 600px)": {
            marginTop: "60px !important",
        },
        "@media (max-width: 960px)": {
            marginTop: "50px !important",
        }
    },
    termsPopUpText: {
        fontFamily: 'SFProDisplay',
        fontWeight: 500,
        fontSize: '14px',
        color: '#494A5F',
        padding: '10px 10px 10px 10px',
        "@media (max-width: 600px)": {
            padding: '10px 0px 10px 0px',
        }
    },
    ConfirmationPopUpText: {
        fontFamily: 'SFProDisplay',
        fontWeight: 500,
        fontSize: '16px',
        color: '#494A5F',
        padding: '10px 0px 10px 0px',
        marginLeft: '-20px',
        "@media (max-width: 600px)": {
            padding: '10px 0px 10px 0px',
            marginLeft: '-20px'
        }
    }
})

export default withStyles(styles)(SignUpRegistrationZeplin2)

// Customizable Area End