import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  TextField,
  Typography,
  Tab,
  Tabs,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
  OutlinedInput,
  Dialog,
  DialogContent,
  DialogActions,
  InputAdornment,
  Tooltip,
  ListItemText,
  DialogTitle,
    } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import 'date-fns';
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector";
import DateFnsUtils from '@date-io/date-fns';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { withStyles } from '@material-ui/core/styles';
import { CustomGrid, handlePrivacyPolicyRedirection, handleTermsOfUseRedirection } from "../../../components/src/common";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Upload from "../../catalogue/src/AttachmentView.web";
import CancelIcon from '@material-ui/icons/Cancel';
import * as Yup from "yup";
import EmployeeAppHeader from "../../../components/src/EmployeeAppHeader.web";
import { Formik } from "formik";
import { configJSON } from "./ConnectWithUsController";

const CustomTabPanel = (props: {
  [x: string]: any;
  children: any;
  value: any;
  index: any;
}) => {
  const { children, classes, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Grid className={classes.container}>{children}</Grid>}
    </div>
  );
}
// Customizable Area End

import CustomerProductQueryController, {
  Props,
} from "./CustomerProductQueryController";
import { AppHeader } from "../../../components/src/AppHeader.web";
import AppFooter from "../../../components/src/AppFooter.web";
import Loader from "../../../components/src/Loader.web";

class CustomerProductQuery extends CustomerProductQueryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area 

  setSelectOptions(selected: any) {
    return selected?.length ? Array.isArray(selected) ? selected.join(', ') :
      <Grid container>
        {
          selected?.map((value: any) => (
            <Typography
              key={value?.value}
            >{value?.value},
            </Typography>
          ))
        }
      </Grid>
      :
      <Typography style={webStyle.selectPlaceholder}>In case you require impurities/standards, please choose multiple options</Typography>
  }

  setDocSelectOptions(selected: any) {
    if (selected?.length === 0) {
      return this.showSelectedDocs(selected);
    } else {
      return Array.isArray(selected) ? selected?.map(e => e?.attributes?.name).join(', ') :
        this.showSelectedDocs(selected)
    }
  }

  showSelectedDocs = (selected: any) => {
    return (
      selected?.length !== 0 ?
        <Grid container>
          {
            selected?.map((value: any) => (
              <Typography key={value}> {value}, </Typography>
            ))
          }
        </Grid>
        :
        <Typography style={webStyle.selectPlaceholder}>Choose Document</Typography>
    )
  }

  setEndMarketSelectOptions(selected: any) {
    if (selected?.length === 0) {
      return this.showSelectedEndMarkets(selected);
    } else {
      return Array.isArray(selected) ? selected?.map(e => e.name).join(', ') :
        this.showSelectedEndMarkets(selected)
    }
  }

  showSelectedEndMarkets = (selected: any) => {
    return (
      selected?.length !== 0 ?
        <Grid container>
          {
            selected?.map((value: any) => (
              <Typography key={value}>{value.name},</Typography>
            ))
          }
        </Grid>
        :
        <Typography style={webStyle.selectPlaceholder}>Choose End Market</Typography>
    )
  }

  AttachmentPopUp() {
    return (
      <AttachmentDialog
        open={this.state.isAttachment}
        data-testId="detailsdocumentuploadicon"
        // onClose={this.handleIsCreatedClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Typography style={{ ...webStyle.dialogTitle, width: '100%' }} align="center">
            Attach available COA *
          </Typography>
          <IconBox onClick={() => { this.removeAPIFile() }}>
            <CloseIcon style={{ fill: '#1A3B71', cursor: 'hand' }}>
            </CloseIcon>
          </IconBox>
        </DialogTitle>
        <DialogContent>
          <Grid style={webStyle.attachmentBox}>
            <Upload
              setFileSizeError={this.setFileSizeError}
              setSelectedFile={this.handleDetailsDocumentUpload}
            // data-testId="detailsdocumentuploadicon"
            />
          </Grid>
        </DialogContent>
        <Grid container justifyContent="space-between">
          <Typography style={this.state.isUploadError ? webStyle.maxSizeErrorText : webStyle.maxSizeText}>Max. file size should be 10 Mb</Typography>
          {
            this.state.uploadedAPIFilesBase[0] !== undefined && (
              <Typography style={webStyle.AttachmentNameText}>{this.state.uploadedAPIFilesBase[0].file_name}</Typography>
            )
          }
        </Grid>
        <Grid container justifyContent="center">
          <Button
            variant="outlined"
            style={{ ...webStyle.cancelButton, textTransform: "none" }}
            onClick={() => { this.removeAPIFile() }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{ ...webStyle.submitButton, textTransform: "none" }}
            onClick={this.submitAPICOAFile}
            data-testId="submitapicoafilebtn"
          >
            Submit
          </Button>
        </Grid>
      </AttachmentDialog>
    )
  };

  SetUpMeeting() {
    const { classes } = this.props
    return (
      <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.saveMeetingRequest();
      }}>
        <Grid container spacing={7}>
          <Grid item lg={6} md={6} sm={6} xs={12} style={{
            paddingBottom: '5px',
            paddingTop: '15px',
            position: 'relative',
          }}>
            <InputLabel htmlFor="name" style={webStyle.inputLabel}>
              {this.state.firstNameLabel}<span style={{ color: "red" }}>*</span>
            </InputLabel>
            <InputField
              id="name"
              variant="outlined"
              required
              placeholder="Enter Your First Name"
              disabled={!this.state.isGuest}
              fullWidth
              value={this.state.meeting.firstName}
              data
              onChange={this.handleMeetingChange("firstName").bind(this)}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12} style={{
            paddingBottom: '5px',
            paddingTop: '15px',
            position: 'relative',
          }}>
            <InputLabel htmlFor="name" style={webStyle.inputLabel}>
              {this.state.lastNamelabel}<span style={{ color: "red" }}>*</span>
            </InputLabel>
            <InputField
              id="name"
              variant="outlined"
              required
              disabled={!this.state.isGuest}
              placeholder="Enter Your Last Name"
              fullWidth
              value={this.state.meeting.lastName}
              onChange={this.handleMeetingChange("lastName").bind(this)}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={{
            paddingBottom: '5px',
            paddingTop: '15px',
            position: 'relative',
          }}>
            <InputLabel htmlFor="company" style={webStyle.inputLabel}>
              {this.state.companyNameLabel}<span style={{ color: "red" }}>*</span>
            </InputLabel>
            <InputField
              id="company"
              variant="outlined"
              placeholder="Enter Company Name"
              disabled
              fullWidth
              // disabled
              required
              value={this.state.meeting.company}
              onChange={this.handleMeetingChange("company").bind(this)}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12} style={{
            paddingBottom: '5px',
            paddingTop: '15px',
            position: 'relative',
          }}>
            <InputLabel htmlFor="email" style={webStyle.inputLabel}>
              {this.state.EmailIdLabel}<span style={{ color: "red" }}>*</span>
            </InputLabel>
            <InputField
              id="email"
              variant="outlined"
              disabled
              required
              placeholder="Enter Email ID"
              fullWidth
              value={this.state.meeting.emailId}
              onChange={this.handleMeetingChange("emailId").bind(this)}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={12} style={{
            paddingBottom: '5px',
            paddingTop: '15px',
            position: 'relative',
          }}>
            <InputLabel htmlFor="phoneNo" style={webStyle.inputLabel}>
              {this.state.countrylabel}<span style={{ color: "red" }}>*</span>
            </InputLabel>
            <div style={{ display: "flex" }}>
              <CustomGrid item md={12} lg={12} sm={12} xs={12} className={this.state.isLoading === true ? 'countryCodeIndex' : 'countryCodeIndex2'} style={{ marginTop: '6px' }}>
                <CountryCodeSelector
                  navigation={""}
                  style={{}}
                  id="countryCode"
                  disable={false}
                  allowPropChange={false}
                  onChange={this.handlePhoneCountryCode}
                  value={this.state.meeting.phoneCountryCode}
                  placeHolder="Please choose country"
                />
                {((this.state.meeting.phoneCountryCode === "null" && this.state.phoneCountryCodeError) || (this.state.meeting.phoneCountryCode === null && this.state.phoneCountryCodeError)) && (
                  <span style={webStyle.allFieldError}>{this.state.phoneCountryCodeError}</span>
                )}
              </CustomGrid>
            </div>
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={12} style={{
            paddingBottom: '5px',
            paddingTop: '15px',
            position: 'relative',
          }}>
            <InputLabel htmlFor="phoneNum" style={webStyle.inputLabel}>
              {this.state.phoneNumLabel}<span style={{ color: "red" }}>*</span>
            </InputLabel>
            <InputField
              id="phoneNum"
              // required
              variant="outlined"
              placeholder="Enter Phone No"
              fullWidth
              // disabled
              value={this.state.meeting.phoneNumber}
              onChange={this.handleMeetingChange("phoneNumber").bind(this)}
            />
            {(this.state.meeting.phoneNumber?.length === 0 && this.state.phoneError) && <span style={webStyle.allFieldError}>Phone number is required</span>}
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12} style={{
            paddingBottom: '5px',
            paddingTop: '15px',
            position: 'relative',
          }}>
            <InputLabel htmlFor="comments" style={webStyle.inputLabel}>
              {this.state.commentLabel}<span style={{ color: "red" }}>*</span>
            </InputLabel>
            <TextArea
              id="comments"
              multiline
              rows={3}
              data-testId="meetingcomments"
              // required
              variant="outlined"
              placeholder="Enter Your Comments here..."
              value={this.state.meeting.comments}
              onChange={this.handleMeetingChange("comments").bind(this)}
            />
            {this.state.meeting.comments === '' ? <span style={webStyle.allFieldError}>{this.state.commentErrorSetMeeting}</span> : null}
          </Grid>
          {this.commonProduct(this.state.product_id.id, this.handleProductChange, this.state.productDetailError)}

          <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: 5, paddingBottom: 0, paddingLeft: 30 }} className={classes.privacyPolicyRes}>
            {this.commonChekboxLable()}
          </Grid>
          <Grid style={{ marginLeft: '32px' }}>{this.state.isTermsAndCondition === false ? <span style={webStyle.allFieldErrorRobot}>{this.state.termsConditionErrorMeeting}</span> : null}</Grid>

          <Grid container style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <SaveButton data-testId="setupmeetingsubmit" type="submit" variant="contained" onClick={this.setUpMetting}>
              Submit
            </SaveButton>
          </Grid>

        </Grid>
      </form>
    )
  }

  sampleRequest() {
    const { classes } = this.props
    const initialFormValues = {
      consignee_address: '',
      pre_shipment_coa: '',
      comments: "",
      bill_to_address: "",
      api_uom_id: '',
      batch_requirement_id: '',
      api_quantity: '',
      pharmacopeia_grade: '',
      end_market_id: [],
      customer_specification: "",
      impurity_quantity: "",
      impurity_uom_id: "",
      working_standard_uom_id: '',
      working_standard_quantity: '',
      request_for_api_placed: "",
      lab_standard_quantity: '',
      lab_standard_uom_id: "",
      sample_types_attributes: [],
      aggrement: false,
      product_name: '',
      isEmployeeState: this.state.isEmployeeLogin,
    };

    const signupSchema = Yup.object().shape({
      aggrement: Yup.boolean().required("this check box require").oneOf([true], 'Please accept terms and condition'),
      pre_shipment_coa: Yup.string().required('Pre-shipment COA is required'),
      consignee_address: Yup.string().test('is-not-only-special-characters', 'Please enter a valid value', value => {
        return !/^[!@#$%^&*(),.?":{}|<>]*$/.test(value || '')}).required('Consignee address is required'),
      product_name: Yup.string()
        .when('isEmployeeState', {
          is: true,
          then: Yup.string()
            .required('Product is required.')
        }),
      comments: Yup.string().test('is-not-only-special-characters', 'Please enter a valid value', value => {
        return !/^[!@#$%^&*(),.?":{}|<>]*$/.test(value || '');
      }).required('Comment is required'),
      bill_to_address: Yup.string().test('is-not-only-special-characters', 'Please enter a valid value', value => {
        return !/^[!@#$%^&*(),.?":{}|<>]*$/.test(value || '');
      }).required('Bill to address is required'),
      sample_types_attributes: Yup.string().required('Query Type is required'),
      lab_standard_quantity: Yup.string()
        .when('sample_types_attributes', {
          is: (company) => company.includes(' Lab Standard'),
          then: Yup.string()
            .required('Lab Standard Quantity is required.'),
        }),
      lab_standard_uom_id: Yup.string()
        .when('sample_types_attributes', {
          is: (company) => company.includes(' Lab Standard'),
          then: Yup.string()
            .required('Lab Standard UOM is required.'),
        }),
      working_standard_quantity: Yup.string()
        .when('sample_types_attributes', {
          is: (company) => company.includes('Working Standard'),
          then: Yup.string()
            .required('Working Standard Quantity is required.'),
        }),
      working_standard_uom_id: Yup.string()
        .when('sample_types_attributes', {
          is: (company) => company.includes('Working Standard'),
          then: Yup.string()
            .required('Working Standard UOM is required.'),
        }),
      impurity_uom_id: Yup.string()
        .when('sample_types_attributes', {
          is: (company) => company.includes('Impurity'),
          then: Yup.string()
            .required('Impurity UOM is required.'),
        }),
      impurity_quantity: Yup.string()
        .when('sample_types_attributes', {
          is: (company) => company.includes('Impurity'),
          then: Yup.string()
            .required('Impurity Quantity is required.'),
        }),
      batch_requirement_id: Yup.string()
        .when('sample_types_attributes', {
          is: (company) => company.includes('API'),
          then: Yup.string()
            .required('Batch Requirement is required.'),
        }),
      api_quantity: Yup.string()
        .when('sample_types_attributes', {
          is: (company) => company.includes('API'),
          then: Yup.string().test('is-not-only-special-characters', 'Please enter a valid value', value => {
            return !/^[!@#$%^&*(),.?":{}|<>]*$/.test(value || '')})
            .required('API Quantity is required.'),
        }),
      api_uom_id: Yup.string()
        .when('sample_types_attributes', {
          is: (company) => company.includes('API'),
          then: Yup.string()
            .required('API UOM is required.'),
          otherwise: Yup.string()
        }),
      request_for_api_placed: Yup.string().when('sample_types_attributes', {
        is: (company) => !company.includes('API') && (company.includes('Working Standard') || company.includes('Impurity') || company.includes(' Lab Standard')),
        then: Yup.string().required('API availability is required'),
        otherwise: Yup.string()
      }),
      pharmacopeia_grade: Yup.string().when('sample_types_attributes', {
        is: (company) => company.includes('Working Standard') || company.includes('Impurity') || company.includes(' Lab Standard') || company.includes('API'),
        then: Yup.string().test('is-not-only-special-characters', 'Please enter a valid value', value => {
          return !/^[!@#$%^&*(),.?":{}|<>]*$/.test(value || '')}).required('Pharmacopeia Reference / Grade is required'),
        otherwise: Yup.string()
      }),
      end_market_id: Yup.string().when('sample_types_attributes', {
        is: (company) => company.includes('Working Standard') || company.includes('Impurity') || company.includes(' Lab Standard') || company.includes('API'),
        then: Yup.string().required('End Market is required'),
        otherwise: Yup.string()
      }),
      customer_specification: Yup.string().when('sample_types_attributes', {
        is: (company) => company.includes('Working Standard') || company.includes('Impurity') || company.includes(' Lab Standard') || company.includes('API'),
        then: Yup.string().test('is-not-only-special-characters', 'Please enter a valid value', value => {
          return !/^[!@#$%^&*(),.?":{}|<>]*$/.test(value || '')}).required('Customer Specifications / PSD is required'),
        otherwise: Yup.string()
      }),
    })
    return (
      <Formik initialValues={initialFormValues}
        validationSchema={signupSchema}
        onSubmit={(values) => { this.OnsaveSampleRequest(values); }}
        data-testId="savesampleform"
      >
        {({ errors, values, touched, handleSubmit, handleChange, handleBlur }: any) => (
          <form className="av-tooltip tooltip-label-bottom" onSubmit={handleSubmit} id="savesample">
            <Grid container spacing={7}>
              <Grid item lg={6} md={6} sm={6} xs={12} style={{ paddingBottom: '5px', paddingTop: '15px', position: 'relative', }}>
                <InputLabel htmlFor="name" style={webStyle.inputLabel}>
                  {this.state.labelName}
                </InputLabel>
                <InputField
                  id="name"
                  variant="outlined"
                  placeholder="Enter Your Name"
                  fullWidth
                  value={this.state.userName}
                  disabled
                />
              </Grid >
              {this.commonDatePicker(this.state.sampleDate)}
              {this.queryTypeSelect(values, handleChange, errors, touched)}
              {this.commonProduct(values?.product_name, handleChange, errors.product_name, errors.product_name, touched.product_name, true, "product_name")}
              {this.ApiAvailableSelect(values, handleChange, errors, touched, handleBlur)}
              {this.selectUIForAPISelect(values, handleChange, errors, touched, handleBlur)}
              {this.customerSpecificationSelect(values, handleChange, errors, touched, handleBlur)}
              {this.pharmaReferenceSelect(values, handleChange, errors, touched, handleBlur)}
              {this.endMarketIdsSelect(values, handleChange, errors, touched, handleBlur)}
              {this.standardQuantitySelect(values, handleChange, errors, touched, handleBlur)}
              {this.standardUMOSelect(values, handleChange, errors, touched, handleBlur)}
              {this.impurityStandardSelect(values, handleChange, errors, touched, handleBlur)}
              {this.impurityUMOSelect(values, handleChange, errors, touched, handleBlur)}
              {this.labQuantitySelect(values, handleChange, errors, touched, handleBlur)}
              {this.labUMOSelect(values, handleChange, errors, touched, handleBlur)}

              <Grid item lg={6} md={6} sm={6} xs={12} style={{
                paddingBottom: '5px',
                paddingTop: '15px',
                position: 'relative',
              }}>
                <InputLabel htmlFor="preShippement" style={webStyle.inputLabel}>
                  {this.state.preShippementLabel}<span style={{ color: "red" }}>*</span>
                </InputLabel>
                {/* --> */}

                <Select
                  labelId="preShippement"
                  displayEmpty
                  id="preShippement"
                  variant="outlined"
                  fullWidth
                  style={webStyle.select}
                  name="pre_shipment_coa"
                  value={values.pre_shipment_coa}
                  defaultValue={""}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <MenuItem value="" className={classes.selectPlaceholder}>
                    <em style={webStyle.selectPlaceholder}>Choose Yes or No</em>
                  </MenuItem>
                  {this.state.shipmentValues?.map((data: any, index: number) => {
                    return (
                      <MenuItem value={data.value} key={index}>
                        {data.value}
                      </MenuItem>
                    );
                  })}
                  {this.state.shipmentValues?.length === 0 && (
                    <MenuItem value={"No Data"}>No Data</MenuItem>
                  )}
                </Select>
                {errors.pre_shipment_coa && touched.pre_shipment_coa ?
                  (<div style={webStyle.allFieldError}>
                    {errors.pre_shipment_coa}
                  </div>) : null
                }
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12} style={{
                paddingBottom: '5px',
                paddingTop: '15px',
                position: 'relative',
              }}>
                <InputLabel htmlFor="ConsigneeAddressLabel" style={webStyle.inputLabel}>
                  {this.state.ConsigneeAddressLabel}<span style={{ color: "red" }}>*</span>
                </InputLabel>
                <InputField
                  id="ConsigneeAddressLabel"
                  variant="outlined"
                  placeholder="Enter Consignee Address"
                  value={values.consignee_address}
                  name="consignee_address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                />
                {errors.consignee_address && touched.consignee_address ?
                  (<div style={webStyle.allFieldError}>
                    {errors.consignee_address}
                  </div>) : null}
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12} style={{
                paddingBottom: '5px',
                paddingTop: '15px',
                position: 'relative',
              }}>
                <InputLabel htmlFor="billToAddressLabel" style={webStyle.inputLabel}>
                  {this.state.billToAddressLabel}<span style={{ color: "red" }}>*</span>
                </InputLabel>
                <InputField
                  id="billToAddressLabel"
                  variant="outlined"
                  placeholder="Enter Bill To Address"
                  value={values.bill_to_address}
                  name="bill_to_address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                />
                {errors.bill_to_address && touched.bill_to_address ? (
                  <div style={webStyle.allFieldError}>
                    {errors.bill_to_address}
                  </div>
                ) : null}
              </Grid>
              {this.commonAditionalEmail()}
              <Grid item lg={12} md={12} sm={12} xs={12} style={{
                paddingBottom: '5px',
                paddingTop: '15px',
                position: 'relative',
              }}>
                <InputLabel htmlFor="comments" style={webStyle.inputLabel}>
                  {this.state.commentLabel}<span style={{ color: "red" }}>*</span>
                </InputLabel>
                <TextArea
                  id="comments"
                  multiline
                  rows={4}
                  value={values.comments}
                  name="comments"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  placeholder="Enter Your Comments Here..."
                />
                {errors.comments && touched.comments ? (
                  <div style={webStyle.allFieldError}>
                    {errors.comments}
                  </div>
                ) : null}
              </Grid>

              <Grid item md={12} lg={12} sm={12} xs={12} style={{
                paddingBottom: '5px',
                paddingTop: '15px',
                position: 'relative',
              }}>
                <Typography component="label">
                  <AttachFileIcon
                    style={webStyle.attachmentIcon}
                  >
                  </AttachFileIcon>
                  <span style={webStyle.attachmentText}>Attachment</span>
                  <input
                    type="file"
                    hidden
                    multiple={true}
                    accept="application/pdf, image/*"
                    onChange={this.handleDocumentUpload}
                  />
                </Typography>
              </Grid>
              <ul>
                {this.state.uploadedFiles?.map((doc: any, index: number) => {
                  return (
                    <Grid container spacing={2} style={{ alignItems: "center", marginBottom: `${this.state.uploadedFiles?.length > 0 ? '10px' : '0px'}` }}>
                      <li key={`file${index}`} style={webStyle.docName}>{doc.name}</li>
                      <DeleteIcon
                        style={{ fill: 'red' }}
                        onClick={() => this.removeFile(doc.name)}>

                      </DeleteIcon>
                      {/* <Button onClick={() => this.removeFile(doc.name)}>Delete</Button> */}
                    </Grid>
                  )
                })}
              </ul>

              <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: 0, paddingLeft: 30, paddingBottom: 0 }} className={classes.privacyPolicyRes}>
                <CheckBoxLabel
                  control={
                    <Checkbox
                      color="default"
                      disableRipple
                      checkedIcon={<span style={webStyle.checkedIcon} />}
                      onChange={handleChange("aggrement")}
                      icon={<span style={webStyle.icon} />}
                      inputProps={{ "aria-label": "decorative checkbox" }}
                      id="aggrement"
                    />
                  }
                  label=""
                  id="aggrement"
                />
                <Typography style={webStyle.termsText} component={"span"}>
                  {this.state.privacyPolicyLabel}
                  <Typography
                    onClick={handlePrivacyPolicyRedirection}
                    component="span"
                    style={webStyle.privacyText}
                  >
                    {" "}
                    {this.state.privacyPolicyText}
                  </Typography>{" "}
                  and
                  <Typography
                    onClick={handleTermsOfUseRedirection}
                    component="span"
                    style={webStyle.privacyText}
                  >
                    {" "}
                    {this.state.termslabelText}
                  </Typography>
                  <Typography component="span"> {this.state.termsLabel}</Typography></Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: 0, paddingLeft: 25, paddingBottom: 10 }}><Typography style={webStyle.allFieldError}>{errors.aggrement && touched.aggrement ? errors.aggrement : null} </Typography></Grid>
              <Grid container style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                <SaveButton data-testId="samplesubmitbtn" type="submit" variant="contained"  >
                  Send
                </SaveButton>
              </Grid>

            </Grid >
          </form>
        )}
      </Formik>
    );
  }

  labUMOSelect = (values: any, handleChange: any, errors: any, touched: any, handleBlur: any) => {
    const { classes } = this.props;
    return (
      values?.sample_types_attributes.includes(' Lab Standard') && (
        <Grid item lg={6} md={6} sm={6} xs={12} style={{ paddingBottom: '5px', paddingTop: '15px', position: 'relative', }}>
          <InputLabel htmlFor="labUMOLabel" style={webStyle.inputLabel}>
            {this.state.labUMOLabel}
            <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Select
            labelId="labUMOLabel"
            id="labUMOLabel"
            displayEmpty
            variant="outlined"
            fullWidth
            style={webStyle.select}
            name="lab_standard_uom_id"
            defaultValue={""}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lab_standard_uom_id}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
          >
            <MenuItem value={""} className={classes.selectPlaceholder}>
              <em style={webStyle.selectPlaceholder}>Choose Lab Standard UOM</em>
            </MenuItem>
            {this.state.labStandardUoms?.map((labStandardUomsData: any, index: number) => {
              return (
                <MenuItem value={labStandardUomsData.id} key={index}>
                  {labStandardUomsData.value}
                </MenuItem>
              );
            })}
            {this.state.labStandardUoms?.length === 0 && (
              <MenuItem value={"No Data"}>No Data</MenuItem>
            )}
          </Select>
          {errors.lab_standard_uom_id && touched.lab_standard_uom_id ? (
            <div style={webStyle.allFieldError}>
              {errors.lab_standard_uom_id}
            </div>
          ) : null}
        </Grid>
      )
    )
  }

  labQuantitySelect = (values: any, handleChange: any, errors: any, touched: any, handleBlur: any) => {
    return (
      values?.sample_types_attributes.includes(' Lab Standard') && (
        <Grid item lg={6} md={6} sm={6} xs={12} style={{ paddingBottom: '5px', paddingTop: '15px', position: 'relative', }}>
          <InputLabel htmlFor="labQuantitylabel" style={webStyle.inputLabel}>
            {this.state.labQuantityLabel}
            <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <InputField
            id="labQuantitylabel"
            variant="outlined"
            placeholder="Enter Lab Standards Quantity"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lab_standard_quantity}
            name="lab_standard_quantity"
            type="number"
            fullWidth
            onInput={(e: any) => {
              e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
            }}
          />
          {errors.lab_standard_quantity && touched.lab_standard_quantity ?
            (<div style={webStyle.allFieldError}>
              {errors.lab_standard_quantity}
            </div>) : null
          }
        </Grid>
      )
    )
  }

  impurityUMOSelect = (values: any, handleChange: any, errors: any, touched: any, handleBlur: any) => {
    const { classes } = this.props;
    return (
      values?.sample_types_attributes.includes('Impurity') && (
        <Grid item lg={6} md={6} sm={6} xs={12} style={{ paddingBottom: '5px', paddingTop: '15px', position: 'relative', }}>
          <InputLabel htmlFor="impurityUMOLabel" style={webStyle.inputLabel}>
            {this.state.impurityUMOLabel}<span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Select
            labelId="impurityUMOLabel"
            id="impurityUMOLabel"
            displayEmpty
            variant="outlined"
            fullWidth
            style={webStyle.select}
            name="impurity_uom_id"
            defaultValue={""}
            value={values.impurity_uom_id}
            onChange={handleChange}
            onBlur={handleBlur}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
          >
            <MenuItem value={""} className={classes.selectPlaceholder}>
              <em style={webStyle.selectPlaceholder}>Choose Impurity UMO</em>
            </MenuItem>
            {this.state.impurityUoms?.map((impurityUomsData, index) => {
              return (
                <MenuItem value={impurityUomsData.id} key={index}>
                  {impurityUomsData.value}
                </MenuItem>
              );
            })}
            {this.state.impurityUoms?.length === 0 && (
              <MenuItem value={"No Data"}>No Data</MenuItem>
            )}
          </Select>
          {errors.impurity_uom_id && touched.impurity_uom_id ?
            (<div style={webStyle.allFieldError}>
              {errors.impurity_uom_id}
            </div>) : null
          }
        </Grid>
      )
    )
  }

  impurityStandardSelect = (values: any, handleChange: any, errors: any, touched: any, handleBlur: any) => {
    return (
      values?.sample_types_attributes.includes('Impurity') && (
        <Grid item lg={6} md={6} sm={6} xs={12} style={{ paddingBottom: '5px', paddingTop: '15px', position: 'relative', }}>
          <InputLabel htmlFor="impurityquantity" style={webStyle.inputLabel}>
            {this.state.impurityQuantityLabel}
            <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <InputField
            id="impurityquantity"
            variant="outlined"
            placeholder="Enter Impurity Quantity"
            value={values.impurity_quantity}
            name="impurity_quantity"
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
            fullWidth
            inputProps={{ maxLength: 6 }}
            onInput={(e: any) => {
              e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
            }} />
          {errors.impurity_quantity && touched.impurity_quantity ?
            (<div style={webStyle.allFieldError}>
              {errors.impurity_quantity}
            </div>) : null}
        </Grid>
      )
    )
  }

  standardUMOSelect = (values: any, handleChange: any, errors: any, touched: any, handleBlur: any) => {
    const { classes } = this.props;
    return (
      values?.sample_types_attributes.includes('Working Standard') && (
        <Grid item lg={6} md={6} sm={6} xs={12} style={{ paddingBottom: '5px', paddingTop: '15px', position: 'relative', }}>
          <InputLabel htmlFor="standardUMO" style={webStyle.inputLabel}>
            {this.state.standardUMO}
            <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Select
            labelId="standardUMO"
            displayEmpty
            id="standardUMO"
            variant="outlined"
            fullWidth
            style={webStyle.select}
            value={values.working_standard_uom_id}
            name="working_standard_uom_id"
            onChange={handleChange}
            onBlur={handleBlur}
            defaultValue={""}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
          >
            <MenuItem value={""} className={classes.selectPlaceholder}>
              <em style={webStyle.selectPlaceholder}>Choose Working Standard UMO</em>
            </MenuItem>
            {this.state.workingStandardUoms?.map((workingStandardData, index) => {
              return (
                <MenuItem value={workingStandardData.id} key={index}>
                  {workingStandardData.value}
                </MenuItem>
              );
            })}
            {this.state.workingStandardUoms?.length === 0 && (
              <MenuItem value={"No Data"}>No Data</MenuItem>
            )}
          </Select>
          {errors.working_standard_uom_id && touched.working_standard_uom_id ?
            (<div style={webStyle.allFieldError}>
              {errors.working_standard_uom_id}
            </div>) : null}
        </Grid>
      )
    )
  }

  standardQuantitySelect = (values: any, handleChange: any, errors: any, touched: any, handleBlur: any) => {
    return (
      values?.sample_types_attributes.includes('Working Standard') && (
        <Grid item lg={6} md={6} sm={6} xs={12} style={{ paddingBottom: '5px', paddingTop: '15px', position: 'relative', }}>
          <InputLabel htmlFor="standardquantity" style={webStyle.inputLabel}>
            {this.state.standardQuantityLabel}
            <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <InputField
            id="standardquantity"
            variant="outlined"
            placeholder="Enter Working Standard Quantity"
            value={values.working_standard_quantity}
            name="working_standard_quantity"
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
            fullWidth
            inputProps={{ maxLength: 6 }}
            onInput={(e: any) => {
              e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
            }}
          />
          {errors.working_standard_quantity && touched.working_standard_quantity ?
            (<div style={webStyle.allFieldError}>
              {errors.working_standard_quantity}
            </div>) : null}
        </Grid>
      )
    )
  }

  pharmaReferenceSelect = (values: any, handleChange: any, errors: any, touched: any, handleBlur: any) => {
    return (
      values?.sample_types_attributes?.length > 0 && (
        <Grid item lg={6} md={6} sm={6} xs={12} style={{ paddingBottom: '5px', paddingTop: '15px', position: 'relative', }}>
          <InputLabel htmlFor="customerspecific" style={webStyle.inputLabel}>
            {this.state.pharmaRefLabel}<span style={{ color: "red" }}>*</span>
          </InputLabel>
          <InputField
            id="customerspecific"
            variant="outlined"
            placeholder="Enter Pharmacopeia Reference / Grade"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.pharmacopeia_grade}
            name="pharmacopeia_grade"
            fullWidth
          />
          {errors.pharmacopeia_grade && touched.pharmacopeia_grade ?
            (<div style={webStyle.allFieldError}>
              {errors.pharmacopeia_grade}
            </div>) : null}
        </Grid>
      )
    )
  }

  endMarketIdsSelect = (values: any, handleChange: any, errors: any, touched: any, handleBlur: any) => {
    return (
      values?.sample_types_attributes?.length > 0 && (
        <Grid item lg={6} md={6} sm={6} xs={12} style={{ paddingBottom: '5px', paddingTop: '15px', position: 'relative', }}>
          <InputLabel htmlFor="endmarketLabel" style={webStyle.inputLabel} >
            {this.state.endmarketLabel} <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <MultiSelect
            labelId="endmarketLabel"
            id="endmarketLabel"
            variant="outlined"
            multiple
            fullWidth
            style={webStyle.select}
            input={<OutlinedInput placeholder="Tag" />}
            value={values.end_market_id}
            name="end_market_id"
            displayEmpty={true}
            renderValue={(selected: any | '') =>
              this.setEndMarketSelectOptions(selected)
            }
            onChange={(e: any, v: any) => { handleChange(e); this.handleOnMarketEndChange(e, v, true) }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              getContentAnchorEl: null,
            }}
          >
            {
              this.state.endMarkets !== undefined && (
                this.state.endMarkets?.map((data: any, index: number) => {
                  return (
                    <MenuItem value={data} key={`endMarketId${data.id}`}>
                      <Checkbox checked={this.state.sampleRequest.end_market_id.findIndex((doc: any) => doc.id === data.id) > -1} />
                      <ListItemText primary={data.name} />
                    </MenuItem>
                  );
                })
              )
            }
            {
              this.state.endMarkets === undefined && (
                <MenuItem value={"No Data"}>No Data</MenuItem>
              )
            }
            {
              this.state.endMarkets !== undefined && (
                this.state.endMarkets?.length === 0 && (
                  <MenuItem value={"No Data"}>No Data</MenuItem>
                )
              )
            }
          </MultiSelect>
          {errors.end_market_id && touched.end_market_id ?
            (<div style={webStyle.allFieldError}>
              {errors.end_market_id}
            </div>) : null}
        </Grid>
      )
    )
  }

  customerSpecificationSelect = (values: any, handleChange: any, errors: any, touched: any, handleBlur: any) => {
    return (
      values?.sample_types_attributes?.length > 0 &&
      (
        <Grid item lg={6} md={6} sm={6} xs={12} style={{ paddingBottom: '5px', paddingTop: '15px', position: 'relative', }}>
          <InputLabel htmlFor="customerspecific" style={webStyle.inputLabel}>
            {this.state.customerSpecificationLabel}<span style={{ color: "red" }}>*</span>
          </InputLabel>
          <InputField
            id="customerspecific"
            variant="outlined"
            placeholder="Enter Customer Specification / PSD"
            value={values.customer_specification}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            name="customer_specification"
          />
          {errors.customer_specification && touched.customer_specification ? (
            <div style={webStyle.allFieldError}>
              {errors.customer_specification}
            </div>
          ) : null}
        </Grid>
      )
    )
  }

  selectUIForAPISelect = (values: any, handleChange: any, errors: any, touched: any, handleBlur: any) => {
    const { classes } = this.props;
    return (
      values?.sample_types_attributes.includes('API') &&
      (<React.Fragment>
        <Grid item lg={6} md={6} sm={6} xs={12} style={{ paddingBottom: '5px', paddingTop: '15px', position: 'relative', }}>
          <InputLabel htmlFor="apiquantity" style={webStyle.inputLabel}>
            {this.state.apiQuantityLabel}<span style={{ color: "red" }}>*</span>
          </InputLabel>
          <InputField
            id="apiquantity"
            variant="outlined"
            placeholder="Enter API Quantity"
            onChange={handleChange}
            onBlur={handleBlur}
            name="api_quantity"
            value={values.api_quantity}
            type="number"
            fullWidth
            inputProps={{ maxLength: 6 }}
            onInput={(e: any) => {
              e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
            }}
          />
          {errors.api_quantity && touched.api_quantity ?
            (<div style={webStyle.allFieldError}>
              {errors.api_quantity}
            </div>) : null}
        </Grid>

        {
          values?.sample_types_attributes.includes('API') && (
            <Grid item lg={6} md={6} sm={6} xs={12} style={{ paddingBottom: '5px', paddingTop: '15px', position: 'relative', }}>
              <InputLabel htmlFor="apiUMO" style={webStyle.inputLabel}>
                {this.state.apiUOMLabel}<span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Select
                labelId="apiUMO"
                displayEmpty
                id="apiUMO"
                variant="outlined"
                fullWidth
                style={webStyle.select}
                value={values.api_uom_id}
                onChange={handleChange}
                name="api_uom_id"
                onBlur={handleBlur}
                defaultValue={""}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
              >
                <MenuItem value={""} className={classes.selectPlaceholder}>
                  <em style={webStyle.selectPlaceholder}>Choose API UOM</em>
                </MenuItem>
                {this.state.APIUMO?.map((data: any, index: number) => {
                  return (
                    <MenuItem value={data.id} key={index}>
                      {data.name}
                    </MenuItem>
                  );
                })}
                {this.state.APIUMO?.length === 0 && (
                  <MenuItem value={"No Data"}>No Data</MenuItem>
                )}
              </Select>
              {errors.api_uom_id && touched.api_uom_id ?
                (<div style={webStyle.allFieldError}>
                  {errors.api_uom_id}
                </div>) : null}
            </Grid>
          )
        }

        {
          values?.sample_types_attributes.includes('API') && (
            <Grid item lg={6} md={6} sm={6} xs={12} style={{ paddingBottom: '5px', paddingTop: '15px', position: 'relative', }}>
              <InputLabel htmlFor="batch" style={webStyle.inputLabel}>
                {this.state.batchReqLabel}<span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Select
                labelId="batch"
                displayEmpty
                id="batch"
                variant="outlined"
                fullWidth
                style={webStyle.select}
                value={values.batch_requirement_id}
                defaultValue={""}
                name="batch_requirement_id"
                onChange={handleChange}
                onBlur={handleBlur}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
              >
                <MenuItem value={""} className={classes.selectPlaceholder}>
                  <em style={webStyle.selectPlaceholder}>Choose API Batch Requirement</em>
                </MenuItem>
                {this.state.BatchRequirement?.map((data: any, index: number) => {
                  return (
                    <MenuItem value={data.id} key={index}>
                      {data.value}
                    </MenuItem>
                  );
                })}
                {this.state.BatchRequirement?.length === 0 && (
                  <MenuItem value={"No Data"}>No Data</MenuItem>
                )}
              </Select>
              {errors.batch_requirement_id && touched.batch_requirement_id ?
                (<div style={webStyle.allFieldError}>
                  {errors.batch_requirement_id}
                </div>) : null}
            </Grid>
          )
        }
      </React.Fragment>)
    )
  }

  ApiAvailableSelect = (values: any, handleChange: any, errors: any, touched: any, handleBlur: any) => {
    const { classes } = this.props;
    return (
      (values?.sample_types_attributes?.length !== 0) && (!this.state.isAPISelected) && (
        values?.sample_types_attributes.includes('Working Standard') ||
        values?.sample_types_attributes.includes('Impurity') ||
        values?.sample_types_attributes.includes(' Lab Standard')) ? (
        <>
          <Grid item lg={6} md={6} sm={6} xs={12} style={{
            paddingBottom: '5px',
            paddingTop: '15px',
            position: 'relative',
          }}>
            <InputLabel htmlFor="apiRequest" style={webStyle.inputLabel}>
              {this.state.apiRequestlabel}<span style={{ color: "red" }}>*</span>
            </InputLabel>
            <Select
              labelId="apiUMO"
              data-testId="sampletypes"
              displayEmpty
              id="apiUMO"
              variant="outlined"
              fullWidth
              style={webStyle.select}
              name="request_for_api_placed"
              defaultValue={""}
              value={values.request_for_api_placed}
              onChange={handleChange}
              onBlur={handleBlur}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                getContentAnchorEl: null
              }}
            >
              <MenuItem value={""} className={classes.selectPlaceholder}>
                <em style={webStyle.selectPlaceholder}>Choose API availability</em>
              </MenuItem>
              {this.state.shipmentValues?.map((data: any, index: number) => {
                return (
                  <MenuItem value={data.value} key={index}
                    onClick={() => {
                      if (data.value === 'Yes') {
                        this.setState({
                          isAttachment: true,
                          apiAttachmentRequired: true
                        })
                      } else {
                        this.setState({
                          apiAttachmentRequired: false,
                          isAttachment: false,
                          uploadedAPIFilesBase: [],
                          uploadedAPIFiles: [],
                        })
                      }
                    }}
                  >
                    {data.value}
                  </MenuItem>
                );
              })}
              {this.state.shipmentValues?.length === 0 && (
                <MenuItem value={"No Data"}>No Data</MenuItem>
              )}
            </Select>
            {
              this.state.apiAttachmentRequired && (
                this.state.uploadedAPIFilesBase.length === 0 && (
                  <p style={{ color: 'red', margin: '5px', fontFamily: 'SFProDisplay', fontSize: '14px' }}>
                    Please attach COA in API Available
                  </p>
                )
              )
            }
            {
              (this.state.sampleRequest.request_for_api_placed === "Yes" || this.state.isAttachment) && (
                this.AttachmentPopUp()
              )
            }
            {errors.request_for_api_placed && touched.request_for_api_placed ?
              (<div style={webStyle.allFieldError}>
                {errors.request_for_api_placed}
              </div>) : null
            }
          </Grid>
        </>
      ) : null
    )
  }

  queryTypeSelect = (values: any, handleChange: any, errors: any, touched: any) => {
    return (
      <Grid item lg={6} md={6} sm={6} xs={12} style={{ paddingBottom: '5px', paddingTop: '15px', position: 'relative', }}>
        <InputLabel htmlFor="documentLabel" style={webStyle.inputLabel}>
          {this.state.sampleLabel}<span style={{ color: "red" }}>*</span>
        </InputLabel>
        <MultiSelect
          labelId="documentLabel"
          id="sampletypeselect"
          data-testId="sampletypeselect"
          variant="outlined"
          multiple
          fullWidth
          style={webStyle.select}
          name="sample_types_attributes"
          input={<OutlinedInput placeholder="Tag" />}
          value={values.sample_types_attributes}
          displayEmpty={true}
          renderValue={(selected: any | '') =>
            this.setSelectOptions(selected)
          }
          onChange={(e: any) => { handleChange(e); this.setAPISelected(e) }}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            getContentAnchorEl: null,
          }}
          defaultValue="none"
        >
          {this.state.sampleTypes?.map((data: any, index: number) => {
            return (
              <MenuItem value={data.value} key={index}>
                <Checkbox
                  name="sample_types_attributes"
                  value={data.value}
                  // @ts-ignore
                  checked={values.sample_types_attributes.includes(data.value)}
                  onChange={e => {
                    if (e.target.checked) {
                      // @ts-ignore
                      values.sample_types_attributes.push(data.value);
                    } else {
                      // @ts-ignore
                      const idx = values.sample_types_attributes.indexOf(data.value);
                      // @ts-ignore
                      values.sample_types_attributes.splice(idx);
                    }
                  }}
                />
                {data.value}
              </MenuItem>
            );
          })}
          {this.state.sampleTypes?.length === 0 && (
            <MenuItem value={"No Data"}>No Data</MenuItem>
          )}
        </MultiSelect>
        {errors.sample_types_attributes && touched.sample_types_attributes ?
          (<div style={webStyle.allFieldError}>
            {errors.sample_types_attributes}
          </div>) : null}
      </Grid>
    )
  }

  ProductQuery() {
    const { classes } = this.props
    return (
      <Grid container spacing={7}>
        <Grid item lg={6} md={6} sm={6} xs={12} style={{
          paddingBottom: '5px',
          paddingTop: '15px',
          position: 'relative',
        }}>
          <InputLabel htmlFor="name" style={webStyle.inputLabel}>
            {this.state.labelName}
          </InputLabel>
          <InputField
            id="name"
            variant="outlined"
            disabled
            placeholder="Enter Your Name"
            defaultValue={"User 1"}
            fullWidth
            value={this.state.userName}
          />
        </Grid>
        {this.commonDatePicker(this.state.date)}

        <Grid item lg={6} md={6} sm={6} xs={12} style={{
          paddingBottom: '5px',
          paddingTop: '15px',
          position: 'relative',
        }}>
          <InputLabel htmlFor="type" style={webStyle.inputLabel}>
            {this.state.typeLabel}
          </InputLabel>
          <InputField
            id="email"
            variant="outlined"
            required
            fullWidth
            disabled
            value={this.state.productQuery.query_type}
          />
        </Grid>

        {this.commonProduct(this.state.product_id.id, this.handleProductChange, this.state.productDetailQueryError)}

        <Grid item lg={6} md={6} sm={6} xs={12} style={{ paddingBottom: '5px', paddingTop: '15px', position: 'relative' }}>
          <InputLabel htmlFor="documentLabel" style={webStyle.inputLabels}>
            {this.state.documentNameLabel}  <DocTooltip title={<span>{configJSON.documentUploadMessage}</span>} enterTouchDelay={0} arrow placement="top">
              <ErrorIcon style={webStyle.errorIcon} />
            </DocTooltip>
          </InputLabel>
          
          <MultiSelect
            labelId="documentLabel"
            data-test-Id="multiselect"
            id="documentLabel"
            variant="outlined"
            multiple
            fullWidth
            IconComponent={(props: { [key: string]: string }) => {
              return (
                <>
                    <ArrowDropDownIcon {...props} style={webStyle.downArrow} />
                  </>
              )
            }}
            
            style={webStyle.select}
            input={<OutlinedInput placeholder="Tag" />}
            value={this.state.selectedDocuments}
            displayEmpty={true}
            defaultChecked={this.returnBoolenValue(this.state.productQuery.catalogue_id == 80)}
            renderValue={(selected: any | '') =>
              this.setDocSelectOptions(selected)
            }
            onChange={this.handleDocumentSelectChange}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              getContentAnchorEl: null,
            }}
            defaultValue="none"            
          >
            {
              this.state.productDocuments !== undefined && (
                this.state.productDocuments?.map((data: any, index: number) => {
                  return (
                    <MenuItem value={data} key={`productdocument${index}`}>
                      <Checkbox checked={this.state.selectedDocuments.findIndex((doc: any) => doc.id === data.id) > -1} />
                      <ListItemText primary={data.attributes.name} />
                    </MenuItem>
                  );
                })
              )
            }
            {
              this.state.productDocuments === undefined && (
                <MenuItem value={"No Data"}>No Data</MenuItem>
              )
            }
            {
              this.state.productDocuments !== undefined && (
                this.state.productDocuments?.length === 0 && (
                  <MenuItem value={"No Data"}>No Data</MenuItem>
                )
              )
            }
          </MultiSelect>
        </Grid>

        <Grid item lg={6} md={6} sm={6} xs={12} style={{
          paddingBottom: '5px',
          paddingTop: '15px',
          position: 'relative',
        }}>
          <InputLabel htmlFor="endmarketLabel" style={webStyle.inputLabel}>
            {this.state.endmarketLabel}<span style={{ color: "red" }}>*</span>
          </InputLabel>
          <MultiSelect
            labelId="endmarketLabel"
            id="endmarketLabel"
            variant="outlined"
            multiple
            fullWidth
            style={webStyle.select}
            input={<OutlinedInput placeholder="Tag" />}
            value={this.state.productQuery.end_market_id}
            displayEmpty={true}
            renderValue={(selected: any | '') =>
              this.setEndMarketSelectOptions(selected)
            }
            onChange={this.handleOnMarketEndChange}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              getContentAnchorEl: null,
            }}
          >
            {
              this.state.endMarkets !== undefined && (
                this.state.endMarkets?.map((data: any, index: number) => {
                  return (
                    <MenuItem value={data} key={`endMarketId${data.id}`}>
                      <Checkbox checked={this.state.productQuery.end_market_id.findIndex((doc: any) => doc.id === data.id) > -1} />
                      <ListItemText primary={data.name} />
                    </MenuItem>
                  );
                })
              )
            }
            {
              this.state.endMarkets === undefined && (
                <MenuItem value={"No Data"}>No Data</MenuItem>
              )
            }
            {
              this.state.endMarkets !== undefined && (
                this.state.endMarkets?.length === 0 && (
                  <MenuItem value={"No Data"}>No Data</MenuItem>
                )
              )
            }
          </MultiSelect>
          {this.state.productQuery.end_market_id.length === 0 ? <span style={webStyle.allFieldError}>{this.state.IsMarketError}</span> : null}
        </Grid>

        {this.commonAditionalEmail()}
        <Grid item lg={12} md={12} sm={12} xs={12} style={{
          paddingBottom: '5px',
          paddingTop: '15px',
          position: 'relative',
        }}>
          <InputLabel htmlFor="comments" style={webStyle.inputLabel}>
            {this.state.commentLabel}<span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextArea
            id="comments"
            data-testId="productquerycomment"
            multiline
            required
            rows={4}
            value={this.state.productQuery.comments}
            onChange={this.handleProductQueryChange("comments").bind(this)}
            variant="outlined"
            placeholder="Please use comment box to raise a general query about the products"
          />
          {this.state.productQuery.comments !== '' ? null : <span style={webStyle.allFieldError}>{this.state.IsCommentError}</span>}
        </Grid>

        <Grid item md={12} lg={12} sm={12} xs={12} style={{
          paddingBottom: '5px',
          paddingTop: '15px',
          position: 'relative',
        }}>
          <Typography component="label">
            <AttachFileIcon
              style={webStyle.attachmentIcon}
            >
            </AttachFileIcon>
            <span style={webStyle.attachmentText}>
              Attachments
            </span>
            <input
              data-testId="documentuploadinput"
              type="file"
              hidden
              accept="application/pdf, image/*"
              multiple={true}
              onChange={this.handleDocumentUpload}
            />

          </Typography>
        </Grid>
        <ul>
          {this.state.uploadedFiles?.map((doc: any, index: number) => {
            return (
              <Grid key={`file${index}`} container spacing={2} style={{ alignItems: "center", marginBottom: `${this.state.uploadedFiles?.length > 0 ? '10px' : '0px'}` }}>
                <li key={`file${index}`} style={webStyle.docName}>{doc.name}</li>
                <DeleteIcon
                  style={{ fill: 'red' }}
                  onClick={() => this.removeFile(doc.name)}>
                </DeleteIcon>
              </Grid>
            )
          })}
        </ul>

        <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: 0, paddingLeft: 30, paddingBottom: 0 }} className={classes.privacyPolicyRes}>
          {this.commonChekboxLable()}
        </Grid>
        <Grid style={{ marginBottom: 30, marginLeft: 32 }}>{this.state.isTermsAndCondition === false ? <span style={webStyle.allFieldErrorRobot}>{this.state.isTermsAndConditionSave}</span> : null}</Grid>
        <Grid container style={{ paddingLeft: '20px', paddingRight: '20px' }}>
          <SaveButton type="submit" variant="contained" data-testId="saveproductquery" onClick={this.saveProductQuery}>
            Send
          </SaveButton>
        </Grid>
      </Grid>
    )
  }

  commonProduct(value: any, changeFunction: any, errorState: any, error?: any, touched?: any, isFormik?: any, name?: any) {
    const { classes } = this.props
    return (
      <Grid item lg={6} md={6} sm={6} xs={12} style={{ paddingBottom: '5px', paddingTop: '15px', position: 'relative', }}>
        <InputLabel htmlFor="product" style={webStyle.inputLabel}>
          {this.state.productlabel}<span style={{ color: "red" }}>*</span>
        </InputLabel>
        {
          this.state.isEmployeeLogin ?
            (
              <>
                <Select
                  labelId="product"
                  displayEmpty
                  id="email"
                  variant="outlined"
                  fullWidth
                  style={webStyle.select}
                  defaultValue={""}
                  value={value}
                  name={isFormik && name}
                  onChange={changeFunction}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                >
                  <MenuItem value={""} className={classes.selectPlaceholder}>
                    <em style={webStyle.selectPlaceholder}>Choose Product</em>
                  </MenuItem>
                  {this.state.productList?.map((data: any, index: number) => {
                    return (
                      <MenuItem value={data} key={index}>
                        {data.name}
                      </MenuItem>
                    );
                  })}
                  {this.state.productList?.length === 0 && (
                    <MenuItem value={"No Data"}>No Data</MenuItem>
                  )}
                </Select>
                {isFormik ? <span>{
                  error && touched ?
                    (<div style={webStyle.allFieldError}>
                      {errorState}
                    </div>) : null} </span> : <span>{this.state.product_id === '' ? <span style={webStyle.allFieldError}>{errorState}</span> : null}</span>}
              </>
            )
            :
            (
              <InputField
                id="email"
                variant="outlined"
                required
                fullWidth
                disabled
                value={this.state.productDetail?.attributes.name}
              />
            )
        }
      </Grid>
    )
  }

  commonChekboxLable() {
    return (
      <>
        <CheckBoxLabel
          control={
            <Checkbox
              color="default"
              disableRipple
              checkedIcon={<span style={webStyle.checkedIcon} />}
              checked={this.state.isTermsAndCondition}
              onChange={this.handleTermsConditionClick}
              icon={<span style={webStyle.icon} />}
              inputProps={{ "aria-label": "decorative checkbox" }}
            />
          }
          label=""
        />
        <Typography style={webStyle.termsText} component={"span"}>
          {this.state.privacyPolicyLabel}
          <Typography onClick={handlePrivacyPolicyRedirection} component="span"
            style={webStyle.privacyText}>{" "}{this.state.privacyPolicyText}</Typography>{" "}
          and
          <Typography
            onClick={handleTermsOfUseRedirection}
            component="span"
            style={webStyle.privacyText}
          >
            {" "}
            {this.state.termslabelText}
          </Typography>
          <Typography component="span"> {this.state.termsLabel}</Typography>
        </Typography>
      </>
    )
  }

  commonDatePicker(value: any) {
    return (<>
      <Grid item lg={6} md={6} sm={6} xs={12} style={{
        paddingBottom: '5px',
        paddingTop: '15px',
        position: 'relative',
      }}>
        <InputLabel htmlFor="company" style={webStyle.inputLabel}>
          {this.state.companyNameLabel}
        </InputLabel>
        <InputField
          id="company"
          variant="outlined"
          placeholder="Enter Your Company Name"
          fullWidth
          disabled
          value={this.state.companyName}
        />
      </Grid>

      <Grid item lg={6} md={6} sm={6} xs={12} style={{
        paddingBottom: '5px',
        paddingTop: '15px',
        position: 'relative',
      }}>
        <InputLabel htmlFor="date" style={webStyle.inputLabel}>
          {this.state.dateLabel}
        </InputLabel>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item md={12} sm={12} xs={12}>
            <MUiDatePicker

              id="date"
              format="dd-MM-yyyy"
              value={value}
              onChange={this.handleDateChange}
              disabled
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid></>)
  }

  commonAditionalEmail() {
    return (
      <Grid item lg={6} md={6} sm={6} xs={12} style={{
        paddingBottom: '0px',
        paddingTop: '15px',
        position: 'relative',
      }}>
        <InputLabel htmlFor="additionalEmail" style={webStyle.inputLabels}>
          {this.state.additionalEmailLabel} <DocTooltip title={<span>{configJSON.multipleEmail}<br /> {configJSON.space}<br />{configJSON.example}</span>} enterTouchDelay={0} arrow placement="top">
            <ErrorIcon style={webStyle.errorIcon} />
              </DocTooltip>
        </InputLabel>
          <InputField
            id="additionalEmail"
            variant="outlined"
            placeholder="Enter Additional Email "
            onKeyUp={(event:React.ChangeEvent<HTMLInputElement>)=>{
              this.setState({email : event.target.value})
            }}
            fullWidth
            type="mail"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                </InputAdornment>
              )
            }}
          />
        <Typography style={webStyle.errorMessageEmail}>{this.state.additionalEmailError}</Typography>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginTop: '15px' }}>
          {
            this.state.additionalEmails?.map((data, index) => {
              return (
                <Typography key={index} style={webStyle.additionalMails} >
                  {data.additional_email} <CancelIcon data-testId="removeemailicon" fontSize="small" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => this.removeEmail(index)} />
                </Typography>
              )
            })
          }
        </div>
      </Grid>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        {this.state.isEmployeeLogin ? <EmployeeAppHeader data-testId="employeeheader" navigation={this.props.navigation} /> : <AppHeader data-testId="appheader" navigation={this.props.navigation} />}
        <MainContainer data-testId="maincontainer">
          <Loader loading={this.state.isLoading} />
          <Grid container>
            {/* <NavLink to={`/ProductDetail/${this.state.productDetail?.id}`}> */}
            <KeyboardBackspaceIcon
              data-testId="backarrow"
              onClick={this.goBack}
              style={{
                ...webStyle.raiseRequestBackButton,
              }}
              fontSize="medium"
            />
            {/* </NavLink> */}
            <Typography style={webStyle.detailTitle}>
              {this.state.queryLabel}
            </Typography>
          </Grid>

          <CustomTabs
            onChange={this.handleChangeTab}
            value={this.state.selectedTab}
            aria-label="Tabs where each tab needs to be selected manually"
            data-testId="tabs"
          >
            <CustomTab
              label="Product Query"
              style={webStyle.Tab1}
              disableRipple
            />
            <CustomTab
              label="Sample Request"
              disableRipple
            />

            <CustomTab
              label="Meeting Request"
              style={webStyle.tab3}
              disableRipple
            />

          </CustomTabs>

          {
            this.state.requestSuccess && (
              <QueryDialog
                // open={this.state.requestSuccess}
                open={true}
                onClose={this.closeSuccess}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                data-testId="queryDialog"
              >
                {!this.state.isEmployees ?
                  <>
                    <DialogContent>
                      <Typography style={webStyle.queryPopUpText}>
                        Your request has been sent to Biocon team
                      </Typography>
                    </DialogContent>
                    <DialogActions>
                      <SaveButton
                        onClick={this.closeSuccess}
                        variant="contained"
                        style={{ marginTop: '10px', width: '100px' }}
                        data-testId="dialogokbtn"
                      >
                        OK
                      </SaveButton>
                    </DialogActions>
                  </>
                  :
                  <div style={{ padding: '0px 60px' }}>
                    <DialogTitle style={{ paddingTop: '32px' }}>
                      <Typography style={webStyle.dialogTitle}>
                        Ticket Created Successfully
                      </Typography>
                      <IconBox onClick={this.goBackToTicketList}>
                        <CloseIcon style={{ fill: '#1A3B71', cursor: 'hand' }}>
                        </CloseIcon>
                      </IconBox>
                    </DialogTitle>
                    <DialogContent>
                      <Typography style={webStyle.ticketRaisedTicket}>
                        Do you want to create another ticket?
                      </Typography>
                    </DialogContent>
                    <DialogActions style={{ paddingBottom: '50px' }}>
                      <SaveButton
                        onClick={this.goBackToTicketList}
                        variant="outlined"
                        style={{ marginRight: '12px' }}
                        data-testId="dialogNobtn"
                      >
                        No
                      </SaveButton>
                      <SaveButton
                        onClick={this.goBackToCreateTicket}
                        variant="contained"
                        style={{ marginLeft: '12px' }}
                        data-testId="dialogYesbtn"
                      >
                        Yes
                      </SaveButton>
                    </DialogActions>
                  </div>
                }
              </QueryDialog>
            )
          }
          <TabPanel value={this.state.selectedTab} index={0}>
            {this.ProductQuery()}
          </TabPanel>

          <TabPanel value={this.state.selectedTab} index={1}>
            {this.sampleRequest()}
          </TabPanel>

          <TabPanel value={this.state.selectedTab} index={2}>
            {this.SetUpMeeting()}
          </TabPanel>
        </MainContainer>
        <AppFooter data-testId="appfooter" isLoading={this.state.isLoading} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {  
  detailTitle: {
    color: "rgba(33, 43, 54, 1)",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    fontSize: "18px",
    paddingBottom: "10px",
    marginLeft: "18px",
  },
  dropDown: {
    display: "flex",
    gap: "20px",
  },
  downArrow: {
    marginLeft: "10px"
  },
  errorIcon: {
    fill: "orange",
    marginRight: "30px",
    padding: '4px 0px 0px 3px',
    height: "18px",
    cursor: 'pointer'
  },
  allFieldError: {
    color: 'red',
    fontSize: '14px',
    fontFamily: 'SFProDisplay',
    margin: '5px',
  },
  allFieldErrorRobot: {
    color: 'red',
    fontSize: '14px',
    fontFamily: 'SFProDisplay',
    marginLeft: '-3px',
  },
  Tab1: {
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
  },
  tab3: {
    borderEndEndRadius: "8px",
    borderTopRightRadius: "8px",
  },
  privacyText: {
    color: "rgba(69, 158, 255, 1)",
    cursor: 'hand',
  },
  inputLabel: {
    fontSize: "15px",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    color: "#153E78",
    paddingBottom: "5px",
    marginRight: "5px"
  },
  inputLabels: {
    fontSize: "15px",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    color: "#153E78",
    marginRight: "5px",
    display: "flex",
    alignItems: "center"
  },
  selectPlaceholder: {
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "15px",
    color: 'rgba(4, 60, 160, 0.65)',
    opacity: 1,
    fontStyle: 'normal',
  },
  select: {
    height: "37px",
    marginTop: "5px",
    borderRadius: "8px",
  },
  checkedIcon: {
    borderRadius: 3,
    marginLeft: '0px',
    marginBottom: '4px',
    border: "1px solid black",
    width: 16,
    height: 16,
    backgroundColor: "white",
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='black'/%3E%3C/svg%3E\")",
    "input:hover ~ &": {},
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 3,
    marginLeft: '0px',
    marginBottom: '4px',
    width: 16,
    height: 16,
    backgroundColor: "white",
    border: "1px solid black",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "white",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  termsText: {
    fontStyle: "normal",
    color: 'rgba(106, 116, 165, 1)',
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "14px",
  },
  queryPopUpText: {
    fontWeight: 500,
    color: 'rgba(33, 43, 54, 1)',
    fontFamily: 'SFProDisplay',
    fontSize: '16px',
    margin: '0px 15px',
  },
  attachmentIcon: {
    marginBottom: '-4px',
    marginRight: '6px',
    fill: "rgba(4, 60, 116, 1)",
    marginLeft: '-6px',
    fontSize: '16px'
  },
  grids: {
    paddingBottom: '5px',
    paddingTop: '15px',
    // position: 'relative',
  },
  additionalMails: {
    fontSize: '13px',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    color: '#153E78',
    backgroundColor: '#ddd',
    padding: '5px 8px',
    marginRight: '10px',
    borderRadius: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '5px',
  },
  attachmentText: {
    fontSize: '13px',
    color: 'rgba(4, 60, 116, 1)',
    fontFamily: 'SFPRODisplay',
    fontWeight: 700,
    textDecoration: 'underline'
  },
  docName: {
    fontSize: '14px',
    color: 'rgba(4, 60, 116, 1)',
    fontFamily: 'SFPRODisplay',
    fontWeight: 700,
    margin: '0px 5px 0px 10px',
  },
  raiseRequestBackButton: {
    color: "rgb(4, 60, 116)",
    marginLeft: "20px",
    cursor: "pointer"
    // margin: "0px 3px 0px 3px",
  },
  dialogTitle: {
    margin: `0px 28px 20px 0`,
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: 1.33,
    color: '#043c74',
  },
  ticketRaisedTicket: {
    padding: '0px 0px 40px 0px',
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: 2,
    color: '#043c74',
  },
  cancelButton: {
    margin: '24px 12px 0 0px',
    padding: '4px 49px 4px 50px',
    borderRadius: '8px',
    border: 'solid 1px #003d78',
    backgroundColor: '#fff',
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: 2.29,
    color: '#003D78',
    boxShadow: 'none',
  },
  submitButton: {
    margin: '24px 0px 0 12px',
    padding: '4px 49px',
    borderRadius: '8px',
    backgroundColor: '#003d78',
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: 2.29,
    color: '#fff',
    boxShadow: 'none',
  },
  attachmentBox: {
    height: '136px',
    margin: '0px 25px 8px 39px',
    padding: '20px 162px 20px 163px',
    borderRadius: '9px',
    border: 'dashed 1px #6a74a5',
    backgroundColor: '#f5f5fa',
  },
  maxSizeText: {
    margin: '0px 73px 24px 40px',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: 1.67,
    color: '#6a74a5',
  },
  AttachmentNameText: {
    margin: '0px 73px 24px 0px',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: 1.67,
    color: '#6a74a5',
  },
  maxSizeErrorText: {
    margin: '0px 73px 24px 40px',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: 1.67,
    color: 'red',
  },
  errorMessageEmail: {
    color: "red",
    fontSize: "14px",
    fontFamily: "SFProDisplay",
    margin: "5px",
  }
};

const CustomTab: any = withStyles({
  root: {
    border: "1px solid rgba(4, 60, 116, 1)",
    width: "28%",
    color: "rgba(4, 60, 116, 1)",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    fontSize: "14px",
    textTransform: "none",
    height: '25px',
    "& .PrivateTabIndicator-colorSecondary-4": {
      display: "none",
    },
    "@media (max-width: 900px)": {
      width: "33%",
      fontSize: "12px",
      padding: 0
    },
  },
})(Tab);

const CustomTabs: any = withStyles({
  root: {
    maxHeight: "35px",
    marginLeft: "24px",
    "& .MuiTab-root": {
      minHeight: '35px',
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .Mui-selected": {
      background: "rgba(225, 239, 255, 1)",
    },
    "@media (max-width: 600px)": {
      maxHeight: "35px",
      marginLeft: "0px",
    },
  },
})(Tabs);

const MainContainer: any = withStyles({
  root: {
    padding: "24px 35px 24px 43px",
    "& .css-yk16xz-control": {
      height: '34px',
    },
    "& .css-1hb7zxy-IndicatorsContainer": {
      height: '34px',
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#BABED8",
      borderWidth: 1,
    },
    "& .MuiOutlinedInput-input": {
      padding: '10px 14px'
    },
    "@media (max-width: 600px)": {
      padding: "24px 24px 24px 24px",
      "& .MuiBox-root-8": {
        padding: "24px 0px 24px 0px",
      },
      "& ..MuiBox-root-30": {
        padding: "24px 0px 24px 0px",
      },
      "& .MuiTypography-body1": {
        marginLeft: "0px !important",
      },
      "& .MuiSvgIcon-root": {
        marginLeft: "-4px !important",
      },
    },
  },
})(Container);

const SaveButton: any = withStyles({
  root: {
    color: "rgba(255, 255, 255, 1)",
    // backgroundColor: "rgba(4, 60, 116, 1)",
    height: "36px",
    width: 120,
    borderRadius: "8px",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    fontSize: "16px",
    marginBottom: "5px",
    marginLeft: "8px",
    boxShadow: "none",
    textTransform: "none",
    backgroundColor: 'rgba(4, 60, 116, 1)',
    "&.MuiButton-contained": {
      backgroundColor: 'rgba(4, 60, 116, 1)',
      "&:hover": {
        backgroundColor: 'rgba(4, 60, 116, 1)',
      }
    },
    "&.MuiButton-outlined": {
      border: '1px solid rgba(4, 60, 116, 1)',
      color: 'rgba(4, 60, 116, 1)',
      padding: '5px 15px',
      backgroundColor: 'transparent',
      "&:hover": {
        backgroundColor: 'transparent',
      }
    },
    "&:hover": {
      backgroundColor: "rgba(4, 60, 116, 1)",
      boxShadow: "none",
    },
    "@media (max-width: 600px)": {
      width: "100%",
      marginLeft: 0,
    },
  },
})(Button);

const InputField: any = withStyles({
  root: {
    background: "inherite",
    margin: "5px 0px 0px 0px",
    "& ::placeholder": {
      color: 'rgba(4, 60, 160, 0.65)',
      fontFamily: 'SFProDisplay',
      fontWeight: 400,
      fontSize: '15px',
      opacity: 1,
    },
    "& .MuiInputBase-input": {
      height: '15px',
    },
    "& .MuiOutlinedInput-input": {
      padding: '11px 14px'
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(214, 214, 214, 1)",
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(218, 224, 241, 1)",
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: "SFProDisplay",
      fontWeight: 400,
      backgroundColor: "rgba(255, 255, 255, 1)",
      fontSize: "15px",
      color: 'rgba(106, 116, 165, 1)',
      "& fieldset": {
        height: 40,
        fontSize: "15px",
        borderColor: "rgba(214, 214, 214, 1)",
        borderRadius: "8px",
        borderWidth: 1,
        color: 'rgba(106, 116, 165, 1)',
      },
      ".Mui-focused": {
        borderColor: "red",
      },
      "& .MuiInputBase-input.Mui-disabled": {
        backgroundColor: '#F0F0FA',
        color: 'rgba(4, 60, 116, 1)',
        borderRadius: "8px",
        height: 15,
      },
      "& .MuiFormHelperText-contained": {
        marginLeft: "0px !important",
      },
      "& .MuiAutocomplete-input": {
        padding: '6px 4px'
      }
    },
  },
})(TextField);

const CheckBoxLabel: any = withStyles({
  root: {
    alignItems: "flex-start",
    marginRight: 0,
    "& .MuiTypography-body1": {
      fontFamily: "SFProDisplay",
      fontWeight: 500,
      fontSize: "18px",
      color: "rgba(58, 63, 99, 1)",
    },
  },
})(FormControlLabel);

const TabPanel: any = withStyles({
  container: {
    padding: '10px 24px 10px 24px',
    marginTop: '30px',
    "@media (max-width: 600px)": {
      padding: '10px 0px 10px 0px'
    }
  }
})(CustomTabPanel);

const QueryDialog: any = withStyles({
  root: {
    "& .MuiDialog-paperScrollPaper": {
      minWidth: 'fit-content',
      padding: '0px 10px 0px 10px'
    },
    "& .MuiDialogContent-root": {
      paddingLeft: '2px',
      textAlign: 'center'
    },
    "& .MuiDialogActions-root": {
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px',
      paddingBottom: '8px',
    },
    "& .WithStyles\(ForwardRef\(Button\)\)-root-10": {
      "@media (maxWidth: 600px)": {
        width: 300
      }
    },
    "& .MuiTypography-h6": {
      display: 'flex',
      justifyContent: 'space-between',
    }
  }
})(Dialog);

const MUiDatePicker: any = withStyles({
  root: {
    boxSizing: 'content-box',
    margin: "4px 0px 5px 0px",
    width: '100%',
    "& .MuiInputBase-root": {
      padding: "18.5px 14px",
      border: '1px solid rgba(218, 224, 241, 1)',
      width: '100%',
      height: '45px',
      borderRadius: '8px',
      paddingRight: 0,
      color: 'rgba(106, 116, 165, 1)',
      fontSize: "14px",
      fontWeight: 500,
    },
    "& .MuiInput-underline:before": {
      display: 'none',
      height: '40px',
    },
    "& .MuiInput-underline:after": {
      display: 'none',
      height: '40px',
    },
    "& .MuiOutlinedInput-root": {
      fontWeight: 500,
      fontFamily: "SFProDisplay",
      backgroundColor: "rgba(255, 255, 255, 1)",
      color: 'rgba(106, 116, 165, 1)',
      fontSize: "16px",
      boxSizing: 'content-box',
      height: '40px',
    },
    "& .MuiInputBase-root.Mui-disabled": {
      background: '#F0F0FA',
      color: 'rgba(4, 60, 116, 1)',
      borderRadius: '8px',
      borderColor: '#F0F0FA'
    },
  }
})(KeyboardDatePicker)

const MultiSelect: any = withStyles({
  root: {
    "& .MuiInputBase-root .MuiSelect-nativeInput": {
      border: 0,
      opacity: 1,
      top: 0
    }
  }
})(Select)

const TextArea: any = withStyles({
  root: {
    width: '100%',
    paddingTop: '10px',
    "& .MuiOutlinedInput-root": {
      fontWeight: 400,
      fontFamily: "SFProDisplay",
      backgroundColor: "rgba(255, 255, 255, 1)",
      fontSize: "16px",
      color: 'rgba(106, 116, 165, 1)',
      "& fieldset": {
        fontSize: "16px",
        borderColor: "rgba(214, 214, 214, 1)",
        borderRadius: "8px",
        borderWidth: 1,
        fontWeight: 400,
        color: 'rgba(106, 116, 165, 1)',
      },
      "& ::placeholder": {
        color: 'rgba(4, 60, 160, 0.65)',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '16px',
        opacity: 1,
      },
    },
  }
})(TextField);

const ToolTips: any = withStyles({
  tooltip: {
    color: 'rgba(255, 191, 0, 1)',
    fontWeight: 400,
    fontFamily: 'SFProDisplay',
    fontSize: '14px',
    backgroundColor: "rgba(255, 255, 255, 1)",
    boxShadow: "3px 4px 25px 2px lightGray",
    "& .MuiTooltip-arrow": {
      color: "rgba(255, 255, 255, 1)",
    }
  }
})(Tooltip);

const ToolTipsWithBlueText: any = withStyles({
  tooltip: {
    color: 'rgba(4, 60, 116, 1)',
    fontWeight: 500,
    fontFamily: 'SFProDisplay',
    fontSize: '15px',
    padding: '10px 5px',
    backgroundColor: "rgba(255, 255, 255, 1)",
    boxShadow: "3px 4px 25px 2px lightGray",
    "& .MuiTooltip-arrow": {
      color: "rgba(255, 255, 255, 1)",
    }
  }
})(Tooltip);

const IconBox: any = withStyles({
  root: {
    background: '#DAE0EC',
    height: '24px',
    width: '26px',
    borderRadius: '50%',
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: '15px',
    padding: '0px 0px 0px 0px',
    top: '15px',
    "& .MuiSvgIcon-root": {
      height: '16px',
      width: '16px'
    },
  }
})(Box);

const AttachmentDialog: any = withStyles({
  root: {
    "& .MuiDialogTitle-root": {
      padding: '18px 0px 0px 0px'
    },
    "& .MuiDialog-paperScrollPaper": {
      padding: '16px 16px 35px 40px',
      borderRadius: '12px',
    },
    "& .MuiDialogContent-root": {
      padding: '0px 40px 0px 0px',
    },
    "& .MuiDialog-paperWidthSm": {
      minWidth: '605px',
    },
    "& .MuiDialogActions-root": {
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px',
      paddingBottom: '30px',
    },
    "& .WithStyles\(ForwardRef\(Button\)\)-root-10": {
      "@media (maxWidth: 600px)": {
        width: 600
      }
    },
    "& .MuiTypography-h6": {
      display: 'flex',
      justifyContent: 'space-between',
    }
  }
})(Dialog);

const styles = () => ({
  selectPlaceholder: {
    backgroundColor: '#fff !important',
    '&.Mui-disabled': {
      opacity: 1
    }
  },
  privacyPolicyRes: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 700px)': {
      alignItems: 'flex-start',
    },
  }
})

const DocTooltip: any = withStyles({
  tooltip: {
    color: 'rgba(255, 191, 0, 1)',
    fontWeight: 400,
    fontFamily: 'SFProDisplay',
    fontSize: '14px',
    backgroundColor: "rgba(255, 255, 255, 1)",
    boxShadow: "3px 4px 25px 2px lightGray",
    "& .MuiTooltip-arrow": {
      color: "rgba(255, 255, 255, 1)",
    },
  }
})(Tooltip);

export default withStyles(styles)(CustomerProductQuery)

// Customizable Area End
