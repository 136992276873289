import {
  apiCall,
  errorNotification,
  getHeaders,
  successNotification,
} from "../../../components/src/common";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
export const WebconfigJSON = require("./config.web.js");
export interface Props {
  classes?: any;
  navigation: any;
  id: string;
  loading: boolean;
  history: any;
}

interface S {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  retypePassword: string;
  enablePasswordField: true;
  enableReTypePasswordField: true;
  error: any;
  phone: string;
  welcomeText: string;
  startedText: string;
  serviseText: string;
  labelTitle: string;
  SubHeading: string;
  labelPassword: string;
  confirmPass: string;
  AccountLabel: string;
  loginText: string;
  labelFirstName: string;
  labelLastName: string;
  labelEmail: string;
  showPassword: boolean;
  confirmShowPassword: boolean;
  IscompanyDetails: boolean;
  labelCompanyName: string;
  companyName: string;
  labelCountry: string;
  country: string;
  phoneNum: string;
  phoneCountryCode: string;
  privacyPolicyLabel: string;
  privacyPolicyText: string;
  termslabelText: string;
  termsLabel: string;
  isTermsCondition: boolean;
  isLoading: boolean;
  termsPopUp: boolean;
  isConfirmation: boolean;
  companyId: number;
  companyList: any[];
  isOtherCompany: boolean;
  companyIsFocued: boolean;
  isPasswordError: boolean;
  isCompanySelected: boolean;
  companyList2: any[];
  loginImage: string;
  imageLoading: boolean;
  termsPopUpDetailsText: string;
  termsPopUpDetailsText2: string;
  AcceptButtonText: string;
  thanksTextAfterSignup: string;
  OkButtonText: string;
  countryCodePlaceholder: string;
  phoneNumberPlaceholder: string;
  countryPlaceholder: string;
  andText: string;
  backBtnText: string;
  newUserSignOption: string;
  signUpSubHeader: string;
  firstNamePlaceholder: string;
  lastnamePlaceholder: string;
  emailIdPlaceholder: string;
  strongPassPlaceholder: string;
  togglePasswordlabel: string;
  confirmPassPlaceholder: string;
  passwordValidationtext: string;
  SignUpBtnText: string;
  retypePasswordFieldName: string;
  confirmPassFieldId: string;
  createPassFieldText: string;
  passwordFieldLabel: string;
  emailFieldLabel: string;
  emailFieldID: string;
  lastNameFieldLabel: string;
  firstNameFieldID: string;
  countryFieldId: string;
  phoneFieldId: string;
  phoneNumberFieldId: string;
  countryCodeFieldID: string;
  phoneNumFieldHTML: string;
  selectCompanyPlaceholder: string;
  companynameHTML: string;
}

interface SS {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  retypePassword: string;
  enablePasswordField: true;
  enableReTypePasswordField: true;
  error: any;
}

export default class SignupWebController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  createAccountApiCallId: any;
  createCustomerProfileApiCallId: any;
  validationApiCallId: string = "";
  emailReg: RegExp;
  getCompanyListApiCallId: any;
  LoginImagesApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.receive = this.receive.bind(this);
    this.setInitialCredentials = this.setInitialCredentials.bind(this);
    this.createAccount = this.createAccount.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    this.handleTermsConditionClick = this.handleTermsConditionClick.bind(this);
    this.setTermsandCondition = this.setTermsandCondition.bind(this);
    this.handleProceedClick = this.handleProceedClick.bind(this);
    this.handlePhoneCountryCode = this.handlePhoneCountryCode.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleCompanySelect = this.handleCompanySelect.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleConfirmShowPassword = this.handleConfirmShowPassword.bind(this);
    this.setCompaniesData = this.setCompaniesData.bind(this);
    this.setDataAfterAPICall = this.setDataAfterAPICall.bind(this);
    this.setAuthToken = this.setAuthToken.bind(this);
    this.setErrorForCreateAccount = this.setErrorForCreateAccount.bind(this);
    this.setLoginImages = this.setLoginImages.bind(this);
    this.getLoginImages = this.getLoginImages.bind(this);
    this.onConfirmationClick = this.onConfirmationClick.bind(this);
    this.gotoCompanyDetails = this.gotoCompanyDetails.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleCompanyRemoveFocus = this.handleCompanyRemoveFocus.bind(this);
    this.goToHome = this.goToHome.bind(this);
    this.updateOthersValue = this.updateOthersValue.bind(this);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      retypePassword: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      error: {},
      phone: "",
      welcomeText: WebconfigJSON.welcomeText,
      startedText: WebconfigJSON.startedText,
      serviseText: WebconfigJSON.serviseText,
      labelTitle: WebconfigJSON.labelTitle,
      SubHeading: WebconfigJSON.SubHeading,
      labelPassword: WebconfigJSON.labelPassword,
      confirmPass: WebconfigJSON.confirmPass,
      AccountLabel: WebconfigJSON.AccountLabel,
      loginText: WebconfigJSON.loginText,
      labelFirstName: WebconfigJSON.labelFirstName,
      labelLastName: WebconfigJSON.labelLastName,
      labelEmail: WebconfigJSON.labelEmail,
      showPassword: false,
      confirmShowPassword: false,
      IscompanyDetails: false,
      labelCompanyName: WebconfigJSON.companyName,
      companyName: "",
      country: "",
      phoneCountryCode: "",
      labelCountry: WebconfigJSON.country,
      phoneNum: WebconfigJSON.phoneNum,
      privacyPolicyLabel: WebconfigJSON.privacyPolicyLabel,
      privacyPolicyText: WebconfigJSON.privacyPolicyText,
      termslabelText: WebconfigJSON.termslabelText,
      termsLabel: WebconfigJSON.termsLabel,
      isTermsCondition: false,
      isLoading: false,
      termsPopUp: false,
      isConfirmation: false,
      companyId: 0,
      companyList: [],
      isOtherCompany: false,
      companyIsFocued: false,
      isPasswordError: false,
      isCompanySelected: false,
      companyList2: [],
      loginImage: "",
      imageLoading: false,
      termsPopUpDetailsText: WebconfigJSON.termsPopUpDetailsText,
      termsPopUpDetailsText2: WebconfigJSON.termsPopUpDetailsText2,
      AcceptButtonText: WebconfigJSON.AcceptButtonText,
      thanksTextAfterSignup: WebconfigJSON.thanksTextAfterSignup,
      OkButtonText: WebconfigJSON.OkButtonText,
      countryCodePlaceholder: WebconfigJSON.countryCodePlaceholder,
      phoneNumberPlaceholder: WebconfigJSON.phoneNumberPlaceholder,
      countryPlaceholder: WebconfigJSON.countryPlaceholder,
      andText: WebconfigJSON.andText,
      backBtnText: WebconfigJSON.backBtnText,
      newUserSignOption: WebconfigJSON.newUserSignOption,
      signUpSubHeader: WebconfigJSON.signUpSubHeader,
      firstNamePlaceholder: WebconfigJSON.firstNamePlaceholder,
      lastnamePlaceholder: WebconfigJSON.lastnamePlaceholder,
      emailIdPlaceholder: WebconfigJSON.emailIdPlaceholder,
      strongPassPlaceholder: WebconfigJSON.strongPassPlaceholder,
      togglePasswordlabel: WebconfigJSON.togglePasswordlabel,
      confirmPassPlaceholder: WebconfigJSON.confirmPassPlaceholder,
      passwordValidationtext: WebconfigJSON.passwordValidationtext,
      SignUpBtnText: WebconfigJSON.SignUpBtnText,
      retypePasswordFieldName: WebconfigJSON.retypePasswordFieldName,
      confirmPassFieldId: WebconfigJSON.confirmPassFieldId,
      createPassFieldText: WebconfigJSON.createPassFieldText,
      passwordFieldLabel: WebconfigJSON.passwordFieldLabel,
      emailFieldLabel: WebconfigJSON.emailFieldLabel,
      emailFieldID: WebconfigJSON.emailFieldID,
      lastNameFieldLabel: WebconfigJSON.lastNameFieldLabel,
      firstNameFieldID: WebconfigJSON.firstNameFieldID,
      countryFieldId: WebconfigJSON.countryFieldId,
      phoneFieldId: WebconfigJSON.phoneFieldId,
      phoneNumberFieldId: WebconfigJSON.phoneNumberFieldId,
      countryCodeFieldID: WebconfigJSON.countryCodeFieldID,
      phoneNumFieldHTML: WebconfigJSON.phoneNumFieldHTML,
      selectCompanyPlaceholder: WebconfigJSON.selectCompanyPlaceholder,
      companynameHTML: WebconfigJSON.companynameHTML,
      // Customizable Area End
    };

    this.emailReg = /\//;
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  /* START --------------------------------------------------------------  FirstName Textfield Props */
  txtInputFirstNamePrpos = {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ firstName: event.target.value });
    },
  };
  /* END --------------------------------------------------------------  FirstName Textfield Props */

  /* START --------------------------------------------------------------  LastName Textfield Props */
  txtInputLastNamePrpos = {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ lastName: event.target.value })
    },
  };
  /* END --------------------------------------------------------------  LastName Textfield Props */

  /* START --------------------------------------------------------------  Password Textfield Props */
  txtInputPasswordWebProps = {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      this.setState({ password: value });
      if (value === this.state.retypePassword) {
        this.setState({ error: { ...this.state.error, confirmPassword: "" } });
      }
    },
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
      const { value } = event.target;
      this.setState({ password: value });
    },
    name: "password",
    autoComplete: "none",
  };
  /* END --------------------------------------------------------------  Password Textfield Props */

  /* START --------------------------------------------------------------  ReenterPassword Textfield Props */
  txtInputReenterPassowrdWebProps = {
    onChange: (event: React.FocusEvent<HTMLInputElement>) => {
      const { value } = event.target;
      this.setState({ retypePassword: value });
      if (value === this.state.password) {
        this.setState({ error: { ...this.state.error, confirmPassword: "" } });
      }
    },
  };
  /* END --------------------------------------------------------------  ReenterPassword Textfield Props */

  /* START -------------------------------------------------------------- componentDidMount */
  async componentDidMount() {
    super.componentDidMount();
    this.getCompanyDropdown();
    this.getLoginImages();
    this.setInitialCredentials();
  }
  /* END --------------------------------------------------------------  componentDidMount*/

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.createAccountApiCallId) {
          this.createAccountAPIData(responseJson, errorReponse);
        }

        if (apiRequestCallId === this.getCompanyListApiCallId) {
          this.setCompaniesData(responseJson)
        }

        if (apiRequestCallId === this.LoginImagesApiCallId) {
          this.setLoginImages(responseJson)
        }
      }
    }
    // Customizable Area End
  }

  /* END --------------------------------------------------------------  Field Validators */

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccountAPIData = (responseJson: any, errorReponse: any) => {
    this.setState({isLoading: false,imageLoading: false});
      if (!responseJson.error && !responseJson.errors) {
        this.setDataAfterAPICall(responseJson)
      } else if (responseJson.error) {
        errorNotification(responseJson.error);
      } else if (responseJson.errors) {
        this.setErrorForCreateAccount(responseJson)
      }
      this.parseApiCatchErrorResponse(errorReponse);
  }

  setLoginImages = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        loginImage: responseJson?.data?.attributes?.media
      })
      setTimeout(()=> {
        this.setState({imageLoading: false, companyIsFocued: !this.state.companyIsFocued})
      }, 1000)
    } else {
      this.setState({imageLoading: false, companyIsFocued: !this.state.companyIsFocued,})
      errorNotification('No Image found');
    }
  }

  setErrorForCreateAccount = (responseJson: any) => {
    responseJson.errors.map((obj: any) => {
      for (const key in obj) {
        if (key === 'password') {
          this.setState({
            isPasswordError: true
          })
        } else {
          this.setState({
            isPasswordError: false
          })
          errorNotification(`${obj[key]}`);
        }
      }
    });
  }

  setDataAfterAPICall = (responseJson: any) => {
    successNotification("Account Created Successfully");
    this.setState({isConfirmation: true});
    this.createCustomerProfile(responseJson.meta.token);
  }

  setCompaniesData = (responseJson: any) => {
    if (responseJson.all_companies) {
      const companies = responseJson.all_companies.map((company: any) => {
        return {
          id: company.id,
          label: company.customer_name
        }
      });
      this.setState({
        companyList: companies
      })
    }
  }

  setAuthToken = (message: any) => {
    const otpAuthTkn = message.getData(getName(MessageEnum.AuthTokenDataMessage));
    if (otpAuthTkn && otpAuthTkn.length > 0) {
      runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
    }
  }

  handleProceedClick(): boolean {
    if (this.isStringNullOrBlank(this.state.firstName) || this.isStringNullOrBlank(this.state.lastName) || this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) || this.isStringNullOrBlank(this.state.retypePassword)) {
      errorNotification(configJSON.errorAllFieldsAreMandatory);
      return false;
    }

    if (!RegExp(/^(?=.*?[A-Z])(?=.*?\d)(?=.*?[#?!@$%^&*-]).{8,}$/).test(this.state.password)) {
      this.setState({isPasswordError: true})
      return false;
    }
    this.setState({IscompanyDetails: true,});
    return true;
  }

  setCompanyName = (attributes: any) => {
    attributes.personal_detail_id = this.state.companyId
      if (typeof this.state.companyName === "object") {
        // @ts-ignore
        attributes.company_name = this.state.companyName?.label
      } else {
        attributes.company_name = this.state.companyName
      }
  }

  createAccount(): boolean {
    let regCheck = /^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ ;
    if (this.state.country === "") {
      errorNotification('Please select valid country name');
      return false;
    }

    if (!this.state.isTermsCondition) {
      errorNotification(WebconfigJSON.termsConditionError);
      return false;
    }

    if (regCheck.test(this.state.email) === false) {
      errorNotification(configJSON.errorEmailNotValid)
      return false;
    }

    if (this.state.password !== this.state.retypePassword) {
      errorNotification(configJSON.errorBothPasswordsNotSame);
      return false;
    }

    if (!RegExp(/^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/).test(this.state.password)) {
      this.setState({isPasswordError: true})
      return false;
    } else {
      this.setState({isPasswordError: false})
    }

    if (this.state.companyId === 0 || typeof this.state.companyName !== "object" && (this.state?.companyName?.toLowerCase() === 'others' || this.state?.companyName?.toLowerCase() === 'other')) {
      if (!this.state.companyName) {
        errorNotification('Please select valid company name');
        return false;
      }
    }
    this.setState({imageLoading : true})
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const attributes: any = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      country_code: this.state.phone ? this.state.phoneCountryCode : "",
      phone_number: this.state.phone,
      country: this.state.country,
    };

    if (this.state.companyId > 0 || this.state.companyId == 0) {
      this.setCompanyName(attributes);
    } else {
      attributes.company_name = this.state.companyName
    }

    const data = {
      attributes,
      type: "email_account",
    };

    const httpBody = {
      data,
    };

    const createAccountMessage = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.apiMethodTypeAddDetail,
      url: configJSON.accountsAPiEndPoint,
    });

    this.createAccountApiCallId = createAccountMessage.messageId;
    runEngine.sendMessage(createAccountMessage.id, createAccountMessage);
    return true;
  }

  onConfirmationClick = () => {
    this.setState({
      isConfirmation: false
    });
    this.props.navigation.navigate("Login");
  }

  createCustomerProfile(token: string) {
    const header = {
      token: token,
      "content-type": "application/json",
    };

    const httpBody = {
      address: this.state.country,
      department_id: 0,
      designation: "",
      landline: "",
      area_of_business: "",
      subsidiary_name: "",
      alternate_email: ""
    }

    const createCustomerProfile = apiCall({
      header,
      httpBody: httpBody,
      url: WebconfigJSON.createCustomerProfileApiEndpoint,
      httpMethod: WebconfigJSON.createCustomerProfileApiMethod,
    });

    runEngine.sendMessage(createCustomerProfile.id, createCustomerProfile);
  }

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handleConfirmShowPassword = () => {
    this.setState({
      confirmShowPassword: !this.state.confirmShowPassword,
    });
  };

  gotoCompanyDetails = () => {
    if (!this.state.IscompanyDetails) {
      this.setState({
        IscompanyDetails: !this.state.IscompanyDetails,
      });
    }
  };

  handleCompanyChange = (event: any, value: any) => {
    let bool;
    if (event.id === -1) {
      bool = true
    } else {
      bool = false
    }
    this.setState({
      companyId: event.id,
      isOtherCompany: bool,
      companyName: value,
    })
    let array = [
      {
        id: event.id,
        customer_name: value.label
      }
    ]
    this.setState({
      companyIsFocued: !this.state.companyIsFocued,
      companyList2: array
    })
  }

  handleCompanySelect = () => {
    this.setState({ companyIsFocued: true })
  }

  handleCompanyRemoveFocus = () => {
    this.setState({ companyIsFocued: false });
  }

  handleChange = (prop: any) => (event: any) => {
    if (prop === 'firstName' || prop === 'lastName') {
      const regEx = /^[a-zA-Z][a-zA-Z\s]*$/;
      if (event.target.value.length === 0) {
        this.setState({ ...this.state, [prop]: event.target.value });
        return true;
      }
      if (event.target.value.match(regEx) && event.target.value.length < 16) {
        this.setState({ ...this.state, [prop]: event.target.value });
        return true;
      }
      else {
        return false;
      }
    } else if (prop === 'phone') {
      const regEx2 = /^[0-9\b]+$/;
      if (event.target.value.length === 0) {
        this.setState({ ...this.state, [prop]: event.target.value });
        return true;
      }
      if (event.target.value.match(regEx2)) {
        this.setState({ ...this.state, [prop]: event.target.value });
        return true;
      } else {
        return false;
      }
    } else {
      this.setState({ ...this.state, [prop]: event.target.value });
    }
  };

  handleTermsConditionClick = () => {
    if (!this.state.isTermsCondition) {
      this.setState({
        termsPopUp: !this.state.termsPopUp,
      })
    }
    else {
      this.setState({
        isTermsCondition: !this.state.isTermsCondition,
      });
    }
  };

  setTermsandCondition = () => {
    this.setState({
      isTermsCondition: !this.state.isTermsCondition,
    });
    this.setState({
      termsPopUp: !this.state.termsPopUp,
    })
  }

  handlePhoneCountryCode(event: any, value: any, data: any) {
    const filtered = data.find((country: any) => country.id === event.value)
    if (value) {
      this.setState({
        phoneCountryCode: event.value,
        country: filtered?.value
      });
    }
  }

  handleBackClick() {
    this.setState({
      IscompanyDetails: false,
    });
  }

  getCompanyDropdown() {

    const getCompanies = apiCall({
      header: getHeaders({}),
      httpMethod: WebconfigJSON.getCompantListApiMethod,
      url: WebconfigJSON.getCompanyListApiEndpoint,
      httpBody: {}
    });

    this.getCompanyListApiCallId = getCompanies.messageId;
    runEngine.sendMessage(getCompanies.id, getCompanies);
  }

  goToHome = () => {
    this.props.navigation.navigate("Home");
  }

  updateOthersValue = (value: string) => {
    this.setState({
      companyName: value
    });
  }

  getLoginImages() {
    this.setState({
      imageLoading: true,
      companyIsFocued: !this.state.companyIsFocued,
    })

    const signupImages = apiCall({
      httpBody: {},
      header: getHeaders({}),
      url: WebconfigJSON.loginImagesAPIEndPoint,
      httpMethod: WebconfigJSON.getCompantListApiMethod,
    });

    this.LoginImagesApiCallId = signupImages.messageId;
    runEngine.sendMessage(signupImages.id, signupImages);
    return true;
  }

  onSignUpRequest = (e: any) => {
    e.preventDefault();
    this.createAccount()
    this.handleProceedClick();
  }

  setInitialCredentials = () => {
    this.setState({
      email: "",
      password: "",
      retypePassword: "",
    })
  }
}
