import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import { errorNotification, successNotification } from '../../../components/src/common'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  passwordSchema: any;
  showPassword: any;
  confirmShowPassword: any;
  token: any;
  resetPassword: any;
  loginImage: any;
  imageLoading: boolean;
  new_password: string;
  confirmed_password: string;
  isPasswordError: boolean;
  resetPasswdHeaderText: string;
  resetPasswdSubHeaderHeaderText: string;
  NewPasswordInputLabel: string;
  passwordPlaceholderText: string;
  passwordRules: string;
  confirmPasswordLabel: string;
  confirmPlaceholderText: string;
  resetButtonText: string;
  PasswordIsRequiredText: string;
  NewPasswordIsRequiredText: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  requestPasswordAPICallId: any;

  //Properties from config
  LoginImagesApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    
    // Customizable Area Start
    this.getLoginImages = this.getLoginImages.bind(this);
    this.resetPasswordFun = this.resetPasswordFun.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleConfirmShowPassword = this.handleConfirmShowPassword.bind(this);
    this.setLoginImages = this.setLoginImages.bind(this);
    this.goToLogin = this.goToLogin.bind(this);
    
    //email schema
    let passwordSchema = Yup.object().shape({
      confirmed_password: Yup.string().required(configJSON.NewPasswordIsRequiredText),
      new_password: Yup.string().required(configJSON.PasswordIsRequiredText),
    });

    this.state = {
      showPassword: false,
      confirmShowPassword: false,
      passwordSchema: passwordSchema,
      token: "",
      resetPassword: "",
      loginImage: "",
      imageLoading: false,
      new_password: "",
      confirmed_password: "",
      isPasswordError: false,
      resetPasswdHeaderText: configJSON.resetPasswdHeaderText,
      resetPasswdSubHeaderHeaderText: configJSON.resetPasswdSubHeaderHeaderText,
      NewPasswordInputLabel: configJSON.NewPasswordInputLabel,
      passwordPlaceholderText: configJSON.passwordPlaceholderText,
      passwordRules: configJSON.passwordRules,
      confirmPasswordLabel: configJSON.confirmPasswordLabel,
      confirmPlaceholderText: configJSON.confirmPlaceholderText,
      resetButtonText: configJSON.resetButtonText,
      PasswordIsRequiredText: configJSON.PasswordIsRequiredText,
      NewPasswordIsRequiredText: configJSON.NewPasswordIsRequiredText,
    };
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    this.getLoginImages();
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    //Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.requestPasswordAPICallId !== null && this.requestPasswordAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.errors || responseJson.error) {
        console.log(responseJson.error, 'responseJson.error')
        this.setState({imageLoading: false})
        if (responseJson.errors) {
          errorNotification(responseJson.errors[0])
        } else {
          errorNotification(responseJson.error)
        }
      } else {
        successNotification(responseJson.message);
        this.setState({imageLoading: false})
        this.goToLogin()
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.LoginImagesApiCallId && this.LoginImagesApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        this.setLoginImages(responseJson)
    }
    //Customizable Area End
  }

  //Customizable Area Start
  handleChange(e: any) {
    this.setState({ resetPassword: e.target.value })
  }
  
  setLoginImages = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({loginImage: responseJson?.data?.attributes?.media})
      setTimeout(()=> {
        this.setState({imageLoading: false})
      }, 1000)
    } else {
      this.setState({imageLoading: false})
      errorNotification('No Image found');
    }
  }

  handleConfirmShowPassword = () => {
    this.setState({ confirmShowPassword: !this.state.confirmShowPassword, });
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword, });
  };

  resetPasswordFun(values: {new_password: string, confirmed_password: string}) {
    this.setState({isPasswordError: false});
    const passwordReg = new RegExp(/^(?=.*?[A-Z])(?=.*?\d)(?=.*?[#?!@$%^&*-]).{8,}$/);
    if (!passwordReg.test(values.new_password)) {
      this.setState({isPasswordError: true});
      return false;
    }
    if (values.new_password !== values.confirmed_password) {
      errorNotification(configJSON.PasswordIsSameText);
      return false;
    }
    let reset_password_token = (new URLSearchParams(window.location.search)).get("token")
    this.setState({imageLoading: true})
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    let httpBody;
    if(reset_password_token) {
      httpBody = { 
        confirmed_password: values.confirmed_password,
        new_password: values.new_password,
        reset_password_token: reset_password_token,
      }
    } else {
      httpBody = { 
        confirmed_password: values.confirmed_password,
        new_password: values.new_password,
        reset_password_token: reset_password_token,
        account_id: parseInt(localStorage.getItem("customer_profile_id") || ""), 
      };
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.requestPasswordAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPasswordAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToLogin() {
    this.props.navigation.navigate(configJSON.loginText)
  }

  getLoginImages() {
    this.setState({imageLoading: true})
    const headers = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.LoginImagesApiCallId = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginImagesAPIEndPoint
    );    
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  //Customizable Area End
}
