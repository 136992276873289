import React from "react";
import { View } from "react-native";
import Select from "react-select";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

const configJSON = require("./config");

interface Props {
  // Customizable Area Start
  navigation: any;
  style: any;
  id: string;
  disable: boolean;
  allowPropChange: boolean;
  value: string;
  placeHolder: string;
  onChange?: (event: any, value: any, dataSource?: any[]) => void;
  isOnlyCountryName?: boolean;
  onFocus?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  dataSource: any[];
  countryCodeSelected: string;
  mobileNo: string;
  token: string;
  placeHolder: string;
  disable: boolean;
  label: any;
  countryData: any[];
  // Customizable Area End
}

interface SS {}

export default class CountryCodeSelector extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  countryCodeApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);

    // Customizable Area Start


    // const colourStyles = {
    //   control: (styles: any) => ({ ...styles, backgroundColor: 'white' }),
    //   option: (styles: any) => {
    //     return {
    //       ...styles,
    //       backgroundColor: 'red',
    //       color: '#FFF',
    //     };
    //   },
    // };

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);
    this.handleChange = this.handleChange.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      dataSource: [],
      countryCodeSelected: "",
      mobileNo: "",
      token: "",
      placeHolder: configJSON.countryPlaceHolder,
      disable: this.props.disable,
      label: null,
      countryData: [],
    };
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    var index = -1;
    var iIndex = -1;

    this.state.dataSource.forEach((item) => {
      iIndex += 1;
      if (item.value === this.props.value) {
        index = iIndex;
        return;
      }
    });
    const customStyles = {

      menu: (provided: any, state: any) => ({
        ...provided,
        // color: 'red',
        // width: state.selectProps.width,
      }),

      singleValue: (provided: any, state: any) => {
        // const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
        const color = state.isDisabled ? 'rgba(106, 116, 165, 1)' : 'black';
        return { ...provided, transition, color };
      },

      control: (provided: any, state: any) => ({
          ...provided,
          border: '1px solid #dae0f1',
          boxShadow: 'none',
          backgroundColor : state.isDisabled ? '#F1F4FA' : 'white',
          borderRadius : '8px',
          fontSize : '16px',
          fontFamily:'SFProDisplay',
          fontWeight: 400,
          '&:hover': {
              border: '1px solid black',
          }
      
        // const opacity = state.isDisabled ? 1 : 1;
        // const transition = 'opacity 300ms';
        // const backgroundColor = state.isDisabled ? '#F1F4FA' : 'white';
        // // const borderColor = state.isActive ? 'red' : '#dae0f1';
        // const borderRadius = '8px';
        // const fontSize = '16px';
        // const border = 0;
        // const outline = '1px solid #dae0f1';
        // return { ...provided, transition, opacity, backgroundColor, borderRadius, fontSize,border,outline };
      })
    }

    return (
      <View style={{ marginBottom: 10, zIndex: 99 }}>
        <Select
          // style={this.props.style}
          styles={customStyles}
          options={this.state.dataSource}
          onFocus={this.props.onFocus}
          formatOptionLabel={data => (
            <>
            {
            !this.props.isOnlyCountryName ? this.flagemojiToPNG(data.emojiFlag) : null}  
            {data.label}
            </>
          )}
          placeholder={this.props.placeHolder}
          onChange={(value: any, actionMeta: any) => {
            if (this.props.onChange) {
              this.props.onChange(value, actionMeta, this.state.countryData);
              return;
            } else {
              this.handleChange(value);
              return;
            }
          }
          }
          value={index >= 0 ? this.state.dataSource[index] : null}
          isDisabled={this.props.disable}
        />
      </View>
    );
    // Customizable Area End
  }

  // Customizable Area Start
  handleChange(item: any) {
    this.setState({ label: item.label });
    const msg = new Message(getName(MessageEnum.CountryCodeMessage));
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), item.value);
    runEngine.sendMessage(getName(MessageEnum.CountryCodeMessage), msg);
  }

  async componentDidMount() {
    this.makeRemoteRequest();
  }

  countryCodesToDropDown = (data: any) => {
    return data.map((item: any) => ({
      label: this.props.isOnlyCountryName ? item.attributes.name : ` ${item.attributes.name} (${item.id
        }) +${item.attributes.country_code}`,
      value: `${item.attributes.country_code}`,
      emojiFlag: item.attributes.emoji_flag
    }));
  };


  flagemojiToPNG = (flag: string) => {
    var countryCode = Array.from(flag, (codeUnit: any) => codeUnit.codePointAt()).map(char => String.fromCharCode(char - 127397).toLowerCase()).join('')
    return (<img src={`https://flagcdn.com/24x18/${countryCode}.png`} alt='flag' />)
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Country Code", message);

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.countryCodeApiCallId != null &&
      this.countryCodeApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      //
      if (responseJson && !responseJson.errors) {
        const countryData = responseJson.data.map((country: any) => {
          return {
            id: country.attributes.country_code,
            value: country.attributes.name
          };
        });
        this.setState({
          dataSource: this.countryCodesToDropDown(responseJson.data),
          countryData: countryData
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  makeRemoteRequest = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryCodeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointGetCountryCodes
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetCountryCodes,
      "token": localStorage.getItem('authToken'),
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetCountryCodesType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
