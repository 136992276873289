import React, { useEffect, useState } from "react";
import CloseIcon from '@material-ui/icons/Close';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Grid,
  Button,
  Drawer,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import { NavLink as RLink } from "react-router-dom";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { handleTermsOfUseRedirection, isGuest, logout } from "./common";
import AboutUsRedirectionConfirmationPopup from "./AboutUsRedirectionConfirmationPopup";
import CookieConsent, { Cookies } from "react-cookie-consent";
import HeaderTiles from "../../blocks/catalogue/src/HeaderTiles.web";
export const configJSON = require("./config.js");

const logo = require("./Logo.png");
const ConnectLogo = require('./Bio-connectLogo.png');

interface IProps {
  isSticky?: boolean;
  navigation?: any;
}

export const AppHeader = ({ isSticky, navigation }: IProps) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<any>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <React.Fragment>
      <AppBar
        position={isSticky ? "sticky" : "static"}
        style={webStyle.appBar}
        className="app-bar"
      >
        <HeaderTiles navigation={undefined} id={""} />
        <Box sx={{
          padding: {
            xs: "10px 10px", sm: "10px 10px", md: '8px 50px', lg: '8px 45px'
          }
        }}>
          <Toolbar disableGutters>
            <RLink style={{ ...webStyle.NavLink }} to="/">
              <img style={webStyle.logoStyle} src={logo} />
            </RLink>

            <Grid container justifyContent="flex-end" alignItems="center">
              <Box sx={{ display: { xs: "none", sm: "none", md: 'none', lg: 'flex' } }} alignItems="center" className="appBarRoute">
                <ResponsiveAppBarRoutes />
              </Box>
              {!isGuest() && <>
                  <Box className="routeIcon">
                    <IconButton
                      id="basic-button"
                      onClick={handleOpenUserMenu}
                      aria-controls={anchorElUser ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={anchorElUser ? 'true' : undefined}
                      style={webStyle.IconButton}
                      className="account-circle"
                    >
                      <AccountCircle />
                      <ArrowDropDownIcon />
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorElUser}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      keepMounted
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <React.Fragment>
                        <MenuItem onClick={() => {
                          handleCloseUserMenu();
                          navigation.navigate("CustomisableUserProfiles")
                        }}
                          style={webStyle.menuStyle}
                        >
                          <Typography style={webStyle.linkName}>
                            My Profile
                          </Typography>
                        </MenuItem>
                        <MenuItem onClick={() => {
                          navigation.navigate("MyQueries")
                          handleCloseUserMenu()
                        }}
                          style={webStyle.menuStyle}
                        >
                          <Typography style={webStyle.linkName}>
                            My Request
                          </Typography>
                        </MenuItem>
                        {
                          localStorage.getItem("customer_invoice") === "true" && (
                        <MenuItem onClick={() => {navigation.navigate("MyInvoice") , handleCloseUserMenu}}
                          style={webStyle.menuStyle}
                        >
                          <Typography style={webStyle.linkName}>
                            My Invoices
                          </Typography>
                        </MenuItem>
                          )
                        }
                        <MenuItem onClick={() => {
                          navigation.navigate("LandingPage")
                          handleCloseUserMenu()
                          logout(navigation);
                        }} style={webStyle.menuHeightStyle}>
                          <Typography style={webStyle.linkName}>
                            Log Out
                          </Typography>
                        </MenuItem>
                      </React.Fragment>
                    </Menu>
                  </Box>
                  <DividerBox></DividerBox>
                </>
              }

              <Box style={webStyle.marginLeftStyle} sx={{display: { xs: "none", lg: "flex" }}}>
                <RLink style={{ ...webStyle.NavLink, ...webStyle.connectLogoMargin }} to="/">
                  <img style={webStyle.logoStyle2} src={ConnectLogo} />
                </RLink>
              </Box>
              <Box sx={{display: { xs: "flex", lg: "none" }}}>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  anchor={'right'}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                >
                  <SideDrawer />
                </Drawer>
              </Box>
            </Grid>
          </Toolbar>
        </Box>
        <CookieConsentPopup />
      </AppBar >
    </React.Fragment>
  );
};

export const CookieConsentPopup = () => {
  const [showCookieBar, setShowCookieBar] = useState<boolean>(false);
  useEffect(() => {
    const isCookieAccepted = Cookies.get("CookieConsent");
    if (!isCookieAccepted) {
      setShowCookieBar(true);
    } else {
      setShowCookieBar(false);
    }
  }, [])
  return <React.Fragment>{showCookieBar && <CookieConsent
    style={webStyle.cookiesEventClass}
    location="none"
    contentStyle={{ }}
    declineButtonStyle={webStyle.declinedButtonStyle}
    flipButtons={true}
    buttonText="Accept"
    buttonStyle={webStyle.buttonStyle}
    overlay={false}
    debug={false}
  >
    <Typography onClick={handleTermsOfUseRedirection} style={webStyle.cookieText}>
      {configJSON.cookieConsentText}
      <RLink to="/" style={{
        ...webStyle.linkName,
        ...webStyle.cookiePolicyLink
      }}
      >
        Cookie Policy
      </RLink>
    </Typography>
    <Button
      style={webStyle.cookieCloseButton} className="cookie_button_position" onClick={() => setShowCookieBar(false)}>
      <CloseIcon style={webStyle.closeIconStyle} />
    </Button>
  </CookieConsent>}
  </React.Fragment>
}


const ResponsiveAppBarRoutes = () => {
  const [isAboutUsPopup, setIsAboutUsPopup] = useState(false);
  const handleAboutUsClick = () => {
    setIsAboutUsPopup(true);
  }

  return (
    <CustomGrid container alignItems="center">
      <RLink to="/" style={webStyle.linkName}
        activeClassName='is-active'
        exact={true}
      >
        Home
      </RLink>
      <RLink to="/APIView"
        style={webStyle.linkName}
        activeClassName='is-active'
        exact={true}
      >
        API Overview
      </RLink>
      <RLink to="/ProductListing/1"
        style={webStyle.linkName}
        className={window.location.pathname.split('/')[1] === "ProductListing" ? 'is-active' : ""}
        exact={true}
      >
        Products
      </RLink>
      <Button
        style={webStyle.linkName}
        className="no_text_transform"
        onClick={handleAboutUsClick}
      >
        About Us
      </Button>
      <RLink
        to="/connectwithus"
        exact={true}
      >
        <ConnectWithUsBtn>
          Connect with us
        </ConnectWithUsBtn>
      </RLink>
      <AboutUsRedirectionConfirmationPopup isOpen={isAboutUsPopup} setIsOpen={setIsAboutUsPopup} />
      {
        isGuest() && (
          <>
            <RLink
              to="/login"
              exact={true}
            >
              <LoginButton>
                Login
              </LoginButton>
            </RLink>
            <DividerBox></DividerBox>
          </>
        )
      }
    </CustomGrid>
  );
};


const SideDrawer = () => {
  const [isAboutUsPopup, setIsAboutUsPopup] = useState(false);

  const handleAboutUsClick = () => {
    setIsAboutUsPopup(true);
  }

  return (
    <CustomGrid container alignItems="flex-start" direction="column">
      <RLink to="/" style={{ ...webStyle.sideNavlinkName, ...webStyle.sideMenuTop }}
        activeClassName='is-active'
        exact={true}
      >
        Home
      </RLink>
      <RLink to="/APIView"
        style={webStyle.sideNavlinkName}
        activeClassName='is-active'
        exact={true}
      >
        API Overview
      </RLink>
      <RLink to="/ProductListing/1"
        style={webStyle.sideNavlinkName}
        className={window.location.pathname.split('/')[1] === "ProductListing" ? 'is-active' : ""}
        exact={true}
      >
        Products
      </RLink>
      <Button
        className="no_text_transform"
        style={{ ...webStyle.sideNavlinkName, ...webStyle.aboutUsLinkName}}
        onClick={handleAboutUsClick}
      >
        About Us
      </Button>
      <RLink
        to="/connectwithus"
        style={webStyle.connectWithUsLink}
        exact={true}
      >
        <ConnectWithUsBtn>
          Connect with us
        </ConnectWithUsBtn>
      </RLink>
      <AboutUsRedirectionConfirmationPopup isOpen={isAboutUsPopup} setIsOpen={setIsAboutUsPopup} />
      {
        isGuest() && (
          <>
            <RLink
              to="/login"
              exact={true}
              style={webStyle.loginButtonLink}
            >
              <LoginButton>
                Login
              </LoginButton>
            </RLink>
            <Grid container justifyContent='center' alignItems="center">
              <HorizontalDividerBox></HorizontalDividerBox>
            </Grid>
            <Box style={webStyle.connectLogoContainer} >
              <RLink style={{ ...webStyle.NavLink }} to="/">
                <img style={webStyle.logoStyle2} src={ConnectLogo} />
              </RLink>
            </Box>
          </>
        )
      }
    </CustomGrid>
  );
};



const webStyle = {
  aboutUsLinkName: {
    padding: 0,
    marginLeft: 19,
  },
  connectWithUsLink: {
    alignItems: 'center',
    display: 'flex',
    margin: '20px 0px 10px 0px',
    width: '100%',
    justifyContent: 'center'
  },
  connectLogoContainer: {
    margin: '10px'
  },
  loginButtonLink: {
    alignItems: 'center',
    display: 'flex',
    margin: '10px 0px 0px 0px',
    width: '100%',
    justifyContent: 'center'
  },
  closeIconStyle: {
    color: "rgba(4, 60, 116, 1)",
    fontSize: "15px",
  },
  cookieCloseButton: {
    backgroundColor: "#fff",
    borderRadius: "50%",
    alignSelf: "center",
    padding: "4px",
    minWidth: "auto"
  },
  cookiePolicyLink: {
    color: "#5d96e0",
    display: "inlineBlock",
    textDecoration: "underline",
    margin: "0 5px"
  },
  cookieText: {
    fontWeight: 400,
    fontFamily: "SFProDisplay",
    letterSpacing: "1px",
    wordSpacing: "1px",
  },
  buttonStyle: {
    backgroundColor: "#fff",
      color: "rgba(4, 60, 116, 1)",
      padding: "10px 50px",
      fontWeight: 500,
      fontFamily: "SFProDisplay",
      borderRadius: "6px",
      marginRight: "100px",
      fontSize: "medium",
  },
  declinedButtonStyle: {
    backgroundColor: "rgba(215, 232, 249, 1)",
    borderRadius: "50%",
    alignSelf: "center",
    padding: "2px"
  },
  cookiesEventClass: {
    overflow: "auto",
    alignItems: "center",
    padding: "0",
    backgroundColor: "#474747",
    position: "relative",
  },
  connectLogoMargin: {
    marginRight: '7px'
  },
  marginLeftStyle: {
    marginLeft: '20px' 
  },
  menuHeightStyle: {
    height: '35px'
  },
  appBar: {
    backgroundColor: "#fff",
  },
  queryPopUpText: {
    fontWeight: 500,
    color: 'rgba(33, 43, 54, 1)',
    fontFamily: 'SFProDisplay',
    fontSize: '20px',
    margin: '0px 15px',
  },
  NavLink: {
    textDecoration: "none",
    color: "inherit",
    fontSize: "inherit",
  },
  menuStyle: {
    height: '35px',
    borderBottom: '1px solid #E1E0E6'
  },
  logoStyle: {
    height: "45px",
    width: 'auto',
  },
  logoStyle2: {
    height: "45px",
    width: 'auto',
  },
  linkName: {
    color: "#003e76",
    display: "block",
    margin: "0px 15px",
    textDecoration: "none",
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '15px',
    boxShadow: 'none'
  },
  sideNavlinkName: {
    color: "#003e76",
    display: "block",
    margin: "10px 20px",
    textDecoration: "none",
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '18px',
    boxShadow: 'none',
  },
  connect: {
    backgroundColor: "#ff8b33",
    borderRadius: "8px",
    maxHeight: '35px',
    padding: '7px 10px 0px 10px',
    margin: "9px 5px 9px 5px",
    fontWeight: 500,
    color: 'white',
    fontFamily: 'SFProDisplay',
    fontSize: '16px'
  },
  IconButton: {
    color: "rgb(4, 60, 116)",
  },
  divider: {
    height: '185px',
    margin: "4px 20px 0px 20px",
  },
  login: {
    color: 'rgba(255, 255, 255, 1)',
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    fontSize: "18px",
  },
  signup: {
    color: 'rgba(4, 60, 116, 1)',
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    fontSize: "18px",
  },
  tilesHeading: {
    margin: '11px 24px 11px 0',
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '28px',
    color: '#fff',
  },
  downloadButton: {
    width: '200px',
    height: '56px',
    margin: '0 0 0 24px',
    padding: '11px 34px',
    borderRadius: '28px',
    boxShadow: `0 2px 4px 0 rgba(4, 60, 116, 0.51)`,
    backgroundColor: '#ebf5ff',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '28px',
    color: '#043c74',
  },
  sideMenuTop: {
    marginTop: 30,
  }
};

const CustomGrid = withStyles({
  root: {
    minWidth: 200,
    "& .MuiIconButton-root": {
      padding: '12px 20px'
    }
  }
})(Grid);

const LoginButton = withStyles({
  root: {
    width: "135px",
    height: "35px",
    color: '#ff8b33',
    opacity: 1,
    margin: '0 6px 0 20px',
    padding: '9px 38px 9px 39px',
    borderRadius: '19.5px',
    border: ' solid 1px #ff8b33',
    backgroundColor: '#fff',
    boxShadow: "none",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    fontSize: "18px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#fff",
      boxShadow: "none",
    },
    "&.MuiButton-outlined": {
      border: '1px solid #ff8b33',
      color: '#ff8b33',
      padding: '9px 38px 9px 39px',
      "&:hover": {
        backgroundColor: '#fff',
      }
    },
    "@media (max-width: 1024px)": {
      width: '80%',
      margin: 0
    }
  },
})(Button);

const DividerBox:any = withStyles({
  root: {
    width: '1px',
    height: '49px',
    margin: '0px 0px 0 15px',
    backgroundColor: '#6a74a5',
  }
})(Box);

const HorizontalDividerBox = withStyles({
  root: {
    width: '80%',
    height: '1px',
    backgroundColor: '#6a74a5',
    marginTop: '20px',
    marginBottom: '20px',
  }
})(Grid);

const ConnectWithUsBtn = withStyles({
  root: {
    width: "120px",
    height: "32px",
    color: '#fff',
    margin: '0 20px 0 10px',
    borderRadius: '8px',
    border: 'solid 1px #8823B4',
    backgroundColor: '#8823B4',
    boxShadow: "none",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    fontSize: "14px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#8823B4",
      boxShadow: "none",
    },
    "@media (max-width: 1024px)": {
      width: '80%',
      margin: 0
    }
  },
})(Button);
