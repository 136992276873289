import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall, authorizeEmployee, getHeaders, isAuthorisedUser, events, isLoggedIn, warningNotification,autoLogOut } from "../../../components/src/common";

// Customizable Area Start
import React from "react";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

export interface ITicketListMeta {
  pagination_details: {
    current_count: number;
    current_page: number;
    next_page: number;
    prev_page: null | number;
    total_count: number;
    total_pages: number;
  };
}

export interface ITicketData {
  id: string;
  type: string;
  attributes: {
    customer_name: string;
    doc_type: [];
    product_description: string;
    product_name: any;
    query_detail: string;
    query_type: string;
    status: string;
    sub_type: string;
    submited_by: string;
    ticket_number: string;
    duration: string;
    is_transferred_ticket: boolean;
    is_overdue_ticket: boolean;
  }
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedTab: number;
  ticketId: string;
  productName: string;
  customerName: string;
  headCells: any[];
  orderBy: string;
  order: any;
  rows: any[];
  TicketListingMeta: null | ITicketListMeta;
  arrayHolderDuplicate: any[];
  count: number;
  page: number;
  token: string;
  ticketList: ITicketData[];
  paginationData: any;
  searchTerm: string;
  isLoading: boolean;
  ticket_assigned_count: number;
  transfered_tickets_count: number;
  isFilteredTicketListing: boolean;
  csvData: string;
  csvFileName: string;
  filterMenu: any,
  filterData: any,
  filterStatusMenu: any,
  filterStatusData: any,
  selectedQueryType: string[],
  selectedStatus: string[],
  overDueCountMessage: string,
  isCreatedPopup: boolean;
  all_customer: any[],
  all_company: any[];
  selectedCustomerName: any;
  all_customer_contact: any[];
  selectedCustomerContact: any;
  query_type: any[];
  selectedAddQueryType: any;
  isQueryTypeError: boolean;
  isCustomerContactError: boolean;
  isCustomerError: boolean;
  startdate: any;
  endDate: any;
  filterSelected: boolean;
  downloadText: string;
  createNewTicketText: string;
  customerNameLabel: string;
  customerNamePlaceholder: string;
  NoDataText: string;
  selectQueryTypePlaceholder: string;
  cancelBtnText: string;
  ProceedBtnText: string;
  noProductsFountText: string;
  QueryTypePlaceholder: string;
  selectCustomerContactPlaceholder: string;
  selectContactPlaceholder: string;
  selectContactNameLabel: string;
  companyNameplaceholder: string;
  ViewDetailsBtnText: string;
  daysText: string;
  searchPlaceholder: string;
  searchProductPlaceholder: string;
  searchTicketIDPlaceholder: string;
  resolveTicketsText: string;
  transferredTicketsText: string;
  ticketsAssignedText: string;
  tabsName: string;
  saveBtnText: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdminConsole3Controller extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  authToken: string;
  getFilterAndSearchProductsApiCallId: any;
  getProductApiCallId: any;
  perPageRecords: number = 9;
  searchProductApiCallId: any;
  getTicketCountApiCallId: any;
  downloadAssignedToMeTicketsApiCallId: string;
  downloadTransferredTicketsApiCallId: string;
  csvRef: any;
  getTransferredTicketsFilterAndSearchApiCallId: string;
  getAllCompanyListAPICalled: string;
  getCustomerContactAPICalled: string;
  getQueryTypeApiCalled: string;
  createTicketApiCalled: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleCancleQuerytypeFilter = this.handleCancleQuerytypeFilter.bind(this);
    this.handleAllClearStatus = this.handleAllClearStatus.bind(this);
    this.handleCustomerContactName = this.handleCustomerContactName.bind(this);
    this.handleFilterAndSearch = this.handleFilterAndSearch.bind(this);
    this.handleFilterAndSearchData = this.handleFilterAndSearchData.bind(this);
    this.handleticketData = this.handleticketData.bind(this);
    this.handleTicketRefresh = this.handleTicketRefresh.bind(this);
    this.setSelectedDate = this.setSelectedDate.bind(this);
    this.handleIsCreatedClose = this.handleIsCreatedClose.bind(this);
    this.goBackFromCreateTicket = this.goBackFromCreateTicket.bind(this);
    this.handleCustomerNameChange = this.handleCustomerNameChange.bind(this);
    this.getComapnyList = this.getComapnyList.bind(this);
    this.getQueryType = this.getQueryType.bind(this);
    this.sliceData = this.sliceData.bind(this);
    this.stableSort = this.stableSort.bind(this);
    this.ASCendingSort = this.ASCendingSort.bind(this);
    this.DESendingSort = this.DESendingSort.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.getTicketCount = this.getTicketCount.bind(this);
    this.handleTransferredTicketsFilterAndSearch = this.handleTransferredTicketsFilterAndSearch.bind(this);
    this.getTransferredTicketsListing = this.getTransferredTicketsListing.bind(this);
    this.onProceedToRaiseQuery = this.onProceedToRaiseQuery.bind(this);
    this.getCustomerContact = this.getCustomerContact.bind(this);
    this.handleChangeQueryType = this.handleChangeQueryType.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.handleCancleStatusFilter = this.handleCancleStatusFilter.bind(this);
    this.handleCheckboxQuerySave = this.handleCheckboxQuerySave.bind(this);
    this.handleStatusCheckBoxChange = this.handleStatusCheckBoxChange.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.filterOptions = this.filterOptions.bind(this);
    this.downloadTickets = this.downloadTickets.bind(this);
    this.filterStatusOptions = this.filterStatusOptions.bind(this);
    this.handleAllClearCheckBox = this.handleAllClearCheckBox.bind(this);
    this.setTokenValues = this.setTokenValues.bind(this);
    this.setCreateTicketData = this.setCreateTicketData.bind(this);
    this.setQueryTypes = this.setQueryTypes.bind(this);
    this.setCustomerContacts = this.setCustomerContacts.bind(this);
    this.setAllCompanyList = this.setAllCompanyList.bind(this);
    this.setTicketCountData = this.setTicketCountData.bind(this);
    this.HandleTransferTicketDownloads = this.HandleTransferTicketDownloads.bind(this);
    this.APICallResponses = this.APICallResponses.bind(this);
    this.DownloadAssignedTicketsHandle = this.DownloadAssignedTicketsHandle.bind(this);
    this.setTransferredTicketData = this.setTransferredTicketData.bind(this);
    this.setFilterredTicketData = this.setFilterredTicketData.bind(this);
    this.setTicketData = this.setTicketData.bind(this);
    this.csvRef = React.createRef();

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      token: "",
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedTab: 0,
      ticketId: '',
      productName: '',
      customerName: '',
      count: 0,
      page: 1,
      ticketList: [],
      searchTerm: '',
      rows: [],
      arrayHolderDuplicate: [],
      orderBy: '',
      order: 'asc',
      TicketListingMeta: null,
      paginationData: [],
      isLoading: false,
      ticket_assigned_count: 0,
      transfered_tickets_count: 0,
      isFilteredTicketListing: false,
      csvData: '',
      csvFileName: '',
      filterMenu: null,
      filterData: [{}],
      filterStatusMenu: null,
      filterStatusData: [{}],
      selectedQueryType: [],
      selectedStatus: [],
      overDueCountMessage: '',
      isCreatedPopup: false,
      all_customer: [],
      all_company: [],
      selectedCustomerName: "",
      all_customer_contact: [],
      selectedCustomerContact: "",
      query_type: [],
      selectedAddQueryType: "",
      isQueryTypeError: false,
      isCustomerContactError: false,
      isCustomerError: false,
      startdate: moment('01-01-2022'),
      endDate: moment(),
      filterSelected: false,
      headCells: [
        {
          id: 'ticket_number',
          numeric: 'left',
          disablePadding: true,
          isSorting: false,
          isFiltering: false,
          label: 'Ticket ID',
        },
        {
          id: 'duration',
          numeric: 'left',
          disablePadding: true,
          isSorting: false,
          isFiltering: false,
          label: 'Duration',
        },
        {
          id: 'product_name',
          numeric: 'left',
          disablePadding: false,
          isSorting: false,
          isFiltering: false,
          label: 'Product Name',
        },
        {
          id: 'query_type',
          numeric: 'left',
          disablePadding: false,
          isSorting: false,
          isFiltering: true,
          label: 'Query Type',
        },
        {
          id: 'status',
          numeric: 'center',
          disablePadding: false,
          isSorting: false,
          isFiltering: true,
          label: 'Status',
        },
        {
          id: 'customer_name',
          numeric: 'left',
          disablePadding: false,
          isSorting: false,
          isFiltering: false,
          label: 'Contact Name',
        },
        {
          id: 'viewDetails',
          numeric: 'center',
          disablePadding: false,
          isSorting: false,
          isFiltering: false,
          label: 'View Details',
        },
      ],
      downloadText: configJSON.downloadText,
      createNewTicketText: configJSON.createNewTicketText,
      customerNameLabel: configJSON.customerNameLabel,
      customerNamePlaceholder: configJSON.customerNamePlaceholder,
      NoDataText: configJSON.NoDataText,
      selectQueryTypePlaceholder: configJSON.selectQueryTypePlaceholder,
      cancelBtnText: configJSON.cancelBtnText,
      ProceedBtnText: configJSON.ProceedBtnText,
      noProductsFountText: configJSON.noProductsFountText,
      QueryTypePlaceholder: configJSON.QueryTypePlaceholder,
      selectCustomerContactPlaceholder: configJSON.selectCustomerContactPlaceholder,
      selectContactPlaceholder: configJSON.selectContactPlaceholder,
      selectContactNameLabel: configJSON.selectContactNameLabel,
      companyNameplaceholder: configJSON.companyNameplaceholder,
      ViewDetailsBtnText: configJSON.ViewDetailsBtnText,
      daysText: configJSON.daysText,
      searchPlaceholder: configJSON.searchPlaceholder,
      searchProductPlaceholder: configJSON.searchProductPlaceholder,
      searchTicketIDPlaceholder: configJSON.searchTicketIDPlaceholder,
      resolveTicketsText: configJSON.resolveTicketsText,
      transferredTicketsText: configJSON.transferredTicketsText,
      ticketsAssignedText: configJSON.ticketsAssignedText,
      tabsName: configJSON.tabsName,
      saveBtnText: configJSON.saveBtnText,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async componentDidMount() {
    super.componentDidMount();
    localStorage.setItem("current_transferrent_ticket", "false")
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        if(isLoggedIn(this.props.navigation)) {
          autoLogOut();
        }
      });
    });
    let authtoken = isLoggedIn(this.props.navigation);
    authorizeEmployee(this.props.navigation)
    localStorage.setItem('previousPath', window.location.pathname)
    if (typeof (authtoken) === "string") {
      localStorage.setItem('ticketRangeValue', JSON.stringify({ startDate: "", key: "selection", endDate: "" }))
      this.authToken = authtoken;
      let isCreatedPopup = localStorage.getItem("createNewTicket");
      if (isCreatedPopup !== null) {
        if (isCreatedPopup === "true") {
          this.getComapnyList();
          this.getQueryType();
          this.setState({isCreatedPopup: true})
        } else {
          this.setState({isCreatedPopup: false});
          this.handleticketData(authtoken)
          this.sliceData();
          this.setState({
            arrayHolderDuplicate: this.state.rows,
          });
        }
      } else {
        this.handleticketData(authtoken)
        this.sliceData();
        this.setState({
          arrayHolderDuplicate: this.state.rows,
        });
      }
    }
  }

  sliceData = () => {
    this.setState({
      count: this.state.rows.length,
      paginationData: this.state.rows.slice(0, 6),
    });
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    this.setTokenValues(message)
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      isAuthorisedUser(responseJson, this.props.navigation);
      if (this.getProductApiCallId != null && this.getProductApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        this.setTicketData(responseJson);
      }
      if (this.getFilterAndSearchProductsApiCallId != null && this.getFilterAndSearchProductsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        this.setFilterredTicketData(responseJson);
      }

      if (this.getTransferredTicketsFilterAndSearchApiCallId != null && this.getTransferredTicketsFilterAndSearchApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        this.setTransferredTicketData(responseJson);
      }

      if (this.downloadAssignedToMeTicketsApiCallId !== null && this.downloadAssignedToMeTicketsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        this.DownloadAssignedTicketsHandle(responseJson);
      }
    }

    this.APICallResponses(responseJson, message);
    // Customizable Area Start
    // Customizable Area End

  }

  // web events
  APICallResponses = (responseJson: any, message: any) => {
    if (this.downloadTransferredTicketsApiCallId !== null && this.downloadTransferredTicketsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.HandleTransferTicketDownloads(responseJson);
    }
    if (this.getTicketCountApiCallId != null && this.getTicketCountApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.setTicketCountData(responseJson);
     }
 
     if (this.getAllCompanyListAPICalled !== null && this.getAllCompanyListAPICalled === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
       this.setAllCompanyList(responseJson)
     }
 
     if (this.getCustomerContactAPICalled !== null && this.getCustomerContactAPICalled === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
       this.setCustomerContacts(responseJson);
     }
 
     if (this.getQueryTypeApiCalled !== null && this.getQueryTypeApiCalled === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
       this.setQueryTypes(responseJson);
     }
 
     if (this.createTicketApiCalled !== null && this.createTicketApiCalled === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
       this.setCreateTicketData(responseJson);
     }
  }

  setTokenValues = (message: any) => {
    if (getName(MessageEnum.SessionResponseMessage) === message?.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.handleticketData(token);
    }
  }

  setTicketCountData = (responseJson: any) => {
    if (responseJson) {
      this.setState({
        transfered_tickets_count: responseJson?.transfered_tickets_count
      })
    }
  }

  setAllCompanyList = (responseJson: any) => {
    if (!responseJson?.error && !responseJson?.errors) {
      const companies = responseJson?.all_companies.map((company: any) => {
        return {
          id: company.id,
          label: company.customer_name
        }
      });
      this.setState({
        all_company: companies
      });
    }
  }

  setCustomerContacts = (responseJson: any) => {
    if (!responseJson?.error && !responseJson?.errors) {
      this.setState({
        all_customer_contact: responseJson?.result
      });
    }
  }

  setQueryTypes = (responseJson: any) => {
    if (!responseJson?.error && !responseJson?.errors) {
      for (let key in responseJson?.query_type) {
        if (responseJson?.query_type.hasOwnProperty(key)) {
          let obj = {
            id: responseJson?.query_type[key],
            name: key
          }
          this.state.query_type.push(obj);
        }
      }
      this.setState({
        query_type: this.state.query_type
      });
    }
  }

  setCreateTicketData = (responseJson: any) => {
    if (!responseJson?.error && !responseJson?.errors) {
      const id = responseJson?.data.id
      localStorage.setItem('CustomerProfileDetails', JSON.stringify(responseJson?.data.attributes));
      if (this.state.selectedAddQueryType?.id === 0) {
        this.props.navigation.navigate("CustomerProductQuery", { query: 'product', id: id || '' });
      } else if (this.state.selectedAddQueryType?.id === 1) {
        this.props.navigation.navigate("CustomerProductQuery", { query: 'sample', id: id || '' });
      } else {
        this.props.navigation.navigate("CustomerProductQuery", { query: 'meeting', id: id || '' });
      }
    }
  }

  setTicketData = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.success) {
      this.setState({
        rows: responseJson?.data,
        ticketList: responseJson?.result.data,
        TicketListingMeta: responseJson?.result.meta,
        overDueCountMessage: responseJson?.overdue_count,
        ticket_assigned_count: responseJson?.total_assigned_count,
      });
      runEngine.debugLog("arrayHolder", this.state.rows);
    }
    this.setState({isLoading: false});
  }

  setFilterredTicketData = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.success) {
      this.setState({
        rows: responseJson?.data,
        ticketList: responseJson?.result.data,
        TicketListingMeta: responseJson?.result.meta,
        overDueCountMessage: responseJson?.overdue_count,
        ticket_assigned_count: responseJson?.total_assigned_count,
      });
    } else {
      this.setState({
        ticketList: [],
        TicketListingMeta: null
      })
    }
    if (responseJson?.message === 'Sorry, no tickets found') {
      this.setState({
        ticketList: [],
        TicketListingMeta: null
      })
    }
    this.setState({isLoading: false});
  }

  setTransferredTicketData = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.success) {
      this.setState({
        rows: responseJson?.data,
        ticketList: responseJson?.result.data,
        TicketListingMeta: responseJson?.result.meta,
        ticket_assigned_count: responseJson?.total_assigned_count,
      });
    } else {
      this.setState({
        ticketList: [],
        TicketListingMeta: null
      })
    }
    this.setState({isLoading: false});
  }

  DownloadAssignedTicketsHandle = (responseJson: any) => {
    if (responseJson?.success && responseJson?.result) {
      this.setState({
        csvData: responseJson?.result,
        csvFileName: responseJson?.filename
      }, () => {
        this.csvRef.current.link.click()
      })
    } else {
      warningNotification("Something went wrong");
    }
    this.setState({isLoading: false});
  }

  HandleTransferTicketDownloads = (responseJson: any) => {
    if (responseJson?.success && responseJson?.result) {
      this.setState({
        csvData: responseJson?.result,
        csvFileName: responseJson?.filename
      }, () => {
        this.csvRef.current.link.click();
      })
    } else {
      warningNotification("Something went wrong");
    }
    this.setState({isLoading: false})
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  handleChangeTab = (event: any, newValue: any) => {
    let bool;
    if (newValue === 1) {
      bool = true;
    } else {
      bool = false
    }
    this.setState({
      isFilteredTicketListing: bool,
      page: 1,
      ticketId: "",
      productName: "",
      customerName: "",
      ticket_assigned_count: 0,
      selectedQueryType: [],
      selectedStatus: [],
      startdate: moment('01-01-2022'),
      endDate: moment(),
      filterSelected: true,
    }, () => {
      if (newValue === 1) {
        localStorage.setItem("current_transferrent_ticket", "true")
        this.getTransferredTicketsListing();
      } else {
        localStorage.setItem("current_transferrent_ticket", "false")
        this.handleticketData(this.authToken);
      }
    });
    this.setState({ selectedTab: newValue });
  };

  stableSort(property: string) {
    this.setState({ orderBy: property });
    let sorted = []
    if (this.state.order === 'asc') {
      this.setState({ order: 'desc' });
      sorted = this.ASCendingSort(this.state.ticketList, property)
    } else {
      this.setState({ order: 'asc' });
      sorted = this.DESendingSort(this.state.ticketList, property)
    }
    this.setState({ ticketList: sorted });
  }

  ASCendingSort = (data: any, property: string) => {
    return data?.sort((a: any, b: any) => b.attributes[property] > a.attributes[property] ? 1 : -1);
  }

  DESendingSort = (data: any, property: string) => {
    return data?.sort((a: any, b: any) => a.attributes[property] < b.attributes[property] ? -1 : 1);
  }

  handlePageChange = (e: any, p: any) => {
    this.setState({ ticket_assigned_count: 0 })
    this.setState({ page: p, }, () => {
      if (this.state.isFilteredTicketListing) {
        this.handleTransferredTicketsFilterAndSearch(this.state.selectedQueryType, this.state.selectedStatus, this.state.startdate, this.state.endDate);
      } else {
        this.handleFilterAndSearchData(this.state.selectedQueryType, this.state.selectedStatus, this.state.startdate, this.state.endDate);
      }
    });
  };

  handleticketData = (token: any, pageNumber: number = 1) => {
    this.setState({ isLoading: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTicketsAPIEndPoint +
      `?per_page=${this.perPageRecords}&page=${this.state.page}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTicketCount = (token: any,) => {
    this.setState({isLoading: true})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTicketCountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ticketCountAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  debounce = (func: any, wait: number = 700) => {
    let timer: any;
    return (...args: any[]) => {
      clearTimeout(timer)
      timer = setTimeout(() => func(...args), wait);
    };
  };

  duplicateCode = () => {
    if (this.state.isFilteredTicketListing) {
      this.handleTransferredTicketsFilterAndSearch(this.state.selectedQueryType, this.state.selectedStatus, this.state.startdate, this.state.endDate);
    } else {
      this.handleFilterAndSearchData(this.state.selectedQueryType, this.state.selectedStatus, this.state.startdate, this.state.endDate);
    }
  }

  debouncedCallback = this.debounce(() => this.duplicateCode(), 700);

  handleChangeSearchVlaue = (prop: any) => (event: any) => {
    const value = event?.target.value;
    let customerId = this.state.ticketId;
    let productName = this.state.productName;
    let industry = this.state.customerName;

    if (prop === "ticketId" && value && value.length > 0) {
      customerId = value;
    } else if (prop === "productName" && value && value.length > 0) {
      productName = value;
    } else if (prop === "customerName" && value && value.length > 0) {
      industry = value;
    }
    if (value && value.length > 0) {
      this.setState({
        ...this.state,
        ticketId: customerId,
        productName: productName,
        customerName: industry,
        searchTerm: value,
        page: 1
      }, this.debouncedCallback)
    } else {
      this.setState({
        ...this.state,
        [prop]: "",
        searchTerm: "",
        page: 1
      }, this.debouncedCallback)
    }
  };

  handleFilterAndSearch = (p: number = 1) => {
    this.setState({ isLoading: true })
    const header = getHeaders({ token: this.authToken });
    let url = configJSON.ticketSearchApiEndPoint + `?per_page=${this.perPageRecords}&page=${this.state.page}&query=${this.state.ticketId}&query_one=${this.state.productName}&query_two=${this.state.customerName}`;

    const filterAndSearchApi = apiCall({
      url: url,
      header,
      httpBody: {},
      httpMethod: configJSON.validationApiMethodType
    });

    this.getFilterAndSearchProductsApiCallId = filterAndSearchApi.messageId;
    runEngine.sendMessage(filterAndSearchApi.id, filterAndSearchApi);

  }

  handleFilterAndSearchData = (queryTypeVal: any, statusVal: any, startDate: any, endDate: any) => {
    this.setState({ isLoading: true, page: 1 })
    let selectedQuries;
    if (queryTypeVal?.length === 0) {
      selectedQuries = ''
    } else if (queryTypeVal?.length === 1) {
      selectedQuries = queryTypeVal[0]
    } else {
      selectedQuries = `['${queryTypeVal.join("' , '")}']`
    }
    let selectedStatus;
    if (statusVal.length === 0) {
      selectedStatus = ''
    } else if (statusVal.length === 1) {
      selectedStatus = statusVal[0]
    } else {
      selectedStatus = `['${statusVal.join("' , '")}']`
    }
    const header = getHeaders({ token: this.authToken });
    let url = configJSON.getTicketsAPIEndPoint + `?per_page=${this.perPageRecords}&query=${selectedQuries}&status=${selectedStatus}&start_date=${moment(startDate).format('DD-MM-YYYY')}&end_date=${moment(endDate).format('DD-MM-YYYY')}&query_one=${this.state.ticketId}&query_two=${this.state.productName}&query_three=${this.state.customerName}&page=${this.state.page}`;

    const filterAndSearchApi = apiCall({
      url: url,
      header,
      httpBody: {},
      httpMethod: configJSON.validationApiMethodType
    });

    this.getFilterAndSearchProductsApiCallId = filterAndSearchApi.messageId;
    runEngine.sendMessage(filterAndSearchApi.id, filterAndSearchApi);
  }

  handleTransferredTicketsFilterAndSearch = (queryTypeVal: any, statusVal: any, startDate: any, endDate: any) => {
    this.setState({ isLoading: true, ticket_assigned_count: 0 });
    let selectedQuries;
    if (queryTypeVal?.length === 0) {
      selectedQuries = ''
    } else if (queryTypeVal?.length === 1) {
      selectedQuries = queryTypeVal[0]
    } else {
      selectedQuries = `['${queryTypeVal.join("' , '")}']`
    }
    let selectedStatus;
    if (statusVal.length === 0) {
      selectedStatus = ''
    } else if (statusVal.length === 1) {
      selectedStatus = statusVal[0]
    } else {
      selectedStatus = `['${statusVal.join("' , '")}']`
    }
    const transferredTicketsSearch = apiCall({
      header: getHeaders({ token: this.authToken }),
      httpMethod: configJSON.getTransferredTicketsSearchApiMethod,
      httpBody: {},
      url: 'bx_block_catalogue/tickets/all_transferred_tickets' + `?per_page=${this.perPageRecords}&query=${selectedQuries}&status=${selectedStatus}&start_date=${moment(startDate).format('DD-MM-YYYY')}&end_date=${moment(endDate)}&query_one=${this.state.ticketId}&query_two=${this.state.productName}&query_three=${this.state.customerName}&page=${this.state.page}`
    });

    this.getTransferredTicketsFilterAndSearchApiCallId = transferredTicketsSearch.messageId;
    runEngine.sendMessage(transferredTicketsSearch.id, transferredTicketsSearch);
  }


  // Customizable Area Start
  handleTicketListClick = (id: string) => {
    this.setState({
      isLoading: true
    })
    if (this.props.navigation) {
      this.props.navigation.navigate("DataStorage", { id })
    }
  }

  getTransferredTicketsListing = () => {
    this.setState({ isLoading: true })
    const header = getHeaders({ token: this.authToken });
    let url = configJSON.getTrasnferredTicketListingApiEndpoint + `?per_page=${this.perPageRecords}&page=${this.state.page}`;

    const transferredTickets = apiCall({
      url: url,
      header,
      httpBody: {},
      httpMethod: configJSON.getTrasnferredTicketListingApiMethod
    });

    this.getFilterAndSearchProductsApiCallId = transferredTickets.messageId;
    runEngine.sendMessage(transferredTickets.id, transferredTickets);
  }

  downloadAssignedToMeTickets = () => {
    this.setState({ isLoading: true });
    let seletedQueries;
    if (this.state.selectedQueryType.length === 0) {
      seletedQueries = ''
    } else if (this.state.selectedQueryType.length === 1) {
      seletedQueries = this.state.selectedQueryType[0]
    } else {
      seletedQueries = `['${this.state.selectedQueryType.join("' , '")}']`
    }
    let selectedStatus;
    if (this.state.selectedStatus.length === 0) {
      selectedStatus = ''
    } else if (this.state.selectedStatus.length === 1) {
      selectedStatus = this.state.selectedStatus[0]
    } else {
      selectedStatus = `['${this.state.selectedStatus.join("' , '")}']`
    }
    const downloadAssignedToMe = apiCall({
      url: configJSON.downloadAssignedToMeTicketsApiEndpoint + `?query=${seletedQueries}&status=${selectedStatus}&start_date=${moment(this.state.startdate).format('DD-MM-YYYY')}&end_date=${moment(this.state.endDate).format('DD-MM-YYYY')}&query_one=${this.state.ticketId}&query_two=${this.state.productName}&query_three=${this.state.customerName}`,
      header: getHeaders({ token: this.authToken }),
      httpBody: {},
      httpMethod: configJSON.downloadAssignedToMeTicketsApiMethod
    });

    this.downloadAssignedToMeTicketsApiCallId = downloadAssignedToMe.messageId;
    runEngine.sendMessage(downloadAssignedToMe.id, downloadAssignedToMe);
  }

  downloadTransferredTickets = () => {
    this.setState({ isLoading: true });
    let seletedQueries;
    if (this.state.selectedQueryType.length === 0) {
      seletedQueries = ''
    } else if (this.state.selectedQueryType.length === 1) {
      seletedQueries = this.state.selectedQueryType[0]
    } else {
      seletedQueries = `['${this.state.selectedQueryType.join("' , '")}']`
    }
    let selectedStatus;
    if (this.state.selectedStatus.length === 0) {
      selectedStatus = ''
    } else if (this.state.selectedStatus.length === 1) {
      selectedStatus = this.state.selectedStatus[0]
    } else {
      selectedStatus = `['${this.state.selectedStatus.join("' , '")}']`
    }
    const downloadTransferredTickets = apiCall({
      url: configJSON.downloadTransferredTicketsApiEndPoint + `?query=${seletedQueries}&status=${selectedStatus}&start_date=${moment(this.state.startdate).format('DD-MM-YYYY')}&end_date=${moment(this.state.endDate).format('DD-MM-YYYY')}&query_one=${this.state.ticketId}&query_two=${this.state.productName}&query_three=${this.state.customerName}`,
      header: getHeaders({ token: this.authToken }),
      httpBody: {},
      httpMethod: configJSON.downloadTransferredTicketsApiMethod
    });

    this.downloadTransferredTicketsApiCallId = downloadTransferredTickets.messageId;
    runEngine.sendMessage(downloadTransferredTickets.id, downloadTransferredTickets);
  }

  downloadTickets = () => {
    if (this.state.isFilteredTicketListing) {
      this.downloadTransferredTickets();
    } else {
      this.downloadAssignedToMeTickets();
    }
  }

  filterOptions = (event: any, val: any) => {
    val === "Query Type" && this.setState(
      {
        filterData: [{ id: 1, label: "Signup Request", value: "Signup Request" },
        { id: 2, label: "Product Query", value: "Product Query" },
        { id: 3, label: "Sample Request", value: "Sample Request" },
        { id: 4, label: "Meeting Request", value: "Set Meeting" },
        { id: 4, label: "Connect With Us", value: "Connect With Us" }]
      })
    this.setState({ filterMenu: event?.currentTarget })
  }

  filterStatusOptions = (event: any, val: any) => {
    val === "Status" && this.setState({ filterStatusData: [{ id: 1, label: "Open", value: "Open" }, { id: 2, label: "In Progress", value: "In Progress" }, { id: 3, label: "Closed", value: "Closed" }] })
    this.setState({ filterStatusMenu: event?.currentTarget })
  }

  handleCheckBoxChange = (event: any) => {
    let selectedOptions = [...this.state.selectedQueryType];
    if (event?.target.checked)
      selectedOptions.push(event?.target.value);
    else
      selectedOptions.splice(selectedOptions.indexOf(event?.target.value), 1);
    this.setState({
      selectedQueryType: selectedOptions,
      ticketId: this.state.ticketId,
      productName: this.state.productName,
      customerName: this.state.customerName
    })
  }

  handleStatusCheckBoxChange = (event: any) => {
    let selectedOptions = [...this.state.selectedStatus];
    if (event?.target.checked)
      selectedOptions.push(event?.target.value);
    else
      selectedOptions.splice(selectedOptions.indexOf(event?.target.value), 1);
    this.setState({
      selectedStatus: selectedOptions,
      ticketId: this.state.ticketId,
      productName: this.state.productName,
      customerName: this.state.customerName
    })
  }

  handleAllClearCheckBox = () => {
    this.setState({ selectedQueryType: [] })
  }

  handleCheckboxQuerySave = () => {
    if (this.state.isFilteredTicketListing) {
      this.handleTransferredTicketsFilterAndSearch(this.state.selectedQueryType, this.state.selectedStatus, this.state.startdate, this.state.endDate)
    } else {
      this.handleFilterAndSearchData(this.state.selectedQueryType, this.state.selectedStatus, this.state.startdate, this.state.endDate)
    }
    this.setState({ filterMenu: null, filterStatusMenu: false })
  }

  handleCancleQuerytypeFilter = () => {
    this.setState({ filterMenu: null })
  }

  handleCancleStatusFilter = () => {
    this.setState({ filterMenu: null, filterStatusMenu: false })
  }

  handleAllClearStatus = () => {
    this.setState({ selectedStatus: [] })
  }

  handleMenuClose = () => {
    this.setState({ filterMenu: null, filterStatusMenu: null })
  }

  handleIsCreatedClose = () => {
    this.setState({ isCreatedPopup: false })
    localStorage.setItem("createNewTicket", "false")
    this.props.navigation.navigate("TicketList");
    if (this.state.rows.length === 0) {
      this.handleticketData(this.authToken)
      this.sliceData();
      this.getTicketCount(this.authToken);
      this.setState({
        arrayHolderDuplicate: this.state.rows,
      });
    }
  }

  goBackFromCreateTicket = () => {
    localStorage.setItem("createNewTicket", "false")
    this.props.navigation.navigate("TicketList");
    if (this.state.rows.length === 0) {
      this.handleticketData(this.authToken)
      this.sliceData();
      this.getTicketCount(this.authToken);
      this.setState({
        arrayHolderDuplicate: this.state.rows,
      });
    }
  }

  handleCustomerNameChange = (e: any) => {
    this.setState({
      selectedCustomerName: e?.target?.value
    })
    this.getCustomerContact(e?.target?.value.id);
  }

  handleCustomerContactName = (e: any) => {
    this.setState({
      selectedCustomerContact: e?.target?.value
    })
  }

  handleChangeQueryType = (e: any) => {
    this.setState({
      selectedAddQueryType: e?.target?.value
    })
  }

  getComapnyList = () => {
    const header = getHeaders({ token: this.authToken });
    const getCompanies = apiCall({
      httpBody: {},
      header: header,
      url: configJSON.getAllCompaniesApiEndpoint,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.getAllCompanyListAPICalled = getCompanies.messageId;
    runEngine.sendMessage(getCompanies.id, getCompanies);
  }

  getCustomerContact = (id: number) => {
    const header = getHeaders({ token: this.authToken });
    const getCompanies = apiCall({
      httpBody: {},
      header: header,
      url: configJSON.getCustomerContactApiEndpoint + `company_id=${id}`,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.getCustomerContactAPICalled = getCompanies.messageId;
    runEngine.sendMessage(getCompanies.id, getCompanies);
  }

  getQueryType = () => {
    const header = getHeaders({ token: this.authToken });
    const getCompanies = apiCall({
      httpBody: {},
      header: header,
      url: configJSON.queryTypeApiEndPoint,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.getQueryTypeApiCalled = getCompanies.messageId;
    runEngine.sendMessage(getCompanies.id, getCompanies);
  }

  onProceedToRaiseQuery = () => {
    localStorage.setItem("viewFolderId", "0");
    if (this.state.selectedCustomerName === "") {
      this.setState({
        isCustomerError: true
      })
      return false
    }

    if (this.state.selectedCustomerContact === "") {
      this.setState({
        isCustomerContactError: true
      })
      return false
    }

    if (this.state.selectedAddQueryType === "") {
      this.setState({
        isQueryTypeError: true
      })
      return false
    }
    this.setState({
      isLoading: true
    });

    const header = getHeaders({ token: this.authToken });

    const body = {
      personal_detail_id: this.state.selectedCustomerName?.id,
      account_id: this.state.selectedCustomerContact?.id,
      query_type: this.state.selectedAddQueryType?.id,
    };

    const createAccount = apiCall({
      httpBody: body,
      header: header,
      url: configJSON.createEmpSideTicketApiEndPoint,
      httpMethod: configJSON.exampleAPiMethod,
    });
    this.createTicketApiCalled = createAccount.messageId;
    runEngine.sendMessage(createAccount.id, createAccount);

    return true;
  }

  setSelectedDate = (startdate: any, endDate: any) => {
    this.setState({
      startdate: startdate,
      endDate: endDate,
      filterSelected: false,
    });
    if (this.state.isFilteredTicketListing) {
      this.handleTransferredTicketsFilterAndSearch(this.state.selectedQueryType, this.state.selectedStatus, startdate, endDate);
    } else {
      this.handleFilterAndSearchData(this.state.selectedQueryType, this.state.selectedStatus, startdate, endDate);
    }
  }

  handleTicketRefresh = () => {
    if (this.state.isFilteredTicketListing) {
      this.handleTransferredTicketsFilterAndSearch(this.state.selectedQueryType, this.state.selectedStatus, this.state.startdate, this.state.endDate);
    } else {
      this.handleFilterAndSearchData(this.state.selectedQueryType, this.state.selectedStatus, this.state.startdate, this.state.endDate);
    }
  }
  // Customizable Area End

}
