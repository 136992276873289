import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { apiCall, getHeaders, getUserdetails, logout, isEmployee, isGuest, IUserdetails, warningNotification, handleUpdateAuthToken, isLoggedIn, events, autoLogOut } from "../../../components/src/common";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  showChemicalName: boolean;
  Data: any;
  IsKnowMore: boolean;
  TherapueticAreaLabel: string;
  ApiTechnologylabel: string;
  Category: string;
  ChemicalLabel: string;
  selectedOption: string;
  descriptionlabel: string;
  documentLabel: string;
  documentList: any[];
  passwordTioolTip: string;
  openToolTip: boolean;
  anchorEl: any;
  isQueryraised: boolean;
  isQuesryOptions: boolean;
  queryOptions: any[];
  noDocumentsAvailableText: string;
  popUPText: string;
  isGuestPopup: boolean;
  loading: boolean;
  isAvailable: boolean;
  isDocumentSelect: boolean;
  catalogueId: any;
  viewFolderId: string;
  nograntAccess: boolean;
  noGrantaccessMsg: string;
  shouldRaiseQueryPopup: boolean;
  bucket_id: any;
  ProductId: any;
  isDMFBucket: boolean;
  productDetail: null | {
    id: string;
    type: string;
    attributes: {
      chemical_structure: string;
      cas_number: string;
      name: string;
      therapeutic_area: string;
      composition: string;
      manufacturing_platform: string;
      product_type: string;
      image: null | string;
      category: string;
      chemical_name: string;
      dmf_availabilities: {
        data: {
          id: string;
          type: string;
          attributes: {
            available: boolean;
            product_dmf: string;
            in_progress: boolean;
            product_dmf_id: number;
          }
        }[]
      };
      product_description: string | null;
      product_documents: {
        data: {
          attributes: {
            product_documents: {
              bucket: string;
              bucket_id: number;
              product_document_id: number;
              confidential: boolean;
              doc_file_name: {
                doc_file: null | string;
                download_file: boolean;
                file_name: string;
                id: number;
              }[];
              rank: number
            }[]
          },
          id: string;
          type: string;
        }[];
      };
    };
  }
  // Customizable Area End
}

let productDetail = {
  id: "5",
  type: "catalogue",
  attributes: {
    name: "Mycophenolic Acid",
    therapuetic_class: "Immunosuppressants",
    composition: "",
    api_technology: "Peptide",
    product_type: "Commercial",
    image: null,
    category: [{
      id: 1,
      name: "Commercial",
    }],
    sub_category: [{
      id: 1,
      name: "string",
    }],
    product_description: {
      data: {
        id: "1",
        type: "description",
        attributes: {
          chemical_name: "(R)-4 (Ethylamino)-3,4-dihydro-2-(3-methoxypropyl)-2H-thieno[3,2-e]-1,2-thiazine-6-sulfonamide 1,1-dioxide.",
          product_dmf: "Lorem Ipsum is simply dummy text of the printing and one or more typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry.  Lorem Ipsum is simply dummy text of the printing and one or more typesetting industry.",
        }
      }
    },
    product_documents: { data: [] },
  }
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductDetailWebController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  authToken: string;
  getProductDetailsApiCallId: string;
  getFilesListApiCallId: string;
  userDetails: IUserdetails;
  getCustomerProfileApiCallId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      IsKnowMore: true,
      showChemicalName: true,
      isDocumentSelect: false,
      TherapueticAreaLabel: configJSON.TherapueticAreaLabel,
      ApiTechnologylabel: configJSON.ApiTechnologylabel,
      Category: configJSON.Category,
      ChemicalLabel: configJSON.ChemicalLabel,
      selectedOption: "Description",
      descriptionlabel: configJSON.descriptionlabel,
      documentLabel: configJSON.documentLabel,
      passwordTioolTip: configJSON.passwordTioolTip,
      openToolTip: false,
      anchorEl: null,
      isQueryraised: false,
      isQuesryOptions: false,
      noDocumentsAvailableText: configJSON.noDocumentsAvailableText,
      popUPText: "",
      isDMFBucket: false,
      Data: {
        Therapuetic_Area: 'Immunosuppressants',
        product_name: 'Everolimus',
        api_technology: "Peptide",
        category: 'Commercial',
        chemicalName: `(R)-4 (Ethylamino)-3,4-dihydro-2-(3-methoxypropyl)-2H-thieno[3,2-e]-1,2-thiazine-6-sulfonamide 1,1-dioxide.`,
        description: 'Lorem Ipsum is simply dummy text of the printing and one or more typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standardd',
        detaildescription: `Lorem Ipsum is simply dummy text of the printing and one or more typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry.  Lorem Ipsum is simply dummy text of the printing and one or more typesetting industry.`,
      },
      isGuestPopup: false,
      documentList: [
        {
          name: 'Document 1',
          isPasswordProtected: false,
        },
        {
          name: 'Document 2',
          isPasswordProtected: true,
        },
        {
          name: 'Document 3',
          isPasswordProtected: false,
        },
        {
          name: 'Document 4',
          isPasswordProtected: true
        }
      ],
      queryOptions: [
        {
          option: 'Product Query',
          route: 'product'
        },
        {
          option: 'Sample Request',
          route: 'sample'
        },
        {
          option: 'Meeting Request',
          route: 'meeting'
        }
      ],
      productDetail: null,
      loading: false,
      isAvailable: false,
      catalogueId: '',
      viewFolderId: '',
      nograntAccess: false,
      noGrantaccessMsg: '',
      shouldRaiseQueryPopup: false,
      bucket_id: 0,
      ProductId: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.handleRaiseQueryChoice = this.handleRaiseQueryChoice.bind(this);
    this.setDescription = this.setDescription.bind(this);
    this.nextToRaiseQuery = this.nextToRaiseQuery.bind(this);
    this.closeGuestPopup = this.closeGuestPopup.bind(this);
    this.handleRaiseQueryClick = this.handleRaiseQueryClick.bind(this);
    this.closeShouldRaiseQuery = this.closeShouldRaiseQuery.bind(this);
    this.setEnableField = this.setEnableField.bind(this);
    this.setInputValue = this.setInputValue.bind(this);
    this.doButtonPressed = this.doButtonPressed.bind(this);
    this.raiseQuery = this.raiseQuery.bind(this);
    this.getEmployeeFolderFiles = this.getEmployeeFolderFiles.bind(this);
    this.handleViewClick = this.handleViewClick.bind(this);
    this.getFolderFiles = this.getFolderFiles.bind(this);
    this.isCustomerLoggedIn = this.isCustomerLoggedIn.bind(this);
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getProductDetailsApiCallId) {
          this.setState({
            loading: false
          })
          if (responseJson.data) {
            this.setState({
              productDetail: responseJson.data
            });
          } else {
            if (responseJson.errors && responseJson.errors[0]?.token === "Token has Expired" && JSON.parse(localStorage.getItem("userInfo") || "")?.attributes?.is_customer === 'True') {
              this.setState({
                loading: true
              })
              await handleUpdateAuthToken()
              const authToken = isLoggedIn(this.props.navigation);
              if (typeof (authToken) === "string") {
                this.authToken = authToken;
              }
              if (this.authToken) {
                this.fetchProductDetail();
              }
            }
          }
        }

        if (apiRequestCallId === this.getFilesListApiCallId) {
          if (responseJson?.errors) {
            responseJson.errors.forEach((res: any) => {
              warningNotification(res.error_message);
            });
          }
          if (responseJson?.error_message) {
            warningNotification(responseJson.error_message);
          }
          else if (responseJson?.result) {
            this.setState({
              loading: false
            })
            if (responseJson.result.length === 0) {
              warningNotification('No Document Found.');
            } else {
              responseJson.result.forEach((res: any) => {
                if (res.error_message === undefined) {
                  this.setState({ nograntAccess: true });
                } else {
                  this.setState({ noGrantaccessMsg: res.error_message });
                }
              });
              if (this.props.navigation) {
                if (this.state.nograntAccess) {
                  this.setState({
                    loading: false
                  })
                  this.props.navigation.navigate(`DocumentView`, {
                    CatalogueId: this.state.catalogueId,
                    BucketId: this.state.viewFolderId
                  });
                } else {
                  this.setState({
                    loading: false
                  })
                  this.setState({
                    shouldRaiseQueryPopup: true
                  })
                }
              }
            }
            this.setState({
              loading: false
            })
          }
        }

        if (apiRequestCallId === this.getCustomerProfileApiCallId) {
          if (responseJson.message === "Profile not found.") {
            logout(this.props.navigation);
          }
          this.setState({
            loading: false
          });
          if (responseJson.errors && responseJson.errors[0]?.token === "Token has Expired" && JSON.parse(localStorage.getItem("userInfo") || "")?.attributes?.is_customer === 'True') {
            this.setState({
              loading: true
            })
            await handleUpdateAuthToken()
            const authToken = isLoggedIn(this.props.navigation);
            if (typeof (authToken) === "string") {
              this.authToken = authToken;
            }
            if (this.authToken) {
              this.isCustomerLoggedIn();
            }
          }
        }
      }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  // Customizable Area Start

  async componentDidMount() {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        if (!isGuest()) {
          autoLogOut();
        }
      });
    });
    const from = localStorage.getItem("from");
    const ProductId = localStorage.getItem("ProductId");
    this.setState({
      catalogueId: ProductId,
      ProductId: ProductId
    });
    if (from === 'document') {
      this.setState({ selectedOption: 'Documents' });
      localStorage.setItem("from", "product")
    }
    const userDetailToken = getUserdetails();
    if (userDetailToken) {
      this.userDetails = userDetailToken;
      if (!isEmployee()) {
        this.isCustomerLoggedIn();
      }
    }
    this.fetchProductDetail();

  }

  fetchProductDetail = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const ProductId = localStorage.getItem("ProductId");
    const url = configJSON.getProductDetailApiEndPoint + ProductId;
    const fetchProductMessage = apiCall({
      url: url,
      header: headers,
      httpBody: {},
      httpMethod: configJSON.getProductDetailApiMethod
    });

    this.getProductDetailsApiCallId = fetchProductMessage.messageId;
    runEngine.sendMessage(fetchProductMessage.id, fetchProductMessage);
    this.setState({
      loading: true
    })
  }

  changeDetails = (selected: string) => {
    let bool;
    if (selected == "Documents") {
      bool = false
    } else {
      bool = true
    }
    this.setState({ selectedOption: selected })
    this.setState({ showChemicalName: bool })
  }

  handleToolTip = (event: any, text: any) => {
    this.setState({ openToolTip: !this.state.openToolTip });
    this.setState({ anchorEl: event.currentTarget });
    this.setState({ popUPText: text });
  };

  raiseQuery = () => {
    if (isGuest()) {
      this.setState({
        isDocumentSelect: false,
        isGuestPopup: true
      });
      return;
    }
    this.isCustomerLoggedIn();
    this.setState({ bucket_id: '' });
    localStorage.setItem("viewFolderId", '')
    if (isGuest()) {
      this.setState({
        isDocumentSelect: false,
        isGuestPopup: true,
      });
    } else {
      this.setState({ isQueryraised: !this.state.isQueryraised });
      this.setState({ isQuesryOptions: false })
    }
  }

  closeGuestPopup = () => {
    this.setState({
      isGuestPopup: false
    });
  }

  nextToRaiseQuery = () => {
    this.setState({ isQuesryOptions: !this.state.isQuesryOptions })
    this.setState({ isQueryraised: !this.state.isQueryraised })
  }

  setDescription = () => {
    this.setState({ IsKnowMore: !this.state.IsKnowMore })
  }

  handleRaiseQueryChoice = (option: string) => {
    this.setState({ isQueryraised: !this.state.isQueryraised, isQuesryOptions: false });
    if (this.props.navigation) {
      this.props.navigation.navigate("CustomerProductQuery", { query: option, id: this.state.productDetail?.id || '' });
    }
  }

  getFolderFiles = (bucketId: any, catalogueId: any) => {
    this.setState({ loading: true });
    const authToken = localStorage.getItem("authToken") || '';
    const header = getHeaders({ token: authToken });

    const getEmpFiles = apiCall({
      httpBody: {},
      header: header,
      url: configJSON.getFilesListApiEndpoint + `?id=${bucketId}&catalogue_id=${catalogueId}`,
      httpMethod: configJSON.getEmployeeFilesListApiMethod,
    });

    this.getFilesListApiCallId = getEmpFiles.messageId;
    runEngine.sendMessage(getEmpFiles.id, getEmpFiles);
  }

  getEmployeeFolderFiles = (bucketId: any, catalogueId: any, bucket: any) => {
    if (bucket?.confidential) {
      if (localStorage.getItem("employee_document_view_permission") === "true") {
        localStorage.setItem('fromPage', "ProductDetail");
        this.props.navigation.navigate(`DocumentView`, {
          CatalogueId: this.state.catalogueId,
          BucketId: this.state.viewFolderId,
          isDMFBucket: false,
          isGuestPopup: false,
        });
      } else if (bucket.bucket.toLowerCase() === 'dmf') {
        localStorage.setItem('fromPage', "ProductDetail");
        this.props.navigation.navigate(`DocumentView`, {
          CatalogueId: this.state.catalogueId,
          BucketId: this.state.viewFolderId,
          isDMFBucket: false,
          isGuestPopup: false,
        });
      } else {
        this.setState({
          isGuestPopup: true,
          isDMFBucket: true
        })
      }
    } else {
      localStorage.setItem('fromPage', "ProductDetail");
      this.props.navigation.navigate(`DocumentView`, {
        CatalogueId: this.state.catalogueId,
        BucketId: this.state.viewFolderId,
        isDMFBucket: false,
        isGuestPopup: false,
      });
    }
  }

  handleViewClick = (docId: any, bucket: any) => {
    if (this.state.productDetail) {
      localStorage.setItem("productName", this.state.productDetail?.attributes.name)
    }
    if (isGuest()) {
      this.setState({
        isDocumentSelect: true,
        isGuestPopup: true
      });
      return;
    }
    this.setState({ bucket_id: bucket });
    this.setState({
      viewFolderId: docId
    }, () => {
      if (isEmployee()) {
        const userRole = this.userDetails.attributes?.role_id;
        if (bucket.bucket.toLowerCase() === 'dmf' && userRole !== 12) {
          this.setState({
            isDMFBucket: true,
            isGuestPopup: true,
          })
        } else {
          this.setState({
            isDMFBucket: false,
            isGuestPopup: false,
          })
          this.getEmployeeFolderFiles(docId, this.state.catalogueId, bucket);
        }
        return;
      } else {
        this.getFolderFiles(docId, this.state.catalogueId);
        return;
      }
    })
  };

  closeShouldRaiseQuery = () => {
    this.setState({
      shouldRaiseQueryPopup: false
    });
  }

  handleRaiseQueryClick = () => {
    this.setState({ shouldRaiseQueryPopup: false });
    localStorage.setItem('viewFolderId', JSON.stringify(this.state.bucket_id))
    if (this.props.navigation) {
      this.props.navigation.navigate("CustomerProductQuery", { query: 'product', id: this.state.productDetail?.id || '' });
    }
  };

  isCustomerLoggedIn = () => {
    const authToken = localStorage.getItem("authToken") || '';
    const header = getHeaders({ token: authToken });
    const getAccount = apiCall({
      httpBody: {},
      header: header,
      url:
        configJSON.getCustomerProfileApiEndpoint +
        this.userDetails.attributes.customer_profile_id,
      httpMethod: configJSON.getFilesListApiMethod,
    });

    this.getCustomerProfileApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
    this.setState({
      loading: true
    })
  };
  // Customizable Area End

}
