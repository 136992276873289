import React from "react";
// Customizable Area Start
import {
  Typography,
  Grid,
  Button,
  Box,
  TextField,
  InputAdornment,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { AppHeader } from "../../../components/src/AppHeader.web";

import AppFooter from "../../../components/src/AppFooter.web";
import { withStyles } from "@material-ui/core/styles";
import TicketRepliesController, { Props } from "./TicketRepliesController.web";
import CloseIcon from '@material-ui/icons/Close';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Loader from "../../../components/src/Loader.web";
import Upload from "./AttachmentView.web";
import { NavLink } from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Logos } from "../../../blocks/DataStorage/src/assets";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
export class TicketReplies extends TicketRepliesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  statusObject() {
    return this.state?.closed_status == "Closed" ? webStyle.cardBadge : this.state?.ticket_status == "Open" ? webStyle?.cardBadge2 : webStyle?.cardBadge3
  }

  AttachmentPopUp() {
    return (
      <AttachmentDialog
        open={this.state.isAttachment}
        // onClose={this.handleIsCreatedClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Typography style={webStyle.dialogTitle} align="center">
            Upload
          </Typography>
          <IconBox onClick={this.handleCloseAttachmentPopUP}>
            <CloseIcon style={{ fill: '#1A3B71', cursor: 'hand' }}>
            </CloseIcon>
          </IconBox>
        </DialogTitle>
        <DialogContent>
          <Grid style={webStyle.attachmentBox}>
            <Upload
              setSelectedFile={this.handleDetailsDocumentUpload}
              setFileSizeError={this.setFileSizeError}
            />
          </Grid>
        </DialogContent>
        <Grid container justifyContent="space-between">
          <Typography style={this.state.isUploadError ? webStyle.maxSizeTextError : webStyle.maxSizeText}>Max. file size should be 10 Mb</Typography>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            {
              this.state.uploadedAPIFilesBase.map((data: any, index: number) => {
                return (
                  <Typography style={webStyle.documentNameText} key={index} align="right">
                    {data.file_name}
                  </Typography>
                )
              })
            }
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Button
            variant="outlined"
            style={{ ...webStyle.cancelButton, textTransform: "none" }}
            onClick={this.handleCloseAttachmentPopUP}
          >
            Cancel
          </Button>
          <Button
            data-testid='btnisAttachment'
            variant="contained"
            style={{ ...webStyle.submitButton, textTransform: "none" }}
            onClick={() => {
              this.setState({
                isAttachment: !this.state.isAttachment
              })
            }}
          >
            Submit
          </Button>
        </Grid>
      </AttachmentDialog>
    )
  };
  // Customizable Area End

  render() {
    const { classes }: any = this.props
    return (
      <React.Fragment>
        <ThemeProvider theme={theme}>
          <AppHeader navigation={this.props.navigation} />
          <Loader loading={this.state.is_loading} />
          <Grid style={webStyle.mainContainer} className={classes?.mainContainerRes}>
            <div style={{ display: "flex", marginTop: '20px' }}>
              <NavLink to={`/QueryDetails/${this.ticketId}`}>
                <KeyboardBackspaceIcon
                  style={{
                    ...webStyle.queryDetailsBackButton
                  }}
                  fontSize="large"
                />
              </NavLink>
              <Typography style={webStyle.detailTitle}>
                Ticket ID: {this.state.ticket_number}
                <Box>
                  <Grid container justifyContent="center" alignItems="center"
                    style={this.statusObject()}
                    className={classes?.cardBadgeRes}
                  >
                    {this.state.closed_status ? "Closed" : this.state?.ticket_status}
                  </Grid>
                </Box>
              </Typography>
            </div>
            {this.state.query_replies && this.state.query_replies.length > 0 ?
              this.state.query_replies.map((replyObject: any, index: any) => {
                return (
                  <Grid key={`reply-${index}`}>
                    <Grid container style={{ justifyContent: "space-between", alignItems: "center" }}>
                      <Grid item container xs={12} style={{ gap: "5px", alignItems: "center" }}>
                        {replyObject?.employee_name && <img style={webStyle.profilePic} src={Logos} />}
                        {replyObject?.user_name && <div style={webStyle.profilePic}>{replyObject?.user_name.charAt(0)}</div>}
                        <div>
                          {
                            (replyObject.employee_name || replyObject.user_name) && (
                              <Typography style={webStyle.profileName}>
                                {(replyObject?.employee_name?.length > 0 ? "Biocon Team" : null) || replyObject.user_name}
                              </Typography>
                            )
                          }
                        </div>&nbsp;&nbsp;
                        <Typography style={{
                          color: 'color: #a3a3a3',
                          opacity: "0.6",
                          fontWeight: 500,
                          fontSize: '14px'
                        }}>
                          {replyObject.reply_time}
                        </Typography>
                      </Grid>
                      {/* <Grid xs={4}> */}
                      {/* </Grid> */}
                      <Grid  style={{ paddingLeft: '48px', paddingTop: '5px', width: 'auto', minWidth: '230px' }}>
                        <div data-testid='btnAttachment'>
                          {
                            replyObject.attachment !== undefined && (
                              replyObject.attachment.map((data: any, index: any) => {
                                return (
                                  <div  key={index} style={webStyle.documentMain}>
                                    <AttachFileIcon
                                      style={webStyle.attachmentIcon}
                                      className="attachment_icon"
                                    >
                                    </AttachFileIcon>
                                    <Typography
                                      
                                      style={webStyle.documentName}
                                      key={index}
                                      onClick={() => this.downloadAPIAttachmentFile(data)}
                                    >
                                      {data.file_name}
                                    </Typography>
                                  </div>
                                )
                              })
                            )
                          }
                        </div>
                      </Grid>
                      <Grid item xs={12} >
                        <div style={{ paddingTop: '15px', paddingLeft: '48px' }}>
                          {
                            replyObject.reply && (
                              <Typography>
                                {replyObject.reply}
                              </Typography>
                            )
                          }
                        </div>
                      </Grid>
                      <div style={{ border: '1px solid #e7e8ed', width: '100%', marginTop: '20px', marginLeft: '48px', marginBottom: '20px' }} />
                    </Grid>


                  </Grid>
                )
              }) : <p>No Replies Found</p>}
            {
              this.state.ticket_status !== 'Closed' && (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <InputField
                    data-testid='onChangeComment'
                    id="ticketId"
                    variant="outlined"
                    value={this.state.ticketComment}
                    onChange={this.handleCommentChange}
                    required
                    placeholder="Type a message..."
                    fullWidth
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end">
                          <AttachFileIcon
                            style={webStyle.attachmentIcon}
                            className="attachment_icon"
                            onClick={this.handleAttachmentPopUP}
                          >
                          </AttachFileIcon>
                          <Button
                            style={{ ...webStyle.sendButton, textTransform: 'none' }}
                            variant="contained"
                            onClick={this.sendComment}
                            disabled={this.state.ticketComment === ""}
                          >
                            Send
                          </Button>
                        </InputAdornment>
                    }}
                  />
                </Grid>
              )
            }
            {this.AttachmentPopUp()}
            {this.state.close_message &&
              <Grid style={webStyle.ticketClosed}>
                <Typography style={webStyle.ticketClosedText}>
                  {this.state.close_message}
                </Typography></Grid>
            }
          </Grid>
        </ThemeProvider>
        <AppFooter isLoading={this.state.is_loading} />
      </React.Fragment>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start



const webStyle = {
  ticketClosedText: {
    fontFamily: "SFProDisplay-Medium",
    fontSize: '16px',
  },
  ticketClosed: {
    margin: "50px 0",
    padding: "15px",
    backgroundColor: "#F7EDEC",
    // borderRadius: "8px",
    color: '#1E385C',
  },
  documentMain: {
    backgroundColor: "#f5f5fa",
    display: 'flex',
    alignItems: 'center',
    padding: '3px',
    borderRadius: '6px',
    border: '1px solid #dae0f1',
    height: '30px',
    paddingRight: '15px',
  },
  profilePic: {
    borderRadius: "30px",
    width: '30px',
    height: '30px',
    fontSize: '20px',
    backgroundColor: "#f4f3f9",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  profileName: {
    fontamily: 'SFPro',
    fontSize: '16px',
    fontWeight: 600,
    color: '#043c74',
    marginLeft: '12px'
  },
  theReply: {
    margin: "5px 30px 0px"
  },
  queryDetailsBackButton: {
    color: "rgb(4, 60, 116)",
    cursor: "pointer",
    marginTop: '25px',
    marginRight: '10px',
  },
  cardButton: {
    borderRadius: "8px",
    height: "42px",
    width: "146px",
    fontFamily: "SFProDisplay",
    fontWeight: 500,
    marginTop: " 24px",
    boxShadow: "none",
    backgroundColor: "rgba(4, 60, 116, 1)",
    color: "#fff",
  },
  QueryValues: {
    fontWeight: 600,
    justifyContent: "space-between",
  },
  deteLabel: {
    color: "rgba(4, 60, 116, 1)",
    fontSize: "16px",
    fontFamily: "SFProDisplay",
    fontWeight: 400,

  },
  cardBadge: {
    // alignItems: "center",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    borderRadius: "16px",
    fontSize: '16px',
    fontFamily: "SFProDisplay",
    fontWeight: 600,
    boxShadow: "none",
    color: "#e34b45",
    width: "140px",
    height: '36px',
    // padding: "4px 33px",
    backgroundColor: "#f7eeed",
    marginLeft: '24px'
  },
  valueBoxes: {
    width: "150px",
    alignItems: "center"

  },
  QueryTitles: {
    fontWeight: 600,
    color: "rgba(4, 60, 116, 1)",
    justifyContent: "space-between",
    alignItems: "center",

  },
  mainContainer: {
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    padding: "8px 115px 10px 134px"

  },
  filterContainer: {
    backgroundColor: "rgb(239 241 245)",
    padding: "15px 126px 25px 143px",
  },
  detailTitle: {
    color: "rgba(33, 43, 54, 1)",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    fontSize: "25px",
    marginTop: "24px",
    marginBottom: "49px",
    display: 'flex',
    alignItems: "center",
  },
  QueryDescription: {
    marginTop: "19px",
    marginBottom: "10px",
    padding: "0px",
    color: "rgba(4, 60, 116, 1)",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
  },
  listName: {
    display: "flex",
    marginTop: "30px",
    marginLeft: "50px",
    fontSize: "x-large",
    fontWeight: 600,
    color: "rgba(4, 60, 116, 1)",
  },
  viewAll: {
    display: "flex",
    justifyContent: "right",
    textDecoration: "underline",
    color: "rgb(4, 60, 116)",
    marginTop: "-1.5rem",
  },
  productContainer: {
    backgroundColor: "white",
  },
  NoDataContainer: {
    height: "220px",
  },
  noDataTitle: {
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    color: "rgb(4, 60, 116)",
    fontSize: "24px",
    marginTop: "-20px",
  },
  attachmentIcon: {
    margin: '8px 5px',
    width: '20px',
    height: '20px',
    fill: '#043c74',
    cursor: 'pointer'
  },
  sendButton: {
    width: '105px',
    height: '34px',
    margin: '8px 0px 8px 24px',
    padding: '10px 42px',
    borderRadius: '7px',
    backgroundColor: '#043c74',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: 1.25,
    color: '#fff',
    boxShadow: 'none',
  },
  dialogTitle: {
    margin: '0px 28px 24px 14px',
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: 1.33,
    color: '#043c74',
    width: '100%'
  },
  attachmentBox: {
    height: '136px',
    margin: '0px 25px 8px 39px',
    padding: '20px 162px 20px 163px',
    borderRadius: '9px',
    border: 'dashed 1px #6a74a5',
    backgroundColor: '#f5f5fa',
  },
  maxSizeText: {
    margin: '0px 73px 5px 40px',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: 1.67,
    color: '#6a74a5',
  },
  maxSizeTextError: {
    margin: '0px 73px 5px 20px',
    fontFamily: 'SFProDisplay-Regular',
    fontSize: '12px',
    lineHeight: 1.67,
    color: 'red',
  },
  documentNameText: {
    margin: '0px 50px 4px 0px',
    fontFamily: 'SFProDisplay-Regular',
    fontSize: '12px',
    lineHeight: 1.67,
    color: '#6a74a5',
  },
  cancelButton: {
    margin: '24px 12px 0 0px',
    padding: '4px 49px 4px 50px',
    borderRadius: '8px',
    border: 'solid 1px #003d78',
    backgroundColor: '#fff',
  },
  submitButton: {
    margin: '24px 0px 0 12px',
    padding: '4px 49px',
    borderRadius: '8px',
    backgroundColor: '#003d78',
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: 2.29,
    color: '#fff',
    boxShadow: 'none',
  },
  documentName: {
    fontFamily: 'SFProDisplay',
    fontSize: '16px',
    lineHeight: 1.88,
    letterSpacing: '-0.16px',
    color: '#719cdc',
    marginLeft: '10px',
    marginBottom: '2px',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  cardBadge3: {
    // alignItems: "center",
    // display: "flex",
    // justifyContent: "center",
    borderRadius: "16px",
    fontFamily: "SFProDisplay",
    fontWeight: 600,
    boxShadow: "none",
    backgroundColor: '#FFF7E6',
    color: '#EBC74F',
    width: "140px",
    height: "36px",
    // padding: '6px 33px',
    fontSize: "16px",
    marginLeft: '24px'
  },
  cardBadge2: {
    // alignItems: "center",
    // display: "flex",
    // justifyContent: "center",
    borderRadius: "16px",
    fontFamily: "SFProDisplay",
    fontWeight: 600,
    boxShadow: "none",
    color: "#79b35f",
    width: "140px",
    height: "36px",
    // padding: '2px 33px',
    fontSize: "16px",
    backgroundColor: '#EBF7F3',
    marginLeft: '24px'
  },
};

const InputField : any = withStyles({
  root: {
    margin: '48px 0px 78px 0px',
    "& ::placeholder": {
      fontSize: '14px',
      lineHeight: 1.25,
      color: '#444',
      fontFamily: 'SFProDisplay',
      fontWeight: 400,
      opacity: 1,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: '#dae0f1',
    },
    "& .MuiInputBase-input": {
      height: '15px',
    },
    "& .MuiOutlinedInput-input": {
      padding: `15px 16px !important`
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: "SFProDisplay",
      fontWeight: 400,
      backgroundColor: "#f5f5fa",
      fontSize: "14px",
      "& fieldset": {
        height: '49px',
        borderColor: '#dae0f1',
        borderRadius: "8px",
        borderWidth: 1,
      },
      ".Mui-focused": {
        borderColor: '#dae0f1',
      },
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px !important",
    },
    "& .MuiInputBase-input.Mui-disabled": {
      background: "#F1F4FA",
      borderRadius: "8px",
      height: 13,
      color: 'rgba(106, 116, 165, 1)',
      fontSize: '16px',
      fontFamily: 'SFProDisplay',
      fontWeight: 400,
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: 'rgba(185, 187, 197, 1)',
      borderWidth: '1px'
    },
    "& .MuiIconButton-root": {
      paddingLeft: '0px'
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: '0px'
    },
    "& .MuiSvgIcon-root": {
      width: '30px',
      height: '40px',
    },
  },
})(TextField);

const AttachmentDialog : any = withStyles({
  root: {
    "& .MuiDialogTitle-root": {
      padding: '18px 0px 0px 0px'
    },
    "& .MuiDialog-paperScrollPaper": {
      padding: '16px 16px 35px 20px',
      borderRadius: '12px',
    },
    "& .MuiDialogContent-root": {
      padding: '0px 20px 0px 0px',
      // textAlign: 'center',
      // paddingBottom: '10px'
    },
    "& .MuiDialog-paperWidthSm": {
      minWidth: '575px',
    },
    "& .MuiDialogActions-root": {
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px',
      paddingBottom: '30px',
    },
    "& .WithStyles\(ForwardRef\(Button\)\)-root-10": {
      "@media (maxWidth: 600px)": {
        width: 600
      }
    },
    "& .MuiTypography-h6": {
      display: 'flex',
      justifyContent: 'space-between',
    }
  }
})(Dialog);

const IconBox : any = withStyles({
  root: {
    background: '#DAE0EC',
    height: '24px',
    width: '24px',
    borderRadius: '50%',
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 2px 0px 2px',
    marginTop: '-19px',
    "& .MuiSvgIcon-root": {
      height: '16px',
      width: '16px'
    },
  }
})(Box);


const styles = () => ({
  mainContainerRes: {
    '@media (max-width: 600px)': {
      padding: '15px !important',
      height: '60vh',
    },
  }
})

export default withStyles(styles)(TicketReplies)

// Customizable Area End
