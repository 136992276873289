import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {
  apiCall,
  authorizeEmployee,
  getHeaders,
  isAuthorisedUser,
  isLoggedIn,
  IUserdetails,
  events,
  autoLogOut
} from "../../../components/src/common";
import { string } from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

export interface IBamDetails {
  biocon_account_manager_country_code: number;
  biocon_account_manager_email: string;
  biocon_account_manager_name: string;
  biocon_account_manager_phone_number: number;
  territory: string;
  biocon_account_manager_id: number;
  territory_id: number;
}

interface IBioconManager {
  country_code: number,
  created_at: string;
  email: string;
  name: string;
  id: number;
  last_name: string;
  phone_number: number;
  role_id: string;
  updated_at: string;
  employee_territory_id: number;
}

export interface ICustomerContactGet {
  contact_person_email_id: string;
  customer_contact_person: string;
  customer_name: string;
}

interface IBioconManagerTerritory {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface ICustomerContacts {
  id: number;
  name: string;
  email: string;
  company: string;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  userDetailLabel: string;
  labelCustomerName: string;
  parentCustomerLabel2: string;
  sapCodeLabel: string;
  customerTypeLabel: string;
  channelLabel: string;
  privacyPolicyLabel: string;
  selectedTab: number;
  ClassificationLabel: string;
  customerContactLabel: string;
  territoryLabel: string;
  ModeOfBusinessLabel: string;
  CurrencyLabel: string;
  websiteLabel: string;
  billingStreetLabel: string;
  shippingStreetLabel: string;
  billingCityLabel: string;
  shippingCityLabel: string;
  shippingPostalCodeLabel: string;
  billingPostalCodeLabel: string;
  billingStateLabel: string;
  shippingStateLabel: string;
  billingCountryLabel: string;
  shippingCountryLabel: string;
  accountManagerLabel: string;
  alternateEmailLabel: string;
  industriesList: any[];
  codeList: any[];
  firstName: string;
  firstNameError: string;
  lastName: string;
  companyName: string;
  emailId: string;
  phoneNumber: string;
  phoneCountryCode: string;
  agreedTermsAndCondition: boolean;
  address: string;
  industry: number;
  designation: string;
  landlineNumber: string;
  areaOfBusiness: string;
  subsidiaryName: string;
  alternateEmail: string;
  isTermsCondition: boolean;
  isLoading: boolean;
  privacyPolicyText: string;
  termslabelText: string;
  termsLabel: string;
  bamEmailIdLabel: string;
  bamPhoneNoLabel: string;
  companySapCode: string;
  customer_type: number;
  channel: number;
  classification: number;
  mode_of_business: number;
  account_currency: number;
  customer_contact: number;
  website: string;
  billing_street: string;
  billing_city: string;
  billing_postal_code: string;
  billing_state: string;
  billing_country: string;
  shipping_street: string;
  shipping_city: string;
  shipping_postal_code: string;
  shipping_state: string;
  shipping_country: string;
  is_same_as_billing_address: boolean;
  all_customer_types: any[];
  all_channels: any[];
  all_classifications: any[];
  all_territories: any[];
  all_mode_of_businesses: any[];
  all_account_currencies: any[];
  territory: any[];
  role_id: number;
  employee_territory_id: number;
  created_at: any;
  updated_at: any;
  email: string;
  industryLabel: string;
  biocon_account_managers: IBioconManager[];
  mappedProductLabel: string;
  products: IProduct[];
  catlogIds: number;
  biocon_manager: any;
  territory_id: number;
  categories: string;
  customerContact: ICustomerContactGet | [];
  mappedProducts: IProductGet[];
  bam_details: IBamDetails | null;
  biocon_manager_id: number;
  biocon_manager_territories: IBioconManagerTerritory[];
  isView: boolean;
  multipleProductForm: any;
  isBamEdit: boolean;
  isProductMapUpdate: boolean;
  stateUserId: null | number;
  stateIsUpdate: boolean;
  isCreatedPopup: boolean;
  createdCustomerPopupText: string;
  customerMaster: string;
  parentCompanyList: [];
  selectedParentCompanyId: number;
  isCustomerContactUpdate: boolean;
  allCustomerContacts: ICustomerContacts[];
  contactPersonEmail: string;
  contactPersonName: string;
  contactPersonCompany: string;
  selectedContactCustomerId: number;
  selectedCustomerContacts: any[];
  isDeletePopup: boolean;
  deleteCustomerContactId: number;
  deleteCustomerContactPopupText: string;
  deleteProductMappedPopupText: string;
  personalDetailsId: string;
  isProductMapDelete: boolean;
  territory_name: string;
  isEditDetails: boolean;
  contactNameLabel: string;
  productNameLabel: string;
  invoiceLabel: string;
  invoiceDateLabel: string;
  invoiceAmountLabel: string;
  invoiceCurrencyLabel: string;
  invoicePOLabel: string;
  invoiceDueDateLabel: string;
  invoicePaymentTermsLabel: string;
  invoiceIncoTermsLabel: string;
  invoicePaymentStatusLabel: string;
  invoiceAttachmentLabel: string;
  invoiceAddLabel: string;
  attachmentLabel: string;
  uploadedFiles: any[];
  uploadedFilesBase64: any[];
  allIncoTerms: any[];
  productsList: any[],
  allPaymentTerms: any;
  invoiceDetail: any[];
  contactName: any;
  companyId: any;
  deleteInvoiceId: number;
  isInvoiceDelete: boolean;
  isInvoiceEdit: boolean;
  deleteInvoicePopupText: string;
  paymentStatus: any;
  attachmentError: any;
  allcustomerInvioce: any[];
  editUserId: any;
  customerParamId: any;
  invoiceFormDetails: any;
  getAttachment: any;
  isInvoiceLoading: boolean;
  customerInvoiceListingMeta: any;
  isViewOnly: boolean;
  page: any;
  perPageRecord: any;
  invalidDateError: any;
  // Customizable Area End
}
export interface IProduct {
  name: string;
  isSelected: boolean;
  isAlreadySelected: boolean;
  id: number
}

export interface IProductGet {
  id: string;
  type: string;
  attributes: {
    product_id: number;
    api_technology: string;
    product_name: string;
    therapeutic_area: string;
  }
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddInvoiceController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createAccountApiCallId: any;
  updateAccountApiCallId: any;
  updateCustomerProfileApiCallId: any;
  updateProductMappedApiCallId: any;
  updateBAMProfileApiCallId: any;
  getCustomerPersonalApiCallId: any;
  getUserPersonalApiCallId: any;
  getCustomerBAMApiCallId: any;
  getMappedProductAPICallId: any;
  getProductMappedDetailAPICallId: any;
  getAllListApiCallId: any;
  getAllBioconManagerApiCallId: any;
  getAllEmployeeTerritoriesCallId: any;
  getAllParentCompanyListCallId: any;
  getAllCustomerContactsApiCallId: any;
  saveCustomerContactApiCallId: string;
  deleteCustomerContactApiCallId: string;
  deleteProductMappedApiCallId: string;
  userDetails: IUserdetails;
  authToken: string;
  isUpdate: boolean;
  userId: number;
  editUserId: number;
  customerParamId: any;
  getCompaniesListApiCallId: string;
  getIncoTeamListApiCallId: string;
  getCategoriesApiCallId: any;
  getInvoiceCurrencyListApiCallId: string;
  getPaymentTermsListApiCallId: string;
  getProductNameListApiCallId: string;
  getIncoTermsListApiCallId: string;
  saveInvoiceApiCallId: string;
  createInvoiceApiCallId: any;
  getAllCustomerInvioceApiCallId: any;
  createInvoiceEditApiCallId: any;
  createContactInvoiceApiCallId: any;
  getByIdCustomerInvioceApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isProductMapDelete: false,
      createdCustomerPopupText: configJSON.createCustomerPopupText,
      userDetailLabel: configJSON.userDetailLabel,
      labelCustomerName: configJSON.labelCustomerName,
      parentCustomerLabel2: configJSON.parentCustomerLabel2,
      sapCodeLabel: configJSON.sapCodeLabel,
      customerTypeLabel: configJSON.customerTypeLabel,
      channelLabel: configJSON.channelLabel,
      privacyPolicyLabel: configJSON.privacyPolicyLabel,
      privacyPolicyText: configJSON.privacyPolicyText,
      termslabelText: configJSON.termslabelText,
      termsLabel: configJSON.termsLabel,
      selectedTab: 0,
      ClassificationLabel: configJSON.ClassificationLabel,
      customerContactLabel: configJSON.customerContactLabel,
      ModeOfBusinessLabel: configJSON.ModeOfBusinessLabel,
      CurrencyLabel: configJSON.CurrencyLabel,
      websiteLabel: configJSON.websiteLabel,
      billingStreetLabel: configJSON.billingStreetLabel,
      shippingStreetLabel: configJSON.shippingStreetLabel,
      billingCityLabel: configJSON.billingCityLabel,
      shippingCityLabel: configJSON.shippingCityLabel,
      billingPostalCodeLabel: configJSON.billingPostalCodeLabel,
      shippingPostalCodeLabel: configJSON.shippingPostalCodeLabel,
      billingStateLabel: configJSON.billingStateLabel,
      shippingStateLabel: configJSON.shippingStateLabel,
      billingCountryLabel: configJSON.billingCountryLabel,
      shippingCountryLabel: configJSON.shippingCountryLabel,
      accountManagerLabel: configJSON.accountManagerLabel,
      alternateEmailLabel: configJSON.alternateEmailLabel,
      allCustomerContacts: [],
      industriesList: [],
      codeList: [],
      firstName: "",
      firstNameError: '',
      lastName: "",
      companyName: "",
      emailId: "",
      phoneCountryCode: "91",
      phoneNumber: "",
      agreedTermsAndCondition: true,
      address: "",
      industry: 0,
      designation: "",
      landlineNumber: "",
      areaOfBusiness: "",
      subsidiaryName: "",
      alternateEmail: "",
      isTermsCondition: true,
      isLoading: false,
      isInvoiceLoading: false,
      bamEmailIdLabel: configJSON.bamEmailIdLabel,
      bamPhoneNoLabel: configJSON.bamPhoneNoLabel,
      territoryLabel: configJSON.territoryLabel,
      companySapCode: "",
      customer_type: 0,
      channel: 0,
      classification: 0,
      mode_of_business: 0,
      territory: [],
      account_currency: 0,
      customer_contact: 0,
      website: "",
      billing_street: "",
      billing_city: "",
      billing_postal_code: "",
      billing_state: "",
      billing_country: "",
      shipping_street: "",
      shipping_city: "",
      shipping_postal_code: "",
      shipping_state: "",
      shipping_country: "",
      mappedProductLabel: configJSON.mappedProductLabel,
      is_same_as_billing_address: false,
      all_customer_types: [],
      all_channels: [],
      all_classifications: [],
      all_territories: [],
      all_mode_of_businesses: [],
      all_account_currencies: [],
      role_id: 0,
      employee_territory_id: 0,
      created_at: '',
      updated_at: '',
      email: '',
      industryLabel: configJSON.industryLabel,
      biocon_account_managers: [],
      products: [],
      catlogIds: 0,
      biocon_manager: {},
      territory_id: 0,
      categories: '',
      biocon_manager_id: 0,
      biocon_manager_territories: [],
      customerMaster: "",
      isCustomerContactUpdate: false,
      customerContact: [],
      mappedProducts: [],
      multipleProductForm: [{ product_id: 0 }],
      bam_details: null,
      isView: false,
      isBamEdit: false,
      isProductMapUpdate: false,
      stateUserId: null,
      stateIsUpdate: false,
      isCreatedPopup: false,
      parentCompanyList: [],
      selectedParentCompanyId: 0,
      contactPersonEmail: "",
      contactPersonName: "",
      contactPersonCompany: "",
      selectedContactCustomerId: 0,
      selectedCustomerContacts: [],
      isDeletePopup: false,
      deleteCustomerContactId: 0,
      deleteCustomerContactPopupText: configJSON.deleteCustomerContactPopupText,
      deleteProductMappedPopupText: configJSON.deleteProductMappedPopupText,
      personalDetailsId: "",
      territory_name: "",
      isEditDetails: false,
      contactNameLabel: configJSON.contactNameLabel,
      productNameLabel: configJSON.productNameLabel,
      invoiceLabel: configJSON.invoiceLabel,
      invoiceDateLabel: configJSON.invoiceDateLabel,
      invoiceAmountLabel: configJSON.invoiceAmountLabel,
      invoiceCurrencyLabel: configJSON.invoiceCurrencyLabel,
      invoicePOLabel: configJSON.invoicePOLabel,
      invoiceDueDateLabel: configJSON.invoiceDueDateLabel,
      invoicePaymentTermsLabel: configJSON.invoicePaymentTermsLabel,
      invoiceIncoTermsLabel: configJSON.invoiceIncoTermsLabel,
      invoicePaymentStatusLabel: configJSON.invoicePaymentStatusLabel,
      invoiceAttachmentLabel: configJSON.invoiceAttachmentLabel,
      invoiceAddLabel: configJSON.invoiceAddLabel,
      attachmentLabel: configJSON.attachmentLabel,
      uploadedFiles: [],
      uploadedFilesBase64: [],
      allIncoTerms: [],
      productsList: [],
      allPaymentTerms: [],
      invoiceDetail: [],
      contactName: "",
      companyId: "",
      deleteInvoiceId: 0,
      isInvoiceDelete: false,
      isInvoiceEdit: true,
      attachmentError: '',
      allcustomerInvioce: [],
      editUserId: '',
      customerParamId: '',
      deleteInvoicePopupText: configJSON.deleteInvoicePopupText,
      paymentStatus: [
        {
          id: 0,
          value: 'Pending'
        },
        {
          id: 1,
          value: 'Paid'
        }
      ],
      getAttachment: "",
      invoiceFormDetails: {
        account_id: '',
        catalogue_id: '',
        invoice: '',
        invoice_date: '',
        invoice_amount: '',
        account_currency_id: '',
        p_o: '',
        due_date: '',
        payment_term_id: '',
        inco_term_id: '',
        payment_status: '',
        // attachment: {
        //   attachment: "",
        //   file_name: ""
        // },
        attachment: ""
      },
      isViewOnly: false,
      customerInvoiceListingMeta: null,
      page: 1,
      perPageRecord: 20,
      invalidDateError: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // this.onPersonalDetailsSave = this.onPersonalDetailsSave.bind(this);
    // this.onBAMDetailsSave = this.onBAMDetailsSave.bind(this);
    // this.onProductMappedDetailsSave = this.onProductMappedDetailsSave.bind(this);
    // this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    // this.handlePhoneCountryCode = this.handlePhoneCountryCode.bind(this);
    // this.handlechangeProducts = this.handlechangeProducts.bind(this);
    // this.personalDetailsValidation = this.personalDetailsValidation.bind(this);
    // this.onPersonalDetailsUpdate = this.onPersonalDetailsUpdate.bind(this);
    this.downloadAPIAttachmentFile = this.downloadAPIAttachmentFile.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        isAuthorisedUser(responseJson, this.props.navigation)
        if (apiRequestCallId === this.getCustomerPersonalApiCallId) {
          this.setState({
            isLoading: false
          })
          if (responseJson && responseJson.data) {
            const personalDetails = responseJson.data.attributes.personal_detail.data.attributes;
            const bamDetails = responseJson.data.attributes.bam_detail.data?.attributes || null;
            const productMapped = responseJson.data.attributes.product_mapped?.data?.attributes.product_mapped.data || [];
            const customerContactDetail = responseJson.data.attributes.customer_contact || [];
            this.setState({
              personalDetailsId: responseJson.data.attributes.personal_detail.data.id || "",
              firstName: personalDetails.customer_name || "",
              selectedParentCompanyId: personalDetails?.parent_customer_name_id || 0,
              companySapCode: personalDetails.customer_sap_code || "",
              customer_type: personalDetails.customer_type_id || 0,
              channel: personalDetails.channel_id || 0,
              classification: personalDetails.classification_id || 0,
              territory: personalDetails.territory,
              territory_id: personalDetails.territory_id,
              mode_of_business: personalDetails.mode_of_business_id || 0,
              account_currency: personalDetails.account_currency_id || 0,
              customer_contact: personalDetails.customer_contact,
              website: personalDetails.website,
              billing_street: personalDetails.billing_street,
              billing_city: personalDetails.billing_city,
              billing_postal_code: String(personalDetails.billing_postal_code) || "",
              billing_state: personalDetails.billing_state,
              billing_country: personalDetails.billing_country,
              shipping_street: personalDetails.shipping_street,
              shipping_city: personalDetails.shipping_city,
              shipping_postal_code: String(personalDetails.shipping_postal_code) || "",
              shipping_state: personalDetails.shipping_state,
              shipping_country: personalDetails.shipping_country,
              is_same_as_billing_address: personalDetails.is_same_as_billing_address,
              emailId: personalDetails.email,
              phoneNumber: personalDetails.phone_number,
              phoneCountryCode: String(personalDetails.country_code),
              address: personalDetails.address || "",
              designation: personalDetails.designation || "",
              landlineNumber: personalDetails.landline || "",
              areaOfBusiness: personalDetails.area_of_business || "",
              subsidiaryName: personalDetails.subsidiary_name,
              alternateEmail: personalDetails.alternate_email,
              customerMaster: personalDetails.customer_master || "",
              industry: personalDetails.industry_id || 0,
              bam_details: bamDetails,
              mappedProducts: productMapped,
              biocon_manager_id: bamDetails?.biocon_account_manager_id || 0,
              employee_territory_id: bamDetails?.territory_id || 0,
              biocon_manager: {
                email: bamDetails?.biocon_account_manager_email || '',
                country_code: bamDetails?.biocon_account_manager_country_code || '',
                phone_number: bamDetails?.biocon_account_manager_phone_number || ''
              },
              // selectedCustomerContacts: [],
              selectedCustomerContacts: responseJson.data.attributes.customer_contact,
              customerContact: customerContactDetail,
              invoiceDetail: responseJson.data.attributes.customer_contact,
            });
            if (productMapped && productMapped.length > 0) {
              productMapped.map((product: IProductGet) => {
                return {
                  product_id: product.attributes.product_id
                }
              });

              const newList = [...this.state.products];
              newList.forEach((product: IProduct) => {
                const isSelected = productMapped.find((mapped: IProductGet) => mapped.attributes.product_id === product.id);
                if (isSelected) {
                  product.isSelected = true
                  product.isAlreadySelected = true
                }
                return product;
              })

              this.setState({
                multipleProductForm: [{ product_id: 0 }]
              });
            }
          }
        }
        if (apiRequestCallId === this.getAllListApiCallId) {
          if (!responseJson.error) {
            this.setState({
              all_customer_types: responseJson.all_customer_types,
              all_channels: responseJson.all_channels,
              all_classifications: responseJson.all_classifications,
              all_territories: responseJson.all_territories,
              all_mode_of_businesses: responseJson.all_mode_of_businesses,
              all_account_currencies: responseJson.all_account_currencies,
              industriesList: responseJson.all_industries
            });
          }
        }
        if (apiRequestCallId === this.getUserPersonalApiCallId) {
          this.setState({isLoading: true})
          if (!responseJson.error) {
            this.setState({
              companyId: responseJson.data.attributes.company_id,
            });
            if (location.href.includes("AddContactInvoice") && location.href.includes("Add/")) {
              this.setState({
                contactName: responseJson.data.attributes.first_name + " " + responseJson.data.attributes.last_name,
              });
            }
            if (location.href.includes("AddContactInvoice")) {
              this.setState(() => ({
                invoiceFormDetails: {
                  ...this.state.invoiceFormDetails,
                  account_id: parseInt(responseJson.data.id),
                },
              }));
              this.setState({
                isLoading: false,
              })
            }
          }
        }

        if (apiRequestCallId === this.getAllCustomerContactsApiCallId) {
          if (!responseJson.error && !responseJson.errors) {
            this.setState({
              allCustomerContacts: responseJson.all_accounts
            })
          }
        }

        // if (apiRequestCallId === this.saveCustomerContactApiCallId) {
        //   if (!responseJson.error && !responseJson.errors) {
        //     successNotification("Customer Contact Added Successfully");
        //     this.setState({
        //       isCustomerContactUpdate: false,
        //       selectedContactCustomerId: 0,
        //       contactPersonEmail: "",
        //       contactPersonName: ""
        //     });
        //     this.getPersonalDetails();
        //   } else {
        //     errorNotification("Something went wrong");
        //   }
        // }

        // if (apiRequestCallId === this.createAccountApiCallId) {
        //   //     /* START --------------------------------------------------------------  Update account Api Call Response Handled*/
        //   if (!responseJson.error && !responseJson.errors) {
        //     let bool;
        //     if (this.isUpdate) {
        //       this.getPersonalDetails();
        //       successNotification("Personal Details Updated Successfully");
        //       bool = false;
        //     } else {
        //       successNotification("Personal Details Created Successfully");
        //       this.props.navigation.navigate("CustomerAdd", { id: responseJson.data.attributes.customer_master_id })
        //       bool = true;
        //     }
        //     this.setState({
        //       selectedTab: 1,
        //       isView: true,
        //       isBamEdit: bool,
        //       isProductMapUpdate: true,
        //       stateUserId: responseJson.data.attributes.customer_master_id,
        //       stateIsUpdate: true
        //     });
        //     this.userId = responseJson.data.attributes.customer_master_id;
        //     if (this.userId) {
        //       this.getPersonalDetails();
        //     }

        //   } else if (responseJson.errors) {
        //     showApiErrorResponse(responseJson)
        //   } else {
        //     //Check Error Response
        //     errorNotification(responseJson.error);
        //   }

        //   this.parseApiCatchErrorResponse(errorReponse);
        //   //   /* END --------------------------------------------------------------  Update account Api Call Response Handled*/
        // }

        // if (apiRequestCallId === this.updateBAMProfileApiCallId) {
        //   //     /* START --------------------------------------------------------------  Update account Api Call Response Handled*/
        //   if (!responseJson.errors && !responseJson.error) {
        //     successNotification("BAM Details Updated Successfully");
        //     this.setState({
        //       selectedTab: 2,
        //       isBamEdit: false
        //     });
        //     this.getPersonalDetails();
        //   } else {
        //     //Check Error Response
        //     showApiErrorResponse(responseJson);
        //   }

        //   this.parseApiCatchErrorResponse(errorReponse);
        //   //   /* END --------------------------------------------------------------  Update account Api Call Response Handled*/
        // }

        // if (apiRequestCallId === this.updateProductMappedApiCallId) {
        //   //     /* START --------------------------------------------------------------  Update account Api Call Response Handled*/
        //   if (!responseJson.errors) {
        //     successNotification("Product Mapped Updated Successfully");
        //     if (this.isUpdate) {
        //       this.setState({
        //         selectedTab: 3,
        //         isProductMapUpdate: false
        //       });
        //     } else {
        //       this.setState({
        //         isCreatedPopup: true
        //       })
        //     }

        //     this.getPersonalDetails();
        //   } else {
        //     //Check Error Response
        //     showApiErrorResponse(responseJson);
        //   }

        //   this.parseApiCatchErrorResponse(errorReponse);
        //   //   /* END --------------------------------------------------------------  Update account Api Call Response Handled*/
        // }

        // if (apiRequestCallId === this.getAllBioconManagerApiCallId) {
        //   if (!responseJson.error && !responseJson.errors) {
        //     this.setState({
        //       biocon_account_managers: responseJson.biocon_account_managers
        //     })
        //   }
        // }

        if (apiRequestCallId === this.getAllCustomerInvioceApiCallId) {
          this.setState({ isInvoiceLoading: false })
          if (!responseJson.error && !responseJson.errors) {
            this.setState({
              allcustomerInvioce: responseJson.result.data,
              customerInvoiceListingMeta: responseJson.result.meta,
            })
          }
        }

        if (apiRequestCallId === this.createInvoiceApiCallId) {
          if (!responseJson.error && !responseJson.errors) {
            this.props.navigation.goBack()
            return true
          }
          this.setState({ isLoading: false })
          // this.getAllCustomerInvioce();
        }

        if (apiRequestCallId === this.createInvoiceEditApiCallId) {
          if (!responseJson.error && !responseJson.errors) {
            this.props.navigation.goBack()
            return true
          }
          this.setState({ isLoading: false })
          // this.getAllCustomerInvioce()
        }

        if (apiRequestCallId === this.createContactInvoiceApiCallId) {
          if (!responseJson.error && !responseJson.errors) {
            this.props.navigation.goBack()
            return true
          }
          this.setState({ isLoading: false })
          // this.getAllCustomerInvioce()
        }


        if (apiRequestCallId === this.getByIdCustomerInvioceApiCallId) {
          this.setState({
            isLoading: true
          })
          if (!responseJson.error && !responseJson.errors) {
            this.setState(() => ({
              invoiceFormDetails: {
                account_id: responseJson.data.attributes.customer_invoice_person_id,
                catalogue_id: responseJson.data.attributes.customer_invoice_catalogue_id,
                invoice: responseJson.data.attributes.invoice,
                invoice_date: responseJson.data.attributes.invoice_date,
                invoice_amount: responseJson.data.attributes.invoice_amount,
                account_currency_id: responseJson.data.attributes.currency_id,
                p_o: responseJson.data.attributes.p_o,
                due_date: responseJson.data.attributes.due_date,
                payment_term_id: responseJson.data.attributes.payment_term_id,
                inco_term_id: responseJson.data.attributes.inco_term_id,
                payment_status: responseJson.data.attributes.payment_status === "Pending" ? 0 : 1,
                attachment: responseJson.data.attributes.attachment,
              },
              contactName: responseJson.data.attributes.customer_invoice_person,
              isLoading: false,
              getAttachment: responseJson.data.attributes.attachment,
            }))
          }
        }
      }

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getMappedProductAPICallId) {
          if (!responseJson.error) {
            const ProductsData = responseJson.products.map((product: any) => {
              return { ...product, isSelected: false }
            });

            this.setState({
              products: ProductsData
            });

          }
        }
      }

      // if (apiRequestCallId && responseJson) {
      //   if (apiRequestCallId === this.getProductMappedDetailAPICallId) {
      //     if (!responseJson.error) {
      //       const ProductsData = responseJson.data.attributes;
      //       this.setState({
      //         catlogIds: ProductsData.catalogue_attributes
      //       });
      //     }
      //   }
      // }

      if (apiRequestCallId === this.getIncoTeamListApiCallId) {
        if (!responseJson.error) {
          this.setState({
            allIncoTerms: responseJson.all_inco_terms,
            allPaymentTerms: responseJson.all_payment_terms,
          })






        }
      }

      if (apiRequestCallId === this.getCategoriesApiCallId) {
        if (!responseJson.errors) {
          const products = responseJson.products.map((product: any) => {
            return {
              id: product[0],
              value: product[1]
            }
          });
          products.push({
            id: -1,
            value: 'Other'
          })
          this.setState({
            productsList: products
          })
        }
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        if(isLoggedIn(this.props.navigation)) {
          autoLogOut();
        }
      });
    });
    const userInfo = JSON.parse(localStorage.getItem("userInfo") || "");
      if (userInfo.attributes.role_id === 11) {
        this.setState({ isViewOnly: true });
      } else {
        this.setState({ isViewOnly: false });
      }
    const authToken = isLoggedIn(this.props.navigation);
    authorizeEmployee(this.props.navigation);
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
      this.getAllList();
      //   this.getAllBioconManagers();
      //   this.getAllManagerTerritories();
      this.getAllProductsListing();
      //   this.getAllCompaniesList();
      this.getContactNameList();
      this.getCategoriesList()
      this.getByIdCustomerInvioce();
      //   this.getAllCustomerInvioce();
      this.getUserPersonalDetails();


      const userId = this.props.navigation.getParam('id');
      const FromTicket = localStorage.getItem("FromTicket");
      if (userId) {
        this.userId = userId;
        this.getAllCustomerContacts();
        this.setState({
          stateUserId: userId
        });
        this.getPersonalDetails();
        const isUpdate = location.href.includes("edit");
        if (isUpdate) {
          this.isUpdate = true;
          this.setState({
            isView: true,
            stateIsUpdate: true,
            stateUserId: userId,
            isInvoiceEdit: false
          });
        }
        if (FromTicket === 'ticketDetail') {
          this.setState({
            isEditDetails: false
          })
        } else {
          this.setState({
            isEditDetails: true
          })
        }
      }

    }
  }
  // handleChangeTab = (event: any, newValue: any) => {
  //   this.setState({ selectedTab: newValue });
  // };

  // isShippingAddressChange = (prop: string): boolean => {
  //   if (prop === "shipping_street" || prop === "shipping_city" || prop === "shipping_postal_code" || prop === "shipping_state" || prop === "shipping_country") {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // personalDetailsValidation() {
  //   if (
  //     this.isStringNullOrBlank(this.state.firstName) ||
  //     !this.isStringNullOrBlank(this.state.firstNameError) ||
  //     this.isStringNullOrBlank(this.state.billing_city) ||
  //     this.isStringNullOrBlank(this.state.billing_country) ||
  //     this.isStringNullOrBlank(this.state.billing_state) ||
  //     this.isStringNullOrBlank(this.state.billing_street) ||
  //     this.isStringNullOrBlank(this.state.shipping_city) ||
  //     this.isStringNullOrBlank(this.state.shipping_country) ||
  //     this.isStringNullOrBlank(this.state.shipping_state) ||
  //     this.isStringNullOrBlank(this.state.shipping_street)
  //   ) {
  //     errorNotification("All fields required");
  //     return false;
  //   }

  //   const websiteRegex = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/gm;
  //   // const zipCodeRegex = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/;
  //   if (this.state.website && this.state.website.trim().length > 0 && !String(this.state.website).toLocaleLowerCase().match(websiteRegex)) {
  //     warningNotification(configJSON.websiteError);
  //     return false;
  //   }

  //   if (this.state.customer_type < 1) {
  //     warningNotification(configJSON.customerTypeError);
  //     return false;
  //   }

  //   if (this.state.territory_id < 1) {
  //     warningNotification(configJSON.territoryError);
  //     return false;
  //   }

  //   if (this.state.mode_of_business < 1) {
  //     warningNotification(configJSON.modeOfBusinessError);
  //     return false;
  //   }

  //   if (this.state.account_currency < 1) {
  //     warningNotification(configJSON.accountCurrencyError);
  //     return false;
  //   }
  //   return true;
  // }

  // onPersonalDetailsSave = () => {

  //   const isValid = this.personalDetailsValidation();
  //   if (!isValid) {
  //     return false
  //   }

  //   this.setState({
  //     isLoading: true
  //   });

  //   const header = getHeaders({ token: this.authToken });

  //   const body = {
  //     customer_name: this.state.firstName,
  //     parent_customer_name_id: this.state.selectedParentCompanyId === 0 ? "" : this.state.selectedParentCompanyId,
  //     customer_sap_code: this.state.companySapCode,
  //     industry_id: this.state.industry,
  //     customer_type_id: this.state.customer_type,
  //     channel_id: this.state.channel,
  //     classification_id: this.state.classification,
  //     territory_id: this.state.territory_id,
  //     mode_of_business_id: this.state.mode_of_business,
  //     account_currency_id: this.state.account_currency,
  //     website: this.state.website,
  //     billing_street: this.state.billing_street,
  //     billing_city: this.state.billing_city,
  //     billing_postal_code: this.state.billing_postal_code,
  //     billing_state: this.state.billing_state,
  //     billing_country: this.state.billing_country,
  //     shipping_street: this.state.shipping_street,
  //     shipping_city: this.state.shipping_city,
  //     shipping_postal_code: this.state.shipping_postal_code,
  //     shipping_state: this.state.shipping_state,
  //     shipping_country: this.state.shipping_country,
  //     is_same_as_billing_address: this.state.is_same_as_billing_address,
  //   };

  //   const createAccount = apiCall({
  //     httpBody: body,
  //     header: header,
  //     url: configJSON.addPersonalApiEndpoint,
  //     httpMethod: configJSON.exampleAPiMethod,
  //   });
  //   this.createAccountApiCallId = createAccount.messageId;
  //   runEngine.sendMessage(createAccount.id, createAccount);

  //   return true;
  // };

  // onPersonalDetailsUpdate = () => {

  //   const isValid = this.personalDetailsValidation();
  //   if (!isValid) {
  //     return false
  //   }

  //   this.setState({
  //     isLoading: true
  //   });

  //   const header = getHeaders({ token: this.authToken });

  //   const body = {
  //     customer_name: this.state.firstName,
  //     parent_customer_name_id: this.state.selectedParentCompanyId === 0 ? "" : this.state.selectedParentCompanyId,
  //     customer_sap_code: String(this.state.companySapCode),
  //     industry_id: this.state.industry,
  //     customer_type_id: this.state.customer_type,
  //     channel_id: this.state.channel,
  //     classification_id: this.state.classification,
  //     territory_id: this.state.territory_id,
  //     mode_of_business_id: this.state.mode_of_business,
  //     account_currency_id: this.state.account_currency,
  //     website: this.state.website,
  //     billing_street: this.state.billing_street,
  //     billing_city: this.state.billing_city,
  //     billing_postal_code: this.state.billing_postal_code,
  //     billing_state: this.state.billing_state,
  //     billing_country: this.state.billing_country,
  //     shipping_street: this.state.shipping_street,
  //     shipping_city: this.state.shipping_city,
  //     shipping_postal_code: this.state.shipping_postal_code,
  //     shipping_state: this.state.shipping_state,
  //     shipping_country: this.state.shipping_country,
  //     is_same_as_billing_address: this.state.is_same_as_billing_address,
  //   };

  //   const updateAccount = apiCall({
  //     httpBody: body,
  //     header: header,
  //     url: configJSON.updatePersonalDetailsApiEndPoint + this.userId,
  //     httpMethod: configJSON.updatePersonalDetailsApiMethod,
  //   });
  //   this.createAccountApiCallId = updateAccount.messageId;
  //   runEngine.sendMessage(updateAccount.id, updateAccount);

  //   return true;
  // }

  // onBAMDetailsSave = () => {


  //   if (this.state.biocon_manager_id === 0) {
  //     warningNotification(configJSON.accountManagerError);
  //     return false;
  //   }

  //   if (this.state.employee_territory_id === 0) {
  //     warningNotification(configJSON.territoryError);
  //     return false;
  //   }

  //   this.setState({
  //     isLoading: true
  //   });

  //   const body = {
  //     biocon_account_manager_id: this.state.biocon_manager_id
  //   };

  //   const updateCustomerProfile = apiCall({
  //     httpBody: body,
  //     header: getHeaders({ token: this.authToken }),
  //     url: configJSON.createUpdateBamDetailsApiEndPoint + `customer_master_id=${this.userId}`,
  //     httpMethod: configJSON.createUpdateBamDetailsApiMethod,
  //   });

  //   this.updateBAMProfileApiCallId = updateCustomerProfile.messageId;
  //   runEngine.sendMessage(updateCustomerProfile.id, updateCustomerProfile);
  // };

  // onProductMappedDetailsSave = () => {

  //   let isValidated = true;

  //   this.state.multipleProductForm.forEach((form: any) => {
  //     if (form.product_id === 0) {
  //       isValidated = false;
  //     }
  //   });

  //   if (!isValidated) {
  //     warningNotification("All Fields Are Mandatory");
  //     return false;
  //   }

  //   this.setState({
  //     isLoading: true
  //   });

  //   const body = {
  //     product_mapped_catalogues_attributes: this.state.multipleProductForm.map((form: any) => {
  //       return { catalogue_id: form.product_id }
  //     })
  //   };

  //   const updateProductMapped = apiCall({ 
  //     httpBody: body,
  //     header: getHeaders({ token: this.authToken }),
  //     url: configJSON.updateProductMappedDetailApiEndPoint + `customer_master_id=${this.userId}`,
  //     httpMethod: configJSON.updateProductMappedDetailApiMethod,
  //   });

  //   this.updateProductMappedApiCallId = updateProductMapped.messageId;
  //   runEngine.sendMessage(updateProductMapped.id, updateProductMapped);
  // };

  // onCustomerContactSave = () => {
  //   if (this.state.selectedContactCustomerId === 0) {
  //     warningNotification(configJSON.customerMasterMandatory);
  //     return false
  //   }

  //   this.setState({
  //     isLoading: true
  //   });
  //   const httpBody = {
  //     account_id: this.state.selectedContactCustomerId
  //   }
  //   const saveCustomerContact = apiCall({
  //     header: getHeaders({ token: this.authToken }),
  //     httpBody: httpBody,
  //     url: configJSON.saveCustomerContactApiEndpoint + `customer_master_id=${this.userId}`,
  //     httpMethod: configJSON.saveCustomerContactApiMethod
  //   });

  //   this.saveCustomerContactApiCallId = saveCustomerContact.messageId;
  //   runEngine.sendMessage(saveCustomerContact.id, saveCustomerContact);
  // }

  getPersonalDetails = () => {
    this.setState({
      isLoading: true
    });
    const header = getHeaders({ token: this.authToken });
    const getAccount = apiCall({
      httpBody: {},
      header: header,
      url:
        configJSON.getPersonalDetailsApiEndPoint + this.userId,
      // this.userDetails.attributes.customer_profile_id,
      httpMethod: configJSON.getCustomerProfileApiMethod,
    });

    this.getCustomerPersonalApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  };

  getUserPersonalDetails = () => {
    this.setState({
      isLoading: true
    });
    const header = getHeaders({ token: this.authToken });
    let userId: any = this.props.navigation.getParam('id')

    let url = configJSON.getPersonalDetailsApiEndPoint + `${userId}/show_user_detail`;
    const getAccount = apiCall({
      httpBody: {},
      header: header,
      url: url,
      // this.userDetails.attributes.customer_profile_id,
      httpMethod: configJSON.getCustomerProfileApiMethod,
    });

    this.getUserPersonalApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  };

  getAllProductsListing = () => {
    const header = getHeaders({ token: this.authToken });
    const getAccount = apiCall({
      httpBody: {},
      header: header,
      url:
        configJSON.productMapListAPIEndPoint,
      httpMethod: configJSON.getCustomerProfileApiMethod,
    });

    this.getMappedProductAPICallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  // getProductsDetails = () => {
  //   const header = getHeaders({ token: this.authToken });
  //   const getAccount = apiCall({
  //     httpBody: {},
  //     header: header,
  //     url:
  //       configJSON.productMappedDetailAPIEndPoint + '1' + '/product_mapped',
  //     httpMethod: configJSON.getCustomerProfileApiMethod,
  //   });

  //   this.getProductMappedDetailAPICallId = getAccount.messageId;
  //   runEngine.sendMessage(getAccount.id, getAccount);
  // }

  getAllList = () => {
    const header = getHeaders({ token: this.authToken });

    const getAllList = apiCall({
      httpBody: {},
      header: header,
      url: configJSON.getAllListApiEndpoint,
      httpMethod: configJSON.getAllListApiMethod,
    });

    this.getAllListApiCallId = getAllList.messageId;
    runEngine.sendMessage(getAllList.id, getAllList);
  };

  // isStringNullOrBlank(str: string) {
  //   return str === null || str.trim().length === 0;
  // }

  // handlePhoneCountryCode(event: any, value: any) {
  //   if (value) {
  //     this.setState({
  //       phoneCountryCode: event.value,
  //     });
  //   }
  // }

  // handlePersonalDetailEdit() {
  //   this.setState({
  //     isView: false
  //   })
  // }

  // handleBamEdit() {
  //   this.setState({
  //     isBamEdit: true
  //   });
  // }

  // handleProductMapEdit() {
  //   this.setState({
  //     isProductMapUpdate: true
  //   })
  // }

  // handleCustomerContactEdit() {
  //   this.setState({
  //     isCustomerContactUpdate: true
  //   })
  // }

  // handleCustomerContactAddNewClick = () => {
  //   if (this.props.navigation) {
  //     this.props.navigation.navigate("CustomerContactAdd")
  //   }
  // }

  getContactNameList = () => {
    const header = getHeaders({ token: this.authToken });

    const getContactNameList = apiCall({
      httpBody: {},
      header: header,
      url: configJSON.getIncoTeamListApiEndpoint,
      httpMethod: configJSON.getIncoTeamListApiMethod,
    });

    this.getIncoTeamListApiCallId = getContactNameList.messageId;
    runEngine.sendMessage(getContactNameList.id, getContactNameList);
  };


  getCategoriesList = () => {

    const categoryApi = apiCall({
      header: getHeaders({}),
      url: configJSON.getCategoriesApiEndPoint,
      httpMethod: configJSON.getCategoriesApiEndMethod,
      httpBody: {}
    });

    this.getCategoriesApiCallId = categoryApi.messageId;
    runEngine.sendMessage(categoryApi.id, categoryApi);
  }


  handleInvoiceAddNewClick = () => {
    this.setState({
      isInvoiceEdit: true
    })
    if (this.props.navigation) {
      this.props.navigation.navigate("AddInvoice", { id: this.userId })
    }
  }

  handleDocumentUpload = async (e: any) => {
    this.setState({ getAttachment: "", attachmentError: "" });
    const uploadedFiles = [].slice.call(e.target.files);
    uploadedFiles.forEach((file: File) => {
      return {
        name: file.name
      }
    })

    const newImagesPromises = []
    for (let row of e.target.files) {
      newImagesPromises.push(this.fileToDataUri(row))
    }


    const fileBase64 = await Promise.all(newImagesPromises)

    this.setState({
      uploadedFilesBase64: fileBase64,
      uploadedFiles: uploadedFiles
    });
  }

  fileToDataUri = (image: any) => {
    return new Promise((res) => {
      const reader = new FileReader();
      const { name, } = image;
      reader.addEventListener('load', () => {
        res({ file_name: name.split('.').slice(0, -1).join('.'), upload: reader.result })
      });
      reader.readAsDataURL(image);
    })
  }

  removeFile = (fileName: string) => {
    fileName === "remove_file" && this.setState({ getAttachment: "" })
    this.setState({
      uploadedFiles: this.state.uploadedFiles.filter(file => file.name !== fileName),
      uploadedFilesBase64: this.state.uploadedFilesBase64.filter(file => file.name !== fileName)
    })
  }

  saveInvoice = (values: any) => {
    if (this.state.uploadedFiles.length === 0 && location.href.includes("Add/")) {
      this.setState({ attachmentError: 'Attachment is required.' })
      return false;
    }
    this.setState({ isLoading: true })
    let addtionalHttpBody: any = { ...values, attachment: this.state.uploadedFilesBase64 };

    let apiUrl: any;
    let isContact: any = location.href.includes("AddContactInvoice");
    let isEdit: any = location.href.includes("Add");
    if (isContact && isEdit) {
      apiUrl = configJSON.createinvoiceApiEndpoint + `?customer_master_id=${this.state.companyId}`;
    } else {
      apiUrl = configJSON.createinvoiceApiEndpoint + `?customer_master_id=${this.userId}`;
    }

    const saveSample = apiCall({
      header: getHeaders({ token: this.authToken }),
      url: apiUrl,
      httpMethod: configJSON.createinvoiceApiMethod,
      httpBody: addtionalHttpBody,
    });
    this.createInvoiceApiCallId = saveSample.messageId;
    runEngine.sendMessage(saveSample.id, saveSample);
  }

  saveInvoiceEditData = (values: any) => {
    let saveEditUrl: any = window.location.href.split("/")
    if (this.state.getAttachment === "" && this.state.uploadedFiles.length === 0 && location.href.includes("edit")) {
      this.setState({ attachmentError: 'Attachment is required.' })
      return false;
    }
    this.setState({
      isLoading: true
    })
    let addtionalHttpBody: any = { ...values, attachment: this.state.uploadedFilesBase64 };

    const saveSample = apiCall({
      header: getHeaders({ token: this.authToken }),
      url: configJSON.createinvoiceEditIdApiEndpoint + `${saveEditUrl.at(-2)}` + `/update_customer_invoice?customer_master_id=${saveEditUrl.at(-2)}` + `&customer_invoice_id=${saveEditUrl.at(-1)}`,
      httpMethod: configJSON.createinvoiceEditIdApiMethod,
      httpBody: addtionalHttpBody,
    });

    this.createInvoiceEditApiCallId = saveSample.messageId;
    runEngine.sendMessage(saveSample.id, saveSample);
  }

  saveContactInvoiceEditData = (values: any) => {
    let saveEditUrl: any = window.location.href.split("/")
    if (this.state.getAttachment === "" && this.state.uploadedFiles.length === 0 && location.href.includes("edit")) {
      this.setState({ attachmentError: 'Attachment is required.' })
      return false;
    }
    this.setState({
      isLoading: true
    })
    let addtionalHttpBody: any = { ...values, attachment: this.state.uploadedFilesBase64 };

    const saveSample = apiCall({
      header: getHeaders({ token: this.authToken }),
      url: configJSON.createinvoiceEditIdApiEndpoint + `${saveEditUrl.at(-2)}` + `/update_customer_invoice?customer_master_id=${saveEditUrl.at(-2)}` + `&customer_invoice_id=${saveEditUrl.at(-1)}`,
      httpMethod: configJSON.createinvoiceEditIdApiMethod,
      httpBody: addtionalHttpBody,
    });

    this.createContactInvoiceApiCallId = saveSample.messageId;
    runEngine.sendMessage(saveSample.id, saveSample);
  }

  handleInvoiceEdit = () => {
    this.setState({
      isInvoiceEdit: true,
    })
  }
  // handleAddressCheck = () => {
  //   this.setState({
  //     is_same_as_billing_address: !this.state.is_same_as_billing_address,
  //   });
  //   if (!this.state.is_same_as_billing_address) {
  //     this.setState({
  //       shipping_street: this.state.billing_street,
  //       shipping_city: this.state.billing_city,
  //       shipping_postal_code: this.state.billing_postal_code,
  //       shipping_state: this.state.billing_state,
  //       shipping_country: this.state.billing_country,
  //     })
  //   }
  // };

  // handleIndustryChange = (event: any, value: any) => {
  //   this.setState({
  //     industry: value.props.value,
  //   });
  // };

  // handleContactMasterChange = (event: any, value: any) => {
  //   const result = this.state.allCustomerContacts.find((contact: ICustomerContacts) => contact.id === value.props.value);
  //   this.setState({
  //     selectedContactCustomerId: value.props.value,
  //     contactPersonEmail: result?.email || "",
  //     contactPersonName: result?.name || ""
  //   })
  // }

  // handleParentCompanyChange = (event: any, value: any) => {
  //   this.setState({
  //     selectedParentCompanyId: event ? event.id : 0
  //   })
  // }

  // handleManagerChange = (event: any, value: any) => {
  //   const selectedEmployee: any = this.state.biocon_account_managers.find(manager => manager.id === value.props.value) || {}
  //   this.setState({
  //     biocon_manager: selectedEmployee,
  //     biocon_manager_id: value.props.value,
  //     // territory_id: value.props.value.employee_territory_id,
  //     territory_name: selectedEmployee.territory,
  //     employee_territory_id: selectedEmployee?.territory_id || 0
  //   });
  // };

  // handleBioconManagerTerritoryChange = (event: any, value: any) => {
  //   this.setState({
  //     employee_territory_id: value.props.value,
  //   });
  // };

  // handleCurrencyChange = (event: any, value: any) => {
  //   this.setState({
  //     account_currency: value.props.value,
  //   });
  // };

  // addMultipleForm = () => {
  //   this.setState({
  //     multipleProductForm: [...this.state.multipleProductForm, { product_id: 0 }]
  //   })
  // }

  // deleteFormElement = (index: number) => {
  //   const newForm = [...this.state.multipleProductForm];
  //   const deletedElemet: any = newForm.splice(index, 1);
  //   const newProducts = [...this.state.products];
  //   newProducts.forEach((product: IProduct) => {
  //     if (product.id === deletedElemet[0].product_id) {
  //       product.isSelected = false;
  //     }
  //     return product
  //   });
  //   this.setState({
  //     products: newProducts,
  //     multipleProductForm: newForm
  //   })
  // }

  // handleModeOfBusinessChange = (event: any, value: any) => {
  //   this.setState({
  //     mode_of_business: value.props.value,
  //   });
  // };

  // handleClassificationChange = (event: any, value: any) => {
  //   this.setState({
  //     classification: value.props.value,
  //   });
  // };

  // handleChannelChange = (event: any, value: any) => {
  //   this.setState({
  //     channel: value.props.value,
  //   });
  // };

  // handleCustomerTypeChange = (event: any, value: any) => {
  //   this.setState({
  //     customer_type: value.props.value,
  //   });
  // };

  // handlechangeTerritory = (event: any, value: any) => {

  //   this.setState({
  //     territory_id: value.props.value
  //   })
  // };

  // handlechangeProducts = (event: any, value: any, productIndex: number) => {
  //   // this.state.catlogIds.push(value.props.value);
  //   // this.state.categories.push(value.props.children);
  //   const selectedValue = value.props.value;
  //   const updatedForm = [...this.state.multipleProductForm];
  //   updatedForm.forEach((form: any, i: number) => {
  //     if (i === productIndex) {
  //       form.product_id = selectedValue;
  //     }
  //     return form;
  //   });
  //   const newProducts = [...this.state.products];
  //   newProducts.forEach((product: IProduct) => {
  //     const isSelected = updatedForm.find((mapped: any) => mapped.product_id === product.id);
  //     if (isSelected) {
  //       product.isSelected = true
  //     } else if (!product.isAlreadySelected) {
  //       product.isSelected = false
  //     }
  //     return product;
  //   });

  //   this.setState({
  //     multipleProductForm: updatedForm,
  //     products: newProducts
  //   });
  // };

  // handleInvoiceByIdClick = (id: any, paramsId: any) => {
  //   this.setState({
  //     isInvoiceEdit: true,
  //   })
  // }

  //for all customer invoice
  getAllCustomerInvioce = () => {
    this.setState({ isInvoiceLoading: true })
    const customerUserId: any = this.props.navigation.getParam('id')
    const allManagers = apiCall({
      header: getHeaders({ token: this.authToken }),
      url: configJSON.getAllCustomerInvioceApiEndPoint + `?customer_master_id=${customerUserId}&page=${this.state.page}&per_page=${this.state.perPageRecord}`,
      httpBody: {},
      httpMethod: configJSON.getAllCustomerInvioceApiMethod
    });

    this.getAllCustomerInvioceApiCallId = allManagers.messageId;
    runEngine.sendMessage(allManagers.id, allManagers);
  }

  handlePageChange = (e: any, p: any) => {
    this.setState({
      page: p,
    }, () => {
      this.getAllCustomerInvioce();
    });
  };

  getByIdCustomerInvioce = () => {
    if (this.props.navigation.getParam('customerID')) {
      this.setState({
        isLoading: true
      });
      let customerUrlId: any = window.location.href.split("/")
      const isUpdate = location.href.includes("edit");
      const allManagers = apiCall({
        header: getHeaders({ token: this.authToken }),
        url: configJSON.getByIdCustomerInvioceApiEndPoint + `?customer_master_id=${customerUrlId.at(-2)}` + `&customer_invoice_id=${customerUrlId.at(-1)}`,
        httpBody: {},
        httpMethod: configJSON.getByIdCustomerInvioceApiMethod
      });

      this.getByIdCustomerInvioceApiCallId = allManagers.messageId;
      runEngine.sendMessage(allManagers.id, allManagers);
    }
  }


  // getAllBioconManagers = () => {

  //   const allManagers = apiCall({
  //     header: getHeaders({ token: this.authToken }),
  //     url: configJSON.getAllBioconManagersApiEndPoint,
  //     httpBody: {},
  //     httpMethod: configJSON.getAllBioconManagersApiMethod
  //   });

  //   this.getAllBioconManagerApiCallId = allManagers.messageId;
  //   runEngine.sendMessage(allManagers.id, allManagers);
  // }

  // getAllManagerTerritories = () => {

  //   const allManagerTerritories = apiCall({
  //     header: getHeaders({ token: this.authToken }),
  //     url: configJSON.getAllEmployeeTerritoriesApiEndPoint,
  //     httpBody: {},
  //     httpMethod: configJSON.getAllEmployeeTerritoriesApiMethod
  //   });

  //   this.getAllEmployeeTerritoriesCallId = allManagerTerritories.messageId;
  //   runEngine.sendMessage(allManagerTerritories.id, allManagerTerritories);
  // }

  // getAllParentCompanyListing = () => {

  //   const allParentCompanies = apiCall({
  //     header: getHeaders({ token: this.authToken }),
  //     url: configJSON.getParentCompanyListingApiEndPoint,
  //     httpBody: {},
  //     httpMethod: configJSON.getParentCompanyListingApiMethod
  //   });

  //   this.getAllParentCompanyListCallId = allParentCompanies.messageId;
  //   runEngine.sendMessage(allParentCompanies.id, allParentCompanies);
  // }

  getAllCustomerContacts = () => {
    this.setState({ isLoading: true })
    const getAllContacts = apiCall({
      header: getHeaders({ token: this.authToken }),
      url: configJSON.getAllApprovedCustomerMastersApiEndpoint + `?customer_master_id=${this.userId}`,
      httpBody: {},
      httpMethod: configJSON.getAllApprovedCustomreMastersApiMethod
    });

    this.getAllCustomerContactsApiCallId = getAllContacts.messageId;
    runEngine.sendMessage(getAllContacts.id, getAllContacts);
  }

  // deleteCustomerContact = () => {

  //   const deleteContact = apiCall({
  //     header: getHeaders({ token: this.authToken }),
  //     url: configJSON.deleteCustomerContactApiEndpoint + `?customer_master_id=${this.userId}&customer_contact_id=${this.state.deleteCustomerContactId}`,
  //     httpBody: {},
  //     httpMethod: configJSON.deleteCustomerContactApiMethod
  //   });

  //   this.deleteCustomerContactApiCallId = deleteContact.messageId;
  //   runEngine.sendMessage(deleteContact.id, deleteContact);

  // }

  // deleteProductMapped = () => {
  //   const deleteProduct = apiCall({
  //     header: getHeaders({ token: this.authToken }),
  //     url: configJSON.deleteProductMappedApiEndpoint + `?customer_master_id=${this.userId}&product_mapped_id=${this.state.deleteCustomerContactId}`,
  //     httpBody: {},
  //     httpMethod: configJSON.deleteProductMappedApiMethod
  //   });

  //   this.deleteProductMappedApiCallId = deleteProduct.messageId;
  //   runEngine.sendMessage(deleteProduct.id, deleteProduct);
  // }

  // handleDeleteClick = (deleteId: number) => {
  //   this.setState({
  //     isDeletePopup: true,
  //     deleteCustomerContactId: deleteId
  //   });
  // }

  // handleDeleteProductMapped = (deleteId: number) => {
  //   this.setState({
  //     isDeletePopup: true,
  //     deleteCustomerContactId: deleteId,
  //     isProductMapDelete: true
  //   });
  // }
  // handleIsDeleteClose = () => {
  //   this.setState({
  //     isDeletePopup: false,
  //     isProductMapDelete: false,
  //     isInvoiceDelete: false
  //   })
  // }
  // handleIsCreatedClose = () => {
  //   this.props.navigation.navigate("MasterCustomer");
  // }

  // handleProductMappedCancel = () => {
  //   const newProducts = [...this.state.products];
  //   newProducts.forEach((product: IProduct) => {
  //     if (product.isSelected && !product.isAlreadySelected) {
  //       product.isSelected = false
  //     }
  //     return product;
  //   });

  //   this.setState({
  //     isProductMapUpdate: false,
  //     multipleProductForm: [{ product_id: 0 }],
  //     products: newProducts
  //   })
  // }

  // getAllCompaniesList = () => {
  //   const header = getHeaders({ token: this.authToken });

  //   const getCompanies = apiCall({
  //     httpBody: {},
  //     header: header,
  //     url: configJSON.getAllCompaniesApiEndpoint,
  //     httpMethod: configJSON.getAllCompaniesApiMethod,
  //   });

  //   this.getCompaniesListApiCallId = getCompanies.messageId;
  //   runEngine.sendMessage(getCompanies.id, getCompanies);
  // };

  downloadAPIAttachmentFile = (attachment: any) => {
    fetch(attachment.attachment, {
      method: 'GET'
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        if (attachment.file_name !== undefined) {
          link.setAttribute(
            'download',
            attachment.file_name,
          );
        }

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link?.parentNode?.removeChild(link);
      });
  }

  // Customizable Area End
}
