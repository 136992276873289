import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  InputLabel,
  Typography,
  Tab,
  Tabs,
  Grid,
  Checkbox,
  TextField,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import {
  withStyles,
} from "@material-ui/core/styles";
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CalendarMonthIcon from '@material-ui/icons/CalendarTodayOutlined';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import LocalPhoneOutlinedIcon from '@material-ui/icons/LocalPhoneOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import BusinessIcon from '@material-ui/icons/Business';
import AppHeader from "../../../components/src/EmployeeAppHeader.web";
import AppFooter from "../../../components/src/AppFooter.web";
import { NavLink as RLink } from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';
import CustomPagination from "../../../components/src/CustomPagination.web";
import SearchIcon from "@material-ui/icons/Search";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import moment from "moment";
import { Formik } from "formik";

function CustomTabPanel(props: {
  [x: string]: any;
  children: any;
  value: any;
  index: any;
}) {
  const { children, value, classes, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <CustomBox sx={{ p: 3 }} className={classes?.container} >{children}</CustomBox>}
    </div>
  );
}
// Customizable Area End

import ProfileDetailsController, {
  Props,
  IProduct,
  IProductGet,
  ICustomerContacts,
} from "./profileDetailController";
import Loader from "../../../components/src/Loader.web";
import CompanyNameSelector from "../../email-account-registration/src/CompanyNameSelector";

export class CustomerAddUpdateProfile extends ProfileDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  personalEditDetails = () => {
    return (
      (this.state.isView && this.state.isEditDetails) && (
        <Grid container spacing={2} style={webStyle.marginBottomStyle}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {(this.isUpdate && this.state.isViewOnly) && (
              <EditButton data-testid="personaldetailseditbtn" onClick={() => this.handlePersonalDetailEdit()} variant="contained" style={webStyle.floatRight}>
                  {this.state.editBtnText} <EditIcon style={webStyle.editIconStyle} />
              </EditButton>
            )}
          </Grid>
        </Grid>
      )
    )
  }

  perSonalDetails() {
    const { classes } = this.props;
    return (
      <CustomBox minHeight='calc(100vh - 500px)' >
        {this.personalEditDetails()}
        <Grid container spacing={7} alignItems="center" style={webStyle.detailsGrid}>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
            <InputLabel htmlFor="firstName" style={webStyle.inputLabel}>
              {this.state.labelCustomerName}<span style={webStyle.errorTextColor}>*</span>
            </InputLabel>
            <InputField
              data-testid="firstName"
              id="firstName"
              variant="outlined"
              disabled={this.state.isView}
              placeholder={this.state.firstNamePlaceholder}
              value={this.state.firstName}
              onChange={this.handleChange("firstName").bind(this)}
              fullWidth
            />
            {
              ((this.state.firstNameError !== '' && this.state.firstName === "") || (this.state.personalDetailsError && this.state.firstName === "")) && (
                <Typography style={webStyle.errorText} component={"span"}>
                  {this.state.customerNameRequiredText}
                </Typography>
              )
            }
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
            <InputLabel htmlFor="parent_customer_name" style={{ ...webStyle.inputLabel, ...webStyle.inputPadding}}>
              {this.state.parentCustomerLabel2}
            </InputLabel>
            <SelectField>
              <CompanyNameSelector
                data-testid='Companyname'
                isClearable={true}
                dataSource={this.state.parentCompanyList}
                selectedValue={this.state.selectedParentCompanyId}
                handleChange={this.handleParentCompanyChange.bind(this)}
                style={webStyle.select}
                isDisabled={this.state.isView}
                placeHolder={this.state.parentCompanyNamePlaceholder}
              />
            </SelectField>
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
            <InputLabel htmlFor="companySapCode" style={webStyle.inputLabel}>
              {this.state.sapCodeLabel}
            </InputLabel>
            <InputField
              id="companySapCode"
              data-testid='companySapCode'
              variant="outlined"
              value={this.state.companySapCode}
              disabled={this.state.isView}
              onChange={this.handleChange("companySapCode").bind(this)}
              placeholder={this.state.customerSapCodePlaceholder}
              fullWidth
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
            <InputLabel htmlFor="customer_contact" style={webStyle.inputLabel}>
              {this.state.industryLabel}
            </InputLabel>
            <SelectField disabled={this.state.isView}>
              <CustomSelect
                labelId="customer_contact"
                data-testid="industry"
                displayEmpty
                id="customer_contact"
                variant="outlined"
                fullWidth
                value={this.state.industry}
                disabled={this.state.isView}
                style={webStyle.select}
                onChange={this.handleIndustryChange.bind(this)}
                defaultValue={""}
              >
                <MenuItem value={0} className={classes?.selectPlaceholder}>
                  <em style={webStyle.countryPlaceHolder}>
                    {this.state.chooseIndustryPlaceholder}
                  </em>
                </MenuItem>
                {this.state.industriesList.map((data: any) => {
                  return (
                    <MenuItem value={data.id} key={data.id}>
                      {data.name}
                    </MenuItem>
                  );
                })}
                {this.state.industriesList.length === 0 && (
                  <MenuItem value="noData" disabled>
                    {this.state.noDataText}
                  </MenuItem>
                )}
              </CustomSelect>
            </SelectField>
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
            <InputLabel htmlFor="customer_type" style={webStyle.inputLabel}>
              {this.state.customerTypeLabel}<span style={webStyle.errorTextColor}>*</span>
            </InputLabel>
            <SelectField>
              <CustomSelect
                labelId="customer_type"
                data-testid="customer_type"
                displayEmpty
                id="customer_type"
                variant="outlined"
                fullWidth
                value={this.state.customer_type}
                disabled={this.state.isView}
                style={webStyle.select}
                onChange={this.handleCustomerTypeChange.bind(this)}
                defaultValue={""}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem value={0} className={classes?.selectPlaceholder}>
                  <em style={webStyle.countryPlaceHolder}>
                    {this.state.chooseCustomerTypePlaceholder}
                  </em>
                </MenuItem>
                {this.state.all_customer_types.map((data: any) => {
                  return (
                    <MenuItem value={data.id} key={data.id}>
                      {data.name}
                    </MenuItem>
                  );
                })}
                {this.state.all_customer_types.length === 0 && (
                  <MenuItem value="noData" disabled>
                   {this.state.noDataText}
                  </MenuItem>
                )}
              </CustomSelect>
            </SelectField>
            {
              ((this.state.personalDetailsError && this.state.customer_type < 1)) && (
                <Typography style={webStyle.errorText} component={"span"}>
                  {this.state.CustomerTypeisRequired}
                </Typography>
              )
            }
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
            <InputLabel htmlFor="channel" style={webStyle.inputLabel}>
              {this.state.channelLabel}
            </InputLabel>
            <SelectField>
              <CustomSelect
                labelId="channel"
                data-testid="channel"
                displayEmpty
                id="channel"
                variant="outlined"
                fullWidth
                value={this.state.channel}
                disabled={this.state.isView}
                style={webStyle.select}
                onChange={this.handleChannelChange.bind(this)}
                defaultValue={""}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem value={0} className={classes?.selectPlaceholder}>
                  <em style={webStyle.countryPlaceHolder}>
                    {this.state.chooseChannelPlaceholder}
                  </em>
                </MenuItem>
                {this.state.all_channels.map((data: any) => {
                  return (
                    <MenuItem value={data.id} key={data.id}>
                      {data.name}
                    </MenuItem>
                  );
                })}
                {this.state.all_channels.length === 0 && (
                  <MenuItem value="noData" disabled>
                    {this.state.noDataText}
                  </MenuItem>
                )}
              </CustomSelect>
            </SelectField>
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
            <InputLabel htmlFor="territory" style={webStyle.inputLabel}>
              {this.state.territoryLabel}<span style={webStyle.errorTextColor}>*</span>
            </InputLabel>
            <SelectField>
              <CustomSelect
                labelId="channel"
                data-testid="territory"
                displayEmpty
                id="channel"
                variant="outlined"
                fullWidth
                value={this.state.territory_id}
                disabled={this.state.isView}
                style={webStyle.select}
                onChange={this.handlechangeTerritory.bind(this)}
                defaultValue={""}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem value={0} className={classes?.selectPlaceholder}>
                  <em style={webStyle.countryPlaceHolder}>
                   {this.state.chooseTerritoryPlaceholder}
                  </em>
                </MenuItem>
                {this.state.all_territories.map((item: any) => {
                  return (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
                {this.state.all_channels.length === 0 && (
                  <MenuItem value="noData" disabled>
                   {this.state.noDataText}
                  </MenuItem>
                )}
              </CustomSelect>
            </SelectField>
            {
              ((this.state.personalDetailsError && this.state.territory_id < 1)) && (
                <Typography style={webStyle.errorText} component={"span"}>
                  {this.state.TerritoryisRequired}
                </Typography>
              )
            }
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
            <InputLabel htmlFor="Classification" style={webStyle.inputLabel}>
              {this.state.ClassificationLabel}
            </InputLabel>
            <SelectField>
              <CustomSelect
                labelId="Classification"
                data-testid="Classification"
                displayEmpty
                id="Classification"
                variant="outlined"
                fullWidth
                value={this.state.classification}
                disabled={this.state.isView}
                style={webStyle.select}
                onChange={this.handleClassificationChange.bind(this)}
                defaultValue={""}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem value={this.state.classification} className={classes?.selectPlaceholder}>
                  <em style={webStyle.countryPlaceHolder}>
                    {this.state.chooseClassificationPlaceholder}
                  </em>
                </MenuItem>
                {this.state.all_classifications.map((data: any) => {
                  return (
                    <MenuItem value={data.id} key={data.id}>
                      {data.name}
                    </MenuItem>
                  );
                })}
                {this.state.all_classifications.length === 0 && (
                  <MenuItem value="noData" disabled>
                    {this.state.noDataText}
                  </MenuItem>
                )}
              </CustomSelect>
            </SelectField>
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
            <InputLabel htmlFor="ModeOfBusiness" style={webStyle.inputLabel}>
              {this.state.ModeOfBusinessLabel}<span style={webStyle.errorTextColor}>*</span>
            </InputLabel>
            <SelectField>
              <CustomSelect
                labelId="ModeOfBusiness"
                data-testid="ModeOfBusiness"
                displayEmpty
                id="ModeOfBusiness"
                variant="outlined"
                fullWidth
                value={this.state.mode_of_business}
                disabled={this.state.isView}
                style={webStyle.select}
                onChange={this.handleModeOfBusinessChange.bind(this)}
                defaultValue={""}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem value={0} className={classes?.selectPlaceholder}>
                  <em style={webStyle.countryPlaceHolder}>
                    {this.state.chooseModeBusinessPlaceholder}
                  </em>
                </MenuItem>
                {this.state.all_mode_of_businesses.map((data: any) => {
                  return (
                    <MenuItem value={data.id} key={data.id}>
                      {data.name}
                    </MenuItem>
                  );
                })}
                {this.state.all_mode_of_businesses.length === 0 && (
                  <MenuItem value="noData" disabled>
                    {this.state.noDataText}
                  </MenuItem>
                )}
              </CustomSelect>
            </SelectField>
            {
              ((this.state.personalDetailsError && this.state.mode_of_business < 1)) && (
                <Typography style={webStyle.errorText} component={"span"}>
                  {this.state.BusinessModeIsrequired}
                </Typography>
              )
            }
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
            <InputLabel htmlFor="Currency" style={webStyle.inputLabel}>
              {this.state.CurrencyLabel}<span style={webStyle.errorTextColor}>*</span>
            </InputLabel>
            <SelectField>
              <CustomSelect
                labelId="Currency"
                data-testid="Currency"
                displayEmpty
                id="Currency"
                variant="outlined"
                fullWidth
                disabled={this.state.isView}
                value={this.state.account_currency}
                style={webStyle.select}
                onChange={this.handleCurrencyChange.bind(this)}
                defaultValue={""}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem value={0} className={classes?.selectPlaceholder}>
                  <em style={webStyle.countryPlaceHolder}>
                    {this.state.ChooseCurrencyPlaceholder}
                  </em>
                </MenuItem>
                {this.state.all_account_currencies.map((data: any) => {
                  return (
                    <MenuItem value={data.id} key={data.id}>
                      {data.name}
                    </MenuItem>
                  );
                })}
                {this.state.all_account_currencies.length === 0 && (
                  <MenuItem value="noData" disabled>
                    {this.state.noDataText}
                  </MenuItem>
                )}
              </CustomSelect>
            </SelectField>
            {
              ((this.state.personalDetailsError && this.state.account_currency < 1)) && (
                <Typography style={webStyle.errorText} component={"span"}>
                  {this.state.CurrencyisRequired}
                </Typography>
              )
            }
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
            <InputLabel htmlFor="website" style={webStyle.inputLabel}>
              {this.state.websiteLabel}
            </InputLabel>
            <InputField
              id="website"
              data-testid="website"
              variant="outlined"
              disabled={this.state.isView}
              onChange={this.handleChange("website").bind(this)}
              value={this.state.website}
              placeholder={this.state.enterWebsitePlaceholder}
              fullWidth
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids}></Grid>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
            <InputLabel htmlFor="billing_street" style={webStyle.inputLabel}>
              {this.state.billingStreetLabel}<span style={webStyle.errorTextColor}>*</span>
            </InputLabel>
            <InputField
              id="billing_street"
              data-testid="billing_street"
              variant="outlined"
              disabled={this.state.isView}
              onChange={this.handleChange("billing_street").bind(this)}
              value={this.state.billing_street}
              placeholder={this.state.billingStreetPlaceholder}
              fullWidth
            />
            {
              ((this.state.personalDetailsError && this.state.billing_street === "")) && (
                <Typography style={webStyle.errorText} component={"span"}>
                  {this.state.billingStreetisRequried}
                </Typography>
              )
            }
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
            <CustomBox display={"flex"} justifyContent="space-between" style={webStyle.customBoxWidth} className={classes?.wraperWidthRes}>
              <InputLabel htmlFor="shipping_street" style={{...webStyle.inputLabel, ...webStyle.inputLabelWidth}}>
                {this.state.shippingStreetLabel}<span style={webStyle.errorTextColor}>*</span>
              </InputLabel>
              <Grid container alignItems="center" style={webStyle.checkBoxGrid}>
                <CheckBoxLabel
                  control={
                    <Checkbox
                      color="default"
                      disableRipple
                      disabled={this.state.isView}
                      checked={this.state.is_same_as_billing_address}
                      onChange={this.handleAddressCheck}
                      checkedIcon={<span style={webStyle.checkedIcon} />}
                      icon={<span style={webStyle.icon} />}
                      inputProps={{ "aria-label": "decorative checkbox" }}
                    />
                  }
                  label={""}
                />
                <Typography style={webStyle.termsText} component={"span"}>
                  {this.state.billingAddressCheckboxLabel}
                </Typography>
              </Grid>
            </CustomBox>
            <InputField
              id="shipping_street"
              data-testid="shipping_street"
              variant="outlined"
              disabled={this.state.isView}
              onChange={this.handleChange("shipping_street").bind(this)}
              value={this.state.shipping_street}
              placeholder={this.state.shippingStreetPlaceholder}
              fullWidth
            />
            {
              ((this.state.personalDetailsError && this.state.shipping_street === "")) && (
                <Typography style={webStyle.errorText} component={"span"}>
                  {this.state.shippingStreetisRequired}
                </Typography>
              )
            }
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
            <InputLabel htmlFor="billing_city" style={webStyle.inputLabel}>
              {this.state.billingCityLabel}<span style={webStyle.errorTextColor}>*</span>
            </InputLabel>
            <InputField
              id="billing_city"
              data-testid="billing_city"
              variant="outlined"
              disabled={this.state.isView}
              onChange={this.handleChange("billing_city").bind(this)}
              value={this.state.billing_city}
              placeholder={this.state.billingCityPlaceholder}
              fullWidth
            />
            {
              ((this.state.personalDetailsError && this.state.billing_city === "")) && (
                <Typography style={webStyle.errorText} component={"span"}>
                  {this.state.billingCityisRequired}
                </Typography>
              )
            }
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
            <InputLabel htmlFor="shipping_city" style={webStyle.inputLabel}>
              {this.state.shippingCityLabel}<span style={webStyle.errorTextColor}>*</span>
            </InputLabel>
            <InputField
              id="shipping_city"
              data-testid="shipping_city"
              variant="outlined"
              onChange={this.handleChange("shipping_city").bind(this)}
              value={this.state.shipping_city}
              disabled={this.state.isView}
              placeholder={this.state.shippingCityPlaceholder}
              fullWidth
            />
            {
              ((this.state.personalDetailsError && this.state.shipping_city === "")) && (
                <Typography style={webStyle.errorText} component={"span"}>
                  {this.state.shippingCityisRequired}
                </Typography>
              )
            }
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
            <InputLabel htmlFor="billing_postal_code" style={webStyle.inputLabel}>
              {this.state.billingPostalCodeLabel}<span style={webStyle.errorTextColor}>*</span>
            </InputLabel>
            <InputField
              id="billing_postal_code"
              data-testid="billing_postal_code"
              variant="outlined"
              onChange={this.handleChange("billing_postal_code").bind(this)}
              value={this.state.billing_postal_code}
              disabled={this.state.isView}
              type="text"
              placeholder={this.state.billingPostalCodePlaceholder}
              fullWidth
            />
            {
              ((this.state.personalDetailsError && this.state.billing_postal_code === "")) && (
                <Typography style={webStyle.errorText} component={"span"}>
                  {this.state.billingPostalCodeisRequired}
                </Typography>
              )
            }
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
            <InputLabel htmlFor="shipping_postal_code" style={webStyle.inputLabel}>
              {this.state.shippingPostalCodeLabel}<span style={webStyle.errorTextColor}>*</span>
            </InputLabel>
            <InputField
              id="shipping_postal_code"
              data-testid="shipping_postal_code"
              variant="outlined"
              onChange={this.handleChange("shipping_postal_code").bind(this)}
              value={this.state.shipping_postal_code}
              disabled={this.state.isView}
              placeholder={this.state.shippingPostalPlaceholder}
              fullWidth
            />
            {
              ((this.state.personalDetailsError && this.state.shipping_postal_code === "")) && (
                <Typography style={webStyle.errorText} component={"span"}>
                  {this.state.shippingPostalisRequired}
                </Typography>
              )
            }
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
            <InputLabel htmlFor="billing_state" style={webStyle.inputLabel}>
              {this.state.billingStateLabel}<span style={webStyle.errorTextColor}>*</span>
            </InputLabel>
            <InputField
              id="billing_state"
              data-testid="billing_state"
              variant="outlined"
              onChange={this.handleChange("billing_state").bind(this)}
              value={this.state.billing_state}
              disabled={this.state.isView}
              placeholder={this.state.billingStatePlaceholder}
              fullWidth
            />
            {
              ((this.state.personalDetailsError && this.state.billing_state === "")) && (
                <Typography style={webStyle.errorText} component={"span"}>
                  {this.state.billingStateisRequired}
                </Typography>
              )
            }
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
            <InputLabel htmlFor="shipping_state" style={webStyle.inputLabel}>
              {this.state.shippingStateLabel}<span style={webStyle.errorTextColor}>*</span>
            </InputLabel>
            <InputField
              id="shipping_state"
              data-testid="shipping_state"
              variant="outlined"
              onChange={this.handleChange("shipping_state").bind(this)}
              value={this.state.shipping_state}
              disabled={this.state.isView}
              placeholder={this.state.shippingStatePlaceholder}
              fullWidth
            />
            {
              ((this.state.personalDetailsError && this.state.shipping_state === "")) && (
                <Typography style={webStyle.errorText} component={"span"}>
                  {this.state.shippingStateisRequired}
                </Typography>
              )
            }
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
            <InputLabel htmlFor="billing_country" style={webStyle.inputLabel}>
              {this.state.billingCountryLabel}<span style={webStyle.errorTextColor}>*</span>
            </InputLabel>
            <InputField
              id="billing_country"
              data-testid="billing_country"
              variant="outlined"
              disabled={this.state.isView}
              onChange={this.handleChange("billing_country").bind(this)}
              value={this.state.billing_country}
              placeholder="Enter Billing Country"
              fullWidth
            />
            {
              ((this.state.personalDetailsError && this.state.billing_country === "")) && (
                <Typography style={webStyle.errorText} component={"span"}>
                  {`Billing country is required`}
                </Typography>
              )
            }
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
            <InputLabel htmlFor="shipping_country" style={webStyle.inputLabel}>
              {this.state.shippingCountryLabel}<span style={webStyle.errorTextColor}>*</span>
            </InputLabel>
            <InputField
              id="shipping_country"
              data-testid="shipping_country"
              variant="outlined"
              disabled={this.state.isView}
              onChange={this.handleChange("shipping_country").bind(this)}
              value={this.state.shipping_country}
              placeholder="Enter Shipping Country"
              fullWidth
            />
            {
              ((this.state.personalDetailsError && this.state.shipping_country === "")) && (
                <Typography style={webStyle.errorText} component={"span"}>
                  {`Shipping country is required`}
                </Typography>
              )
            }
          </Grid>
        </Grid>
        {this.personalSaveDetailsBtn()}
      </CustomBox>
    );
  }

  personalSaveDetailsBtn = () => {
    return (
      !this.state.isView && <Grid container style={webStyle.isViewMargin}>
        <SaveButton type="submit" variant="contained" data-testid="personaldetailssubmitbtn">
          {this.isUpdate ? "Save & Next" : "Save"}
        </SaveButton>
      </Grid>
    )
  }

  BAMDetails() {
    const { classes } = this.props
    return (
      <CustomBox minHeight='calc(100vh - 500px)' >
        {(!this.state.isBamEdit && this.state.isEditDetails && this.state.isViewOnly) && <Grid container spacing={2} style={webStyle.marginBottomStyle}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <EditButton data-testid="bameditbtn" onClick={() => this.handleBamEdit()} variant="contained" style={webStyle.floatRight}>
              {this.state.editBtnText}
            </EditButton>
          </Grid>
        </Grid>}
        {!this.state.isBamEdit && this.state.bam_details ?
          <>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography style={webStyle.DetailHeading}>
                Biocon Account Manager
              </Typography>
              {
                this.state.bam_details.length === 0 && (
                  <>
                    <Typography style={webStyle.NoDataPaddingStyle}>
                      {this.state.noDataText}
                    </Typography>
                  </>
                )
              }
            </Grid>
            <Grid container spacing={2}>
              {
                this.state.bam_details.map((data: any) => {
                  return (
                    <Grid item lg={4} md={6} sm={6} xs={6} key={data.attributes.id} alignItems="center">
                      <CustomCard>
                        <CardContent>
                          <Typography style={webStyle.customerName}>
                            {data.attributes.name}
                          </Typography>
                          <Typography style={webStyle.typoStyle}>
                            <MailOutlineIcon style={webStyle.DetailsIcon} />
                            <Typography component="span" style={webStyle.dataText}>
                              {data.attributes.email}
                            </Typography>
                          </Typography>
                          <Typography style={webStyle.typoStyle}>
                            <LocalPhoneOutlinedIcon style={webStyle.DetailsIcon} />
                            <Typography component="span" style={webStyle.dataText}>
                              +{data.attributes.country_code} {data.attributes.phone_number}
                            </Typography>
                          </Typography>
                          <Typography style={webStyle.typoStyle}>
                            <LocationOnOutlinedIcon style={webStyle.DetailsIcon} />
                            <Typography component="span" style={webStyle.dataText}>
                              {data.attributes.territory_name}
                            </Typography>
                          </Typography>
                        </CardContent>
                      </CustomCard>
                    </Grid>
                  )
                })
              }
            </Grid>
          </> : ""}
        {this.state.isBamEdit &&
          <>
            {this.state.multipleBAMForm.length === 0 && (
              <SaveButton data-testid="addmore" onClick={() => this.addMultipleBamForm()} variant="contained"
                className={classes?.addMoreProducts}>
                Add
              </SaveButton>
            )}
            {this.state.multipleBAMForm.map((data: any, index: number) => {
              return (
                <Grid container spacing={7} key={data.biocon_manager_id}>
                  <Grid item lg={6} md={6} sm={6} xs={12} style={webStyle.grids}>
                    <InputLabel htmlFor="accountManager" style={webStyle.inputLabel}>
                      {this.state.accountManagerLabel}<span style={webStyle.errorTextColor}>*</span>
                    </InputLabel>
                    <SelectField>
                      <CustomSelect
                        labelId="accountManager"
                        displayEmpty
                        id="accountManager"
                        data-testid="accountManager"
                        variant="outlined"
                        fullWidth
                        value={data.attributes.id}
                        style={webStyle.select}
                        onChange={(e: any) => this.handleManagerChange(e, index)}
                        defaultValue={""}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        <MenuItem disabled value={0} className={classes?.selectPlaceholder}>
                          <em style={webStyle.countryPlaceHolder}>
                            Choose Account Manager
                          </em>
                        </MenuItem>
                        {
                          this.state.biocon_account_managers.map((data, index) => {
                            return (
                              <MenuItem value={data.id} key={data.id}>
                                {data.name}
                              </MenuItem>
                            );
                          })
                        }
                        {
                          this.state.biocon_account_managers.length === 0 && (
                            <MenuItem value="noData" disabled>
                              {this.state.noDataText}
                            </MenuItem>
                          )
                        }
                      </CustomSelect>
                    </SelectField>
                    {
                      (this.state.accountManagerError && this.state.multipleBAMForm[0].attributes.id === 0) && (
                        <div style={webStyle.accountManagerError}>Biocon Account Manager required</div>
                      )
                    }
                  </Grid>
                  <>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={webStyle.grids}>
                      <InputLabel htmlFor="bamEmailId" style={webStyle.inputLabel}>
                        {this.state.bamEmailIdLabel}
                      </InputLabel>
                      <InputField
                        id="bamEmailId"
                        variant="outlined"
                        onChange={this.handleChange("bamEmailId").bind(this)}
                        value={data.attributes.email}
                        placeholder="Enter Email ID"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={webStyle.grids}>
                      <InputLabel htmlFor="bamPhoneNo" style={webStyle.inputLabel}>
                        {this.state.bamPhoneNoLabel}<span style={webStyle.errorTextColor}>*</span>
                      </InputLabel>
                      <Grid container spacing={1}>
                        <Grid item lg={2} md={2} sm={3} xs={4}>
                          <InputField
                            disabled
                            defaultValue={"+91"}
                            className="noField"
                            variant="outlined"
                            value={data.attributes.country_code}
                          >
                          </InputField>
                        </Grid>
                        <Grid item md={10} lg={10} sm={9} xs={8}>
                          <InputField
                            id="bamEmailId"
                            variant="outlined"
                            onChange={this.handleChange("phoneNumber").bind(this)}
                            value={data.attributes.phone_number}
                            placeholder="Enter Phone No"
                            fullWidth
                            disabled
                          >
                          </InputField>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={webStyle.grids}>
                      <InputLabel htmlFor="territory" style={webStyle.inputLabel}>
                        {this.state.territoryLabel}<span style={webStyle.errorTextColor}>*</span>
                      </InputLabel>
                      <InputField
                        id="bamEmailId"
                        variant="outlined"
                        onChange={this.handleChange("bamEmailId").bind(this)}
                        value={data.attributes.territory_name}
                        placeholder={'Territory'}
                        fullWidth
                        disabled
                      />
                      {
                        (this.state.territoryIdError && this.state.territory_id === 0) && (
                          <div style={webStyle.terriotoryError}>Please Select Territory</div>
                        )
                      }
                    </Grid>
                  </>
                  <Grid item container alignItems="center" justifyContent="flex-end" lg={12} md={12} sm={12} xs={12}>
                    <DeleteIcon style={webStyle.deleteBam} onClick={() => this.removeBamDetails(index)}></DeleteIcon>
                    {
                      this.state.multipleBAMForm.length - 1 === index && (
                        <SaveButton data-testid="addmore" onClick={() => this.addMultipleBamForm()} variant="contained"
                          className={classes?.addMoreProducts}
                        >
                          Add More +
                        </SaveButton>
                      )
                    }
                  </Grid>
                </Grid>
              )
            })
            }
            <Grid style={{ ...webStyle.ButtonBox, ...webStyle.buttonPadding }} className={classes?.bamEditSave} container>
              <SaveButton type="submit" variant="contained">
                Save & Next
              </SaveButton>
            </Grid>
          </>
        }
      </CustomBox>
    )
  }

  AdditionalDetails() {
    const { classes } = this.props
    return (
      <CustomBox minHeight='calc(100vh - 500px)' >
        <Grid container spacing={2}>
          {(!this.state.isProductMapUpdate && this.state.isEditDetails && this.state.isViewOnly) &&
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <EditButton data-testid="additionalnewbtn" onClick={() => this.handleProductMapEdit()} variant="contained" style={webStyle.floatRight}>
                Add New
              </EditButton>
            </Grid>}
          {
            !this.state.isProductMapUpdate && this.state.mappedProducts.map((data: IProductGet, index) => {
              return (
                <Grid item lg={3} key={data.id} style={webStyle.listGrid} >
                  <CustomCard>
                    {
                      (this.state.isEditDetails && this.state.Can_delete_permission) && (
                        <DeleteIcon
                          data-testid="additionaldetaildeletebtn"
                          className="float_right"
                          style={{ ...webStyle.deleteIconStyle, ...webStyle.deleteIconPadding}}
                          onClick={() => this.handleDeleteProductMapped(+data.id)}>
                        </DeleteIcon>
                      )
                    }
                    <CardContent>
                      <Typography style={webStyle.customerName}>
                        {data.attributes.product_name}
                      </Typography>
                      <Typography style={webStyle.typoStyle}>
                        <Typography component="span" style={webStyle.dataText}>
                          {data.attributes.therapeutic_area}
                        </Typography>
                      </Typography>
                      <Typography style={webStyle.typoStyle}>
                        <Typography component="span" style={webStyle.dataText}>
                          {data.attributes.api_technology}
                        </Typography>
                      </Typography>
                    </CardContent>
                  </CustomCard>
                </Grid>
              )
            })
          }

        </Grid>
        {
          this.state.isProductMapUpdate && this.state.multipleProductForm.map((form: any, index: number) => {
            return <Grid container spacing={7} key={form.product_id}>
              <Grid key={form.id} item lg={6} md={6} sm={6} xs={12}>
                <InputLabel htmlFor="mappedProduct" style={webStyle.inputLabel}>
                  {this.state.mappedProductLabel}{` ${index + 1}`}<span style={webStyle.errorTextColor}>*</span>
                </InputLabel>
                <SelectField>
                  <CustomSelect
                    labelId="mappedProduct"
                    data-testid="mappedProduct"
                    displayEmpty
                    id="mappedProduct"
                    variant="outlined"
                    fullWidth
                    value={form.product_id}
                    style={webStyle.select}
                    defaultValue={form.product_id}
                    onChange={(e: any, v: any) => this.handlechangeProducts(e, v, index)}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem value={0} className={classes?.selectPlaceholder}>
                      <em style={webStyle.countryPlaceHolder}>
                        Choose Product to map
                      </em>
                    </MenuItem>
                    {this.state.products.map((data: IProduct, index) => {
                      return (
                        <MenuItem disabled={data.isSelected} value={data.id} key={data.id}>
                          {data.name}
                        </MenuItem>
                      );
                    })}
                    {this.state.products.length === 0 && (
                      <MenuItem value="noData" disabled>
                        {this.state.noDataText}
                      </MenuItem>
                    )}
                  </CustomSelect>
                </SelectField>
                {
                  (this.state.productMappedError && form.product_id === 0) && (
                    <div style={webStyle.productMappError}>{`Please select mapped product`}</div>
                  )
                }
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12} container alignItems="center"
                style={webStyle.productMapGrid}
              >
                {index !== 0 &&
                  <DeleteIcon onClick={() => this.deleteFormElement(index)}
                    style={webStyle.deleteEle}
                  ></DeleteIcon>}
                {index === (this.state.multipleProductForm.length - 1) &&
                  <SaveButton data-testid="addmore" onClick={() => this.addMultipleForm()} variant="contained"
                    className={classes?.addMoreProducts}
                  >
                    Add More +
                  </SaveButton>}
              </Grid>
            </Grid>
          })
        }

        {
          this.state.isCreatedPopup && (
            <QueryDialog
              open={this.state.isCreatedPopup}
              onClose={this.handleIsCreatedClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle>
                <IconBox onClick={this.handleIsCreatedClose}>
                  <CloseIcon style={webStyle.closeIcon}>
                  </CloseIcon>
                </IconBox>
              </DialogTitle>
              <DialogContent>
                <Typography style={webStyle.PopUpText}>
                  {this.state.createdCustomerPopupText}
                </Typography>
              </DialogContent>
              <DialogActions>
                <CUSButton onClick={this.handleIsCreatedClose}>
                  <Typography style={webStyle.custID}>
                    {this.state.customerMaster}
                  </Typography>
                </CUSButton>
              </DialogActions>
            </QueryDialog>
          )
        }
        {this.state.isProductMapUpdate && <Grid style={webStyle.updateBtn}>
          <SaveButton type="submit" variant="contained">
            {this.isUpdate ? "Save & Next" : "Approve"}
          </SaveButton>
          <SaveButton
            onClick={() => this.handleProductMappedCancel()}
            variant="outlined"
            style={webStyle.cancelBtn}
            className={classes?.productsAddCancel}
          >
            Cancel
          </SaveButton>
        </Grid>}
      </CustomBox>
    )
  }

  ContactDetails() {
    const { classes } = this.props
    return (
      <CustomBox minHeight='calc(100vh - 480px)' style={webStyle.contactGrid}>
        {(!this.state.isCustomerContactUpdate && this.state.isEditDetails && this.state.isViewOnly) &&
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <EditButton data-testid="contactdetailsaddnewbtn" onClick={() => this.handleCustomerContactAddNewClick()} variant="contained" style={webStyle.floatRight}>
              Add New
            </EditButton>
          </Grid>}
        <Grid container spacing={3}>
          {this.state.isCustomerContactUpdate && <React.Fragment>
            <Grid item lg={6} md={6} sm={6} xs={12} style={webStyle.grids}>
              <InputLabel htmlFor="customer_contact" style={webStyle.inputLabel}>
                Customer Contact Person
              </InputLabel>
              <SelectField>
                <CustomSelect
                  labelId="customer_contact"
                  displayEmpty
                  id="customer_contact"
                  data-testid="customer_contact"
                  variant="outlined"
                  fullWidth
                  value={this.state.selectedContactCustomerId}
                  style={webStyle.select}
                  onChange={this.handleContactMasterChange.bind(this)}
                  defaultValue={""}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem value={0} className={classes?.selectPlaceholder}>
                    <em style={webStyle.countryPlaceHolder}>
                      Choose Contact Person
                    </em>
                  </MenuItem>
                  {this.state.allCustomerContacts.map((data: ICustomerContacts) => {
                    return (
                      <MenuItem value={data.id} key={data.id}>
                        {data.name}
                      </MenuItem>
                    );
                  })}
                  {this.state.allCustomerContacts.length === 0 && (
                    <MenuItem value="noData" disabled>
                      {this.state.noDataText}
                    </MenuItem>
                  )}
                </CustomSelect>
              </SelectField>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12} style={webStyle.grids}>
              <InputLabel htmlFor="bamEmailId" style={webStyle.inputLabel}>
                Contact Person Email ID
              </InputLabel>
              <InputField
                id="bamEmailId"
                variant="outlined"
                onChange={this.handleChange("bamEmailId").bind(this)}
                value={this.state.contactPersonEmail}
                placeholder="Enter Email ID"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12} style={webStyle.grids}>
              <InputLabel htmlFor="bamEmailId" style={webStyle.inputLabel}>
                Customer Name
              </InputLabel>
              <InputField
                id="bamEmailId"
                variant="outlined"
                onChange={this.handleChange("name").bind(this)}
                value={this.state.contactPersonName}
                placeholder="Enter Name"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12} style={webStyle.grids}>
              <SaveButton
                data-testid="customercontactaddnewbtn"
                onClick={() => this.handleCustomerContactAddNewClick()}
                style={webStyle.addMoreBtn}
                variant="contained"
              >
                + Add More
              </SaveButton>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12} style={{ ...webStyle.grids, ...webStyle.buttonTopPadding }} >
              <SaveButton data-testid="customercontactsave" onClick={() => this.onCustomerContactSave()} type="submit" variant="contained">
                Save
              </SaveButton>
            </Grid>
          </React.Fragment>}
        </Grid>

        <Grid container spacing={2}>
          {!this.state.isEditDetails && this.state.selectedCustomerContacts.map((customer: any) => (
            <Grid item lg={4} key={customer.customer_contact_person_id} onClick={() => localStorage.setItem("From", "ticketDetail")}>
              <RLink
                to={`/CustomerContact/edit/${customer.customer_contact_person_id}`}
                exact={true}
                onClick={() => localStorage.setItem("From", "ticketDetail")}
              >
                <CustomCard>
                  <CardContent>
                    <Typography style={webStyle.customerName}>
                      {customer.customer_contact_person}
                    </Typography>
                    <Typography style={webStyle.typoStyle}>
                      <MailOutlineIcon style={webStyle.DetailsIcon} />
                      <Typography component="span" style={webStyle.emailText}>
                        {customer.contact_person_email_id}</Typography>
                    </Typography>
                    <Typography style={webStyle.typoStyle}>
                      <BusinessIcon style={webStyle.DetailsIcon} />
                      <Typography component="span" style={webStyle.emailText}>
                        {customer.customer_name}
                      </Typography>
                    </Typography>
                  </CardContent>
                </CustomCard>
              </RLink>
            </Grid>
          ))
          }

          {this.state.isEditDetails && !this.state.isCustomerContactUpdate && this.state.selectedCustomerContacts.map((customer: any) => (
            <Grid item lg={4} key={customer.customer_contact_person_id}>
              <RLink
                to={`/CustomerContact/edit/${customer.customer_contact_person_id}`}
                exact={true}
              >
                <CustomCard>
                  <CardContent>
                    {
                      (this.state.isEditDetails && this.state.Can_delete_permission) && (
                        <DeleteIcon
                          data-testid="conactdetailsdeletebtn"
                          style={webStyle.deleteIconStyle}
                          className="float_right"
                          onClick={() => this.handleDeleteClick(customer.id)}>

                        </DeleteIcon>
                      )
                    }
                    <Typography style={webStyle.customerName}>
                      {customer.customer_contact_person}
                    </Typography>
                    <Typography style={webStyle.typoStyle}>
                      <MailOutlineIcon style={webStyle.DetailsIcon} />
                      <Typography component="span" style={webStyle.emailText}>
                        {customer.contact_person_email_id}</Typography>
                    </Typography>
                    <Typography style={webStyle.typoStyle}>
                      <BusinessIcon style={webStyle.DetailsIcon} />
                      <Typography component="span" style={webStyle.emailText}>
                        {customer.customer_name}
                      </Typography>
                    </Typography>
                  </CardContent>
                </CustomCard>
              </RLink>
            </Grid>
          ))
          }
        </Grid>
      </CustomBox>
    )
  }

  InvoiceDetails() {
    return (
      this.state.isInvoiceLoading ?
        <Loader loading={this.state.isInvoiceLoading} />
        :
        <CustomBox minHeight='calc(100vh - 500px)' >
          <Grid container spacing={2}>
            {(!this.state.isProductMapUpdate && this.state.isEditDetails) &&
              <Grid container lg={12} md={12} sm={12} xs={12} justifyContent="space-between">
                <Grid item lg={3} md={3} sm={4} xs={10} style={webStyle.searchGrid}>
                  <InputField
                    id="searchFolder"
                    data-testid="invoiceserach"
                    variant="outlined"
                    onChange={this.handleSearchInvoices}
                    placeholder="Search Invoice"
                    fullWidth
                    InputProps={{
                      startAdornment:
                        <InputAdornment position="start">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            <SearchIcon style={webStyle.searchIcon}></SearchIcon>
                          </IconButton>
                        </InputAdornment>
                    }}
                  />
                </Grid>
                {
                  localStorage.getItem("employee_invoice_permission") === "true" && (
                    <EditButton data-testid="addnewinvoicebtn" onClick={() => this.handleInvoiceAddNewClick()}
                      variant="contained" style={webStyle.floatRight}>
                      Add New
                    </EditButton>
                  )
                }
              </Grid>}
            {
              this.state.allcustomerInvioce?.map((curEle: any) => {
                return (
                  <Grid item lg={3} md={4} sm={6} style={webStyle.listGrid} key={curEle?.id}>
                    <RLink
                      to={`/AddInvoice/edit/${this.props.navigation.getParam('id')}/${curEle.id}`}
                      exact={true}
                    >
                      <CustomCard>
                        <CardContent>
                          <Grid container justifyContent="space-between">
                            <Typography style={webStyle.customerInvoiceName}>
                              {curEle?.attributes?.customer_invoice_catalogue} -
                              <span style={webStyle.invoiceNumber}> {curEle?.attributes?.invoice}</span>
                            </Typography>
                            <Typography style={webStyle.creationDate}>
                              {moment(curEle?.attributes?.invoice_created_date, "DD/MM/YYYY").format("DD-MM-YYYY")}
                            </Typography>
                          </Grid>

                          <Typography style={webStyle.typoStyle}>
                            <PermContactCalendarIcon style={webStyle.DetailsIcon} />
                            <Typography component="span" style={webStyle.emailText}>
                              {curEle?.attributes?.customer_invoice_person}
                            </Typography>
                          </Typography>
                          <Typography style={webStyle.typoStyle}>
                            <CalendarMonthIcon style={webStyle.DetailsIcon} />
                            <Typography component="span" style={webStyle.emailText}>
                              {curEle?.attributes?.invoice_date}
                            </Typography>
                          </Typography>
                        </CardContent>
                      </CustomCard>
                    </RLink>
                  </Grid>
                )
              })
            }
          </Grid>
          {this.state.allcustomerInvioce.length !== 0 ? <Grid item xs={12} sm={12} lg={12} style={webStyle.paginationGrid}>
            <CustomPagination
              data-testid="customepaginaion"
              per_page={this.state.perPageRecord}
              page={this.state.customerInvoiceListingMeta?.pagination_details.current_page}
              count={this.state.customerInvoiceListingMeta?.pagination_details.total_pages}
              arrayLength={this.state.customerInvoiceListingMeta?.pagination_details.total_count}
              handlePageChange={(e: any, p: any) => this.handlePageChange(e, p)}
            />
          </Grid> :
            <p style={webStyle.noInvoiceData}>
              No Invoice Found
            </p>
          }
        </CustomBox>
    );
  }

  popupTextCondition = () => {
    return (
      this.state.isProductMapDelete ? this.state.deleteProductMappedPopupText : this.state.deleteCustomerContactPopupText
    )
  }

  TabsContent = () => {
    return (
      <CustomTabs
            data-testid="changeTab"
            onChange={this.handleChangeTab}
            value={this.state.selectedTab}
            aria-label="Tabs where each tab needs to be selected manually"
            className="tabs-wrapper"
          >
            <CustomTab
              label="Personal Details"
              style={webStyle.Tab1}
              // disableRipple
            />
            <CustomTab
              label="BAM Details"
              disabled={!this.state.stateUserId}
              disableRipple
            />
            <CustomTab
              label="Products Mapped"
              // disabled={!this.state.stateUserId}
              disableRipple
            />
            {this.isUpdate && <CustomTab
              label="Customer Contacts"
              disableRipple
            />}
            <CustomTab
              label="Invoices"
              disabled={!this.state.stateUserId}
              style={webStyle.tab3}
              disableRipple
            />
          </CustomTabs>
    )
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <AppHeader navigation={this.props.navigation} />
        <MainContainer>
          <Loader loading={this.state.isLoading} data-testid="loading" />
          <Typography style={webStyle.detailTitle} data-testid="header">
            <KeyboardBackspaceIcon
              data-testid="backIcon"
              onClick={() => this.goTOBack()}
              style={webStyle.backSpaceIcon}
            />
            {this.isUpdate ? this.state.firstName : this.state.addCustomerheader}
          </Typography>
          {this.TabsContent()}
          {
            this.state.isDeletePopup && (
              <QueryDialog
                open={this.state.isDeletePopup}
                onClose={this.handleIsDeleteClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle>
                  <IconBox onClick={this.handleIsDeleteClose} data-testid="deleteclosebtn">
                    <CloseIcon style={webStyle.closeIcon}>
                    </CloseIcon>
                  </IconBox>
                </DialogTitle>
                <DialogContent>
                  <Typography style={webStyle.PopUpText}>
                    {this.state.isInvoiceDelete ? this.state.deleteInvoicePopupText : (this.popupTextCondition())}
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <SaveButton data-testid="deleteyesbtn" onClick={() => this.handleSaveInvoice()} variant="contained">
                    Yes
                  </SaveButton>
                  <SaveButton onClick={this.handleIsDeleteClose} variant="outlined">
                    No
                  </SaveButton>
                </DialogActions>
              </QueryDialog>
            )
          }
          <form
            data-testid="personaldetailsaveform"
            onSubmit={(e) => {
              e.preventDefault();
              if (this.state.selectedTab === 0) {
                if (this.state.stateUserId) {
                  this.onPersonalDetailsUpdate();
                } else {
                  this.onPersonalDetailsSave();
                }
              } else if (this.state.selectedTab === 1) {
                this.onBAMDetailsSave();
              } else if (this.state.selectedTab === 2) {
                this.onProductMappedDetailsSave();
              }
            }}
          >
            <TabPanel value={this.state.selectedTab} index={0} data-testid="personalDetailsTab">
              {this.perSonalDetails()}
            </TabPanel>

            <TabPanel value={this.state.selectedTab} index={1} data-testid="bam_details">
              {this.BAMDetails()}
            </TabPanel>

            <TabPanel value={this.state.selectedTab} index={2}>
              {this.AdditionalDetails()}
            </TabPanel>

            <TabPanel value={this.state.selectedTab} index={3}>
              {this.ContactDetails()}
            </TabPanel>
            <TabPanel value={this.state.selectedTab} index={4}>
              {this.InvoiceDetails()}
            </TabPanel>
          </form>
        </MainContainer>
        <AppFooter isLoading={this.state.isLoading} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const QueryDialog: any = withStyles({
  root: {
    "& .MuiDialogTitle-root": {
      padding: '16px 0px'
    },
    "& .MuiDialog-paperScrollPaper": {
      padding: '0px 10px 0px 10px',
      borderRadius: '10px',
    },
    "& .MuiDialogContent-root": {
      padding: '8px 24px',
      textAlign: 'center',
      paddingBottom: '25px'
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: '500px',
    },
    "& .MuiDialogActions-root": {
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px',
      paddingBottom: '30px',
    },
    "& .WithStyles(ForwardRef\\(Button)-root-10": {
      "@media (maxWidth: 600px)": {
        width: 300
      }
    }
  }
})(Dialog);

const webStyle = {
  productMapGrid: {
    paddingBottom: '0px'
  },
  deleteEle: {
    marginRight: '15px',
    fill: 'red'
  },
  updateBtn: {
    paddingTop: '12%'
  },
  cancelBtn: {
    marginLeft: '10px'
  },
  contactGrid: {
    marginTop: '25px' 
  },
  addMoreBtn: {
    marginTop: '28px'
  },
  buttonTopPadding: {
    paddingTop: '2%'
  },
  searchGrid: {
    marginTop: '0px', 
    marginLeft: '12px'
  },
  searchIcon: {
    fill: 'rgb(4, 60, 116)', 
    fontSize: '20px'
  },
  invoiceNumber: {
    fontWeight: 400, 
    color: "#6a6ea5"
  },
  paginationGrid: {
    marginTop: "10px"
  },
  noInvoiceData: {
    marginTop: '25px', 
    paddingLeft: '10px'
  },
  backSpaceIcon: {
    marginBottom: '-4px', cursor: 'pointer', paddingRight: '10px'
  },
  DetailsIcon: {
    fill: '#1D3D66'
  },
  terriotoryError: {
    color: 'red', 
    fontSize: '14px'
  },
  accountManagerError: {
    color: 'red', 
    fontSize: '14px', 
    marginTop: '4px'
  },
  productMappError: {
    color: 'red', 
    fontSize: '14px', 
    marginTop: '5px'
  },
  listGrid: {
    width: 'inherit'
  },
  buttonPadding: {
    paddingLeft: `0px !important`
  },
  deleteBam: {
    marginRight: '15px', 
    fill: 'red', 
    cursor: 'pointer', 
    paddingTop: '8px'
  },
  typoStyle: {
    display: 'flex',
    alignItems: 'center'
  },
  NoDataPaddingStyle: {
    paddingLeft: '20px'
  },
  isViewMargin: {
    marginTop: '40px'
  },
  checkBoxGrid: {
    padding: '0px 0px 0px 5px',
    marginTop: '-14px'
  },
  inputLabelWidth: {
    width: '90%',
  },
  customBoxWidth: {
    width: '75%'
  },
  inputPadding: {
    paddingBottom: '10px'
  },
  detailsGrid: {
    paddingTop: '20px'
  },
  editIconStyle: {
    marginLeft: "10px", 
    height: "19px", 
    width: "19px"
  },
  closeIcon: {
    fill: '#1A3B71', 
    cursor: 'hand'
  },
  deleteIconPadding: {
    padding: '15px'
  },
  deleteIconStyle: {
    fill: 'red', 
    cursor: 'hand'
  },
  floatRight: {
    float: 'right'
  },
  marginBottomStyle: { 
    marginBottom: '5px'
  },
  errorTextColor: {
    color: "red"
  },
  Tab1: {
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
  },
  PopUpText: {
    color: '#000000',
    fontFamily: 'SFProDisplay',
    fontSize: '20px',
    margin: '0px 15px',
  },
  custID: {
    fontWeight: 600,
    fontFamily: 'SFProDisplay',
    fontSize: '12px',
  },
  tab3: {
    borderEndEndRadius: "8px",
    borderTopRightRadius: "8px",
  },
  detailTitle: {
    color: "rgba(33, 43, 54, 1)",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    fontSize: "18px",
    paddingBottom: "13px",
    marginLeft: "24px",
  },
  inputLabel: {
    fontSize: "14px",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    color: "#1A3B71",
    paddingBottom: "5px",
  },
  errorText: {
    color: 'red',
    fontSize: '14px',
    display: 'inline-block',
    marginTop: '5px'
  },
  termsText: {
    color: "#727096",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "14px",
    paddingTop: "4px",
  },
  checkedIcon: {
    borderRadius: 4,
    border: "1px solid #727096",
    width: 14,
    height: 14,
    backgroundColor: "white",
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='black'/%3E%3C/svg%3E\")",
    "input:hover ~ &": {},
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 4,
    width: 14,
    height: 14,
    backgroundColor: "white",
    border: "1px solid #727096",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "white",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  countryPlaceHolder: {
    fontStyle: "normal",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "14px",
    color: "rgba(218, 224, 241, 1)",
  },
  select: {
    height: "35px",
    borderRadius: "8px",
    marginTop: '5px',
  },
  ButtonBox: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingLeft: '33px',
    paddingTop: '8%',
    paddingBottom: '30px'
  },
  grids: {
    paddingTop: '18px',
    paddingBottom: '0px'
  },
  customerName: {
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '16px',
    color: '#1D3D66',
    margin: '5px 0px 5px 30px'
  },
  customerInvoiceName: {
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '16px',
    color: '#1D3D66',
    margin: '5px 0px 5px 0px'
  },
  emailText: {
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '16px',
    margin: '10px 10px'
  },
  dataText: {
    fontFamily: 'SFProDisplay',
    fontWeight: 500,
    fontSize: '16px',
    margin: '6px 10px'
  },
  DetailHeading: {
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '18px',
    color: '#1D3D66',
    margin: '5px 0px 10px 10px'
  },
  creationDate: {
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '12px',
    color: '#1D3D66',
    margin: '7px 0px 5px 0px'
  },
};

const CustomTab: any = withStyles({
  root: {
    border: "1px solid rgba(4, 60, 116, 1)",
    width: "100%",
    color: "rgba(4, 60, 116, 1)",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    fontSize: "14px",
    textTransform: "none",
    "& .PrivateTabIndicator-colorSecondary-4": {
      display: "none",
    },
    "@media (max-width: 1024px)": {
      width: "25%",
    },
  },
})(Tab);

const CustomTabs: any = withStyles({
  root: {
    marginLeft: "24px",
    "& .MuiTab-root": {
      minHeight: '30px',
      maxWidth: '240px',
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .Mui-selected": {
      background: "rgba(225, 239, 255, 1)",
    },
    "@media (max-width: 470px)": {
      maxHeight: "80px",
    },
    "@media (max-width: 600px)": {
      marginLeft: "0px",
    },
  },
})(Tabs);

const InputField: any = withStyles({
  root: {
    background: "inherit",
    margin: "5px 0px 0px 0px",
    "& ::placeholder": {
      color: 'rgba(218, 224, 241, 1)',
      fontFamily: 'SFProDisplay',
      fontWeight: 400,
      fontSize: '14px',
      opacity: 1,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(214, 214, 214, 1)",
    },
    "& .MuiInputBase-input": {
      height: '15px',
    },
    "& .MuiOutlinedInput-input": {
      padding: `11.8px 14px !important`
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: "SFProDisplay",
      fontWeight: 400,
      backgroundColor: "rgba(255, 255, 255, 1)",
      fontSize: "14px",
      "& fieldset": {
        height: '40px',
        borderColor: "rgba(218, 224, 241, 1)",
        borderRadius: "8px",
        borderWidth: 1,
      },
      ".Mui-focused": {
        borderColor: "rgba(218, 224, 241, 1)",
      },
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px !important",
    },
    "& .MuiInputBase-input.Mui-disabled": {
      background: "#F1F4FA",
      borderRadius: "8px",
      height: 13,
      color: 'rgba(106, 116, 165, 1)',
      fontSize: '14px',
      fontFamily: 'SFProDisplay',
      fontWeight: 400,
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(218, 224, 241, 1)",
      borderWidth: '1px'
    }
  },
})(TextField);

const CheckBoxLabel: any = withStyles({
  root: {
    alignItems: "flex-start",
    "& .MuiTypography-body1": {
      fontFamily: "SFProDisplay",
      fontWeight: 400,
      fontSize: "18px",
      color: "rgba(58, 63, 99, 1)",
    },
    "&.MuiFormControlLabel-root": {
      marginRight: `1px !important`
    },
    "& .MuiIconButton-label": {
      marginBottom: `-5px !important`
    }
  },
})(FormControlLabel);

const SaveButton: any = withStyles({
  root: {
    color: "rgba(255, 255, 255, 1)",
    height: "35px",
    width: 130,
    borderRadius: "8px",
    fontFamily: "SFProDisplay",
    fontSize: "16px",
    fontWeight: 700,
    marginTop: "5px",
    boxShadow: "none",
    textTransform: "none",
    marginRight: '10px',
    "&.MuiButton-contained": {
      backgroundColor: 'rgba(4, 60, 116, 1)',
      "&:hover": {
        backgroundColor: 'rgba(4, 60, 116, 1)',
      }
    },
    "&.MuiButton-outlined": {
      border: '1px solid rgba(4, 60, 116, 1)',
      color: 'rgba(4, 60, 116, 1)',
      padding: '5px 15px',
      "&:hover": {
        backgroundColor: 'transparent',
      }
    },
    "&:hover": {
      backgroundColor: "rgba(4, 60, 116, 1)",
      boxShadow: "none",
    },
    "@media (max-width: 600px)": {
      width: "100%",
      marginLeft: 0,
    },
  },
})(Button);

const EditButton: any = withStyles({
  root: {
    color: "rgba(255, 255, 255, 1)",
    backgroundColor: "rgba(4, 60, 116, 1)",
    height: "40px",
    width: 110,
    borderRadius: "8px",
    fontFamily: "SFProDisplay",
    fontSize: "14px",
    fontWeight: 700,
    marginTop: "5px",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "rgba(4, 60, 116, 1)",
      boxShadow: "none",
    },
    "@media (max-width: 600px)": {
      width: "100%",
      marginLeft: 0,
    },
  },
})(Button);

const CUSButton: any = withStyles({
  root: {
    color: "#71769D",
    backgroundColor: "#F3F4F9",
    height: "44px",
    width: 150,
    borderRadius: "8px",
    fontFamily: "SFProDisplay",
    fontSize: "16px",
    fontWeight: 700,
    marginTop: "5px",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#F3F4F9",
      boxShadow: "none",
    },
    "@media (max-width: 600px)": {
      width: "100%",
      marginLeft: 0,
    },
  },
})(Button);

const MainContainer: any = withStyles({
  root: {
    "& .css-yk16xz-control": {
      borderRadius: '8px',
      borderColor: 'rgba(218, 224, 241, 1)',
      marginTop: '3px',
      height: '40px'
    },
    padding: "24px 36px 24px 48px",
    "@media (min-width: 600px) and (max-width: 750px)": {
      padding: "24px 24px 24px 24px",
    },
    "@media (max-width: 600px)": {
      padding: "24px 24px 24px 24px",
      "& .MuiBox-root-8": {
        padding: "24px 0px 24px 0px",
      },
      "& ..MuiBox-root-30": {
        padding: "24px 0px 24px 0px",
      },
      "& .MuiTypography-body1": {
        marginLeft: "0px !important",
      },
    },
    "& .noField": {
      display: 'flex',
      justifyContent: 'center',
      "& .MuiInputBase-input.Mui-disabled": {
        textAlign: 'center'
      }
    },
    '& .tabs-wrapper button': {
      minWidth: 'auto'
    }
  },
})(Container);

const CustomSelect: any = withStyles({
  root: {
    "&.MuiOutlinedInput-input": {
      padding: '13px 14px',
    }
  }
})(Select);

const SelectField: any = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      fontFamily: "SFProDisplay",
      backgroundColor: "rgba(255, 255, 255, 1)",
      fontSize: "16px",
      color: '#1F436D',
      fontWeight: 500,
    },
    "& .MuiInputBase-input": {
      fontFamily: "SFProDisplay",
      fontWeight: 500,
      backgroundColor: "rgba(255, 255, 255, 1)",
      fontSize: "16px",
      color: '#1F436D',
    },
    width: '100%',
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(218, 224, 241, 1)",
      borderWidth: 1,
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(218, 224, 241, 1)",
      borderWidth: 1,
    },
    "& .MuiSelect-icon": {
      fill: "rgba(4, 60, 116, 1)",
    },
    "& .MuiOutlinedInput-input": {
      padding: '10px 14px'
    },
    "& .MuiSelect-select": {
      "&.Mui-disabled": {
        background: "#F1F4FA",
        borderRadius: "8px",
        height: 13,
        color: 'rgba(106, 116, 165, 1)',
        fontSize: '14px',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
      }
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(218, 224, 241, 1)",
      borderWidth: '1px'
    }

  }
})(FormControl);

const CustomCard: any = withStyles({
  root: {
    boxShadow: 'none',
    border: '1px solid rgba(218, 224, 241, 1)',
    borderRadius: '8px',
    height: '100%',
    "& .MuiSvgIcon-root": {
      height: '20px',
      width: '20px',
    }
  }
})(Card);

const IconBox: any = withStyles({
  root: {
    background: '#DAE0EC',
    height: '26px',
    width: '26px',
    borderRadius: '50%',
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 0px 0px 0px',
    "& .MuiSvgIcon-root": {
      height: '20px',
      width: '20px'
    }
  }
})(Box)

const TabPanel: any = withStyles({
  container: {
    padding: 24,
    "@media (max-width: 600px)": {
      padding: '24px 0px 24px 0px'
    },
  },
})(CustomTabPanel)

const CustomBox: any = withStyles({
  root: {

  }
})(Box)

const styles = () => ({
  container: {
    "@media (max-width: 600px)": {
      paddingLeft: 20,
      paddingRight: 20
    }
  },
  bamEditSave: {
    "@media (max-width: 600px)": {
      paddingLeft: '28px !important',
      paddingRight: 18
    }
  },
  productsAddCancel: {
    "@media (max-width: 600px)": {
      marginLeft: '0px !important',
      marginTop: '20px'
    }
  },
  addMoreProducts: {
    marginLeft: '5px',
    "@media (max-width: 600px)": {
      marginLeft: '0px !important',
      marginRight: '0px !important'
    }
  },
  selectPlaceholder: {
    backgroundColor: '#fff !important',
    '&.Mui-disabled': {
      opacity: 1
    }
  },
  wraperWidthRes: {
    "@media (max-width: 1060px)": {
      width: '100% !important'
    }
  },
})

export default withStyles(styles)(CustomerAddUpdateProfile)
// Customizable Area End
