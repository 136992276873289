import React from "react";
// Customizable Area Start
import {
  Container, Typography, Grid, TextField, InputAdornment,
  IconButton, Box
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ProductListingController, { IProductListing, Props } from "./ProductListingController";
import Productcard from "./ProductCard2.web";
import AppFooter from "../../../components/src/AppFooter.web";
import { AppHeader } from "../../../components/src/AppHeader.web";
import CustomPagination from "../../../components/src/CustomPagination.web";
import CustomDropDown from "../../../components/src/CustomDropDown.web";
import SortMenuPopup from "./SortMenuPopup";
import Loader from "../../../components/src/Loader.web";
import EmployeeAppHeader from "../../../components/src/EmployeeAppHeader.web";
import { isEmployee } from "../../../components/src/common";
import SearchIcon from "@material-ui/icons/Search";
import CategoriesGroupDropdown2 from "../../../components/src/CategoriesGroupDropdown2";
import FlashLoginPopUp from "./FlashDetailsPop.web";

// Customizable Area End

export class ProductListing2 extends ProductListingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.searchInputRefVar = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  searchInputRefVar: React.RefObject<HTMLInputElement>;

  // Customizable Area End

  render() {
    return (
      <>
        {isEmployee() ? <EmployeeAppHeader navigation={this.props.navigation} /> : <AppHeader navigation={this.props.navigation} />}
        <SearchGrid>
          <InternalSearchGrid data-testid="internalSearch">
            <Container style={webStyle.noPadding}>
              <Grid container spacing={2} alignItems="center">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <InputField
                    data-testid="txtCompanyName"
                    variant="outlined"
                    required
                    type="text"
                    placeholder={this.state.SearchPlaceholder}
                    value={this.state.searchString}
                    onChange={(e: any) => this.searchByProductName(e)}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" >
                          <IconButton
                            aria-label={this.state.togglePasswordText}
                            edge="end"
                          >
                            <SearchIcon style={webStyle.searchIcon} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            </Container>
          </InternalSearchGrid>
        </SearchGrid>
        <FilterGrid>
          <Container style={webStyle.noPadding}>
            <Grid container spacing={2}>
              <CategoriesGroupDropdown2
                data-testid="categorydropdown"
                options={this.state.categoriesWithProductList}
                selectedValue={this.state.selectedCategoryGroupValue}
                handleOnChange={this.filterByProductCategory2}
              />
              <CustomDropDown
                data-testid="therapeuticAreadropdown"
                displayName={this.state.therapeuticAreaText}
                DropdownValue={this.state.therapyDropdownValue}
                FilterByDropType={this.filterByProductTherapy}
                uniqueProducts={this.state.uniqueProductsTherapy}
              />
              <CustomDropDown
                data-testid="manufacturingPlatform"
                displayName={this.state.manufacturingPlatformText}
                DropdownValue={this.state.productNameDropdownValue}
                FilterByDropType={this.filterByProductName}
                uniqueProducts={this.state.uniqueProductsName}
              />
            </Grid>
          </Container>
        </FilterGrid>

        <ProductGrid>
          <Container style={webStyle.noPadding}>
            <CustomBox display='flex' alignItems='center' justifyContent="center" style={webStyle.listPadding} >
                <CustomPagination
                  data-testid="customPagination"
                  isTopPagination={true}
                  per_page={PER_PAGE}
                  page={this.state.productsListingMeta?.pagination_details.current_page}
                  count={Math.ceil(this.state.productsListingMeta?.pagination_details.total_count / PER_PAGE)}
                  arrayLength={this.state.productsListingMeta?.pagination_details.total_count}
                  handlePageChange={(e: any, p: any) => this.handlePageChange(e, p)}
                  handleDownloadCatalog={this.downloadCatalogApiCall}
                />
              <SortMenuPopup
                data-testid="sortmenu"
                sortAscending={this.sortAscending.bind(this)}
                sortDescending={this.sortDescending}
                Ascending={this.state.Ascending}
              />
            </CustomBox>

            <Grid container spacing={2} style={webStyle.productContainer} data-testid="productcardGrids">
              {this.state?.productsList && this.state.productsList.length > 0 ? this.state?.productsList?.map((item: IProductListing, index: number) => (
                <Grid item lg={4} md={6} sm={12} xs={12} style={webStyle.cardPadding} key={item.id} data-testid="productcard">
                  <Productcard
                    item={item}
                    index={index}
                    handleClick={this.handleKnowMoreClick}
                    searchTerm={this.state.searchString}
                  />
                </Grid>
              ))
              : 
              <Grid
                style={webStyle.NoDataContainer}
                container
                justifyContent="center"
                alignItems="center"
                >
                  {(!this.state.loading && this.state.noData) && <Typography style={webStyle.noDataTitle}>
                    {this.state.noProductData}
                  </Typography>}
                </Grid>
              }
            </Grid>
            <CustomBox style={webStyle.paginationPadding}>
              <CustomPagination
                data-testid="customPagination1"
                per_page={PER_PAGE}
                page={this.state.productsListingMeta?.pagination_details.current_page}
                count={Math.ceil(this.state.productsListingMeta?.pagination_details.total_count / PER_PAGE)}
                arrayLength={this.state.productsListingMeta?.pagination_details.total_count}
                handlePageChange={(e: any, p: any) => this.handlePageChange(e, p)}
              />
            </CustomBox>
          </Container>
          {
            this.state.loading && (
              <Loader loading={this.state.loading} />
            )
          }
        </ProductGrid>
        {
          this.state.isProductFlashLogin && (
            <FlashLoginPopUp navigation={this.props.navigation} id={""} />
          )
        }
        <AppFooter isLoading={this.state.loading} />
      </>
    );
  }
}

// Customizable Area Start
const InternalSearchGrid: any = withStyles({
  root: {
    backgroundColor: "rgb(239 241 245)",
    padding: "0px 40px 0px 40px",
    borderColor: "rgba(218, 224, 241, 1)",
    display: 'flex',
    alignItems: 'center',
    "@media (max-width: 600px)": {
      padding: '0px',
    }
  }
})(Grid);

const CustomBox: any = withStyles({
  root: {
  }
})(Box);

const InputField: any = withStyles({
  root: {
    background: "inherite",
    margin: "12px 0px 10px 0px",
    "@media (max-width: 600px)": {
      margin: '0px',
    },
    "& ::placeholder": {
      color: '#6a74a5',
      fontSize: '16px',
      fontFamily: 'SFProDisplay',
      fontWeight: 400,
      opacity: 1,
    },
    "& .MuiInputBase-input": {
      height: '15px',
    },
    "& .MuiOutlinedInput-input": {
      padding: '8px 14px'
    },
    "& .MuiIconButton-root": {
      color: 'lightGrey'
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: "SFProDisplay",
      fontWeight: 400,
      backgroundColor: "rgba(255, 255, 255, 1)",
      fontSize: "16px",
      borderRadius: "8px",
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '-0.39px',
      color: '#6a74a5',
      '&.Mui-focused fieldset': {
        borderColor: "#dae0f1",
        borderWidth: 1,
      },
      '&.Mui-hovered fieldset': {
        borderColor: "#dae0f1",
        borderWidth: 1,
      },
      "& :hover": {
        borderColor: "#dae0f1",
        borderWidth: 1,
      },
      "& fieldset": {
        borderColor: "#dae0f1",
        borderWidth: 1,
        height: '35px',
      },
    },
    "& .MuiFormHelperText-contained": {
      margin: "0px !important",
      color: "rgba(106, 116, 165, 1)",
      fontSize: "12px",
      fontFamily: "SFProDisplay",
      fontWeight: 500,
    },
  },
})(TextField);

const FilterGrid: any = withStyles({
  root: {
    backgroundColor: "rgb(239 241 245)",
    padding: "5px 86px 15px 95px",
    "@media (max-width: 600px)": {
      padding: '15px 30px',
      paddingTop: '0px',
    }
  }
})(Grid);

const SearchGrid: any = withStyles({
  root: {
    padding: '15px 45px 0px 55px',
    background: 'rgb(239 241 245)',
    paddingTop: '5px',
    "@media (max-width: 600px)": {
      padding: `15px 30px 15px 30px !important`,
    }
  }
})(Grid);

const ProductGrid: any = withStyles({
  root: {
    padding: '0px 85px 10px 100px',
    "@media (max-width: 650px)": {
      padding: '10px 20px 10px 20px !important',
    },
    "& .MuiIconButton-root": {
      paddingTop: '8px',
    },
    "& .MuiGrid-root.MuiGrid-item > .MuiPaper-root.MuiCard-root": {
      boxShadow: '2px 4px 10px 0 rgba(0, 0, 0, 0.18)',
    },
    "@media (max-width: 600px)": {
      padding: `10px 20px 10px 20px !important`,
      "& .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12": {
        padding: '8px !important',
      },
    },
    "& .MuiTypography-h5": {
      fontFamily: "SFProDisplay",
      fontWeight: 700,
      fontSize: `26px !important`,
    },
    "& .MuiList-padding": {
      paddingTop: `0px !important`,
      paddingBottom: `0px !important`,
    }
  }
})(Grid);

const PER_PAGE = 9;
const webStyle = {
  productContainer: {
    backgroundColor: "white",
  },
  NoDataContainer: {
    height: '220px'
  },
  noDataTitle: {
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    color: 'rgb(4, 60, 116)',
    fontSize: '24px',
    marginTop: '-20px'
  },
  listPadding: {
    padding: '10px 0px 10px 0px'
  }, 
  paginationPadding: {
    padding: '30px 0px 20px 0px'
  },
  cardPadding: {
    padding: '10px 10px'
  },
  noPadding: {
    padding: 0
  },
  searchIcon: {
    fill: '#6a74a5'
  }
};

export default ProductListing2;
// Customizable Area End
