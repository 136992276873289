Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "RequestManagement";
exports.labelBodyText = "RequestManagement Body";

exports.detailsTitle = "Query Details";
exports.ticketIdLabel = "Ticket ID";
exports.queryTypeLabel = "Query Type";
exports.submittedByLabel = "Submitted By";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End