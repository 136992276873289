import React from "react";

// Customizable Area Start
import {
  Container,
  Typography,
  Box,
  Button,
  Grid,
  TextField,
  Dialog,
  DialogContent,
  MenuItem,
  Tab,
  Tabs,
  Select,
  InputLabel,
  FormControlLabel,
  Checkbox,
  FormControl,
  Popover,
  InputAdornment,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';
import AppFooter from "../../../components/src/AppFooter.web";
import AppHeader from "../../../components/src/EmployeeAppHeader.web";
import { MainImage, Logos } from "./assets";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { withStyles } from '@material-ui/core/styles';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { NavLink } from 'react-router-dom';
import SearchIcon from "@material-ui/icons/Search";
// Customizable Area End

import QueryDetailsController, {
  ICommonFolderFile,
  IEmployeeData,
  IInvoiceFolderFile,
  IProductBucketFileListing,
  Props,
} from "./QueryDetailController";
import Loader from "../../../components/src/Loader.web";

function DetailsTabPanel(props: {
  [x: string]: any;
  children: any;
  value: any;
  index: any;
}) {
  const { children, value, classes, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <CustomBox sx={{ p: 3 }} className={classes.container}>{children}</CustomBox>}
    </div>
  );
}

function grantAccessDuplicateCode(data: any, index: number) {
  return (
    <MenuItem key={data.id} value={data.id} style={webStyle.menuItem}>{data.name}</MenuItem>
  )
}

function transferQueryDuplicateCode(data: any, index: number) {
  return (
    <MenuItem key={`teamstatus${index}`} value={data.id} style={webStyle.menuItem}>{data.name}</MenuItem>
  )
}

function replyToCustomerDuplicateCode(data: any, index: number) {
  return (
    <MenuItem key={`status${index}`} value={data.id} style={webStyle.menuItem}>{data.name}</MenuItem>
  )
}


export class QueryDetail extends QueryDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  statusSelectList = (manuData: any) => {
    return this.state.statusList && (this.state.queryData?.query_type !== this.state.sampleRequestConditionText ?
      (this.StatusListForCXUser(manuData)) :
      this.state.statusList?.map(manuData))
  }

  StatusListForCXUser = (manuData: any) => {
    return (
      this.state.statusList.map(manuData)
      )
    }

  tranferredTicketHistory = (data: any, index: any) => {
    return (
      <CustomTimelineItem key={data.transfer_created_at}>
        <TimelineSeparator>
          <TimelineDot />
            {(index + 1) !== this.state.all_histories.length ? <TimelineConnector /> : null}
          </TimelineSeparator>
          <TimelineContent>
            <Typography style={webStyle.ticketHeader}>
                <span style={webStyle.ticketCreatedBy}>{data.transfer_comment_message} </span>
            </Typography>
            <Typography style={webStyle.ticketCreatedDate}>
              {data.transfer_created_at}
            </Typography>
            {data.transfer_comment &&
                <HistoryBox>
                  {data.transfer_comment}
                </HistoryBox>}
            </TimelineContent>
      </CustomTimelineItem>
    )
  }

  transferredTicketHistoryTeam = (data: any, index: any) => {
    return (
      <CustomTimelineItem key={data.transfer_created_at}>
        <TimelineSeparator>
          <TimelineDot />
            {(index + 1) !== this.state.all_histories.length ? <TimelineConnector /> : null}
          </TimelineSeparator>
          <TimelineContent>
            <Typography style={webStyle.ticketHeader}>
              <span style={webStyle.ticketCreatedBy}>{data.transferred_team_name ? data.transferred_team_name : data.transferred_emp_name}</span>
            </Typography>
            <Typography style={webStyle.ticketCreatedDate}>
              {data.transfer_created_at}
            </Typography>
          </TimelineContent>
        </CustomTimelineItem>
    )
  }

  ticketCreateHistory = () => {
    return (
      <>
        <Typography style={webStyle.ticketHeader}>
            {this.state.ticketCreatedByText} :
              <span style={webStyle.ticketCreatedBy}>{" "}
                  {this.state.ticket_created_by.name}
                  {this.state.ticket_created_by.company !== "" &&
                (
                  <>
                    <span>,</span>
                    {this.state.ticket_created_by.company}
                  </>
                )
              }
              </span>
        </Typography>
        <Typography style={webStyle.ticketCreatedDate}>
            {this.state.ticket_created_by.created_at}
        </Typography>
    </>
    )
  }

  commonStatusId(manuData?: any) {
    const { classes } = this.props
    return (
      <Grid item md={12} lg={12} sm={12} style={webStyle.paddingStyle}>
        <InputLabel
          htmlFor={this.state.statusText}
          style={webStyle.inputLabel}
        >
          {this.state.changeStatusText}
        </InputLabel>
        <SelectField>
          <Select
            labelId={this.state.statusText}
            displayEmpty
            id={this.state.statusText}
            variant="outlined"
            fullWidth
            style={webStyle.select}
            defaultValue={0}
            value={this.state.statusId}
            onChange={this.handleGrantAccessStatusChange}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
            disabled={this.state.istiketClosed}
          >
            <MenuItem disabled value={0} className={classes.selectPlaceholder}>
              <em style={webStyle.selectPlaceHolder}>{this.state.selectStatusText}</em>
            </MenuItem>
            {this.statusSelectList(manuData)}
            {!this.state.statusList && (
              <MenuItem disabled value={-1}>{this.state.noDataText}</MenuItem>
            )}
          </Select>
        </SelectField>
      </Grid>
    )
  }

  commonAssignData() {
    return (
      <Grid item md={3} lg={3} sm={4} xs={6} >
        <Typography style={webStyle.DataLabel}>
          {this.state.assigntoLabel}
        </Typography>
        {
          this.state?.queryData?.assigned_to?.length && this.state.queryData.assigned_to.length > 0 && this.state.queryData.assigned_to.map((item: any, index) => {
            return (
              <Typography style={webStyle.DataValue} key={item}>
                {item}
                {this.state.queryData != null && index !== this.state.queryData?.assigned_to.length - 1 && <span>,</span>}
              </Typography>
            )
          })
        }
      </Grid>
    )
  }

  HistoryPopUp() {
    return (
      <QueryDialog
        open={this.state.isHistoryPopUp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid style={webStyle.dialogHeaderGrid}>
          <Typography style={webStyle.dialogTitle}>
            {this.state.ticketHistoryText} - {this.state.queryData?.ticket_number}
          </Typography>
          <IconBox onClick={this.showHistory}>
            <CloseIcon style={webStyle.closeIcon}></CloseIcon>
          </IconBox>
        </Grid>
        <DialogContent style={webStyle.dialogContentPadding} >
          <Timeline>
            <CustomTimelineItem>
              <TimelineSeparator>
                <TimelineDot />
                {(this.state.all_histories.length === 0) ? null : <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                  {this.ticketCreateHistory()}
              </TimelineContent>
            </CustomTimelineItem>
            {
              this.state.all_histories.map((data, index) => {
                return (
                  <>
                    {data.reply_created_at && (
                      <CustomTimelineItem key={data.reply_created_at}>
                        <TimelineSeparator>
                          <TimelineDot />
                          {(index + 1) !== this.state.all_histories.length ? <TimelineConnector /> : null}
                        </TimelineSeparator>
                        <TimelineContent>
                          <Typography style={webStyle.ticketHeader}>
                            <span style={webStyle.ticketCreatedBy}>{data.reply_comment_message}</span>
                          </Typography>
                          <Typography style={webStyle.ticketCreatedDate}>
                            {data.reply_created_at}
                          </Typography>
                          <HistoryBox>
                            {data.reply_comment}
                          </HistoryBox>
                        </TimelineContent>
                      </CustomTimelineItem>)
                    }
                    {data.grant_created_at && (
                      <CustomTimelineItem key={data.grant_created_at}>
                        <TimelineSeparator>
                          <TimelineDot />
                          {(index + 1) !== this.state.all_histories.length ? <TimelineConnector /> : null}
                        </TimelineSeparator>
                        <TimelineContent>
                          <Typography style={webStyle.ticketHeader}>
                            <span style={webStyle.ticketCreatedBy}>{data.grant_comment_message} </span>
                          </Typography>
                          <Typography style={webStyle.ticketCreatedDate}>
                            {data.grant_created_at}
                          </Typography>
                          {data.grant_comment !== "" && <HistoryBox>
                            {data.grant_comment}
                          </HistoryBox>}
                        </TimelineContent>
                      </CustomTimelineItem>)
                    }

                    {data.transfer_created_at && data.transfer_comment_message
                      && (
                        <>{this.tranferredTicketHistory(data, index)}</>
                        )
                    }

                    {data.transfer_created_at && (data.transferred_team_name || data.transferred_emp_name) && (
                      <>
                      {this.transferredTicketHistoryTeam(data, index)}
                      </>
                    )
                    }
                  </>
                )
              })
            }
          </Timeline>
        </DialogContent>
      </QueryDialog >
    );
  };

  DataUserName = (data: any) => {
    return (
      data.user_name ? data.user_name : ""
    )
  }

  CommentsPopUp() {
    return (
      <QueryDialog
        open={this.state.isCommentsPopUp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid style={webStyle.dialogHeaderGrid}>
          <Typography style={webStyle.dialogTitle}>
            {this.state.commentHistoryText} - {this.state.queryData?.ticket_number}
          </Typography>
          <IconBox onClick={() => this.showComments()}>
            <CloseIcon style={webStyle.closeIcon}>
            </CloseIcon>
          </IconBox>
        </Grid>
        <DialogContent style={webStyle.dialogContentPadding}>
          {
            this.state.all_comments.map((data) => {
              return (
                <Grid key={data.user_name} style={webStyle.empNameGrid}>
                  <Typography align="left" style={webStyle.inlineNameStyle}>
                    {data.user_name ? <div style={webStyle.profilePic}>{data.user_name.charAt(0)}</div> : <img src={Logos} style={webStyle.avatarImg} />}
                    <span style={webStyle.bioConEmp}>
                      {data.employee_name ? data.employee_name : this.userNameText(data)}
                    </span>
                    <span style={webStyle.commentDateTime}>{data.reply_time}</span>
                  </Typography>
                  <Grid style={webStyle.attachmentGrid}>
                    <div>
                      {
                        data.attachment !== undefined && (
                          data.attachment.map((data: any) => {
                            return (
                              <div style={webStyle.documentMain} key={data.file_id}>
                                <AttachFileIcon
                                  style={webStyle.attachmentIcon}
                                  className="attachment_icon"
                                >
                                </AttachFileIcon>
                                <Typography style={webStyle.documentName} onClick={() => this.downloadAttachmentFile(data)}>
                                  {data.file_name}
                                </Typography>
                              </div>
                            )
                          })
                        )
                      }
                    </div>
                  </Grid>
                  <Typography style={webStyle.commentText}>
                    {data.reply}
                  </Typography>
                </Grid>
              )
            })
          }
        </DialogContent>
      </QueryDialog>
    );
  };

  StatusCXList = () => {
    return (
      this.state.isCustomerExcellence === this.state.CustomerExcellenceConditionText ? this.state.statusList.map(grantAccessDuplicateCode) : this.state.statusList.slice(0, 2).map(grantAccessDuplicateCode)
    )
  }
  
  userNameText = (data: any) => {
    return (
      data.user_name ? data.user_name : ""
    )
  }

  grantAccess() {
    const { classes } = this.props
    return (
      <>
        <Grid item md={12} lg={12} sm={12} style={webStyle.grantAccessGrid}>
          <InputLabel
            htmlFor={this.state.statusText}
            style={webStyle.inputLabel}
          >
            {this.state.changeStatusText}
          </InputLabel>
          <SelectField>
            <Select
              labelId={this.state.statusText}
              displayEmpty
              id={this.state.statusText}
              variant="outlined"
              fullWidth
              style={webStyle.select}
              defaultValue={0}
              value={this.state.statusId}
              onChange={this.handleGrantAccessStatusChange}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                getContentAnchorEl: null
              }}
            >
              <MenuItem disabled value={0} className={classes.selectPlaceholder}>
                <em style={webStyle.selectPlaceHolder}>{this.state.selectStatusText}</em>
              </MenuItem>
              {
                this.state.statusList && (this.state.queryData?.query_type === this.state.sampleRequestConditionText ?
                  this.StatusCXList() : this.state.statusList.map(grantAccessDuplicateCode))
              }
              {!this.state.statusList && (
                <MenuItem disabled value={-1}>{this.state.noDataText}</MenuItem>
              )}
            </Select>
          </SelectField>
        </Grid>
        <Grid item md={12} lg={12} sm={12} style={webStyle.paddingStyle}>
          <InputLabel
            htmlFor={this.state.commentFieldLabel}
            style={webStyle.inputLabel}
          >
            {this.state.replyCustomerLabel}
          </InputLabel>
          <TextArea
            id={this.state.commentFieldLabel}
            multiline
            placeholder={this.state.commentPlaceholder}
            rows={3}
            variant="outlined"
            value={this.state.grantAccessComment}
            onChange={this.handleChange("grantAccessComment").bind(this)}
            style={webStyle.fullWidthStyle}
          />
        </Grid>
        {this.state.additionalFiles || this.state.documentSelect ?
          <Grid container spacing={2} justifyContent="space-between" style={webStyle.searchFolderGrid}>
            <Grid item lg={12} xs={12} container>
              <Typography style={webStyle.headerName} className={classes.headerName} >{this.state.fromPage}</Typography>
              <ArrowRightIcon />
              <Typography style={webStyle.headerName} className={classes.headerName}>{this.state.toPage}</Typography>
              {
                !this.state.selectedProductBucket && !this.state.commonFolder && !this.state.invoices && (
                  <Grid item lg={6} md={6} sm={6} xs={10} style={webStyle.searchInputGrid}>
                    <InputField
                      id={this.state.searchFolderText}
                      variant="outlined"
                      onChange={this.handleSeachDocument}
                      placeholder={this.state.searchDocPlaceholder}
                      value={this.state.searchDocValue}
                      fullWidth
                      InputProps={{
                        startAdornment:
                          <InputAdornment position="start">
                            <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                            >
                              <SearchIcon style={webStyle.searchIcon}></SearchIcon>
                            </IconButton>
                          </InputAdornment>
                      }}
                    />
                  </Grid>
                )
              }
              <ArrowBackIcon className="floatRight" style={webStyle.backIcon} onClick={this.goBack}></ArrowBackIcon>
            </Grid>
          </Grid> : ""}
        <>
          <Grid container spacing={3} justifyContent="center">
            <Grid item sm={6} md={6}>
              <Buttons
                variant="outlined"
                style={{...webStyle.fullWidthStyle, ...webStyle.documentListButtons,color: `${this.state.documentBtnActive ? '#fff' : ''}`,backgroundColor: `${this.state.documentBtnActive ? '#043c74' : ''}`,  }}
                className={classes.documentButtonTabs}
                onClick={this.setSelectDocuments}>
                <FolderOpenIcon style={webStyle.folderOpenIcon} /> {this.state.documentSelectionText}
              </Buttons>
            </Grid>
            <Grid item sm={6} md={6}>
              <Buttons
                variant="outlined"
                style={{...webStyle.fullWidthStyle, ...webStyle.documentListButtons, backgroundColor: `${!this.state.documentBtnActive ? '#043c74' : ''}`, color: `${!this.state.documentBtnActive ? '#fff' : ''}`}}
                className={classes.documentButtonTabs}
                onClick={this.setAdditionalFiles.bind(this)}
              >
                <FolderOpenIcon style={webStyle.folderOpenIcon} /> {this.state.additionalFilesText}
              </Buttons>
            </Grid>
          </Grid>
          {
            this.state.additionalFiles ? (
              this.documentSelection()
            ) :
              (
                this.additionalFiles()
              )
          }
          <Grid container spacing={2} justifyContent="center" style={webStyle.grantAccessButtonGrid}>
            <Grid item>
              <Buttons variant="outlined" onClick={this.closeActionPopUp} className={classes.documentButtonTabs}>
                {this.state.cancleButtonText}
              </Buttons>
            </Grid>
            <Grid item>
              <Buttons
                variant="outlined" onClick={this.SubmitGrantAccess}
                className={classes.documentButtonTabs}
                disabled={this.state.istiketClosed}
              >
                {this.state.submitButtonText}
              </Buttons>
            </Grid>
          </Grid>
        </>
      </>
    )
  };

  additionalFiles() {
    const { classes } = this.props;
    return (
      <>
        {this.state.documentSelect && !this.state.selectedProductBucket &&
          <Grid item container spacing={2} style={webStyle.folderListGrid} className={classes.mainCard}>
            {this.state.productBucketListing && this.state.productBucketListing.length > 0 ? this.state.productBucketListing.map((data: any) => {
                return (
                  <>
                    {data.bucket_id && (
                        this.productBucketList(data)
                      )
                    }
                  </>
                )
              }) : <p>{this.state.noBucketsFoundText}</p>
            }
          </Grid>
        }
        {
          this.state.selectedProductBucket && <CustomBox className={classes.documentsList} style={webStyle.noPadding}>
            {this.selectedProductBuckets()}
            {this.DocumentViewCheckBoxList()}
            {this.NoBucketDisplay()}
          </CustomBox>
        }
      </>
    )
  }

  productBucketList = (data: any) => {
    return (
      <Grid item lg={6} md={6} sm={6} xs={6}
        key={data.bucket_id}
        onClick={() => this.proceedForBucketList(data)}
        container alignItems="center" justifyContent="space-between" wrap="nowrap"
        className={data?.name?.toLowerCase() !== 'dmf' ? 'selectPointer' : ''}
      >
        <>
          <Typography style={webStyle.inlineNameStyle}>
            <FolderOpenIcon style={data?.files_present ? webStyle.storageIcon : webStyle.storageIcon2}></FolderOpenIcon>
            <span style={data?.files_present ? webStyle.folderName : webStyle.folderName2}>{data.name}</span>
          </Typography>
          {!data?.files_present && (
            <ToolTips title={this.state.documentAccessTioolTip} enterTouchDelay={0} arrow placement="top">
              <ErrorIcon style={webStyle.errorIcon}></ErrorIcon>
            </ToolTips>
          )}
        </>
      </Grid>
    )
  }

  selectedProductBuckets = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={8} style={webStyle.selectedProductBucketsGrid} className="right_text_align">
          <CheckBoxLabel
            style={webStyle.CheckBoxLabel2}
            control={
            <Checkbox
              disableRipple
              checked={this.handleCheckctAllViews()}
              checkedIcon={<span style={(webStyle.checkedIcon)} />}
              onChange={this.handleSelectAllViews}
              icon={<span style={webStyle.icon} />}
              inputProps={{ 'aria-label': 'decorative checkbox' }}
              style={webStyle.viewAllCheckBox}
            />
          }
            label={this.state.isViewAllText}
        />
        </Grid>
      <Grid item xs={4} className="right_text_align"></Grid>
    </Grid>
    )
  }

  DocumentViewCheckBoxList = () => {
    return (
      this.state.productBucketFileListing.map((data: IProductBucketFileListing, index: number) => {
        return (
          <Grid container spacing={2} key={data.file_id}>
            <Grid item xs={7} style={webStyle.listWrapper}>
              <Typography style={webStyle.fileName} onClick={() => this.onDocPreview(data.doc_file)}>
                {data.file_name}
              </Typography>
              </Grid>
              <Grid item xs={2}>
              <CheckBoxLabel
                control={
                  <Checkbox
                    disableRipple
                    checked={data.grant_access}
                    checkedIcon={<span style={(webStyle.checkedIcon)} />}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleProductBucketFileSelectionGrantAccess(index, e)}
                    icon={<span style={webStyle.icon} />}
                    inputProps={{ 'aria-label': 'decorative checkbox' }}
                    style={webStyle.viewCheckBox}
                  />
                }
                label={this.state.ViewText}
              />
              </Grid>
            <Grid item xs={3}>
              <CheckBoxLabel
                control={
                  <Checkbox
                    disableRipple
                    checked={data.download_file}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleProductBucketFileSelectionDownloadPermission(index, e)}
                    checkedIcon={<span style={webStyle.checkedIcon} />}
                    icon={<span style={webStyle.icon} />}
                    inputProps={{ 'aria-label': 'decorative checkbox' }}
                    style={webStyle.checkBoxColor}
                  />
                }
                label={this.state.downloadText}
              />
            </Grid>
          </Grid>
        )
      })
    )
  }

  NoBucketDisplay = () => {
    return (
      this.state.productBucketFileListing.length === 0 && (
        <Grid container alignItems="center" justifyContent="center">
          <Typography style={webStyle.noDocs}>{this.state.noDocumentsText}</Typography>
        </Grid>
      )
    )
  }

  documentSelection() {
    const { classes } = this.props
    return (
      <>
        {!this.state.commonFolder && !this.state.invoices ? (
          <Grid container spacing={3} justifyContent="center">
            <Grid item lg={6} md={6} sm={6} xs={6} onClick={this.setCommonFolder} container className="selectPointer">
              <> <FolderOpenIcon style={webStyle.storageIcon}></FolderOpenIcon>
                <Typography style={webStyle.folderName}>{this.state.commonFolderText}</Typography>
              </>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6} onClick={this.setInvoiceList} container className="selectPointer">
              <> <FolderOpenIcon style={webStyle.storageIcon}></FolderOpenIcon>
                <Typography style={webStyle.folderName}>{this.state.invoiceText}</Typography>
              </>
            </Grid>
          </Grid>
        ) : null}
        {/* this will show common folders listing */}
        {this.state.commonFolder && !this.state.selectedCommonFolder && (
          <Grid container spacing={2} style={webStyle.folderListGrid} className={classes.mainCard}>
            {this.state.commonFolderList && this.state.commonFolderList.map((data: any) => {
              return (
                <Grid item lg={6} md={6} sm={6} xs={6} key={data.id}
                  onClick={() => this.handleCommonFolderClick(data)}
                  container className={data.name.toLowerCase() !== 'dmf' ? 'selectPointer' : ''}>
                  <>
                    <FolderOpenIcon style={webStyle.storageIcon} className={data.name.toLowerCase() !== 'dmf' ? 'selectPointer' : ''}></FolderOpenIcon>
                    <Typography style={webStyle.folderName}>{data.name}</Typography>
                  </>
                </Grid>
              )
            })
            }
          </Grid>
        )}
        {/* this will show common folders File listing */}
        {this.state.selectedCommonFolder && (
          <CustomBox className={classes.documentsList} style={webStyle.noPadding}>
            {this.state.selectedCommonFolderFiles.map((data: ICommonFolderFile, index: number) => {
              return (
                <Grid container spacing={2} key={data.doc_file}>
                  <Grid item xs={4}>
                    <Typography style={webStyle.fileName} onClick={() => this.onDocPreview(data.doc_file)}> {data.file_name}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <CheckBoxLabel style={webStyle.grantAccess}
                      control={
                        <Checkbox
                          color="default"
                          disableRipple
                          checked={data.grant_access}
                          checkedIcon={<span style={(webStyle.checkedIcon)} />}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleCommonFileSelectionGrantAccess(index, e)}
                          icon={<span style={webStyle.icon} />}
                          inputProps={{ 'aria-label': 'decorative checkbox' }}
                          style={webStyle.checkBoxColor}
                        />
                      }
                      label={this.state.ViewText}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CheckBoxLabel
                      control={
                        <Checkbox
                          color="default"
                          disableRipple
                          checked={data.download_file}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleCommonFileSelectionDownloadPermission(index, e)}
                          checkedIcon={<span style={(webStyle.checkedIcon)} />}
                          icon={<span style={webStyle.icon} />}
                          inputProps={{ 'aria-label': 'decorative checkbox' }}
                          style={webStyle.checkBoxColor}
                        />
                      }
                      label={this.state.downloadText}
                    />
                  </Grid>
                </Grid>
              )
            })
            }
          </CustomBox>
        )}
        {/* this will show invoices folder listing */}
        {this.state.invoices && !this.state.selectedInvoiceFolder && (
          <Grid container spacing={2} style={webStyle.folderListGrid} className={classes.mainCard} >
            {this.state.invoicesList && this.state.invoicesList.map((data: any) => {
              return (
                <Grid item lg={6} md={6} sm={6} xs={6} key={data.name} container className="selectPointer" onClick={() => this.handleInvoiceFolderClick(data)}>
                  <FolderOpenIcon style={webStyle.storageIcon}></FolderOpenIcon>
                  <Typography style={webStyle.folderName}>{data.name || ''}</Typography>
                </Grid>
              )
            })}
          </Grid>
        )}
        {/* this will show Invoice folders File listing */}
        {this.state.selectedInvoiceFolder && (
          <CustomBox className={classes.documentsList} style={webStyle.noPadding}>
            {this.state.selectedInvoiceFolderFiles.map((data: IInvoiceFolderFile, index: number) => {
              return (
                <Grid container spacing={2} key={data.file_id}>
                  <Grid item xs={4}>
                    <Typography style={webStyle.fileName} onClick={() => this.onDocPreview(data.doc_file)}>
                      {data.file_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <CheckBoxLabel
                      control={
                        <Checkbox
                          color="default"
                          disableRipple
                          checked={data.grant_access}
                          checkedIcon={<span style={(webStyle.checkedIcon)} />}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleInvoiceFileSelectionGrantAccess(index, e)}
                          icon={<span style={webStyle.icon} />}
                          inputProps={{ 'aria-label': 'decorative checkbox' }}
                          style={webStyle.checkBoxColor}
                        />
                      }
                      label={this.state.ViewText}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CheckBoxLabel
                      control={
                        <Checkbox
                          color="default"
                          disableRipple
                          checked={data.download_file}
                          checkedIcon={<span style={(webStyle.checkedIcon)} />}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleInvoiceFileSelectionDownloadPermission(index, e)}
                          icon={<span style={webStyle.icon} />}
                          inputProps={{ 'aria-label': 'decorative checkbox' }}
                          style={webStyle.checkBoxColor}
                        />
                      }
                      label={this.state.downloadText}
                    />
                  </Grid>
                </Grid>
              )
            })
            }
          </CustomBox>
        )}
      </>
    )
  };

  transferQuery() {
    const { classes } = this.props;
    return (
      <>
        <form data-testid='submitTransferQuery' onSubmit={(e) => { e.preventDefault(); this.submitTransferQuery() }}>
          <Grid item md={12} lg={12} sm={12}>
            <InputLabel
              htmlFor={this.state.phoneNumText}
              style={webStyle.inputLabel}
            >
              {this.state.transferQueryToTeamText} <span style={webStyle.errorText}>*</span>
            </InputLabel>
            <SelectField>
              <Select
                labelId={this.state.phoneNumText}
                displayEmpty
                id={this.state.phoneNumText}
                variant="outlined"
                fullWidth
                style={webStyle.select}
                defaultValue={0}
                value={this.state.selectedEmployeeToTransfer}
                onChange={this.handleRoleChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
                disabled={this.state.istiketClosed}
              >
                <MenuItem value={0} className={classes.selectPlaceholder}>
                  <em style={webStyle.selectPlaceHolder}>{this.state.selectTransferQueryToText}</em>
                </MenuItem>
                {
                  this.state.allRoles && this.state.allRoles.map((data: IEmployeeData, index: number) => {
                    return (
                      <MenuItem key={data.id} value={data.id} style={webStyle.menuItem}>{data.name}</MenuItem>
                    )
                  })
                }
                {this.state.allRoles && this.state.allRoles.length === 0 && (
                  <MenuItem value={-1}>{this.state.noDataText}</MenuItem>
                )}
              </Select>
            </SelectField>
          </Grid>
          <Grid item container justifyContent="flex-end">
            {
              this.state.isRequiredTeamTransfer ?
                <Typography style={webStyle.mandatoryErrorText}>{this.state.mandatoryFieldText}</Typography>:''
            }
          </Grid>
          <Grid item md={12} lg={12} sm={12} style={webStyle.paddingStyle}>
            <InputLabel
              htmlFor={this.state.phoneNumText}
              style={webStyle.inputLabel}
            >
              {this.state.teamMemberNameText} <span style={webStyle.errorText}>*</span>
            </InputLabel>
            <SelectField>
              <Select
                labelId={this.state.phoneNumText}
                displayEmpty
                id={this.state.phoneNumText}
                variant="outlined"
                fullWidth
                style={webStyle.select}
                defaultValue={0}
                value={this.state.selectedteamMember}
                onChange={this.handleTeamMemberChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
                disabled={this.state.istiketClosed}
              >
                <MenuItem value={0} className={classes.selectPlaceholder}>
                  <em style={webStyle.selectPlaceHolder}>{this.state.selectText}</em>
                </MenuItem>
                {
                  this.state.teamMembers && this.state.teamMembers.map((data: IEmployeeData, index: number) => {
                    return (
                      <MenuItem key={data.id} value={data.id} style={webStyle.menuItem}>{data.name}</MenuItem>
                    )
                  })
                }
                {
                  this.state.teamMembers.length !== 0 && (
                    <MenuItem key={'All'} value={'All'} style={webStyle.menuItem}>
                      {this.state.allText}
                    </MenuItem>
                  )
                }
                {this.state.teamMembers && this.state.teamMembers.length === 0 && (
                  <MenuItem value={-1} disabled>{this.state.noDataText}</MenuItem>
                )}
              </Select>
            </SelectField>
          </Grid>
          <Grid item container justifyContent="flex-end">
            {this.state.isRequiredMemberTransfer ?
              <Typography style={webStyle.mandatoryErrorText}>{this.state.mandatoryText}</Typography> : ''}
          </Grid>

          <Grid item md={12} lg={12} sm={12} style={webStyle.paddingStyle}>
            <InputLabel
              htmlFor={this.state.internalCommentsLabel}
              style={webStyle.inputLabel}
            >
              {this.state.internalCommentsLabel}
            </InputLabel>
            <TextArea
              id={this.state.internalCommentsLabel}
              multiline
              placeholder={this.state.internalCommentsPlaceholder}
              rows={4}
              variant="outlined"
              value={this.state.transferTheQueryComment}
              onChange={this.handleChange("transferTheQueryComment").bind(this)}
              style={webStyle.fullWidthStyle}
              disabled={this.state.istiketClosed}
            />
          </Grid>
          {this.commonStatusId(transferQueryDuplicateCode)}
          {
            this.state.queryData?.reply_to_customer && (
              <Grid item md={12} lg={12} sm={12} style={webStyle.paddingStyle}>
                <InputLabel
                  htmlFor={this.state.commentFieldLabel}
                  style={webStyle.inputLabel}
                >
                  {this.state.replyCustomerLabel}
                </InputLabel>
                <TextArea
                  id={this.state.commentFieldLabel}
                  multiline
                  placeholder={this.state.commentPlaceholder}
                  rows={3}
                  variant="outlined"
                  value={this.state.customerReply}
                  onChange={this.handleChange("customerReply").bind(this)}
                  style={webStyle.fullWidthStyle}
                  disabled={this.state.istiketClosed}
                />
              </Grid>
            )
          }
          <Grid item container justifyContent="flex-end">
            {
              this.state.isReplyToCusomerTrasferQuery ?
                <Typography style={webStyle.mandatoryErrorText}>{this.state.mandatoryFieldText}</Typography> : ''
            }
          </Grid>
          <Grid item container justifyContent="flex-end">
            {
              this.state.isReplyToCusomerTrasferQueryNumSc ?
                <Typography style={webStyle.mandatoryErrorText}>{this.state.replyRules}</Typography> : ''
            }
          </Grid>
          <Grid item md={12} lg={12} sm={12} style={webStyle.paddingStyle}>
            <InputLabel
              htmlFor={this.state.statusText}
              style={webStyle.inputLabel}
            >
              {this.state.pendingWithLabel}
            </InputLabel>
            <SelectField>
              <Select
                labelId={this.state.statusText}
                displayEmpty
                id={this.state.statusText}
                variant="outlined"
                fullWidth
                style={webStyle.select}
                defaultValue={0}
                value={this.state.pendingWith}
                onChange={this.handlePendingWithChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
                disabled={this.state.istiketClosed}
              >
                <MenuItem value={0} className={classes.selectPlaceholder}>
                  <em style={webStyle.selectPlaceHolder}>{this.state.selectText}</em>
                </MenuItem>
                {
                  this.state.allRoles && this.state.allRoles.map((data: any, index: number) => {
                    return (
                      <MenuItem key={data.id} value={data.id} style={webStyle.menuItem}>{data.name}</MenuItem>
                    )
                  })
                }
                {this.state.allRoles && this.state.allRoles.length === 0 && (
                  <MenuItem value={-1}>{this.state.noDataText}</MenuItem>
                )}
              </Select>
            </SelectField>
          </Grid>
          <Grid container spacing={2} justifyContent="center" style={webStyle.paddingStyle}>
            <Grid item>
              <Buttons onClick={() => this.clearPopUp()} variant="outlined">
                {this.state.cancleButtonText}
              </Buttons>
            </Grid>
            <Grid item>
              <Buttons
                type="submit" variant="contained"
                disabled={this.state.istiketClosed}
              >
                {this.state.submitButtonText}
              </Buttons>
            </Grid>
          </Grid>
        </form>
      </>
    )
  }

  replyToCustomer() {
    return (
      <>
        <form onSubmit={(e) => {
          e.preventDefault();
          this.submitReplyToCustomer();
        }}>
          {
            this.state.queryData?.reply_to_customer && (
              <Grid item md={12} lg={12} sm={12}>
                <InputLabel
                  htmlFor={this.state.RemarksLabel}
                  style={webStyle.inputLabel}
                >
                  {this.state.replyCustomerLabel} <span style={webStyle.errorText}>*</span>
                </InputLabel>
                <TextArea
                  id={this.state.replyToCustomerText}
                  multiline
                  placeholder={this.state.replyCustomerLabel}
                  rows={4}
                  value={this.state.customerReply}
                  onChange={this.handleChange("customerReply").bind(this)}
                  variant="outlined"
                  style={webStyle.fullWidthStyle}
                />
              </Grid>
            )
          }

          <Grid item container justifyContent="flex-end">
            {
              this.state.isReplyToCusomer && this.state.customerReply === '' ?
                <Typography style={webStyle.mandatoryErrorText}>{this.state.mandatoryFieldText}</Typography> : ''
            }
          </Grid>
          <Grid item container justifyContent="flex-end">
            {
              this.state.isReplyToCusomerNumSc ?
                <Typography style={webStyle.mandatoryErrorText}>{this.state.replyRules}</Typography> : ''
            }
          </Grid>
          {!this.state.queryData?.is_transferred_ticket && <Grid container style={webStyle.attachMentGridMargin}>
            <Grid item lg={12} xs={12} style={webStyle.attachmentPadding}>
              <Typography component="label" style={webStyle.attachmentTextStyle}>
                <AttachFileIcon
                  fontSize="small"
                  style={webStyle.addAttachmentIcon}
                >
                </AttachFileIcon>
                <span style={webStyle.attachmentLabel}>{this.state.AttachmentsText}</span>
                <input
                  type="file"
                  hidden
                  accept="image/*,.pdf,.doc,.docx,.dot,.docm"
                  multiple={true}
                  onChange={this.handleDocumentUpload}
                />
              </Typography>
            </Grid>
            <ul style={{...webStyle.fullWidthStyle, ...webStyle.filesList }}>
              <Grid container spacing={2}>
                {this.state.uploadedFiles.map((doc: any) => {
                  return (
                    <Grid container alignItems="center" justifyContent="space-between" key={doc.name} item spacing={2} md={6} lg={6} sm={6} style={webStyle.attachmentDocStyle}>
                      <li key={doc.name} style={webStyle.docName}>
                        <Grid container alignItems="center" className="text_white_space">
                          <a href={doc.name} style={webStyle.docNameStyle} download className="text_overflow_wrap">{doc.name}</a>
                        </Grid>
                      </li>
                      <DeleteIcon
                        data-testid='DeleteIcon'
                        style={webStyle.deleteIcon}
                        onClick={() => this.removeFile(doc.name)}>
                      </DeleteIcon>
                    </Grid>
                  )
                })}
              </Grid>
            </ul>
          </Grid>}
          {this.commonStatusId(replyToCustomerDuplicateCode)}
          <Grid item md={12} lg={12} sm={12} style={webStyle.paddingStyle}>
            <InputLabel
              htmlFor={this.state.commentFieldLabel}
              style={webStyle.inputLabel}
            >
              {this.state.internalCommentsLabel}
            </InputLabel>
            <TextArea
              id={this.state.commentFieldLabel}
              multiline
              placeholder={this.state.internalCommentPlaceholder}
              rows={3}
              variant="outlined"
              value={this.state.replyToCutsomerCommet}
              onChange={this.handleChange("replyToCutsomerCommet").bind(this)}
              style={webStyle.fullWidthStyle}
            />
          </Grid>
          <Grid container spacing={2} justifyContent="center" style={webStyle.buttonsGrid}>
            <Grid item>
              <Buttons onClick={this.setActionPopUp} variant="outlined">
                {this.state.cancleButtonText}
              </Buttons>
            </Grid>
            <Grid item>
              <Buttons
                type="submit" variant="contained">
                {this.state.submitButtonText}
              </Buttons>
            </Grid>
          </Grid>
        </form>
      </>
    )
  }

  ActionPopUp() {
    const { classes } = this.props
    return (
      <QueryDialog
        open={this.state.isactionPopUp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid>
          <CancelOutlinedIcon
            data-testid='CancelOutlinedIcon'
            className="cancelIcon"
            onClick={() => this.clearPopUp()}
          />
        </Grid>
        <DialogContent className="contentPadding">
          <Typography style={webStyle.ActionPopUpText} className={classes.takeActionLabel}>
            {this.state.takeActionText}
          </Typography>
          <CustomTabs
            onChange={this.handleChangeTab}
            value={this.state.selectedTab}
            aria-label={this.state.customTabsLabelText}
            className="tabsWrapper"
            style={webStyle.TabWrapper}
          >
            {this.state.queryData && this.state.queryData?.query_type === this.state.ProductQueryConditionText && <CustomTab
              label={this.state.GrantAccessText}
              style={webStyle.Tab1}
              disabled={this.state.istiketClosed}
            />}
            {
              this.state.queryData?.reply_to_customer ?
                (
                  <CustomTab
                    label={this.state.TransferTheQueryText}
                    disableRipple
                    disabled={this.state.istiketClosed}
                    style={this.state.queryData && this.state.queryData?.query_type !== this.state.ProductQueryConditionText ? webStyle.Tab1 : {}}
                  />
                ) : (
                  <CustomTab
                    label={this.state.TransferTheQueryText}
                    disableRipple
                    disabled={this.state.istiketClosed}
                    style={this.state.queryData && this.state.queryData?.query_type !== this.state.ProductQueryConditionText ? webStyle.Tab2 : webStyle.Tab4}
                  />
                )
            }
            {this.state.queryData?.reply_to_customer && (
              <CustomTab
                label={this.state.ReplyToCustomerTabsText}
                style={webStyle.tab3}
                disableRipple
                disabled={this.state.istiketClosed}
              />
            )}
          </CustomTabs>
          {this.state.queryData && this.state.queryData?.query_type === this.state.ProductQueryConditionText &&
            <TabPanel value={this.state.selectedTab} index={0}>
              {this.grantAccess()}
            </TabPanel>}
          <TabPanel value={this.state.selectedTab} index={this.state.queryData && this.state.queryData?.query_type === this.state.ProductQueryConditionText ? 1 : 0}>
            {this.transferQuery()}
          </TabPanel>
          <TabPanel value={this.state.selectedTab} index={this.state.queryData && this.state.queryData?.query_type === this.state.ProductQueryConditionText ? 2 : 1}>
            {this.replyToCustomer()}
          </TabPanel>
        </DialogContent>
      </QueryDialog >
    );
  };

  ChemicalNameToolTip() {
    return (
      <CustomBox>
        <Grid>
          <CancelTwoToneIcon
            data-testid='CancelTwoToneIcon'
            className="cancelTwoToneIcon"
            onClick={(e: any) => this.handleToolTip(e, "")}
            >
          </CancelTwoToneIcon>
        </Grid>
        <Grid item lg={12}>
          <Typography style={webStyle.chemicalToolTip}>
            {this.state.popUPText}
          </Typography>
        </Grid>
      </CustomBox>
    )
  };

  ActionBustonForCXUser = () => {
    const { classes } = this.props
    return (
      this.state.isCustomerExcellence === this.state.CustomerExcellenceConditionText ?
        <ActionButtons
          onClick={this.setActionPopUp}
          className={this.state.queryData?.query_type !== this.state.signupRequestConditionText ? classes.actionButton : classes.signupactionButton}
        >
          {this.state.actionButtonText}
        </ActionButtons>
        :
        null
    )
  }

  ActionButtonForTransferredTicket = () => {
    const { classes } = this.props
    return (
      this.state.queryData?.is_transferred_ticket ?
        (this.state.isCustomerExcellence === this.state.CustomerExcellenceConditionText || this.state.isUserPerformAction) && 
        (<ActionButtons
          onClick={this.setActionPopUp}
          className={this.state.queryData?.query_type !== this.state.signupRequestConditionText ? classes.actionButton : classes.signupactionButton}
        >
          {this.state.actionButtonText}
        </ActionButtons>)
        :
        <ActionButtons
        onClick={this.setActionPopUp}
        className={this.state.queryData?.query_type !== this.state.signupRequestConditionText ? classes.actionButton : classes.signupactionButton}
      >
        {this.state.actionButtonText}
      </ActionButtons>
    )
  }

  actionButtonConditions = () => {
    return (
      localStorage.getItem("current_transferrent_ticket") === "true" ?
        this.ActionBustonForCXUser()
        :
        this.ActionButtonForTransferredTicket()
    )
  }

  productMultipleName = () => {
    const { classes } = this.props
    return (
      this.state.queryData?.product_name?.length > 1 ?
        <Grid item md={12} lg={12} sm={10} xs={12} style={webStyle.productNamePadding}>
          <Typography style={webStyle.DataLabel}>
            {this.state.productNameText}
          </Typography>
        {this.state.queryData?.product_name.map((data: any,index: number) => {
          return (
            <Typography style={webStyle.DataValue} key={data.products}>
              {data.products}
              {this.state.queryData?.product_name.length - 1 !== index && (
                <span>,</span>
              )
            }
            </Typography>
          )
        })
      }
    </Grid>
   : 
   <Grid item md={12} lg={12} sm={10} xs={12} className={classes.imgSpacingRes}>
      <img src={MainImage} style={webStyle.productImage} />
      <ProductName style={webStyle.proImageName}>
      <Typography style={webStyle.proImageName}>
          {this.state.queryData?.query_type === this.state.ConnectWithUsText ?
            this.state.queryData?.product_name[0].products
          :
          this.state.queryData?.product_name
          }
        </Typography>
      </ProductName>
      </Grid>
    )
  }

  HeaderDetails = () => {
    const { classes } = this.props
    return (
      <Grid container>
        <Grid item xl={7} lg={8} md={7} sm={7} container justifyContent="space-between">
          <Grid container>
            <NavLink to={`/TicketList`}>
              <KeyboardBackspaceIcon
                style={webStyle.cardButtonBack}
                fontSize="medium"
              />
            </NavLink>
            <Typography style={webStyle.QueryTitle}>{this.state.Heading}</Typography>
            <StatusChips style={this.state.queryData?.status_id === 0 ? webStyle.cancelStatus : this.StausColorCondition()}>
              {this.state.queryData?.status}
            </StatusChips>
          </Grid>
          <div>
            <Grid style={webStyle.closedMessage}>{this.state.closed_message?.slice(0, 45)}</Grid>
          </div>
          </Grid>
          <Grid item xl={5} md={5} lg={4} sm={5} xs={12} container justifyContent="flex-end" className={classes.mainBtnTab}>
            {this.state.queryData?.query_type !== this.state.signupRequestConditionText ?
              <ActionButtons onClick={this.handleTicketDetailsClick} className={classes.ticketDetail}>
                {this.state.ticketDetailsButtonText}
              </ActionButtons>
              :
              <NavLink
                activeClassName="active"
                aria-current="page"
                exact={true}
                to={`/CustomerContact/edit/${this.state.signUpcustomerMasterId}`}
              >
              <ActionButtons
                onClick={() => localStorage.setItem("FromTicket", "signUpRequest")}
                className={classes.ticketDetail}>
                {this.state.ticketDetailsButtonText}
              </ActionButtons>
            </NavLink>
          }
          {this.state.queryData?.status === this.state.ClosedStatusText ? 
            this.ActionButtonForClosed() : <>
          {(!this.state.queryIsTransferred) && this.actionButtonConditions()}</>}
        </Grid>
      </Grid>
    )
  }

  ActionButtonForClosed = () => {
    const { classes } = this.props
    return (
      this.state.queryData?.query_type !== this.state.signupRequestConditionText && !this.state.queryData?.is_reopened && !this.state.is_reopened && (
      <ActionButtons
        data-testid="resetButton"
        onClick={() => this.reOpenTicket()}
        className={classes.ticketDetail}>
        {this.state.reOpenButtonText}
      </ActionButtons>)
    )
  }

  StausColorCondition = () => {
    return (
      this.state.queryData?.status_id === 2 ? webStyle.openStatus : webStyle.progressStatus
    )
  }

  productNameImage = () => {
    const { classes } = this.props
    return (
      <Grid item lg={3} md={3}>
        {this.state.queryData?.query_type !== this.state.ConnectWithUsText ?
        <Grid item md={12} lg={12} sm={10} xs={12} className={classes.imgSpacingRes}>
          <img src={MainImage} style={webStyle.productImage} />
          <ProductName style={webStyle.proImageName}>
            <Typography style={webStyle.proImageName}>
              {this.state.queryData?.product_name}
            </Typography>
          </ProductName>
        </Grid>
        :
        this.productMultipleName()
      }
      </Grid>
    )
  }

  DetailsRow1 = () => {
    return (
      <Grid container spacing={2} item md={9} lg={9} sm={12} xs={12} >
        {this.state.queryData?.query_type === this.state.signupRequestConditionText &&
          <React.Fragment>
            <Grid item md={3} lg={4} sm={6} xs={12}>
              <Typography style={webStyle.DataLabel}>
                {this.state.ticketIdLabel}
              </Typography>
              <Typography style={webStyle.DataValue}>
                {this.state.queryData?.ticket_number}
              </Typography>
            </Grid>
            <Grid item md={3} lg={4} sm={6} xs={12}>
              <Typography style={webStyle.DataLabel}>
                {this.state.queryTypeLabel}
              </Typography>
              <Typography style={webStyle.DataValue}>
                {this.state.queryData?.query_type}
              </Typography>
            </Grid>
            {this.commonAssignData()}
          </React.Fragment>
        }
        {this.state.queryData?.query_type !== this.state.signupRequestConditionText && 
          this.QueryDataRow2()
        }
      </Grid>
    )
  }

  QueryDataRow2 = () => {
    return (
      <React.Fragment>
        <Grid item md={4} lg={4} sm={6} xs={6} style={webStyle.mainGridPadding}>
          <Typography style={webStyle.DataLabel}>
            {this.state.ticketIdLabel}
          </Typography>
          <Typography style={webStyle.DataValue}>
            {this.state.queryData?.ticket_number}
          </Typography>
        </Grid>
        <Grid item md={4} lg={4} sm={4} xs={6} >
          <Typography style={webStyle.DataLabel}>
            {this.state.queryTypeLabel}
          </Typography>
          <Typography style={webStyle.DataValue}>
            {this.state.queryData?.query_type}
    </Typography>
        </Grid>
      {this.commonAssignData()}
      {this.state?.queryData?.query_type === this.state.ProductQueryConditionText &&
        <Grid item md={3} lg={4} sm={4} xs={6} >
          <Typography style={webStyle.DataLabel}>
            {this.state.docTypeLabel}
          </Typography>
          {this.docTypeDetails()}
        </Grid>}
        {this.productQueryData()}
       {this.submmittedByDetails()}
      </React.Fragment>
    )
  }

  productQueryData = () => {
    return (
      <>
      <Grid item md={4} lg={4} sm={4} xs={6} style={webStyle.mainGridPadding}>
        <Typography style={webStyle.DataLabel}>
            {this.state.pendingWithLabel}
          </Typography>
          <Typography style={webStyle.DataValue}>
            {this.state.queryData?.transfer_query_response?.data !== null ?
              this.state.queryData?.transfer_query_response?.data?.attributes?.pending_with
                : <span style={webStyle.noDataDash}>- -</span>
            }
          </Typography>
        </Grid>
        <Grid item md={4} lg={4} sm={6} xs={6} style={webStyle.mainGridPadding}>
          <Typography style={webStyle.DataLabel}>
            {this.state.requestRaisedOnText}
          </Typography>
          <Typography style={webStyle.DataValue}>
            {this.state.queryData !== null ?
              this.state.queryData?.ticket_creation_date
              : <span style={webStyle.noDataDash}>- -</span>
            }
          </Typography>
        </Grid>
      </>
    )
  }

  submmittedByDetails = () => {
    const { classes } = this.props
    return (
      <Grid item md={3} lg={3} xs={4}>
      <Typography style={webStyle.SubmittedBy} className={classes.submittedByLabel} >
        {this.state.queryData?.is_biocon_employee === true ? <span>{this.state.submmittedByTeamText}</span> : this.state.submittedByLabel}
      </Typography>
      {this.state.queryData?.submitted_by !== undefined && (this.state.queryData?.submitted_by?.filter((data) => data?.isContactMaster === true || data?.isContactMaster === false)).map((submitter) => {
        return (
          <CustomBox
            onClick={() => this.checkForSubmmittedBy(submitter)}
            key={`submitted-by-${submitter.masterId}`}
            style={webStyle.SubmittedName}
            className={submitter.masterId === null ? 'NonClickText' : ""}
          >
          <Typography component="span" style={webStyle.SubmittedName} className={submitter.masterId === null ? 'noUnderLineText' : "underlinedText"}>
            {(submitter.isContactMaster === true || submitter.isContactMaster === false) && submitter?.masterName}
          </Typography>
            {submitter.isContactMaster && ","}
          </CustomBox>
        )
      })}
    </Grid>
    )
  }

  docTypeDetails = () => {
    return (
      <CustomBox display='inline' >
        <Typography style={webStyle.DataValue} component="span">
          {this.state.queryData?.doc_type[0]?.name}
        </Typography>
        {this.state.queryData !== undefined && this.state.queryData?.query_type === this.state.ProductQueryConditionText && this.state.queryData?.doc_type.length > 1 && (
          <>
            <Typography
              data-testid='clickEvent'
              component={"span"}
              style={webStyle.dotVal}
              aria-owns={this.state.openToolTip ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onClick={(e: any) => this.handleToolTip(e, this.state.queryData?.doc_type.map(doc => doc.name).join(', '))}
              onMouseEnter={(e: any) => this.handleToolTip(e, this.state.queryData?.doc_type.map(doc => doc.name).join(', '))}
              onMouseLeave={(e: any) => this.handleToolTip(e, this.state.queryData?.doc_type.map(doc => doc.name).join(', '))}
            >...</Typography>
            <Typography
              data-testid='clickEventPass'
              component={"span"}
              style={webStyle.ReadMoreLabel}
              aria-owns={this.state.openToolTip ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onClick={(e: any) => this.handleToolTip(e, this.state.queryData?.doc_type.map(doc => doc.name).join(', '))}
              onMouseEnter={(e: any) => this.handleToolTip(e, this.state.queryData?.doc_type.map(doc => doc.name).join(', '))}
            >
              {this.state.readMoreText}
            </Typography>
          </>
        )}
      </CustomBox>
    )
  }

  attachmentDetails = () => {
    return (
      <Grid item lg={12} md={12} style={webStyle.attachmentTextPadding} xs={5}>
        <Typography style={webStyle.DetailDataLabel}>
          {this.state.AttachmentsText}
        </Typography>
        <Typography style={webStyle.SubmittedName}>
          {this.state.queryData?.attachment_files.map((attachment, index) => {
            return <Button onClick={() => this.downloadAttachmentFile(attachment)} key={`attachement${attachment.id}`} 
              style={{ ...webStyle.SubmittedName, ...webStyle.attachmentDownloadButton }}>
              {   attachment.file_name} {this.state.queryData?.attachment_files.length !== (index + 1) ? ", " : null}
                </Button>
              })}
        </Typography>
        {this.state.queryData?.attachment_files.length === 0 && (
          <span style={webStyle.noDataMiddleDash}>- -</span>
        )}
      </Grid>
    )
  }

  TooltipBox = () => {
    return (
      <ChemicalDetailPopover
        data-testid='ChemicalDetailPopover'
        id="mouse-over-popover"
        open={this.state.openToolTip}
        anchorEl={this.state.anchorEl}
        disableRestoreFocus
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={(e: any) => this.handleToolTip(e, '')}
      >
        {this.ChemicalNameToolTip()}
      </ChemicalDetailPopover>
    )
  }
  
  signupRequestSubmmittedBy = () => {
    const { classes } = this.props
    return (
      <Grid container spacing={2} style={webStyle.attachmentBox}>
        <Grid item md={8} lg={8} xs={6}>
          <Typography style={webStyle.DetailDataLabel} className={classes.submittedByLabel} >
            {this.state.submittedByLabel}
          </Typography>
          {this.state.queryData?.submitted_by?.map((submitter, index: number) => (
          <Button
                id="NonClickText"
                onClick={() => this.checkForSubmmittedBy(submitter)}
                key={`submitted-by-${submitter.masterId}`}
                style={webStyle.SubmittedName}
                className={submitter.masterId === null ? 'NonClickText' : ""}
            >
            <Typography component="span" style={webStyle.SubmittedName} className="underlinedText">
                {submitter.masterName}
            </Typography>
            {index + 1 !== this.state.queryData?.submitted_by?.length ? ", " : null}
          </Button>
        ))}
      </Grid>
    </Grid>
    )
  }

  customerComments = () => {
    return (
      <>
      <Grid item md={12} lg={12} sm={12} xs={12} style={webStyle.commentsGrid}>
        <Typography style={webStyle.DataLabel}>
          {this.state.customerCommentsText}
        </Typography>
        <Typography style={webStyle.DataValue}>
          {
            this.state.queryData?.customer_comments !== "" ?
              this.state.queryData?.customer_comments :
              <span style={webStyle.noComments}>- -</span>
          }
        </Typography>
        </Grid>
        {this.state.all_comments.length > 0 && (
          <Grid item md={12} lg={12} sm={12} xs={12} style={webStyle.commentsGridPadding}>
            <Typography
              data-testid='showComments'
              style={webStyle.viewAllCommentText}
              onClick={() => this.showComments()}
            >
              {this.state.viewCommentsHistoryText}
            </Typography>
          </Grid>
        )}
      </>
    )
  }

  apiAttachmentDetails =() => {
    return (
      <Grid container spacing={2} style={webStyle.attachmentBox}>
        <Grid item md={8} lg={8} xs={6}>
          <Typography style={webStyle.DataLabel}>
            {this.state.coaText}
          </Typography>
          <Typography style={webStyle.DataValue} data-testid='downloadAPIAttachmentFile' onClick={() => this.proceedToDownloadAPIDoc()}>
            {this.state.queryData?.api_attachment?.api_file_name !== "" && this.state.queryData?.api_attachment !== null ?
              <span style={webStyle.attachmentFileName}>
                {this.state.queryData?.api_attachment?.api_file_name}
              </span>
              : <span style={webStyle.noApiAttachment}>- -</span>
            }
          </Typography>
        </Grid>
      </Grid>
    )
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <AppHeader navigation={this.props.navigation} />
        <Loader loading={this.state.isLoading} data-testid="loading"/>
        {this.state.queryData &&
          <MainContainer className={this.state.queryData?.query_type === this.state.signupRequestConditionText ? "paddingClass" : ""}>
            {this.HeaderDetails()}
            <React.Fragment>
              <Grid container spacing={4}>
                {this.state.queryData?.query_type !== this.state.signupRequestConditionText && this.productNameImage()}
                {this.DetailsRow1()}
                {this.state.queryData?.query_type !== this.state.signupRequestConditionText &&this.customerComments()}
              </Grid>
            {
              this.state.queryData.query_type !== this.state.SetUpMeetingText && this.state.queryData.query_type !== this.state.signupRequestConditionText &&
              this.attachmentDetails()
            }
            {
              this.state.queryData.query_type !== this.state.signupRequestConditionText && (
                <Grid item md={12} lg={12} sm={12} xs={12} style={webStyle.mainGridPadding}>
                  <Typography
                    data-testid='showHistory'
                    style={webStyle.ticketHistory}
                    onClick={() => this.showHistory()}
                  >
                    {this.state.ticketHistoryText}
                  </Typography>
                </Grid>
              )
            }
            {this.state.queryData?.query_type === this.state.sampleRequestConditionText && (this.apiAttachmentDetails())}
            {this.state.queryData.query_type === this.state.signupRequestConditionText && (this.signupRequestSubmmittedBy())}
            </React.Fragment >
            {this.state.isactionPopUp && ( this.ActionPopUp() )}
            {this.TooltipBox()}
            {this.HistoryPopUp()}
            {this.CommentsPopUp()}
          </MainContainer>}
        {(this.state.queryData?.product_name) && (<AppFooter isLoading={this.state.isLoading}/>)}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  docName: {
    fontSize: '14px',
    color: 'rgba(4, 60, 116, 1)',
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    margin: '0px 5px 0px 10px',
    textDecoration: 'underline',
    width: '80%'
  },
  grantAccess: {
    alignItems: 'center'
  },
  headerName: {
    color: 'rgba(0, 0, 0, 0.85)',
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '14px',
    cursor: 'pointer'
  },
  cardButtonBack: {
    color: "#043c74",
    marginTop: '7px'
  },
  proImageName: {
    fontSize: '20px',
    color: 'white',
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    padding: '5px 10px',
  },
  QueryTitle: {
    fontSize: '20px',
    color: 'rgba(33, 43, 54, 1)',
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    padding: '5px 10px 20px 0px'
  },
  TabWrapper: {
    paddingLeft: '3px',
    paddingRight: '20px'
  },
  Tab1: {
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
  },
  Tab4: {
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
  },
  Tab2: {
    borderRadius: '8px',
  },
  tab3: {
    borderEndEndRadius: "8px",
    borderTopRightRadius: "8px",
  },
  productImage: {
    height: '80%',
    width: '80%',
    paddingRight: 5,
  },
  DataLabel: {
    fontWeight: 700,
    color: 'rgba(15, 72, 127, 1)',
    fontFamily: 'SFProDisplay',
    fontSize: '16px',
  },
  DetailDataLabel: {
    color: 'rgba(0, 73, 131, 1)',
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '16px',
    paddingTop: '10px',
  },
  SubmittedBy: {
    color: 'rgba(0, 73, 131, 1)',
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '16px',
    width: '200px'
  },
  DataValue: {
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 1)',
  },
  SubmittedName: {
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '14px',
    color: '#5A9CF0',
    paddingTop: '5px',
    cursor: 'pointer',
  },
  ActionPopUpText: {
    color: 'rgba(4, 60, 116, 1)',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '18px',
    margin: '0px 15px',
    paddingBottom: '10px'
  },
  select: {
    height: '35px',
    borderRadius: '8px',
    borderColor: 'rgba(4, 60, 116, 1)',
    color: '#6D84A9',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '14px',
    "& .MuiSelect-root": {
      borderColor: 'rgba(4, 60, 116, 1)',
    }
  },
  selectPlaceHolder: {
    fontStyle: 'normal',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '14px',
    color: 'rgba(218, 224, 241, 1)',
  },
  menuItem: {
    fontStyle: 'normal',
    fontFamily: 'SFProDisplay',
    fontWeight: 500,
    fontSize: '14px',
    color: 'rgba(4, 60, 116, 1)',
  },
  inputLabel: {
    fontSize: '14px',
    fontFamily: 'SFProDisplay',
    fontWeight: 500,
    color: 'rgba(4, 60, 116, 1)',
    paddingBottom: '10px',
  },
  checkedIcon: {
    borderRadius: 3,
    border: '1px solid rgba(4, 60, 116, 1)',
    width: 14,
    height: 14,
    backgroundColor: 'white',
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='rgba(4, 60, 116, 1)'/%3E%3C/svg%3E\")",
    'input:hover ~ &': {
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 3,
    width: 14,
    height: 14,
    backgroundColor: 'white',
    border: '1px solid black',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(4, 60, 116, 1)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: 'white',
    },
  },
  checkBoxLabel: {
    color: 'rgba(4, 60, 116, 1)',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '14px',
    paddingTop: '6px',
  },
  attachmentLabel: {
    color: 'rgba(4, 60, 116, 1)',
    fontFamily: 'SFProDisplay',
    fontWeight: 500,
    fontSize: '14px',
    paddingTop: '6px'
  },
  docTitle: {
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '16px',
    marginBottom: '5px',
  },
  listWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '0px',
  },
  fileName: {
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '14px',
    color: 'rgba(4, 60, 116, 1)',
    marginLeft: '10px',
    overflow: 'auto',
    marginTop: '6px',
    marginRight: '10px',
    cursor: 'pointer'
  },
  folderName: {
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '14px',
    padding: '0px 0px 5px 0px',
    cursor: 'hand',
    color: '#043c74',
    display: 'inline-block',
    paddingLeft: '5px',
  },
  folderName3: {
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '14px',
    padding: '0px 0px 5px 0px',
    cursor: 'hand',
    color: '#043c74',
    display: 'inline-block',
  },
  folderName2: {
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '14px',
    padding: '0px 0px 5px 0px',
    cursor: 'hand',
    color: 'grey',
    display: 'inline-block',
    paddingLeft: '5px',
  },
  storageIcon: {
    cursor: 'pointer',
    color: '#043c74',
    fontSize: '20px',
  },
  storageIcon2: {
    cursor: 'pointer',
    color: 'grey',
    fontSize: '20px',
  },
  remarkBox: {
    border: '1px solid black',
    borderRadius: '8px',
    padding: '6px 5px',
  },
  remarkText: {
    fontSize: '14px',
    color: 'rgb(4, 60, 116)',
    fontFamily: 'SFProDisplay',
    fontWeight: 500,
  },
  remarkDate: {
    fontSize: 'x-small',
    color: "#505050",
    fontFamily: 'SFProDisplay',
    fontWeight: 500,
  },
  noDocs: {
    fontSize: '14px',
    color: 'rgb(4, 60, 116)',
    fontFamily: 'SFProDisplay',
    fontWeight: 500,
  },
  chemicalToolTip: {
    color: 'rgba(4, 60, 116, 1)',
    fontWeight: 500,
    fontFamily: 'SFProDisplay',
    fontSize: '14px',
    padding: '12px 5px 10px 5px'
  },
  ReadMoreLabel: {
    fontWeight: 500,
    fontFamily: 'SFProDisplay',
    fontSize: '16px',
    color: 'rgba(0, 169, 255, 1)',
    padding: '0px 0px 0px 1px',
    textDecoration: 'none',
    marginLeft: '2px',
    "&:hover": {
      textDecoration: 'underline',
    },
    cursor: 'hand',
  },
  dotVal: {
    fontWeight: 700,
    fontFamily: 'SFProDisplay',
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.85)',
    padding: '0px 0px 0px 0px',
    marginLeft: 2
  },
  openStatus: {
    backgroundColor: '#EBF7F3',
    color: '#85C67A',
    border: '1px solid #EBF7F3',
  },
  progressStatus: {
    backgroundColor: '#FFF7E6',
    color: '#EBC74F',
    border: '1px solid #FFF7E6',
  },
  cancelStatus: {
    backgroundColor: '#F8ECEE',
    color: '#CE4436',
    border: '1px solid #F8ECEE',
  },
  viewAllCommentText: {
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: 1.5,
    letterSpacing: '-0.2px',
    color: '#459eff',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  ticketHistory: {
    width: 'max-content',
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: 1.6,
    color: '#004983',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  closedMessage: {
    display: 'flex',
    paddingTop: '10px',
    fontFamily: 'SFProDisplay',
    fontSize: '16px', 
    paddingBottom: '20px', 
    color: '#777777' 
  },
  dialogTitle: {
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: 1.33,
    color: '#043c74',
    margin: '32px 28px 9px 7px',
  },
  ticketHeader: {
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: 1.43,
    color: '#043c74',
    marginTop: '-15px'
  },
  ticketCreatedBy: {
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: 2,
    color: '#003d78',
  },
  ticketCreatedDate: {
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: 1.67,
    color: '#6a74a5',
    margin: '3px 0px 8px 0px',
  },
  CommentName: {
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: 1.43,
    color: '#043c74',
  },
  commentDateTime: {
    margin: '3px 24px 15px 19px',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: 2,
    color: '#6a74a5',
  },
  rightSideComment: {
    marginLeft: '50px'
  },
  leftSideComment: {
    marginRight: '50px'
  },
  avatarImg: {
    width: '24px',
    height: '24px',
    margin: '0px 12px 0px 7px',
    padding: '4px 6px 4px 7px',
    backgroundColor: '#f5f5fa',
    borderRadius: '25px',
  },
  bioConEmp: {
    margin: '2px 16px 14px 0px',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: 1.43,
    color: '#043c74',
  },
  commentText: {
    margin: '5px 0 6px 60px',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: 1.67,
    color: '#043c74',

  },
  profilePic: {
    borderRadius: "30px",
    width: '24px',
    height: '24px',
    margin: "0px 12px 0px 7px",
    padding: "4px 6px 4px 7px",
    fontSize: '20px',
    backgroundColor: "#f4f3f9",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  documentMain: {
    backgroundColor: "#f5f5fa",
    display: 'flex',
    alignItems: 'center',
    borderRadius: '6px',
    border: '1px solid #dae0f1',
    paddingRight: "15px",
    height: '30px',
    minWidth: '180px',
    width: 'fit-content'
  },
  attachmentIcon: {
    margin: '8px 5px',
    width: '20px',
    height: '20px',
    fill: '#043c74',
    cursor: 'pointer'
  },
  documentName: {
    fontFamily: 'SFProDisplay',
    fontSize: '14px',
    lineHeight: 1.88,
    letterSpacing: '-0.16px',
    color: '#719cdc',
    marginLeft: '5px',
    marginBottom: '2px',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  paddingStyle: {
    paddingTop: '15px'
  },
  dialogHeaderGrid: {
    padding: '0px 30px',
  },
  closeIcon: {
    fill: '#1A3B71',
    cursor: 'hand'
  },
  dialogContentPadding: {
    padding: '4px 24px 4px 24px'
  },
  attachmentGrid: {
    paddingLeft: '58px',
    width: 'auto',
    minWidth: '230px',
    marginBottom: '10px'
  },
  fullWidthStyle: {
    width: '100%'
  },
  empNameGrid: {
    paddingTop: '22px',
    paddingBottom: '5px'
  },
  inlineNameStyle: {
    display: 'flex',
  },
  grantAccessGrid: {
    marginTop: '-10px'
  },
  searchFolderGrid: {
    padding: '10px 0px 5px 0px',
    marginBottom: '-22px'
  },
  searchInputGrid: {
    paddingLeft: '15px',
    marginTop: '-10px'
  }, 
  searchIcon: {
    fill: 'rgb(4, 60, 116)',
    fontSize: '20px'
  },
  backIcon: {
    cursor: 'hand',
    fontSize: '20px',
    paddingLeft: '10px'
  },
  documentListButtons: {
    textAlign: 'left', 
    justifyContent: 'flex-start', 
    fontFamily: 'SFProDisplay', 
    fontSize: '14px'
  },
  folderOpenIcon: {
    marginRight: '8px'
  },
  grantAccessButtonGrid: {
    margin: '20px 0px 10px 0px',
    marginTop: '10px'
  },
  folderListGrid: {
    marginTop: '10px',
    justifyContent: 'flex-start',
    padding: '0px'
  },
  noPadding: {
    paddingBottom: '0px'
  },
  selectedProductBucketsGrid: {
    padding: '0px 0px 0px 18px',
    marginLeft: '18px',
  },
  CheckBoxLabel2: {
    marginRight: '-23px'
  },
  errorIcon: {
    fill: "orange", 
    padding: '0px 0px 0px 10px',
    fontSize: '16px', 
  },
  viewAllCheckBox: {
    color: '#000', 
    display: 'flex', 
    alignItems: 'center', 
    borderColor: '#6a74a5'
  },
  viewCheckBox: {
    color: '#6a74a5', 
    display: 'flex', 
    alignItems: 'center', 
    borderColor: '#6a74a5'
  },
  checkBoxColor: {
    color: '#6a74a5'
  },
  errorText: {
    color: "red" 
  },
  mandatoryErrorText: {
    color: 'red', 
    fontSize: '14px',
    fontWeight: 400, 
    fontFamily: 'SFProDisplay'
  },
  attachMentGridMargin: {
    margin: '10px 0px 10px 3px'
  },
  attachmentTextStyle: {
    textDecoration: 'underline',
    color: 'rgba(4, 60, 116, 1)'
  },
  addAttachmentIcon: {
    marginBottom: '-5px', 
    marginRight: '2px', 
    fill: "rgba(4, 60, 116, 1)", 
    marginLeft: '-6px'
  },
  filesList: {
    paddingLeft: '10px',
    marginBottom: '0px'
  },
  attachmentDocStyle: {
    padding: "10px 20px 10px 10px"
  },
  docNameStyle: {
    color: 'inherit', 
    marginTop: '1px', 
  },
  deleteIcon: {
    fill: '#ff4747', 
    fontSize: '1.3rem'
  },
  noDataDash: {
    paddingLeft: '40px', 
    marginLeft: '10px'
  },
  noDataMiddleDash: {
    paddingLeft: '40px', 
    marginLeft: '0px'
  },
  attachmentDownloadButton: {
    color: '#459eff', 
    padding: '0px', 
    textDecoration: 'underline'
  },
  attachmentFileName:  {
    textDecoration: 'underline', 
    color: 'rgb(90, 156, 240)', 
    cursor: 'pointer'
  },
  attachmentPadding: {
    paddingTop: '3px' 
  },
  buttonsGrid: {
    paddingBottom: '5px'
  },
  productNamePadding: {
    paddingTop: '10px'
  },
  mainGridPadding: {
    margin: '12px 0px'
  },
  commentsGrid: {
    margin: '12px 0px', 
    marginBottom: '0px'
  },
  noComments: {
    paddingLeft: '40px', 
    marginLeft: '30px'
  },
  commentsGridPadding: {
    margin: '0px 0px 24px 0px', 
    paddingTop: '0px'
  },
  attachmentBox: {
    paddingBottom: '45px'
  },
  attachmentTextPadding: {
    paddingBottom: '20px'
  },
  noApiAttachment: {
    paddingLeft: '10px', 
    marginLeft: '0px'
  }
};

const ChemicalDetailPopover: any = withStyles({
  paper: {
    color: 'rgba(4, 60, 116, 1)',
    fontWeight: 700,
    fontFamily: 'SFProDisplay',
    fontSize: '18px',
    backgroundColor: "rgba(255, 255, 255, 1)",
    boxShadow: "3px 4px 25px 2px lightGray",
    minWidth: 300,
    maxWidth: 300,
    borderRadius: 8,
    padding: '5px 10px',
    overflowX: "unset",
    overflowY: "unset",
    "&::before": {
      backgroundColor: "rgba(255, 255, 255, 1)",
      content: '""',
      display: "block",
      position: "absolute",
      width: 14,
      height: 14,
      top: -6,
      transform: "rotate(45deg)",
      left: "calc(50% - 10px)",
    },
    "@media (max-width: 980px)": {
      width: '68%',
    }
  }
})(Popover);

const TabPanel: any = withStyles({
  container: {
    padding: 24,
    "@media (max-width: 600px)": {
      padding: '24px 0px 24px 0px',
      overflowX: 'hidden'
    },
  },
})(DetailsTabPanel)

const MainContainer: any = withStyles({
  root: {
    padding: '20px 48px 93px 73px',
    "@media (max-width: 1260px)": {
      "& .ticketBtn": {
        marginLeft: `-1.5% !important`
      }
    },
    "@media (max-width: 600px)": {
      padding: '20px 20px 93px 20px'
    }
  }
})(Container);

const ActionButtons: any = withStyles({
  root: {
    background: 'rgba(4, 60, 116, 1)',
    height: '34px',
    width: '135px',
    color: 'rgba(255, 255, 255, 1)',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '15px',
    textTransform: 'none',
    borderRadius: '8px',
    marginLeft: '15px',
    marginBottom: '15px',
    "&:hover": {
      background: 'rgba(4, 60, 116, 1)',
    },
  }
})(Button);

const Buttons: any = withStyles({
  root: {
    height: '34px',
    width: 134,
    color: 'rgba(255, 255, 255, 1)',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '14px',
    textTransform: 'none',
    boxShadow: 'none',
    margin: '10px 0px 0px 0px',
    borderRadius: '8px',
    "&.MuiButton-contained": {
      backgroundColor: 'rgba(0, 61, 120, 1)',
      "&:hover": {
        backgroundColor: 'rgba(0, 61, 120, 1)',
      },
      "&.Mui-disabled": {
        backgroundColor: `rgba(0, 0, 0, 0.12) !important`,
      }
    },
    "&.MuiButton-outlined": {
      border: '1px solid rgba(0, 61, 120, 1)',
      color: 'rgba(0, 61, 120, 1)',
      padding: '5px 15px',
      "&:hover": {
        backgroundColor: 'transparent',
      }
    },
    "&:hover": {
      boxShadow: 'none',
    },
    "@media (max-width: 500px)": {
      width: '100%',
    }
  },
})(Button);

const QueryDialog: any = withStyles({
  root: {
    "& .cancelIcon" : {
      cursor: 'hand', 
      float: 'right', 
      marginTop: '10px'
    },
    "& .contentPadding": {
      padding: '4px 0px 4px 0px'
    },
    "& .MuiDialog-paperScrollPaper": {
      minWidth: '580px',
      padding: '0px 10px 0px 10px',
      borderRadius: '15px',
      "@media (max-width: 600px)": {
        minWidth: '300px',
      }
    },
    "& .MuiDialogContent-root": {
      paddingLeft: '2px'
    },
    "& .MuiDialogActions-root": {
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px',
      paddingBottom: '8px',
    },
    "& .WithStyles(ForwardRef(Button))-root-10": {
      "@media (maxWidth: 600px)": {
        width: 300
      }
    }
  }
})(Dialog);

const CustomTab: any = withStyles({
  root: {
    border: "1px solid rgba(4, 60, 116, 1)",
    width: "auto",
    color: '#A0A1AB',
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    fontSize: "14px",
    textTransform: "none",
    "& .PrivateTabIndicator-colorSecondary-4": {
      display: "none",
    },
    "@media (max-width: 900px)": {
      width: "33%",
      fontSize: "14px",
    },
  },
})(Tab);

const CustomTabs: any = withStyles({
  root: {
    maxHeight: "30px",
    marginLeft: "20px",
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .MuiTab-root": {
      minHeight: '30px',
    },
    "& .Mui-selected": {
      background: "rgba(225, 239, 255, 1)",
      color: 'rgba(0, 61, 120, 1)',
    },
    "@media (max-width: 600px)": {
      maxHeight: "80px",
      marginLeft: "0px",
    },
  },
})(Tabs);

const TextArea: any = withStyles({
  root: {
    background: "inherit",
    height: 'auto',
    margin: '5px 0px 5px 0px',
    "& ::placeholder": {
      color: 'rgba(218, 224, 241, 1)',
      fontFamily: 'SFProDisplay',
      fontWeight: 500,
      fontSize: '14px',
      opacity: 1,
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: "SFProDisplay",
      fontWeight: 500,
      backgroundColor: "rgba(255, 255, 255, 1)",
      fontSize: "14px",
      color: '#1F436D',
      '&.Mui-focused fieldset': {
        borderColor: "rgba(4, 60, 116, 1)",
        borderWidth: 1,
      },
      '&.Mui-hovered fieldset': {
        borderColor: "rgba(4, 60, 116, 1)",
        borderWidth: 1,
      },
      "& :hover": {
        borderColor: "rgba(4, 60, 116, 1)",
        borderWidth: 1,
      },
      "& fieldset": {
        fontSize: "16px",
        borderColor: "rgba(4, 60, 116, 1)",
        borderRadius: "8px",
        borderWidth: 1,
      },
    },
  }
})(TextField);

const CheckBoxLabel: any = withStyles({
  root: {
    alignItems: 'center',
    marginRight: '0px',
    "& .MuiTypography-body1": {
      fontFamily: 'SFProDisplay',
      fontWeight: 400,
      fontSize: '14px',
      color: 'rgba(58, 63, 99, 1)',
    },
  },
})(FormControlLabel);

const ProductName: any = withStyles({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '75%',
    width: '70%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
})(Grid);

const SelectField: any = withStyles({
  root: {
    width: '100%',
    "& .MuiInputBase-input": {
      fontFamily: "SFProDisplay",
      fontWeight: 500,
      backgroundColor: "rgba(255, 255, 255, 1)",
      fontSize: "14px",
      color: '#1F436D',
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: "SFProDisplay",
      fontWeight: 500,
      backgroundColor: "rgba(255, 255, 255, 1)",
      fontSize: "14px",
      color: '#1F436D',
    },
    "& .MuiOutlinedInput-input": {
      padding: '11px 14px'
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(4, 60, 116, 1)",
      borderWidth: 1,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(4, 60, 116, 1)",
      borderWidth: 1,
    },
    "& .MuiSelect-icon": {
      fill: "rgba(4, 60, 116, 1)",
    },
  }
})(FormControl)

const styles: any = () => ({
  mainCard: {
    padding: '0px 50px',
    "@media (max-width: 600px)": {
      padding: "0px !important"
    }
  },
  actionButton: {
    "@media (max-width: 600px)": {
      width: "140px !important",
    },
  },
  signupactionButton: {
    float: "right",
    "@media (max-width: 980px)": {
      width: "140px !important",
    },
    "@media (max-width: 600px)": {
      width: "140px !important",
      position: "inherit !important",
      margin: "-70px 150px 100px 55px !important"
    },
  },
  ticketDetail: {
    "@media (max-width: 600px)": {
      width: "140px !important"
    }
  },
  mainBtnTab: {
    paddingRight: 0,
    height: 50,
    "@media (max-width: 600px)": {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row-reverse'
    }
  },
  productDescription: {
    "@media (max-width: 600px)": {
      marginTop: "20px !important",
      paddingLeft: "8px !important"
    },
    "@media (max-width: 1280px)": {
      paddingLeft: "8px !important"
    }
  },
  productName: {
    "@media (max-width: 600px)": {
      paddingLeft: "8px !important"
    },
    "@media (min-width: 1280px)": {
      paddingLeft: "20px !important"
    }
  },
  submittedByLabel: {
    "@media (max-width: 600px)": {
      paddingTop: "0px !important"
    }
  },
  selectPlaceholder: {
    backgroundColor: '#fff !important',
    '&.Mui-disabled': {
      opacity: 1
    }
  },
  documentButtonTabs: {
    '&:hover': {
      backgroundColor: '#043c74 !important',
      color: '#ffffff !important'
    },
  },
  takeActionLabel: {
    "@media (max-width: 600px)": {
      margin: "0px !important"
    }
  },
  headerName: {
    "@media (max-width: 600px)": {
      fontSize: "14px !important"
    }
  },
  documentsList: {
    paddingTop: 30,
    "@media (max-width: 600px)": {
      paddingLeft: "0px !important",
      paddingRight: "0px !important"
    }
  },
  imgSpacingRes: {
    position: 'relative', 
    textAlign: 'center', 
    color: 'white', 
    marginBottom: '10px', 
    marginLeft: '-10%',
    "@media (max-width: 967px)": {
      marginLeft: '-16px !important'
    }
  }
})

const StatusChips: any = withStyles({
  root: {
    height: '30px',
    width: 'auto',
    borderRadius: '35px',
    padding: '0px 20px',
    background: '#ea6b1a',
    marginTop: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'SFProDisplay',
    fontWeight: 500,
    fontSize: '14px'

  }
})(Box);

const InputField: any = withStyles({
  root: {
    background: "inherite",
    margin: "5px 0px 0px 0px",
    "& ::placeholder": {
      color: 'rgba(218, 224, 241, 1)',
      fontFamily: 'SFProDisplay',
      fontWeight: 400,
      fontSize: '14px',
      opacity: 1,
    },
    "& .MuiIconButton-root": {
      padding: '0px',
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: "SFProDisplay",
      fontWeight: 400,
      backgroundColor: "rgba(255, 255, 255, 1)",
      fontSize: "14px",
      "& fieldset": {
        height: '35px',
        borderColor: "rgba(218, 224, 241, 1)",
        borderRadius: "8px",
        borderWidth: 1,
      },
      ".Mui-focused": {
        borderColor: "rgba(218, 224, 241, 1)",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(214, 214, 214, 1)",
    },
    "& .MuiInputBase-input": {
      height: '15px',
    },
    "& .MuiOutlinedInput-input": {
      padding: `9.2px 14px !important`,
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px !important",
    },
    "& .MuiInputBase-input.Mui-disabled": {
      background: "#F1F4FA",
      borderRadius: "8px",
      height: 13,
      color: 'rgba(106, 116, 165, 1)',
      fontSize: '14px',
      fontFamily: 'SFProDisplay',
      fontWeight: 400,
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(218, 224, 241, 1)",
      borderWidth: '1px'
    }
  },
})(TextField);

const ToolTips: any = withStyles({
  tooltip: {
    color: 'rgba(255, 191, 0, 1)',
    fontWeight: 400,
    fontFamily: 'SFProDisplay',
    fontSize: '14px',
    backgroundColor: "rgba(255, 255, 255, 1)",
    boxShadow: "3px 4px 25px 2px lightGray",
    "& .MuiTooltip-arrow": {
      color: "rgba(255, 255, 255, 1)",
    }
  }
})(Tooltip);

const IconBox: any = withStyles({
  root: {
    position: 'absolute',
    right: '15px',
    top: '30px',
    background: '#DAE0EC',
    height: '22px',
    width: '22px',
    borderRadius: '50%',
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 0px 0px 0px',
    marginTop: '-19px',
    "& .MuiSvgIcon-root": {
      height: '16px',
      width: '16px'
    },
  }
})(Box);

const CustomTimelineItem: any = withStyles({
  root: {
    "&.MuiTimelineItem-missingOppositeContent:before": {
      display: 'none'
    },
    "& .MuiTimelineDot-defaultGrey": {
      background: '#043c74'
    },
    "& .MuiTimelineDot-root": {
      padding: '0px',
      height: '10px',
      width: '10px',
      boxShadow: 'none',
      margin: '0px',
    },
    "& .MuiTimelineConnector-root": {
      backgroundColor: '#dae0f1',
      maxHeight: '394px',
    },
    "& .MuiTimelineContent-root": {
      marginBottom: '27px'
    }
  }
})(TimelineItem)

const HistoryBox: any = withStyles({
  root: {
    padding: '8px',
    borderRadius: '4px',
    border: 'solid 1px #dae0f1',
    backgroundColor: '#f5f5fa',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: 1.67,
    color: '#043c74',
  }
})(Box);

const CustomBox: any = withStyles({
  root: {
    "& .cancelTwoToneIcon": {
      fill: "rgba(4, 60, 116, 1)", 
      float: 'right'
    }
  }
})(Box);

export default withStyles(styles)(QueryDetail)

// Customizable Area End
