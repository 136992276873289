import React from "react";

import {
    withStyles,
} from "@material-ui/core/styles";
import { SearchContainer , InputField, CustomTabs, CustomTab } from "../../../components/src/common";
import {
    Grid,
    Box,
    Container,
    InputAdornment,
    IconButton,
    TableRow,
    TableHead,
    TableCell,
    TableSortLabel,
    TableBody,
    Table,
    Button,
    Typography,
    TableContainer,
    Menu,
    MenuItem,
    FormGroup,
    FormControlLabel,
    Checkbox,
    DialogTitle,
    DialogContent,
    InputLabel,
    Dialog,
    Select,
    FormControl,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import AppHeader from "../../../components/src/EmployeeAppHeader.web";
import AppFooter from "../../../components/src/AppFooter.web";
import ArrowDropDownSharpIcon from '@material-ui/icons/ArrowDropDownSharp';
import ArrowDropUpSharpIcon from '@material-ui/icons/ArrowDropUpSharp';
import AdminConsole3Controller, {
    ITicketData,
    Props,
} from "./TicketListController";
import CustomPagination from "../../../components/src/CustomPagination.web";
import { NavLink } from "react-router-dom";
import Loader from "../../../components/src/Loader.web";
import { DownloadBox } from "./MasterContact.web";
import DownloadIcon from '@material-ui/icons/GetApp';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { CSVLink } from 'react-csv';
import DateRangTicketList from "./DateRangTicketList.web";
import { FilterIcon, SyncIcon } from "./assets";
import CachedIcon from '@material-ui/icons/Cached';

const ActionButtons : any = withStyles({
    root: {
        background: '#e1efff',
        height: '30px',
        width: '165px',
        color: '#043c74',
        fontFamily: 'SFProDisplay',
        fontWeight: 500,
        fontSize: '13px',
        textTransform: 'none',
        borderRadius: '8px',
        border: '1px solid #043c74',
        "&:hover": {
            background: '#e1efff',
        },
    }
})(Button);

const PER_PAGE = 9;

export class TicketList extends AdminConsole3Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    HeadCellWithFilter = (open: boolean, openStatu: boolean, headCell: any) => {
        return (
        headCell?.label.toString() === "Query Type" ? (
            <div>
                <Typography
                    className="selectPointer"
                    style={{ ...webStyle.headerText, textAlign: headCell?.numeric, ...webStyle.headerCenter }}
                    onClick={(e: any) => this.filterOptions(e, headCell?.label)}
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    data-testid="Querytypefilterbtn"

                >
                    {headCell?.label}
                    {
                        this.state.selectedQueryType.length > 0 ?
                            <img src={FilterIcon} height={18} width={16}
                                style={webStyle.filterIcon} />
                            :
                            <ArrowDropDownSharpIcon></ArrowDropDownSharpIcon>
                    }
                </Typography>
                <Menu
                    id="basic-menu"
                    open={open}
                    anchorEl={this.state.filterMenu}
                    onClose={this.handleMenuClose}
                    getContentAnchorEl={null}
                    MenuListProps={{
                        "aria-labelledby": "basic-button"
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}>
                    <Grid container justifyContent="flex-end" item >
                        <Button variant="outlined" className="knowMoreButton" style={webStyle.buttonStyle} onClick={this.handleAllClearCheckBox} data-testid="filterclearbtn">Clear</Button>
                    </Grid>
                    {this.state.filterData.map((filterDataItem: any) => {
                        return (
                            <FormGroup key={filterDataItem.id}>
                                <MenuItem
                                    style={webStyle.menuItem}
                                >
                                    <FormControlLabel
                                        data-testid="checkboxform"
                                        control={
                                            <Checkbox style={webStyle.checkBoxStyle}
                                                data-testid="checkbox"
                                                value={filterDataItem.label}
                                                checked={this.state.selectedQueryType.includes(filterDataItem.label)} onChange={(event) => { this.handleCheckBoxChange(event) }} />
                                        }
                                        label={filterDataItem.label}
                                    />
                                </MenuItem>
                            </FormGroup>
                        )
                    })
                    }
                    <Grid container justifyContent="space-between" item style={webStyle.clearBtnGrid}>
                        <Grid item>
                            <Button variant="outlined" className="knowMoreButton" style={webStyle.CancelButtonStyle} onClick={this.handleCancleQuerytypeFilter}>{this.state.cancelBtnText}</Button>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" className="knowMoreButton" style={webStyle.saveButtonStyle} onClick={this.handleCheckboxQuerySave} data-testid="QueryfilterSavebtn">{this.state.saveBtnText}</Button>
                        </Grid>
                    </Grid>
                </Menu>
            </div>
        ) : (
            <>
                <Typography
                    className="selectPointer"
                    style={{ ...webStyle.headerText, textAlign: headCell?.numeric , ...webStyle.headerCenter}}
                    onClick={(e: any) => this.filterStatusOptions(e, headCell?.label)}
                    id="basic-button-status"
                    aria-controls={openStatu ? "status-basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openStatu ? "true" : undefined}
                    data-testid="Statusfilterbtn"
                >
                    {headCell?.label}
                    {
                        this.state.selectedStatus.length > 0 ?
                            <img src={FilterIcon} height={18} width={16}
                                style={webStyle.filterIcon} />
                            :
                            <ArrowDropDownSharpIcon></ArrowDropDownSharpIcon>
                    }
                </Typography>
                <Menu
                    id="status-basic-menu"
                    anchorEl={this.state.filterStatusMenu}
                    open={openStatu}
                    onClose={this.handleMenuClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button-status"
                    }}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Grid container justifyContent="flex-end" item>
                        <Button variant="outlined" className="knowMoreButton" style={webStyle.clearButtonStyle} onClick={this.handleAllClearStatus}>Clear</Button>
                    </Grid>
                    {this.state.filterStatusData.map((filterDataItem: any) => {
                        return (
                            <FormGroup key={filterDataItem.id}>
                                <MenuItem
                                    key={filterDataItem.id}
                                    id={filterDataItem.label}
                                    style={webStyle.menuItemStyle}
                                >
                                    <FormControlLabel
                                        data-testid="checkboxform"
                                        control={
                                            <Checkbox style={webStyle.formCheckBoxStyle}
                                                data-testid="checkbox"
                                                value={filterDataItem.label}
                                                checked={this.state.selectedStatus.includes(filterDataItem.label)} onChange={(event) => { this.handleStatusCheckBoxChange(event) }} />
                                        }
                                        label={filterDataItem.label}
                                    />
                                </MenuItem>
                            </FormGroup>
                        )
                    })
                    }
                    <Grid container justifyContent="space-between" item style={webStyle.clearBtnGrid}>
                        <Grid item>
                            <Button variant="outlined" className="knowMoreButton" style={webStyle.CancelButtonStyle} onClick={this.handleCancleStatusFilter}>{this.state.cancelBtnText}</Button>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" className="knowMoreButton" style={webStyle.saveButtonStyle} onClick={this.handleCheckboxQuerySave} data-testid="QueryfilterSavebtn">{this.state.saveBtnText}</Button>
                        </Grid>
                    </Grid>
                </Menu>
            </>
        )
        )
    }

    TicketIdHeader = (headCell: any) => {
        return (
            headCell?.label.toString() === "Ticket ID" ?
                <Grid container>
                    <Typography style={{ ...webStyle.headerText, textAlign: headCell?.numeric }}>{headCell?.label}</Typography>
                    <CachedIcon onClick={() => this.handleTicketRefresh()} data-testid="CacheIcon" style={webStyle.cachedIcon}/>
                </Grid>
                :
                <Typography style={{ ...webStyle.headerText, textAlign: headCell?.numeric }}>
                    {headCell?.label}
                </Typography>
        )
    }

    headerCellWithFilterCondition = (headCell: any, open: boolean, openStatu: boolean) => {
        return (
            headCell.isFiltering ? (
                <>
                    {this.HeadCellWithFilter(open,openStatu, headCell)}
                </>
            ) : this.TicketIdHeader(headCell)   
        )
    }

    ProductNameDisplay = (row: any) => {
        return (
            row.attributes.product_name ? row.attributes.product_name : <div style={webStyle.productPadding}>-</div>
        )
    }

    statusConditions = (row: any) => {
        return (
            row.attributes.status === "Closed" ? webStyle.Open : webStyle.inProgress
        )
    }

    ListTabsContainer = () => {
        const { classes } = this.props;
        return (
            <Grid container style={webStyle.dateCountSpace} className={classes.dateCountSpaceRes}>
            <CustomTabs
                onChange={this.handleChangeTab}
                value={this.state.selectedTab}
                aria-label={this.state.tabsName}
            >
                <Grid style={webStyle.Tab2} onClick={(e) => this.handleChangeTab(e, 0)} data-testid="ticketassignmetab">
                    <CustomTab
                        value={0}
                        label={<CustomBox display='flex' flexDirection='row' alignItems='center' justifyContent='center' >
                                <p style={webStyle.CellPadding}>
                                    {this.state.ticketsAssignedText}
                                </p>
                                {this.state.TicketListingMeta?.pagination_details && !this.state.isFilteredTicketListing && <CountBox>{this.state.ticket_assigned_count}</CountBox>}
                            </CustomBox>
                        }
                        style={this.state.selectedTab === 0 ? { ...webStyle.Tab1, position: 'relative' } : webStyle.tab3}
                        disableRipple
                    />
                </Grid>
                <Grid style={webStyle.activeTransferredTickets} onClick={(e) => this.handleChangeTab(e, 1)} data-testid="transferredtickettab">
                    <CustomTab
                        value={1}
                        label={<CustomBox display='flex' flexDirection='row' alignItems='center' justifyContent='center' >
                                <p style={webStyle.CellPadding}>
                                    {this.state.transferredTicketsText}
                                </p>
                                {this.state.isFilteredTicketListing && <CountBox> {this.state.ticket_assigned_count}</CountBox>}
                            </CustomBox>

                        }
                        style={this.state.selectedTab === 1 ? { ...webStyle.Tab1, position: 'relative' } : webStyle.tab3}
                        disableRipple
                    />
                </Grid>
            </CustomTabs>
            <Grid item lg={6} md={5} sm={8}>
                <Alert severity="warning" className={classes.overdueAlertRes} style={webStyle.alertBox}>
                    <Grid item xs={12} style={webStyle.noMarginTop}>
                        <span style={webStyle.overdueCount}>{this.state.overDueCountMessage && this.state.overDueCountMessage.split(".")[0] + ". "}</span>
                    </Grid>
                    <Grid item xs={6} style={webStyle.overdueTicketsLink}>
                        <NavLink to={'/OverdueTickets'}>
                            <ActionButtons className={classes.actionButton}>
                                {this.state.resolveTicketsText}
                            </ActionButtons>
                        </NavLink>
                    </Grid>
                </Alert>
            </Grid>
            <Grid item className={classes.dateRangeRes}>
                <DateRangTicketList
                    setSelectedDate={this.setSelectedDate}
                    startDate={this.state.startdate}
                    endDate={this.state.endDate}
                    isFilterSelected={this.state.filterSelected}
                />
            </Grid>
        </Grid>
        )
    }

    SearchFieldContainer = () => {
        return (
            <SearchContainer container spacing={3}>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                    <InputField
                        id="ticketId"
                        data-testid='ticketIdsearch'
                        variant="outlined"
                        value={this.state.ticketId}
                        onChange={this.handleChangeSearchVlaue("ticketId").bind(this)}
                        required
                        placeholder={this.state.searchTicketIDPlaceholder}
                        fullWidth
                        InputProps={{
                            startAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={this.setEnableField}
                                        edge="end"
                                    >
                                        <SearchIcon style={webStyle.searchIconStyle}></SearchIcon>
                                    </IconButton>
                                </InputAdornment>,
                            endAdornment: this.state.ticketId && (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        edge="end"
                                        onClick={this.handleChangeSearchVlaue("ticketId").bind(this)}
                                    >
                                    <CloseIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                            <InputField
                                id="productName"
                                data-testid="productNameSearch"
                                variant="outlined"
                                value={this.state.productName}
                                onChange={this.handleChangeSearchVlaue("productName").bind(this)}
                                required
                                placeholder={this.state.searchProductPlaceholder}
                                fullWidth
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.setEnableField}
                                                edge="end"
                                            >
                                                <SearchIcon style={webStyle.searchIconStyle}></SearchIcon>
                                            </IconButton>
                                        </InputAdornment>,
                                    endAdornment: this.state.productName && (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                edge="end"
                                                onClick={this.handleChangeSearchVlaue("productName").bind(this)}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={12}>
                            <InputField
                                id="customerName"
                                data-testid="contactNameSearch"
                                variant="outlined"
                                value={this.state.customerName}
                                onChange={this.handleChangeSearchVlaue("customerName").bind(this)}
                                required
                                placeholder={this.state.searchPlaceholder}
                                fullWidth
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.setEnableField}
                                                edge="end"
                                            >
                                                <SearchIcon style={webStyle.searchIconStyle}></SearchIcon>
                                            </IconButton>
                                        </InputAdornment>,
                                    endAdornment: this.state.customerName && (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                edge="end"
                                                onClick={this.handleChangeSearchVlaue("customerName").bind(this)}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    </SearchContainer>
        )
    }

    TableBodyContainer = () => {
        return (
            <TableBody>
                {this.state.ticketList.map((row: ITicketData, index: number) => {
                    return (
                        <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.id}
                            style={webStyle.alignIntemsCenter}>
                        <TableCell style={row.attributes.is_overdue_ticket === true ? webStyle.dataTextflag : webStyle.dataText} >
                            <p style={{ ...webStyle.CellPadding, ...webStyle.alignIntemsCenter }}>{row.attributes.ticket_number} 
                               {row.attributes.is_transferred_ticket && (
                                    <img src={SyncIcon} height={14} width={14} style={webStyle.imgMargin} />
                                )}
                            </p>
                        </TableCell>
                        <TableCell style={row.attributes.is_overdue_ticket === true ? webStyle.dataTextflag : webStyle.dataText} >
                            {row.attributes.duration} {this.state.daysText}
                        </TableCell>
                        <TableCell
                            component="th"
                            scope="row"
                            style={row.attributes.is_overdue_ticket === true ? webStyle.dataTextflag : webStyle.dataText}
                        >
                            {row.attributes.query_type === "Connect With Us" ? (
                                row.attributes.product_name.map((data: any, Proindex: number) => {
                                    return (
                                        <div key={row.attributes.product_name}>
                                            {data.products ? data.products : data.other_products}
                                                {Proindex !== row.attributes.product_name.length - 1 && ","}
                                        </div>
                                    )
                                })
                            ) : (
                                this.ProductNameDisplay(row)
                            )}
                        </TableCell>
                        <TableCell style={row.attributes.is_overdue_ticket === true ? webStyle.dataTextflag : webStyle.QueryTypeData}>
                            {row.attributes.query_type}
                        </TableCell>
                        <TableCell style={webStyle.dataText}>
                            {(row.attributes.is_overdue_ticket === true && row.attributes.status === "Closed") ? (
                            <StatusButton style={webStyle.Open}>
                                {row.attributes.status}
                            </StatusButton>) : (
                            <StatusButton style={row.attributes.status === "Open" ? webStyle.Closed : this.statusConditions(row)}>
                                {row.attributes.status}
                            </StatusButton>)}
                        </TableCell>
                        <TableCell style={row.attributes.is_overdue_ticket === true ? webStyle.dataTextflag : webStyle.dataText}>
                            {row.attributes.customer_name}
                        </TableCell>
                        <TableCell style={webStyle.dataText}>
                            <DetailButoon className="float_right" onClick={() => this.handleTicketListClick(row.id)} variant="contained" data-testid="detailsbtn">
                                {this.state.ViewDetailsBtnText}
                            </DetailButoon>
                        </TableCell>
                    </TableRow>
                );
            })}
        </TableBody>
    )
    }

    PaginationContainer = () => {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Grid container alignItems="center" style={webStyle.paginationPadding}>
                    <Grid item xs={5} sm={4} lg={5}>
                        <Grid container alignItems="center" className={classes.downloadContainer} >
                            <Grid item xs={12} container alignItems="center">
                                <DownloadBox> <DownloadIcon style={webStyle.downloadIcon} ></DownloadIcon> </DownloadBox>
                                <Button onClick={this.downloadTickets} style={webStyle.downloadGrid} data-testid="downloadticketbtn">
                                    <Typography style={webStyle.downloadText}>{this.state.downloadText}</Typography>
                                </Button>
                                    <CSVLink
                                        data={this.state.csvData}
                                        filename={this.state.csvFileName}
                                        className='hidden'
                                        ref={this.csvRef}
                                        target='_blank'
                                        enclosingCharacter={`"`}
                                    />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={7} sm={8} lg={7}>
                        {/* @ts-ignore */}
                        <CustomPagination
                            per_page={PER_PAGE}
                            page={this.state.TicketListingMeta?.pagination_details.current_page ?? 1}
                            count={Math.ceil((this.state.TicketListingMeta?.pagination_details.total_count ?? 0) / PER_PAGE)}
                            arrayLength={this.state.TicketListingMeta?.pagination_details.total_count ?? 0}
                            handlePageChange={(e: any, p: any) => this.handlePageChange(e, p)}
                            data-testid="customepaginaion"
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }

    CreateTicketPopUp = () => {
        const { classes } = this.props;
        return (
            <CreateTicketDialog
                open={localStorage.getItem("createNewTicket") === "true"}
                onClose={this.handleIsCreatedClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
            <DialogTitle>
                <Typography style={webStyle.dialogTitle}>
                    {this.state.createNewTicketText}
                </Typography>
                <IconBox onClick={() => this.goBackFromCreateTicket()} data-testid="closeicon" >
                    <CloseIcon style={webStyle.closeIconStyle}></CloseIcon>
                </IconBox>
            </DialogTitle>
            <DialogContent>
                <Grid item lg={12} md={12} sm={12} xs={12} style={webStyle.grid}>
                    <InputLabel htmlFor="firstName" style={webStyle.inputLabel}>
                        {this.state.customerNameLabel}<span style={webStyle.errorText2}>*</span>
                    </InputLabel>
                    <SelectField>
                        <CustomSelect
                            labelId="channel"
                            displayEmpty
                            data-testid="customername"
                            id="channel"
                            variant="outlined"
                            fullWidth
                            value={this.state.selectedCustomerName?.id}
                            style={webStyle.select}
                            onChange={this.handleCustomerNameChange.bind(this)}
                            defaultValue={""}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                getContentAnchorEl: null,
                            }}
                        >
                            <MenuItem value={""} className={classes.selectPlaceholder}>
                                <em style={webStyle.countryPlaceHolder}>
                                    {this.state.customerNamePlaceholder}
                                </em>
                            </MenuItem>
                            {this.state.all_company?.map((customerNameData: any) => {
                                return (
                                    <MenuItem value={customerNameData} key={customerNameData.label}>
                                        {customerNameData.label}
                                    </MenuItem>
                                );
                            })}
                            {this.state.all_company?.length === 0 && (
                                <MenuItem value="noData" disabled>
                                    {this.state.NoDataText}
                                </MenuItem>
                            )}
                        </CustomSelect>
                    </SelectField>
                    {
                        (this.state.isCustomerError && this.state.selectedCustomerName === "") && (
                            <Typography style={webStyle.errorText}>
                                {this.state.companyNameplaceholder}
                            </Typography>
                        )
                    }
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} style={webStyle.grid}>
                    <InputLabel htmlFor="firstName" style={webStyle.inputLabel}>
                        {this.state.selectContactNameLabel}<span style={webStyle.errorText2}>*</span>
                    </InputLabel>
                    <SelectField>
                        <CustomSelect
                            labelId="channel"
                            data-testid="customercontactname"
                            displayEmpty
                            id="channel"
                            variant="outlined"
                            fullWidth
                            value={this.state.selectedCustomerContact?.id}
                            style={webStyle.select}
                            onChange={this.handleCustomerContactName.bind(this)}
                            defaultValue={""}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                getContentAnchorEl: null,
                            }}
                        >
                            <MenuItem value={""} className={classes.selectPlaceholder}>
                                <em style={webStyle.countryPlaceHolder}>
                                    {this.state.selectContactPlaceholder}
                                </em>
                            </MenuItem>
                            {this.state.all_customer_contact?.map((contactNameData: any, index: number) => {
                                return (
                                    <MenuItem value={contactNameData} key={contactNameData.full_name}>
                                        {contactNameData.full_name}
                                    </MenuItem>
                                );
                            })}
                            {this.state.all_customer_contact?.length === 0 && (
                                <MenuItem value="noData" disabled>
                                    {this.state.NoDataText}
                                </MenuItem>
                            )}
                        </CustomSelect>
                    </SelectField>
                    {
                        (this.state.isCustomerContactError && this.state.selectedCustomerContact ===
                            "") && (
                            <Typography style={webStyle.errorText}>
                                {this.state.selectCustomerContactPlaceholder}
                            </Typography>
                        )
                    }
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} style={webStyle.grid}>
                    <InputLabel htmlFor="firstName" style={webStyle.inputLabel}>
                        {this.state.QueryTypePlaceholder}<span style={webStyle.errorText2}>*</span>
                    </InputLabel>
                    <SelectField>
                        <CustomSelect
                            labelId="channel"
                            displayEmpty
                            id="channel"
                            data-testid="selectquerytype"
                            variant="outlined"
                            fullWidth
                            value={this.state.selectedAddQueryType?.id}
                            style={webStyle.select}
                            onChange={this.handleChangeQueryType.bind(this)}
                            defaultValue={""}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                getContentAnchorEl: null,
                            }}
                        >
                            <MenuItem value={""} className={classes.selectPlaceholder}>
                                <em style={webStyle.countryPlaceHolder}>
                                    {this.state.QueryTypePlaceholder}
                                </em>
                            </MenuItem>
                            {this.state.query_type.map((data: any) => {
                                return (
                                    <MenuItem value={data} key={data.name}>
                                        {data.name}
                                    </MenuItem>
                                );
                            })}
                            {this.state.query_type.length === 0 && (
                                <MenuItem value="noData" disabled>
                                    {this.state.NoDataText}
                                </MenuItem>
                            )}
                        </CustomSelect>
                    </SelectField>
                    {
                        (this.state.isQueryTypeError && this.state.selectedAddQueryType === "") && (
                            <Typography style={webStyle.errorText}>
                                {this.state.selectQueryTypePlaceholder}
                            </Typography>
                        )
                    }
                </Grid>
                <Grid container justifyContent={"center"} style={webStyle.savebtn}>
                    <SaveButton
                        data-testid="createticketcancelbtn"
                        onClick={() => this.goBackFromCreateTicket()}
                        variant="outlined"
                        style={webStyle.cancelBtn}
                    >
                        {this.state.cancelBtnText}
                    </SaveButton>
                    <SaveButton
                        data-testid="createticketsubmit"
                        onClick={() => this.onProceedToRaiseQuery()}
                        type="submit"
                        variant="contained"
                        style={webStyle.proceedBtn}
                    >
                        {this.state.ProceedBtnText}
                    </SaveButton>
                </Grid>
            </DialogContent>
        </CreateTicketDialog>
        )
    }
    // Customizable Area End

    render() {
        const { classes } = this.props;
        const open = Boolean(this.state.filterMenu);
        const openStatu = Boolean(this.state.filterStatusMenu);
        return (
            // Customizable Area Start
            <>
                <AppHeader
                    navigation={this.props.navigation}
                    getQueryType={this.getQueryType}
                    getCompanyList={this.getComapnyList}
                />
                <MainContainer className={classes.container} id="Reloadcontent" data-testid="Reloadcontent">
                    <Loader loading={this.state.isLoading} />
                    {this.ListTabsContainer()}
                    {this.SearchFieldContainer()}
                    <TableContainer style={{ ...webStyle.fullWidthstyle, ...webStyle.table }}>
                        <Table
                            aria-labelledby="tableTitle"
                            size={'medium'}
                        >
                            <TableHead style={webStyle.tableHeadStyle}>
                                <TableRow>
                                    {this.state.headCells.map((headCell) => (
                                        <TableCell
                                            style={webStyle.tableCellStyle}
                                            align={headCell?.numeric}
                                            key={headCell.id}
                                            padding={headCell.disablePadding ? 'none' : 'normal'}
                                            sortDirection={this.state.orderBy === headCell?.label ? this.state.order : false}
                                        >
                                            {
                                                headCell.isSorting ? (
                                                    <TableSortLabel
                                                        data-testid="tablesortlable"
                                                        active={headCell.isSorting}
                                                        direction={this.state.orderBy === headCell.id ? this.state.order : 'asc'}
                                                        style={{ ...webStyle.headerText, textAlign: headCell?.numeric }}
                                                        onClick={() => this.stableSort(headCell.id)}
                                                    >
                                                        {headCell?.label}
                                                        <div style={webStyle.tableSortStyle}>
                                                            {
                                                                this.state.orderBy === headCell.id &&
                                                                    this.state.order !== 'asc' ?
                                                                    <ArrowDropDownSharpIcon></ArrowDropDownSharpIcon>
                                                                    :
                                                                    <ArrowDropUpSharpIcon></ArrowDropUpSharpIcon>
                                                            }
                                                        </div>
                                                    </TableSortLabel>
                                                ) : this.headerCellWithFilterCondition(headCell,open,openStatu)
                                            }
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            {this.TableBodyContainer()}
                        </Table>
                        {
                            !this.state.isLoading && this.state.ticketList.length === 0 && (
                                <Grid item lg={12} md={12} sm={12} style={webStyle.noDataContainer}>
                                    <Typography style={{ ...webStyle.noData, ...webStyle.headerCenter }}>
                                      {this.state.noProductsFountText}
                                    </Typography>
                                </Grid>
                            )
                        }
                    </TableContainer>
                    {
                        this.state.TicketListingMeta &&
                        this.PaginationContainer()
                    }
                </MainContainer >
                {this.CreateTicketPopUp()}
                <AppFooter isLoading={this.state.isLoading} />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    searchIconStyle: {
        fill: '#8B95AF'
    },
    overdueTicketsLink: {
        flexBasis: "0", 
        marginLeft: '15px'
    },
    overdueCount: {
        fontWeight: 600, 
        fontSize: '14px'
    },
    noMarginTop: {
        marginTop: "0px"
    },
    closeIconStyle: {
        fill: '#1A3B71', 
        cursor: 'hand'
    },
    downloadGrid: {
        marginLeft: "0px"
    },
    paginationPadding: {
        marginTop: '15px'
    },
    imgMargin: {
        marginLeft: '5px'
    },
    alignIntemsCenter: {
        alignItems: 'center'
    },
    CellPadding: {
        margin: '0px', 
        display: 'flex'
    },
    errorText2: {
        color: "red"
    },
    savebtn: {
        marginTop: '0px'
    },
    cancelBtn: {
        marginRight: '12px'
    },
    proceedBtn: {
        marginLeft: '12px'
    },
    fullWidthstyle: {
        width: '100%'
    },
    productPadding: {
        paddingLeft: "30px"
    },
    cachedIcon: {
        fill: 'rgb(0, 62, 118)', 
        marginBottom: '-8px', 
        marginLeft: '5px'
    },
    filterIcon: {
        paddingTop: '5px',
        marginLeft: '5px'
    },
    headerCenter: {
        display: "flex",
        justifyContent: "center"
    },
    clearBtnGrid: {
        marginBottom: "10px", 
        padding: "10px 10px 0px 10px"
    },
    tableHeadStyle: {
        background: '#E3EFFD'
    },
    tableCellStyle: {
        maxWidth: '128px', 
        background: '#E3EFFD',
        borderColor: 'rgba(170, 188, 206, 1)'
    },
    tableSortStyle: {
        paddingLeft: '5px' 
    },
    noDataContainer: {
        height: '150px', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center'
    },
    formCheckBoxStyle: {
        padding: "0px 3px 0px 5px",
        color: "#043c74", 
        fill: "#043c74" 
    },
    menuItemStyle: {
        backgroundColor: '#ffffff', 
        color: '#404056', 
        padding: '10px 15px'
    },
    clearButtonStyle: {
        borderColor: "#043c74", 
        color: "#043c74",
        width: "20%", 
        padding: "0px", 
        marginTop: "10px", 
        marginRight: "10px", 
        marginBottom: "0px",
    },
    CancelButtonStyle: {
        borderColor: "#043c74", 
        color: "#043c74", 
        width: "30%", 
        padding: "0px", 
        marginBottom: "0px",
    },
    saveButtonStyle: {
        borderColor: "#043c74",
        backgroundColor: "#043c74",
        color: "#ffffff", 
        width: "30%", 
        padding: "0px",
        marginBottom: "0px",
    },
    buttonStyle : {
        borderColor: "#043c74",
        color: "#043c74",
        width: "30%",
        padding: "0px",
        marginTop: "10px",
        marginRight: "10px", 
        marginBottom: "0px",
    },
    checkBoxStyle: {
        padding: "0px 3px 0px 5px",
        color: "#043c74",
        fill: "#043c74",
    },
    menuItem: {
        backgroundColor: '#ffffff',
         color: '#404056',
          padding: '10px 15px',
    },
    downloadText: {
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '14px',
        color: '#043c74',
        textDecoration: 'underline',
    },
    downloadIcon: {
        height: "16px",
        width: "16px",
        margin: "0px",
        color: "#043c74"
    },
    dateCountSpace: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    Tab1: {
        background: '#fff',
    },
    Tab2: {
        borderTopLeftRadius: "16px",
        borderBottomLeftRadius: "16px",
        background: '#DCDFF0',
    },
    activeTransferredTickets: {
        borderTopRightRadius: "16px",
        borderBottomRightRadius: "16px",
        background: '#DCDFF0',
    },
    tab3: {
        borderTopLeftRadius: "16px",
        borderBottomLeftRadius: "16px",
    },
    headerText: {
        color: 'rgba(4, 60, 116, 1)',
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '16px',
    },
    dataText: {
        maxWidth: '128px',
        color: 'rgba(4, 60, 116, 1)',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '14px',
        borderColor: '#F2F2F5',
    },
    QueryTypeData: {
        color: 'rgba(4, 60, 116, 1)',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '15px',
        borderColor: '#F2F2F5',
        width: "130px"
    },
    inProgress: {
        backgroundColor: '#FFF7E6',
        color: '#EBC74F',
        border: '1px solid #FFF7E6',
        cursor: 'arrow',
    },
    Closed: {
        backgroundColor: '#F8ECEE',
        color: '#CE4436',
        border: '1px solid #F8ECEE',
        cursor: 'arrow',
    },
    dataTextflag: {
        maxWidth: '128px',
        color: '#ff0000',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '14px',
        borderColor: '#F2F2F5',
    },
    table: {
        border: '1px solid rgba(170, 188, 206, 1)',
        boxShadow: 'none',
        borderRadius: '16px',
    },
    Open: {
        backgroundColor: '#EBF7F3',
        color: '#85C67A',
        border: '1px solid #EBF7F3',
        cursor: 'arrow',
    },
    noData: {
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '20px',
    },
    alertBox: {
        color: "#3a3f63",
        borderRadius: "16px",
        border: 'solid 1px #f6e5ae',
        backgroundColor: '#fff9d7',
        fontSize: '12px',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        alignItems: 'center',
    },
    countryPlaceHolder: {
        fontStyle: "normal",
        fontFamily: "SFProDisplay",
        fontWeight: 400,
        fontSize: "16px",
        color: "rgba(218, 224, 241, 1)",
    },
    grid: {
        paddingBottom: '20px'
    },
    errorText: {
        fontSize: '14px',
        color: 'red',
        fontFamily: 'SFProDisplay-Regular',
        marginTop: '6px',
        marginLeft: '5px'
    },
    dialogTitle: {
        margin: `0px 28px 24px 0`,
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: 1.33,
        color: '#043c74',
    },
    inputLabel: {
        fontSize: "15px",
        fontFamily: "SFProDisplay",
        fontWeight: 700,
        lineHeight: 2,
        color: '#043c74',
        paddingBottom: "10px",
    },
    select: {
        height: "46px",
        borderRadius: "8px",
        marginTop: '5px',
    },
};

const MainContainer: any = withStyles({
    root: {
        "& .MuiSvgIcon-fontSizeInherit": {
            fontSize: '28px'
        },
        "& .MuiAlert-root": {
            padding: `6px 15px !important`
        },
        padding: "34px 55px 6.5% 50px",
        "& .MuiTableCell-paddingNone": {
            padding: '8px 16px'
        },
        "& .MuiTableCell-alignRight": {
            flexDirection: 'row'
        },
        "& .MuiTableCell-root": {
            padding: '8px 16px',
        },
        "& .MuiTableRow-root": {
            backgroundColor: 'inherit',
            "&:hover": {
                backgroundColor: "inherit",
            },
        },
        "& .MuiTableSortLabel-icon": {
            display: 'none'
        },
        "& .MuiAlert-message": {
            padding: '0',
            display: 'contents'
        },
        "& .MuiAlert-standardWarning .MuiAlert-icon": {
            color: "#043c74",
            fontSize: "30px",
            padding: "2px 0 0 0",
        }
    }
})(Container)

const CountBox: any = withStyles({
    root: {
        height: '20px',
        width: '30px',
        backgroundColor: 'rgba(218, 224, 241, 1)',
        color: 'rgba(4, 60, 116, 1)',
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '12px',
        margin: '0px 5px 0px 12px',
        borderRadius: '16px',
        textAlign: 'center',
        padding: '3px 3px'
    }
})(Box)

const StatusButton: any = withStyles({
    root: {
        width: '110px',
        height: '32px',
        color: 'inherit',
        fontFamily: 'SFProDisplay',
        fontSize: '14px',
        fontWeight: 500,
        backgroundColor: 'inherit',
        borderColor: 'inherit',
        borderRadius: '8px',
        borderWidth: '1px',
        boxShadow: 'none',
        textTransform: 'none',
        float: 'left',
        cursor: 'initial',
        marginLeft: '-2px',
        "&:hover": {
            backgroundColor: "#E3EFFD",
            boxShadow: "none",
        },
    }
})(Button);

const DetailButoon: any = withStyles({
    root: {
        width: '124px',
        height: '32px',
        color: '#459eff',
        fontSize: '14px',
        fontFamily: 'SFProDisplay',
        fontWeight: 500,
        backgroundColor: '#e1efff',
        border: '1px solid #459eff',
        borderRadius: '8px',
        borderWidth: '1px',
        boxShadow: 'none',
        textTransform: 'none',
        "&:hover": {
            backgroundColor: "#e1efff",
            boxShadow: "none",
        },
    }
})(Button);

const CreateTicketDialog: any = withStyles({
    root: {
        "& .MuiDialog-paperWidthSm": {
            minWidth: '590px',
        },
        "& .MuiDialog-paperScrollPaper": {
            padding: '16px 16px 30px 40px',
            borderRadius: '12px',
        },
        "& .MuiDialogTitle-root": {
            padding: '18px 0px 0px 0px'
        },
        "& .MuiDialogContent-root": {
            padding: '0px 40px 0px 0px',
            paddingBottom: '10px'
        },
        "& .MuiTypography-h6": {
            display: 'flex',
            justifyContent: 'space-between',
        },
        "& .MuiDialogActions-root": {
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0px',
            paddingBottom: '30px',
        },
        "& .WithStyles(ForwardRef(Button))-root-10": {
            "@media (maxWidth: 600px)": {
                width: 600
            }
        },
    }
})(Dialog);

const IconBox: any = withStyles({
    root: {
        height: '24px',
        width: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#DAE0EC',
        borderRadius: '50%',
        float: 'right',
        padding: '0px 0px 0px 0px',
        marginTop: '-19px',
        "& .MuiSvgIcon-root": {
            height: '16px',
            width: '16px'
        },
    }
})(Box);

const CustomSelect: any = withStyles({
    root: {
        "&.MuiOutlinedInput-input": {
            padding: '13px 14px',
        }
    }
})(Select);

const SelectField: any = withStyles({
    root: {
        width: '100%',
        "& .MuiOutlinedInput-root": {
            fontFamily: "SFProDisplay",
            fontWeight: 500,
            backgroundColor: "rgba(255, 255, 255, 1)",
            fontSize: "16px",
            color: '#6a74a5',
        },
        "& .MuiInputBase-input": {
            fontFamily: "SFProDisplay",
            fontWeight: 500,
            backgroundColor: "rgba(255, 255, 255, 1)",
            fontSize: "14px",
            color: '#6a74a5',
        },
        "& .MuiOutlinedInput-input": {
            padding: '13px 16px 13px 24px'
        },
        "& .MuiSelect-icon": {
            fill: "rgba(4, 60, 116, 1)",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#dae0f1",
            borderWidth: 1,
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#dae0f1",
            borderWidth: 1,
        },
    }
})(FormControl);

const SaveButton: any = withStyles({
    root: {
        width: 130,
        height: "36px",
        color: "rgba(255, 255, 255, 1)",
        fontSize: "16px",
        fontFamily: "SFProDisplay",
        fontWeight: 700,
        marginRight: '10px',
        marginTop: "5px",
        borderRadius: "8px",
        boxShadow: "none",
        textTransform: "none",
        "&.MuiButton-contained": {
            backgroundColor: 'rgba(4, 60, 116, 1)',
            "&:hover": {
                backgroundColor: 'rgba(4, 60, 116, 1)',
            }
        },
        "&.MuiButton-outlined": {
            border: '1px solid rgba(4, 60, 116, 1)',
            color: 'rgba(4, 60, 116, 1)',
            padding: '5px 15px',
            "&:hover": {
                backgroundColor: 'transparent',
            }
        },
        "&:hover": {
            backgroundColor: "rgba(4, 60, 116, 1)",
            boxShadow: "none",
        },
        "@media (max-width: 600px)": {
            width: "100%",
            marginLeft: 0,
        },
    },
})(Button);

const CustomBox: any = withStyles({
    root: {

    }
})(Box)

const styles = () => ({
    container: {
        "@media (max-width: 600px)": {
            paddingLeft: 20,
            paddingRight: 20

        },
    },
    overdueAlertRes: {
        "@media (max-width: 500px)": {
            flexWrap: 'wrap',
            rowGap: '10px',
            '& div': {
                margin: '0px !important'
            }
        }
    },
    dateCountSpaceRes: {
        "@media (max-width: 720px)": {
            flexDirection: 'row'
        }
    },
    dateRangeRes: {
        "@media (max-width: 720px)": {
            marginLeft: "-20px"
        }
    }
})

export default withStyles(styles)(TicketList)
// Customizable Area End
