import React from "react";

// Customizable Area Start
import {
    Container,
    Button,
    Grid,
    MenuItem,
    Select
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import GetAppIcon from '@material-ui/icons/GetApp';
import DownloadPermissionsController, {
    IDocument,
    Props
} from "./DocumentViewController";
import { AppHeader } from "../../../components/src/AppHeader.web";
import AppFooter from "../../../components/src/AppFooter.web";
import { Document, Page, pdfjs } from "react-pdf";
import { isFirefox } from 'react-device-detect';
import Loader from "../../../components/src/Loader.web";
import EmployeeAppHeader from "../../../components/src/EmployeeAppHeader.web";
import { isEmployee } from "../../../components/src/common";

// Customizable Area End
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class DocumentView extends DownloadPermissionsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    documentView = () => {
        return (
            isFirefox ?
            <Document
                onLoadSuccess={this.onDocumentLoadSuccess}
                onItemClick={(e: any) => e.preventDefault()}
                file={this.state.selectedDocument?.doc_file}
                loading={"Loading page…"}
                data-testId="documentviewer"
            >
            {Array.from(
                new Array(this.state.numPages),
                    (_el, index) => (
                        <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            loading={"Loading page…"}
                        />
                    )
            )}
            </Document> :
            <iframe
                style={webStyle.docView}
                onLoad={this.disableRightclick}
                id="fraDisabled"
                src={this.state.selectedDocument?.doc_file ?
                    `${this.state.selectedDocument?.doc_file}#toolbar=0` : ''}
            />
        )
    }

    downloadButton = () => {
        return (
            this.state.selectedDocument?.download_file ?
                <DownloadButton
                    disabled={!this.state.selectedDocument || !this.state.selectedDocument?.download_file}
                    onClick={this.handleDownloadClick}
                    variant="contained"
                    data-testId="downloadbtn"
                >
                    <GetAppIcon style={webStyle.Downloadicon}></GetAppIcon>
                    {this.state.DownloadButtonText}
                </DownloadButton> : <div style={webStyle.Downloadicon}></div>
        )
    }

    selectDocumentField = () => {
        const { classes } = this.props
        return (
            <Grid item lg={4}>
                <Select
                    classes={{ select: classes.root }}
                    labelId={this.state.departmentIdText}
                    displayEmpty
                    id={this.state.departmentIdText}
                    variant="outlined"
                    data-testId="documentSelect"
                    fullWidth
                    value={this.state.selectedDocument?.file_id || 0}
                    style={webStyle.select}
                    onChange={this.handleDocumentSelect.bind(this)}
                    defaultValue={""}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null,
                    }}
                >
                <MenuItem value={0} disabled>
                    <em style={webStyle.PlaceHolder}>
                        {this.state.selectDocumentPlaceholder}
                    </em>
                </MenuItem>
                {this.state.documentsList.map((data: IDocument) => {
                    if (data.file_name) {
                        return (
                            <MenuItem value={data.file_id} key={data.file_id}>
                                {data.file_name}
                            </MenuItem>
                        );
                    }
                })}
                {this.state.documentsList.length === 0 && (
                    <MenuItem value="noData" disabled>{this.state.noDataText}</MenuItem>
                )}
                </Select>
            </Grid>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                {isEmployee() ? <EmployeeAppHeader data-testId="employeeheader" navigation={this.props.navigation} /> : <AppHeader navigation={this.props.navigation} />}
                <MainContainer>
                    <Loader loading={this.state.loading} />
                    <Grid container justifyContent="space-between">
                        <BackButtom onClick={this.handleBackClick} variant="outlined" data-testId="backbutton">
                            <ArrowBackIosIcon style={webStyle.Backicon}></ArrowBackIosIcon>
                            {this.state.BackButtonText}
                        </BackButtom>
                        {this.selectDocumentField()}
                        {this.downloadButton()}
                    </Grid>
                    <Grid container style={webStyle.docContainer} className="document_view">
                        {this.state.documentsList.length > 0 ? 
                            this.documentView()
                            : <p>{this.state.loadingText}</p>}
                    </Grid>
                    <div className="document_cover" style={webStyle.coverDocument}>
                    </div>
                </MainContainer>
                <AppFooter isLoading={false} />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    coverDocument: {
        width: window.innerWidth - 100
    },
    Backicon: {
        width: '16px',
        height: '16px',
        marginRight: '3px'
    },
    Downloadicon: {
        width: '18px',
        height: '18px',
        marginRight: '5px'
    },
    docContainer: {
        border: '1px solid #D9D9D9',
        height: '650px',
        borderRadius: '8px',
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    select: {
        height: "38px",
        borderRadius: "8px",
        display: "flex",
    },
    PlaceHolder: {
        fontWeight: 500,
        fontFamily: "SFProDisplay",
        fontSize: "16px",
        color: "rgba(0, 0, 0, 0.23)",
        fontStyle: "normal"
    },
    docView: {
        width: '100%',
        height: '100%'
    }
};

const MainContainer:any = withStyles({
    root: {
        padding: '20px 60px 65px 75px',
        "& .MuiOutlinedInput-input": {
            padding: '9px 12px',
        },
        "@media (max-width: 600px)": {
            padding: '20px 20px 20px 20px'
        }
    }
})(Container);

const BackButtom:any = withStyles({
    root: {
        color: '#193263',
        borderColor: '#193263',
        borderRadius: '5px',
        height: '34px',
        textTransform: 'none',
    }
})(Button)

const DownloadButton:any = withStyles({
    root: {
        color: '#FFFFFF',
        background: '#193263',
        borderRadius: '5px',
        height: '34px',
        boxShadow: 'none',
        textTransform: 'none',
    }
})(Button)

const styles = () => ({
    root: {
    }
})

export default withStyles(styles)(DocumentView)

// Customizable Area End
