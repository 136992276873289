import React from "react";

// Customizable Area Start
import {
    Container,
    Button,
    Typography,
    TextField,
    InputLabel,
    Grid,
    MenuItem,
    Select,
    InputAdornment,
    IconButton,
    Box,
} from "@material-ui/core";
import {
    withStyles,
} from "@material-ui/core/styles";
import AppHeader from "../../../components/src/EmployeeAppHeader.web";
import AppFooter from "../../../components/src/AppFooter.web";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector";
import { CustomGrid, logout } from "../../../components/src/common";
// import EditIcon from '@mui/icons-material/Edit';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
// Customizable Area End

import CustomerProfilesForEmpControllerWeb, {
    Props,
} from "./CustomerDetailsInEmployeeController";


export default class EmployeeProfile extends CustomerProfilesForEmpControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <AppHeader navigation={this.props.navigation} />
                <MainContainer>
                    <Typography style={webStyle.detailTitle}>
                        {this.state.ProfileDetailLabel}
                    </Typography>
                    <form
                    // onSubmit={(e) => {
                    //     e.preventDefault();
                    //     this.onProfileDetailsSave();
                    // }}
                    >
                        <Grid container spacing={7}>
                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="firstName" style={webStyle.inputLabel}>
                                    {this.state.labelFirstName}
                                </InputLabel>
                                <InputField
                                    id="firstName"
                                    data-testid='txtFirstName'
                                    variant="outlined"
                                    required
                                    disabled
                                    placeholder="First Name"
                                    value={this.state.firstName}
                                    // onChange={this.handleChange("firstName").bind(this)}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="lastName" style={webStyle.inputLabel}>
                                    {this.state.lastnameLabel}
                                </InputLabel>
                                <InputField
                                    id="lastName"
                                    variant="outlined"
                                    required
                                    disabled
                                    placeholder="Last Name"
                                    value={this.state.lastName}
                                    // onChange={this.handleChange("lastName").bind(this)}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="emailId" style={webStyle.inputLabel}>
                                    {this.state.emailLabel}
                                </InputLabel>
                                <InputField
                                    id="email"
                                    variant="outlined"
                                    disabled
                                    value={this.state.emailId}
                                    // onChange={this.handleChange("emailId").bind(this)}
                                    required
                                    placeholder="Email ID"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="biospaceId" style={webStyle.inputLabel}>
                                    {this.state.biospaceIdLabel}
                                </InputLabel>
                                <InputField
                                    id="biospaceId"
                                    variant="outlined"
                                    disabled
                                    value={this.state.biospaceId}
                                    // onChange={this.handleChange("biospaceId").bind(this)}
                                    required
                                    placeholder="Biospace ID"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="phoneNo" style={webStyle.inputLabel}>
                                    {this.state.CountryLabel}
                                </InputLabel>
                                <CustomGrid item md={12} lg={12} sm={12} xs={12} style={{ marginTop: "5px" }}>
                                    <CountryCodeSelector
                                        style={{}}
                                        navigation={""}
                                        disable={this.state.phoneNumberEditFlag}
                                        id="countryCode"
                                        allowPropChange={true}
                                        value={this.state.phoneCountryCode}
                                        onChange={this.handlePhoneCountryCode}
                                        placeHolder="Please select country"
                                    />

                                </CustomGrid>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="phoneNo" style={webStyle.inputLabel}>
                                    {this.state.phoneNumLabel}
                                </InputLabel>
                                <InputField
                                    id="phoneNo"
                                    data-testid="txtPhoneNo"
                                    variant="outlined"
                                    disabled={this.state.phoneNumberEditFlag}
                                    value={this.state.phoneNumber}
                                    placeholder="Phone Number"
                                    onChange={this.handleChange("phoneNumber").bind(this)}
                                    fullWidth
                                    type='number'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">

                                                {
                                                    this.state.phoneNumberEditFlag ? <EditIcon style={{ backgroundColor: "transparent" }} data-testid="btnEditPhone" onClick={this.handlePhoneNumberEditFlag} /> : (
                                                        <Grid container style={{ display: "flex", justifyContent: "space-between", flexWrap: "nowrap", width: "60px", marginLeft: "10px" }}>
                                                            <Grid item>
                                                                <IconButton disableRipple disableFocusRipple style={{ width: "20px", height: "20px" }} data-testid="btnClearNumber" onClick={this.handlePhoneNumberClear}>
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            </Grid>
                                                            <Grid item>
                                                                <IconButton disableRipple disableFocusRipple style={{ width: "20px", height: "20px" }} data-testid="btnSaveNumber" onClick={this.handlePhoneNumberSave}>
                                                                    <CheckIcon />
                                                                </IconButton>
                                                            </Grid>


                                                        </Grid>

                                                    )
                                                }

                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="companyName" style={webStyle.inputLabel}>
                                    {this.state.companynameLabel}
                                </InputLabel>
                                <InputField
                                    id="companyName"
                                    variant="outlined"
                                    value={this.state.companyName}
                                    // onChange={this.handleChange("companyName").bind(this)}
                                    required
                                    placeholder="Company Name"
                                    fullWidth
                                    disabled
                                />
                            </Grid>

                            <Grid item md={6} sm={6} xs={12} lg={6} style={webStyle.grids}>
                                <InputLabel htmlFor="reportManager" style={webStyle.inputLabel}>
                                    {this.state.reportManagerLabel}
                                </InputLabel>
                                <InputField
                                    id="reportManager"
                                    variant="outlined"
                                    value={`${this.state?.reportManager?.first_name || ''} ${this.state?.reportManager?.last_name || ''}`}
                                    disabled
                                    // onChange={this.handleChange("reportManager").bind(this)}
                                    required
                                    placeholder="Reporting Manager"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="department" style={webStyle.inputLabel}>
                                    {this.state.departmentLabel}
                                </InputLabel>
                                <InputField
                                    id="department"
                                    variant="outlined"
                                    value={this.state.departmentName}
                                    disabled
                                    fullWidth
                                    // onChange={this.handleChange("department").bind(this)}
                                    // onChange={this.handleDepartmentChange.bind(this)}
                                    required
                                    placeholder="Department"
                                />
                                {/* <Select
                                    labelId="department"
                                    displayEmpty
                                    id="department"
                                    variant="outlined"
                                    fullWidth
                                    value={this.state.department}
                                    style={webStyle.select}
                                    onChange={this.handleDepartmentChange.bind(this)}
                                    defaultValue={""}
                                    MenuProps={{
                                        style: {
                                            maxHeight: 310,
                                        },
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                >
                                    <MenuItem value={this.state.department}>
                                        <em style={webStyle.selectPlaceHolder}>
                                            Select Department
                                        </em>
                                    </MenuItem>
                                    {this.state.departmentList.map((data, index) => {
                                        return (
                                            <MenuItem value={data.id} key={index}>
                                                {data.name}
                                            </MenuItem>
                                        );
                                    })}
                                    {this.state.departmentList.length === 0 && (
                                        <MenuItem value="noData" disabled>
                                            No Data
                                        </MenuItem>
                                    )}
                                </Select> */}
                            </Grid>

                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="assignedCustomer" style={webStyle.inputLabel}>
                                    {this.state.assignedCustomerLabel}
                                </InputLabel>
                                <InputField
                                    id="assignedCustomer"
                                    variant="outlined"
                                    value={this.state.assignedCustomer}
                                    // onChange={this.handleChange("assignedCustomer").bind(this)}
                                    required
                                    placeholder="Assigned Customer"
                                    fullWidth
                                    disabled
                                />
                            </Grid>

                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="Profile" style={webStyle.inputLabel}>
                                    {this.state.ProfileLabel}
                                </InputLabel>
                                <InputField
                                    id="Profile"
                                    variant="outlined"
                                    value={this.state.profile}
                                    disabled
                                    // onChange={this.handleChange("Profile").bind(this)}
                                    required
                                    placeholder="Profile"
                                    fullWidth
                                />
                                {/* <Select
                                    labelId="Profile"
                                    displayEmpty
                                    id="Profile"
                                    variant="outlined"
                                    fullWidth
                                    value={this.state.profile_id}
                                    style={webStyle.select}
                                    onChange={this.handleProfileChange.bind(this)}
                                    defaultValue={""}
                                    MenuProps={{
                                        style: {
                                            maxHeight: 310,
                                        },
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                >
                                    <MenuItem value={this.state.profile_id}>
                                        <em style={webStyle.selectPlaceHolder}>
                                            Select Profile
                                        </em>
                                    </MenuItem>
                                    {this.state.ProfileList.map((data, index) => {
                                        return (
                                            <MenuItem value={data.id} key={index}>
                                                {data.name}
                                            </MenuItem>
                                        );
                                    })}
                                    {this.state.ProfileList.length === 0 && (
                                        <MenuItem value="noData" disabled>
                                            No Data
                                        </MenuItem>
                                    )}
                                </Select> */}
                            </Grid>

                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="Territory" style={webStyle.inputLabel}>
                                    {this.state.TerritoryLabel}
                                </InputLabel>
                                <InputField
                                    id="Territory"
                                    variant="outlined"
                                    value={this.state.territory}
                                    disabled
                                    // onChange={this.handleChange("Territory").bind(this)}
                                    required
                                    placeholder="Territory"
                                    fullWidth
                                />
                                {/* <Select
                                    labelId="Territory"
                                    displayEmpty
                                    id="Territory"
                                    variant="outlined"
                                    fullWidth
                                    value={this.state.territory_id}
                                    style={webStyle.select}
                                    onChange={this.handleTerritoryChange.bind(this)}
                                    defaultValue={""}
                                    MenuProps={{
                                        style: {
                                            maxHeight: 310,
                                        },
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                >
                                    <MenuItem value={this.state.profile_id}>
                                        <em style={webStyle.selectPlaceHolder}>
                                            Select Territory
                                        </em>
                                    </MenuItem>
                                    {this.state.territoryList.map((data, index) => {
                                        return (
                                            <MenuItem value={data.id} key={index}>
                                                {data.name}
                                            </MenuItem>
                                        );
                                    })}
                                    {this.state.territoryList.length === 0 && (
                                        <MenuItem value="noData" disabled>
                                            No Data
                                        </MenuItem>
                                    )}
                                </Select> */}
                            </Grid>

                            {/* <Grid container spacing={2} style={{ paddingTop: '30px', paddingLeft: '28px' }}> */}
                            {/* <Grid item>
                                    <LogOutButton onClick={logout} variant="outlined">Log Out</LogOutButton>
                                </Grid> */}
                            {/* <Grid item>
                                    <SaveButton type="submit" variant="contained">
                                        Save
                                    </SaveButton>
                                </Grid>
                            </Grid> */}
                        </Grid>
                    </form>
                </MainContainer>
                <AppFooter isLoading={this.state.isLoading} />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle = {
    detailTitle: {
        color: "rgba(33, 43, 54, 1)",
        fontFamily: "SFProDisplay",
        fontWeight: 700,
        fontSize: "20px",
        marginBottom: "30px",
    },
    inputLabel: {
        fontSize: "14px",
        fontFamily: "SFProDisplay",
        fontWeight: 700,
        color: "rgba(4, 60, 116, 1)",
        paddingBottom: "5px",
        paddingTop: "10px",
    },
    selectPlaceHolder: {
        fontStyle: "normal",
        fontFamily: "SFProDisplay",
        fontWeight: 400,
        fontSize: "16px",
        color: "rgba(4, 60, 160, 0.65)",
    },
    select: {
        height: "42px",
        marginTop: "6px",
        borderRadius: "8px",
    },
    grids: {
        paddingTop: '10px',
        paddingBottom: '0px'
    },
};

const InputField: any = withStyles({
    root: {
        background: "inherite",
        margin: "5px 0px 0px 0px",
        "& ::placeholder": {
            color: 'rgba(4, 60, 160, 0.65)',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
            fontSize: '14px',
            opacity: 1,
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(214, 214, 214, 1)",
        },
        "& .MuiInputBase-input": {
            height: '15px',
        },
        "& .MuiOutlinedInput-input": {
            padding: `12px 14px !important`
        },
        "& .MuiOutlinedInput-root": {
            fontFamily: "SFProDisplay",
            fontWeight: 400,
            backgroundColor: "rgba(255, 255, 255, 1)",
            fontSize: "14px",
            "& fieldset": {
                height: '40px',
                borderColor: "rgba(218, 224, 241, 1)",
                borderRadius: "8px",
                borderWidth: 1,
            },
            ".Mui-focused": {
                borderColor: "rgba(218, 224, 241, 1)",
            },
        },
        "& .MuiFormHelperText-contained": {
            marginLeft: "0px !important",
        },
        "& .MuiInputBase-input.Mui-disabled": {
            background: "#F1F4FA",
            borderRadius: "8px",
            height: 13,
            color: 'rgba(106, 116, 165, 1)',
            fontSize: '14px',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
        },
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(218, 224, 241, 1)",
            borderWidth: '1px'
        }
    },
})(TextField);

const LogOutButton = withStyles({
    root: {
        border: "2px solid rgba(4, 60, 116, 1)",
        color: "rgba(4, 60, 116, 1)",
        height: "50px",
        width: 180,
        borderRadius: "8px",
        fontFamily: "SFProDisplay",
        fontWeight: 700,
        fontSize: "20px",
        marginTop: "5px",
        marginBottom: "5px",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 1)",
            boxShadow: "none",
        },
        "@media (max-width: 600px)": {
            width: "100%",
            marginLeft: 0,
        },
    },
})(Button);

const SaveButton = withStyles({
    root: {
        color: "rgba(255, 255, 255, 1)",
        backgroundColor: "rgba(4, 60, 116, 1)",
        height: "50px",
        width: 180,
        borderRadius: "8px",
        fontSize: "20px",
        fontFamily: "SFProDisplay",
        fontWeight: 700,
        marginTop: "5px",
        marginBottom: "5px",
        boxShadow: "none",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "rgba(4, 60, 116, 1)",
            boxShadow: "none",
        },
        "@media (max-width: 600px)": {
            width: "100%",
            marginLeft: 0,
        },
    },
})(Button);

const MainContainer: any = withStyles({
    root: {
        padding: "30px 55px 6.5% 75px",
        "& .MuiOutlinedInput-input": {
            padding: '12px '
        },
        "@media (max-width: 600px)": {
            padding: "24px 24px 24px 24px",
            "& .MuiBox-root-8": {
                padding: "24px 0px 24px 0px",
            },
            "& .MuiBox-root-30": {
                padding: "24px 0px 24px 0px",
            },
            "& .MuiTypography-body1": {
                marginLeft: "0px !important",
            },
        },



    },
})(Container);


// Customizable Area End
