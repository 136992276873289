Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.contentTypeApiGetCountryCodes = "application/json";
exports.apiEndPointGetCountryCodes = "account/accounts/country_code_and_flag";
exports.apiGetCountryCodesType = "GET";

// Customizable Area Start
exports.countryPlaceHolder = "Enter Country Code";
exports.countryPlaceHolderMobile = "Type Here...";
// Customizable Area End
