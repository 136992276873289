import React from "react";
// Customizable Area Start
import { NavLink } from "react-router-dom";
import {
  Typography,
  Grid,
  Button,
  Box,
  TextField,
  InputAdornment,
  Popover,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { withStyles } from "@material-ui/core/styles";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace"
import { AppHeader } from "../../../components/src/AppHeader.web";
import QueryDetailsController, { Props } from "./QueryDetailsController.web";
import Loader from "../../../components/src/Loader.web";
import CloseIcon from '@material-ui/icons/Close';
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';
import Upload from "./AttachmentView.web";
import AppFooter from "../../../components/src/AppFooter.web";
// Customizable Area End

export class QueryDetails extends QueryDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  ChemicalNameToolTip() {
    return (
      <CustomBox>
        <Grid>
          <CancelTwoToneIcon
            data-testid='btnCancelTwoToneIcon'
            style={webStyle.cancelIcon}
            className="floatRight"
            onClick={(e: any) => this.handleToolTip(e, "")}
          >
          </CancelTwoToneIcon>
        </Grid>
        <Grid item lg={12}>
          <Typography style={webStyle.chemicalToolTip}>
            {this.state.DocumentText}-[{this.state.popUPText}]
          </Typography>
        </Grid>
      </CustomBox>
    )
  };

  ProductNameList() {
    return (
      <CustomBox>
        <Grid>
          <CancelTwoToneIcon
            data-testid='ProductNameList'
            style={webStyle.cancelIcon}
            className="floatRight"
            onClick={(e: any) => this.handleProductToolTip(e, [], [])}
          >
          </CancelTwoToneIcon>
        </Grid>
        <Grid item lg={12}> 
          <Typography style={webStyle.chemicalToolTip} data-testid="productName">
            {this.state.ProductText}-[
            {this.state.ProductpopUPText.map((data: any) => {
              return (
                <NavLink key={data.id} to={`/ProductDetail/${data.name}`}>
                  <Typography data-testid="productName" onClick={() => this.setPopProductId(data)} style={webStyle.productNameStyle}>
                    {data.products}
                  </Typography>
                </NavLink>
              )
            })}
            ]
          </Typography>
        </Grid>
      </CustomBox>
    )
  };

  CardbadgeColor = () => {
    return (
      this.state.query_detail?.status == "Open" ? webStyle.cardBadge2 : webStyle.cardBadge3
    )
  }

  AttachmentPopUp() {
    return (
      <AttachmentDialog
        open={this.state.isAttachment}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Typography style={webStyle.dialogTitle} align="center">
            {this.state.uploadText}
          </Typography>
          <IconBox onClick={this.handleCloseAttachmentPopUP}>
            <CloseIcon style={webStyle.closeIcon}></CloseIcon>
          </IconBox>
        </DialogTitle>
        <DialogContent>
          <Grid style={webStyle.attachmentBox}>
            <Upload
              setSelectedFile={this.handleDetailsDocumentUpload}
              setFileSizeError={this.setFileSizeError}
            />
          </Grid>
        </DialogContent>
        <Grid container justifyContent="space-between">
          <Typography style={this.state.isUploadError ? webStyle.maxSizeTextError : webStyle.maxSizeText}>{this.state.fileSizeText}</Typography>
          <Grid item lg={6} md={6} sm={6} xs={12}>           
            {
              this.state.uploadedAPIFilesBase.map((data: any) => {
                return (
                  <Typography style={webStyle.documentNameText} key={data.file_id} align="right">
                    {data.file_name}
                  </Typography>
                )
              })
            }
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Button
            variant="outlined"
            style={webStyle.cancelButton}
            className="no_text_transform"
            onClick={this.handleCloseAttachmentPopUP}
          >
            {this.state.CancelBtnText}
          </Button>
          <Button
            data-testid='isAttachmentSet'
            variant="contained"
            style={webStyle.submitButton}
            className="no_text_transform"
            onClick={this.handleAttachmentPopUP}
          >
            {this.state.SubmitBtnText}
          </Button>
        </Grid>
      </AttachmentDialog>
    )
  };

  readMoreText = () => {
    return (
      <>
        <Typography
          data-testid='queryDetailPassEvent'
          component={"span"}
          style={webStyle.dotVal}
          aria-owns={this.state.openToolTip ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onClick={(e: any) => this.handleProductToolTip(e, this.state.query_detail?.product[0]?.name, this.state.query_detail?.product[0]?.id)}
          onMouseEnter={(e: any) => this.handleProductToolTip(e, this.state.query_detail?.product[0]?.name, this.state.query_detail?.product[0]?.id)}
          onMouseLeave={(e: any) => this.handleProductToolTip(e, this.state.query_detail?.product[0]?.name, this.state.query_detail?.product[0]?.id)}
        >...</Typography>
        <Typography
          component={"span"}
          style={webStyle.ReadMoreLabel}
          aria-owns={this.state.openToolTip ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onClick={(e: any) => this.handleProductToolTip(e, this.state.query_detail?.product[0]?.name, this.state.query_detail?.product[0]?.id)}
          onMouseEnter={(e: any) => this.handleProductToolTip(e, this.state.query_detail?.product[0]?.name, this.state.query_detail?.product[0]?.id)}
        >
          {this.state.ReadMoreText}
        </Typography>
      </>
    )
  }

  attachmentComment = () => {
    return (
      this.state.isSendComment && (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <InputField
            data-testid="onChangeComment"
            id="ticketId"
            variant="outlined"
            value={this.state.ticketComment}
            onChange={this.handleCommentChange}
            required
            placeholder={this.state.TypeMessagePlaceholder}
            fullWidth
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <AttachFileIcon
                    style={webStyle.attachmentIcon}
                    className="attachment_icon"
                    onClick={this.handleAttachmentPopUP}
                  >
                  </AttachFileIcon>
                  <Button
                    style={webStyle.sendButton}
                    className="no_text_transform"
                    variant="contained"
                    onClick={this.sendComment}
                    disabled={this.state.ticketComment === ""}
                  >
                    {this.state.SendButtonText}
                  </Button>
                </InputAdornment>
            }}
          />
        </Grid>
      )
    )
  }

  queryDocList = () => {
    const { classes } = this.props;
    return (
      <CustomBox>
        <NavLink to={`/ProductDetail/${this.state.query_detail?.product[0]?.id}`}>
          <Typography className={classes?.details} style={webStyle.detailsTextColor}>
            {this.state.query_detail?.query_type} -
            {this.state.query_detail?.doc_name.length === 0 ? "General" : ""}
          </Typography>
        </NavLink>
        {this.state.query_detail?.doc_name.length > 1 && (
          <>
            <Typography
              component={"span"}
              style={webStyle.dotVal}
              aria-owns={this.state.openToolTip ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onClick={(e: any) => this.handleToolTip(e, this.state.query_detail?.doc_name.map((doc: any) => doc.name).join(', '))}
              onMouseEnter={(e: any) => this.handleToolTip(e, this.state.query_detail?.doc_name.map((doc: any) => doc.name).join(', '))}
              onMouseLeave={(e: any) => this.handleToolTip(e, this.state.query_detail?.doc_name.map((doc: any) => doc.name).join(', '))}
            >...</Typography>
            <Typography
              component={"span"}
              style={webStyle.ReadMoreLabel}
              aria-owns={this.state.openToolTip ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onClick={(e: any) => this.handleToolTip(e, this.state.query_detail?.doc_name.map((doc: any) => doc.name).join(', '))}
              onMouseEnter={(e: any) => this.handleToolTip(e, this.state.query_detail?.doc_name.map((doc: any) => doc.name).join(', '))}
            >
              {this.state.ReadMoreText}
            </Typography>
          </>
        )}
      </CustomBox>
    )
  }

  DetailsPopOver = () => {
    return (
      <>
        <ChemicalDetailPopover
          data-testid='ChemicalDetailPopover'
          id="mouse-over-popover"
          open={this.state.openToolTip}
          anchorEl={this.state.anchorEl}
          disableRestoreFocus
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={(e: any) => this.handleToolTip(e, '')}
        >
          {this.ChemicalNameToolTip()}
        </ChemicalDetailPopover>
        <ChemicalDetailPopover
          data-testid='ChemicalDetail'
          id="mouse-over-popover"
          open={this.state.openProductToolTip}
          anchorEl={this.state.anchorEProduct}
          disableRestoreFocus
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={(e: any) => this.handleProductToolTip(e, [], [])}
        >
          {this.ProductNameList()}
        </ChemicalDetailPopover>
      </>
    )
  }

  leaveCommentButton = () => {
    return (
      (this.state.isSendComment || this.state.query_detail?.view_reply === this.state.LeavecommentBtnText) && (
        <Button
          style={webStyle.cardButton}
          className="no_text_transform"
          variant="contained"
          onClick={this.handleSendComment}
        >
          {this.state.LeavecommentBtnText}
        </Button>
      )
    )
  }
  // Customizable Area End

  render() {
    const { classes } = this.props;
    return (
      <>
        <AppHeader navigation={this.props.navigation} />
        <Loader loading={this.state.is_loading} />
        <Grid className={classes?.mainContainer}>
          <Grid container alignItems="center" justifyContent="space-between" style={webStyle.gridsPadding}>
            <Grid item style={webStyle.gridsPadding}>
              <NavLink to={`/MyQueries`} style={webStyle.headerLink} data-testid="Backoption">
                <KeyboardBackspaceIcon
                  style={webStyle.queryDetailsBackButton}
                  fontSize="medium"
                />
                <Typography style={webStyle.detailTitle} data-testid="headerTitle">
                    {this.state.requestDetailsText}
                </Typography>
              </NavLink>
            </Grid>
            <Grid item>
            {
              <ActionButtons onClick={this.handleTicketDetailsClick} className={classes?.ticketDetail}>
                {this.state.ticketdetailsText}
              </ActionButtons>
            }
            {(this.state.query_detail?.query_type !== this.state.SignupRequestText && this.state.query_detail?.status === "Closed" && !this.state.query_detail?.is_reopened && !this.state.is_reopened) && (
              <ActionButtons data-testid="reopenBtn" onClick={this.handleTicketReopenTicket} className={classes?.ticketDetail}>
                {this.state.reOpenTicketText}
              </ActionButtons>
            )}
            </Grid>
          </Grid>

          <Grid item container className={classes?.resGrid} style={webStyle.theDetails}>
            <Grid item lg={2} style={webStyle.detailsRow1}>
              <Grid className={classes?.headings}>
               {this.state.TicketIDText}
              </Grid>
              <Grid style={webStyle.gridsPadding}>
                <Typography className={classes?.details}>
                  {this.state.query_detail?.ticket_number || '-'}
                </Typography>
              </Grid>
            </Grid>

            <Grid item lg={2} style={webStyle.detailsRow1}>
              <Grid className={classes?.headings}>
                {this.state.productNameLabel}
              </Grid>
              {this?.state?.query_detail?.product !== null ?
                <Grid className="product_name_link" style={webStyle.gridsPadding}>
                  <Typography className={classes?.details2}>
                    {
                      this.state.query_detail?.query_type === this.state.ConnectWithUsText ?
                        <>
                          <NavLink to={`/ProductDetail/${this.state.query_detail?.product[0]?.name[0]?.products}`}>
                            <Typography 
                              className={classes?.details} 
                              style={webStyle.productNameStyle}
                              onClick={this.setProductIds}
                            >
                              {this.state.query_detail?.product[0]?.name[0]?.products}
                            </Typography>
                          </NavLink>
                          {this.state.query_detail?.product[0]?.name?.length > 1 && (
                            this.readMoreText()
                          )}
                        </>
                        :
                        <NavLink to={`/ProductDetail/${this?.state?.query_detail?.product[0]?.name}`}>
                          <Typography 
                            className={classes?.details} 
                            style={webStyle.productNameStyle}
                            onClick={this.setProductBucketId}
                            >
                            {this.state.query_detail?.product[0]?.name}
                          </Typography>
                        </NavLink>
                    }
                  </Typography>
                </Grid> : "--"}
            </Grid>
            <Grid item lg={2} style={webStyle.detailsRow1}>
              <Grid className={classes?.headings}>
                {this.state.RequestTypeText}
              </Grid>
              <Grid style={webStyle.gridsPadding}>
                <Typography className={classes?.details2}>
                  {this.state.query_detail?.query_type === this.state.ProductQueryText ?
                    this.queryDocList()
                    :
                    <div>
                      {this.state.query_detail?.query_type} -
                      {this.state.query_detail?.doc_name.length === 0 ? this.state.GeneralText : this.state.DocumentText}
                    </div>
                  }
                </Typography>
              </Grid>
            </Grid>

            <Grid item lg={2} style={webStyle.detailsRow1}>
              <Grid className={classes?.headings}>
                {this.state.RequestRaisedOnText}
              </Grid>
              <Grid style={webStyle.gridsPadding}>
                <Typography className={classes?.details}>
                  {this.state.query_detail?.ticket_creation_date || '-'}
                </Typography>
              </Grid>
            </Grid>

            <Grid item lg={2} style={webStyle.detailsRow1}>
              <CustomBox>
                <Grid container style={this.state.query_detail?.status?.status !== undefined ? webStyle.cardBadge : this.CardbadgeColor()} className={classes?.cardBadgeRes}>{
                    this.state.query_detail?.status?.status !== undefined ?
                      this.state.query_detail?.status?.status :
                      this.state.query_detail?.status
                      || '-'}
                </Grid>
              </CustomBox>
            </Grid>
          </Grid>

          <Grid>
            <Grid>
              <Typography className={classes?.headings}>
                {this.state.RequestDescriptionText}
              </Typography>
            </Grid>
            <Grid>
              <Typography className={classes?.details} style={webStyle.detailsTextColor}>
                {this.state.query_detail?.query_description || '--'}
              </Typography>
            </Grid>
          </Grid>
          <Grid>
          {this.state.query_detail?.view_reply !== this.state.LeavecommentBtnText && (
            <NavLink to={`/TicketReplies/${this.ticketId}`}>
              <Button style={webStyle.cardButton} variant="contained">
                <Typography style={webStyle.repliesText} className="knowMoreButton">
                  {this.state.SeeAllRepliesText}
                </Typography>
              </Button>
            </NavLink>
          )}
          {this.leaveCommentButton()}
          </Grid>
          {this.attachmentComment()}
          {this.DetailsPopOver()}
          {this.AttachmentPopUp()}
        </Grid>
        <AppFooter isLoading={this.state.is_loading} />
      </>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const webStyle = {
  closeIcon: {
    fill: '#1A3B71', 
    cursor: 'hand'
  },
  cancelIcon: {
    fill: "rgba(4, 60, 116, 1)", 
  },
  repliesText: {
    fontSize: "16px"
  },
  detailsTextColor: {
    color: "#202124"
  },
  displayFlexText: {
    display: "flex"
  },
  productNameStyle: {
    color: "#459EFF", 
    textDecoration: 'underline'
  },
  detailsRow1: {
    width: "auto", 
    minWidth: "200px", 
    margin: "30px 0", 
    gap: "10px" 
  },
  gridsPadding: {
    paddingTop: '10px'
  },
  headerLink: {
    display: 'flex',
    alignItems: 'center',
  },
  theDetails: {
    justifyContent: "space-between",
    columnGap: '15px',
  },
  cardButton: {
    borderRadius: "8px",
    height: "40px",
    padding: '13px 13px',
    width: "auto",
    fontSize: '16px',
    fontFamily: "SFProDisplay",
    fontWeight: 500,
    margin: " 30px 0",
    boxShadow: "none",
    backgroundColor: "rgba(4, 60, 116, 1)",
    color: "#fff",
    cursor: 'pointer'
  },
  cardBadge: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: "25px",
    fontFamily: "SFProDisplay",
    fontWeight: 500,
    boxShadow: "none",
    padding: "8px 0px",
    color: "#e34b45",
    width: "140px",
    height: "40px",
    fontSize: "16px",
    backgroundColor: "#f7eeed",
  },
  cardBadge2: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    borderRadius: "25px",
    fontFamily: "SFProDisplay",
    fontWeight: 500,
    boxShadow: "none",
    padding: "8px 0px",
    color: "#79b35f",
    width: "130px",
    height: "40px",
    fontSize: "16px",
    backgroundColor: '#EBF7F3',
  },
  cardBadge3: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    borderRadius: "25px",
    fontFamily: "SFProDisplay",
    fontWeight: 500,
    boxShadow: "none",
    padding: "11px 0px",
    backgroundColor: '#FFF7E6',
    color: '#EBC74F',
    width: "150px",
    height: "50px",
    fontSize: "18px",
  },
  mainContainer: {
    fontFamily: "SFProDisplay",
    padding: "10px 130px",
    fontWeight: 400,
  },
  detailTitle: {
    color: "#212b36",
    fontFamily: "SFProDisplay",
    fontSize: "18px",
    fontWeight: 700
  },
  queryDetailsBackButton: {
    color: "rgb(4, 60, 116)",
    cursor: "pointer",
    marginRight: '15px',
  },
  dotVal: {
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.85)',
    padding: '0px 0px 0px 0px',
    marginLeft: 2
  },
  ReadMoreLabel: {
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '14px',
    color: 'rgba(0, 169, 255, 1)',
    padding: '0px 0px 0px 1px',
    textDecoration: 'none',
    marginLeft: '2px',
    marginTop: '2px',
    "&:hover": {
      textDecoration: 'underline',
    },
    cursor: 'hand',
  },
  chemicalToolTip: {
    color: 'rgba(4, 60, 116, 1)',
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '14px',
    padding: '12px 5px 10px 5px'
  },
  sendButton: {
    width: '105px',
    height: '34px',
    margin: '8px 0px 8px 24px',
    padding: '10px 42px',
    borderRadius: '7px',
    backgroundColor: '#043c74',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: 1.25,
    color: '#fff',
    boxShadow: 'none',
  },
  attachmentIcon: {
    margin: '8px 0px',
    width: '24px',
    height: '24px',
    fill: '#043c74',
    cursor: 'pointer'
  },
  dialogTitle: {
    margin: '0px 28px 24px 14px',
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: 1.33,
    color: '#043c74',
    width: '100%'
  },
  attachmentBox: {
    height: '136px',
    margin: '0px 25px 8px 39px',
    padding: '20px 162px 20px 163px',
    borderRadius: '9px',
    border: 'dashed 1px #6a74a5',
    backgroundColor: '#f5f5fa',
  },
  maxSizeText: {
    margin: '0px 73px 5px 40px',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: 1.67,
    color: '#6a74a5',
  },
  maxSizeTextError: {
    margin: '0px 73px 5px 20px',
    fontFamily: 'SFProDisplay-Regular',
    fontSize: '12px',
    lineHeight: 1.67,
    color: 'red',
  },
  documentNameText: {
    margin: '0px 50px 4px 0px',
    fontFamily: 'SFProDisplay-Regular',
    fontSize: '12px',
    lineHeight: 1.67,
    color: '#6a74a5',
  },
  cancelButton: {
    margin: '24px 12px 0 0px',
    padding: '4px 49px 4px 50px',
    borderRadius: '8px',
    border: 'solid 1px #003d78',
    backgroundColor: '#fff',
  },
  submitButton: {
    margin: '24px 0px 0 12px',
    padding: '4px 49px',
    borderRadius: '8px',
    backgroundColor: '#003d78',
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: 2.29,
    color: '#fff',
    boxShadow: 'none',
  },
};

const styles: any = () => ({
  flexWrapstyle: {
    flexWrap: 'wrap'
  },
  mainContainer: {
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    padding: "10px 100px",
    "@media (max-width: 650px)": {
      padding: "0px 15px",
    },
  },
  resGrid: {
    "@media (max-width: 425px)": {
      '& .MuiGrid-root.MuiGrid-container': {
        margin: "10px 0px !important",
      },
    },
  },
  cardBadgeRes: {
    "@media (max-width: 864px)": {
      '&.MuiGrid-root.MuiGrid-container': {
        marginTop: "0px !important",
        marginBottom: "0px !important",
      },
    }
  },
  headings: {
    fontSize: "18px",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    color: "#0f487f",
    "@media (max-width: 425px)": {
      fontSize: "18px"
    }
  },
  details: {
    fontSize: "16px",
    color: '#000',
    fontFamily: "SFProDisplay",
    fontWeight: 500,
    "@media (max-width: 425px)": {
      fontSize: "16px"
    }
  },
  details2: {
    fontSize: "16px",
    color: '#000',
    fontFamily: "SFProDisplay",
    fontWeight: 500,
    flexWrap: 'wrap',
    display: 'flex',
    "@media (max-width: 425px)": {
      fontSize: "16px"
    }
  },
  cardButton: {
    borderRadius: "8px",
    height: "40px",
    width: "160px",
    fontFamily: "SFProDisplay",
    fontWeight: 500,
    margin: " 30px 0",
    boxShadow: "none",
    backgroundColor: "rgba(4, 60, 116, 1)",
    color: "#fff",
    "@media (max-width: 425px)": {
      height: "40px",
      width: "180px",
    }
  },
})

const ActionButtons : any = withStyles({
  root: {
    background: 'rgba(4, 60, 116, 1)',
    height: '34px',
    width: '125px',
    color: 'rgba(255, 255, 255, 1)',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '15px',
    textTransform: 'none',
    borderRadius: '8px',
    marginLeft: '15px',
    marginBottom: '15px',
    "&:hover": {
      background: 'rgba(4, 60, 116, 1)',
    },
  }
})(Button);

const ChemicalDetailPopover : any = withStyles({
  paper: {
    color: 'rgba(4, 60, 116, 1)',
    fontFamily: 'SFProDisplay',
    fontWeight: 500,
    fontSize: '16px',
    backgroundColor: "rgba(255, 255, 255, 1)",
    boxShadow: "3px 4px 25px 2px lightGray",
    minWidth: 300,
    maxWidth: 300,
    borderRadius: 8,
    padding: '5px 10px',
    overflowX: "unset",
    overflowY: "unset",
    "&::before": {
      backgroundColor: "rgba(255, 255, 255, 1)",
      content: '""',
      display: "block",
      position: "absolute",
      width: 14,
      height: 14,
      top: -6,
      transform: "rotate(45deg)",
      left: "calc(50% - 10px)",
    },
    "@media (max-width: 980px)": {
      width: '68%',
    }
  }
})(Popover);

const InputField : any = withStyles({
  root: {
    margin: '48px 0px 78px 0px',
    "& ::placeholder": {
      fontSize: '14px',
      lineHeight: 1.25,
      color: '#444',
      fontFamily: 'SFProDisplay',
      fontWeight: 400,
      opacity: 1,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: '#dae0f1',
    },
    "& .MuiInputBase-input": {
      height: '15px',
    },
    "& .MuiOutlinedInput-input": {
      padding: `15px 16px !important`
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: "SFProDisplay",
      fontWeight: 400,
      backgroundColor: "#f5f5fa",
      fontSize: "14px",
      "& fieldset": {
        height: '49px',
        borderColor: '#dae0f1',
        borderRadius: "8px",
        borderWidth: 1,
      },
      ".Mui-focused": {
        borderColor: '#dae0f1',
      },
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px !important",
    },
    "& .MuiInputBase-input.Mui-disabled": {
      background: "#F1F4FA",
      borderRadius: "8px",
      height: 13,
      color: 'rgba(106, 116, 165, 1)',
      fontSize: '16px',
      fontFamily: 'SFProDisplay',
      fontWeight: 400,
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: 'rgba(185, 187, 197, 1)',
      borderWidth: '1px'
    },
    "& .MuiIconButton-root": {
      paddingLeft: '0px'
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: '0px'
    },
    "& .MuiSvgIcon-root": {
      width: '30px',
      height: '40px',
    },
  },
})(TextField);

const AttachmentDialog : any = withStyles({
  root: {
    "& .MuiDialogTitle-root": {
      padding: '18px 0px 0px 0px'
    },
    "& .MuiDialog-paperScrollPaper": {
      padding: '16px 16px 35px 20px',
      borderRadius: '12px',
    },
    "& .MuiDialogContent-root": {
      padding: '0px 20px 0px 0px',
    },
    "& .MuiDialog-paperWidthSm": {
      minWidth: '575px',
    },
    "& .MuiDialogActions-root": {
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px',
      paddingBottom: '30px',
    },
    "& .WithStyles(ForwardRef(Button))-root-10": {
      "@media (maxWidth: 600px)": {
        width: 600
      }
    },
    "& .MuiTypography-h6": {
      display: 'flex',
      justifyContent: 'space-between',
    }
  }
})(Dialog);

const IconBox : any = withStyles({
  root: {
    background: '#DAE0EC',
    height: '24px',
    width: '24px',
    borderRadius: '50%',
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 2px 0px 2px',
    marginTop: '-19px',
    "& .MuiSvgIcon-root": {
      height: '16px',
      width: '16px'
    },
  }
})(Box);

const CustomBox : any = withStyles({
  root: {
  }
})(Box);

export default withStyles(styles)(QueryDetails)
// Customizable Area End
