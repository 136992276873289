import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import { errorNotification } from '../../../components/src/common'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  emailSchema: any;
  showSuccessPopUp: any;
  showPasswordResetPopUp: any;
  sendEmailOk: any;
  sendEmailOk2: any;
  phoneSchema: any;
  otpSchema: any;
  passwordSchema: any;
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  phoneValue: any;
  countryCodeSelected: any;
  showPassword: any;
  confirmShowPassword: any;
  token: any;
  enablePasswordField: boolean;
  btnConfirmPasswordShowHide: boolean;
  resetPassword: any;
  popUpText: string;
  loginText: string;
  resetPasswordHeader: string;
  subHeaderText: string;
  passwordLabelText: string;
  passwordPlaceholderText: string;
  confirmPasswordLabel: string;
  confirmPlaceholderText: string;
  resetButtonText: string;
  htmlForPasswordInput: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
const phoneRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&]).{8,}$/;
const passwordInvisibleImage = require("../assets/ic_password_invisible.png");
const passwordVisibleImage = require("../assets/ic_password_visible.png");
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;
  requestEmailOtpCallId: any;
  requestPhoneOtpCallId: any;
  requestChangePasswordCallId: any;
  requestGoToConfirmationCallId: any;
  otpToken: any;
  isChangePassword: boolean;

  //Properties from config
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  forthLabelText: string = configJSON.forthLabelText;
  fifthLabelText: string = configJSON.fifthLabelText;
  sixthLabelText: string = configJSON.sixthLabelText;
  firstInputAutoCompleteType: any = configJSON.firstInputAutoCompleteType;
  firstInputKeyboardStyle: any = configJSON.firstInputKeyboardStyle;
  firstInputPlaceholder: string = configJSON.firstInputPlaceholder;
  firstInputErrorColor: any = configJSON.firstInputErrorColor;
  buttonTextIsNext: string = configJSON.buttonTextIsNext;
  buttonColorForNextButton: any = configJSON.buttonColorForNextButton;
  secondInputAutoCompleteType: any = configJSON.secondInputAutoCompleteType;
  secondInputKeyboardType: any = configJSON.secondInputKeyboardType;
  secondInputPlaceholder: string = configJSON.secondInputPlaceholder;
  secondInputErrorColor: any = configJSON.secondInputErrorColor;
  thirdInputPlaceholder: string = configJSON.thirdInputPlaceholder;
  thirdInputErrorColor: any = configJSON.thirdInputErrorColor;
  buttonTitleIsSMSPhoneAccount: string =
    configJSON.buttonTitleIsSMSPhoneAccount;
  buttonTitleIsEmailAccount: string = configJSON.buttonTitleIsEmailAccount;
  labelTextIsPleaseEnterYourNewPassword: string =
    configJSON.labelTextIsPleaseEnterYourNewPassword;
  labelTextIsYourPasswordHasBeenSuccessfullyChanged: string =
    configJSON.labelTextIsYourPasswordHasBeenSuccessfullyChanged;
  placeholderIsPassword: string = configJSON.placeholderIsPassword;
  passwordVisibleImage: any = passwordVisibleImage;
  passwordInvisibleImage: any = passwordInvisibleImage;
  placeholderIsReTypePassword: string = configJSON.placeholderIsReTypePassword;
  buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
  buttonColorForOkButton: any = configJSON.buttonColorForOkButton;
  countryCodeSelectorPlaceholder: string =
    configJSON.countryCodeSelectorPlaceholder;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.closePopUp = this.closePopUp.bind(this);
    this.goToConfirmationAfterPasswordChange = this.goToConfirmationAfterPasswordChange.bind(this);
    this.goToChangePasswordAfterOtp = this.goToChangePasswordAfterOtp.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.goToOtpAfterPhoneValidation = this.goToOtpAfterPhoneValidation.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleConfirmShowPassword = this.handleConfirmShowPassword.bind(this);
    this.goToLogin = this.goToLogin.bind(this);
    this.goToHome = this.goToHome.bind(this);
    this.goToOtpAfterEmailValidation = this.goToOtpAfterEmailValidation.bind(this);
    this.startForgotPassword = this.startForgotPassword.bind(this);
    this.returnBoolenValue = this.returnBoolenValue.bind(this);
    this.handleConfirmShowPassword = this.handleConfirmShowPassword.bind(this);
    this.validationRulesRequest = this.validationRulesRequest.bind(this);
    this.setPasswordRules = this.setPasswordRules.bind(this);
    this.setInitialConditions = this.setInitialConditions.bind(this);
    this.setConfirmPasswordCall = this.setConfirmPasswordCall.bind(this);
    this.setTokenData = this.setTokenData.bind(this);
    this.setCountryCode = this.setCountryCode.bind(this);
    //email schema
    let emailSchema = {
      email: Yup.string().email(configJSON.pleaseEnterAValidEmail).required(configJSON.emailIsRequired),
      aggrement: Yup.boolean().required("this check box require").oneOf([true], 'This field must be checked')
    };

    //phone schema
    let phoneSchema = {
      phone: Yup.string()
        .matches(phoneRegExp, configJSON.phoneNumberIsNotValid)
        .required(configJSON.phoneNumberIsRequired)
    };

    //otpSchema
    let otpSchema = {
      otpCode: Yup.number()
        .min(4)
        .required(configJSON.otpCodeIsRequired)
    };

    //passwordSchema
    let passwordSchema = {
      password: Yup.string()
        .required(configJSON.pleaseEnterAPassword)
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&]).{8,}$/,
          configJSON.passwordRules
        ),
      confirmPassword: Yup.string()
        .required(configJSON.pleaseConfirmYourPassword)
        .when("password", {
          is: val => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            configJSON.passwordsMustMatch
          )
        })
    };

    this.state = {
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      emailValue: "",
      phoneValue: "",
      countryCodeSelected: "",
      showPassword: false,
      confirmShowPassword: false,
      passwordRules: configJSON.passwordRules,
      emailSchema: emailSchema,
      showSuccessPopUp: false,
      showPasswordResetPopUp: false,
      sendEmailOk: false,
      sendEmailOk2: false,
      phoneSchema: phoneSchema,
      otpSchema: otpSchema,
      passwordSchema: passwordSchema,
      token: "",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      resetPassword: "",
      popUpText: configJSON.popUpText,
      loginText: configJSON.loginText,
      resetPasswordHeader: configJSON.resetPasswordHeader,
      subHeaderText: configJSON.subHeaderText,
      passwordLabelText: configJSON.passwordLabelText,
      passwordPlaceholderText: configJSON.passwordPlaceholderText,
      confirmPasswordLabel: configJSON.confirmPasswordLabel,
      confirmPlaceholderText: configJSON.confirmPlaceholderText,
      resetButtonText: configJSON.resetButtonText,
      htmlForPasswordInput: configJSON.htmlForPasswordInput
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.validationRulesRequest();
  }

  validationRulesRequest = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileValidationSettingsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    //Customizable Area Start
    if(message?.properties?.RestAPIResponceSuccessMessage?.message){	
      this.setInitialConditions();
    }	
    if (message?.properties?.RestAPIResponceSuccessMessage?.errors) {
      this.setSuccessResponse(message);
    }
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.setDetails(otpAuthTkn, message);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.validationAPICallId && this.validationAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.validationAPIData(responseJson)
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.requestEmailOtpCallId !== null && this.requestEmailOtpCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.requestEmailOtpCall(message);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.requestPhoneOtpCallId !== null && this.requestPhoneOtpCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.requestPhoneOtpCall(responseJson,message);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.requestGoToConfirmationCallId !== null && this.requestGoToConfirmationCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      this.setConfirmPasswordCall(responseJson, errorReponse);
    } else if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      this.setCountryCode(message);
    }
    //Customizable Area End
  }
  
  //Customizable Area Start
  validationAPIData = (responseJson: any) => {
    if (responseJson === undefined) {
      return;
    }
    if (responseJson && responseJson.data[0]) {
      this.setPasswordRules(responseJson);
    }
  }
  
  setDetails = (otpAuthTkn: any, message: any) => {
    if (otpAuthTkn && otpAuthTkn.length > 0) {
      this.setTokenData(otpAuthTkn);
    } else {
      this.setAccountType(message);
    }
  }

  setSuccessResponse = (message: any) => {
    if(message?.properties?.RestAPIResponceSuccessMessage?.errors[0]?.message.includes("is not registered")){	
      this.setState({ showSuccessPopUp: false });	
      this.setState({ showPasswordResetPopUp: false });	
    }
  }
  setInitialConditions = () => {
    this.setState({ showSuccessPopUp: true });   	
    this.setState({ showPasswordResetPopUp: true });	
    this.setState({ sendEmailOk:true})
  }

  setCountryCode = (message: any) => {
    let selectedCode = message.getData(getName(MessageEnum.CountyCodeDataMessage));
    if (selectedCode !== undefined) {
      this.setState({
        countryCodeSelected:
          selectedCode.indexOf("+") > 0
            ? selectedCode.split("+")[1]
            : selectedCode
      });
    }
  }

  setConfirmPasswordCall = (responseJson: any, errorReponse: any) => {
      if (responseJson !== undefined && responseJson.data) {
        this.setState({
          accountStatus: "Confirmation",
          showPasswordResetPopUp: true
        });
      } else if (responseJson !== undefined && responseJson.errors) {
        errorNotification(responseJson.errors[0].message);
        this.setState({showPasswordResetPopUp: false})
        this.props.navigation.navigate("ForgotPassword");
      } else {
        this.setState({showPasswordResetPopUp: false})
        errorNotification(responseJson.message);
      }
    }
    requestPhoneOtpCall = (responseJson: any, message: any) => {
    if (responseJson !== undefined && responseJson.meta && responseJson.meta.token) {
      this.otpToken = responseJson.meta.token;
      this.setState({ token: this.otpToken });
      const msg: Message = new Message(getName(MessageEnum.NavigationMobilePhoneOTPMessage));
      msg.addData(getName(MessageEnum.AuthTokenDataMessage), this.state.token);
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      msg.addData(getName(MessageEnum.AuthTokenPhoneNumberMessage), this.state.phoneValue);
      msg.addData(getName(MessageEnum.EnterOTPAsForgotPasswordMessage), true);
      this.send(msg);
    } else {
      let errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  requestEmailOtpCall = (message: any) => {
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson !== undefined && responseJson.meta && responseJson.meta.token) {
      this.otpToken = responseJson.meta.token;
      this.setState({ token: this.otpToken });
      const msg: Message = new Message(getName(MessageEnum.NavigationMobilePhoneOTPMessage));
      msg.addData(getName(MessageEnum.AuthTokenDataMessage), this.state.token);
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      msg.addData(getName(MessageEnum.AuthTokenEmailMessage),this.state.emailValue);
      msg.addData(getName(MessageEnum.EnterOTPAsForgotPasswordMessage), true);
      this.send(msg); 
    } else if (responseJson && responseJson.errors) {
        errorNotification(responseJson.errors[0].message);
    }
  }

  setPasswordRules = (responseJson: any) => {
      const passRegex = RegExp(responseJson?.data[0]?.password_validation_regexp);
      const emailRegex = RegExp(responseJson?.data[0]?.email_validation_regexp);
      this.setState({
        //email schema
        emailSchema: {
          email: Yup.string()
            .email(configJSON.pleaseEnterAValidEmail)
            .required(configJSON.emailIsRequired)
            .matches(emailRegex, configJSON.invalidEmailAddress)
        },
        //password schema
        passwordSchema: {
          password: Yup.string()
            .required(configJSON.pleaseEnterAPassword)
            .matches(passRegex, configJSON.passwordRules),
        confirmPassword: Yup.string()
            .required(configJSON.pleaseConfirmYourPassword)
            .when("password", {
            is: val => (val && val.length > 0 ? true : false),
              then: Yup.string().oneOf(
                [Yup.ref("password")],
                  configJSON.passwordsMustMatch
            )
          })
        },
    });
  };

  setAccountType = (message: any) => {
    const accountType = message.getData(
      getName(MessageEnum.NavigationForgotPasswordPageInfo)
    );
    if (accountType) {
      this.startForgotPassword(accountType);
    }
  }

  setTokenData = (otpAuthTkn: any) => {
    this.setState({ token: otpAuthTkn });
    if (this.isChangePassword) {
      this.setState({ accountStatus: "ChangePassword" });
    }
    this.otpToken = this.state.token;
  }

  handleChange(e: any) {
    this.setState({
      resetPassword: e.target.value
    })
  }
  handleConfirmShowPassword = () => {
    this.setState({
      confirmShowPassword: !this.state.confirmShowPassword,
    });
  };
  

  returnBoolenValue = (condition:any) => {
    if(condition){
        return true;
    }else{
        return false
    }
}
  startForgotPassword(accountType: string) {
    this.setState({
      accountStatus: accountType === "sms" ? "EnterPhone" : "EnterEmail"
    });
  }

  goToOtpAfterEmailValidation(values: { accountType: string; email: string }) {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestEmailOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({
      emailValue: values.email ? values.email : ""
    });

    const data = {
      type: values.accountType ? values.accountType : "email_account",
      attributes: {
        email: values.email ? values.email : ""
      }
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }

  goToOtpAfterPhoneValidation(values: { phone: string }) {
    if (
      !this.state.countryCodeSelected ||
      this.state.countryCodeSelected.length === 0
    ) {
      this.showAlert(
        configJSON.goToOtpAfterPhoneValidationErrorTitle,
        configJSON.goToOtpAfterPhoneValidationErrorBody
      );
      return;
    }
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestPhoneOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({
      phoneValue:
        this.state.countryCodeSelected && values.phone
          ? this.state.countryCodeSelected + values.phone
          : ""
    });

    const data = {
      type: "sms_account",
      attributes: {
        full_phone_number: this.state.phoneValue
      }
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToChangePasswordAfterOtp(values: { otpCode: string }) {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestChangePasswordCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryConfirmOtpAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      otp_code: values.otpCode ? values.otpCode : ""
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToConfirmationAfterPasswordChange(values: {
    password: any;
    confirmPassword: any;
  }) {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.requestGoToConfirmationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryChangePasswordAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    const tokenVal= this.props.navigation.getParam('token')
    const data = {
      attributes: {
        token: tokenVal,
        new_password: values.password,
        confirm_password: values.password,
      }
    };
    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    if (values.password === values.confirmPassword) {
      setTimeout(() => {
        this.setState({
          sendEmailOk2: true
        })
      }, 1000);
    }
  }

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  } 

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  closePopUp = () => {
    this.setState({sendEmailOk2: false})
  }
  //Customizable Area End
}
