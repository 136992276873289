import React from "react";
import {
  Grid,
  Typography,
  TextField,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Button,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { LoginImage, BioLogo, Background } from "./assets";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { withStyles } from "@material-ui/core/styles";
import { Dimensions } from "react-native";
import LoginScreenWebController, { Props } from "./LoginScreenControllerBioSpace.web";

export default class LoginScreenBioSpaceWeb extends LoginScreenWebController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    //@ts-ignore
    const { navigation } = this.props;
    return (
      <>
        <Grid container
          style={{
            overflow: "auto",
          }}
        >
          <LeftGrid item lg={5} md={5} sm={5} xs={12}
            style={{
              position: 'relative',
              marginRight: '7%',
            }}
          >
            <img src={LoginImage} style={webStyle.leftImage} className="img" />
            <WelcomeCard>
              <Typography style={webStyle.welcomeText}>
                {this.state.welcomeText}
              </Typography>
              <Typography style={webStyle.startedText}>
                {this.state.startedText}
              </Typography>
              <Typography style={webStyle.serviseText}>
                {this.state.serviseText}
              </Typography>
            </WelcomeCard>
          </LeftGrid>
          <RightBox item lg={6} md={6} sm={6} xs={12}>
            <RightGrid item lg={10} md={10} sm={10} xs={12}>
              <img
                src={BioLogo}
                style={webStyle.LogoImage}
              />
              <Typography style={webStyle.Header}>
                {this.state.loginHeader}
              </Typography>
              <Typography style={webStyle.subHeader}>
                {this.state.loginSubHeading}
              </Typography>
              <form onSubmit={this.doEmailLoginWeb} data-testid="formsubmit">
                <InputLabel
                  htmlFor="txtInputEmail"
                  style={webStyle.EmailinputLabel}
                >
                  Email address
                </InputLabel>
                <InputField
                  id="txtInputEmail"
                  data-testid='inputemail'
                  variant="outlined"
                  placeholder="example@gmail.com"
                  required
                  onChange={this.handleChange("email").bind(this)}
                  value={this.state.email}
                  fullWidth
                />
                <InputLabel
                  htmlFor="passwordInput"
                  style={webStyle.PassinputLabel}
                >
                  Password
                </InputLabel>
                <PasswordField
                  id="passwordInput"
                  data-testid="passwordInput"
                  variant="outlined"
                  required
                  value={this.state.password}
                  onChange={this.handleChange("password").bind(this)}
                  placeholder="• • • • • • • • • • • • •"
                  fullWidth
                  type={this.state.showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          edge="end"
                          style={{ color: 'lightgray' }}
                          data-testid="passwordt"
                        >
                          {this.state.showPassword ? (
                            <VisibilityOffOutlinedIcon />
                          ) : (
                            <VisibilityOutlinedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Grid container justifyContent="space-between" style={{ padding: '10px 0px', marginLeft: '2px' }}>
                  <CheckBoxLabel
                    control={
                      <Checkbox
                        color="default"
                        disableRipple
                        checked={this.CustomCheckBoxProps.isChecked}
                        onChange={this.btnRememberMeProps.onPress}
                        checkedIcon={<span style={webStyle.checkedIcon} />}
                        icon={<span style={webStyle.icon} />}
                        inputProps={{ "aria-label": "decorative checkbox" }}
                      />
                    }
                    label="Remember Password"
                  />
                  <Typography
                    onClick={() => {
                      navigation.navigate("ForgotPassword");
                    }}
                    style={webStyle.PassText}
                    data-testid="forgetpswdbtn"
                  >
                    {this.state.forgotPasslabel}
                  </Typography>
                </Grid>
                <Buttons type="submit" variant="contained">
                  Login
                </Buttons>
              </form>
            </RightGrid>
          </RightBox>
        </Grid>
      </>
    );
  }
}

// Customizable Area Start
const webStyle = {
  leftImage: {
    height: Dimensions.get("window").height,
    width: "100%",
    marginTop: '0px',
    marginLeft: '-2px'
  },
  LogoImage: {
    height: '58px',
    width: '160px',
    paddingTop: '10px',
    paddingBottom: '40px',
  },
  Header: {
    fontWeight: 500,
    color: "rgba(4, 60, 116, 1)",
    fontFamily: "SFProDisplay",
    // fontSize: '24px',
    fontSize: '30px',
    paddingBottom: '5px',
  },
  subHeader: {
    fontWeight: 500,
    fontFamily: "SFProDisplay",
    // fontSize: '14px',
    fontSize: '16px',
    color: "rgb(4, 60, 116)",
    marginBottom: "10px",
  },
  EmailinputLabel: {
    // fontSize: '16px',
    fontSize: '18px',
    // fontFamily: "SFProDisplay",
    fontFamily: 'DMSans-Regular',
    // fontWeight: 500,
    color: "rgba(58, 63, 99, 1)",
    paddingBottom: "5px",
    paddingTop: "10px",
  },
  PassinputLabel: {
    // fontSize: '16px',
    fontSize: '18px',
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    color: "rgba(58, 63, 99, 1)",
    paddingBottom: "5px",
    paddingTop: "10px",
  },
  PassText: {
    color: "rgba(69, 158, 255, 1)",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    // fontSize: '16px',
    fontSize: "18px",
    paddingTop: "4px",
    cursor: 'pointer',
  },
  AccountText: {
    color: "rgba(58, 63, 99, 1)",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    // fontSize: "16px",
    fontSize: "18px",
    padding: "0px 10px 10px 10px",
  },
  singUp: {
    color: "rgba(69, 158, 255, 1)",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    // fontSize: "16px",
    fontSize: "18px",
    padding: "0px 10px 10px 10px",
    cursor: 'pointer',
  },
  icon: {
    borderRadius: 3,
    width: 15,
    height: 15,
    backgroundColor: "white",
    border: "1px solid rgba(58, 63, 99, 1)",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    color: 'rgba(58, 63, 99, 1)',
    "input:hover ~ &": {
      backgroundColor: "white",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  checkedIcon: {
    borderRadius: 3,
    border: "1px solid rgba(58, 63, 99, 1)",
    width: 15,
    height: 15,
    backgroundColor: "white",
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='rgba(58, 63, 99, 1)'/%3E%3C/svg%3E\")",
    "input:hover ~ &": {},
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  welcomeText: {
    color: 'rgba(255, 255, 255, 1)',
    fontWeight: 500,
    fontFamily: 'SFProDisplay',
    fontSize: '24px',
    // fontSize: '48px',
    marginTop: '4px',
  },
  startedText: {
    color: 'rgba(255, 255, 255, 1)',
    fontWeight: 500,
    fontFamily: 'SFProDisplay',
    fontSize: '20px',
    // fontSize: '24px',
    paddingBottom: '5px',
  },
  serviseText: {
    color: 'rgba(255, 255, 255, 1)',
    fontWeight: 500,
    fontFamily: 'SFProDisplay',
    fontSize: '14px',
    // fontSize: '24px',
    marginBottom: '4px'
  }
};

const PasswordField:any = withStyles({
  root: {
    background: "inherite",
    margin: "5px 0px 5px 0px",
    "& ::placeholder": {
      color: 'rgba(214, 214, 214, 1)',
      opacity: 1,
      fontSize: '20px',
      // fontWeight: "600",
      fontFamily: 'SFProDisplay',
      fontWeight: 400,
    },
    "& .MuiInputBase-input": {
      height: '15px',
    },
    "& .MuiOutlinedInput-input": {
      padding: '14px 14px'
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: "SFProDisplay",
      fontSize: '16px',
      fontWeight: 400,
      "& fieldset": {
        height: '45px',
        borderColor: "rgba(214, 214, 214, 1)",
        borderRadius: '8px',
        borderWidth: '1px',
      },
    },
  },
})(TextField);

const InputField:any = withStyles({
  root: {
    background: "inherite",
    margin: "5px 0px 5px 0px",
    "& ::placeholder": {
      color: 'rgba(214, 214, 214, 1)',
      opacity: 1,
      // fontSize: '16px',
      fontSize: '18px',
      fontFamily: 'DMSans-Bold'
    },
    "& .MuiInputBase-input": {
      height: '15px',
    },
    "& .MuiOutlinedInput-input": {
      padding: '14px 14px'
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: "SFProDisplay",
      fontSize: '16px',
      fontWeight: 400,
      "& fieldset": {
        height: '45px',
        borderColor: "rgba(214, 214, 214, 1)",
        borderRadius: '8px',
        borderWidth: '1px',
      },
    },
  },
})(TextField);

const Buttons:any = withStyles({
  root: {
    width: "100%",
    color: "rgba(255, 255, 255, 1)",
    backgroundColor: "rgba(69, 158, 255, 1)",
    opacity: 1,
    boxShadow: "none",
    // height: "50px",
    height: "56px",
    borderRadius: "5px",
    // fontFamily: "SFProDisplay-Regular",
    fontFamily: 'DMSans-Bold',
    // fontSize: '22px',
    fontSize: '26px',
    // fontWeight: 700,
    marginTop: '10px',
    textTransform: "none",
    "&:hover": {
      backgroundColor: "rgba(69, 158, 255, 1)",
      boxShadow: "none",
    },
  },
})(Button);

const CheckBoxLabel:any = withStyles({
  root: {
    "& .MuiTypography-body1": {
      fontFamily: "SFProDisplay",
      fontWeight: 400,
      fontSize: "18px",
      color: "rgba(58, 63, 99, 1)",
    },
  },
})(FormControlLabel);

const WelcomeCard:any = withStyles({
  root: {
    position: 'absolute',
    top: '75%',
    left: '45%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
  }
})(Grid);

const LeftGrid:any = withStyles({
  root: {
    "@media (max-width: 600px)": {
      marginRight: '0px',
    },
    "& .img": {
      "@media (max-height: 600px)": {
        height: `100% !important`
      }
    }
  }
})(Grid)

const RightBox:any = withStyles({
  root: {
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '10px 5px 10px 0px',
  }
})(Grid);

const RightGrid:any = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "center",
    paddingRight: '0px',
    paddingLeft: '70px',
    "@media (max-width: 600px)": {
      padding: `0px 20px 0px 20px !important`,
    },
  }
})(Grid)

// Customizable Area End
