import React from "react";

//Customizable Area Start
import {
    View,
    StyleSheet,
    Text,
} from "react-native";
import { Formik } from "formik";
import * as Yup from "yup";
import ForgotPasswordController, { Props } from "./ForgotPasswordController2";
import { NavLink } from 'react-router-dom'
// debug
import CloseIcon from '@material-ui/icons/Close';
import "./forgotPasswordStyle.css";
import { withStyles } from "@material-ui/core/styles"; 
import {
    Box,
    Grid,
    Button,
    TextField,
    InputLabel,
    Typography,
    FormControlLabel,
    Checkbox,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Container,
} from "@material-ui/core";
import {
    bioconLogo,
    backgroundImg,
} from "./assets";
import Loader from "../../../components/src/Loader.web";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
//Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        //Customizable Area Start
        //Customizable Area End
    }


    TermsPopUp() {
        return (
            // @ts-ignore
            <TermsDialog open={true} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle>
                {/* @ts-ignore */}
                    <IconBox>
                        <CloseIcon data-testid="CloseIcon"
                          onClick={ () => {this.setState({sendEmailOk: false})}}
                          style={{ fill: '#1A3B71', cursor: 'hand' }}>
                        </CloseIcon>
                    </IconBox>
                </DialogTitle>
                <DialogContent>
                    <Typography
                        style={{
                            margin: "0px 0 38px",
                            fontFamily: "SFProDisplay",
                            fontWeight: 500,
                            fontSize: "18px",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "0.4px",
                            color: "#043c74",
                            textAlign: "center",
                          }}
                    >
                        The link to generate new password has been sent to your registered email-id. 
                    </Typography>
                </DialogContent>
                <DialogActions>
                <NavLink to="/login">
                {/* @ts-ignore */}
                    <AcceptButton 
                    data-testid="acceptButton"
                    onClick={ () => {
                        this.setState({sendEmailOk: false})
                    }} 
                    style={{
                        fontFamily: "SFProDisplay",
                        fontWeight: 400,
                    }}
                    >
                        Ok
                    </AcceptButton>
                    </NavLink>
                </DialogActions>

            </TermsDialog>
        )
    }

    render() {
        return (
                <div className = "testingDiv" style={{overflow: "hidden"}}>
                    {/* @ts-ignore */}
                    <MainContainer container>
                        <Grid item lg={6} md={6} sm={false} xs={false} style={{position: 'relative'}}>
                            {/* @ts-ignore */}
                            <Box sx={webStyle.backImageSpace} className="leftImg">
                            {/* {
                               this.state.imageLoading && (
                                <div style={{position: "fixed",left: "25%",top: "50%"}}>
                                    <CircularProgress style={{ height: '50px', width: '50px' }} />
                                </div>
                                )
                            } */}
                                <img
                                    style={webStyle.signupImage}
                                    src={this.state.loginImage}
                                    alt=""
                                    className="signupImg"
                                />
                            </Box>
                        </Grid>
                        {/* @ts-ignore */}
                        <RightBox item lg={6} md={6} sm={6} xs={12} style={webStyle.companyCenterBox}>
                            <Container style={{ padding: 0 }}>
                            {/* @ts-ignore */}
                                <RightGrid item lg={12} md={12} sm={12} xs={12}>
                                    <Grid container style={{ maxWidth: '100%' }}>
                                        <img
                                            src={bioconLogo}
                                            style={webStyle.bioconLogo}
                                            onClick={this.goToHome}
                                        />
                                    </Grid>
                                    <NavLink to={`/login`}>
                                        <KeyboardBackspaceIcon
                                            style={{
                                                ...webStyle.cardButtonBack,
                                            }}
                                            fontSize="large"
                                        />
                                    </NavLink>
                                    <Typography style={webStyle.Header}>
                                        Forgot Password
                                    </Typography>
                                    <Typography style={webStyle.subHeader}>
                                        Please share your details to reset password
                                    </Typography>
                                    {this.state.sendEmailOk && this.state.showSuccessPopUp && this.TermsPopUp()}
                <Formik
                  data-testid='FormikForm'
                  initialValues={{ accountType: "email_account", email: "" ,aggrement:false}}
                  validationSchema={Yup.object().shape(this.state.emailSchema)}
                  validateOnMount={true}
                  validateOnChange={true}
                  onSubmit={(values, actions) => {
                      this.goToOtpAfterEmailValidation(values);
                      actions.setSubmitting(false);
                    }}
                    >
                  {({
                      handleChange,
                      handleSubmit,
                      errors,
                      touched
                    }) => (
                        // @ts-ignore
                        <View>
                        {/* @ts-ignore */}
                      <View
                        style={
                            this.isPlatformWeb()
                            ? styles.webInput
                            : styles.mobileInput
                        }
                        >
                         <InputLabel htmlFor="emailInput" style={webStyle.inputLabel}>Registered Email ID<span>*</span></InputLabel>
                                            {/* @ts-ignore */}
                        <InputField
                        id="emailInput"
                        data-testid='emailinput'
                        type="email"
                        variant="outlined"
                        required
                        placeholder="Enter your email id"
                        fullWidth
                        style={webStyle.inputEmail}
                        onChange={handleChange("email")}
                        />
                        { touched.email && errors.email ? (
                            // @ts-ignore
                          <Text style={styles.errorStyle}>{errors.email}</Text>
                          ) : null}
                      </View>
                      <Grid container>
                                                    <Grid item xs={12} >
                                                        <CheckBoxLabel
                                                                control={ 
                                                                    <Checkbox
                                                                    color="default"
                                                                    disableRipple
                                                                    checkedIcon={<span style={webStyle.captchaCheckedIcon} />}
                                                                    required
                                                                    onChange={handleChange}
                                                                    icon={<span style={webStyle.captchaIcon} />}
                                                                    inputProps={{ "aria-label": "decorative checkbox" }}
                                                                    id="aggrement"
                                                                    />
                                                                }
                                                                label="I'm not a robot"
                                                                id="aggrement"
                                                                />
                                                        </Grid>
                                                    <Grid item xs={12}>
                                                                {touched.aggrement && errors.aggrement ? (<Typography style={{color:'red',fontSize:'14px'}}>{errors.aggrement} </Typography>) : null}
                                                    </Grid>
                                                </Grid>
                      {/* @ts-ignore */}
                      <View style={{ zIndex: -1}}>
                        <button
                          type="submit"
                        data-testid="btnSubmit"
                          onClick={() => {
                              !errors.email && 
                              handleSubmit()
                        }}
                          style={{
                            maxWidth: '100%',
                            height: '45px',
                            margin: '12% 0 0px',
                            padding: '1.2% 0px 1.2% 0px',
                            borderRadius: '8px',
                            backgroundColor: '#459eff',
                            fontFamily: 'DMSans-Bold',
                            fontSize: '22px',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            textAlign: 'center',
                            color: '#fff',
                            textTransform: "none",
                            minWidth: '100%',
                            boxShadow: 'none',
                            borderWidth: "0px",
                            cursor: "pointer",
                        }}
                        >Generate Reset Link
                        </button>
                      </View>
                    </View>
                  )}
                </Formik>
                                    {/* @ts-ignore */}
                                    <Box sx={webStyle.haveAccount}>
                                        <Typography style={webStyle.AccountText}>
                                            Don't have an account?
                                        </Typography>
                                        <Typography style={webStyle.login}>
                                            <NavLink to="/signup" style={webStyle.loginlink}>
                                                Sign Up
                                            </NavLink>
                                        </Typography>
                                    </Box>
                                </RightGrid>
                            </Container>
                        </RightBox>
                    </MainContainer>
                    <Loader loading={this.state.imageLoading} />
                </div>
        );
    }
}

// Customizable Area Start
const styles = StyleSheet.create({
    button: {
        marginTop: 16,
        justifyContent: "center",
        alignItems: "center",
        width: "80%",
        zIndex: -1
    },
    webInput: {
        marginTop: 20,
        width: "100%",
        zIndex: -1
    },
    mobileInput: {
        flexDirection: "column",
        alignItems: "stretch",
        fontSize: 16,
        textAlign: "left",
        backgroundColor: "#00000000",
        marginTop: 20,
        borderWidth: 1,
        borderColor: "#767676",
        borderRadius: 2,
        includeFontPadding: true
    },
    phoneInput: {
        flex: 3,
        marginTop: 20
    },
    errorStyle: {
        color: "red",
        textAlign: "left"
    }
});

// debug


const webStyle = {
    Buttons: {
        maxWidth: '100%',
        height: '5.7%',
        margin: '3.9% 0 0px',
        padding: '1.2% 0px 1.2% 0px',
        borderRadius: '8px',
        backgroundColor: '#459eff',
        fontFamily: 'DMSans-Bold',
        fontSize: '22px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#fff',
        textTransform: "none",
        minWidth: '100%',
        boxShadow: 'none',
        // "&:hover": {
        //     backgroundColor: "rgba(69, 158, 255, 1)",
        //     boxShadow: "none",
        // },
    },
    inputPassword: {
        margin: "10px 0",
    },
    inputPassword2: {
        margin: "10px 0 50px",
    },
    backImageSpace: {
        padding: '0 1.9% 0 0',
    },
    signupImage: {
        width: '95.9%',
        height: '100vh',
    },
    cardButtonBack: {
        color: "#043c74",
        marginTop: '15px'
    },
    welcomeText: {
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '48px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#fff',
    },
    startedText: {
        margin: '1.3% 0px 6.7px 0px',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '24px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        color: '#fff',
    },
    bioconLogo: {
        width: '30%',
        height: '100%',
        margin: '0px 0px 2% 0',
        cursor: 'hand',
    },
    Header: {
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '20px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#043c74',
        margin: "15px 0 10px",
    },
    subHeader: {
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '14px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#6a74a5',
        margin: " 0  0 10px",
    },
    inputLabel: {
        margin: "0 0 10px",
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '16px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#3a3f63',
    },
    inputEmail: {
        margin: "10px 0 8px",
        height: '45px',
    },
    haveAccount: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '100%',
        marginTop: '10px'
    },
    AccountText: {
        margin: '2% 0px 0 0px',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '16px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#3a3f63',
    },
    login: {
        margin: '2% 8px 0 8px',
        fontFamily: 'SFProDisplay',
        fontSize: '16px',
        fontWeight: 400,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '-0.35px',
        color: '#459eff',
        cursor: 'hand',
    },
    loginlink: {
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '16px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '-0.35px',
        color: '#459eff',
        cursor: 'hand',
    },
    checkedIcon: {
        borderRadius: '6px',
        border: 'solid 1px #3a3f63',
        backgroundColor: '#fbfcff',
        width: 24,
        height: 24,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='black'/%3E%3C/svg%3E\")",
        "input:hover ~ &": {},
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    icon: {
        borderRadius: '6px',
        border: 'solid 1px #3a3f63',
        backgroundColor: '#fbfcff',
        width: 24,
        height: 24,
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2,
        },
        "input:hover ~ &": {
            backgroundColor: "#fbfcff",
        },
        "&:hover": {
            backgroundColor: "#fbfcff",
        },
    },
    termsText: {
        color: "rgba(58, 63, 99, 1)",
        fontFamily: "SFProDisplay",
        fontWeight: 400,
        fontSize: "16px",
        margin: '24px 0 0px 12px',
    },
    companyCenterBox: {
        display: 'flex',
        alignItems: 'center',
    },
    captchaIcon:{
        borderRadius: '6px',
        border: 'solid 1px #3a3f63',
        backgroundColor: '#fbfcff',
        width: 20,
        height: 20,
        marginLeft:'-8px',
        marginRight:'0px',
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2,
        },
        "input:hover ~ &": {
            backgroundColor: "#fbfcff",
        },
        "&:hover": {
            backgroundColor: "#fbfcff",
        },
    },
    captchaCheckedIcon: {
        borderRadius: '6px',
        border: 'solid 1px #3a3f63',
        backgroundColor: '#fbfcff',
        width: 20,
        height: 20,
        marginLeft:'-8px',
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='black'/%3E%3C/svg%3E\")",
        "input:hover ~ &": {},
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
}

const RightBox = withStyles({
    root: {
        backgroundImage: `url(${backgroundImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '98% 100%',
        margin: '1.2% 0px 1.2% 0px',
        padding: '0% 8.9% 0% 5.4%',
        display: 'flex',
        alignItems: 'center',
        "& .PrivateSwitchBase-root-14": {
            padding: '0px',
        },
        "& .compnaySelect .css-yk16xz-control": {
            maxWidth: '100%',
            borderRadius: '8px'
        },
        "& .compnaySelect .css-1pahdxg-control": {
            maxWidth: '100%',
        },
        "& .compnaySelect .css-26l3qy-menu": {
            maxWidth: '100%',
        }
    }
})(Grid);

const RightGrid = withStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        paddingRight: '0px',
    }
})(Grid);

const InputField = withStyles({
    root: {
        maxWidth: '100%',
        borderRadius: '8px',
        backgroundColor: '#fff',
        "& ::placeholder": {
            color: '#d6d6d6',
            fontSize: '16px',
            opacity: 1,
            fontFamily: 'SFProDisplay',
            fontWeight: 500,
        },
        "& .MuiInputBase-input": {
            height: '12px',
        },
        "& .MuiOutlinedInput-input": {
            padding: '3% 14px'
        },
        "& .MuiOutlinedInput-root": {
            fontWeight: 500,
            fontFamily: "SFProDisplay",
            backgroundColor: "rgba(255, 255, 255, 1)",
            fontSize: "16px",
            borderRadius: "8px",
            height: '100%',
            "& fieldset": {
                border: 'solid 1px #d6d6d6',
                borderRadius: '8px',
            },
        },
    },
})(TextField);

// debug
export const CustomGrid:any = withStyles({
    root: {
        minWidth: '40%',
        marginRight: '9px',
        "@media (min-width: 960px) and (max-width: 1510px)": {
            minWidth: `39% !important`,
            maxWidth: `39% !important`,
        },
        "@media (min-width: 380px) and (max-width: 960px)": {
            minWidth: `100% !important`,
            maxWidth: `100% !important`,
            marginBottom: '8px'
        },
        "& .css-tlfecz-indicatorContainer": {
            color: '#000000',
            paddingRight: '12px',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
        },
        "& .css-1okebmr-indicatorSeparator": {
            display: 'none',
            fontFamily: 'SFProDisplay',
            fontWeight: 700,
        },
        "& .css-1wa3eu0-placeholder": {
            display: 'flex',
            color: 'rgba(214, 214, 214, 1)',
            fontFamily: 'SFProDisplay',
            fontSize: '16px',
            fontWeight: 500,
            opacity: 1,
        },
        "& .css-1uccc91-singleValue": {
            fontFamily: 'SFProDisplay',
            fontSize: '16px',
            fontWeight: 500,
        },
        "& .css-yk16xz-control": {
            borderRadius: "8px",
            borderColor: "rgba(214, 214, 214, 1)",
            fontWeight: 500,
            fontFamily: "SFProDisplay",
            fontSize: "16px",
            width: "100%",
            marginRight: '8px',
        },
        "& .css-g1d714-ValueContainer": {
            position: "revert",
        },
        "& .css-1pahdxg-control:hover": {
            borderColor: '#3F51B5',
            boxShadow: 'none',
            borderWidth: 2
        },
        "& .css-1pahdxg-control": {
            borderColor: '#3F51B5',
            boxShadow: 'none',
            borderWidth: 2
        },
        "& .css-1hb7zxy-IndicatorsContainer": {
            borderRadius: "8px",
            borderColor: "rgba(214, 214, 214, 1)",
            fontWeight: 500,
            fontFamily: "SFProDisplay",
            fontSize: "16px",
            with: "100%",
        },
        "& .css-26l3qy-menu": {
            fontFamily: "SFProDisplay",
            fontSize: "14px",
            fontWeight: 500,
        },
        "& .css-view-1dbjc4n": {
            margin: `0px !important`,
        }
    },
})(Grid);

const CheckBoxLabel:any = withStyles({
    root: {
        alignItems: "center",
        margin: '14px 0px 0px 0px',
        "& .MuiTypography-body1": {
            fontFamily: "SFProDisplay",
            fontWeight: 400,
            fontSize: "16px",
            color: "rgba(58, 63, 99, 1)",
        },
    },
})(FormControlLabel);

const MainContainer = withStyles({
    root: {
        
        overflowX: 'inherit',
        "& .css-1pahdxg-control": {
            borderColor: `rgba(218, 224, 241, 1)!important`,
            boxShadow: 'none',
            borderRadius: '8px',
            height: '40px',
        },
        "@media (max-width: 600px)": {
            "& .signupImg": {
                width: `100% !important`,
                height: `95% !important`,
                display: 'none'
            },
            "& .leftImg": {
                padding: `0px !important`,
            }
        },
        "@media (max-height: 1030px)": {
            "@media (max-width: 600px)": {
                height: '100%',
                overflowY: 'inherit',
            },
        },
    }
})(Grid);

const TermsDialog = withStyles({
    root: {
        "& .MuiDialog-paperScrollPaper": {
            borderRadius: '5px'
        },
        "& .MuiDialogActions-root": {
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0px',
            paddingBottom: '8px',
        },
        "& .WithStyles\(ForwardRef\(Button\)\)-root-10": {
            "@media (max-width: 600px)": {
                width: 300
            }
        },
        "& .MuiDialog-paperWidthSm": {
            minWidth: "670px",
            "@media (max-width: 768px)": {
                minWidth: "100%"
            }
        }
    }
})(Dialog);

const AcceptButton = withStyles({
    root: {
        width: "130px",
        height: "40px",
        padding: "9px 63px",
        borderRadius: "4px",
        backgroundColor: "#043c74",
        color: "rgba(255, 255, 255, 1)",
        opacity: 1,
        boxShadow: "none",
        fontFamily: "SFProDisplay",
        fontWeight: 700,
        fontSize: "18px",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#043c74",
            boxShadow: "none",
        },
        marginBottom: "18px"
    },
})(Button);

const IconBox = withStyles({
    root: {
        background: '#DAE0EC',
        height: '26px',
        width: '26px',
        borderRadius: '50%',
        float: 'right',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 0px 0px 0px',
        "& .MuiSvgIcon-root": {
            height: '20px',
            width: '20px'
        }
    }
})(Box)

