Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AuditTrail";
exports.labelBodyText = "AuditTrail Body";

exports.btnExampleTitle = "CLICK ME";
exports.userDetailLabel = "User Details";
exports.additionalDetailLabel = "Additional Details";
exports.addressLabel = "Address";
exports.designationLabel = "Designation";
exports.labelCustomerName = "Customer Name";
exports.sapCodeLabel = "Customer SAP Code";
exports.customerTypeLabel = "Customer Type";
exports.parentCustomerLabel2 = "Parent Customer Name";
exports.parentCustomerLabel = "First Name";
exports.industryLabel = "Industry";
exports.lastNameLabel = "Last Name";
exports.customerCompanyLabel = "Company Name";
exports.channelLabel = "Channel";
exports.ClassificationLabel = "Classification";
exports.customerContactLabel = "Customer Contacts";
exports.territoryLabel = "Territory";
exports.ModeOfBusinessLabel = "Mode Of Business";
exports.CurrencyLabel = "Account Currency";
exports.websiteLabel = "Website";
exports.billingStreetLabel = "Billing Street";
exports.shippingStreetLabel = "Shipping Street";
exports.billingCityLabel = "Billing City";
exports.shippingCityLabel = "Shipping City";
exports.billingPostalCodeLabel = "Billing Postal Code";
exports.shippingPostalCodeLabel = "Shipping Postal Code";
exports.billingStateLabel = "Billing State";
exports.shippingStateLabel = "Shipping State";
exports.billingCountryLabel = "Billing Country";
exports.accountManagerLabel = "Biocon Account Manager";
exports.shippingCountryLabel = "Shipping Country";
exports.alternateEmailLabel = "Alternate Email";
exports.labelFirstName = "First Name";
exports.companynameLabel = "Company Name";
exports.emailLabel = "Email ID";
exports.lastnameLabel = "Last Name";
exports.countryCodeLabel = "Country Code";
exports.phoneNumLabel = "Mobile No";
exports.departmentLabel = "Department";
exports.updateAccountApiEndpoint = "account_block/accounts/";
exports.updateAccountApiMethod = "PUT";
exports.updateCustomerProfileApiEndpoint = "bx_block_customer_profile/customer_profiles/";
exports.updateCustomerProfileApiMethod = "PUT";
exports.termsConditionError = "Please accept terms and condition";
exports.getCustomerProfileApiEndpoint = "bx_block_customer_profile/customer_profiles/";
exports.getCustomerProfileApiMethod = "GET";
exports.getDepartsmentListApiEndpoint = "bx_block_customer_profile/customer_profiles/get_department";
exports.getDepartmentListApiMethod = "GET";
exports.privacyPolicyLabel = "* I agree and accept the";
exports.privacyPolicyText = "Privacy Policy";
exports.termslabelText = "Terms of Use";
exports.termsLabel = " of this website";
exports.bamEmailIdLabel = "BAM Email ID";
exports.bamPhoneNoLabel = "BAM Phone No";
exports.territoryLabel = "Territory";
exports.mappedProductLabel= "Mapped Product";
exports.getPersonalDetailsApiEndPoint = "account_block/customer_masters/";
exports.getBAMListAPIENDPoint = "/account_block/bam_details";
exports.getBAMDetailsApiEndPoint = "/account_block/accounts/";
exports.getAllListApiEndpoint = "account_block/accounts/info_selection_listing";
exports.getAllListApiMethod = "GET";
exports.addPersonalApiEndpoint = "/account_block/customer_masters";
exports.landlinelabel = "Landline";
exports.alternateEmailLabel = "Alternate Email ID";
exports.contactUniqueLabel = "Contact Unique ID";
exports.productMapListAPIEndPoint = "account_block/product_mapped/catalogue_listing";
exports.productMappedDetailAPIEndPoint = "account_block/accounts/";
exports.createProductMappedAPIEndPoint = "account_block/product_mapped";
exports.getAllBioconManagersApiEndPoint = "account_block/customer_masters/all_biocon_account_manager";
exports.getAllBioconManagersApiMethod = "GET";
exports.getAllEmployeeTerritoriesApiEndPoint = "account_block/profile_details/emp_listing";
exports.getAllEmployeeTerritoriesApiMethod = "GET";
exports.createUpdateBamDetailsApiEndPoint = "account_block/customer_masters/bam_detail?";
exports.createUpdateBamDetailsApiMethod = "POST";
exports.updatePersonalDetailsApiEndPoint = "account_block/customer_masters/";
exports.updatePersonalDetailsApiMethod = "PUT";
exports.updateProductMappedDetailApiEndPoint = "account_block/customer_masters/product_mapped?";
exports.updateProductMappedDetailApiMethod = "POST";
exports.getParentCompanyListingApiEndPoint = "account_block/accounts/parent_company_listing";
exports.getParentCompanyListingApiMethod = "GET";
exports.getAllApprovedCustomerMastersApiEndpoint = "account_block/customer_masters/approved_users/";
exports.getAllApprovedCustomreMastersApiMethod = "GET";
exports.getAllSalutationsApiEndpoint = "account_block/customer_masters/salutation";
exports.getAllSalutationsApiMethod = "GET";
exports.getDepartsmentListApiEndpoint = "bx_block_customer_profile/customer_profiles/get_department";
exports.getDepartmentListApiMethod = "GET";
exports.saveCustomerContactApiEndpoint = "account_block/customer_masters/customer_contact?";
exports.saveCustomerContactApiMethod = "POST";
exports.getAllCompaniesApiEndpoint = "account_block/accounts/company_listing";
exports.getAllCompaniesApiMethod = "GET";
exports.saveContactMasterDetailApiEndpoint = "account_block/customer_masters/user_details";
exports.saveContactMasterDetailApiMethod = "POST";
exports.deleteProductMappedApiEndpoint = "account_block/customer_masters/delete_product_mapped";
exports.deleteProductMappedApiMethod = "DELETE";
exports.saveInvoiceApiEndpoint = "";
exports.saveInvoiceApiMethod = "POST";
//TODO: APIs endpoints will be set in future
exports.getIncoTeamListApiEndpoint = "account_block/customer_masters/terms_listing";
exports.getIncoTeamListApiMethod = "GET";
exports.getCategoriesApiEndPoint = "bx_block_catalogue/catalogues/listing";
exports.getCategoriesApiEndMethod = "GET";
exports.getAllCustomerInvioceApiEndPoint = "account_block/customer_masters/all_customer_invoice";
exports.getAllCustomerInvioceApiMethod = "GET";
exports.getAllContectInvioceApiEndPoint = "account_block/customer_masters/all_contact_invoice";
exports.getAllContectInvioceApiMethod = "GET";
exports.getByIdCustomerInvioceApiEndPoint = "/account_block/customer_masters/show_customer_invoice";
exports.getByIdCustomerInvioceApiMethod = "GET";
exports.createinvoiceEditIdApiEndpoint = "account_block/customer_masters/";
exports.createinvoiceEditIdApiMethod = "PUT";
exports.createinvoiceApiMethod = "POST";
exports.createinvoiceApiEndpoint = "account_block/customer_masters/customer_invoice";

// ------------------

exports.btnExampleTitle = "CLICK ME";
exports.customerTypeError = "Please Select Customer Type";
exports.territoryError = "Please Select Territory";
exports.modeOfBusinessError = "Please Select Mode Of Business";
exports.accountCurrencyError = "Please Select Account Currency";
exports.websiteError = "Please Enter Valid Website";
exports.accountManagerError = "Please Select Biocon Account Manager";
exports.billinPostalCodeError = "Please Enter Valid Billing Postal Code";
exports.shippingPostalCodeError = "Please Enter Valid Shipping Postal Code";
exports.createCustomerPopupText = "The customer is created and customer ID has been generated.";
exports.customerMasterMandatory = "Please Select Customer Contact Person";
exports.solutationError = "Please Select Solutation";
exports.companyError = "Please Select Company";
exports.emailError = "Please Enter Valid Email";
exports.alternetEmailError = "Please Enter Valid Alternate Email";
exports.deleteCustomerContactApiEndpoint = "account_block/customer_masters/delete_customer_contact";
exports.deleteCustomerContactApiMethod = "DELETE";
exports.deleteCustomerContactPopupText = "Are you sure want to Delete Customer Contact?";
exports.deleteProductMappedPopupText = "Are you sure want to Delete Mapped Product?";
exports.errorPasswordNotValid = "Password not valid.";
exports.passwordError="Pasword Must contains alteast 1 upper case ,1 lower case,1 number , 1 special character"
exports.deleteInvoicePopupText = "Are you sure want to Delete Invoice?";
exports.contactNameLabel="Contact Name";
exports.productNameLabel="Product Name";
exports.invoiceLabel="Invoice Number";
exports.invoiceDateLabel="Invoice Date";
exports.invoiceAmountLabel="Invoice Amount";
exports.invoiceCurrencyLabel="Currency";
exports.invoicePOLabel="PO";
exports.invoiceDueDateLabel="Due Date";
exports.invoicePaymentTermsLabel="Payment Terms";
exports.invoiceIncoTermsLabel="Inco Terms";
exports.invoicePaymentStatusLabel="Payment Status";
exports.invoiceAttachmentLabel="Attachmnet";
exports.invoiceAddLabel="Invoice Details";
exports.attachmentLabel="Attachment";
exports.chooseDepartmentText = "Choose Department";
exports.noDataText = "No Data";
exports.chooseSolutionText = "Choose Solutation";
exports.firstNamePlaceholder = "Enter First Name";
exports.lastNamePlaceholder = "Enter Last Name";
exports.emailIdPlaceholder = "Enter Email ID";
exports.countryCodePlaceholder = "Enter Country Code";
exports.phoneNumberPlaceholder = "Enter your phone number";
exports.countryNamePlaceholder = "Enter your country name";
exports.completeaddressPlaceholder = "Enter Complete Address";
exports.designationPlaceholder = "Enter Designation";
exports.landlineNumberPlaceholder = "Enter Landline Number";
exports.alternateEmailIdPlaceholder = "Enter Alternate Email ID";
exports.uniqueIdPlaceholder = "Unique Id Auto Generated";
exports.searchInvoicePlaceholder = "Search Invoice";
exports.saveButtonText = "Save";
exports.solutationText = "Mr.";
exports.countryLabelText = "Country";
exports.noInvoiceData = "Sorry, no customer invoices found";
exports.addNewButtonText = "Add New";
exports.resetPasswordBtnText = "Reset Password";
exports.activeOptionText = "Active";
exports.InactiveOptionText = "Inactive";
exports.sendResetPasswordEmailEndPoint = "account_block/accounts/send_reset_email";
exports.contactUpdateSuccessMsg = "Customer Contact Updated Successfully";
exports.contactAddedSuccessMsg = "Customer Contact Added Successfully";
exports.invoicesTabLabel = "Invoices";
exports.userDetailsTabPanel = "User Details";
exports.tabsLabelText = "Tabs where each tab needs to be selected manually";
exports.searchFolderIdText = "searchFolder";
exports.contactUniqueLabelIdText = "contactUniqueLabel";
exports.alternateEmailLabelIdText = "alternateEmailLabel";
exports.landlinelabelIdText = "landlinelabel";
exports.designationLabelIdText = "designationLabel";
exports.chooseCompanyPlaceholder = "Choose Company";
exports.SomethingwentwrongText = "Something went wrong";
exports.mailSendSuccess = "Mail sent successfully to reset password";
exports.CustomerExcellenceText = "Customer Excellence";
exports.ITText = "IT";
exports.editBtnText = "Edit";
exports.customerNameRequiredText = "Customer name is required";
exports.parentCompanyNamePlaceholder = "Choose Parent Company Name";
exports.customerSapCodePlaceholder = "Enter Customer SAP Code";
exports.chooseIndustryPlaceholder = "Choose Industry";
exports.chooseCustomerTypePlaceholder = "Choose Customer Type";
exports.CustomerTypeisRequired = "Customer type is required";
exports.chooseChannelPlaceholder = "Choose Channel";
exports.chooseTerritoryPlaceholder = "Choose Territory";
exports.TerritoryisRequired = "Territory is required";
exports.chooseClassificationPlaceholder = "Choose Classification";
exports.chooseModeBusinessPlaceholder = " Choose Mode Of Business";
exports.BusinessModeIsrequired = "Mode of business is required";
exports.ChooseCurrencyPlaceholder = "Choose Currency";
exports.CurrencyisRequired = "Account currency is required";
exports.enterWebsitePlaceholder = "Enter website";
exports.billingStreetPlaceholder = "Enter Billing Street";
exports.billingStreetisRequried = "Billing street is required";
exports.billingAddressCheckboxLabel = "Same as Billing Address";
exports.shippingStreetPlaceholder = "Enter Shipping Street";
exports.shippingStreetisRequired = "Shipping street is required";
exports.billingCityPlaceholder = "Enter Billing City";
exports.billingCityisRequired = "Billing city is required";
exports.shippingCityPlaceholder = "Enter Shipping City";
exports.shippingCityisRequired = "Shipping city is required";
exports.billingPostalCodePlaceholder = "Enter Billing Postal Code";
exports.billingPostalCodeisRequired = "Billing postal code is required";
exports.shippingPostalPlaceholder = "Enter Shipping Postal Code";
exports.shippingPostalisRequired = "Shipping postal code is required";
exports.billingStatePlaceholder = "Enter Billing State";
exports.billingStateisRequired = "Billing state is required";
exports.shippingStatePlaceholder = "Enter Shipping State";
exports.shippingStateisRequired = "Shipping state is required";
exports.addCustomerheader = "Add New Customer";
exports.enterNamePlaceholder = "Enter Name";
exports.addMoreBtnText = "Add More +";
exports.yesButtonText = "Yes";
exports.EmailregexExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// ------------------
// Customizable Area End