import React from "react";
import {
  Grid,
  Typography,
  TextField,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Button,
  InputAdornment,
  IconButton,
  Divider,
  Box,
  Container
} from "@material-ui/core";
import { BioLogo, Background, ConnectLogo } from "./assets";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import { withStyles } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";
import LoginScreenWebController, { Props } from "./LoginScreenController.web";
import { NavLink as RLink } from "react-router-dom";

export default class LoginScreenWeb extends LoginScreenWebController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    //@ts-ignore
    const { navigation } = this.props;
    return (
      <>
        <MainContainer container>
          <Grid item lg={6} md={6} sm={6} xs={12} style={{ position: "relative" }} className="leftImageRes">
            <Box sx={webStyle.backImageSpace}>
              <img
                src={this.state.loginImage}
                style={webStyle.leftImage}
                className="leftImg"
              />
            </Box>
          </Grid>
          <RightBox item lg={6} md={6} sm={6} xs={12}>
            <Container style={{ padding: 0 }}>
              <RightGrid item lg={12} md={12} sm={12} xs={12}>
                <Grid container className="logoContainer">
                  <RLink to={"/"}>
                    <img
                      src={BioLogo}
                      style={webStyle.LogoImage}
                      onClick={this.goToHome}
                      className="Mainlogo"
                    />
                  </RLink>
                  <Divider orientation="vertical" style={webStyle.divider} />
                  <img
                    src={ConnectLogo}
                    style={webStyle.connectLogo}
                    className="blendLogo"
                  />
                </Grid>
                <Typography style={webStyle.Header}>
                  {this.state.loginHeader}
                </Typography>
                <Typography style={webStyle.subHeader}>
                  {this.state.loginSubHeading}
                </Typography>
                <form onSubmit={(e: any) => {e.preventDefault(); this.doEmailLoginWeb();}} data-testid="formsubmit">
                  <InputLabel htmlFor="txtInputEmail" style={webStyle.EmailinputLabel}>
                    {this.state.emailLabelText}
                  </InputLabel>
                  <InputField
                    id="txtInputEmail"
                    data-testid="inputemail"
                    variant="outlined"
                    placeholder="example@gmail.com"
                    required
                    onChange={this.handleChange("email").bind(this)}
                    value={this.state.email}
                    fullWidth
                  />
                  <InputLabel
                    htmlFor="passwordInput"
                    style={webStyle.PassinputLabel}
                  >
                    Password
                  </InputLabel>
                  <PasswordField
                    id="passwordInput"
                    variant="outlined"
                    required
                    value={this.state.password}
                    onChange={this.handleChange("password").bind(this)}
                    placeholder="• • • • • • • • • • • • •"
                    fullWidth
                    type={this.state.showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            edge="end"
                            style={{ color: "lightgray" }}
                          >
                            {this.state.showPassword ? (
                              <VisibilityOffOutlinedIcon />
                            ) : (
                              <VisibilityOutlinedIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <Grid
                    container
                    justifyContent="space-between"
                    style={{
                      padding: "10px 0px",
                      marginLeft: "6px",
                      maxWidth: "100%"
                    }}
                  >
                    <CheckBoxLabel
                      control={
                        <Checkbox
                          color="default"
                          disableRipple
                          checked={this.CustomCheckedBoxProps.isChecked}
                          onChange={this.btnRememberMeProps.onPress}
                          checkedIcon={<span style={webStyle.checkedIcon} />}
                          icon={<span style={webStyle.icon} />}
                          inputProps={{ "aria-label": "decorative checkbox" }}
                        />
                      }
                      label="Remember Password"
                    />
                    <Typography
                      onClick={() => {
                        navigation.navigate("ForgotPassword");
                      }}
                      style={webStyle.PassText}
                      className="forgetPasswordRes"
                      data-testid="forgetpswdbtn"
                    >
                      {this.state.forgotPasslabel}
                    </Typography>
                  </Grid>
                  <Grid style={webStyle.maxWidthStyle}>
                    <Buttons type="submit" variant="contained">
                      {this.state.loginButtonText}
                    </Buttons>
                  </Grid>
                </form>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  style={webStyle.maxWidthStyle}
                >
                  <Typography style={webStyle.AccountText}>
                    {this.state.AccountText}
                  </Typography>
                  <Typography
                    onClick={() => this.goTOSignup()}
                    style={webStyle.singUp}
                    data-testid="signupbtn"
                  >
                    {this.state.signUpText}
                  </Typography>
                </Grid>
              </RightGrid>
            </Container>
          </RightBox>
          <Loader loading={this.state.imageLoading} />
        </MainContainer>
      </>
    );
  }
}

// Customizable Area Start
const webStyle = {
  maxWidthStyle: {
    maxWidth: "100%"
  },
  backImageSpace: {
    padding: "0 1.9% 0 0",
    width: "100%"
  },
  leftImage: {
    height: "100vh",
    width: "93.9%"
  },
  LogoImage: {
    height: "54px",
    width: "100%",
    paddingTop: "10px",
    paddingBottom: "10px",
    cursor: "hand"
  },
  Header: {
    fontWeight: 500,
    color: "rgba(4, 60, 116, 1)",
    fontFamily: "SFProDisplay",
    fontSize: "20px",
    paddingBottom: "5px"
  },
  subHeader: {
    fontWeight: 500,
    fontFamily: "SFProDisplay",
    fontSize: "14px",
    color: "rgb(4, 60, 116)",
    marginBottom: "10px"
  },
  EmailinputLabel: {
    fontSize: "16px",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    color: "rgba(58, 63, 99, 1)",
    paddingBottom: "5px",
    paddingTop: "10px"
  },
  PassinputLabel: {
    fontSize: "16px",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    color: "rgba(58, 63, 99, 1)",
    paddingBottom: "5px",
    paddingTop: "10px"
  },
  PassText: {
    color: "rgba(69, 158, 255, 1)",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "16px",
    paddingTop: "4px",
    cursor: "pointer"
  },
  AccountText: {
    color: "rgba(58, 63, 99, 1)",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "16px",
    padding: "15px 0px",
    paddingRight: "10px"
  },
  singUp: {
    color: "rgba(69, 158, 255, 1)",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "18px",
    padding: "15px 0px",
    cursor: "pointer"
  },
  icon: {
    borderRadius: 3,
    width: 15,
    height: 15,
    backgroundColor: "white",
    border: "1px solid rgba(58, 63, 99, 1)",
    marginRight: "8px",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2
    },
    color: "rgba(58, 63, 99, 1)",
    "input:hover ~ &": {
      backgroundColor: "white"
    },
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  checkedIcon: {
    borderRadius: 3,
    marginRight: "8px",
    border: "1px solid rgba(58, 63, 99, 1)",
    width: 15,
    height: 15,
    backgroundColor: "white",
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='rgba(58, 63, 99, 1)'/%3E%3C/svg%3E\")",
    "input:hover ~ &": {},
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  welcomeText: {
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "48px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#fff"
  },
  startedText: {
    margin: "1.3% 0px 6.7px 0px",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "24px",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    color: "#fff"
  },
  serviseText: {
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "16px",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    color: "#fff"
  },
  divider: {
    height: "60px",
    width: "1px",
    backgroundColor: "#6a74a5",
    margin: "8px 5.9% 5.4% 5.9%"
  },
  connectLogo: {
    width: "193px",
    height: "60px",
    paddingTop: "10px",
    paddingBottom: "10px"
  },
};

const PasswordField: any = withStyles({
  root: {
    maxWidth: "100%",
    background: "inherite",
    margin: "5px 0px 5px 0px",
    "& ::placeholder": {
      color: "rgba(214, 214, 214, 1)",
      opacity: 1,
      fontSize: "16px",
      fontFamily: "SFProDisplay",
      fontWeight: 400
    },
    "& .MuiInputBase-input": {
      height: "15px"
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px"
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: "SFProDisplay",
      fontSize: "14px",
      fontWeight: 400,
      "& fieldset": {
        height: "40px",
        borderColor: "rgba(214, 214, 214, 1)",
        borderRadius: "8px",
        borderWidth: "1px"
      }
    }
  }
})(TextField);

const InputField: any = withStyles({
  root: {
    maxWidth: "100%",
    background: "inherite",
    margin: "5px 0px 5px 0px",
    "& ::placeholder": {
      color: "rgba(214, 214, 214, 1)",
      opacity: 1,
      fontSize: "14px",
      fontFamily: "DMSans-Bold"
    },
    "& .MuiInputBase-input": {
      height: "15px"
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px"
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: "SFProDisplay",
      fontSize: "14px",
      fontWeight: 400,
      "& fieldset": {
        height: "40px",
        borderColor: "rgba(214, 214, 214, 1)",
        borderRadius: "8px",
        borderWidth: "1px"
      }
    }
  }
})(TextField);

const Buttons: any = withStyles({
  root: {
    width: "100%",
    color: "rgba(255, 255, 255, 1)",
    backgroundColor: "rgba(69, 158, 255, 1)",
    opacity: 1,
    boxShadow: "none",
    height: "45px",
    borderRadius: "5px",
    fontFamily: "DMSans-Bold",
    fontSize: "22px",
    marginTop: "10px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "rgba(69, 158, 255, 1)",
      boxShadow: "none"
    }
  }
})(Button);

const CheckBoxLabel: any = withStyles({
  root: {
    "& .MuiTypography-body1": {
      fontFamily: "SFProDisplay",
      fontWeight: 400,
      fontSize: "16px",
      color: "rgba(58, 63, 99, 1)"
    }
  }
})(FormControlLabel);

const RightBox: any = withStyles({
  root: {
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "98% 100%",
    margin: "1.2% 0px 1.2% 0px",
    padding: "0px 60px",
    paddingRight: "90px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 1070px)": {
      paddingLeft: "0px",
      paddingRight: "15px"
    },
    "& .PrivateSwitchBase-root-14": {
      padding: "0px"
    },
    "@media (max-width: 600px)": {
      padding: "0px"
    }
  }
})(Grid);

const RightGrid: any = withStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingRight: "0px",
    paddingLeft: "4px",
    "@media (max-width: 600px)": {
      padding: `0px 20px 0px 20px !important`
    }
  }
})(Grid);

const MainContainer: any = withStyles({
  root: {
    overflowX: "inherit",
    "& .leftImg": {
      objectFit: "cover",
      objectPosition: "top"
    },
    "& .logoContainer": {
      maxWidth: "100%",
      flexWrap: "nowrap"
    },
    "@media (max-height: 1030px)": {
      "@media (max-width: 600px)": {
        height: "100%",
        overflowY: "inherit"
      }
    },
    "@media (max-width: 600px)": {
      "& .leftImageRes": {
        display: "none"
      },
      "& .leftImg": {
        display: "none"
      }
    },
    "@media (max-width: 1150px)": {
      "& .logoContainer": {
        maxWidth: `100% !important`,
      },
      "& .Mainlogo": {
        width: `100% !important`
      },
      "& .blendLogo": {
        width: `130px !important`
      }
    },
    "@media (max-width: 1070px)": {
      "& .forgetPasswordRes": {
        margin: "auto",
        marginRight: "0px"
      }
    }
  }
})(Grid);

// Customizable Area End
