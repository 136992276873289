import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall, getHeaders, isAuthorisedUser, isLoggedIn, warningNotification } from "../../../components/src/common";

// Customizable Area Start
import { handleUpdateAuthToken } from "../../../components/src/common";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

export enum MY_QUERIES_SORT_TYPES {
  SORT_BY_OLDEST = 0,
  SORT_BY_NEWEST = 1,
  SORT_BY_DATE = 2
}

export interface IMyTicketDetail {
  product_name: string;
  product_query_id: number;
  query_type: string;
  status: string;
  status_id: number;
  ticket_creation_date: string;
  ticket_id: number;
  ticket_number: string;
  status_message: string;
  doc_name: [];
}

export interface IQueryListMeta {
  current_count: number;
  current_page: number;
  next_page: number;
  prev_page: number;
  total_count: number;
  total_pages: number;
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  sort_key: number;
  selected_tab: number;
  per_page_records: number;
  page_number: number;
  query_list_meta: IQueryListMeta | null;
  tickets_count: number | null;
  queries_list: IMyTicketDetail[];
  allcontectInvioce: any[];
  filter_key: number;
  is_loading: boolean;
  total_tickets_count: number;
  contactInvoiceListingMeta: any;
  page: any;
  perPageRecord: any;
  invoiceFormDetails: any;
  contactNameLabel: string;
  productNameLabel: string;
  invoiceLabel: string;
  invoiceDateLabel: string;
  invoiceAmountLabel: string;
  invoiceCurrencyLabel: string;
  invoicePOLabel: string;
  invoiceDueDateLabel: string;
  invoicePaymentTermsLabel: string;
  invoiceIncoTermsLabel: string;
  invoicePaymentStatusLabel: string;
  invoiceAttachmentLabel: string;
  invoiceAddLabel: string;
  attachmentLabel: string;
  getAttachment: any;
  getDataaaa: any;
  searchInvoiceText: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MyInvoicesController extends BlockComponent<Props, S, SS> {
  getQueriesApiCallId: string;
  getAllContectInvioceApiCallId: string;
  getUserPersonalApiCallId: string;
  authToken: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      is_loading: false,
      filter_key: 0,
      sort_key: 1,
      queries_list: [],
      total_tickets_count: 0,
      selected_tab: 0,
      per_page_records: 12,
      page_number: 1,
      query_list_meta: null,
      tickets_count: 0,
      allcontectInvioce: [],
      contactInvoiceListingMeta: null,
      page: 1,
      perPageRecord: 20,
      invoiceFormDetails: {
        account_id: '',
        catalogue_id: '',
        invoice: '',
        invoice_date: '',
        invoice_amount: '',
        account_currency_id: '',
        p_o: '',
        due_date: '',
        payment_term_id: '',
        inco_term_id: '',
        payment_status: '',
        attachment: "",
      },
      contactNameLabel: configJSON.contactNameLabel,
      productNameLabel: configJSON.productNameLabel,
      invoiceLabel: configJSON.invoiceLabel,
      invoiceDateLabel: configJSON.invoiceDateLabel,
      invoiceAmountLabel: configJSON.invoiceAmountLabel,
      invoiceCurrencyLabel: configJSON.invoiceCurrencyLabel,
      invoicePOLabel: configJSON.invoicePOLabel,
      invoiceDueDateLabel: configJSON.invoiceDueDateLabel,
      invoicePaymentTermsLabel: configJSON.invoicePaymentTermsLabel,
      invoiceIncoTermsLabel: configJSON.invoiceIncoTermsLabel,
      invoicePaymentStatusLabel: configJSON.invoicePaymentStatusLabel,
      invoiceAttachmentLabel: configJSON.invoiceAttachmentLabel,
      invoiceAddLabel: configJSON.invoiceAddLabel,
      attachmentLabel: configJSON.attachmentLabel,
      getAttachment: "",
      getDataaaa: "",
      searchInvoiceText: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSeachInvoices = this.handleSeachInvoices.bind(this);
    this.duplicateCode = this.duplicateCode.bind(this);
    this.debouncedCallback = this.debouncedCallback.bind(this);
    this.debounce = this.debounce.bind(this);
    this.tokenHasExpired = this.tokenHasExpired.bind(this);
    this.setInvoiceData = this.setInvoiceData.bind(this);
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const authToken = isLoggedIn(this.props.navigation);
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
    }
    this.getAllContactInvioce();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      isAuthorisedUser(responseJson, this.props.navigation);
      this.setState({
        is_loading: false
      });
      if (this.getAllContectInvioceApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        this.setState({ is_loading: false })
        this.setInvoiceData(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  tokenHasExpired = () => {
    this.setState({ is_loading: true });
    const authToken = isLoggedIn(this.props.navigation);
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
    }
  }

  setInvoiceData = async (responseJson: any) => {
    if (responseJson.message === "Sorry, no customer invoices found") {
      this.setState({
        allcontectInvioce: [],
        contactInvoiceListingMeta: [],
      })
    } else if (!responseJson.error && !responseJson.errors) {
      this.setState({
        allcontectInvioce: responseJson.result.data,
        contactInvoiceListingMeta: responseJson.result.meta,
      })
    } else {
      if (responseJson.errors && responseJson.errors[0]?.token === "Token has Expired" && JSON.parse(localStorage.getItem("userInfo") || "")?.attributes?.is_customer === 'True') {
        this.tokenHasExpired()
        await handleUpdateAuthToken();
        if (this.authToken) {
          this.getAllContactInvioce();
        }
      }
    }
  }

  isJson(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  getAllContactInvioce = () => {
    this.setState({ is_loading: true })
    const customerUserId: any = this.isJson(localStorage.getItem("userInfo") || "") && JSON.parse(localStorage.getItem("userInfo") || "")?.id;
    const allManagers = apiCall({
      header: getHeaders({ token: this.authToken }),
      url: configJSON.getAllContectInvioceApiEndPoint + `?account_id=${customerUserId}&page=${this.state.page}&per_page=${this.state.perPageRecord}&query=${this.state.searchInvoiceText}`,
      httpBody: {},
      httpMethod: configJSON.getAllContectInvioceApiMethod
    });
    this.getAllContectInvioceApiCallId = allManagers.messageId;
    runEngine.sendMessage(allManagers.id, allManagers);
  }

  handlePageChange = (e: any, p: any) => {
    this.setState({
      page: p,
    }, () => {
      this.getAllContactInvioce();
    });
  };

  debounce = (func: any, wait: number = 700) => {
    let timer: any;
    return (...args: any[]) => {
      clearTimeout(timer)
      timer = setTimeout(() => func(...args), wait);
    };
  };

  debouncedCallback = this.debounce(() => this.duplicateCode(), 700);

  handleSeachInvoices = (event: any) => {
    if (event.target.value.length > 0) {
      this.setState({
        ...this.state,
        searchInvoiceText: event.target.value,
        page: 1
      }, this.debouncedCallback)
    } else {
      this.setState({ searchInvoiceText: '' })
      this.setState({ page: 1, }, () => { this.getAllContactInvioce(); });
    }
  };

  duplicateCode = () => {
    this.getAllContactInvioce();
  };

  // Customizable Area End
}
