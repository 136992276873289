import React from "react";
import {
  Typography,
  TextField,
  Grid,
  InputAdornment,
  IconButton,
  Container
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { withStyles } from "@material-ui/core/styles";

const CustomSearch = (props: any) => {
  return (
    <SearchGrid alignItems="center">
      <Container style={{ padding: 0 }}>
        <Grid container spacing={0} alignItems="center">
          {/* <Grid item lg={1} md={2} sm={2} xs={3} style={{ paddingRight: 0 }}>
            <Typography style={webStyle.searchTitle}>
              Search 
            </Typography>
          </Grid> */}
          <Grid item lg={12} md={12} sm={12} xs={12} >
            <InputField
              id="companyname"
              variant="outlined"
              required
              type="text"
              placeholder="Search"
              ref={props.searchInputRefVar}
              onChange={(e) => props.searchByProductName(e)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" >
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <SearchIcon style={{ fill: '#6a74a5', fontSize: '20px' }} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </SearchGrid >
  );
};

const webStyle = {
  searchTitle: {
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    color: "rgba(4, 60, 116, 1)",
    margin: '0px 27px 8px 0',
    fontSize: "30px",
    lineHeight: 1.2,
    letterSpacing: '-0.4px',

  },
};

export default CustomSearch;

const SearchGrid:any = withStyles({
  root: {
    backgroundColor: "rgb(239 241 245)",
    padding: "0px 58px 0px 70px",
    borderColor: "rgba(218, 224, 241, 1)",
    height: "65px",
    display: 'flex',
    alignItems: 'center',
    "@media (max-width: 700px)": {
      padding: "0px 30px 0px 30px",
    }
  }
})(Grid);

const InputField = withStyles({
  root: {
    background: "inherite",
    margin: "0px 0px 0px 0px",
    "& ::placeholder": {
      color: '#6a74a5',
      fontSize: '14px',
      fontFamily: 'SFProDisplay',
      fontWeight: 400,
      opacity: 1,
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl": {
      borderRadius: "22px !important",
    },
    "& .MuiInputBase-input": {
      height: '15px',
    },
    "& .MuiOutlinedInput-input": {
      padding: '11px 14px'
    },
    "& .MuiIconButton-root": {
      color: 'lightGrey',
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: "SFProDisplay",
      fontWeight: 400,
      backgroundColor: "rgba(255, 255, 255, 1)",
      fontSize: "16px",
      borderRadius: "8px",
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '-0.39px',
      color: '#6a74a5',
      '&.Mui-focused fieldset': {
        borderColor: "#dae0f1",
        borderWidth: 1,
      },
      '&.Mui-hovered fieldset': {
        borderColor: "#dae0f1",
        borderWidth: 1,
      },
      "& :hover": {
        borderColor: "#dae0f1",
        borderWidth: 1,
      },
      "& fieldset": {
        borderColor: "#dae0f1",
        borderWidth: 1,
        height: '40px',
      },
    },
    "& .MuiFormHelperText-contained": {
      margin: "0px !important",
      color: "rgba(106, 116, 165, 1)",
      fontSize: "12px",
      fontFamily: "SFProDisplay",
      fontWeight: 500,
    },
  },
})(TextField);
