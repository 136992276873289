import React from "react";
import { Grid, CardMedia } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import LandingPageController, { Props } from "./FullPageVideoController";
import { AppHeader } from "../../../components/src/AppHeader";

export class FullPageBanners extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
    return (
      <>
        <AppHeader navigation={this.props.navigation}/>
        <Grid style={webStyle.fullpage} className={classes?.container} container>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <CardMedia
              component="video"
              src={this.state.bannerVideo}
              autoPlay={true}
              controls
              controlsList="nodownload"
              style={webStyle.videoSection}
              className="bannerVideo"
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

const webStyle = {
  fullpage: {
    height: "100vh",
    backgroundColor: "#000000",
    padding: `0px 158px 0px`
  },
  videoSection: {
    width: "100%",
    height: "67.9%"
  }
};

const styles = () => ({
  container: {
    "@media (max-width: 1024px)": {
      padding: `0px 5px 0px !important`
    }
  }
})

export default withStyles(styles)(FullPageBanners)
