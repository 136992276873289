import React from "react";
import { Button, Box, Grid, Tabs, Tab } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import IconButton from "@material-ui/core/IconButton";
import { Typography, Chip } from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MY_QUERIES_SORT_TYPES } from "./MyQueriesController";
// import { DateRangePicker } from "react-date-range";
import DateRangePicker from "./DatePicker.web";
// import { DateRangePicker } from "materialui-daterange-picker";

const useStyles = makeStyles(() => ({
  padding: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
}));

interface IProps {
  selectedTab: number | string;
  handleChangeTab: (event: any, value: any) => void;
  handleSort: (sortName: MY_QUERIES_SORT_TYPES, data?: any) => void;
  sortKey: number;
  totalRecords: number | any;
}
export default function MyQueriesSortMenuPopup({
  selectedTab,
  handleChangeTab,
  handleSort,
  sortKey,
  totalRecords,
}: IProps) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showDatePicker, setShowDatePicker] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <CustomTabs
          onChange={handleChangeTab}
          value={selectedTab}
          aria-label="Tabs where each tab needs to be selected manually"
        >
          <CustomTab
            label={
              <Typography
                style={{
                  fontSize: "16px",
                  fontFamily: "SFProDisplay",
                  fontWeight: 700,
                }}
              >
                All Requests{" "}
                {selectedTab === 0 ? (
                  <Chip
                    label={totalRecords === undefined ? 0 : totalRecords}
                    component={Typography} size="small"
                    clickable style={{ backgroundColor: "#dae0f1", color: "#043c74", fontWeight: 700, fontSize: "12px", width: "auto", height: "20px" }}
                  />
                ) : (
                  ""
                )}
              </Typography>
            }
            style={selectedTab === 0 ? webStyle.Tab1 : webStyle.Tab6}
            disableRipple
          />
          <CustomTab
            label={
              <Typography
                style={{
                  fontSize: "18px",
                  fontFamily: "SFProDisplay",
                  fontWeight: 700,
                }}
              >
                Open{" "}
                {selectedTab === 1 ? (
                  <Chip
                    label={totalRecords === undefined ? 0 : totalRecords}
                    component={Typography} size="small"
                    clickable style={{ backgroundColor: "#dae0f1", color: "#043c74", fontWeight: 700, fontSize: "12px", width: "30px", height: "20px" }}
                  />
                ) : (
                  ""
                )}
              </Typography>
            }
            style={selectedTab === 1 ? webStyle.Tab2 : webStyle.Tab}
            disableRipple
          />
          <CustomTab
            label={
              <Typography
                style={{
                  fontSize: "18px",
                  fontFamily: "SFProDisplay",
                  fontWeight: 700,
                }}
              >
                In Progress{" "}
                {selectedTab === 2 ? (
                  <Chip
                    label={totalRecords === undefined ? 0 : totalRecords}
                    component={Typography} size="small"
                    clickable style={{ backgroundColor: "#dae0f1", color: "#043c74", fontWeight: 700, fontSize: "12px", width: "30px", height: "20px" }}
                  />
                ) : (
                  ""
                )}
              </Typography>
            }
            style={selectedTab === 2 ? webStyle.Tab2 : webStyle.Tab}
            disableRipple
          />
          <CustomTab
            label={
              <Typography
                style={{
                  fontSize: "18px",
                  fontFamily: "SFProDisplay",
                  fontWeight: 700,
                }}
              >
                Closed{" "}
                {selectedTab === 3 ? (
                  <Chip
                    label={totalRecords === undefined ? 0 : totalRecords}
                    component={Typography} size="small"
                    clickable style={{ backgroundColor: "#dae0f1", color: "#043c74", fontWeight: 700, fontSize: "12px", width: "30px", height: "20px" }}
                  />
                ) : (
                  ""
                )}
              </Typography>
            }
            style={selectedTab === 3 ? webStyle.tab3 : webStyle.Tab7}
            disableRipple
          />
        </CustomTabs>

        <Box style={webStyle.sortContainer}>
          <Box
            data-testid='handleClick'
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            display="flex"
          >
            <Typography style={webStyle.sortTitle}>Sort </Typography>
            <IconButton>
              <ArrowUpwardIcon style={webStyle.sortArrow1} />
              <ArrowDownwardIcon style={webStyle.sortArrow} />
            </IconButton>
            {/* <IconButton>
              <ArrowDownwardIcon style={webStyle.sortArrow1} />
              <ArrowUpwardIcon style={webStyle.sortArrow} />
             </IconButton> */}
          </Box>

          <Menu
            data-testid='handleClose'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              classes: { padding: classes.padding },
            }}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem
              data-testid='myQueriesSortTypes'
              onClick={() => {
                handleSort(MY_QUERIES_SORT_TYPES.SORT_BY_OLDEST);
                handleClose();
              }}
              style={sortKey === 0 ? webStyle.selected : webStyle.unSelected}
            >
              Sort by Oldest
            </MenuItem>
            <MenuItem
              data-testid='sortByNewest'
              onClick={() => {
                handleSort(MY_QUERIES_SORT_TYPES.SORT_BY_NEWEST);
                handleClose();
              }}
              style={sortKey === 1 ? webStyle.selected : webStyle.unSelected}
            >
              Sort by Newest
            </MenuItem>
            <MenuItem
             data-testid='sortbyDate'
              onClick={() => {
                // handleSort(MY_QUERIES_SORT_TYPES.SORT_BY_DATE)
                setShowDatePicker((prev) => !prev);
                handleClose();
              }}
              style={sortKey === 2 ? webStyle.selected : webStyle.unSelected}
            >
              Sort by Date
            </MenuItem>
          </Menu>
        </Box>
      </div>
      {showDatePicker && (
        <DateRangePicker
          data-testid='DateRangePicker'
          handleSort={() => handleSort(MY_QUERIES_SORT_TYPES.SORT_BY_DATE)}
          setShowDatePicker={setShowDatePicker}
        />
      )}
    </>
  );
}

const webStyle = {
  cardButton: {
    borderRadius: "8px",
    height: "42px",
    width: "146px",
    fontFamily: "SFProDisplay",
    fontWeight: 500,
    margin: " 56px 104px 0",
    boxShadow: "none",
    backgroundColor: "rgba(4, 60, 116, 1)",
    color: "#c6e4f7",
  },
  detailTitle: {
    color: "rgba(33, 43, 54, 1)",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    fontSize: "20px",
    paddingBottom: "10px",
    marginLeft: "24px",
  },
  Tab1: {
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    fontFamily: 'SFProDisplay',
    fontSize: '18px',
    fontWeight: 700,
    color: '#043c74',
    height: "40px",
    width: '28%',
  },
  Tab: {
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '18px',
    color: '#043c74',
    height: "40px",
    opacity: '0.49',
    width: 'auto',
  },
  Tab6: {
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '18px',
    color: '#043c74',
    height: "40px",
    opacity: '0.49',
    width: '20%',
  },
  Tab7: {
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '18px',
    color: '#043c74',
    height: "40px",
    opacity: '0.49',
    width: '20%',
  },
  Tab2: {
    fontFamily: 'SFProDisplay',
    fontSize: '18px',
    fontWeight: '700',
    color: '#043c74',
    height: "40px",
    width: '20%',

  },
  tab3: {
    borderEndEndRadius: "8px",
    borderTopRightRadius: "8px",
    fontFamily: 'SFProDisplay',
    fontSize: '18px',
    fontWeight: '700',
    color: '#043c74',
    height: "40px",
    width: '20%'
  },
  sortContainer: {
    display: "flex",
    justifyContent: "end",
    margin: "0px 0px 20px",
    marginLeft: "10px",
    // paddingTop: "1rem",
    // paddingBottom: '15px'
  },
  sortTitle: {
    // marginTop: "4px",
    marginRight: "-10px",
    marginTop: '7px',
    fontSize: "16px",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    color: "rgba(106, 116, 165, 1)",
    // display: 'flex',
    // alignItems: 'flex-start'
  },
  sortArrow1: {
    fontSize: "26px",
    width: "20px",
    height: "20px",
    marginTop: '-2px',
    // marginTop: "-9px",
    marginLeft: "3px",
    fill: "rgba(106, 116, 165, 1)",
  },
  sortArrow: {
    marginTop: '-2px',
    fontSize: "26px",
    width: "20px",
    height: "20px",
    // marginTop: "-9px",
    marginLeft: "-4px",
    fill: "rgba(106, 116, 165, 1)",
  },
  selected: {
    background: "rgba(218, 224, 241, 1)",
    color: "rgba(4, 60, 116, 1)",
    fontSize: "14px",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    height: "35px",
  },
  unSelected: {
    fontSize: "14px",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    height: "35px",
  },
};

const CustomTab : any = withStyles({
  root: {
    border: "1px solid rgba(4, 60, 116, 1)",
    width: "100%",
    color: "rgba(4, 60, 116, 1)",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    fontSize: "14px",
    textTransform: "none",
    height: "100px",
    "& .PrivateTabIndicator-colorSecondary-4": {
      display: "none",
    },
    "@media (max-width: 900px)": {
      width: "33%",
      fontSize: "12px",
      padding: 0,
    },
  },
})(Tab);

const CustomTabs : any = withStyles({
  root: {
    // marginLeft: "24px",
    "&.MuiTabs-root": {
      width: '100%',
    },
    "& .MuiTab-root": {
      minHeight: "35px",
      "@media (max-width: 760px)": {
        width: "49% !important",
        borderRadius: "8px !important",
        "&:nth-child(3), &:nth-child(4)": {
          marginTop: "10px",
        },
      },
      "@media (max-width: 660px)": {
        width: "45% !important",
        borderRadius: "8px !important",
        "&:nth-child(1), &:nth-child(3)": {
          marginRight: "10px",
        },

        "& .MuiTab-wrapper p": {
          fontSize: '14px !important',
        },
      },
    },
    '@media (min-width: 760px) and (max-width: 900px)': {
      "& .MuiTab-root": {
        minWidth: '135px'
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .Mui-selected": {
      background: "rgba(225, 239, 255, 1)",

    },
    "& .MuiTabs-flexContainer": {
      "@media (max-width: 760px)": {
        flexWrap: "wrap",
        // justifyContent: "space-between",
      },

    }
  },
})(Tabs);
