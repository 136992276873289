import React from "react";

//Customizable Area Start
import { Formik } from "formik";
import * as Yup from "yup";
import ForgotPasswordController, { Props } from "./ResetPasswordController";
import "./forgotPasswordStyle.css";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { withStyles } from "@material-ui/core/styles"; 
import {
    IconButton,
    InputAdornment,
    Box,
    Grid,
    TextField,
    InputLabel,
    Typography,
    Container,
    Button,
} from "@material-ui/core";
import {
    bioconLogo,
    backgroundImg,
} from "./assets";
import Loader from "../../../components/src/Loader.web";
//Customizable Area End

export class ResetPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        //Customizable Area Start
        //Customizable Area End
    }

    render() {
        const signupSchema = Yup.object().shape({
            new_password: Yup.string().required(this.state.PasswordIsRequiredText),
            confirmed_password: Yup.string().required(this.state.NewPasswordIsRequiredText),
        })
        return (
            <div className="testingDiv" style={webStyle.overflowStyle}>
                <MainContainer container>
                    <Grid item lg={6} md={6} id="drop-area">
                        <CustomBox sx={webStyle.backImageSpace} className="leftImg">
                            <img
                                data-testid="Background"
                                src={this.state.loginImage}
                                alt=""
                                className="signupImg"
                                style={webStyle.signupImage}
                            />
                        </CustomBox>
                    </Grid>
                    <RightContainer item lg={6} md={6} sm={6} xs={12} style={webStyle.companyCenterBox}>
                        <Container style={webStyle.noPadding}>
                            <RightGrid item lg={12} md={12} sm={12} xs={12}>
                                <Grid container style={webStyle.maxWidthStyle}>
                                    <img
                                        src={bioconLogo}
                                        onClick={this.goToHome}
                                        style={webStyle.bioconLogo}
                                    />
                                </Grid>
                                <Typography data-testid="header" style={webStyle.Header}>{this.state.resetPasswdHeaderText}</Typography>
                                <Typography data-testid="sub-header" style={webStyle.subHeader}>{this.state.resetPasswdSubHeaderHeaderText}</Typography>
                            <Formik
                                data-testid='FormikForm'
                                initialValues={{ new_password: "", confirmed_password: "" }}
                                validationSchema={signupSchema}
                                onSubmit={(values) => {this.resetPasswordFun(values)}}>
                                    {({touched,handleChange,handleSubmit,errors,values}: any) => (
                                    <form data-testid='form' className="av-tooltip tooltip-label-bottom" onSubmit={handleSubmit} id="savesample">
                                        <Grid>
                                            <InputLabel htmlFor="emailInput" style={webStyle.inputLabel}>{this.state.NewPasswordInputLabel}<span style={webStyle.errorStyle}>*</span></InputLabel>
                                            <InputTextField
                                                id="new_password"
                                                name="new_password"
                                                data-testid='newpassword'
                                                variant="outlined"
                                                type={this.state.showPassword ? "text" : "password"}
                                                InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton edge="end" aria-label="toggle password visibility"
                                                            onClick={this.handleClickShowPassword}>
                                                                {this.state.showPassword ? (<VisibilityOffOutlinedIcon />) : (<VisibilityOutlinedIcon />)}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),}}
                                                placeholder={this.state.passwordPlaceholderText}
                                                fullWidth
                                                style={webStyle.inputEmail}
                                                value={values?.new_password}
                                                onChange={handleChange("new_password")}
                                            />{touched?.new_password && errors?.new_password ? (
                                            <Typography style={webStyle.errorStyle}>{errors?.new_password}</Typography>) : 
                                            <Typography data-testid="passworderror" variant="body2" style={this.state.isPasswordError ? webStyle.passwordTypoError : webStyle.passwordTypo} className="passwordTypo">
                                                {this.state.passwordRules}
                                            </Typography>
                                        }
                                        </Grid>
                                        <Grid style={errors?.new_password ? webStyle.errorTextBox : webStyle.helpertextBox}>
                                            <InputLabel htmlFor="emailInput" style={webStyle.inputLabel}>{this.state.confirmPasswordLabel}<span style={webStyle.errorStyle}>*</span></InputLabel>
                                            <InputTextField
                                                id="confirmed_password"
                                                data-testid='confirmedpassword'
                                                variant="outlined"
                                                type={this.state.confirmShowPassword ? "text" : "password"}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton edge="end" aria-label="toggle password visibility"
                                                                onClick={this.handleConfirmShowPassword}>
                                                                {this.state.confirmShowPassword ? (<VisibilityOffOutlinedIcon />) : (<VisibilityOutlinedIcon />
                                                            )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                name="confirmed_password"
                                                value={values?.confirmed_password}
                                                placeholder={this.state.confirmPlaceholderText}
                                                fullWidth
                                                style={webStyle.inputEmail}
                                                onChange={handleChange("confirmed_password")}
                                            />
                                                {touched?.confirmed_password && errors?.confirmed_password ? (
                                                    <Typography style={webStyle.errorStyle}>{errors.confirmed_password}</Typography>) : null}
                                        </Grid>
                                        <Grid className="ZindexStyle">
                                        <ResetButton
                                            type="submit"
                                            data-testid="btnSubmit"
                                        >
                                            {this.state.resetButtonText}
                                        </ResetButton>
                                        </Grid>
                                    </form>
                                )}
                            </Formik>
                        </RightGrid>
                    </Container>
                </RightContainer>
            </MainContainer>
        <Loader data-testid="loader" loading={this.state.imageLoading} />
    </div>);
    }
}

// Customizable Area Start
const webStyle = {
    maxWidthStyle: {
        maxWidth: '100%' 
    },
    overflowStyle: {
        overflow: "hidden"
    },
    errorStyle: {
        color: "red",
    },
    passwordTypo: {
        maxWidth: '100%',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '12px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#6a74a5',
    },
    passwordTypoError: {
        maxWidth: '100%',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '12px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#e74c3c',
    },
    backImageSpace: {
        padding: '0 1.9% 0 0',
    },
    signupImage: {
        width: '95.9%',
        height: '100vh',
    },
    Header: {
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '20px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#043c74',
        margin: "15px 0 10px",
    },
    bioconLogo: {
        width: '30%',
        height: '100%',
        margin: '0px 0px 2% 0',
        cursor: 'hand',
    },
    subHeader: {
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '16px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#6A74A5',
        margin: "0px",
        paddingBottom: '20px'
    },
    inputEmail: {
        margin: "10px 0 0px",
        height: '45px',
    },
    inputLabel: {
        margin: "0 0 2px",
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '16px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#3a3f63',
    },
    companyCenterBox: {
        display: 'flex',
        alignItems: 'center',
    },
    resetButton: {
        maxWidth: '100%',
        height: '45px',
        margin: '5% 0 0px',
        padding: '1.2% 0px 1.2% 0px',
        borderRadius: '8px',
        backgroundColor: '#459eff',
        fontFamily: 'DMSans-Bold',
        fontSize: '22px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#fff',
        minWidth: '100%',
        boxShadow: 'none',
        borderWidth: "0px",
        cursor: "pointer",
    },
    helpertextBox: {
        paddingTop: "12px"
    },
    errorTextBox: {
        paddingTop: "10px"
    },
    noPadding: {
        padding: '0px'
    }
}

const RightGrid: any = withStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        paddingRight: '0px',
    }
})(Grid);

const RightContainer: any = withStyles({
    root: {
        backgroundImage: `url(${backgroundImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '98% 100%',
        margin: '1.2% 0px 1.2% 0px',
        padding: '0% 8.9% 0% 5.4%',
        display: 'flex',
        alignItems: 'center',
        "& .PrivateSwitchBase-root-14": {
            padding: '0px',
        },
        "& .compnaySelect .css-yk16xz-control": {
            maxWidth: '100%',
            borderRadius: '8px'
        },
        "& .compnaySelect .css-1pahdxg-control": {
            maxWidth: '100%',
        },
        "& .compnaySelect .css-26l3qy-menu": {
            maxWidth: '100%',
        }
    }
})(Grid);

const InputTextField: any = withStyles({
    root: {
        maxWidth: '100%',
        borderRadius: '8px',
        backgroundColor: '#fff',
        "& ::placeholder": {
            color: '#d6d6d6',
            fontSize: '16px',
            opacity: 1,
            fontFamily: 'SFProDisplay',
            fontWeight: 500,
        },
        "& .MuiInputBase-input": {
            height: '12px',
        },
        "& .MuiOutlinedInput-root": {
            fontWeight: 500,
            fontFamily: "SFProDisplay",
            backgroundColor: "rgba(255, 255, 255, 1)",
            fontSize: "16px",
            borderRadius: "8px",
            height: '35px',
            "& fieldset": {
                border: 'solid 1px #d6d6d6',
                borderRadius: '8px',
            },
        },
        "& .MuiOutlinedInput-input": {
            padding: '3% 11px'
        },
    },
})(TextField);

const MainContainer: any = withStyles({
    root: {
        "& .css-1pahdxg-control": {
            borderColor: `rgba(218, 224, 241, 1)!important`,
            boxShadow: 'none',
            borderRadius: '8px',
            height: '40px',
        },
        "@media (max-width: 600px)": {
            "& .signupImg": {
                width: `100% !important`,
                height: `95% !important`,
                display: 'none'
            },
            "& .leftImg": {
                padding: `0px !important`,
            }
        },
        "@media (max-height: 1030px)": {
            "@media (max-width: 600px)": {
                height: '100%',
                overflowY: 'inherit',
            },
        },
        overflowX: 'inherit',
    }
})(Grid);

const CustomBox: any = withStyles({
    root: {

    }
})(Box)

const ResetButton: any = withStyles({
    root: {
      maxWidth: '100%',
      height: '3.1%',
      margin: '3.9% 0 0px',
      padding: '1% 0px 1% 0px',
      borderRadius: '8px',
      backgroundColor: '#459eff',
      fontFamily: 'DMSans-Bold',
      fontSize: '20px',
      textAlign: 'center',
      color: '#fff',
      textTransform: "none",
      minWidth: '100%',
      boxShadow: 'none',
      borderWidth: "0px",
      cursor: 'pointer',
      "&:hover": {
        backgroundColor: "#459eff",
        boxShadow: "none",
      },
    }
  })(Button);

const styles = () => ({
})
export default withStyles(styles)(ResetPassword)


