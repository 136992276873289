import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import { BlockComponent } from "../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { apiCall, downloadAttachmentFile, errorNotification, getHeaders, warningNotification } from "../../components/src/common";

// Customizable Area Start

export interface IProductListing {
  isLoading?: boolean;
  attributes: {
    manufacturing_platform: string;
    category: {
      id: number;
      name: string;
    }[];
    image: string | null;
    name: string;
    product_description: {
      data: {
        id: string;
        type: string;
        attributes: {
          chemical_name: string;
          product_dmf: string;
        };
      };
    };
    product_documents: any;
    sub_category: {
      id: number;
      name: string;
    }[];
    therapeutic_area: string;
  };
  id: string;
  type: string;
}

export interface ICategory {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
  }
}

export interface ICategoryProduct {
  id: number;
  name: string;
  products: {
    id: number;
    name: string;
  }[];
}
export interface IProductListingMeta {
  pagination_details: {
    current_count: number;
    current_page: number;
    next_page: number;
    prev_page: null | number;
    total_count: number;
    total_pages: number;
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // navigation: any;
  // id: string;
  // Customizable Area Start
  isLoading: boolean;
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  footerData: any,
  //   api: string,
  //     email: string,
  //     phone_number: 0,
  //     content:string,
  //     cookie_policy: string,
  //     privacy_policy: string,
  //     terms_of_use: string

}

interface SS {
  id: any;
}
const PER_PAGE = 9;
export default class ProductListingController extends BlockComponent<
  Props,
  S,
  SS
> {
  authToken: string;
  // isLoading: boolean;
  getProductApiCallId: any;
  searchProductApiCallId: any;
  sortProductApiCallId: any;
  perPageRecords: number = 9;
  footerApiCallId: any;
  footerData: any;
  getFilterAndSearchProductsApiCallId: any;
  getCategoriesWithProductsCallId: any;
  downloadCatalogApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // this.getListRequest = this.getListRequest.bind(this);
    // this.handleSearchByProductName = this.handleSearchByProductName.bind(this);
    // this.handleFilterAndSearch = this.handleFilterAndSearch.bind(this);
    // this.isAnyFilterApplied = this.isAnyFilterApplied.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      footerData: []
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    super.componentDidMount();
    localStorage.setItem("landingPageApiCount", '0')
    localStorage.setItem("productPageExtraApisCount", '0')
    // let localStorageFooterData = localStorage.getItem("footerData")
    // if (localStorageFooterData !== null && localStorageFooterData !== '' && localStorageFooterData !== undefined) {
    //   this.setState({
    //     footerData: JSON.parse(localStorageFooterData)
    //   });
    // } else {
      this.fetchFooterData();
    // }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };



  async receive(from: string, message: Message) {

    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (
        this.footerApiCallId != null &&
        this.footerApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
        if (!responseJson.errors) {

          if (responseJson && responseJson.result && responseJson.result.data[0]) {
            localStorage.setItem("footerData", JSON.stringify(responseJson.result.data[0].attributes));
            this.setState({
              footerData: responseJson.result.data[0].attributes
            });
          }
        }

      }
    }
    // Customizable Area End
  }

  fetchFooterData = () => {
    const footerApi = apiCall({
      header: getHeaders({}),
      url: configJSON.urlForFooterApi,
      httpMethod: configJSON.urlForFooterApiType,
      httpBody: {}
    });

    this.footerApiCallId = footerApi.messageId;
    runEngine.sendMessage(footerApi.id, footerApi);
  }
}
