import React from "react";

// Customizable Area Start
import {
    Container,
    Box,
    Button,
    InputLabel,
    Typography,
    Grid,
    TextField,
    Select as MuiSelect,
    MenuItem,
    FormControl,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
} from "@material-ui/core";
import {
    withStyles,
} from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector";
import AppHeader from "../../../components/src/EmployeeAppHeader.web";
import AppFooter from "../../../components/src/AppFooter.web";
import { components } from "react-select";
import CompanyNameSelectorAutocomplete from "../../../components/src/CompanyNameSelectorAutocomplete";
// Customizable Area End


import { CustomGrid, TabPanel , TextArea } from "../../../components/src/common";
import HandleSignupRequestController, { Props } from "./HandleSignupRequestController";
import Loader from "../../../components/src/Loader.web";

export class HandleSignupRequest extends HandleSignupRequestController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    NoOptionsMessage = (props: any) => {
        return (
            <components.NoOptionsMessage {...props} align="left">
                <span className="custom-css-class" style={webStyle.optionTextColor}>{this.state.noOptionMessageText}</span>
            </components.NoOptionsMessage>
        );
    };

    perSonalDetails() {
        const { classes } = this.props;
        return (
            <>
                <Grid container spacing={7} alignItems="center" style={webStyle.paddingTop}>
                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="firstName" style={webStyle.inputLabel}>
                            {this.state.labelCustomerName}<span style={webStyle.errorText}>*</span>
                        </InputLabel>
                        <CustomGrid item md={12} lg={12} sm={12} xs={12} className={this.state.isLoading === true ? 'countryCodeIndex' : 'countryCodeIndex2'} style={webStyle.countryCodePadding}>
                            <CompanyNameSelectorAutocomplete
                                data-testid="customerNameInput"
                                isClearable={true}
                                dataSource={this.state.companyList}
                                selectedValue={this.state.selectedCompanyId}
                                handleChange={this.handleCompanyChange}
                                style={webStyle.select}
                                isDisabled={false}
                                placeHolder={this.state.customerNameSelectPlaceholder}
                            />
                        </CustomGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} style={{ ...webStyle.saveButtonPadding , ...webStyle.grids }}>
                        <SaveButton data-testid='SaveBtn' onClick={this.handleAddNewClick.bind(this)} variant="contained">{this.state.addNewbuttonLabel}</SaveButton>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="firstName" style={webStyle.inputLabel}>
                            {this.state.parentCustomerLabel}<span style={webStyle.errorText}>*</span>
                        </InputLabel>
                        <InputField
                            data-testid="firstName"
                            id={this.state.firstNameId}
                            variant="outlined"
                            disabled
                            value={this.state.firstName}
                            onChange={this.handleChange("firstName").bind(this)}
                            required
                            placeholder={this.state.firstNamePlaceholder}
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="lastName" style={webStyle.inputLabel}>
                            {this.state.lastNameLabel}<span style={webStyle.errorText}>*</span>
                        </InputLabel>
                        <InputField
                            data-testid="lastName"
                            id={this.state.lastNameId}
                            variant="outlined"
                            disabled
                            value={this.state.lastName}
                            required
                            onChange={this.handleChange("lastName").bind(this)}
                            placeholder={this.state.lastNamePlaceholder}
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="companyName" style={webStyle.inputLabel}>
                            {this.state.customerCompanyLabel}<span style={webStyle.errorText}>*</span>
                        </InputLabel>
                        <InputField
                            data-testid="companyName"
                            id={this.state.companyNameId}
                            variant="outlined"
                            disabled
                            value={this.state.companyName}
                            required
                            onChange={this.handleChange("companyName").bind(this)}
                            placeholder={this.state.companyNamePlaceholder}
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="emailLabel" style={webStyle.inputLabel}>
                            {this.state.emailLabel}<span style={webStyle.errorText}>*</span>
                        </InputLabel>
                        <InputField
                            id={this.state.emailLabelId}
                            variant="outlined"
                            required
                            disabled
                            value={this.state.email}
                            onChange={this.handleChange("email").bind(this)}
                            placeholder={this.state.emailIdPlaceholder}
                            fullWidth
                        />
                    </Grid>

                    <Grid container spacing={2} item lg={6} md={6} sm={6} xs={12}
                        className='countryCodeIndex'
                        style={{ ...webStyle.grids, ...webStyle.countryCodeGrid }}
                    >
                        <CustomGrid item md={6} lg={4} sm={12} xs={12}>
                            <InputLabel htmlFor="bamPhoneNo" style={webStyle.inputLabel}>
                                {this.state.countryCodeLabel}<span style={webStyle.errorText}>*</span>
                            </InputLabel>
                            <CountryCodeSelector
                                navigation={""}
                                style={{ }}
                                id={this.state.countryCodeId}
                                disable={true}
                                allowPropChange={false}
                                onChange={this.handlePhoneCountryCode}
                                value={this.state.phoneCountryCode}
                                placeHolder={this.state.countryCodePlaceholder}
                            />
                        </CustomGrid>
                        <Grid item lg={8} md={6} sm={12} xs={12} className={classes.countryCode}>
                            <InputField
                                id="phoneNumber"
                                variant="outlined"
                                inputMode="numeric"
                                inputProps={{
                                    maxLength: 14,
                                }}
                                type="tel"
                                disabled
                                value={this.state.phoneNumber}
                                onChange={this.handleChange("phoneNumber").bind(this)}
                                placeholder={this.state.phoneNumberPlaceholder}
                                fullWidth
                            />
                        </Grid>
                    </Grid>

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids} className={classes.country}>
                        <InputLabel htmlFor="mobileNum" style={webStyle.inputLabel}>
                            {this.state.countryLabel}<span style={webStyle.errorText}>*</span>
                        </InputLabel>
                        <InputField
                            id="country"
                            disabled
                            variant="outlined"
                            value={this.state.country}
                            onChange={this.handleChange("country").bind(this)}
                            required
                            placeholder={this.state.countryNamePlaceholder}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={12} sm={12} xs={12} style={webStyle.additionalGrid}>
                        <Typography
                            style={{ ...webStyle.detailTitle, ...webStyle.additionalDetailsLabel}}>
                            {this.state.additionalDetailLabel}
                        </Typography>
                    </Grid>

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="address" style={webStyle.inputLabel}>
                            {this.state.addressLabel}
                        </InputLabel>
                        <InputField
                            id="address"
                            variant="outlined"
                            value={this.state.address}
                            onChange={this.handleChange("address").bind(this)}
                            placeholder={this.state.completeAddressPlaceholder}
                            fullWidth
                        />
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="departmentLabel" style={webStyle.inputLabel}>
                            {this.state.departmentLabel}
                        </InputLabel>
                        <SelectField>
                            <CustomSelect
                                data-testid="department"
                                labelId="departmentLabel"
                                displayEmpty
                                id="departmentLabel"
                                variant="outlined"
                                fullWidth
                                value={this.state.department}
                                style={webStyle.select}
                                onChange={this.handleDepartmentChange.bind(this)}
                                defaultValue={""}
                            >
                                <MenuItem value={0}>
                                    <em style={webStyle.countryPlaceHolder}>
                                       {this.state.countryPlaceHolder}
                                    </em>
                                </MenuItem>
                                {this.state.departmentList.map((data) => {
                                    return (
                                        <MenuItem value={data.id} key={data.id}>
                                            {data.name}
                                        </MenuItem>
                                    );
                                })}
                                {this.state.departmentList.length === 0 && (
                                    <MenuItem value="noData" disabled>
                                        {this.state.noDataText}
                                    </MenuItem>
                                )}
                            </CustomSelect>
                        </SelectField>
                    </Grid>

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="designationLabel" style={webStyle.inputLabel}>
                            {this.state.designationLabel}
                        </InputLabel>
                        <InputField
                            id="designationLabel"
                            variant="outlined"
                            value={this.state.designation}
                            onChange={this.handleChange("designation").bind(this)}
                            placeholder={this.state.designationPlaceholder}
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="landlinelabel" style={webStyle.inputLabel}>
                            {this.state.landlinelabel}
                        </InputLabel>
                        <InputField
                            id="landlinelabel"
                            variant="outlined"
                            value={this.state.landlineNumber}
                            onChange={this.handleChange("landlineNumber").bind(this)}
                            placeholder={this.state.landlineNumberPlaceholder}
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="alternateEmailLabel" style={webStyle.inputLabel}>
                            {this.state.alternateEmailLabel}
                        </InputLabel>
                        <InputField
                            id="alternateEmailLabel"
                            variant="outlined"
                            value={this.state.alternateEmail}
                            onChange={this.handleChange("alternateEmail").bind(this)}
                            placeholder={this.state.alternateEmailPlaceholder}
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>

                    </Grid>

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="comments" style={webStyle.inputLabel}>
                            {this.state.commentFieldLabel}<span style={webStyle.errorText}>*</span>
                        </InputLabel>
                        <TextArea
                            id="comments"
                            multiline
                            rows={4}
                            required
                            variant="outlined"
                            placeholder={this.state.commentPlaceholder}
                            value={this.state.comments}
                            onChange={this.handleChange("comments").bind(this)}
                        />
                    </Grid>
                </Grid>

                <Grid container style={webStyle.marginTop} spacing={4}>
                    <SaveButton data-testid='BtnReject' onClick={() => this.handleSignupReject()} type="submit" variant="outlined" className={classes.rejectButton}>
                        {this.state.rejectButtonText}
                    </SaveButton>
                    <SaveButton data-testid='BtnApprove' onClick={() => this.handleSignupApproval()} type="submit" variant="contained" className={classes.approveButton}>
                        {this.state.approveButtonText}
                    </SaveButton>
                </Grid>
            </>
        );
    };

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <AppHeader navigation={this.props.navigation}/>
                <MainContainer>
                    <Loader loading={this.state.isLoading} data-testid="loader"/>
                    <Typography style={{ ...webStyle.detailTitle, ...webStyle.detailPaddingTop }}>
                        {this.state.userDetailLabel}
                    </Typography>
                    <form onSubmit={(e) => { e.preventDefault();}}>
                        <TabPanel value={this.state.selectedTab} index={0}>
                            {this.perSonalDetails()}
                        </TabPanel>
                    </form>
                    {
                        this.state.isCreatedPopup && (
                            <QueryDialog
                                open={this.state.isCreatedPopup}
                                onClose={this.handleIsCreatedClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle>
                                    <IconBox onClick={this.handleIsCreatedClose}>
                                        <CloseIcon style={webStyle.closeIcon}>
                                        </CloseIcon>
                                    </IconBox>
                                </DialogTitle>
                                {this.state.isReject ? <DialogContent>
                                    <Typography
                                        style={{ ...webStyle.PopUpText, ...webStyle.popUpTextPadding }}>
                                        {this.state.rejectPopupText}
                                    </Typography>
                                </DialogContent> : <React.Fragment><DialogContent>
                                    <Typography style={webStyle.PopUpText}>
                                        {this.state.createdCustomerPopupText}
                                    </Typography>
                                </DialogContent>
                                    <DialogActions>
                                        <CUSButton onClick={this.handleIsCreatedClose}>
                                            <Typography style={webStyle.custID}>
                                                {this.state.customer_id}
                                            </Typography>
                                        </CUSButton>
                                    </DialogActions></React.Fragment>}
                            </QueryDialog>

                        )
                    }
                    {
                        this.state.companyNotSameErrorPopUp && (
                            <QueryDialog
                                open={this.state.companyNotSameErrorPopUp}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle>
                                    <IconBox onClick={this.companyErrorPopUpClose} data-testid="closeIcon">
                                        <CloseIcon style={webStyle.closeIcon}>
                                        </CloseIcon>
                                    </IconBox>
                                </DialogTitle>
                                <DialogContent>
                                    <Typography
                                        style={{ ...webStyle.PopUpText, ...webStyle.popUpTextPadding }}>
                                        {this.state.companyNotSameError}
                                    </Typography>
                                </DialogContent>
                                <DialogActions>
                                    <SaveButton variant="outlined"  onClick={this.companyErrorPopUpClose}>
                                        {this.state.CancelButtonText}
                                    </SaveButton>
                                    <SaveButton variant="contained" onClick={this.handleSinupOk}>
                                        {this.state.yesButtonText}
                                    </SaveButton>
                                </DialogActions>
                            </QueryDialog>

                        )
                    }
                </MainContainer>
                <AppFooter isLoading={this.state.isLoading} />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const MainContainer:any = withStyles({
    root: {
        padding: "24px 36px 24px 48px",
        "& .css-view-1dbjc4n": {
            marginBottom: `0px !important`,
        },
        "& .css-yk16xz-control": {
            height: '40px',
        },
        "& .css-1hb7zxy-IndicatorsContainer": {
            height: '40px',
        },
        "& .css-1pahdxg-control": {
            borderColor: `rgba(218, 224, 241, 1)!important`,
            boxShadow: 'none',
            borderRadius: '8px',
            height: '40px',
        },
        "& .css-1fhf3k1-control": {
            backgroundColor: `white !important`,
            borderColor: "rgba(218, 224, 241, 1)",
            borderRadius: '8px'
        },
        "@media (max-width: 600px)": {
            padding: "24px 24px 24px 24px",
            "& .MuiBox-root-8": {
                padding: "24px 0px 24px 0px",
            },
            "& ..MuiBox-root-30": {
                padding: "24px 0px 24px 0px",
            },
            "& .MuiTypography-body1": {
                marginLeft: "0px !important",
            },
        },
    },
})(Container);

const IconBox: any = withStyles({
    root: {
        height: '24px',
        width: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#DAE0EC',
        borderRadius: '50%',
        float: 'right',
        padding: '0px 0px 0px 0px',
        marginTop: '-1px',
        "& .MuiSvgIcon-root": {
            height: '16px',
            width: '16px'
        },
    }
})(Box);

const CUSButton:any = withStyles({
    root: {
        color: "#71769D",
        backgroundColor: "#F3F4F9",
        height: "44px",
        width: 150,
        borderRadius: "8px",
        fontFamily: "SFProDisplay",
        fontWeight: 700,
        fontSize: "12px",
        marginTop: "5px",
        boxShadow: "none",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#F3F4F9",
            boxShadow: "none",
        },
        "@media (max-width: 600px)": {
            width: "100%",
            marginLeft: 0,
        },
    },
})(Button);

const QueryDialog:any = withStyles({
    root: {
        "& .MuiDialogTitle-root": {
            padding: '16px 0px'
        },
        "& .MuiDialog-paperScrollPaper": {
            minWidth: '500px',
            padding: '0px 10px 0px 10px',
            borderRadius: '10px',
        },
        "& .MuiDialogContent-root": {
            padding: '8px 24px',
            textAlign: 'center',
            paddingBottom: '25px'
        },
        "& .MuiDialog-paperWidthSm": {
            maxWidth: '500px',
        },
        "& .MuiDialogActions-root": {
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0px',
            paddingBottom: '30px',
        },
        "& .WithStyles(ForwardRef(Button))-root-10": {
            "@media (maxWidth: 600px)": {
                width: 300
            }
        }
    }
})(Dialog);
const webStyle = {
    additionalDetailsLabel: {
        padding: '0px 0px', 
        marginLeft: '0px', 
        marginTop: '15px'
    },
    additionalGrid: {
        padding: '5px 28px'
    },
    countryCodeGrid: {
        paddingRight: '10px'
    },
    saveButtonPadding: {
        marginTop: '16px',
    },
    countryCodePadding: {
        marginTop: "5px"
    },
    popUpTextPadding: {
        paddingBottom: '40px'
    },
    closeIcon: {
        fill: '#1A3B71', 
        cursor: 'hand'
    },
    detailPaddingTop: {
        paddingTop: '15px'
    },
    marginTop: {
        marginTop: '56px'
    },
    errorText: {
        color: "red" 
    },
    paddingTop: {
        paddingTop: '10px'
    },
    grids: {
        paddingTop: '18px',
        paddingBottom: '0px'
    },
    addNew: {
        boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.18)'
    },
    optionTextColor: {
        color: '#923231'
    },
    PopUpText: {
        fontWeight: 700,
        color: '#000000',
        fontFamily: 'SFProDisplay',
        fontSize: '20px',
        margin: '0px 15px',
    },
    custID: {
        fontWeight: 700,
        fontFamily: 'SFProDisplay',
        fontSize: '12px',

    },
    Tab1: {
        borderTopLeftRadius: "8px",
        borderBottomLeftRadius: "8px",
    },
    tab3: {
        borderEndEndRadius: "8px",
        borderTopRightRadius: "8px",
    },
    detailTitle: {
        color: "rgba(33, 43, 54, 1)",
        fontFamily: "SFProDisplay",
        fontWeight: 700,
        fontSize: "20px",
        marginLeft: "24px",
    },
    buttonStyle: {
        width: "100%",
        height: "45px",
        marginTop: "40px",
        border: "none",
        backgroundColor: "rgb(98, 0, 238)",
    },
    inputLabel: {
        fontSize: "16px",
        fontFamily: "SFProDisplay",
        fontWeight: 700,
        color: "#1A3B71",
        paddingBottom: "5px",
    },
    termsText: {
        color: "#727096",
        fontFamily: "SFProDisplay",
        fontWeight: 400,
        fontSize: "16px",
        paddingTop: "4px",
    },
    countryPlaceHolder: {
        fontStyle: "normal",
        fontFamily: "SFProDisplay",
        fontWeight: 400,
        fontSize: "16px",
        color: "rgba(218, 224, 241, 1)",
    },
    select: {
        height: "40px",
        borderRadius: "8px",
        marginTop: '5px',
    },
    checkedIcon: {
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='black'/%3E%3C/svg%3E\")",
        "input:hover ~ &": {},
        borderRadius: 4,
        border: "1px solid #727096",
        width: 15,
        height: 15,
        backgroundColor: "white",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    icon: {
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2,
        },
        borderRadius: 4,
        width: 15,
        height: 15,
        backgroundColor: "white",
        border: "1px solid #727096",
        "input:hover ~ &": {
            backgroundColor: "white",
        },
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    privacyText: {
        color: "rgba(69, 158, 255, 1)",
    },
    DetailBox: {
        height: '230px',
    },
    ButtonBox: {
        height: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        paddingBottom: '30px'
    },
};

const CustomSelect:any = withStyles({
    root: {
        "&.MuiOutlinedInput-input": {
            padding: '13px 14px',
        }
    }
})(MuiSelect);

const InputField:any = withStyles({
    root: {
        background: "inherite",
        margin: "5px 0px 0px 0px",
        "& ::placeholder": {
            color: 'rgba(218, 224, 241, 1)',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
            fontSize: '16px',
            opacity: 1,
        },
        "& .MuiInputBase-input": {
            height: '15px',
        },
        "& .MuiOutlinedInput-root": {
            fontFamily: "SFProDisplay",
            fontWeight: 400,
            backgroundColor: "rgba(255, 255, 255, 1)",
            fontSize: "16px",
            "& fieldset": {
                height: '45px',
                borderColor: "rgba(218, 224, 241, 1)",
                borderRadius: "8px",
                borderWidth: 1,
            },
            ".Mui-focused": {
                borderColor: "rgba(218, 224, 241, 1)",
            },
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(214, 214, 214, 1)",
        },
        "& .MuiOutlinedInput-input": {
            padding: `14.2px 14px !important`
        },
        "& .MuiFormHelperText-contained": {
            marginLeft: "0px !important",
        },
        "& .MuiInputBase-input.Mui-disabled": {
            background: "#F1F4FA",
            borderRadius: "8px",
            height: 13,
            color: 'rgba(106, 116, 165, 1)',
            fontSize: '16px',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
        },
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(218, 224, 241, 1)",
            borderWidth: '1px'
        }
    },
})(TextField);

const SelectField: any = withStyles({
    root: {
        width: '100%',
        "& .MuiInputBase-input": {
            fontFamily: "SFProDisplay",
            fontWeight: 500,
            backgroundColor: "rgba(255, 255, 255, 1)",
            fontSize: "14px",
            color: '#6a74a5',
        },
        "& .MuiOutlinedInput-input": {
            padding: '13px 16px 13px 24px'
        },
        "& .MuiOutlinedInput-root": {
            fontFamily: "SFProDisplay",
            fontWeight: 500,
            backgroundColor: "rgba(255, 255, 255, 1)",
            fontSize: "16px",
            color: '#6a74a5',
        },
        "& .MuiSelect-icon": {
            fill: "rgba(4, 60, 116, 1)",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#dae0f1",
            borderWidth: 1,
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#dae0f1",
            borderWidth: 1,
        },
    }
})(FormControl);

const SaveButton:any = withStyles({
    root: {
        color: "rgba(255, 255, 255, 1)",
        height: "40px",
        width: 150,
        borderRadius: "8px",
        fontFamily: "SFProDisplay",
        fontWeight: 700,
        fontSize: "18px",
        marginTop: "5px",
        boxShadow: "none",
        textTransform: "none",
        "&.MuiButton-contained": {
            backgroundColor: 'rgba(0, 61, 120, 1)',
            boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.18)',
            "&:hover": {
                backgroundColor: 'rgba(0, 61, 120, 1)',
            }
        },
        "&.MuiButton-outlined": {
            border: '1px solid rgba(0, 61, 120, 1)',
            color: 'rgba(0, 61, 120, 1)',
            padding: '5px 15px',
            "&:hover": {
                backgroundColor: 'transparent',
            }
        },
        "&:hover": {
            backgroundColor: "rgba(4, 60, 116, 1)",
            boxShadow: "none",
        },
        "@media (max-width: 600px)": {
            width: "100%",
            marginLeft: 0,
        },
    },
})(Button);

const styles = () => ({
    rejectButton: {
        marginRight: '10px',
        "@media (max-width: 600px)": {
            marginRight: "0px"
        }
    },
    approveButton: {
        marginLeft: '10px',
        "@media (max-width: 600px)": {
            marginLeft: "0px",
            marginTop: '15px'
        }
    },
    countryCode: {
        marginTop: '15px',
        "@media (max-width: 600px)": {
            marginTop: '0px !important'
        }
    },
    country: {
        paddingLeft: '40px !important',
        "@media (max-width: 600px)": {
            paddingLeft: '30px !important'
        }
    }
})

export default withStyles(styles)(HandleSignupRequest)
// Customizable Area End