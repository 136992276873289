import React from "react";

// Customizable Area Start
import {
  Container,
  Typography,
  Box,
  Button,
  Grid,
  Tooltip,
  Popover,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import { MainLogo } from "./assets";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';
import ErrorIcon from '@material-ui/icons/Error';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ProductDetailWebController, {
  Props
} from "./ProductDetailsController";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { AppHeader } from "../../../components/src/AppHeader.web";
import AppFooter from "../../../components/src/AppFooter.web";
import { navigateToLogin, navigateToSignup, showTextWithValidation, isEmployee } from "../../../components/src/common";
import Loader from "../../../components/src/Loader.web";
import EmployeeAppHeader from "../../../components/src/EmployeeAppHeader.web";
import { CheckboxWithLabel } from "../../../components/src/CheckboxWithLabel";
// Customizable Area End
export class ProductDetailWeb extends ProductDetailWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  ProductDetail() {
    const { classes } = this.props;
    return (
      <Grid>
        <Grid container style={{ justifyContent: "space-between" }}>
          <Grid item lg={this.state.productDetail?.attributes.chemical_structure ? 6 : 12} md={12} sm={12} xs={12} style={{ marginBottom: '15px' }}>
            <Typography style={webStyle.description}>
              {showTextWithValidation(this.state.productDetail?.attributes.product_description)}
            </Typography>
          </Grid>
          {this.state.productDetail?.attributes.chemical_structure && <Grid item lg={5} md={12} sm={12} xs={12}>
            {/* <div style={{backgroundColor: "red", padding: "50px"}} >  */}
            {
              this.state.productDetail?.attributes.chemical_structure && this.state.showChemicalName &&
              <Grid style={{ alignSelf: "center", }}>
                {/* @ts-ignore */}
                <Typography style={webStyle.descriptionStructure}  >
                  Chemical Structure - {this.state.productDetail?.attributes.name}
                </Typography>
              </Grid>
            }
            <div style={{ marginBottom: '15px', marginTop: '15px', }}>
              <div className={classes.chamicalFormulaImage}>
                <img src={this.state.productDetail?.attributes.chemical_structure != null ? this.state.productDetail?.attributes.chemical_structure : ""} style={webStyle.productImage2} />
              </div>
            </div>
          </Grid>}
        </Grid>



        <Typography style={webStyle.descriptionLabel}>{this.state.descriptionlabel}</Typography>
        <Grid item container xs={12} >
          {this.state.productDetail?.attributes.dmf_availabilities &&
            this.state.productDetail.attributes.dmf_availabilities.data &&
            this.state.productDetail.attributes.dmf_availabilities.data.map((dmf: any, index: number) => {
              return (
                dmf.attributes.available && 
              <div key={index}>
                {/* @ts-ignore */}
                <Box key={`dmf-avaibility-${index}`
                } className={classes.dmfWrapperRes}  >
                  <Typography
                    style={webStyle.dmfLabel}
                  >
                    {dmf.attributes.product_dmf}
                  </Typography>
                  {/* justifyContent="space-between" */}
                  {/* @ts-ignore */}
                  <Box display={'flex'} alignItems='center' mt='13px'  >
                    {dmf.attributes.available && <CheckboxWithLabel data-testid='CheckboxWithLabel' onChange={() => { console.log('Clicked') }} isChecked={dmf.attributes.available} label='Available' />}
                    {/* {dmf.attributes.in_progress && <CheckboxWithLabel data-testid='CheckboxWithLabelClick' onChange={() => { console.log('Clicked') }} containerStyle={{ marginLeft: `${dmf.attributes.available && "24"}` }} isChecked={dmf.attributes.in_progress} label='In Progress' />} */}
                  </Box>
                </Box>
              </div>
              )
            })
          }
          {
            this.state.productDetail?.attributes.dmf_availabilities === null && (
              <div style={{ paddingLeft: '45px' }}>
                --
              </div>
            )
          }
        </Grid>
      </Grid >
    );
  };

  // ProductDetailExactCopy() {
  //   return (
  //     <Grid>
  //       <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginBottom: '15px' }}>
  //         <Typography
  //           style={webStyle.description}
  //         >
  //           {showTextWithValidation(this.state.productDetail?.attributes.product_description)}
  //         </Typography>
  //       </Grid>
  //       <Typography style={webStyle.descriptionLabel}>{this.state.descriptionlabel}</Typography>
  //       <Grid container xs={12} >
  //         {this.state.productDetail?.attributes.dmf_availabilities && this.state.productDetail.attributes.dmf_availabilities.data && this.state.productDetail.attributes.dmf_availabilities.data.map((dmf: any, index: number) => {
  //           return <Grid key={`dmf-avaibility-${index}`} item lg={4} md={4} sm={6} xs={12} >
  //             <Typography
  //               style={webStyle.dmfLabel}
  //             >
  //               {dmf.attributes.product_dmf}
  //             </Typography>
  //             {/* @ts-ignore */}

  //             <Box display={'flex'} alignItems='center' mt='13px' >
  //               <CheckboxWithLabel onChange={() => { console.log('Clicked') }} isChecked={dmf.attributes.available} label='Available' />
  //               <CheckboxWithLabel onChange={() => { console.log('Clicked') }} containerStyle={{ marginLeft: 24 }} isChecked={dmf.attributes.in_progress} label='In Progress' />
  //             </Box>
  //           </Grid>
  //         })
  //         }
  //       </Grid>





  //       {/* <Typography style={webStyle.description}>
  //         {
  //           this.state.IsKnowMore ?
  //             (
  //               showTextWithValidation(this.state.productDetail?.attributes.dmf_availability.slice(0, 354))
  //             ) :
  //             (
  //               showTextWithValidation(this.state.productDetail?.attributes.dmf_availability)
  //             )
  //         }
  //         {
  //           this.state?.productDetail?.attributes.dmf_availability?.length !== undefined &&
  //           this.state?.productDetail?.attributes.dmf_availability?.length > 354 &&
  //           (
  //             this.state.IsKnowMore ?
  //               (
  //                 this.state.productDetail?.attributes.dmf_availability.slice(0, 354)
  //               ) :
  //               (
  //                 this.state.productDetail?.attributes.dmf_availability
  //               )
  //           )
  //         }
  //         {
  //           this.state?.productDetail?.attributes?.dmf_availability !== undefined && (
  //             this.state?.productDetail?.attributes?.dmf_availability?.length > 354 &&
  //             (
  //               this.state.IsKnowMore ?
  //                 (
  //                   <Typography style={webStyle.knowMoreLabel} component={"span"}
  //                     onClick={this.setDescription}
  //                   >
  //                     Read More..
  //                   </Typography>
  //                 ) :
  //                 (
  //                   <Typography style={webStyle.knowMoreLabel} component={"span"}
  //                     onClick={this.setDescription}
  //                   >
  //                     Read Less..
  //                   </Typography>
  //                 )
  //             )
  //           )
  //         }

  //       </Typography> */}
  //     </Grid>
  //   );
  // };

  Documents() {
    return (
      <Grid container spacing={2}>
        {
          this.state.productDetail?.attributes?.product_documents?.data && (this.state.productDetail?.attributes?.product_documents?.data.length || 0) > 0 ?
            this.state.productDetail?.attributes.product_documents.data.map((doc, index: number) => {
              return doc.attributes.product_documents.map((bucket, bucketIndex: number) => {
                return (
                  <Grid key={`documentlisting-${bucketIndex}`} item md={6} lg={6} sm={12} xs={12}>
                    {/* @ts-ignore */}
                    <Box style={webStyle.docBox} key={index} display="flex" justifyContent="space-between" alignItems="center">
                      <Grid container>
                        <Typography style={webStyle.docTitle}>
                          {showTextWithValidation(bucket.bucket)}
                        </Typography>
                        {
                          bucket.confidential && (
                            // @ts-ignore
                            
                            isEmployee() ?
                              <ErrorIcon
                                fontSize="small"
                                style={{ fill: "orange", padding: '3px 0px 0px 20px' }}
                              >
                              </ErrorIcon>
                            :
                            <ToolTips title={this.state.passwordTioolTip} enterTouchDelay={0} arrow placement="top">
                              <ErrorIcon
                                fontSize="small"
                                style={{ fill: "orange", padding: '3px 0px 0px 20px' }}
                              >
                              </ErrorIcon>
                            </ToolTips>
                          )
                        }
                      </Grid>
                      {/* @ts-ignore */}
                      <ViewButton data-testid='ViewButton' onClick={() => this.handleViewClick(bucket.product_document_id, bucket)}>
                        View
                      </ViewButton>
                    </Box>
                  </Grid>
                )
              })

            }) :
            <Typography style={webStyle.noDataText}>
              {this.state.noDocumentsAvailableText}
            </Typography>
        }
      </Grid>
    );
  };

  ChemicalNameToolTip(text: any) {
    return (
      // @ts-ignore

      <Box>
        <Grid>
          <CancelTwoToneIcon
            data-testid='CancelTwoToneIcon'
            style={{ fill: "rgba(4, 60, 116, 1)", float: 'right' }}
            onClick={(e: any) => this.handleToolTip(e, "")}
          >
          </CancelTwoToneIcon>
        </Grid>
        <Grid item lg={12}>
          <Typography style={webStyle.chemicalToolTip}>
            {this.state.popUPText}
          </Typography>
        </Grid>
      </Box >
    )
  };

  loginMsg = () => {
    return (
      `${this.state.isDocumentSelect ? 'Please login to view documents' : 'Please login to raise a query.'}`
    )
  }

  RaiseQueryPopUp() {
    const { classes } = this.props;
    return (
      // @ts-ignore
      <QueryDialog
        open={this.state.isQueryraised}
        onClose={this.raiseQuery}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '0px' }}
        >
          <Typography style={webStyle.queryPopUpText}>
            Raise a Request
          </Typography>
          <CloseIcon
            onClick={this.raiseQuery}
            style={{ float: 'right', marginTop: '-13px', marginLeft: '4px', fill: 'black' }}
          >
          </CloseIcon>
        </DialogContent>
        <DialogActions>

          <Grid container spacing={2} style={{ margin: '0px 10px' }}>
            {
              this.state.queryOptions.map((data: any, index) => {
                return (
                  <Grid item md={4} sm={4} xs={12} key={index}>
                    {/* @ts-ignore */}
                    <Buttons
                      data-testid='handleRaiseQueryChoice'
                      onClick={() => this.handleRaiseQueryChoice(data.route)}
                      variant="outlined"
                      style={webStyle.QueryOptions}
                      className={classes.raiseQueryBtnHover}
                    >
                      {data.option}
                    </Buttons>
                  </Grid>
                )
              })
            }
          </Grid>
        </DialogActions >
      </QueryDialog >
    );
  };

  guestPopup() {
    return (
      //  @ts-ignore 
      <GuestDialog
        open={this.state.isGuestPopup}
        onClose={this.closeGuestPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <CloseIcon
            onClick={this.closeGuestPopup}
            style={{
              float: 'right', marginTop: '-13px', marginLeft: '4px',
              fill: 'black', cursor: 'pointer'
            }}
          >
          </CloseIcon>
        </DialogTitle>
        <DialogContent>
          <Typography style={webStyle.queryPopUpText} align="center">
            {
              (this.state.isDMFBucket || localStorage.getItem("employee_document_view_permission") === "false") ?
                `Contact Administrator for accessing the document`
                :
                this.loginMsg()
            }
          </Typography>
        </DialogContent>
        <DialogActions>
          {/* @ts-ignore */}
          {
            this.state.isDMFBucket ?
              <AcceptButton
                onClick={() => this.setState({
                  isDMFBucket: false,
                  isGuestPopup: false
                })}
                style={{ width: '75px' }}
                variant="contained"
              >
                OK
              </AcceptButton>
              :
              <>
                <AcceptButton
                  onClick={() => navigateToSignup(this.props.navigation)}
                  variant="outlined"
                >
                  Signup
                </AcceptButton>
                {/* @ts-ignore */}
                <AcceptButton
                  onClick={() => navigateToLogin(this.props.navigation)}
                  variant="contained"
                >
                  Login
                </AcceptButton>
              </>
          }
        </DialogActions >
      </GuestDialog >
    );
  }

  shouldRaiseQueryPopup() {
    return (
      //@ts-ignore 
      <GuestDialog
        open={this.state.shouldRaiseQueryPopup}
        onClose={this.closeShouldRaiseQuery}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <CloseIcon
            onClick={this.closeShouldRaiseQuery}
            style={{
              float: 'right', marginTop: '-13px', marginLeft: '4px',
              fill: 'black', cursor: 'pointer'
            }}
          >
          </CloseIcon>
        </DialogTitle>
        <DialogContent>
          <Typography style={webStyle.queryPopUpText} align="center">
            Please raise a request to access the document
          </Typography>
        </DialogContent>
        <DialogActions>
          {/* @ts-ignore */}

          <AcceptButton
            onClick={this.closeShouldRaiseQuery}
            style={{ fontSize: 14 }}
            variant="outlined"
          >
            Cancel
          </AcceptButton>
          {/* @ts-ignore */}

          <AcceptButton
            onClick={this.handleRaiseQueryClick}
            style={{ fontSize: 14 }}
            variant="contained"
          >
            Raise a Request
          </AcceptButton>
        </DialogActions >
      </GuestDialog >
    );
  }



  // Customizable Area End

  render() {

    const { classes } = this.props;
    return (
      // Customizable Area Start
      <>
        {isEmployee() ?
          //@ts-ignore 
          <EmployeeAppHeader navigation={this.props.navigation} /> : <AppHeader navigation={this.props.navigation} />}
        {/* @ts-ignore */}

        <Loader loading={this.state.loading} />
        <MainContainer className="paddingClass2">
          {!this.state.loading && <Grid container alignItems="center" style={{ paddingBottom: '10px' }}>
            <Grid item xs={12} md={6} sm={6} style={{ display: 'flex', alignItems: 'center' }}>
              <KeyboardBackspaceIcon
                data-testid='KeyboardBackspaceIcon'
                style={{
                  ...webStyle.cardButtonBack,
                }}
                onClick={() => this.props.navigation.navigate('ProductListing', { pageNumber: 1 })}
                fontSize="medium"
              />
              <Typography style={webStyle.headerName}>
                {showTextWithValidation(this.state.productDetail?.attributes.therapeutic_area)}
              </Typography>
              <ArrowRightIcon />
              <Typography style={webStyle.headerName}>{showTextWithValidation(this.state.productDetail?.attributes.name)}</Typography>
            </Grid>

            <Grid item xs={12} md={6} sm={6} style={{ display: 'flex', justifyContent: 'center' }} className={classes.raiseQueryBtn}>
              {/* @ts-ignore */}
              {!isEmployee() && <ButtonGrid item xl={2} lg={4} sm={12} md={12} xs={12}  >
                {/* @ts-ignore */}
                <QueryButtons onClick={this.raiseQuery}>
                  Raise a Request
                </QueryButtons>
                {
                  this.state.isQueryraised && (
                    this.RaiseQueryPopUp()
                  )
                }
              </ButtonGrid>}
              {
                this.state.isGuestPopup && (
                  this.guestPopup()
                )
              }
              {this.state.shouldRaiseQueryPopup && this.shouldRaiseQueryPopup()}
            </Grid>
          </Grid>
          }
          <Grid container>
            <Grid item lg={2} md={3} sm={4} xs={12}
              style={{
                position: 'relative',
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <img src={MainLogo} className={classes.productImage} style={webStyle.productImage} />
              {/* @ts-ignore */}

              <ProductName>
                <Typography style={webStyle.proImageName} align="center">
                  {showTextWithValidation(this.state.productDetail?.attributes.name)}
                </Typography>
              </ProductName>
            </Grid>
            <Grid className={classes.productDetail} item lg={10} md={9} sm={8} xs={12}>
              <Grid container spacing={2}>
                <Grid item md={5} lg={4} sm={6} xs={12}>
                  <Typography style={webStyle.areaLabel}>
                    {this.state.TherapueticAreaLabel}
                  </Typography>
                  {/* @ts-ignore */}
                  <Typography style={webStyle.areaValue}>
                    {showTextWithValidation(this.state.productDetail && this.state.productDetail?.attributes.therapeutic_area.slice(0, 45))}
                    {this.state.productDetail?.attributes.therapeutic_area !== undefined &&
                      this.state.productDetail?.attributes.therapeutic_area.length > 45 && (
                        <>
                          <Typography
                            data-testid='TypographyEvent'
                            component={"span"}
                            style={webStyle.dotVal}
                            aria-owns={this.state.openToolTip ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onClick={(e: any) => this.handleToolTip(e, this.state.productDetail?.attributes.therapeutic_area)}
                            onMouseEnter={(e: any) => this.handleToolTip(e, this.state.productDetail?.attributes.therapeutic_area)}
                            onMouseLeave={(e: any) => this.handleToolTip(e, this.state.productDetail?.attributes.therapeutic_area)}
                          >...</Typography>
                          <Typography
                            data-testid='TypographyPassEvent'
                            component={"span"}
                            style={webStyle.ReadMoreLabel}
                            aria-owns={this.state.openToolTip ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onClick={(e: any) => this.handleToolTip(e, this.state.productDetail?.attributes.therapeutic_area)}
                            onMouseEnter={(e: any) => this.handleToolTip(e, this.state.productDetail?.attributes.therapeutic_area)}
                          >
                            Read More
                          </Typography>
                        </>
                      )
                    }
                  </Typography>
                </Grid>

                <Grid item md={4} lg={4} sm={6} xs={12}>
                  <Typography style={webStyle.areaLabel} noWrap={true}>
                    {this.state.ApiTechnologylabel}
                  </Typography>
                  {/* @ts-ignore */}
                  <Typography style={webStyle.areaValue}>
                    {showTextWithValidation(this.state.productDetail?.attributes.manufacturing_platform.slice(0, 45))}
                    {this.state.productDetail?.attributes.manufacturing_platform !== undefined &&
                      this.state.productDetail?.attributes.manufacturing_platform.length > 45 && (
                        <>
                          <Typography
                            data-testid='manufacturingplatform'
                            component={"span"}
                            style={webStyle.dotVal}
                            aria-owns={this.state.openToolTip ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onClick={(e: any) => this.handleToolTip(e, this.state.productDetail?.attributes.manufacturing_platform)}
                            onMouseEnter={(e: any) => this.handleToolTip(e, this.state.productDetail?.attributes.manufacturing_platform)}
                            onMouseLeave={(e: any) => this.handleToolTip(e, this.state.productDetail?.attributes.manufacturing_platform)}
                          >...</Typography>
                          <Typography
                            data-testid='manufacturingplatformEvent'
                            component={"span"}
                            style={webStyle.ReadMoreLabel}
                            aria-owns={this.state.openToolTip ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onClick={(e: any) => this.handleToolTip(e, this.state.productDetail?.attributes.manufacturing_platform)}
                            onMouseEnter={(e: any) => this.handleToolTip(e, this.state.productDetail?.attributes.manufacturing_platform)}
                          >
                            Read More
                          </Typography>
                        </>
                      )
                    }
                  </Typography>
                </Grid>

                <Grid item md={3} lg={3} sm={6} xs={12}>
                  <Typography style={webStyle.CategoryLabel}>
                    {this.state.Category}
                  </Typography>
                  <Typography style={webStyle.CategoryValue}>
                    {showTextWithValidation(this.state.productDetail?.attributes.category.slice(0, 29))}
                    {this.state.productDetail?.attributes.category && this.state.productDetail?.attributes.category.length > 29 && (
                      <>
                        <Typography
                          data-testid='category'
                          component={"span"}
                          style={webStyle.dotVal}
                          aria-owns={this.state.openToolTip ? 'mouse-over-popover' : undefined}
                          aria-haspopup="true"
                          onClick={(e: any) => this.handleToolTip(e, this.state.productDetail?.attributes.category)}
                          onMouseEnter={(e: any) => this.handleToolTip(e, this.state.productDetail?.attributes.category)}
                          onMouseLeave={(e: any) => this.handleToolTip(e, this.state.productDetail?.attributes.category)}
                        >...</Typography>
                        <Typography
                          data-testid='categoryEvent'
                          component={"span"}
                          style={webStyle.ReadMoreLabel}
                          aria-owns={this.state.openToolTip ? 'mouse-over-popover' : undefined}
                          aria-haspopup="true"
                          onClick={(e: any) => this.handleToolTip(e, this.state.productDetail?.attributes.category)}
                          onMouseEnter={(e: any) => this.handleToolTip(e, this.state.productDetail?.attributes.category)}
                        >
                          Read More
                        </Typography>
                      </>
                    )
                    }
                  </Typography>
                </Grid>

                <Grid item md={5} lg={4} sm={6} xs={12}>
                  <Typography style={webStyle.areaLabel} noWrap={true}>
                    {this.state.ChemicalLabel}
                  </Typography>
                  {/* @ts-ignore */}
                  <Typography style={webStyle.areaValue}>
                    {showTextWithValidation(this.state.productDetail?.attributes.chemical_name.slice(0, 45))}
                    {this.state.productDetail?.attributes.chemical_name !== undefined &&
                      this.state.productDetail?.attributes.chemical_name.length > 45 && (
                        <>
                          <Typography
                            data-testid='chemicalname'
                            component={"span"}
                            style={webStyle.dotVal}
                            aria-owns={this.state.openToolTip ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onClick={(e: any) => this.handleToolTip(e, this.state.productDetail?.attributes.chemical_name)}
                            onMouseEnter={(e: any) => this.handleToolTip(e, this.state.productDetail?.attributes.chemical_name)}
                            onMouseLeave={(e: any) => this.handleToolTip(e, this.state.productDetail?.attributes.chemical_name)}
                          >...</Typography>
                          <Typography
                            data-testid='chemicalnameEvent'
                            component={"span"}
                            style={webStyle.ReadMoreLabel}
                            aria-owns={this.state.openToolTip ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onClick={(e: any) => this.handleToolTip(e, this.state.productDetail?.attributes.chemical_name)}
                            onMouseEnter={(e: any) => this.handleToolTip(e, this.state.productDetail?.attributes.chemical_name)}
                          >
                            Read More
                          </Typography>
                        </>
                      )
                    }
                    {/* @ts-ignore */}

                    <ChemicalDetailPopover
                      data-testid='ChemicalDetailPopover'
                      id="mouse-over-popover"
                      open={this.state.openToolTip}
                      anchorEl={this.state.anchorEl}
                      disableRestoreFocus
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      onClose={(e: any) => this.handleToolTip(e, this.state.productDetail?.attributes.chemical_name)}
                    >
                      {this.ChemicalNameToolTip(this.state.productDetail?.attributes.chemical_name)}
                    </ChemicalDetailPopover>
                  </Typography>
                </Grid>

                {this.state.productDetail?.attributes.cas_number && <Grid item md={4} lg={4} sm={6} xs={12} style={{ paddingTop: '15px' }}>
                  <Typography style={webStyle.areaLabel} noWrap={true}>
                    CAS No.
                  </Typography>
                  {/*@ts-ignore  */}
                  <Typography style={webStyle.areaValue}>
                    {this.state.productDetail?.attributes.cas_number != null ? this.state.productDetail?.attributes.cas_number.toUpperCase() : "-"}
                  </Typography>
                </Grid>}
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ justifyContent: "space-between" }}>
            <Grid container spacing={2}>
              <Grid item>
                {/* @ts-ignore */}

                <Buttons
                  data-testid='changeDetails'
                  variant={this.state.selectedOption === 'Description' ? "contained" : 'outlined'}
                  onClick={() => this.changeDetails('Description')}
                >
                  Description
                </Buttons>
              </Grid>
              <Grid item>
                {/* @ts-ignore */}

                <Buttons variant={this.state.selectedOption === 'Documents' ? "contained" : 'outlined'}
                  data-testid='changeDetailsDocuments'
                  onClick={() => {
                    this.changeDetails('Documents')
                  }}>
                  Documents
                </Buttons>
              </Grid>
            </Grid>
            {/* {
              this.state.productDetail?.attributes.chemical_structure && this.state.showChemicalName &&
              <Grid style={{ alignSelf: "center", marginRight: "25%" }}>
                <Typography style={webStyle.descriptionStructure}  >
                  Chemical Structure - {this.state.productDetail?.attributes.name}
                </Typography>
              </Grid>
            } */}
          </Grid>
          {
            this.state.selectedOption === 'Description' ?
              (
                this.ProductDetail()
              ) :
              (
                this.Documents()
              )
          }

        </MainContainer>
        {
          this.state.productDetail && (
            <AppFooter isLoading={this.state.loading} />
          )
        }

      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  proImageName: {
    fontSize: '20px',
    color: 'white',
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    padding: '5px 10px',
  },
  headerName: {
    color: 'rgba(0, 0, 0, 0.85)',
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '15px',
    cursor: 'pointer'
  },
  cardButtonBack: {
    color: "rgb(4, 60, 116)",
    margin: "0px 3px 0px 3px",
    cursor: 'pointer'
  },
  productTitle: {
    fontSize: '22px',
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    padding: '10px 10px 10px 0px',
    display: 'flex',
    alignItems: 'center',
  },
  productImage: {
    width: '100%',
    marginBottom: '10px'
  },
  productImage2: {

  },
  areaLabel: {
    fontWeight: 700,
    color: 'rgba(4, 60, 116, 1)',
    fontFamily: 'SFProDisplay',
    fontSize: '15px',
    margin: '0px 10px 0px 10px',
  },
  areaValue: {
    fontWeight: 700,
    fontFamily: 'SFProDisplay',
    fontSize: '15px',
    color: 'rgba(0, 0, 0, 0.85)',
    padding: '0px 10px 0px 10px',
    wordWrap: 'break-word',
  },
  CategoryLabel: {
    fontWeight: 700,
    color: 'rgba(4, 60, 116, 1)',
    fontFamily: 'SFProDisplay',
    fontSize: '16px',
    padding: '0px 10px 0px 10px'
  },
  CategoryValue: {
    fontWeight: 700,
    fontFamily: 'SFProDisplay',
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.85)',
    padding: '0px 10px 0px 10px'
  },
  dotVal: {
    fontWeight: 700,
    fontFamily: 'SFProDisplay',
    fontSize: '18px',
    color: 'rgba(0, 0, 0, 0.85)',
    padding: '0px 0px 0px 0px',
  },
  ReadMoreLabel: {
    fontWeight: 700,
    fontFamily: 'SFProDisplay',
    fontSize: '15px',
    color: 'rgba(0, 169, 255, 1)',
    padding: '0px 0px 0px 1px',
    textDecoration: 'none',
    marginLeft: '2px',
    "&:hover": {
      textDecoration: 'underline',
    },
  },
  chemicalToolTip: {
    color: 'rgba(4, 60, 116, 1)',
    fontWeight: 700,
    fontFamily: 'SFProDisplay',
    fontSize: '14px',
    padding: '12px 5px 10px 5px'
  },
  description: {
    fontWeight: 700,
    color: 'rgba(0, 0, 0, 0.85)',
    fontFamily: 'SFProDisplay',
    fontSize: '14px',
  },
  descriptionStructure: {
    fontWeight: 700,
    color: 'rgba(4, 60, 116, 1)',
    fontFamily: 'SFProDisplay',
    fontSize: '16px',
    alignSelf: "center",
    textAlign: 'center',

  },
  descriptionLabel: {
    fontWeight: 700,
    color: 'rgba(4, 60, 116, 1)',
    fontFamily: 'SFProDisplay',
    fontSize: '18px',
    paddingBottom: '5px',
  },
  dmfLabel: {
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.85)',
    fontFamily: 'SFProDisplay',
    fontSize: '16px',
    marginTop: '16px'
  },
  docBox: {
    border: '1px solid rgba(151, 151, 151, 1)',
    borderRadius: 8,
    padding: '5px 0px 5px 10px'
  },
  docTitle: {
    fontWeight: 700,
    color: 'rgba(20, 20, 20, 1)',
    fontFamily: 'SFProDisplay',
    fontSize: '16px',
  },
  knowMoreLabel: {
    fontWeight: 700,
    fontFamily: 'SFProDisplay',
    fontSize: '14px',
    color: 'rgba(0, 169, 255, 1)',
    padding: '5px 0px 0px 1px',
    cursor: 'pointer',
    marginLeft: '5px'
  },
  queryPopUpLabel: {
    fontWeight: 700,
    color: 'rgba(33, 43, 54, 1)',
    fontFamily: 'SFProDisplay',
    fontSize: '18px'
  },
  queryPopUpText: {
    fontWeight: 700,
    color: 'rgba(33, 43, 54, 1)',
    fontFamily: 'SFProDisplay',
    fontSize: '16px',
    margin: '0px 15px',
  },
  popUpButtons: {
    height: '40px',
    width: '135px',
    borderRadius: '8px',
    borderWidth: 2,
    margin: '14px 8px'
  },
  QueryOptions: {
    height: '38px',
    borderRadius: '8px',
    borderWidth: 2,
    width: '130px',
    margin: '6px 0px'
  },
  noDataText: {
    fontWeight: 700,
    color: 'rgba(4, 60, 116, 1)',
    fontFamily: 'SFProDisplay',
    fontSize: '16px',
    margin: '0px 10px 0px 10px',
  },
};

const MainContainer: any = withStyles({
  root: {
    padding: '20px 60px 65px 75px',
    "@media (max-width: 600px)": {
      padding: '20px 10px 65px 10px'
    }
  }
})(Container);

const QueryButtons = withStyles({
  root: {
    background: '#1A3B71',
    height: '35px',
    width: '140px',
    color: 'rgba(255, 255, 255, 1)',
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '14px',
    textTransform: 'none',
    borderRadius: '45px',
    "&:hover": {
      background: '#1A3B71',
    },
    "@media (max-width: 800px)": {
      marginBottom: '2px'
    },
    "@media (max-width: 600px)": {
      float: 'left !important',
      marginTop: 10,
      marginBottom: 10,
    },
    "@media (max-width: 1023px)": {
      float: 'left !important',
      marginTop: 10,
      marginBottom: 10,
    }
  }
})(Button);

const Buttons = withStyles({
  root: {
    height: '35px',
    width: 150,
    fontWeight: 700,
    color: 'rgba(255, 255, 255, 1)',
    fontFamily: 'SFProDisplay',
    fontSize: '14px',
    textTransform: 'none',
    boxShadow: 'none',
    margin: '15px 0px 15px 0px',
    "&.MuiButton-contained": {
      backgroundColor: 'rgba(4, 60, 116, 1)',
      "&:hover": {
        backgroundColor: 'rgba(4, 60, 116, 1)',
      }
    },
    "&.MuiButton-outlined": {
      border: '1px solid rgba(4, 60, 116, 1)',
      color: 'rgba(4, 60, 116, 1)',
      padding: '5px 0px',
      "&:hover": {
        backgroundColor: 'transparent',
      }
    },
    "&:hover": {
      boxShadow: 'none',
    },
  },
})(Button);

const ViewButton = withStyles({
  root: {
    height: '30px',
    width: '130px',
    fontWeight: 700,
    background: 'rgba(232, 243, 255, 1)',
    color: 'rgba(0, 169, 255, 1)',
    fontFamily: 'SFProDisplay',
    fontSize: '18px',
    textTransform: 'none',
    boxShadow: 'none',
    margin: '1px 10px 0px 0px',
    borderRadius: 8,
    "&:hover": {
      background: 'rgba(232, 243, 255, 1)',
    }
  },
})(Button);

const ToolTips = withStyles({
  tooltip: {
    color: 'rgba(255, 191, 0, 1)',
    fontWeight: 400,
    fontFamily: 'SFProDisplay',
    fontSize: '14px',
    backgroundColor: "rgba(255, 255, 255, 1)",
    boxShadow: "3px 4px 25px 2px lightGray",
    "& .MuiTooltip-arrow": {
      color: "rgba(255, 255, 255, 1)",
    }
  }
})(Tooltip);

const ChemicalDetailPopover = withStyles({
  paper: {
    color: 'rgba(4, 60, 116, 1)',
    fontWeight: 700,
    fontFamily: 'SFProDisplay',
    fontSize: '20px',
    backgroundColor: "rgba(255, 255, 255, 1)",
    boxShadow: "3px 4px 25px 2px lightGray",
    minWidth: 300,
    maxWidth: 500,
    borderRadius: 8,
    padding: '5px 10px',
    overflowX: "unset",
    overflowY: "unset",
    "&::before": {
      backgroundColor: "rgba(255, 255, 255, 1)",
      content: '""',
      display: "block",
      position: "absolute",
      width: 14,
      height: 14,
      top: -6,
      transform: "rotate(45deg)",
      left: "calc(50% - 10px)",
    },
    "@media (max-width: 980px)": {
      width: '68%',
    }
  }
})(Popover);

const QueryDialog = withStyles({
  root: {
    "& .MuiDialog-paperScrollPaper": {
      minWidth: 'fit-content',
      padding: '0px 10px 0px 10px'
    },
    "& .MuiDialogContent-root": {
      paddingLeft: '2px'
    },
    "& .MuiDialogActions-root": {
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px',
      paddingBottom: '8px',
    },
    "& .WithStyles\(ForwardRef\(Button\)\)-root-10": {
      "@media (maxWidth: 600px)": {
        width: 300
      }
    }
  }
})(Dialog);

const GuestDialog = withStyles({
  root: {
    "& .MuiDialog-paperScrollPaper": {
      padding: '0px 10px 0px 10px',
      borderRadius: '8px',
    },
    "& .MuiDialogActions-root": {
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px',
      paddingBottom: '8px',
    },
    "& .WithStyles\(ForwardRef\(Button\)\)-root-10": {
      "@media (max-width: 600px)": {
        width: 300
      }
    },
    "& .MuiDialogTitle-root": {
      paddingTop: `20px !important`,
      paddingRight: `0px !important`,
      paddingBottom: `0px !important`
    },
    "& .MuiDialogContent-root": {
      paddingTop: `3px !important`,
      paddingLeft: '2px'
    }
  }
})(Dialog);

const ProductName = withStyles({
  root: {
    position: 'absolute',
    top: '47%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '75%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
})(Grid);

const ButtonGrid = withStyles({
  root: {
    marginLeft: '12.6%',
    "@media (min-width: 1800px)": {
      marginLeft: '14%',
    },
    "@media (min-width: 2000px)": {
      marginLeft: '15%',
    },
    "@media (min-width: 3000px)": {
      marginLeft: '16%',
    },
    "@media (max-width: 1220px)": {
      marginLeft: '-5%',
    },
    "@media (max-width: 1023px)": {
      marginLeft: '0px',
    }
  }
})(Grid);

const AcceptButton = withStyles({
  root: {
    width: "140px",
    color: 'rgba(255, 255, 255, 1)',
    opacity: 1,
    boxShadow: "none",
    height: "40px",
    borderRadius: "6px",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    fontSize: "18px",
    margin: "20px 0px 15px 0px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1A3B71",
      boxShadow: "none",
    },
    "&.MuiButton-contained": {
      backgroundColor: 'rgba(4, 60, 116, 1)',
      "&:hover": {
        backgroundColor: 'rgba(4, 60, 116, 1)',
      }
    },
    "&.MuiButton-outlined": {
      border: '1px solid rgba(4, 60, 116, 1)',
      color: 'rgba(4, 60, 116, 1)',
      padding: '5px 0px',
      "&:hover": {
        backgroundColor: 'transparent',
      }
    },
    "@media (max-width: 500px)": {
      width: '100%',
    }
  },
})(Button);

const styles = () => ({
  productDetail: {
    paddingTop: '10px',
    paddingLeft: '30px',
    "@media (max-width: 600px)": {
      paddingLeft: '0px'
    }
  },
  productImage: {
    "@media (max-width: 600px)": {
      width: '95% !important'
    }
  },
  raiseQueryBtn: {
    "@media (max-width: 1000px)": {
      justifyContent: 'flex-end !important'
    },
    '& button': {
      float: 'right !important',
    }
  },
  raiseQueryBtnHover: {
    '&:hover': {
      backgroundColor: '#11213b !important',
      color: '#fff',
      borderColor: '#11213b'
    }
  },
  chamicalFormulaImage: {
    width: '75%',
    height: 'auto',
    maxHeight: '290px',
    '& img': {
      objectFit: 'cover',
      objectPosition: 'center',
      maxWidth: '100%',
    }
  },
  dmfWrapperRes: {
    marginRight: '50px',
    "@media (max-width: 700px)": {
      margin: '8px',
      minWidth: '120px'
    }
  }
})

export default withStyles(styles)(ProductDetailWeb)

// Customizable Area End
