Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "DocumentDistribution";
exports.labelBodyText = "DocumentDistribution Body";

exports.TherapueticAreaLabel= "Therapeutic area";
exports.TherapueticAreaLabel2= "Therapeutic area";
exports.ApiTechnologylabel= "Manufacturing platform";
exports.Category= "Category";
exports.ChemicalLabel= "Chemical Name";
exports.descriptionlabel= "DMF Availability";
exports.documentLabel= "Documents";
exports.passwordTioolTip= "Please ask permission by raising product query to access this file";

exports.btnExampleTitle = "CLICK ME";
exports.getProductDetailApiEndPoint = "catalogue/catalogues/";
exports.getProductDetailApiMethod = "GET";
exports.noDocumentsAvailableText = "No Documents Found";
exports.getFilesListApiEndpoint = "bx_block_catalogue/catalogues/open_folder";
exports.getFilesListApiMethod = "GET";
exports.getEmployeeFilesListApiEndpoint = "bx_block_catalogue/catalogues/employee_open_folder";
exports.getEmployeeFilesListApiMethod = "GET";
exports.getCustomerProfileApiEndpoint = "bx_block_customer_profile/customer_profiles/";
// Customizable Area End