import React from "react";

import {
    withStyles,
} from "@material-ui/core/styles";
import {
    Grid,
    Box,
    Container,
    TextField,
    InputAdornment,
    IconButton,
    TableRow,
    TableHead,
    TableCell,
    TableSortLabel,
    TableBody,
    Table,
    Button,
    Typography,
    TableContainer,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import AppHeader from "../../../components/src/EmployeeAppHeader.web";
import AppFooter from "../../../components/src/AppFooter.web";
import ArrowDropDownSharpIcon from '@material-ui/icons/ArrowDropDownSharp';
import ArrowDropUpSharpIcon from '@material-ui/icons/ArrowDropUpSharp';
import DownloadIcon from '@material-ui/icons/GetApp';
import AdminConsole3Controller, {
    ICustomerMasterTicket,
    Props,
} from "./MasterCustomerController";
import CustomPagination from "../../../components/src/CustomPagination.web";
import { CSVLink } from 'react-csv';
import { NavLink as RLink } from "react-router-dom";
import Loader from "../../../components/src/Loader.web";
import CachedIcon from '@material-ui/icons/Cached';
import CloseIcon from '@material-ui/icons/Close';

const PER_PAGE = 10;
export class MasterCustomer extends AdminConsole3Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { classes } = this.props
        return (
            // Customizable Area Start
            <>
                <AppHeader navigation={this.props.navigation} />
                <MainContainer className={classes.container} >
                    <Loader loading={this.state.isLoading} />
                    <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                        <Typography style={webStyle.heading}>
                            Customer Master
                        </Typography>

                           
                        {
                            this.state.isOnlyView && (
                                <AddButton onClick={() => this.handleAddNewClick()} data-testid="addnewbtn">
                                    Add New
                                </AddButton>
                            )
                        }
                    </Grid>
                    <SearchContainer container spacing={3}>
                        <Grid item lg={4} md={4} sm={4} xs={12}>
                            <InputField
                                id="ticketId"
                                data-testid='customerIdsearch'
                                variant="outlined"
                                value={this.state.ticketId}
                                onChange={this.handleChange("ticketId").bind(this)}
                                required
                                placeholder="Search by Customer ID"
                                fullWidth
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                edge="end"
                                            >
                                                <SearchIcon style={{ fill: '#8B95AF' }}></SearchIcon>
                                            </IconButton>
                                        </InputAdornment>,
                                    endAdornment: this.state.ticketId && (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    edge="end"
                                                    onClick={this.handleChange("ticketId").bind(this)}
                                                >
                                                <CloseIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                }}
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={12}>
                            <InputField
                                id="productName"
                                data-testid="customerNameSearch"
                                variant="outlined"
                                value={this.state.productName}
                                onChange={this.handleChange("productName").bind(this)}
                                required
                                placeholder="Search by Customer Name"
                                fullWidth
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                edge="end"
                                            >
                                                <SearchIcon style={{ fill: '#8B95AF' }}></SearchIcon>
                                            </IconButton>
                                        </InputAdornment>,
                                        endAdornment: this.state.productName && (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    edge="end"
                                                    onClick={this.handleChange("productName").bind(this)}
                                                >
                                                <CloseIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                }}
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={12}>
                            <InputField
                                id="customerName"
                                data-testid="industrySearch"
                                variant="outlined"
                                value={this.state.customerName}
                                onChange={this.handleChange("customerName").bind(this)}
                                required
                                placeholder="Search by Industry"
                                fullWidth
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                edge="end"
                                            >
                                                <SearchIcon style={{ fill: '#8B95AF' }}></SearchIcon>
                                            </IconButton>
                                        </InputAdornment>,
                                        endAdornment: this.state.customerName && (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    edge="end"
                                                    onClick={this.handleChange("customerName").bind(this)}
                                                >
                                                <CloseIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                }}
                            />
                        </Grid>
                    </SearchContainer>
                    <TableContainer style={{ width: '100%', ...webStyle.table }}>
                        <Table aria-labelledby="tableTitle"size={'medium'}>
                            <TableHead style={{ background: '#E3EFFD' }}>
                                <TableRow>
                                    {this.state.headCells.map((headCell) => (
                                        <TableCell
                                            style={{
                                                maxWidth: '128px', background: '#E3EFFD',
                                                borderColor: 'rgba(170, 188, 206, 1)'
                                            }}
                                            align={headCell.numeric}
                                            key={headCell.id}
                                            padding={headCell.disablePadding ? 'none' : 'normal'}
                                            sortDirection={this.state.orderBy === headCell.label ? this.state.order : false}
                                        >
                                            {
                                                headCell.isSorting ? (

                                                    <TableSortLabel
                                                        active={headCell.isSorting}
                                                        direction={this.state.orderBy === headCell.id ? this.state.order : 'asc'}
                                                        style={webStyle.headerText}
                                                        data-testid="tablesortlable"
                                                    >
                                                        {headCell.label}
                                                        <div style={{ paddingLeft: '5px' }}>
                                                            {
                                                                this.state.orderBy === headCell.id &&
                                                                    this.state.order !== 'asc' ?
                                                                    <ArrowDropDownSharpIcon></ArrowDropDownSharpIcon>
                                                                    :
                                                                    <ArrowDropUpSharpIcon></ArrowDropUpSharpIcon>
                                                            }
                                                        </div>
                                                    </TableSortLabel>
                                                ) : headCell.label.toString() === "Customer ID" ?
                                                    <div style={{display: 'flex'}}>
                                                       <Typography style={{ ...webStyle.headerText, textAlign: headCell.numeric }}>
                                                          {headCell.label}
                                                        </Typography>
                                                        <CachedIcon 
                                                           onClick={() => this.handleTicketRefresh()}
                                                           style={{fill: 'rgb(0, 62, 118)', marginBottom: '-8px', marginLeft: '5px'}}
                                                        />
                                                    </div> :
                                                    <Typography
                                                        style={webStyle.headerText}
                                                        className={headCell.numeric === "center" ? 'detailsCol' : ''}>
                                                        {headCell.label}
                                                    </Typography>
                                            }

                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.customerTickets && this.state.customerTickets.map((row: ICustomerMasterTicket, index: number) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.id}
                                        >
                                            <TableCell style={webStyle.dataText}>
                                                {row.attributes.customer_id}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                style={webStyle.dataText}
                                            >
                                                {row.attributes.customer_name}
                                            </TableCell>
                                            <TableCell style={webStyle.dataText}>
                                                {row.attributes.parent_customer_name}
                                            </TableCell>
                                            <TableCell style={{ ...webStyle.dataText, paddingLeft: '18px' }}>
                                                {row.attributes.customer_sap_code}
                                            </TableCell>
                                            <TableCell style={webStyle.dataText}>
                                                {row.attributes.industry}
                                            </TableCell>
                                            <TableCell style={{ ...webStyle.dataText }}>
                                                <RLink to={`/customer/edit/${row.id}`}
                                                    exact={true}>
                                                    <DetailButoon variant="contained" style={{ float: 'right' }} data-testid="detailsbtn">
                                                        View Details
                                                    </DetailButoon>
                                                </RLink>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        {
                            this.state.customerTickets && this.state.customerTickets.length === 0 && (
                                <Grid item lg={12} md={12} sm={12} style={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography style={{ ...webStyle.noData, display: 'flex', justifyContent: 'center' }}>
                                        No Records Found
                                    </Typography>
                                </Grid>
                            )
                        }
                    </TableContainer>
                    <Grid container alignItems="center" style={{ paddingTop: '10px', alignItems: 'center' }}>
                        <Grid item xs={5} sm={4} lg={5}>
                            <Grid container alignItems="center" className={classes.downloadContainer}   >
                                <DownloadBox>
                                    <DownloadIcon style={webStyle.downloadIcon}></DownloadIcon>
                                </DownloadBox>
                                <Button onClick={this.downloadCustomerMasterTickets} data-testid="downloadbtn">
                                    <Typography style={webStyle.downloadText}>Download</Typography>
                                </Button>
                                <CSVLink
                                    data={this.state.csvData}
                                    filename={this.state.csvFileName}
                                    className='hidden'
                                    ref={this.csvRef}
                                    target='_blank'
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={7} sm={8} lg={7}>
                            {this.state.customerTicketsMeta && <CustomPagination
                                per_page={PER_PAGE}
                                page={this.state.customerTicketsMeta?.pagination_details.current_page}
                                count={Math.ceil(this.state.customerTicketsMeta?.pagination_details.total_count / PER_PAGE)}
                                arrayLength={this.state.customerTicketsMeta?.pagination_details.total_count}
                                handlePageChange={(e: any, p: any) => this.handlePageChange(e, p)}
                                data-testid={"customepaginaion"}
                            />}
                        </Grid>
                    </Grid>
                </MainContainer>
                <AppFooter isLoading={this.state.isLoading} />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    heading: {
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '18px',
        color: '#184176',
        marginLeft: '5px'
    },
    Tab1: {
        background: '#fff',
    },
    Tab2: {
        borderTopLeftRadius: "16px",
        borderBottomLeftRadius: "16px",
        background: '#DCDFF0',
    },
    tab3: {
        borderTopLeftRadius: "0px",
        borderBottomLeftRadius: "0px",
    },
    headerText: {
        color: 'rgba(4, 60, 116, 1)',
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '16px',
    },
    dataText: {
        color: 'rgba(4, 60, 116, 1)',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '14px',
        borderColor: '#F2F2F5',
    },
    table: {
        border: '1px solid rgba(170, 188, 206, 1)',
        boxShadow: 'none',
        borderRadius: '16px',
    },
    Open: {
        backgroundColor: '#EBF7F3',
        color: '#85C67A',
        border: '1px solid #EBF7F3',
    },
    inProgress: {
        backgroundColor: '#FFF7E6',
        color: '#EBC74F',
        border: '1px solid #FFF7E6',
    },
    Closed: {
        backgroundColor: '#F8ECEE',
        color: '#CE4436',
        border: '1px solid #F8ECEE',
    },
    noData: {
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '20px',
    },
    downloadText: {
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '14px',
        color: '#193C6A',
        textDecoration: 'underline'
    },
    downloadIcon: {
        height: "16px",
        width: "16px",
        margin: "0px",
        color: "#043c74"
    },
};

const MainContainer: any = withStyles({
    root: {
        padding: "34px 55px 6.5% 75px",
        "& .MuiTableCell-paddingNone": {
            padding: '8px 16px'
        },
        "& .MuiTableCell-alignRight": {
            flexDirection: 'row'
        },
        "& .MuiTableCell-root": {
            padding: '8px 16px',
        },
        "& .MuiTableRow-root": {
            backgroundColor: 'inherit',
            "&:hover": {
                backgroundColor: "inherit",
            },
        },
        "& .MuiTableSortLabel-icon": {
            display: 'none'
        }
    }
})(Container)

const SearchContainer: any = withStyles({
    root: {
        padding: '15px 0px 20px 0px'
    }
})(Grid);

const InputField: any = withStyles({
    root: {
        background: "inherite",
        margin: "5px 0px 0px 0px",
        "& ::placeholder": {
            color: 'rgba(185, 187, 197, 1)',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
            fontSize: '14px',
            opacity: 1,
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: 'rgba(185, 187, 197, 1)',
        },
        "& .MuiInputBase-input": {
            height: '15px',
        },
        "& .MuiOutlinedInput-input": {
            padding: `11px 14px !important`
        },
        "& .MuiOutlinedInput-root": {
            fontFamily: "SFProDisplay",
            fontWeight: 400,
            backgroundColor: "rgba(255, 255, 255, 1)",
            fontSize: "14px",
            "& fieldset": {
                height: '40px',
                borderColor: 'rgba(185, 187, 197, 1)',
                borderRadius: "8px",
                borderWidth: 1,
            },
            ".Mui-focused": {
                borderColor: 'rgba(185, 187, 197, 1)',
            },
        },
        "& .MuiFormHelperText-contained": {
            marginLeft: "0px !important",
        },
        "& .MuiInputBase-input.Mui-disabled": {
            background: "#F1F4FA",
            borderRadius: "8px",
            height: 13,
            color: 'rgba(106, 116, 165, 1)',
            fontSize: '14px',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
        },
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: 'rgba(185, 187, 197, 1)',
            borderWidth: '1px'
        },
        "& .MuiIconButton-root": {
            paddingLeft: '0px'
        },
        "& .MuiOutlinedInput-adornedStart": {
            paddingLeft: '5px'
        },
        "& .MuiSvgIcon-root": {
            width: '20px',
            height: '20px',
        },
    },
})(TextField);

const DetailButoon: any = withStyles({
    root: {
        backgroundColor: '#e1efff',
        border: '1px solid #459eff',
        borderRadius: '8px',
        borderWidth: '1px',
        width: '124px',
        height: '32px',
        color: '#459eff',
        fontFamily: 'SFProDisplay',
        fontWeight: 500,
        fontSize: '14px',
        boxShadow: 'none',
        textTransform: 'none',
        "&:hover": {
            backgroundColor: "#e1efff",
            boxShadow: "none",
        },
    }
})(Button);

const AddButton: any = withStyles({
    root: {
        color: "rgba(255, 255, 255, 1)",
        backgroundColor: "rgba(4, 60, 116, 1)",
        height: "35px",
        marginRight: '10px',
        width: 110,
        borderRadius: "8px",
        fontFamily: "SFProDisplay",
        fontWeight: 700,
        fontSize: "16px",
        marginTop: "5px",
        boxShadow: "none",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "rgba(4, 60, 116, 1)",
            boxShadow: "none",
        },
        "@media (max-width: 600px)": {
            width: "100%",
            marginLeft: 0,
        },
    },
})(Button);

const DownloadBox: any = withStyles({
    root: {
        border: '1.9px solid rgba(4, 60, 116, 1)',
        marginRight: '5px',
        borderRadius: '4px',
        height: '16px'
    }
})(Box);

const styles = () => ({
    container: {
        "@media (max-width: 600px)": {
            paddingLeft: 20,
            paddingRight: 20
        }
    },
    downloadContainer: {
        "@media (max-width: 600px)": {
            marginTop: 15,
            marginLeft: 15
        }
    }
})

export default withStyles(styles)(MasterCustomer)
// Customizable Area End
