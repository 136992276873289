import React from "react";

interface IProps {
    setSelectedFile: (files: any) => void;
    setFileSizeError: (isError: boolean) => void;
}
export default function MyQueriesSortMenuPopup({
    setSelectedFile,
    setFileSizeError,
}: IProps) {
    const [over, setover] = React.useState(false);
    const $input = React.useRef(null);
    const checkFileSize = (files: any) => {
        let fileSizeKB = roundOff(files[0].size * 0.001);
        let fileSizeMB = roundOff(fileSizeKB * 0.001);
        if (fileSizeMB < 10) {
            setSelectedFile(files);
            setFileSizeError(false);
        } else {
            setSelectedFile([]);
            setFileSizeError(true);
        }
    }

    const roundOff = (value: any) => {
        return Math.round(value * 100) / 100;
    }
    return (
        <>
            <div
                onClick={() => {
                    /* @ts-ignore */
                    $input.current.click();
                }}
                onDrop={(e: any) => {
                    e.preventDefault();
                    e.persist();
                    setover(false);
                    checkFileSize(e.dataTransfer.files);
                }}
                onDragOver={e => {
                    e.preventDefault();
                    setover(true);
                }}
                onDragLeave={e => {
                    e.preventDefault();
                    setover(false);
                }}
                className={over ? "upload-container over" : "upload-container"}
            >
                <p style={{ textAlign: 'center' }}>
                    <span style={webStyle.dragDropText}>
                        Drag & drop file here
                    </span>
                    <br />
                    <span style={webStyle.dragDropText}>OR</span>
                    <br />
                    <span style={webStyle.dragDropText}>
                        <a style={{ color: '#4EA2FE', textDecoration: 'underline', marginRight: '5px' }}>Click here</a>to attach file(s)</span>
                </p>
                <input
                    style={{ display: "none" }}
                    type="file"
                    // accept="image/*"
                    ref={$input}
                    onChange={(e: any) => {
                        /* @ts-ignore */
                        checkFileSize(e.target.files)
                    }
                    }
                // multiple={maxFiles > 1}
                />
            </div>
        </>
    );
}

const webStyle = {
    dragDropText: {
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: 2.29,
        color: '#003d78',
        cursor: 'pointer'
    }
}
