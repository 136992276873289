import React from "react";

import {
    withStyles,
} from "@material-ui/core/styles";
import {
    Grid,
    Tab,
    Tabs,
    Box,
    Container,
    TextField,
    InputAdornment,
    IconButton,
    TableRow,
    TableHead,
    TableCell,
    TableSortLabel,
    TableBody,
    Table,
    Button,
    Typography,
    TableContainer,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { AppHeader } from "../../../components/src/AppHeader.web";
import AppFooter from "../../../components/src/AppFooter.web";
import ArrowDropDownSharpIcon from '@material-ui/icons/ArrowDropDownSharp';
import ArrowDropUpSharpIcon from '@material-ui/icons/ArrowDropUpSharp';
import AdminConsole3Controller, {
    Props,
} from "./CustomerTicketController";
import CustomPagination from "../../../components/src/CustomPagination.web";

const PER_PAGE = 9;
export default class CustomerTicketList extends AdminConsole3Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <AppHeader navigation={this.props.navigation}/>
                <MainContainer>
                    <CustomTabs
                        onChange={this.handleChangeTab}
                        value={this.state.selectedTab}
                        aria-label="Tabs where each tab needs to be selected manually"
                    >
                        <Grid style={webStyle.Tab2}>
                            <CustomTab
                                label={
                                    <p style={{ margin: '0px', display: 'flex' }}>
                                        Tickets assigned to me
                                        <CountBox>20</CountBox>
                                    </p>
                                }
                                style={{ ...webStyle.Tab1, position: 'relative' }}
                                disableRipple
                            />
                        </Grid>
                        <CustomTab
                            label="Transferred Ticktes"
                            disableRipple
                            style={webStyle.tab3}
                        />

                    </CustomTabs>
                    <SearchContainer container spacing={3}>
                        <Grid item lg={4} md={4} sm={4} xs={12}>
                            <InputField
                                id="ticketId"
                                variant="outlined"
                                value={this.state.ticketId}
                                onChange={this.handleChange("ticketId").bind(this)}
                                required
                                placeholder="Search by Ticket ID"
                                fullWidth
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.setEnableField}
                                                edge="end"
                                            >
                                                <SearchIcon style={{ fill: '#8B95AF' }}></SearchIcon>
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={12}>
                            <InputField
                                id="productName"
                                variant="outlined"
                                value={this.state.productName}
                                onChange={this.handleChange("productName").bind(this)}
                                required
                                placeholder="Search by product name"
                                fullWidth
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.setEnableField}
                                                edge="end"
                                            >
                                                <SearchIcon style={{ fill: '#8B95AF' }}></SearchIcon>
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={12}>
                            <InputField
                                id="customerName"
                                variant="outlined"
                                value={this.state.customerName}
                                onChange={this.handleChange("customerName").bind(this)}
                                required
                                placeholder="Search by Customer name"
                                fullWidth
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.setEnableField}
                                                edge="end"
                                            >
                                                <SearchIcon style={{ fill: '#8B95AF' }}></SearchIcon>
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                        </Grid>
                    </SearchContainer>
                    <TableContainer
                        style={{ width: '100%', ...webStyle.table }}
                    >
                        <Table
                            aria-labelledby="tableTitle"
                            size={'medium'}
                        >
                            <TableHead style={{ background: '#E3EFFD' }}>
                                <TableRow>
                                    {this.state.headCells.map((headCell) => (
                                        <TableCell
                                            style={{
                                                maxWidth: '128px', background: '#E3EFFD',
                                                borderColor: 'rgba(170, 188, 206, 1)'
                                            }}
                                            align={headCell.numeric}
                                            key={headCell.id}
                                            padding={headCell.disablePadding ? 'none' : 'normal'}
                                            sortDirection={this.state.orderBy === headCell.label ? this.state.order : false}
                                        >
                                            {
                                                headCell.isSorting ? (

                                                    <TableSortLabel
                                                        active={headCell.isSorting}
                                                        direction={this.state.orderBy === headCell.id ? this.state.order : 'asc'}
                                                        style={{ ...webStyle.headerText, textAlign: headCell.numeric }}
                                                        onClick={() => this.stableSort(headCell.id)}
                                                    >
                                                        {headCell.label}
                                                        <div style={{ paddingLeft: '5px' }}>
                                                            {
                                                                this.state.orderBy === headCell.id &&
                                                                    this.state.order !== 'asc' ?
                                                                    <ArrowDropDownSharpIcon></ArrowDropDownSharpIcon>
                                                                    :
                                                                    <ArrowDropUpSharpIcon></ArrowDropUpSharpIcon>
                                                            }
                                                        </div>
                                                    </TableSortLabel>
                                                ) :
                                                    <Typography
                                                        style={{ ...webStyle.headerText, textAlign: headCell.numeric }}
                                                    >
                                                        {headCell.label}
                                                    </Typography>
                                            }

                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.ticketList.map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.id}
                                        >
                                            <TableCell style={webStyle.dataText}>
                                                {row.ticket_number}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                style={webStyle.dataText}
                                            >
                                                {row.product_name}
                                            </TableCell>
                                            <TableCell style={webStyle.dataText}>
                                                {row.query_type}
                                            </TableCell>
                                            <TableCell style={{ ...webStyle.dataText }}>
                                                <StatusButton
                                                    style={row.status === "Open" ? webStyle.Open :
                                                        row.status === "Closed" ? webStyle.Closed :
                                                            webStyle.inProgress}>
                                                    {row.status}
                                                </StatusButton>
                                            </TableCell>
                                            <TableCell style={webStyle.dataText}>
                                                {row.customer_name}
                                            </TableCell>
                                            <TableCell style={{ ...webStyle.dataText }}>
                                                <DetailButoon variant="contained" style={{ float: 'right' }}>
                                                    View Details
                                                </DetailButoon>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        {
                            this.state.ticketList.length === 0 && (
                                <Grid item lg={12} md={12} sm={12} style={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography style={{ ...webStyle.noData, display: 'flex', justifyContent: 'center' }}>
                                        No Records Found
                                    </Typography>
                                </Grid>
                            )
                        }
                    </TableContainer>
                    {this.state.TicketListingMeta && <CustomPagination
                        per_page={PER_PAGE}
                        page={this.state.TicketListingMeta?.pagination_details.current_page}
                        count={Math.ceil(this.state.TicketListingMeta?.pagination_details.total_count / PER_PAGE)}
                        arrayLength={this.state.TicketListingMeta?.pagination_details.total_count}
                        handlePageChange={(e: any, p: any) => this.handlePageChange(e, p)}
                    />}
                </MainContainer>
                <AppFooter isLoading={false} />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    Tab1: {
        background: '#fff',
    },
    Tab2: {
        borderTopLeftRadius: "16px",
        borderBottomLeftRadius: "16px",
        background: '#DCDFF0',
    },
    headerText: {
        color: 'rgba(4, 60, 116, 1)',
        fontFamily: 'SFProDisplay',
        fontSize: '16px',
        fontWeight: 700
    },
    tab3: {
        borderTopLeftRadius: "0px",
        borderBottomLeftRadius: "0px",
    },
    dataText: {
        color: 'rgba(4, 60, 116, 1)',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '16px',
        borderColor: '#F2F2F5',
    },
    table: {
        border: '1px solid rgba(170, 188, 206, 1)',
        boxShadow: 'none',
        borderRadius: '16px',
    },
    Open: {
        backgroundColor: '#EBF7F3',
        color: '#85C67A',
        border: '1px solid #EBF7F3',
    },
    inProgress: {
        backgroundColor: '#FFF7E6',
        color: '#EBC74F',
        border: '1px solid #FFF7E6',
    },
    Closed: {
        backgroundColor: '#F8ECEE',
        color: '#CE4436',
        border: '1px solid #F8ECEE',
    },
    noData: {
        fontFamily: 'SFProDisplay',
        fontSize: '20px',
        fontWeight: 700,
    }
};

const CustomTab : any = withStyles({
    root: {
        border: "1px solid rgba(218, 224, 241, 1)",
        width: "100%",
        color: "rgba(4, 60, 116, 1)",
        fontFamily: "SFProDisplay",
        fontWeight: 700,
        fontSize: "18px",
        textTransform: "none",
        borderRadius: '16px',
        "& .PrivateTabIndicator-colorSecondary-4": {
            display: "none",
        },
        "@media (max-width: 670px)": {
            width: "50%",
        },
    },
})(Tab);

const CustomTabs : any = withStyles({
    root: {
        maxHeight: "40px",
        backgroundColor: 'rgba(255, 255, 255, 1)',
        "& .MuiTab-root": {
            minHeight: '34px',
            maxHeight: "44px",
            maxWidth: '280px',
        },
        "& .MuiTabs-indicator": {
            display: "none",
        },
        "& .Mui-selected": {
            background: "#DCDFF0",
            color: '#8093B3',
            maxWidth: '280px',
            "& .MuiTab-textColorInherit": {
                opacity: 0.7,
            },
        },
        "& .MuiTab-textColorInherit": {
            opacity: 1,
        },
        "@media (max-width: 470px)": {
            maxHeight: "80px",
        },
        "@media (max-width: 600px)": {
            marginLeft: "0px",
        },
    },
})(Tabs);

const MainContainer : any = withStyles({
    root: {
        padding: "34px 55px 6.5% 75px",
        "& .MuiTableCell-paddingNone": {
            padding: '10px 16px'
        },
        "& .MuiTableCell-alignRight": {
            flexDirection: 'row'
        },
        "& .MuiTableCell-root": {
            padding: '10px 16px',
        },
        "& .MuiTableRow-root": {
            backgroundColor: 'inherit',
            "&:hover": {
                backgroundColor: "inherit",
            },
        },
        "& .MuiTableSortLabel-icon": {
            display: 'none'
        }
    }
})(Container)

const CountBox : any = withStyles({
    root: {
        height: '20px',
        width: '30px',
        backgroundColor: 'rgba(218, 224, 241, 1)',
        color: 'rgba(4, 60, 116, 1)',
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '12px',
        margin: '3px 5px 0px 12px',
        borderRadius: '16px',
        textAlign: 'center',
        padding: '3px 3px'
    }
})(Box)

const SearchContainer : any = withStyles({
    root: {
        padding: '25px 0px 20px 0px'
    }
})(Grid);

const InputField : any = withStyles({
    root: {
        background: "inherite",
        margin: "5px 0px 0px 0px",
        "& ::placeholder": {
            color: 'rgba(185, 187, 197, 1)',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
            fontSize: '16px',
            opacity: 1,
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: 'rgba(185, 187, 197, 1)',
        },
        "& .MuiInputBase-input": {
            height: '15px',
        },
        "& .MuiOutlinedInput-input": {
            padding: `14.2px 14px !important`
        },
        "& .MuiOutlinedInput-root": {
            fontFamily: "SFProDisplay",
            fontWeight: 400,
            backgroundColor: "rgba(255, 255, 255, 1)",
            fontSize: "16px",
            "& fieldset": {
                height: '45px',
                borderColor: 'rgba(185, 187, 197, 1)',
                borderRadius: "8px",
                borderWidth: 1,
            },
            ".Mui-focused": {
                borderColor: 'rgba(185, 187, 197, 1)',
            },
        },
        "& .MuiFormHelperText-contained": {
            marginLeft: "0px !important",
        },
        "& .MuiInputBase-input.Mui-disabled": {
            background: "#F1F4FA",
            borderRadius: "8px",
            height: 13,
            color: 'rgba(106, 116, 165, 1)',
            fontSize: '16px',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
        },
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: 'rgba(185, 187, 197, 1)',
            borderWidth: '1px'
        },
        "& .MuiIconButton-root": {
            paddingLeft: '0px'
        },
        "& .MuiOutlinedInput-adornedStart": {
            paddingLeft: '5px'
        },
        "& .MuiSvgIcon-root": {
            width: '30px',
            height: '40px',
        },
    },
})(TextField);

const StatusButton : any = withStyles({
    root: {
        backgroundColor: 'inherit',
        borderColor: 'inherit',
        borderRadius: '8px',
        borderWidth: '1px',
        width: '130px',
        height: '40px',
        boxShadow: 'none',
        textTransform: 'none',
        color: 'inherit',
        fontFamily: 'SFProDisplay',
        fontWeight: 500,
        fontSize: '16px',
        float: 'right',
        "&:hover": {
            backgroundColor: "#E3EFFD",
            boxShadow: "none",
        },
    }
})(Button);

const DetailButoon : any = withStyles({
    root: {
        backgroundColor: '#e1efff',
        border: '1px solid #459eff',
        borderRadius: '8px',
        borderWidth: '1px',
        width: '144px',
        height: '40px',
        color: '#459eff',
        fontFamily: 'SFProDisplay',
        fontWeight: 500,
        fontSize: '16px',
        boxShadow: 'none',
        textTransform: 'none',
        "&:hover": {
            backgroundColor: "#e1efff",
            boxShadow: "none",
        },
    }
})(Button);
// Customizable Area End
