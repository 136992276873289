import React from "react";

// Customizable Area Start
import {
    Container,
    Box,
    Button,
    InputLabel,
    Typography,
    Grid,
    TextField,
    Select,
    MenuItem,
    FormControl,
} from "@material-ui/core";
import {
    withStyles,
} from "@material-ui/core/styles";
import * as Yup from "yup";
import { Formik } from "formik";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AppHeader from "../../../components/src/EmployeeAppHeader.web";
import AppFooter from "../../../components/src/AppFooter.web";
import AddInvoiceController, { Props } from "./AddInvoiceController";
import Loader from "../../../components/src/Loader.web";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import moment from "moment";

function TabPanel(props: {
    [x: string]: any;
    children: any;
    value: any;
    index: any;
}) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}
// Customizable Area End


export class AddInvoice extends AddInvoiceController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    InvoiceDetails() {
        const { classes } = this.props
        let patternTwoDigisAfterComma: any = /^\d+(\.\d{0,2})?$/;
        const InvoiceSchema = Yup.object().shape({
            account_id: Yup.string().required('Contact Name is required.'),
            catalogue_id: Yup.string().required('Product Name is required.'),
            invoice: Yup.string().required('Invoice Number is required.'),
            invoice_date: Yup.string().required('Invoice Date is required.').test("is-valid-date", "Please Enter Valid Invoice Date.",
                (val: any) => {
                    return this.validateDateRange(val);
                }
            ),
            invoice_amount: Yup.number().required('Invoice Amount is required.').typeError('Please type numbers only.')
                .min(0, "Invoice Amount must be greater than or equal to 0.").test("is-decimal", "Invoice amount should valid up to two digits after decimal.",
                    (val: any) => {
                        if (val != undefined) {
                            return patternTwoDigisAfterComma.test(val);
                        }
                        return true;
                    }
                ),
            account_currency_id: Yup.string().required('Currency is required.'),
            due_date: Yup.string().required('Due Date is required.').test("is-valid-date", "Please Enter Valid Due Date.",
                (val: any) => {
                    return this.validateDateRange(val);
                }
            ),
            payment_term_id: Yup.string().required('Payment terms is required.'),
            payment_status: Yup.string().required('Payment Satus is required.'),
            attachment: Yup.mixed().required('Attachment is required.').test("FILE_FORMAT", "Please upload only PDF file.",
                (value: any) => value && value.name === undefined ? true : !value || (value && value.name !== undefined && ['application/pdf'].includes(value.type)))
        })
        const isContact: any = location.href.includes("AddContactInvoice");
        const isEdit: any = location.href.includes("edit");
        return (
            <>
                {!this.state.isLoading ? <Formik data-testid="AddInvoiceFormik" initialValues={this.state.invoiceFormDetails} validationSchema={InvoiceSchema}
                    onSubmit={(values) => { this.isDefined(isContact, isEdit, values) }}>
                    {({ errors, values, touched, handleSubmit, handleChange, handleBlur, setFieldValue }: any) => (
                        <form data-testid="addInvoiceForm" style={{ marginTop: "-30px" }} onSubmit={handleSubmit}>
                            {(!this.state.isInvoiceEdit && this.state.isViewOnly) &&
                                <EditButton data-testid="btnEditForm" onClick={() => this.handleInvoiceEdit()} variant="contained" style={webStyle.invoicEditIcon}>
                                    Edit <EditIcon style={{ marginLeft: "10px", height: "19px", width: "19px" }} />
                                </EditButton>}
                            <Grid container spacing={7} alignItems="center" >
                                {isContact === true ? <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                    <InputLabel htmlFor="invoice" style={webStyle.inputLabel}>{this.state.contactNameLabel}<span style={{ color: "red" }}>*</span></InputLabel>
                                    <InputField
                                        id="account_id"
                                        variant="outlined"
                                        name="account_id"
                                        value={this.state.contactName}
                                        fullWidth
                                        disabled />
                                </Grid>
                                    :
                                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                        <InputLabel htmlFor="account_id" style={{ ...webStyle.inputLabel, paddingBottom: '10px' }}>{this.state.contactNameLabel}<span style={{ color: "red" }}>*</span></InputLabel>
                                        <SelectField>
                                            <CustomSelect
                                                labelId="account_id"
                                                name="account_id"
                                                displayEmpty
                                                id="account_id"
                                                variant="outlined"
                                                fullWidth
                                                value={values.account_id}
                                                style={webStyle.select}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                defaultValue={''}
                                                disabled={!this.state.isInvoiceEdit}>
                                                <MenuItem value="" className={classes?.selectPlaceholder}>
                                                    <em style={webStyle.countryPlaceHolder}>Choose Contact name</em>
                                                </MenuItem>
                                                {this.state.allCustomerContacts?.map((data: any, index: number) => {
                                                    return (<MenuItem value={data.id} key={`industry${index}`}>{data.name}</MenuItem>);
                                                })}
                                                {this.isNoData(this.state.allCustomerContacts)}
                                            </CustomSelect>
                                        </SelectField>
                                        {this.finalErrorMessage(errors.account_id, touched.account_id)}
                                    </Grid>
                                }
                                <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                    <InputLabel htmlFor="catalogue_id" style={{ ...webStyle.inputLabel, paddingBottom: '10px' }}> {this.state.productNameLabel}<span style={{ color: "red" }}>*</span></InputLabel>
                                    <SelectField>
                                        <CustomSelect
                                            labelId="catalogue_id"
                                            name="catalogue_id"
                                            displayEmpty
                                            id="catalogue_id"
                                            variant="outlined"
                                            fullWidth
                                            value={values.catalogue_id}
                                            style={webStyle.select}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            defaultValue={""}
                                            disabled={!this.state.isInvoiceEdit}>
                                            <MenuItem value="" className={classes?.selectPlaceholder}>
                                                <em style={webStyle.countryPlaceHolder}>Choose Product name</em>
                                            </MenuItem>
                                            {this.state.productsList?.map((data: any, index: number) => {
                                                return (<MenuItem value={data.id} key={`industry${index}`}>{data.value}</MenuItem>);
                                            })}
                                            {this.isNoData(this.state.productsList)}
                                        </CustomSelect>
                                    </SelectField>
                                    {this.finalErrorMessage(errors.catalogue_id, touched.catalogue_id)}
                                </Grid>
                                <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                    <InputLabel htmlFor="invoice" style={webStyle.inputLabel}>{this.state.invoiceLabel}<span style={{ color: "red" }}>*</span></InputLabel>
                                    <InputField
                                        id="invoice"
                                        variant="outlined"
                                        name="invoice"
                                        value={values.invoice}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Enter invoice Number"
                                        fullWidth
                                        disabled={!this.state.isInvoiceEdit} />
                                    {this.finalErrorMessage(errors.invoice, touched.invoice)}
                                </Grid>
                                <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                    <InputLabel htmlFor="invoice_date" style={webStyle.inputLabel}>{this.state.invoiceDateLabel}<span style={{ color: "red" }}>*</span></InputLabel>
                                    <InputField
                                        id="invoice_date"
                                        name="invoice_date"
                                        variant="outlined"
                                        type="date"
                                        value={values.invoice_date}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Enter invoice Date"
                                        fullWidth
                                        disabled={!this.state.isInvoiceEdit} />
                                    {this.finalErrorMessage(errors.invoice_date, touched.invoice_date)}
                                </Grid>
                                <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                    <InputLabel htmlFor="invoice_amount" style={webStyle.inputLabel}>{this.state.invoiceAmountLabel}<span style={{ color: "red" }}>*</span></InputLabel>
                                    <InputField
                                        id="invoice_amount"
                                        variant="outlined"
                                        name="invoice_amount"
                                        value={values.invoice_amount}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Enter invoice Amount"
                                        fullWidth
                                        disabled={!this.state.isInvoiceEdit} />
                                    {this.finalErrorMessage(errors.invoice_amount, touched.invoice_amount)}
                                </Grid>
                                <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                    <InputLabel htmlFor="account_currency_id" style={webStyle.inputLabel}>{this.state.invoiceCurrencyLabel}<span style={{ color: "red" }}>*</span></InputLabel>
                                    <SelectField>
                                        <CustomSelect
                                            labelId="account_currency_id"
                                            name="account_currency_id"
                                            displayEmpty
                                            id="account_currency_id"
                                            variant="outlined"
                                            fullWidth
                                            value={values.account_currency_id}
                                            style={webStyle.select}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            defaultValue={""}
                                            disabled={!this.state.isInvoiceEdit}>
                                            <MenuItem value="" className={classes?.selectPlaceholder}>
                                                <em style={webStyle.countryPlaceHolder}>Choose Currentcy</em>
                                            </MenuItem>
                                            {this.state.all_account_currencies?.map((data: any, index: number) => {
                                                return (
                                                    <MenuItem value={data.id} key={`industry${index}`}>{data.name}</MenuItem>
                                                );
                                            })}
                                            {this.isNoData(this.state.all_account_currencies)}
                                        </CustomSelect>
                                        {this.finalErrorMessage(errors.account_currency_id, touched.account_currency_id)}
                                    </SelectField>
                                </Grid>
                                <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                    <InputLabel htmlFor="p_o" style={webStyle.inputLabel}>{this.state.invoicePOLabel}</InputLabel>
                                    <InputField
                                        id="p_o"
                                        variant="outlined"
                                        name="p_o"
                                        value={values.p_o}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Enter invoice PO"
                                        fullWidth
                                        disabled={!this.state.isInvoiceEdit} />
                                    {this.finalErrorMessage(errors.p_o, touched.p_o)}
                                </Grid>
                                <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                    <InputLabel htmlFor="due_date" style={webStyle.inputLabel}>{this.state.invoiceDueDateLabel}<span style={{ color: "red" }}>*</span></InputLabel>
                                    <InputField
                                        id="due_date"
                                        variant="outlined"
                                        name="due_date"
                                        type="date"
                                        value={values.due_date}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Enter invoice due date"
                                        fullWidth
                                        disabled={!this.state.isInvoiceEdit} />
                                    {this.finalErrorMessage(errors.due_date, touched.due_date)}
                                </Grid>
                                <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                    <InputLabel htmlFor="payment_term_id" style={{ ...webStyle.inputLabel, paddingBottom: '10px' }}>{this.state.invoicePaymentTermsLabel}<span style={{ color: "red" }}>*</span></InputLabel>
                                    <SelectField>
                                        <CustomSelect
                                            labelId="payment_term_id"
                                            name="payment_term_id"
                                            displayEmpty
                                            id="payment_term_id"
                                            variant="outlined"
                                            fullWidth
                                            value={values.payment_term_id}
                                            style={webStyle.select}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            defaultValue={""}
                                            disabled={!this.state.isInvoiceEdit}>
                                            <MenuItem value="" className={classes?.selectPlaceholder}>
                                                <em style={webStyle.countryPlaceHolder}>Choose Payment Terms</em>
                                            </MenuItem>
                                            {this.state.allPaymentTerms?.map((data: any, index: number) => {
                                                return (
                                                    <MenuItem value={data.id} key={`industry${index}`}>{data.payment_term}</MenuItem>
                                                );
                                            })}
                                            {this.isNoData(this.state.allPaymentTerms)}
                                        </CustomSelect>
                                    </SelectField>
                                    {this.finalErrorMessage(errors.payment_term_id, touched.payment_term_id)}
                                </Grid>
                                <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                    <InputLabel htmlFor="inco_term_id" style={{ ...webStyle.inputLabel, paddingBottom: '10px' }}>{this.state.invoiceIncoTermsLabel}</InputLabel>
                                    <SelectField>
                                        <CustomSelect
                                            labelId="inco_term_id"
                                            name="inco_term_id"
                                            displayEmpty
                                            id="inco_term_id"
                                            variant="outlined"
                                            fullWidth
                                            value={values.inco_term_id}
                                            style={webStyle.select}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            defaultValue={""}
                                            disabled={!this.state.isInvoiceEdit}>
                                            <MenuItem value="" className={classes?.selectPlaceholder}>
                                                <em style={webStyle.countryPlaceHolder}>Choose Inco Terms</em>
                                            </MenuItem>
                                            {this.state.allIncoTerms?.map((data: any, index: number) => {
                                                return (
                                                    <MenuItem value={data.id} key={`industry${index}`}>{data.inco_term}</MenuItem>
                                                );
                                            })}
                                            {this.isNoData(this.state.allIncoTerms)}
                                        </CustomSelect>
                                    </SelectField>
                                </Grid>
                                <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                    <InputLabel htmlFor="payment_status" style={{ ...webStyle.inputLabel, paddingBottom: '10px' }}>{this.state.invoicePaymentStatusLabel}<span style={{ color: "red" }}>*</span></InputLabel>
                                    <SelectField>
                                        <CustomSelect
                                            labelId="payment_status"
                                            name="payment_status"
                                            displayEmpty
                                            id="payment_status"
                                            variant="outlined"
                                            fullWidth
                                            value={values.payment_status}
                                            style={webStyle.select}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            defaultValue={""}
                                            disabled={!this.state.isInvoiceEdit}>
                                            <MenuItem value="" className={classes?.selectPlaceholder}>
                                                <em style={webStyle.countryPlaceHolder}>Choose Payment Status</em>
                                            </MenuItem>
                                            {this.state.paymentStatus?.map((data: any, index: number) => {
                                                return (
                                                    <MenuItem value={data.id} key={`industry${index}`}>{data.value}</MenuItem>
                                                );
                                            })}
                                            {this.isNoData(this.state.paymentStatus)}
                                        </CustomSelect>
                                    </SelectField>
                                    {this.finalErrorMessage(errors.payment_status, touched.payment_status)}
                                </Grid>
                                <Grid item md={12} lg={12} sm={12} xs={12} style={{ paddingBottom: '5px', paddingTop: '15px', position: 'relative', }}>
                                    <Typography component="label">
                                        <AttachFileIcon style={webStyle.attachmentIcon}></AttachFileIcon>
                                        <span style={webStyle.attachmentText}>Attachment</span>
                                        <input
                                            type="file"
                                            name="attachment"
                                            id="attachment"
                                            value={""}
                                            hidden
                                            accept="application/pdf"
                                            onChange={(e: any) => { this.handleDocumentUpload(e); setFieldValue("attachment", e?.currentTarget?.files[0]) }}
                                            //@ts-ignore
                                            disabled={!this.state.isInvoiceEdit} />
                                    </Typography>
                                </Grid>
                                <div style={{ display: "flex", flexDirection: 'column' }}>
                                    <ul>
                                        {this.attachmentListUI()}
                                        {this.uploadAttachmentsUI()}
                                    </ul>
                                    {this.addAttachmentError(this.state?.attachmentError)}
                                    {this.editAttachmentError(this.state?.attachmentError)}
                                    {this.finalErrorAttachmentMessage(errors.attachment, touched.attachment)}
                                </div>
                            </Grid>
                            <Grid container style={{ marginTop: '30px' }}>
                                <Grid>{this.saveButton(this.state?.isInvoiceEdit)}</Grid>
                            </Grid>
                        </form>
                    )}
                </Formik> : <Loader loading={this.state.isLoading} />}
            </>
        );
    }

    validateDateRange = (val: any) => {
        let dateReg = /^\d{2}[./-]\d{2}[./-]\d{4}$/
        if (!moment(val).format('DD-MM-YYYY').match(dateReg)) {
            return false
        } else {
            return true
        }
    }

    attachmentListUI = () => {
        return (
            this.state?.getAttachment && <Grid container spacing={2} style={{ alignItems: "center", marginBottom: this.isMargin() }}>
                <li style={this.isInvoiceEditStyle(this.state?.isInvoiceEdit)} onClick={() => this.downloadAPIAttachmentFile(this.state.getAttachment)}>{this.state.getAttachment.file_name}</li>
                {this.isEditDeleteIcon(this.state?.isInvoiceEdit)}
            </Grid>
        )
    }

    uploadAttachmentsUI = () => {
        return (
            this.state?.uploadedFiles?.map((doc: any, index: number) => {
                return (
                    <Grid container spacing={2} style={{ alignItems: "center", marginBottom: this.isMargin() }}>
                        <li key={`file${index}`} style={this.isInvoiceEditStyle(this.state?.isInvoiceEdit)} >{doc?.name}</li>
                        <DeleteIcon style={{ fill: 'red' }} onClick={() => this.removeFile(doc.name)}></DeleteIcon>
                    </Grid>
                )
            })
        )
    }

    finalErrorMessage(error: any, touch: any) {
        if (error) {
            if (touch) {
                return <div style={webStyle.allFieldError}>
                    {error}
                </div>
            }
            else {
                return null
            }
        }
        else {
            return;
        }
    }

    finalErrorAttachmentMessage(error: any, touch: any) {
        if (error) {
            if (touch) {
                return <div style={webStyle.attachFileErrorAttach}>
                    {error}
                </div>
            }
            else {
                return null
            }
        }
        else {
            return;
        }
    }

    // checkAttchmentType(value: any) {
    //     if (value) {
    //         if (value.name === undefined) {
    //             return true
    //         }
    //         else {
    //             if(!value || (value && value.name !== undefined && ['application/pdf'].includes(value.type))){
    //                 return true
    //             }
    //         }
    //     }
    //     else{
    //         return;
    //     }
    // }

    isDefined(isContact: any, isEdit: any, values: any) {
        if (isContact) {
            if (isEdit) {
                if (this.props.navigation.getParam('customerID')) {
                    this.saveContactInvoiceEditData(values)
                }
            } else {
                this.saveInvoice(values)
            }
        }
        else if (this.props.navigation.getParam('customerID')) {
            this.saveInvoiceEditData(values)
        }
        else {
            this.saveInvoice(values)
        }
    }

    addAttachmentError(value: any) {
        if (value) {
            if (location.href.includes("Add/")) {
                return <span style={webStyle.attachFileError}>{value}</span>
            }
            else {
                return null
            }
        }
        else {
            return;
        }
    }

    editAttachmentError(value: any) {
        if (value) {
            if (location.href.includes("edit/")) {
                return <span style={webStyle.attachFileError}>{value}</span>
            }
            else {
                return null
            }
        }
        else {
            return;
        }
    }

    saveButton(isEdit: any) {
        if (isEdit) {
            return <SaveButton type="submit" variant="contained" style={{ marginRight: '10px' }}>
                Save
            </SaveButton>
        }
        else {
            return;
        }
    }

    isInvoiceEditStyle(isEdit: any) {
        if (isEdit) {
            return webStyle.docNameEdit
        }
        else {
            return webStyle.docName
        }
    }

    isEditDeleteIcon(isEdit: any) {
        if (isEdit) {
            return <DeleteIcon
                style={{ fill: 'red' }}
                onClick={() => this.removeFile("remove_file")}
            >
            </DeleteIcon>
        }
        else {
            return;
        }
    }

    isMargin() {
        if (this.state.uploadedFiles?.length > 0) {
            return "10px"
        }
        else {
            return "0px"
        }
    }

    isNoData(value: any) {
        if (value?.length === 0) {
            return <MenuItem value="noData" disabled>
                No Data
            </MenuItem>
        }
        else {
            return;
        }
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <AppHeader navigation={this.props.navigation} />
                <MainContainer>
                    <Loader loading={this.state.isLoading} />
                    <div>
                        <KeyboardBackspaceIcon
                            style={{
                                ...webStyle.cardButtonBack,
                            }}
                            fontSize="large"
                            onClick={() => this.props.navigation.goBack()}
                        />
                        <Typography style={webStyle.detailTitle}>
                            {
                                this.state.invoiceAddLabel}
                        </Typography>
                    </div>
                    <form>
                        <TabPanel value={this.state.selectedTab} index={0}>
                            {this.InvoiceDetails()}
                        </TabPanel>
                    </form>
                </MainContainer>
                <AppFooter isLoading={this.state.isLoading} />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle = {
    cardButtonBack: {
        color: "rgb(4, 60, 116)",
        marginLeft: "18px",
        cursor: 'pointer',
    },
    invoicEditIcon: {
        float: 'right',
        transform: "translate(0px, -30px)",
    },
    attachFileError: {
        color: 'red',
        fontSize: '14px',
        fontFamily: 'SFProDisplay',
        marginLeft: '28px',
    },
    attachFileErrorAttach: {
        color: 'red',
        fontSize: '14px',
        fontFamily: 'SFProDisplay',
        marginLeft: '40px',
        marginTop: "-17px",
        marginBottom: "15px"
    },
    detailTitle: {
        color: "rgba(33, 43, 54, 1)",
        fontFamily: "SFProDisplay",
        fontWeight: 700,
        fontSize: "18px",
        marginLeft: "24px",
        marginTop: '10px',
        textDecoration: 'underline'
    },
    inputLabel: {
        fontSize: "14px",
        fontFamily: "SFProDisplay",
        fontWeight: 700,
        color: "#1A3B71",
        paddingBottom: "5px",
    },
    countryPlaceHolder: {
        fontStyle: "normal",
        fontFamily: "SFProDisplay",
        fontWeight: 400,
        fontSize: "16px",
        color: "rgba(218, 224, 241, 1)",
    },
    select: {
        height: "37px",
        borderRadius: "8px",
        marginTop: '5px',
    },
    grids: {
        paddingTop: '18px',
        paddingBottom: '0px'
    },
    allFieldError: {
        color: 'red',
        fontSize: '14px',
        fontFamily: 'SFProDisplay',
        margin: '5px 5px 5px 0px',
    },
    attachmentIcon: {
        marginBottom: '-4px',
        marginRight: '6px',
        fill: "rgba(4, 60, 116, 1)",
        marginLeft: '-6px',
        fontSize: '16px',
        cursor: 'pointer'
    },
    attachmentText: {
        fontSize: '13px',
        color: 'rgba(4, 60, 116, 1)',
        fontFamily: 'SFPRODisplay',
        fontWeight: 700,
        cursor: 'pointer'
    },
    docName: {
        fontSize: '14px',
        color: 'rgb(69, 158, 255)',
        fontFamily: 'SFPRODisplay',
        fontWeight: 700,
        margin: '0px 5px 0px 0px',
        cursor: 'pointer',
        padding: "0 10px",
        display: "flex",
        textDecoration: "underline",
        textUnderlinePosition: 'under',
    },
    docNameEdit: {
        fontSize: '14px',
        color: 'rgb(69, 158, 255)',
        fontFamily: 'SFPRODisplay',
        fontWeight: 700,
        margin: '0px 5px 0px 0px',
        cursor: 'pointer',
        padding: "0 10px",
        display: "flex",
        textDecoration: "underline",
        textUnderlinePosition: 'under',
    },
};

const EditButton: any = withStyles({
    root: {
        color: "rgba(255, 255, 255, 1)",
        backgroundColor: "rgba(4, 60, 116, 1)",
        height: "40px",
        width: 110,
        borderRadius: "8px",
        fontFamily: "SFProDisplay",
        fontSize: "14px",
        fontWeight: 700,
        marginTop: "5px",
        boxShadow: "none",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "rgba(4, 60, 116, 1)",
            boxShadow: "none",
        },
        "@media (max-width: 600px)": {
            width: "100%",
            marginLeft: 0,
            marginTop: "50px",
        },
    },
})(Button);

const InputField: any = withStyles({
    root: {
        background: "inherite",
        margin: "5px 0px 0px 0px",
        "& ::placeholder": {
            color: 'rgba(218, 224, 241, 1)',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
            fontSize: '14px',
            opacity: 1,
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(214, 214, 214, 1)",
        },
        "& .MuiInputBase-input": {
            height: '15px',
        },
        "& .MuiOutlinedInput-input": {
            padding: `12px 14px !important`
        },
        "& .MuiOutlinedInput-root": {
            fontFamily: "SFProDisplay",
            fontWeight: 400,
            backgroundColor: "rgba(255, 255, 255, 1)",
            fontSize: "14px",
            "& fieldset": {
                height: '40px',
                borderColor: "rgba(218, 224, 241, 1)",
                borderRadius: "8px",
                borderWidth: 1,
            },
            ".Mui-focused": {
                borderColor: "rgba(218, 224, 241, 1)",
            },
        },
        "& .MuiFormHelperText-contained": {
            marginLeft: "0px !important",
        },
        "& .MuiInputBase-input.Mui-disabled": {
            background: "#F1F4FA",
            borderRadius: "8px",
            height: 13,
            color: 'rgba(106, 116, 165, 1)',
            fontSize: '14px',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
        },
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(218, 224, 241, 1)",
            borderWidth: '1px'
        }
    },
})(TextField);

const SaveButton: any = withStyles({
    root: {
        color: "rgba(255, 255, 255, 1)",
        height: "40px",
        width: 110,
        borderRadius: "8px",
        fontFamily: "SFProDisplay",
        fontSize: "18px",
        fontWeight: 700,
        marginTop: "5px",
        boxShadow: "none",
        textTransform: "none",
        "&.MuiButton-contained": {
            backgroundColor: 'rgba(0, 61, 120, 1)',
            boxShadow: 'none',
            "&:hover": {
                backgroundColor: 'rgba(0, 61, 120, 1)',
            }
        },
        "&.MuiButton-outlined": {
            border: '1px solid rgba(0, 61, 120, 1)',
            color: 'rgba(0, 61, 120, 1)',
            padding: '5px 15px',
            "&:hover": {
                backgroundColor: 'transparent',
            }
        },
        "&:hover": {
            backgroundColor: "rgba(4, 60, 116, 1)",
            boxShadow: "none",
        },
        "@media (max-width: 600px)": {
            width: "100%",
            marginLeft: 0,
        },
    },
})(Button);

const MainContainer: any = withStyles({
    root: {
        "& ::placeholder": {
            color: 'rgba(218, 224, 241, 1)',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
            fontSize: '16px',
            opacity: 1,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: `rgba(218, 224, 241, 1) !important`,
        },
        "& .css-yk16xz-control": {
            borderRadius: '8px',
            height: '43px',
            borderColor: 'rgba(218, 224, 241, 1)',
            marginTop: '4px',
        },
        padding: "24px 36px 24px 48px",
        "& .css-view-1dbjc4n": {
            marginBottom: `0px !important`,
        },
        "& .css-1hb7zxy-IndicatorsContainer": {
            height: '36px',
        },
        "& .css-1fhf3k1-control": {
            backgroundColor: `#F1F4FA !important`,
            borderColor: "rgba(218, 224, 241, 1)",
            borderRadius: '8px',
            color: 'rgba(106, 116, 165, 1)',
            fontSize: '16px',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
        },
        "@media (max-width: 600px)": {
            padding: "24px 0px 24px 0px",
            "& .MuiBox-root-8": {
                padding: "24px 0px 24px 0px",
            },
            "& ..MuiBox-root-30": {
                padding: "24px 0px 24px 0px",
            },
        },
    },
})(Container);

const CustomSelect: any = withStyles({
    root: {
        "&.MuiOutlinedInput-input": {
            padding: '13px 14px',
        },
        "&.MuiInputBase-input.Mui-disabled": {
            background: "#F1F4FA",
            borderRadius: "8px",
            height: 13,
            color: 'rgba(106, 116, 165, 1)',
            fontSize: '16px',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
        },
        "& .MuiInputBase-input.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(218, 224, 241, 1)",
            borderWidth: '1px'
        }
    }
})(Select);

const SelectField: any = withStyles({
    root: {
        "& .MuiOutlinedInput-root": {
            fontFamily: "SFProDisplay",
            fontWeight: 500,
            backgroundColor: "rgba(255, 255, 255, 1)",
            fontSize: "14px",
            color: '#1F436D',
        },
        "& .MuiInputBase-input": {
            fontFamily: "SFProDisplay",
            fontWeight: 500,
            backgroundColor: "rgba(255, 255, 255, 1)",
            fontSize: "14px",
            color: '#1F436D',
        },
        width: '100%',
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(218, 224, 241, 1)",
            borderWidth: 1,
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(218, 224, 241, 1)",
            borderWidth: 1,
        },
        "& .MuiSelect-icon": {
            fill: "rgba(4, 60, 116, 1)",
        },
        "& .MuiOutlinedInput-input": {
            padding: '9px 14px'
        },
    }
})(FormControl);

const styles = () => ({
    country: {
        paddingLeft: '42px !important',
        paddingRight: '18px !important',
        "@media (max-width: 600px)": {
            paddingLeft: '28px !important',
            paddingRight: '34px !important',
        }
    },
    selectPlaceholder: {
        backgroundColor: '#fff !important',
        '&.Mui-disabled': {
            opacity: 1
        }
    }
})

export default withStyles(styles)(AddInvoice)

// Customizable Area End
