Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomisableUserProfiles";
exports.labelBodyText = "CustomisableUserProfiles Body";

exports.btnExampleTitle = "CLICK ME";
exports.userDetailLabel = "User Details";
exports.labelFirstName = "First Name";
exports.companynameLabel = "Company Name";
exports.emailLabel = "Email ID";
exports.lastnameLabel = "Last Name";
exports.countryCodeLabel = "Country Code";
exports.phoneNumLabel = "Phone No";
exports.addressLabel = "Address";
exports.departmentLabel = "Department";
exports.designationLabel = "Designation";
exports.landlinelabel = "Landline";
exports.businessareaLabel = "Area Of Business";
exports.affilateNameLabel = "Affiliate/Subsidiary Name";
exports.alternateEmailLabel = "Alternate Email";
exports.updateAccountApiEndpoint = "account_block/accounts/";
exports.updateProfileApiEndpoint = "account_block/profile_details/";
exports.updateAccountApiMethod = "PUT";
exports.updateCustomerProfileApiEndpoint = "bx_block_customer_profile/customer_profiles/";
exports.updateCustomerProfileApiMethod = "POST";
exports.resetPasswordBtnText = "Reset Password";
exports.termsConditionError = "Please accept terms and condition";
exports.getEmployeeProfileApiEndpoint = "account_block/profile_details/";
exports.getEmployeeProfileApiMethod = "GET";
exports.getDepartsmentListApiEndpointForEmp = "account_block/employee_profile_details/emp_listing";
exports.getDepartsmentListApiEndpoint = "bx_block_customer_profile/customer_profiles/get_department";
exports.getDepartmentListApiMethod = "GET";
exports.getCustomerProfileApiEndpoint = "bx_block_customer_profile/customer_profiles/";
exports.getCustomerProfileApiMethod = "GET";
exports.privacyPolicyLabel = "* I agree and accept the";
exports.privacyPolicyText = "Privacy Policy";
exports.termslabelText = "Terms of Use";
exports.termsLabel = " of this website";

exports.ProfileDetailLabel = "Profile Details";
exports.biospaceIdLabel = "Biospace ID";
exports.reportManagerLabel = "Reporting Manager";
exports.assignedCustomerLabel = "Assigned Customer";
exports.ProfileLabel = "Profile";
exports.TerritoryLabel = "Territory";
exports.CountryLabel = "Country";

exports.emailError = "Please enter valid alternate email id";
exports.getAllSalutationsApiEndpoint = "account_block/customer_masters/salutation";

exports.sendResetPasswordEmailEndPoint = "account_block/accounts/send_reset_email";
// Customizable Area End
