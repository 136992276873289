import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall, authorizeEmployee, getHeaders, getUserdetails, isAuthorisedUser, isLoggedIn, warningNotification,events, autoLogOut } from "../../../components/src/common";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

export interface ITicketListMeta {
  pagination_details: {
    current_count: number;
    current_page: number;
    next_page: number;
    prev_page: null | number;
    total_count: number;
    total_pages: number;
  };
}

export interface IContactMaster {
  id: string;
  type: string;
  attributes: {
    address: string;
    alternate_email: string;
    comments: string;
    contact_name: string;
    contact_unique_id: string;
    country: string;
    country_code: number;
    customer_name: string;
    customer_sap_code: any;
    department_id: number;
    department_name: string;
    designation: string;
    email: string;
    industry: string;
    landline: string;
    phone_number: number;
    is_activated: boolean;
  }
}
interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedTab: number;
  customerId: string;
  industrySearch: string;
  countrySearch: string;
  customerName: string;
  headCells: any[];
  isAsc: boolean;
  orderBy: string;
  order: any;
  rows: any[];
  TicketListingMeta: null | ITicketListMeta;
  arrayHolderDuplicate: any[];
  count: number;
  page: number;
  token: string;
  ticketList: any[];
  paginationData: any;
  isSearch: boolean;
  searchTerm: string;
  allContactMaster: IContactMaster[];
  csvData: string;
  csvFileName: string;
  isLoading: boolean;
  isOnlyView: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MasterContactController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  authToken: string;
  getFilterAndSearchProductsApiCallId: any;
  getProductApiCallId: any;
  perPageRecords: number = 9;
  searchProductApiCallId: any;
  getContactMasterDownloadTicketsCallId: any;
  csvRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleFilterAndSearch = this.handleFilterAndSearch.bind(this);
    this.handleticketData = this.handleticketData.bind(this);
    this.csvRef = React.createRef();

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      token: "",
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedTab: 1,
      customerId: '',
      industrySearch: '',
      countrySearch: '',
      customerName: '',
      count: 0,
      page: 1,
      ticketList: [],
      isSearch: false,
      searchTerm: '',
      isOnlyView: false,
      rows: [],
      isLoading: false,
      arrayHolderDuplicate: [],
      orderBy: '',
      order: 'asc',
      isAsc: false,
      TicketListingMeta: null,
      paginationData: [],
      allContactMaster: [],
      csvData: "",
      csvFileName: "",
      headCells: [
        {
          id: 'ticket_number',
          numeric: 'left',
          disablePadding: true,
          isSorting: false,
          label: 'Contact ID',
        },
        {
          id: 'product_name',
          numeric: 'left',
          disablePadding: false,
          isSorting: false,
          label: 'Contact Name',
        },
        {
          id: 'status',
          numeric: 'left',
          disablePadding: false,
          isSorting: false,
          label: 'Customer Name',
        },
        {
          id: 'Country',
          numeric: 'left',
          disablePadding: false,
          isSorting: false,
          label: 'Country',
        },
        {
          id: 'viewDetails',
          numeric: 'center',
          disablePadding: false,
          isSorting: false,
          label: 'Check Details',
        },
      ],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async componentDidMount() {
    super.componentDidMount();
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        if(isLoggedIn(this.props.navigation)) {
          autoLogOut();
        }
      });
    });
    const authToken = isLoggedIn(this.props.navigation);
    authorizeEmployee(this.props.navigation);
    localStorage.setItem('previousPath', window.location.pathname)
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
      this.handleFilterAndSearch()
    }
    this.setCXUserOnly();
    this.sliceData();
    this.setState({
      arrayHolderDuplicate: this.state.rows,
    });
  }

  setCXUserOnly = () => {
    let useDetails = JSON.parse(localStorage.getItem("contact_master_permission") || "")
    if (useDetails?.can_create) {
      this.setState({
        isOnlyView: true
      })
    } else {
      this.setState({
        isOnlyView: false
      })
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  sliceData = () => {
    this.setState({
      count: this.state.rows.length,
      paginationData: this.state.rows.slice(0, 6),
    });
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      this.setToken(message);
    }

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      isAuthorisedUser(responseJson, this.props.navigation);
      this.setState({ isLoading: false });
      if (this.getProductApiCallId != null && this.getProductApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        this.setTicketAPIData(responseJson)
      }
      if (this.getContactMasterDownloadTicketsCallId != null && this.getContactMasterDownloadTicketsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        this.setDownloadAPIData(responseJson)
      }

      if (this.getFilterAndSearchProductsApiCallId != null && this.getFilterAndSearchProductsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        this.afterFilterData(responseJson)
      }
    }
    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => { this.setState({ txtInputValue: text }); },
    secureTextEntry: false
  };

  txtInputMobileProps = { ...this.txtInputWebProps, autoCompleteType: "email", keyboardType: "email-address" };

  txtInputProps = this.isPlatformWeb() ? this.txtInputWebProps : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.AuthTokenDataMessage), this.state.txtInputValue);
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  handleChangeTab = (event: any, newValue: any) => {
    this.setState({ selectedTab: newValue });
  };

  debounce = (func: any, wait: number = 700) => {
    let timer: any;
    return (...args: any[]) => {
      clearTimeout(timer)
      timer = setTimeout(() => func(...args), wait);
    };
  };

  debouncedCallback = this.debounce(() => this.handleFilterAndSearch(), 700);

  setTicketData = (responseJson: any) => {
    this.setState({
      rows: responseJson.data,
      ticketList: responseJson.result.data,
      TicketListingMeta: responseJson.result.meta,
    });
  }

  setDataAfterFilter = (responseJson: any) => {
    this.setState({
      rows: responseJson.data,
      allContactMaster: responseJson.result.data,
      TicketListingMeta: responseJson.result.meta,
    });
  }

  handleChange = (prop: any) => (event: any) => {
    const value = event.target.value;
    let customerId = this.state.customerId;
    let customerName = this.state.customerName;
    let country = this.state.countrySearch;
    if (prop === "customerId" && value && value.length > 0) {
      customerId = value;
    } else if (prop === "customerName" && value && value.length > 0) {
      customerName = value;
    } else if (prop === "countrySearch" && value && value.length > 0) {
      country = value;
    }
    if (value && value.length > 0) {
      this.setState({
        ...this.state,
        customerId: customerId,
        countrySearch: country,
        customerName: customerName,
        searchTerm: value,
        page: 1
      }, () => {
        this.debouncedCallback();
      })
    } else {
      this.setState({
        ...this.state,
        [prop]: "",
        searchTerm: "",
        page: 1
      }, () => {
        this.debouncedCallback();
      })
    }
  };

  getComparator(order: string, orderBy: any) {
    return order === 'desc'
      ? (a: any, b: any) => this.descendingComparator(a, b, orderBy)
      : (a: any, b: any) => -this.descendingComparator(a, b, orderBy);
  }

  stableSort(property: string) {
    this.setState({ orderBy: property });
    let sorted = []
    if (this.state.order === 'asc') {
      this.setState({ order: 'desc' });
      sorted = this.state.ticketList.sort((a: any, b: any) => b.attributes[property] > a.attributes[property] ? 1 : -1);
    } else {
      this.setState({ order: 'asc' });
      sorted = this.state.ticketList.sort((a: any, b: any) => a.attributes[property] < b.attributes[property] ? -1 : 1);
    }
    this.setState({ ticketList: sorted });
  }

  descendingComparator(a: { [x: string]: number; }, b: { [x: string]: number; }, orderBy: string | number) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  handlePageChange = (e: any, p: any) => {
    this.setState({ page: p }, () => { this.handleFilterAndSearch(); });
  };

  searchByProductName = (event: any) => {
    const value = event.target.value;
    if (value && value.length > 0) {
      this.setState({ isSearch: true, page: 1, searchTerm: value }, () => {
        this.handleFilterAndSearch();
      });
    }
    else {
      this.setState({ isSearch: false, page: 1, searchTerm: "" }, () => {
        this.handleFilterAndSearch();
      });
    }
  };

  handleFilterAndSearch = () => {
    this.setState({ isLoading: true });
    const header = getHeaders({ token: this.authToken });
    const filterAndSearchApi = apiCall({
      url: configJSON.getContactMasterTicketsApiEndpoint + `per_page=10&page=${this.state.page}&query=${this.state.customerId}&query_one=${this.state.customerName}&query_two=${this.state.countrySearch}`,
      header,
      httpBody: {},
      httpMethod: configJSON.validationApiMethodType
    });

    this.getFilterAndSearchProductsApiCallId = filterAndSearchApi.messageId;
    runEngine.sendMessage(filterAndSearchApi.id, filterAndSearchApi);
    return true;
  }

  handleticketData = (token: any, pageNumber: number = 1) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTicketsAPIEndPoint +
      `?per_page=${this.perPageRecords}&page=${this.state.page}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area Start
  downloadContactMasterTickets = () => {
    this.setState({ isLoading: true });
    const downloadMaster = apiCall({
      url: configJSON.getContactMasterDownloadApiEndpoint + `?query=${this.state.customerId}&query_one=${this.state.customerName}&query_two=${this.state.countrySearch}`,
      httpBody: {},
      httpMethod: configJSON.getContactMasterDownloadApiMethod,
      header: getHeaders({ token: this.authToken })
    });

    this.getContactMasterDownloadTicketsCallId = downloadMaster.messageId;
    runEngine.sendMessage(downloadMaster.id, downloadMaster);
  }

  handleAddNewClick = () => {
    if (this.props.navigation) {
      localStorage.setItem("FromTicket", "From");
      this.props.navigation.navigate("CustomerContactAdd")
    }
  }

  handleViewDetailClick = (id: string) => {
    if (this.props.navigation) {
      localStorage.setItem("FromTicket", "menus");
      this.props.navigation.navigate("CustomerContactEdit", { id: id })
    }
  }

  setEmptyData = () => {
    this.setState({
      allContactMaster: [],
      TicketListingMeta: null
    })
  }

  DownloadContactsData = (responseJson: any) => {
    this.setState({ csvData: responseJson.result, csvFileName: responseJson.filename }, () => {
      this.csvRef.current.link.click()
    })
  }

  setToken = (message: any) => {
    let token = message.getData(getName(MessageEnum.SessionResponseToken));
    this.setState({ token: token });
    this.handleticketData(token);
  }

  afterFilterData = (responseJson: any) => {
    if (!responseJson.error && !responseJson.errors && responseJson.success) {
      this.setDataAfterFilter(responseJson);
    } else if (!responseJson.success) {
      this.setEmptyData()
    }
  }

  setDownloadAPIData = (responseJson: any) => {
    if (responseJson.success && responseJson.result) {
      this.DownloadContactsData(responseJson)
    } else {
      warningNotification("Something went wrong");
    }
  }

  setTicketAPIData = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.success) {
      this.setTicketData(responseJson);
      runEngine.debugLog("arrayHolder", this.state.rows);
    }
  }

  handleTicketRefresh = () => {
    this.handleFilterAndSearch()
  }
  // Customizable Area End

}
