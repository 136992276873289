import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { handleUpdateAuthToken,apiCall, getHeaders, isAuthorisedUser, isLoggedIn, successNotification, errorNotification } from "../../../components/src/common";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

export interface IQueryTicketDetail {
  query_description: string;
  query_type: string;
  status: any;
  ticket_creation_date: string;
  ticket_number: string;
  doc_name: any;
  product?: any;
  view_reply?: any;
  is_reopened?: any;
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  is_loading: boolean;
  query_detail: IQueryTicketDetail | null;
  openToolTip: boolean;
  anchorEl: any;
  popUPText: string;
  isAttachment: boolean;
  isSendComment: boolean;
  ticketComment: any;
  uploadedAPIFilesBase: any;
  uploadedAPIFiles: any;
  isUploadError: boolean;
  openProductToolTip: boolean;
  anchorEProduct: any;
  ProductpopUPText: any;
  DocumentText: string;
  ProductText: string;
  uploadText: string;
  fileSizeText: string;
  CancelBtnText: string;
  SubmitBtnText: string;
  ReadMoreText: string;
  SendButtonText: string;
  LeavecommentBtnText: string;
  SeeAllRepliesText: string;
  requestDetailsText: string;
  ticketdetailsText: string;
  reOpenTicketText: string;
  SignupRequestText: string;
  TicketIDText: string;
  RequestTypeText: string;
  productNameLabel: string;
  RequestRaisedOnText: string;
  RequestDescriptionText: string;
  TypeMessagePlaceholder: string;
  ProductQueryText: string;
  GeneralText: string;
  ConnectWithUsText: string;
  is_reopened: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class QueryDetailsController extends BlockComponent<Props, S, SS> {
  getQueryDetailApiCallId: string;
  ticketId: string;
  authToken: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      is_loading: false,
      query_detail: null,
      openToolTip: false,
      anchorEl: "",
      popUPText: "",
      isAttachment: false,
      isSendComment: false,
      ticketComment: "",
      uploadedAPIFilesBase: [],
      uploadedAPIFiles: [],
      isUploadError: false,
      openProductToolTip: false,
      anchorEProduct: "",
      ProductpopUPText: [],
      DocumentText: configJSON.DocumentText,
      ProductText: configJSON.ProductText,
      uploadText: configJSON.uploadText,
      fileSizeText: configJSON.fileSizeText,
      CancelBtnText: configJSON.CancelBtnText,
      SubmitBtnText: configJSON.SubmitBtnText,
      ReadMoreText: configJSON.ReadMoreText,
      SendButtonText: configJSON.SendButtonText,
      LeavecommentBtnText: configJSON.LeavecommentBtnText,
      SeeAllRepliesText: configJSON.SeeAllRepliesText,
      requestDetailsText: configJSON.requestDetailsText,
      ticketdetailsText: configJSON.ticketdetailsText,
      reOpenTicketText: configJSON.reOpenTicketText,
      SignupRequestText: configJSON.SignupRequestText,
      TicketIDText: configJSON.TicketIDText,
      RequestTypeText: configJSON.RequestTypeText,
      productNameLabel: configJSON.productNameLabel,
      RequestRaisedOnText: configJSON.RequestRaisedOnText,
      RequestDescriptionText: configJSON.RequestDescriptionText,
      TypeMessagePlaceholder: configJSON.TypeMessagePlaceholder,
      ProductQueryText: configJSON.ProductQueryText,
      GeneralText: configJSON.GeneralText,
      ConnectWithUsText: configJSON.ConnectWithUsText,
      is_reopened: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.handleAttachmentPopUP = this.handleAttachmentPopUP.bind(this);
    this.handleCloseAttachmentPopUP = this.handleCloseAttachmentPopUP.bind(this);
    this.handleSendComment = this.handleSendComment.bind(this);
    this.handleFiles = this.handleFiles.bind(this);
    this.handleTicketDetailsClick = this.handleTicketDetailsClick.bind(this);
    this.preventDefaults = this.preventDefaults.bind(this);
    this.handleDetailsDocumentUpload = this.handleDetailsDocumentUpload.bind(this);
    this.fileToDataUri = this.fileToDataUri.bind(this);
    this.handleTicketReopenTicket = this.handleTicketReopenTicket.bind(this);
    this.setReopenTicketResponseData = this.setReopenTicketResponseData.bind(this);
    this.handleQueryDetailsResponse = this.handleQueryDetailsResponse.bind(this);
    this.handleLeaveCommentResponse = this.handleLeaveCommentResponse.bind(this);
    this.setProductIds = this.setProductIds.bind(this);
    this.setProductBucketId = this.setProductBucketId.bind(this);
    this.setPopProductId = this.setPopProductId.bind(this);
    this.handleToolTip = this.handleToolTip.bind(this);
    this.handleProductToolTip = this.handleProductToolTip.bind(this);
    this.setCommentResponseData = this.setCommentResponseData.bind(this);
    this.getQueryDetail = this.getQueryDetail.bind(this);
    this.setQueryDetails = this.setQueryDetails.bind(this);
    this.tokenErrorResponse = this.tokenErrorResponse.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.setFileSizeError = this.setFileSizeError.bind(this);
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const authToken = isLoggedIn(this.props.navigation);
    this.ticketId = this.props.navigation.getParam("id");
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
      this.getQueryDetail();
    }
    const dropArea = document.getElementById("drop-area");
    ["dragenter", "dragover", "dragleave", "drop"].forEach(eventName => {
      {/* @ts-ignore */ }
      dropArea.addEventListener(eventName, this.preventDefaults, false);
    });
    {/* @ts-ignore */ }
    dropArea.addEventListener("drop", this.handleDrop, false);
    // Customizable Area End
  }

  preventDefaults = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDrop = (e: any) => {
    const dt = e.dataTransfer;
    const { files } = dt;
    this.handleFiles(files);
  };

  handleFiles = (files: any) => {
    let reader = new FileReader();
    reader?.readAsDataURL(files[0]);
    reader.onloadend = function () {};
  };

  handleTicketDetailsClick = () => {
    if (this.props.navigation) {
      this.props.navigation.navigate("QueryDetailsTicket", { id: this.props.navigation.getParam('id') })
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      isAuthorisedUser(responseJson, this.props.navigation);
      this.setState({is_loading: false});

      if (this.getQueryDetailApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        this.handleQueryDetailsResponse(responseJson);
      }

      if (this.LeaveaCommentApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        this.handleLeaveCommentResponse(responseJson);
      }

      if(this.ReopenTicketsAPICallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        this.setState({is_loading: false, is_reopened: true})
        this.setReopenTicketResponseData(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  LeaveaCommentApiCallId: string;
  ReopenTicketsAPICallID: string;

  handleQueryDetailsResponse = (responseJson: any) => {
    if (responseJson && responseJson.result && responseJson.result.length > 0) {
      this.setQueryDetails(responseJson)
    } else {
      if(responseJson.errors && responseJson.errors[0]?.token === configJSON.TokenhasExpired && JSON.parse(localStorage.getItem("userInfo") || "")?.attributes?.is_customer === 'True') {
        this.tokenErrorResponse()
        if(this.authToken){
          this.getQueryDetail();
        }
      }
    }
  }

  handleLeaveCommentResponse = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setCommentResponseData();
    } else {
      if (responseJson.errors && responseJson.errors[0]?.token === configJSON.TokenhasExpired && JSON.parse(localStorage.getItem("userInfo") || "")?.attributes?.is_customer === 'True') {
        this.tokenErrorResponse()
        if(this.authToken){
          this.sendComment();
        }
      }
    }
  }

  setCommentResponseData = () => {
    this.setState({
      is_loading: false,
      isSendComment: false,
      uploadedAPIFiles: [],
      uploadedAPIFilesBase: [],
    })
    this.getQueryDetail();
    successNotification("Ticket Comment added successfully");
  }

  tokenErrorResponse = async () => {
    this.setState({is_loading: true});
    await handleUpdateAuthToken();
     const authToken = isLoggedIn(this.props.navigation);
    if (typeof (authToken) === "string") {
       this.authToken = authToken;
    } 
  }
  
  setQueryDetails = (responseJson: any) => {
    this.setState({
      query_detail: responseJson?.result[0]
    })
    localStorage.setItem('ticket_status', responseJson.result[0].status)
  }

  setReopenTicketResponseData = (responseJson: any) => {
    if (responseJson.data) {
      this.getQueryDetail();
      successNotification(configJSON.ticketOpenSuccessMsg)
    } else {
      errorNotification(responseJson?.errors[0]?.status)
    }
  }

  getQueryDetail() {
    this.setState({
      is_loading: true
    });
    const header = getHeaders({ token: this.authToken });
    const fetchQueryDetail = apiCall({
      url: configJSON.getMyQueryDetailApiEndpoint + `${this.ticketId}/show_my_ticket`,
      header,
      httpBody: {},
      httpMethod: configJSON.getMyQueryDetailApiMethod
    });

    this.getQueryDetailApiCallId = fetchQueryDetail.messageId;
    runEngine.sendMessage(fetchQueryDetail.id, fetchQueryDetail);
  }

  handleToolTip = (event: any, text: any) => {
    this.setState({ openToolTip: !this.state.openToolTip });
    this.setState({ anchorEl: event?.currentTarget });
    this.setState({ popUPText: text });
  };

  handleProductToolTip = (event: any, arrayName: any, arrayId: any) => {
    this.setState({ openProductToolTip: !this.state.openProductToolTip });
    this.setState({ anchorEProduct: event.currentTarget });
    arrayName.forEach((element: any, index: number) => {
      return (
        element.id = arrayId[index].product_id
      )
    });
    this.setState({ ProductpopUPText: arrayName });
  }

  handleAttachmentPopUP = () => {
    this.setState({
      isAttachment: !this.state.isAttachment
    })
  };

  handleCloseAttachmentPopUP = () => {
    this.setState({
      isAttachment: !this.state.isAttachment,
      uploadedAPIFilesBase: []
    });
    this.setFileSizeError(false)
  };

  handleSendComment = () => {
    this.setState({
      isSendComment: !this.state.isSendComment
    })
  };

  handleCommentChange = (e: any) => {
    this.setState({
      ticketComment: e?.target?.value
    })
  };

  sendComment = () => {
    this.ticketId = this.props.navigation.getParam("id");
    const httpBody = {
      customer_attachments_attributes: this.state.uploadedAPIFilesBase,
      message: this.state.ticketComment,
    }
    const connectWithUs = apiCall({
      url: configJSON.leaveACommenyApiEndPoint + `?ticket_id=${this.ticketId}`,
      httpBody: httpBody,
      httpMethod: configJSON.downloadCatalogSentEmailMethod,
      header: getHeaders({ token: this.authToken })
    });

    this.LeaveaCommentApiCallId = connectWithUs.messageId;
    runEngine.sendMessage(connectWithUs.id, connectWithUs);
    this.setState({
      is_loading: true
    })
  }

  fileToDataUri = (image: any) => {
    return new Promise((res) => {
      const reader = new FileReader();
      const { name, } = image;
      reader.addEventListener('load', () => {
        res({ file_name: name, upload: reader.result })
      });
      reader?.readAsDataURL(image);
    })
  };

  handleDetailsDocumentUpload = async (files: any) => {
    const uploadedFiles = [].slice.call(files);
    uploadedFiles.forEach((file: File) => {
      return {
        name: file.name
      }
    })

    for (let i = 0; i < files.length; i++) {
      this.state.uploadedAPIFilesBase.push(this.fileToDataUri(files[i]))
    }

    const fileBase64 = await Promise.all(this.state.uploadedAPIFilesBase)
    this.setState({
      uploadedAPIFilesBase: fileBase64,
      uploadedAPIFiles: uploadedFiles
    });
  };

  setFileSizeError = (value: boolean) => {
    this.setState({
      isUploadError: value
    })
  };

  handleTicketReopenTicket = () => {
    this.setState({is_loading: true})
    const ticketId = this.props.navigation.getParam('id')
    const body = {
      data: {
        attributes: {
          status: 0
        }
      }
    }
    const ReopenTickets = apiCall({
      header: getHeaders({ token: this.authToken }),
      httpMethod: configJSON.reOpenTicketApiMethodType,
      httpBody: body,
      url: configJSON.reopenTicketAPIEndPoint + `${ticketId}`
    });

    this.ReopenTicketsAPICallID = ReopenTickets.messageId;
    runEngine.sendMessage(ReopenTickets.id, ReopenTickets);
  }

  setProductIds = () => {
    localStorage.setItem("ProductId", this.state.query_detail?.product[0]?.id[0]?.product_id)
  }

  setProductBucketId = () => {
    localStorage.setItem("ProductId", this?.state?.query_detail?.product[0]?.id)
  }

  setPopProductId = (data: any) => {
    localStorage.setItem("ProductId", data.id)
  }

  // Customizable Area End
}
