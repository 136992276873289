import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall, getHeaders } from "../../../components/src/common";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface ITicketListMeta {
  pagination_details: {
    current_count: number;
    current_page: number;
    next_page: number;
    prev_page: null | number;
    total_count: number;
    total_pages: number;
  };
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedTab: number;
  ticketId: string;
  productName: string;
  customerName: string;
  headCells: any[];
  isAsc: boolean;
  orderBy: string;
  order: any;
  rows: any[];
  TicketListingMeta: null | ITicketListMeta;
  arrayHolderDuplicate: any[];
  count: number;
  page: number;
  token: string;
  ticketList: any[];
  paginationData: any;
  isSearch: boolean;
  searchTerm: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdminConsole3Controller extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  authToken: string;
  getFilterAndSearchProductsApiCallId: any;
  getProductApiCallId: any;
  perPageRecords: number = 9;
  searchProductApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleFilterAndSearch = this.handleFilterAndSearch.bind(this);
    this.handleticketData = this.handleticketData.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      token: "",
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedTab: 1,
      ticketId: '',
      productName: '',
      customerName: '',
      count: 0,
      page: 1,
      ticketList: [],
      isSearch: false,
      searchTerm: '',
      headCells: [
        {
          id: 'ticket_number',
          numeric: 'left',
          disablePadding: true,
          isSorting: false,
          label: 'Ticket ID',
        },
        {
          id: 'product_name',
          numeric: 'left',
          disablePadding: false,
          isSorting: false,
          label: 'Product Name',
        },
        {
          id: 'query_type',
          numeric: 'left',
          disablePadding: false,
          isSorting: true,
          label: 'Query Type',
        },
        {
          id: 'status',
          numeric: 'center',
          disablePadding: false,
          isSorting: true,
          label: 'Status',
        },
        {
          id: 'customer_name',
          numeric: 'left',
          disablePadding: false,
          isSorting: false,
          label: 'Customer Name',
        },
        {
          id: 'viewDetails',
          numeric: 'center',
          disablePadding: false,
          isSorting: false,
          label: 'Check Details',
        },
      ],
      rows: [],
      arrayHolderDuplicate: [],
      orderBy: '',
      order: 'asc',
      isAsc: false,
      TicketListingMeta: null,
      paginationData: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async componentDidMount() {
    super.componentDidMount();
    let token = localStorage.getItem('authToken') || "";
    this.authToken = token;
    this.handleticketData(token)
    this.sliceData();

    this.setState({
      arrayHolderDuplicate: this.state.rows,
    });
  }

  sliceData = () => {
    this.setState({
      count: this.state.rows.length,
      paginationData: this.state.rows.slice(0, 6),
    });
  };

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.handleticketData(token);
    }

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }



    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (
        this.getProductApiCallId != null &&
        this.getProductApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
        this.setState({
          ticketList: responseJson.product_queries.concat(responseJson.sample_requests),
        });
        runEngine.debugLog("arrayHolder", this.state.rows);
      }
      if (
        this.getFilterAndSearchProductsApiCallId != null &&
        this.getFilterAndSearchProductsApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
        if (responseJson && !responseJson.errors && responseJson.success) {
          this.setState({
            rows: responseJson.data,
            ticketList: responseJson.result.data,
            TicketListingMeta: responseJson.result.meta,
          });
        } else {
          this.setState({
            ticketList: [],
            TicketListingMeta: null
          })
        }
      }
    }



    // Customizable Area Start
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  handleChangeTab = (event: any, newValue: any) => {
    this.setState({ selectedTab: newValue });
  };

  handleChange = (prop: any) => (event: any) => {
    this.setState({ ...this.state, [prop]: event.target.value });
    this.searchByProductName(event)
  };

  stableSort(property: string) {
    this.setState({ orderBy: property });
    let sorted = []
    if (this.state.order === 'asc') {
      this.setState({ order: 'desc' });
      sorted = this.state.ticketList.sort((a: any, b: any) => b.attributes[property] > a.attributes[property] ? 1 : -1);
    } else {
      this.setState({ order: 'asc' });
      sorted = this.state.ticketList.sort((a: any, b: any) => a.attributes[property] < b.attributes[property] ? -1 : 1);
    }
    this.setState({ ticketList: sorted });
  }

  getComparator(order: string, orderBy: any) {
    return order === 'desc'
      ? (a: any, b: any) => this.descendingComparator(a, b, orderBy)
      : (a: any, b: any) => -this.descendingComparator(a, b, orderBy);
  }

  descendingComparator(a: { [x: string]: number; }, b: { [x: string]: number; }, orderBy: string | number) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  handlePageChange = (e: any, p: any) => {
    this.setState({
      page: p,
    }, () => {
      this.handleFilterAndSearch();
    });
  };

  handleticketData = (token: any, pageNumber: number = 1) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.customerTicketsAPIPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  searchByProductName = (event: any) => {
    const value = event.target.value;
    if (value && value.length > 0) {
      this.setState({
        isSearch: true,
        page: 1,
        searchTerm: value,
      }, () => {
        this.handleFilterAndSearch();
      });
    }
    else {
      this.setState({
        isSearch: false,
        page: 1,
        searchTerm: "",
      }, () => {
        this.handleFilterAndSearch();
      });
    }
  };

  handleFilterAndSearch = (p: number = 1) => {
    const header = getHeaders({ token: this.authToken });
    let url = "";

    url = configJSON.customerTicketsAPIPoint;
    const filterAndSearchApi = apiCall({
      url: url,
      header,
      httpBody: {},
      httpMethod: configJSON.validationApiMethodType
    });

    this.getFilterAndSearchProductsApiCallId = filterAndSearchApi.messageId;
    runEngine.sendMessage(filterAndSearchApi.id, filterAndSearchApi);

  }


  // Customizable Area Start
  handleTicketListClick = (id: number) => {
    if (this.props.navigation) {
      this.props.navigation.navigate("DataStorage", { id })
    }
  }
  // Customizable Area End

}
