import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "../../CustomisableUserProfiles/src/assets";
import {
  apiCall,
  errorNotification,
  getHeaders,
  getUserdetails,
  IUserdetails,
  successNotification,
  isLoggedIn,
} from "../../../components/src/common";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  ProfileDetailLabel: string;
  labelFirstName: string;
  lastnameLabel: string;
  biospaceIdLabel: string;
  companynameLabel: string;
  reportManagerLabel: string;
  emailLabel: string;
  phoneNumLabel: string;
  departmentLabel: string;
  TerritoryLabel: string;
  assignedCustomerLabel: string;
  ProfileLabel: string;
  departmentList: any[];
  codeList: any[];
  firstName: string;
  lastName: string;
  companyName: string;
  emailId: string;
  biospaceId: string;
  reportManager: {
    first_name: string,
    last_name: string,
  };
  assignedCustomer: string;
  profile_id: number;
  phoneNumber: string;
  phoneCountryCode: string;
  department: number;
  isLoading: boolean;
  CountryLabel: string;
  ProfileList: any[];
  territoryList: any[];
  departmentName: string;
  profile: string;
  territory_id: number;
  territory: string;
  comcompany_id: number;
  countryName: string;
  phoneNumberEditFlag: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomerProfilesForEmpControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  updateAccountApiCallId: any;
  updateCustomerProfileApiCallId: any;
  getEmployeeProfileApiCallId: any;
  getDepartmentListApiCallId: any;
  getProfileListApiCallId: any;
  userDetails: IUserdetails;
  authToken: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      ProfileDetailLabel: configJSON.ProfileDetailLabel,
      labelFirstName: configJSON.labelFirstName,
      lastnameLabel: configJSON.lastnameLabel,
      biospaceIdLabel: configJSON.biospaceIdLabel,
      companynameLabel: configJSON.companynameLabel,
      reportManagerLabel: configJSON.reportManagerLabel,
      assignedCustomerLabel: configJSON.assignedCustomerLabel,
      ProfileLabel: configJSON.ProfileLabel,
      TerritoryLabel: configJSON.TerritoryLabel,
      emailLabel: configJSON.emailLabel,
      phoneNumLabel: configJSON.phoneNumLabel,
      departmentLabel: configJSON.departmentLabel,
      CountryLabel: configJSON.CountryLabel,
      departmentList: [],
      codeList: [],
      firstName: "",
      lastName: "",
      companyName: "",
      emailId: "",
      biospaceId: "",
      reportManager: { first_name: '', last_name: '' },
      assignedCustomer: "",
      profile_id: 0,
      phoneCountryCode: "91",
      phoneNumber: "",
      department: 0,
      isLoading: false,
      ProfileList: [],
      territoryList: [],
      departmentName: '',
      profile: '',
      territory_id: 0,
      territory: '',
      comcompany_id: 0,
      countryName: '',
      phoneNumberEditFlag: true,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.onProfileDetailsSave = this.onProfileDetailsSave.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    this.handlePhoneCountryCode = this.handlePhoneCountryCode.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getEmployeeProfileApiCallId) {
          if (!responseJson.error) {
            const profileData = responseJson.data.attributes;
            this.setState({
              firstName: profileData.first_name || "",
              lastName: profileData.last_name,
              comcompany_id: profileData.company_id,
              companyName: profileData.company_name || "",
              emailId: profileData.email,
              phoneNumber: profileData.phone_number,
              phoneCountryCode: String(profileData.country_code),
              department: profileData.department_id,
              departmentName: profileData.department,
              biospaceId: profileData.biospace_id,
              assignedCustomer: profileData.assigned_customer,
              reportManager: profileData.reporting_manager,
              profile_id: profileData.profile_id,
              profile: profileData.profile,
              territory_id: profileData.territory_id,
              territory: profileData.territory,
              countryName: profileData.country,
            });
          }
        }
      }

      // if (apiRequestCallId === this.getDepartmentListApiCallId) {
      //   if (!responseJson.error) {
      //     this.setState({ departmentList: responseJson.employee_departments });
      //     this.setState({ ProfileList: responseJson.employee_profiles });
      //     this.setState({ territoryList: responseJson.employee_territories });
      //   }
      // }

      if (apiRequestCallId === this.updateAccountApiCallId) {
        //     /* START --------------------------------------------------------------  Update account Api Call Response Handled*/
        if (!responseJson.errors) {
          successNotification("Profile Details Updated Successfully");
        } else {
          //Check Error Response
          errorNotification(responseJson?.errors[0]?.phone_number);
          this.setState({
            phoneNumberEditFlag: false,
          })
        }

        this.parseApiCatchErrorResponse(errorReponse);
        //   /* END --------------------------------------------------------------  Update account Api Call Response Handled*/
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    const authToken = isLoggedIn(this.props.navigation);
    if (typeof authToken === "string") {
      this.authToken = authToken;
      const userDetailToken = getUserdetails();
      // this.getDepartmentList();
      if (userDetailToken) {
        this.userDetails = userDetailToken;
        this.getProfileDetails();
      }
    }
  }

  handleChange = (prop: any) => (event: any) => {
    this.setState({ ...this.state, [prop]: event.target.value });
  };

  onProfileDetailsSave = () => {

    if (
      // this.isStringNullOrBlank(this.state.firstName) ||
      // this.isStringNullOrBlank(this.state.lastName) ||
      // this.isStringNullOrBlank(this.state.companyName) ||
      // this.isStringNullOrBlank(this.state.emailId) ||
      // this.isStringNullOrBlank(this.state.biospaceId) ||
      // this.isStringNullOrBlank(this.state.reportManager) ||
      // this.isStringNullOrBlank(this.state.assignedCustomer) ||
      this.isStringNullOrBlank(this.state.phoneCountryCode) ||
      this.isStringNullOrBlank(String(this.state.phoneNumber))
    ) {
      errorNotification("Phone number Required");
      this.setState({
        phoneNumberEditFlag: false,
      })
      return false;
    }

    const header = getHeaders({ token: this.authToken });

    const body = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.emailId,
      biospace_id: this.state.biospaceId,
      country_code: this.state.phoneCountryCode,
      country: this.state.countryName,
      phone_number: this.state.phoneNumber,
      reporting_manager: this.state.reportManager,
      assigned_customer: this.state.assignedCustomer,
      company_id: this.state.comcompany_id,
      company_name: this.state.companyName,
      department: this.state.departmentName,
      department_id: this.state.department,
      profile_id: this.state.profile_id,
      profile: this.state.profile,
      territory_id: this.state.territory_id,
      territory: this.state.territory,
    };

    const updateAccount = apiCall({
      httpBody: body,
      header: header,
      url: configJSON.updateProfileApiEndpoint + this.userDetails.id,
      // this.userDetails.id,
      httpMethod: configJSON.updateAccountApiMethod,
    });

    this.updateAccountApiCallId = updateAccount.messageId;
    runEngine.sendMessage(updateAccount.id, updateAccount);

    return true;
  };

  getProfileDetails = () => {
    const header = getHeaders({ token: this.authToken });

    const getAccount = apiCall({
      httpBody: {},
      header: header,
      url:
        configJSON.getEmployeeProfileApiEndpoint + this.userDetails?.id,
      httpMethod: configJSON.getEmployeeProfileApiMethod,
    });

    this.getEmployeeProfileApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  };

  // getDepartmentList = () => {
  //   const header = getHeaders({ token: this.authToken });

  //   const getDepartments = apiCall({
  //     httpBody: {},
  //     header: header,
  //     url: configJSON.getDepartsmentListApiEndpointForEmp,
  //     httpMethod: configJSON.getDepartmentListApiMethod,
  //   });

  //   this.getDepartmentListApiCallId = getDepartments.messageId;
  //   runEngine.sendMessage(getDepartments.id, getDepartments);
  // };

  isStringNullOrBlank(str: string) {
    return str === null || str.trim().length === 0;
  }

  handlePhoneCountryCode(event: any, value: any) {
    if (value) {
      this.setState({
        phoneCountryCode: event.value,
        countryName: event.label
      });
    }
  };

  // handleDepartmentChange = (event: any, value: any) => {
  //   this.setState({
  //     department: value.props.value,
  //     departmentName: value.props.children
  //   });
  // };

  // handleProfileChange = (event: any, value: any) => {
  //   this.setState({
  //     profile_id: value.props.value,
  //     profile: value.props.children
  //   });
  // };

  // handleTerritoryChange = (event: any, value: any) => {
  //   this.setState({
  //     territory_id: value.props.value,
  //     territory: value.props.children
  //   });
  // };

  handlePhoneNumberEditFlag = () => {
    this.setState({
      phoneNumberEditFlag: !this.state.phoneNumberEditFlag,
    })
  }

  handlePhoneNumberClear = () => {
    this.setState({
      phoneNumber: '',
    })
  }

  handlePhoneNumberSave = () => {
    this.setState({
      phoneNumberEditFlag: true,
    })
    this.onProfileDetailsSave();
  }

  // Customizable Area End
}
