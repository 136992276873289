import React, { useState } from "react";
import { NavLink as RLink } from "react-router-dom";
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';
import { withStyles } from '@material-ui/core/styles';
import { getHighlightedText } from "../../../components/src/common";
import { IProductListing } from "./ProductListingController";
import {
    Button,
    Typography,
    Box,
    Grid,
    Popover,
    Card,
    CardHeader,
    CardContent,
    CardActions,
} from "@material-ui/core";

interface ICardprops {
    CardClass: any,
    CardValueClass: any,
    CardButtonClass: any,
    item: IProductListing,
    searchTerm: string,
}

const DevelopementProductcard = (props: ICardprops) => {
    const { CardValueClass, CardButtonClass, CardClass, item } = props;
    const [popUPText, setPopUPData] = useState(null);
    const [anchorE2, setAnchorE2] = useState(null);
    const [openToolTip, setToolTip] = useState(false);
    let value = item.attributes;

    const handleDataToolTip = (event: any, text: any) => {
        setToolTip(!openToolTip);
        setAnchorE2(event.currentTarget);
        setPopUPData(text);
    };
    const TheProductTitle = () => {
        return (
            <Box>
                {getHighlightedText(value.name, props.searchTerm)}
                <Typography className="subTitle" style={{ fontSize: "14px", color: "#8823b4", fontWeight: 600 }}>
                    &#40;{item.attributes.if_pipeline_product}&#41;
                </Typography>
            </Box>

        )
    };
    const ProductNameToolTip = () => {
        return (
            <Box>
                <Grid>
                    <CancelTwoToneIcon
                        data-testid='CancelTwoToneIcon'
                        style={{ fill: "rgba(4, 60, 116, 1)", float: 'right' }}
                        onClick={(e: any) => handleDataToolTip(e, "")}
                    >
                    </CancelTwoToneIcon>
                </Grid>
                <Grid item lg={12}>
                    <Typography style={webStyle.ProductToolTip}>
                        {popUPText}
                    </Typography>
                </Grid>
            </Box>
        )
    };

    const ReadMoreLabel = () => {
        return (
            <>
                <Typography
                    data-testid='ReadMoreLabels'
                    component={"span"}
                    style={webStyle.MoredotVal}
                    aria-owns={openToolTip ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={(e: any) => handleDataToolTip(e, value.therapeutic_area)}
                    onMouseLeave={(e: any) => handleDataToolTip(e, value.therapeutic_area)}
                >...</Typography>
                <Typography
                    data-testid='ReadMoreLabelClass'
                    component={"span"}
                    style={webStyle.ReadMoreLabelStyle}
                    aria-owns={openToolTip ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={(e: any) => handleDataToolTip(e, value.therapeutic_area)}
                >
                    Read More
                </Typography>
            </>
        )
    }

    return (
        <ProductCard key={`product-item-${item.id}`} style={{ height: '100%' }}>
            <CardHeader style={{ ...webStyle.productHeading, ...(CardClass) }} title={TheProductTitle()} />
            <ProductCardContent>
                <Box display="flex" alignItems="center" justifyContent={"center"}>
                    <Typography style={webStyle.ProductdetailLabel} align="center" className="details">
                        Manufacturing Platform: <span style={CardValueClass}>
                            {getHighlightedText(value.manufacturing_platform.slice(0, 40), props.searchTerm)}
                            {value.manufacturing_platform !== undefined && value.manufacturing_platform.length > 40 && (
                                <>{ReadMoreLabel()}</>
                            )
                            }
                        </span>
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center" justifyContent={"center"}>
                    <Typography style={webStyle.ProductDetailLabel2} align="center" className="details">
                        Therapeutic Area: <span className="textWrap" style={CardValueClass}>
                            {getHighlightedText(value.therapeutic_area.slice(0, 40), props.searchTerm)}
                            {value.therapeutic_area !== undefined && value.therapeutic_area.length > 40 && (
                                <>
                                    {ReadMoreLabel()}
                                </>
                            )
                            }
                        </span>
                    </Typography>
                    <ProductNamePopover
                        data-testid='ChemicalDetailPopover'
                        open={openToolTip}
                        id="mouse-over1-popover"
                        disableRestoreFocus
                        anchorEl={anchorE2}
                        onClose={(e: any) => handleDataToolTip(e, value.therapeutic_area)}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}>
                        {ProductNameToolTip()}
                    </ProductNamePopover>
                </Box>
            </ProductCardContent>
            <ProductCardAction>
                <RLink to={`/ProductDetail/${item.attributes.name.replace(/\s+/g, '-')}`}>
                    <Button
                        data-testid='btnKnowMore'
                        onClick={
                            () => localStorage.setItem('ProductId', item.id)
                        }
                        style={{ ...webStyle.cardActionButton, ...(CardButtonClass) }}
                        className="knowMoreButton knowMoreButtonHover"
                        variant="contained"
                    >
                        Know More
                    </Button>
                </RLink>
            </ProductCardAction>
        </ProductCard>
    );
};

const webStyle = {
    productHeading: {
        color: "rgba(4, 60, 116, 1)",
        textAlign: "center" as "center",
        height: "75px",
    },
    ProductdetailLabel: {
        color: "#6a74a5",
        fontSize: '16px',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        margin: '20px 24px 5px 24px',
    },
    ProductDetailLabel2: {
        color: "#6a74a5",
        fontSize: '16px',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        margin: '0px 24px 0px 24px',
    },
    cardActionButton: {
        borderRadius: '21px',
        height: "34px",
        width: '146px',
        fontFamily: 'SFProDisplay',
        fontWeight: 500,
        margin: '56px 104px 0',
        boxShadow: 'none',
    },
    MoredotVal: {
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '16px',
        color: 'rgba(0, 0, 0, 0.85)',
        padding: '0px 0px 0px 0px',
    },
    ReadMoreLabelStyle: {
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '14px',
        color: 'rgba(0, 169, 255, 1)',
        padding: '0px 0px 0px 1px',
        textDecoration: 'none',
        marginLeft: '2px',
        "&:hover": {
            textDecoration: 'underline',
        },
        cursor: 'hand',
    },
    ProductToolTip: {
        fontSize: '14px',
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        padding: '12px 5px 10px 5px',
        color: 'rgba(4, 60, 116, 1)',
    },
};

export default DevelopementProductcard;

const ProductCard: any = withStyles({
    root: {
        borderColor: "rgba(185, 187, 197, 1)",
        margin: '0px 0px 0px 0px',
        borderWidth: '2px',
        borderRadius: "16px",
        "& .MuiCardHeader-root": {
            padding: '0px'
        },
        "& .MuiPaper-elevation1": {
            boxShadow: `0px 2px 1px - 1px #dae0f0 ,
                        0px 1px 1px 0px #dae0f0 ,
                        0px 1px 3px 0px #dae0f0`,
        },
        "&:hover": {
            "& .subTitle": {
                color: "#fff !important"
            },
            "& .knowMoreButton": {
                boxShadow: `0 2px 4px 0 rgba(0, 0, 0, 0.4) !important`,
                backgroundColor: "#fff!important",
                color: "#003173 !important",
            },
            "& knowMoreButtonHover": {
                color: "#fff !important"
            },
            "& .MuiCardHeader-root": {
                backgroundColor: `#003173 !important`,
                color: "#fff"
            },
            "& .MuiCardHeader-title": {
                color: "#fff"
            },
            "& .details span": {
                color: "#fff!important",
            },
            "& .details": {
                color: "#fff!important",
            },
            backgroundColor: "#003173",
            boxShadow: '0px 4px 30px 0 rgba(0, 0, 0, 0.18)',
        },
        "@media (min-width: 1280px) and (max-width: 1381px)": {
            "& .details": {
                inlineSize: '63%',
            }
        },
    }
})(Card)

const ProductCardContent: any = withStyles({
    root: {
        padding: '0px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
})(CardContent);

const ProductCardAction: any = withStyles({
    root: {
        padding: '0px',
        justifyContent: "center",
    }
})(CardActions);

const ProductNamePopover: any = withStyles({
    paper: {
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '20px',
        minWidth: 300,
        maxWidth: 300,
        boxShadow: "3px 4px 25px 2px lightGray",
        padding: '5px 10px',
        overflowX: "unset",
        overflowY: "unset",
        borderRadius: 8,
        backgroundColor: "rgba(255, 255, 255, 1)",
        color: 'rgba(4, 60, 116, 1)',
        "@media (max-width: 980px)": {
            width: '68%',
        },
        "&::before": {
            width: 14,
            height: 14,
            content: '""',
            display: "block",
            position: "absolute",
            transform: "rotate(45deg)",
            backgroundColor: "rgba(255, 255, 255, 1)",
            top: -6,
            left: "calc(50% - 10px)",
        },
    }
})(Popover);

