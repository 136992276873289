Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.reOpenTicketApiMethodType = "PUT";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "DataStorage";
exports.labelBodyText = "DataStorage Body";

exports.btnExampleTitle = "CLICK ME";
exports.btnExampleTitle = "CLICK ME";
exports.Heading = "Request Details";
exports.AttachmentsText = "Attachments";
exports.productLabel = "Product Name";
exports.ticketIdLabel = "Ticket ID";
exports.queryTypeLabel = "Query Type";
exports.docTypeLabel = "Doc Type";
exports.pendingWithLabel = "Pending With";
exports.subTypeLabel = "Sub Type";
exports.submittedByLabel = "Submitted by";
exports.Category = "Category";
exports.assigntoLabel="Assigned To";
exports.ChemicalLabel = "Chemical Name";
exports.descriptionlabel = "Product DMF";
exports.documentLabel = "Documents";
exports.passwordTioolTip = "This document is password protected";
exports.queryTransferLabel = "Transfer query to";
exports.RemarksLabel = "Remarks";
exports.internalCommentsLabel = "Internal Comments";
exports.replyCustomerLabel = "Reply to customer";
exports.MarkLabel = "Mark as Closed";
exports.getQueryDetailApiEndpoint = "/bx_block_catalogue/tickets/";
exports.getQueryDetailApiMethod = "GET";
exports.getEmployeesListApiEndpoint = "account_block/bam_details";
exports.getEmployeesListApiMethod = "GET";
exports.createGrantAcessApiEndpoint = "";
exports.createGrantAccessApiMethod = "";
exports.createTransferQueryApiEndpoint = "account_block/take_action";
exports.createTransferQueryApiMethod = "POST";
exports.createReplyCustomerApiEndpoint =
  "account_block/take_action/action_reply_customer";
exports.createReplyCustomerApiMethod = "POST";
exports.getFolderListingsApiEndpoint = "account_block/take_action/listing";
exports.getFolderListingApiMethod = "GET";
exports.getFolderFilesListingApiEndpoint =
  "account_block/take_action/common_folder_listing?";
exports.getFolderFilesListingApiMethod = "GET";
exports.getInvoiceFilesListingApiEndpoint =
  "account_block/take_action/invoice_listing?";
exports.getInvoiceFilesListingApiMethod = "GET";
exports.getAllRemarksApiEndpoint = "account_block/take_action/all_remarks";
exports.getAllRemarksApiMethod = "GET";
exports.getAllRepliesApiEndpoint = "account_block/take_action/all_replies";
exports.getAllRepliesApiMethod = "GET";
exports.remarkHistoryLabel = "Remark History";
exports.remarkHistoryLabel = "Reply History";
exports.labelFirstName = "First Name";
exports.companynameLabel = "Company Name";
exports.emailLabel = "Email ID";
exports.lastnameLabel = "Last Name";
exports.countryCodeLabel = "Country Code";
exports.phoneNumLabel = "Mobile No";
exports.departmentLabel = "Department";
exports.userDetailLabel = "User Details";
exports.additionalDetailLabel = "Additional Details";
exports.addressLabel = "Address";
exports.designationLabel = "Designation";
exports.labelCustomerName = "Customer Name";
exports.sapCodeLabel = "Customer SAP Code";
exports.customerTypeLabel = "Customer Type";
exports.userDetailLabel = "User Details";
exports.additionalDetailLabel = "Additional Details";
exports.addressLabel = "Address";
exports.designationLabel = "Designation";
exports.labelCustomerName = "Customer Name";
exports.sapCodeLabel = "Customer SAP Code";
exports.customerTypeLabel = "Customer Type";
exports.parentCustomerLabel2 = "Parent Customer Name";
exports.parentCustomerLabel = "First Name";
exports.alternateEmailLabel = "Alternate Email";
exports.contactUniqueLabel = "Contact Unique ID";
exports.landlinelabel = "Landline";
exports.countryLabel = "Country";
exports.getDepartsmentListApiEndpoint =
  "bx_block_customer_profile/customer_profiles/get_department";
exports.getDepartmentListApiMethod = "GET";
exports.getSignupRequestApiEndpoint =
  "account_block/customer_masters/account_data";
exports.getSignupRequestApiMethod = "GET";
exports.getCompaniesListApiEndPoint = "account_block/accounts/company_listing";
exports.getCompaniesListApiMethod = "GET";
exports.companyError = "Please Select Company";
exports.emailError = "Please Enter Valid Email";
exports.createdCustomerPopupText =
  "This signup request has been approved and Contact ID has been generated.";
exports.approveSignupRequestApiEndpoint =
  "account_block/customer_masters/approve_user_detail";
exports.approveSignupRequestApiMethod = "POST";
exports.rejectSignupRequestApiEndpoint =
  "account_block/customer_masters/reject_user_detail";
exports.rejectSignupRequestApiMethod = "POST";
exports.rejectPopupText = "The signup request has been rejected.";
exports.getDocumentSelectionBucketListingApiEndpoint =
  "bx_block_catalogue/catalogues/product_bucket_listing";
exports.getDocumentSelectionBucketListingApiMethod = "GET";
exports.getAllSelectedBucketFilesApiEndpoint =
  "bx_block_catalogue/catalogues/document_listing";
exports.getAllSelectedBucketFilesApiMethod = "GET";
exports.grantAccessToTicketApiEndpoint =
  "account_block/take_action/grant_access";
exports.grantAccessToTicketApiMethod = "POST";
exports.getAllCommonFolderFilesApiEndpoint =
  "account_block/take_action/common_folder_listing";
exports.getAllCommonFolderFilesApiMethod = "GET";
exports.getAllInvoiceFolderFilesApiEndpoint =
  "account_block/take_action/invoice_listing";
exports.getAllInoiceFolderFilesApiMethod = "GET";
exports.companyNotSameError =
  "Selected Company name from duplication check dropdown is not same as company name entered. Do you want to proceed?";
exports.getTicketDetailsApiEndpoint =
  "bx_block_catalogue/tickets/ticket_details";
exports.getTicketDetailsApiMethod = "GET";
exports.getstatusListAPIEndPoint = "bx_block_catalogue/tickets/status";
exports.getRoleListingAPIEndPoint = "account_block/take_action/role_listing";
exports.teamMembersAPIEndPoint = "account_block/take_action/team_listing";
exports.getMyQueryRepliesApiEndpoint = "account_block/accounts/employee_replies";
exports.getTicketHistoryApiEndpoint = "bx_block_catalogue/tickets/ticket_history";
exports.documentAccessTioolTip = "Document not available";
exports.phoneNumberPlaceholder = "Enter your phone number";
exports.countryNamePlaceholder = "Enter your country name";
exports.completeAddressPlaceholder = "Enter Complete Address";
exports.countryPlaceHolder = "Choose Department";
exports.noDataText = "No Data";
exports.designationPlaceholder = "Enter Designation";
exports.landlineNumberPlaceholder = "Enter Landline Number";
exports.alternateEmailPlaceholder = "Enter Alternate Email ID";
exports.rejectButtonText = "Reject";
exports.approveButtonText = "Approve";
exports.commentFieldLabel = "Comment";
exports.noOptionMessageText = "noOptionMessage";
exports.customerNameSelectPlaceholder = "Type Customer Name";
exports.noOptionMessage = "This customer is not available in the master.Kindly add the customer in the master and then proceed."
exports.firstNamePlaceholder = "Enter First Name";
exports.lastNamePlaceholder = "Enter Last Name";
exports.companyNamePlaceholder = "Enter Company Name";
exports.emailIdPlaceholder = "Enter Email ID";
exports.countryCodePlaceholder = "Enter Country Code";
exports.addNewbuttonLabel = "Add New";
exports.firstNameId = "firstName";
exports.lastNameId = "lastName";
exports.companyNameId = "companyName";
exports.emailLabelId = "emailLabel";
exports.countryCodeId = "countryCode";
exports.documentSelectionButtonText = "Document Selection";
exports.additionalFilesButtonText = "Additional Files";
exports.CancelButtonText = "Cancel";
exports.submitButtomText = "Submit";
exports.reOpenButtonText = "Reopen";
exports.ticketDetailsButtonText = "Ticket Details";
exports.submmittedBYEmpText = "Submitted by-Biocon team";
exports.ticketCreatedByText = "Ticket created by";
exports.changeStatusText = "Change Status"; 
exports.selectStatusText = "Select Status";
exports.ticketHistoryText = "Ticket History";
exports.commentHistoryText = "Comment History";
exports.documentSelectionText = "Document Selection";
exports.additionalFilesText = "Additional Files";
exports.cancleButtonText = "Cancel";
exports.submitButtonText = "Submit";
exports.noBucketsFoundText = "No Buckets Found";
exports.isViewAllText = "All Views";
exports.downloadText = "Download";
exports.noDocumentsText = "NO Documents";
exports.commonFolderText = "Common Folder";
exports.invoiceText = "Invoice";
exports.transferQueryToTeamText = "Transfer query to team";
exports.selectTransferQueryToText = "Select transfer query to";
exports.mandatoryFieldText = "This is mandatory field";
exports.teamMemberNameText = "Team member name";
exports.selectText = "Select";
exports.allText = "All";
exports.mandatoryText = "This is mandatory field";
exports.replyRules = "Only Numbers or Special Characters are not allowed";
exports.internalCommentsPlaceholder = "Internal Comments";
exports.commentPlaceholder = "Type your comment here...";
exports.internalCommentPlaceholder = "Type your internal comment here...";
exports.takeActionText = "Take action";
exports.actionButtonText = "Action";
exports.productNameText = "Prodduct Name";
exports.readMoreText = "Read More";
exports.requestRaisedOnText = "Request Raised On";
exports.submmittedByTeamText = "Submitted by-Biocon team";
exports.customerCommentsText = "Customer Comments";
exports.viewCommentsHistoryText = "View Comment History";
exports.coaText = "COA";
exports.searchDocPlaceholder ="Search document name";
exports.ViewText = "View";
exports.statusText = "status";
exports.replyToCustomerText= "ReplyTocustomer";
exports.phoneNumText = "phoneNum";
exports.searchFolderText = "searchFolder";
exports.ticketOpenSuccessMsg = "Ticket Reopened Successfully";
exports.bucketSearchApiEndPoint = "bx_block_catalogue/catalogues/product_bucket_listing_search";
exports.signupRequestConditionText = "Signup Request";
exports.sampleRequestConditionText = "Sample Request";
exports.ProductQueryConditionText = "Product Query";
exports.customTabsLabelText = "Tabs where each tab needs to be selected manually";
exports.GrantAccessText= "Grant access";
exports.TransferTheQueryText = "Transfer the query";
exports.ReplyToCustomerTabsText = "Reply to customer";
exports.CustomerExcellenceConditionText = "Customer Excellence";
exports.ConnectWithUsText = "Connect With Us";
exports.SetUpMeetingText = "Set Up Meeting";
exports.ClosedStatusText = "Closed";
exports.errorText = "Something Went Wrong";
exports.SingleDocumentText = "Document";
exports.InvoicesText = "Invoices";
exports.CommonFoldersText = "Common Folders";
exports.commonFolderText2 = "Common Folder";
exports.meetingRequestText = "Meeting Request";
exports.reopenTicketAPIEndPoint = "bx_block_catalogue/tickets/";
exports.reopenedText = "Open";
exports.markAsClosedText = "markAsClosed";
exports.yesButtonText = "Yes";
// @ts-nocheck
exports.emailRegexExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
exports.doNotExitMsg = "do not exist in customer master";
exports.landlineNumberText = "landlineNumber";
exports.phoneNumberText = "phoneNumber";
exports.BackButtonText = "Back";
// Customizable Area End
