import {
  apiCall,
  errorNotification,
  getPreviousPath,
  removePreviousPath,
  successNotification,
} from "../../../components/src/common";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { configJSON } from "./EmailAccountLoginController";

export interface Props {
  classes?: any;
  navigation: any;
  id: string;
  loading: boolean;
  history: any;
}

interface S {
  password: string;
  email: string;
  showPassword: boolean;
  loading: boolean;
  isConnected: boolean;
  error: any;
  isFormInvalid: boolean;
  checkedRememberMe: boolean;
  labelTitle: string;
  loginHeader: string;
  emailTitle: string;
  emailPlaceholder: string;
  passwordTitle: string;
  passwordPlaceholder: string;
  btnTxtLogin: string;
  loginSubHeading: string;
  forgotPasslabel: string;
  headerPermission: any[];
  imageLoading: boolean;
  emailLabelText: string;
  loginButtonText: string;
  AccountText: string;
  signUpText: string;
  welcomeText: string;
  startedText: string;
  loginImage: string;
}

interface SS {
  id: any;
}

export default class LoginScreenWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  LoginImagesApiCallId: string = "";
  HeaderOptionPermissionAPICallID: string = "";
  InvoicePermissionAPICallID: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  networkSubscribe: any;
  emailRef: any;
  passwordRef: any;

  /* START -------------------------------------------------------------- constructor */
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.doEmailLoginWeb = this.doEmailLoginWeb.bind(this);
    this.sendLoginFailMessage = this.sendLoginFailMessage.bind(this);
    this.getLoginImages = this.getLoginImages.bind(this);
    this.goToHome = this.goToHome.bind(this);
    this.setLoginImages = this.setLoginImages.bind(this);
    this.setLoginData = this.setLoginData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.setConditions = this.setConditions.bind(this);
    this.setInitialCredentials = this.setInitialCredentials.bind(this);
    this.setInvoiceOptionData = this.setInvoiceOptionData.bind(this);
    this.setRememberCredentials = this.setRememberCredentials.bind(this);
    this.setPermissionData = this.setPermissionData.bind(this);
    this.goTOSignup = this.goTOSignup.bind(this);
    this.CustomCheckedBoxProps.onChangeValue = this.CustomCheckedBoxProps.onChangeValue.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      showPassword: false,
      loading: false,
      isFormInvalid: false,
      isConnected: true,
      error: {},
      checkedRememberMe: false,
      labelTitle: configJSON.labelTitle,
      loginHeader: configJSON.loginHeader,
      emailTitle: configJSON.emailTitle,
      emailPlaceholder: configJSON.emailPlaceholder,
      loginImage: "",
      headerPermission: [],
      imageLoading: false,
      emailLabelText: configJSON.emailLabelText,
      loginButtonText: configJSON.loginButtonText,
      passwordTitle: configJSON.passwordTitle,
      passwordPlaceholder: configJSON.passwordPlaceholder,
      btnTxtLogin: configJSON.btnTxtLogin,
      loginSubHeading: configJSON.loginSubHeading,
      forgotPasslabel: configJSON.forgotPasslabel,
      AccountText: configJSON.AccountText,
      signUpText: configJSON.signUpText,
      welcomeText: configJSON.welcomeText,
      startedText: configJSON.startedText,
    };

    this.emailReg = new RegExp("");

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  /* END -------------------------------------------------------------- constructor */

  /* START -------------------------------------------------------------- componentDidMount */
  async componentDidMount() {
    super.componentDidMount();
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      this.props.navigation.navigate("LandingPage");
    } else {
      this.getLoginImages();
      this.checkForVerified();
    }
    if(window.location.pathname !== "/loginVerify") {
      const userCred = localStorage.getItem("userCredentials");
      if (userCred) {
        const parsedCredentials = JSON.parse(userCred);
        this.setState({
          email: parsedCredentials.userName,
          password: parsedCredentials.password,
          checkedRememberMe: true,
        });
        this.CustomCheckedBoxProps.isChecked = true;
      }
    }
  }
  /* END --------------------------------------------------------------  componentDidMount*/

  CustomCheckedBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckedBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckedBoxProps.isChecked });
      this.CustomCheckedBoxProps.isChecked = !this.CustomCheckedBoxProps.isChecked;
    },
  };

  /* START --------------------------------------------------------------  API's responce area*/

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      this.setInitialCredentials(message);
      } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.validationApiCallId && responseJson !== undefined) {
          this.validationAPIResponseData(responseJson);
        }

        if (apiRequestCallId === this.apiEmailLoginCallId) {
          this.setState({ loading: false });
          this.loginApiResponse(responseJson);
          this.parseApiCatchErrorResponse(errorResponse);
        }

        if (apiRequestCallId === this.LoginImagesApiCallId) {
          this.setLoginImages(responseJson)
        }

        if (apiRequestCallId === this.HeaderOptionPermissionAPICallID) {
          this.setOptionsPermissions(responseJson)
        }
      }
    }
  }

  openInfoPage() {
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  setInvoiceOptionData = (responce: any) => {
    localStorage.setItem("customer_invoice", responce.visible)
  }

  /* END --------------------------------------------------------------  API's responce area*/

  /* START -------------------------------------------------------------- Email Login in Web Section */
  validationAPIResponseData = (responseJson: any) => {
    if (responseJson.data && responseJson.data.length !== 0) {
      let regexData = responseJson.data[0];
      if (regexData && regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  }

  loginApiResponse = (responseJson: any) => {
    if (responseJson && responseJson.meta && responseJson.meta.token || responseJson?.meta?.refresh_token) {
      this.afterSuccessLogin(responseJson);
    } else if (responseJson.error) {
      errorNotification(responseJson.error);
    } else if (responseJson.errors) {
      errorNotification(responseJson.errors[0].message);
    } else {
      this.setErrorResponse(responseJson)
    }
  }

  setErrorResponse = (responseJson: any) => {
    if (responseJson?.errors) {
      responseJson?.errors.map((obj: any) => {
        for (const key in obj) {
          errorNotification(`${obj[key]}`);
        }
      });
      this.sendLoginFailMessage();
    }
  }

  setLoginData = (responseJson: any) => {
    if (responseJson.data.attributes.auto_generated_password) {
      localStorage.setItem("customer_profile_id", responseJson.data.id)
      this.props.navigation.navigate("ResetPassword");
    } else if (responseJson.data.attributes.is_customer === "True") {
      localStorage.setItem("isEmployee", "false");
      if (getPreviousPath() && getPreviousPath() !== "/") {
        this.saveLoggedInUserData(responseJson);
        this.props.navigation.goBack();
        removePreviousPath();
        this.sendLoginSuccessMessage();
        successNotification(responseJson.meta.message);
        return;
      } else {
        this.saveLoggedInUserData(responseJson);
        this.sendLoginSuccessMessage();
        successNotification(responseJson.meta.message);
        this.props.navigation.navigate("Home");
        return;
      }
    } else {
      localStorage.setItem("isEmployee", "true");
      if (getPreviousPath() && getPreviousPath() !== "/") {
        this.props.navigation.goBack();
        removePreviousPath();
        this.saveLoggedInUserData(responseJson);
        this.sendLoginSuccessMessage();
        successNotification(responseJson.meta.message);
        return;
      } else {
        this.saveLoggedInUserData(responseJson);
        this.sendLoginSuccessMessage();
        successNotification(responseJson.meta.message);
        this.props.navigation.navigate("Home");
        return;
      }
    }
  }

  setInitialCredentials = (message: any) => {
    const userName = message.getData(getName(MessageEnum.LoginUserName));
    const password = message.getData(getName(MessageEnum.LoginPassword));
    if (!userName && !password) {
      this.setState({email: userName,password: password});
    }
  }

  afterSuccessLogin = (responseJson: any) => {
    runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    this.setRememberCredentials();
    if (responseJson.meta.refresh_token) {
      localStorage.setItem("refreshToken", responseJson.meta.refresh_token)
    }
    if (responseJson.data.attributes) {
      this.setLoginData(responseJson);
    }
  }

  setRememberCredentials = () => {
    if (this.state.checkedRememberMe) {
      localStorage.setItem("userCredentials",JSON.stringify({userName: this.state.email,password: this.state.password}));
    } else {
      localStorage.removeItem("userCredentials");
    }
  }

  setLoginImages = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        loginImage: responseJson?.data?.attributes?.media
      })
      setTimeout(()=> {
        this.setState({imageLoading: false})
      }, 1000)
    } else {
      this.setState({imageLoading: false})
      errorNotification('No Image found');
    }
  }

  doEmailLoginWeb() {
    this.employeeHeaderOptionPermission();
  }

  proceedToLogin = () => {
    if (this.state.email === null || this.state.email.length === 0 || !this.emailReg.test(this.state.email)) {
      errorNotification(configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      errorNotification(configJSON.errorPasswordNotValid);
      return false;
    }

    this.setState({loading: true});

    //TODO: Make Api Call Here for login
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const requestBody = {
      email: this.state.email,
      password: this.state.password,
    };
    const requestMessage = apiCall({
      header: header,
      httpBody: requestBody,
      httpMethod: configJSON.loginAPiMethod,
      url: configJSON.loginAPiEndPoint,
    });

    this.apiEmailLoginCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  /* END -------------------------------------------------------------- Email Login in Web Section */

  /* START -------------------------------------------------------------- sendLoginFailMessage */
  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }
  /* END -------------------------------------------------------------- sendLoginFailMessage */

  /* START -------------------------------------------------------------- sendLoginSuccesMessage */
  sendLoginSuccessMessage() {
    const msg = new Message(getName(MessageEnum.LoginSuccessMessage));
    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }
  /* END -------------------------------------------------------------- sendLoginSuccesMessage */

  /* START -------------------------------------------------------------- saveLoggedInUserData */
  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );

      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      localStorage.setItem("userInfo", JSON.stringify(responseJson.data));
      this.setPermissionData(responseJson.data);
      this.send(msg);
    }
  }
  /* END -------------------------------------------------------------- saveLoggedInUserData */

  /* START -------------------------------------------------------------- callGetValidationApi */

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  goToHome = () => {
    this.props.navigation.navigate("Home");
  };

  getLoginImages() {
    this.setState({imageLoading: true})
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.LoginImagesApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginImagesAPIEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  employeeHeaderOptionPermission = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.HeaderOptionPermissionAPICallID = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.EmployeeHeaderOptionPermissionAPIEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  setOptionsPermissions = (responseJson: any) => {
    this.setState({
      headerPermission: responseJson.data
    }, () => this.proceedToLogin()
    )
  }

  setPermissionData = (userDetails: any) => {
    localStorage.setItem("Customer_Master", 'false');
    localStorage.setItem("Contact_Master", 'false');
    localStorage.setItem("View_My_Tickets", 'false');
    localStorage.setItem("Create_New_Ticket", 'false');
    let permission = {
      can_create: false,
      can_read: false,
      can_update: false,
      can_delete: false
    }
    localStorage.setItem("customr_master_permission", JSON.stringify(permission))
    localStorage.setItem("contact_master_permission", JSON.stringify(permission))
    this.state.headerPermission?.forEach((element: any) => {
      element.attributes.sub_menu.data.forEach((element2: any) => {
        element2.attributes.roles?.data?.forEach((roles: any) => {
          if(roles.attributes.role_id === userDetails.attributes.role_id) {
                this.setConditions(element2, roles)
              }
          });
      });
  });
  }

  setConditions = (element2: any, roles: any) => {
    if (element2.attributes.name === 'Customer Master') {
      let permission = {
        can_create: roles.attributes.can_create,
        can_read: roles.attributes.can_read,
        can_update: roles.attributes.can_update,
        can_delete: roles.attributes.can_delete
      }
      localStorage.setItem("Customer_Master", JSON.stringify(roles.attributes.can_read))
      localStorage.setItem("customr_master_permission", JSON.stringify(permission))
    }
    if (element2.attributes.name === 'Contact Master') {
      localStorage.setItem("Contact_Master", JSON.stringify(roles.attributes.can_read))
      let permission = {
        can_create: roles.attributes.can_create,
        can_read: roles.attributes.can_read,
        can_update: roles.attributes.can_update,
        can_delete: roles.attributes.can_delete
      }
      localStorage.setItem("contact_master_permission", JSON.stringify(permission))
    }
    if (element2.attributes.name === 'View My Tickets') {
      let permission = {
        can_create: roles.attributes.can_create,
        can_read: roles.attributes.can_read,
        can_update: roles.attributes.can_update,
        can_delete: roles.attributes.can_delete
      }
      localStorage.setItem("View_My_Tickets", JSON.stringify(permission))
    }
    if (element2.attributes.name === 'Create New Ticket') {
      let permission = {
        can_create: roles.attributes.can_create,
        can_read: roles.attributes.can_read,
        can_update: roles.attributes.can_update,
        can_delete: roles.attributes.can_delete
      }
      localStorage.setItem("Create_New_Ticket", JSON.stringify(permission))
    }
}

  checkForVerified = () => {
    if (window.location.pathname === "/loginVerify") {
      successNotification("Your email address has been verified successfully.")
    }
  }

  handleChange = (prop: any) => (event: any) => {
    this.setState({ ...this.state, [prop]: event.target.value });
  };

  goTOSignup = () => {
    window.location.replace("Signup");
  }
  /* END -------------------------------------------------------------- callGetValidationApi */
}
