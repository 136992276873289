import React, { useEffect, useRef, useState } from 'react'
import { DateRange } from 'react-date-range'
import { addDays } from 'date-fns'
import CloseIcon from '@material-ui/icons/Close';
import DateRangeIcon from '@material-ui/icons/DateRange';
import moment from "moment";
import { FilterIcon } from "./assets";

interface Iprops {
    setSelectedDate?: any;
    startDate?: any;
    endDate?: any;
    isFilterSelected?: boolean;
}

const DateRangTicketList = (props: Iprops) => {
    const { setSelectedDate, startDate, endDate, isFilterSelected } = props;
    const date = [
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 0),
            key: 'selection'
        }
    ]
    const [range, setRange] = useState(date)
    const [open, setOpen] = useState(true)
    const [selectDateValue, setSelectDateValue] = useState<any>(moment(startDate).format("DD MMM YYYY"))
    const refOne = useRef<HTMLDivElement>(null)
    const [openPopUp, setOpenPopUp] = useState(false)
    const [filterSelected, setFilterSelected] = useState(false);

    React.useEffect(() => {
        document.addEventListener("keydown", hideOnEscape, true)
        document.addEventListener("click", hideOnClickOutside, true)
        if (new Date(startDate).getTime() !== new Date(endDate).getTime()) {
            let selectedDate = `${moment(startDate).format("DD MMM YYYY")} - ${moment(endDate).format("DD MMM YYYY")}`
            setSelectDateValue(selectedDate);
            setFilterSelected(true);
        }
        if (!filterSelected && moment(startDate).format("DD-MM-YYYY") === '01-01-2022') {
            let selectedDate = `${moment().format("DD MMM YYYY")}`
            setSelectDateValue(selectedDate);
            setFilterSelected(false);
        } else if (!filterSelected) {
            let selectedDate = `${moment(startDate).format("DD MMM YYYY")}`
            setSelectDateValue(selectedDate);
            setFilterSelected(false);
        }
        if (isFilterSelected) {
            let selectedDate = `${moment().format("DD MMM YYYY")}`
            setSelectDateValue(selectedDate);
            setFilterSelected(false);
            setRange(date)
        }
    }, [startDate, endDate, filterSelected, isFilterSelected])

    const hideOnEscape = (e: any) => {
        if (e.key === "Escape") {
            setOpen(false)
        }
    }

    const hideOnClickOutside = (e: any) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            setOpen(false)
        }
    }
    const handleClickOkDate = async () => {
        setRange([...range])
        setOpenPopUp(false);
        setSelectedDate(range[0].startDate, range[0].endDate);
        if (new Date(range[0].startDate).getTime() !== new Date(range[0].endDate).getTime()) {
            setFilterSelected(true);
        } else {
            setFilterSelected(false);
        }
    }
    const handleClickResetDate = () => {
        setRange(date);
        setFilterSelected(false);
        setSelectedDate(moment('01-01-2022'), moment());
        setSelectDateValue(moment().format("DD MMM YYYY"));
    }
    const handleClickCloseCalendar = () => {
        setOpenPopUp(false)
    };
    return (
        <>
            <div style={{ ...webStyle.inputField, display: 'flex', alignItems: 'center' }}>
                {selectDateValue}
                {
                    !filterSelected ?
                        <DateRangeIcon style={webStyle.icon} onClick={() => setOpenPopUp(true)} data-testid="daterangeIcon"/>
                        :
                        <img data-testid="imgicon" src={FilterIcon} height={18} width={16} style={{ marginLeft: '5px' }} onClick={() => setOpenPopUp(true)} />
                }
            </div>
            {openPopUp && (<div style={webStyle.dateRangPopUp} ref={refOne}>
                <span style={webStyle.close}>
                    <CloseIcon onClick={handleClickCloseCalendar} style={webStyle.closeIcon} data-testid="closebtn" ></CloseIcon>
                </span>
                <DateRange
                    data-testid="daterange"
                    onChange={(item: any) => {
                        setRange([item.selection])
                        const startDate = new Date((item.selection.startDate).getTime() - (item.selection.startDate).getTimezoneOffset() * 60000).toISOString()
                        const endDate = new Date((item.selection.endDate).getTime() - (item.selection.endDate).getTimezoneOffset() * 60000).toISOString()
                        localStorage.setItem('ticketRangeValue', JSON.stringify({ startDate, key: "selection", endDate }))
                    }}
                    moveRangeOnFirstSelection={false}
                    editableDateInputs={true}
                    ranges={range}
                />
                <div style={{ display: "flex", justifyContent: 'space-evenly', padding: '0px 10px 10px 0px ' }}>
                    <button onClick={handleClickOkDate} style={webStyle.cardButton} data-testid="savebtn" ><span>Save</span></button>
                    <button onClick={handleClickResetDate} style={webStyle.cardButton} data-testid="resetbtn" ><span>Reset</span></button>
                </div>
            </div>)}

        </>
    )
}
const webStyle: any = {
    close: {
        display: "flex",
        justifyContent: "end",
        transform: 'translate(-10px, 19px)',
        cursor: 'pointer',
    },
    inputField: {
        height: '40px',
        width: 'auto',
        backgroundColor: '#fff',
        borderRadius: '14px',
        border: '1px solid #dae0f1',
        fontSize: '14px',
        color: '#043c74',
        letterSpacing: '1px',
        fontFamily: 'SFProDisplay',
        paddingLeft: '10px',
        paddingRight: '10px',
    },
    dateRangPopUp: {
        position: "fixed",
        backgroundColor: '#ffffff',
        zIndex: '1',
        right: '35px',
        borderRadius: '8px',
        boxShadow: '20px 20px 40px 0 rgba(0, 0, 0, 0.1)'
    },
    closeIcon: {
        borderRadius: '50%',
        height: '24px',
        width: '24px',
        backgroundColor: '#dae0f1',
        marginBottom: '10px',
        color: '#043c74',
    },
    cardButton: {
        borderRadius: '8px',
        height: "35px",
        width: '100px',
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        boxShadow: 'none',
        backgroundColor: 'rgb(3 60 116)',
        color: 'rgb(198, 228, 247)',
        fontSize: '14px',
        cursor: 'pointer',
    },
    icon: {
        color: '#003d78',
        cursor: 'pointer',
        marginLeft: '5px'
    }

}


export default DateRangTicketList