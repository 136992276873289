import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import CustomTextItem from "./CustomTextItem";
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../blocks/adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../blocks/adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../blocks/adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../blocks/adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../blocks/adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../blocks/adapters/src/SplashScreenAdapter";
import EmailAccountLogInAdapter from "../../blocks/adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../blocks/adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../blocks/adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../blocks/adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../blocks/adapters/src/OtpToNewPasswordAdapter";

//Assembler generated adapters start
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});

interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface S { }

interface SS { }

class HomeScreen extends BlockComponent<Props, S, SS> {

  static instance: HomeScreen;

  constructor(props: Props) {
    super(props);
    HomeScreen.instance = this;
  }

  render() {
    const { navigation } = this.props;
    // const _this = this;

    return (
      // @ts-ignore
      <SafeAreaView>
       {/* @ts-ignore */}
        <ScrollView contentContainerStyle={styles.scrollView} bounces={false}>
       {/* @ts-ignore */}
          <View style={styles.container}>
       {/* @ts-ignore */}
            <View style={styles.header}>
       {/* @ts-ignore */}
              <Text style={styles.welcome}>
                Welcome to BioconPhase1withoutSAP!
              </Text>
            </View>

       {/* @ts-ignore */}
            <Text style={styles.instructions}>{instructions}</Text>
       {/* @ts-ignore */}
            <Text style={styles.header}>DEFAULT BLOCKS</Text>
            <CustomTextItem
              content={'InfoPage'}
              onPress={() => navigation.navigate("InfoPage")}
            />
            <CustomTextItem
              content={'Alert'}
              onPress={() => this.showAlert("Example", "This happened")}
            />
            <CustomTextItem content={'Catalogue'} onPress={() => navigation.navigate("Catalogue")} />
            <CustomTextItem content={'core'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'EmailAccountLoginBlock'} onPress={() => navigation.navigate("EmailAccountLoginBlock")} />
            <CustomTextItem content={'EmailAccountRegistration'} onPress={() => navigation.navigate("EmailAccountRegistration")} />
            <CustomTextItem content={'CountryCodeSelector'} onPress={() => navigation.navigate("CountryCodeSelector")} />
            <CustomTextItem content={'ForgotPassword'} onPress={() => navigation.navigate("ForgotPassword")} />
            <CustomTextItem content={'OTPInputAuth'} onPress={() => navigation.navigate("OTPInputAuth")} />
            <CustomTextItem content={'Sorting'} onPress={() => navigation.navigate("Sorting")} />
            <CustomTextItem content={'Filteritems'} onPress={() => navigation.navigate("Filteritems")} />
            <CustomTextItem content={'Categoriessubcategories'} onPress={() => navigation.navigate("Categoriessubcategories")} />
            <CustomTextItem content={'LandingPage'} onPress={() => navigation.navigate("LandingPage")} />
            <CustomTextItem content={'AuditTrail'} onPress={() => navigation.navigate("AuditTrail")} />
            <CustomTextItem content={'DynamicContent'} onPress={() => navigation.navigate("DynamicContent")} />
            <CustomTextItem content={'TwofactorAuthentication'} onPress={() => navigation.navigate("TwofactorAuthentication")} />
            <CustomTextItem content={'Search'} onPress={() => navigation.navigate("Search")} />
            <CustomTextItem content={'SecurityPolicy'} onPress={() => navigation.navigate("SecurityPolicy")} />
            <CustomTextItem content={'RequestManagement'} onPress={() => navigation.navigate("RequestManagement")} />
            <CustomTextItem content={'ReviewAndApproval'} onPress={() => navigation.navigate("ReviewAndApproval")} />
            <CustomTextItem content={'AdminConsole3'} onPress={() => navigation.navigate("AdminConsole3")} />
            <CustomTextItem content={'BiospaceSingleSignonForBioconEmployees3'} onPress={() => navigation.navigate("BiospaceSingleSignonForBioconEmployees3")} />
            <CustomTextItem content={'RolesPermissions2'} onPress={() => navigation.navigate("RolesPermissions2")} />
            <CustomTextItem content={'DataStorage'} onPress={() => navigation.navigate("DataStorage")} />
            <CustomTextItem content={'PasswordProtectedPages'} onPress={() => navigation.navigate("PasswordProtectedPages")} />
            <CustomTextItem content={'DocumentDistribution'} onPress={() => navigation.navigate("DocumentDistribution")} />
            <CustomTextItem content={'EmailNotifications'} onPress={() => navigation.navigate("EmailNotifications")} />
            <CustomTextItem content={'CustomisableUserProfiles'} onPress={() => navigation.navigate("CustomisableUserProfiles")} />
            <CustomTextItem content={'DownloadPermissions'} onPress={() => navigation.navigate("DownloadPermissions")} />

          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontSize: 16,
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
  }
});
// Customizable Area End
export default HomeScreen;