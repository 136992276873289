import React, { useEffect, useState } from "react";
import {
  Typography, Container, Box, Grid, Dialog, DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ILandigPageTeamDetails } from "./LandingPageController";
import { withStyles } from "@material-ui/core/styles";
import { Markup } from 'interweave';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { getHighlightedText } from "../../../components/src/common";
import CloseIcon from '@material-ui/icons/Close';

interface IProps {
  teams: ILandigPageTeamDetails[] | null;
  searchTerm: string;
}

function useOnScreen(ref: any) {

  const [isIntersecting, setIntersecting] = useState(false)

  const observer = new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting)
  )

  React.useEffect(() => {
    observer.observe(ref.current)
    // Remove the observer as soon as the component is unmounted
    return () => { observer.disconnect() }
  }, [])

  return isIntersecting
}


const KnowOurTeam = ({ teams, searchTerm }: IProps) => {
  const [innerWidth, setWidth] = useState(window.innerWidth);
  const [detailsModal, setDetailsModal] = useState(false);
  const [detailsData, setDetailsData] = useState<any>({});
  const sliderRef: any = React.createRef();
  const divRef: any = React.createRef();

  const isVisible = useOnScreen(divRef);

  useEffect(() => {
    if (isVisible) {
      sliderRef.current && sliderRef.current.slickPlay();
    } else {
      sliderRef.current && sliderRef.current.slickPause();
    }
  }, [isVisible]);

  useEffect(() => {
    if (teams !== null) {
      if (teams.length !== undefined) {
        if (teams?.length < 4) {
          slides = teams?.length
        } else {
          window.addEventListener("resize", updateWidth);
          return () => window.removeEventListener("resize", updateWidth);
        }
      }
    }
  });
  const updateWidth = () => {
    setWidth(window.innerWidth);
  };
  const openDetailsDrawer = (data: any) => {
    setDetailsData(data);
    setDetailsModal(true);
  }
  let slides = 0;
  if (teams) {
    if (teams?.length > 4) {
      if (innerWidth < 2100 && innerWidth > 1830) {
        slides = 6;
      } else if (innerWidth < 1900 && innerWidth > 1490) {
        slides = 5;
      } else if (innerWidth < 1500 && innerWidth > 1190) {
        slides = 5;
      } else if (innerWidth < 1200 && innerWidth > 890) {
        slides = 3;
      } else if (innerWidth < 900 && innerWidth > 610) {
        slides = 2;
      } else if (innerWidth < 620) {
        slides = 1;
      }
    } else {
      slides = teams?.length
    }
  }


  const teamSliderSettings = {
    className: "updateSlider",
    slidesToShow: slides,
    slidesToScroll: 1,
    speed: 800,
    autoplaySpeed: 4000,
    dots: false,
    infinite: true,
    touchMove: false,
    swipe: false,
    pauseOnHover: true
  };
  return (
    <MainGrid>
      {/* <Container style={{ padding: 0 }}>  */}
      <Typography component="div" style={webStyle.listName}>
        Meet Our Team
      </Typography>
      <Box style={{ padding: "0px 42px 0px 42px" }} className={slides < 5 ? "konw_our_team" : ""}>
        <div onMouseEnter={() => sliderRef.current && sliderRef.current.slickPause()} onMouseLeave={() => sliderRef.current && sliderRef.current.slickPlay()} ref={divRef}>
          <Slider ref={sliderRef} key='teamslider' autoplay={false} {...teamSliderSettings}>
            {teams &&
              teams.map((item: ILandigPageTeamDetails) => (
                <Grid key={item.id} className="team-Container">
                  <Grid style={webStyle.personContainer}>

                    <Grid container>
                      <img
                        src={item.attributes.image}
                        className="team-img"
                      />
                    </Grid>
                    <Typography style={webStyle.personName}>
                      {getHighlightedText(item.attributes.full_name, searchTerm)}
                    </Typography>
                    <Typography style={webStyle.persondesignation}>
                      {getHighlightedText(item.attributes.designation, searchTerm)}
                    </Typography>
                    <ArrowRightAltIcon
                      style={{ ...webStyle.rightArrowIcon, float: 'right' }}
                      onClick={() => openDetailsDrawer(item)}
                    >
                    </ArrowRightAltIcon>
                    {/* <Typography
                        className="person-description"
                        style={webStyle.description}
                      >
                        <Markup content={item.attributes.description} />
                      </Typography> */}
                  </Grid>
                </Grid>
              ))}
          </Slider>
        </div>
      </Box>
      {
        detailsModal && (
          <TeamDialog
            open={detailsModal}
            onClose={() => setDetailsModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div
              style={{
                display: 'flex',
                paddingRight: '0px',
                maxHeight: '255px'
              }}
            >
              <img src={detailsData.attributes.image}
                style={webStyle.detailImg}
              />
              <IconBox>
                <CloseIcon
                  onClick={() => setDetailsModal(false)}
                  style={{ fill: '#043c74', margin: '0 0px', fontSize: '24px' }}
                >
                </CloseIcon>
              </IconBox>
            </div>
            <Typography
              style={webStyle.detailHeading}
              align={'center'}
            >
              {detailsData.attributes.full_name}
            </Typography>
            <Typography
              style={webStyle.detailDesignation}
              align={'center'}
            >
              {detailsData.attributes.designation}
            </Typography>
            <Markup
              className="detailsDescription"
              content={detailsData.attributes.description}
            />
          </TeamDialog>
        )
      }
      {/* </Container> */}
    </MainGrid>
  );
};

const webStyle = {
  description: {
    color: "#435466",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "16px",
    marginTop: "10px",
    margin: '10px 2% 0px 2%'
  },
  listName: {
    fontSize: "22px",
    fontWeight: 600,
    color: "rgba(4, 60, 116, 1)",
    fontFamily: "SFProDisplay",
    paddingLeft: "40px",
    // paddingBottom: "25px"
  },
  personContainer: {
    // minWidth: '320px',
    maxWidth: "250px",
    minHeight: "330px",
    // padding: '0 0 18.5px',
    boxShadow: '0px 2px 36px 0px rgba(0, 0, 0, 0.12)',
    margin: '35px 0px 0px 13px',
    borderRadius: "8px",
    backgroundColor: '#fff',
  },
  personName: {
    margin: '20px 16px 0px',
    fontFamily: 'SFProDisplay',
    fontWeight: 600,
    fontSize: '18px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#043c74',
    minHeight: '29px',
  },
  persondesignation: {
    minHeight: '42px',
    margin: '0px 16px 3.4%',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '14px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#043c74',
  },
  rightArrowIcon: {
    height: '6.1%',
    margin: '0px 18.5px 0px 0px',
    fill: '#043c74',
    cursor: 'hand',
  },
  detailImg: {
    width: '31.3%',
    maxHeight: '205px',
    margin: '0px 25.9% 9px 34.4%',
  },
  detailHeading: {
    maxWidth: '70.6%',
    margin: '0px 14.7% 0px 14.7%',
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: 'normal',
    color: '#043c74',
  },
  detailDesignation: {
    maxWidth: '70.6%',
    margin: '12px 14.7% 16px 14.7%',
    fontFamily: 'SFProDisplay',
    fontSize: '18px',
    fontWeight: 500,
    color: '#043c74',
  }
};
export default KnowOurTeam;

const MainGrid = withStyles({
  root: {
    padding: "20px 30px 10px 35px",
    background: "#F8FDFF",
    marginTop: "10px",
    "@media (max-width: 700px)": {
      padding: "16px"
    }
  }
})(Container);

const TeamDialog = withStyles({
  root: {
    "& .MuiDialog-paperScrollPaper": {
      maxWidth: '444px',
      padding: '24px 24px 28px',
      borderRadius: '11px',
      backgroundColor: '#fff',
    },
    "& .MuiDialogContent-root": {
      paddingLeft: '0px'
    },
    "& .MuiDialogContent-root:first-child": {
      paddingTop: '0px',
    },
    "& .MuiDialogActions-root": {
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px',
      paddingBottom: '8px',
    },
    "& .WithStyles\(ForwardRef\(Button\)\)-root-10": {
      "@media (maxWidth: 600px)": {
        width: 300
      }
    }
  }
})(Dialog);

const IconBox = withStyles({
  root: {
    width: '20px',
    height: '20px',
    margin: '0px',
    padding: '6px 7px',
    backgroundColor: '#dae0f1',
    borderRadius: '25px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
})(Box);
