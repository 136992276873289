import React from "react";
import SignupWebController, { Props } from "./SignupController.web";

// Customizable Area Start
import {
  Grid,
  Typography,
  TextField,
  InputLabel,
  Button,
  InputAdornment,
  IconButton,
  Checkbox,
  FormControlLabel,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Divider,
} from "@material-ui/core";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { LoginImage, BoiConLogo, Background, ConnectLogo } from "./assets";
import { withStyles } from "@material-ui/core/styles";
import { Dimensions } from "react-native";
import CloseIcon from '@material-ui/icons/Close';
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector";
import { handlePrivacyPolicyRedirection, handleTermsOfUseRedirection } from "../../../components/src/common";
// Customizable Area End

let screenHeight = Dimensions.get("window").height;
export default class SignupWeb extends SignupWebController {
  constructor(props: Props) {
    super(props);
  }

  TermsPopUp() {
    return (
      <TermsDialog
        open={this.state.termsPopUp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <IconBox onClick={this.handleTermsConditionClick}>
            <CloseIcon style={{ fill: '#1A3B71', cursor: 'hand' }}>
            </CloseIcon>
          </IconBox>
        </DialogTitle>
        <DialogContent>
          <Typography style={webStyle.termsPopUpText}>
            By registering on this website, you are allowing Biocon Limited to use all information
            provided by you, specifically for the purposes of responding to your queries; sending
            communications pertaining orders placed by you on Biocon Limited and its group companies;
            sharing information about our events and webcasts; and sending updates, security alerts and
            administrative messages. Your use of this website and sharing of your personal information
            shall at all times be subject to the Privacy Policy and the Terms of Use.
            <br />
            <br />
            Please provide your confirmation by clicking on the “Accept” button to proceed further with
            registration process
          </Typography>
        </DialogContent>
        <DialogActions>
          <AcceptButton onClick={this.setTermsandCondition}>
            Accept
          </AcceptButton>
        </DialogActions>

      </TermsDialog>
    )
  }

  ProfileConfirmationPopup() {
    return (
      <TermsDialog
        open={this.state.isConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <IconBox onClick={this.onConfirmationClick}>
            <CloseIcon style={{ fill: '#1A3B71', cursor: 'hand' }}>
            </CloseIcon>
          </IconBox>
        </DialogTitle>
        <DialogContent>
          <Typography style={webStyle.termsPopUpText}>
            Your profile is under review. Our team will get back to you on your email
          </Typography>
        </DialogContent>
        <DialogActions>
          <AcceptButton onClick={this.onConfirmationClick}>
            Ok
          </AcceptButton>
        </DialogActions>

      </TermsDialog>
    )
  }




  setCompanyDetails() {
    return (
      <>
        <InputLabel htmlFor="companyname" style={webStyle.inputLabel}>
          {this.state.labelCompanyName}*
        </InputLabel>
        <InputField
          id="companyname"
          variant="outlined"
          required
          placeholder="Enter your company name"
          value={this.state.companyName}
          onChange={this.handleChange("companyName").bind(this)}
          fullWidth
        />
        <InputLabel htmlFor="phoneNum" style={webStyle.inputLabel}>
          {this.state.phoneNum}*
        </InputLabel>
        <Grid container spacing={2} style={{ paddingTop: "5px" }}>
          <CustomGrid item md={6} lg={6} sm={12} xs={12}>
            <CountryCodeSelector
              navigation={""}
              style={{ positin: 'inherite' }}
              id="countryCode"
              disable={false}
              allowPropChange={false}
              onChange={this.handlePhoneCountryCode}
              value={this.state.phoneCountryCode}
              placeHolder="Enter Country Code"
            />
          </CustomGrid>
          <Grid item lg={6} md={6} sm={12} xs={12} style={{ paddingTop: 3 }}>
            <InputField
              id="phoneNumber"
              variant="outlined"
              inputMode="numeric"
              inputProps={{
                maxLength: 14,
              }}
              type="tel"
              value={this.state.phone}
              onChange={this.handleChange("phone").bind(this)}
              required
              placeholder="Enter your phone number"
              fullWidth
            />
          </Grid>
        </Grid>
        <InputLabel htmlFor="country" style={webStyle.inputLabel}>
          {this.state.labelCountry}
        </InputLabel>
        <InputField
          id="country"
          disabled
          variant="outlined"
          value={this.state.country}
          onChange={this.handleChange("country").bind(this)}
          required
          placeholder="Enter your country name"
          fullWidth
        />
        <Box display={"flex"}>
          <CheckBoxLabel
            control={
              <Checkbox
                color="default"
                disableRipple
                checked={this.state.isTermsCondition}
                onChange={this.handleTermsConditionClick}
                checkedIcon={<span style={webStyle.checkedIcon} />}
                icon={<span style={webStyle.icon} />}
                inputProps={{ "aria-label": "decorative checkbox" }}
              />
            }
            label={""}
          />
          <Typography style={webStyle.termsText} component={"span"}>
            {this.state.privacyPolicyLabel}
            <Typography
              onClick={handlePrivacyPolicyRedirection}
              component="span"
              style={webStyle.privacyText}
            >
              {" "}
              {this.state.privacyPolicyText}
            </Typography>{" "}
            and
            <Typography
              onClick={handleTermsOfUseRedirection}
              component="span"
              style={webStyle.privacyText}
            >
              {" "}
              {this.state.termslabelText}
            </Typography>
            <Typography component="span"> {this.state.termsLabel}</Typography>
          </Typography>
          {
            this.state.termsPopUp && (
              this.TermsPopUp()
            )
          }{
            this.state.isConfirmation &&
            this.ProfileConfirmationPopup()
          }
        </Box>
      </>
    );
  }

  render() {
    const { navigation } = this.props;
    return (
      <>
        <Grid container style={{ overflow: "auto" }}>
          <LeftGrid item lg={5} md={5} sm={5} xs={12}
            style={{
              position: 'relative',
            }}
          >
            <img src={LoginImage}
              style={this.state.IscompanyDetails ? webStyle.loginImage2 : webStyle.loginImage}
              // style={webStyle.loginImage} 
              className="img" />
            <WelcomeCard>
              <Typography style={webStyle.welcomeText}>
                {this.state.welcomeText}
              </Typography>
              <Typography style={webStyle.startedText}>
                {this.state.startedText}
              </Typography>
              <Typography style={webStyle.serviseText}>
                {this.state.serviseText}
              </Typography>
            </WelcomeCard>
          </LeftGrid>
          <RightBox item lg={6} md={6} sm={6} xs={12}
            style={this.state.IscompanyDetails ? {} : webStyle.rightPart}>
            <RightGrid item lg={10} md={10} sm={10} xs={12}>
              {this.state.IscompanyDetails && (
                <Box style={{ marginTop: '0px' }} display="flex" justifyContent={"end"}>
                  <Typography
                    style={webStyle.backOption}
                    onClick={this.handleBackClick}
                  >
                    Back
                  </Typography>
                </Box>
              )}
              <Grid container>
                <img
                  src={BoiConLogo}
                  style={webStyle.LogoImage}
                />
                <Divider orientation="vertical" style={webStyle.divider} />
                <img
                  src={ConnectLogo}
                  style={webStyle.connectLogo}
                  className="blendLogo"
                />
              </Grid>
              <Typography style={webStyle.Header} component="h2">
                {this.state.labelTitle}
              </Typography>
              <Typography
                style={this.state.IscompanyDetails ?
                  webStyle.subHeader2
                  : webStyle.subHeader
                }
              >
                {this.state.SubHeading}
              </Typography>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  this.state.IscompanyDetails
                    ? this.createAccount()
                    : this.handleProceedClick();
                }}
                style={{ marginBottom: '2px' }}
              >
                {this.state.IscompanyDetails ? (
                  <>
                    {this.setCompanyDetails()}
                  </>
                ) : (
                  <>
                    <InputLabel htmlFor="firstName" style={webStyle.inputLabel}>
                      {this.state.labelFirstName}*
                    </InputLabel>
                    <InputField
                      id="firstName"
                      variant="outlined"
                      type="text"
                      inputProps={{ pattern: "[A-Za-z]{1,32}" }}
                      required
                      placeholder="Enter your first name"
                      value={this.state.firstName}
                      onChange={this.handleChange("firstName").bind(this)}
                      fullWidth
                    />

                    <InputLabel htmlFor="lastName" style={webStyle.inputLabel}>
                      {this.state.labelLastName}*
                    </InputLabel>
                    <InputField
                      id="lastName"
                      variant="outlined"
                      value={this.state.lastName}
                      onChange={this.handleChange("lastName").bind(this)}
                      required
                      placeholder="Enter your last name"
                      fullWidth
                    />
                    <InputLabel htmlFor="emailInput" style={webStyle.inputLabel}>
                      {this.state.labelEmail}*
                    </InputLabel>
                    <InputField
                      id="emailInput"
                      type="mail"
                      variant="outlined"
                      onChange={this.handleChange("email").bind(this)}
                      value={this.state.email}
                      required
                      placeholder="Enter your email id"
                      fullWidth
                    />
                    <InputLabel htmlFor="createPass" style={webStyle.inputLabel}>
                      {this.state.labelPassword}*
                    </InputLabel>
                    <InputField
                      id="createPass"
                      variant="outlined"
                      required
                      placeholder="Create a strong password"
                      onChange={this.handleChange("password").bind(this)}
                      value={this.state.password}
                      fullWidth
                      helperText="Password must have 8 characters, alphanumeric and must include special character"
                      type={this.state.showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              edge="end"
                            >
                              {this.state.showPassword ? (
                                <VisibilityOffOutlinedIcon />
                              ) : (
                                <VisibilityOutlinedIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <InputLabel htmlFor="confirmPass" style={webStyle.inputLabel}>
                      {this.state.confirmPass}*
                    </InputLabel>
                    <InputField
                      id="confirmPass"
                      variant="outlined"
                      required
                      value={this.state.retypePassword}
                      onChange={this.handleChange("retypePassword").bind(this)}
                      type={this.state.confirmShowPassword ? "text" : "password"}
                      placeholder="Confirm your password"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleConfirmShowPassword}
                              edge="end"
                            >
                              {this.state.confirmShowPassword ? (
                                <VisibilityOffOutlinedIcon />
                              ) : (
                                <VisibilityOutlinedIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </>
                )}
                <Buttons type="submit" variant="contained">
                  {this.state.IscompanyDetails ? "Sign Up" : "Proceed"}
                </Buttons>
              </form>
              <Grid container justifyContent="center">
                <Typography style={webStyle.AccountText}>
                  {this.state.AccountLabel}
                </Typography>
                <Typography
                  onClick={() => {
                    navigation.navigate("Login");
                  }}
                  style={webStyle.login}
                >
                  {this.state.loginText}
                </Typography>
              </Grid>
            </RightGrid>
          </RightBox>
        </Grid>
      </>
    );
  }
}

// Customizable Area Start
const webStyle = {
  loginImage: {
    // height: screenHeight,
    height: '100%',
    width: "100%",
    marginTop: '-1px',
    marginLeft: '-2px'
  },
  loginImage2: {
    height: screenHeight,
    width: "100%",
    marginTop: '-1px',
    marginLeft: '-2px'
  },
  rightPart: {
    paddingTop: "2px",
  },
  LogoImage: {
    display: "flex",
    height: '58px',
    width: '160px',
    paddingTop: "15px",
    paddingBottom: "20px",
  },
  Header: {
    color: "rgba(4, 60, 116, 1)",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "24px",
  },
  subHeader: {
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "14px",
    color: "rgba(4, 60, 116, 1)",
    marginBottom: "8px",
  },
  subHeader2: {
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "14px",
    color: "rgba(4, 60, 116, 1)",
    marginBottom: "10px",
  },
  inputLabel: {
    fontSize: "18px",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    color: "rgba(4, 60, 116, 1)",
    paddingBottom: "2px",
    paddingTop: "5px",
  },
  AccountText: {
    color: "rgba(58, 63, 99, 1)",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "18px",
    padding: "5px 10px 8px 0px",
  },
  login: {
    color: "rgba(69, 158, 255, 1)",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "18px",
    padding: "5px 10px 8px 0px",
    cursor: 'pointer'
  },
  countryPlaceHolder: {
    fontStyle: "normal",
    fontWeight: 500,
    fontFamily: "SFProDisplay",
    fontSize: "16px",
    color: "rgba(214, 214, 214, 1)",
  },
  checkedIcon: {
    borderRadius: 3,
    border: "1px solid black",
    width: 15,
    height: 15,
    backgroundColor: "white",
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='black'/%3E%3C/svg%3E\")",
    "input:hover ~ &": {},
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 3,
    width: 15,
    height: 15,
    backgroundColor: "white",
    border: "1px solid black",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "white",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  termsText: {
    fontWeight: 400,
    color: "rgba(58, 63, 99, 1)",
    fontFamily: "SFProDisplay",
    fontSize: "18px",
    paddingTop: "4px",
  },
  privacyText: {
    color: "rgba(69, 158, 255, 1)",
    cursor: 'hand',
  },
  welcomeText: {
    color: 'rgba(255, 255, 255, 1)',
    fontWeight: 500,
    fontFamily: 'SFProDisplay',
    fontSize: '24px',
    marginTop: '4px',
  },
  startedText: {
    color: 'rgba(255, 255, 255, 1)',
    fontWeight: 500,
    fontFamily: 'SFProDisplay',
    fontSize: '20px',
    paddingBottom: '5px',
  },
  serviseText: {
    color: 'rgba(255, 255, 255, 1)',
    fontWeight: 500,
    fontFamily: 'SFProDisplay',
    fontSize: '14px',
    marginBottom: '4px'
  },
  backOption: {
    fontWeight: 700,
    fontFamily: 'SFProDisplay',
    fontSize: '16px',
    color: "rgba(4, 60, 116, 1)",
    cursor: "hand",
    paddingTop: '20px',
  },
  termsPopUpText: {
    fontFamily: 'SFProDisplay',
    fontWeight: 500,
    fontSize: '16px',
    color: '#494A5F',
    padding: '10px 30px 10px 20px'
  },
  divider: {
    height: '50px',
    width: '1px',
    backgroundColor: '#6a74a5',
    margin: '20px 5.9% 5.4%',
  },
  connectLogo: {
    width: '28.4%',
    height: '58px',
    paddingTop: "15px",
    paddingBottom: "20px",
  }
};

const InputField = withStyles({
  root: {
    background: "inherite",
    margin: "5px 0px 5px 0px",
    "& ::placeholder": {
      color: 'rgba(214, 214, 214, 1)',
      fontSize: '16px',
      opacity: 1,
      fontFamily: 'SFProDisplay',
      fontWeight: 500,
    },
    "& .MuiInputBase-input": {
      height: '15px',
    },
    "& .MuiOutlinedInput-input": {
      padding: '14px 14px'
    },
    "& .MuiIconButton-root": {
      color: 'lightGrey'
    },
    "& .MuiOutlinedInput-root": {
      fontWeight: 500,
      fontFamily: "SFProDisplay",
      backgroundColor: "rgba(255, 255, 255, 1)",
      fontSize: "16px",
      borderRadius: "8px",
      "& fieldset": {
        borderColor: "gba(214, 214, 214, 1)",
        borderWidth: 1,
        height: '45px',
      },
    },
    "& .MuiFormHelperText-contained": {
      margin: "0px !important",
      color: "rgba(106, 116, 165, 1)",
      fontSize: "12px",
      fontFamily: "SFProDisplay",
      fontWeight: 400,
    },
  },
})(TextField);

const Buttons = withStyles({
  root: {
    width: "100%",
    color: "rgba(255, 255, 255, 1)",
    backgroundColor: "rgba(69, 158, 255, 1)",
    opacity: 1,
    boxShadow: "none",
    height: "56px",
    borderRadius: "8px",
    fontFamily: "DMSans-Bold",
    fontSize: "26px",
    margin: "10px 0px 10px 0px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "rgba(69, 158, 255, 1)",
      boxShadow: "none",
    },
  },
})(Button);

const CheckBoxLabel = withStyles({
  root: {
    alignItems: "flex-start",
    marginRight: 0,
    "& .MuiTypography-body1": {
      fontFamily: "SFProDisplay",
      fontWeight: 400,
      fontSize: "18px",
      color: "rgba(58, 63, 99, 1)",
    },
  },
})(FormControlLabel);

const WelcomeCard = withStyles({
  root: {
    position: 'absolute',
    top: '75%',
    left: '45%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
  }
})(Grid);

const RightGrid = withStyles({
  root: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    paddingRight: "0px",
    paddingLeft: "70px",
    "@media (max-width: 820px)": {
      padding: `0px 30px 0px 30px !important`,
    },
    "@media (max-width: 1024)": {
      padding: `0px 0px 0px 60px !important`,
    },
  },
})(Grid);

const LeftGrid = withStyles({
  root: {
    marginRight: '7%',
    "& .img": {
      "@media (max-height: 600px)": {
        height: `100% !important`,
      },
      "@media (max-width: 820px)": {
        width: `100% !important`
      },
    },
    "@media (max-width: 640px)": {
      marginRight: '0px',
    },
  }
})(Grid)

const RightBox = withStyles({
  root: {
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    margin: '10px 5px 10px 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
})(Grid);

export const CustomGrid: any = withStyles({
  root: {
    "& .css-tlfecz-indicatorContainer": {
      color: '#000000',
      paddingRight: '12px',
      fontFamily: 'SFProDisplay',
      fontWeight: 400,
    },
    "& .css-1okebmr-indicatorSeparator": {
      display: 'none',
      fontFamily: 'SFProDisplay',
      fontWeight: 700,
    },
    "& .css-1wa3eu0-placeholder": {
      display: 'flex',
      color: 'rgba(214, 214, 214, 1)',
      fontFamily: 'SFProDisplay',
      fontSize: '16px',
      fontWeight: 500,
      opacity: 1,
    },
    "& .css-1uccc91-singleValue": {
      fontFamily: 'SFProDisplay',
      fontSize: '16px',
      fontWeight: 500,
    },
    "& .css-yk16xz-control": {
      height: "42px",
      borderRadius: "8px",
      borderColor: "rgba(214, 214, 214, 1)",
      fontWeight: 500,
      fontFamily: "SFProDisplay",
      fontSize: "16px",
      with: "100%",
    },
    "& .css-g1d714-ValueContainer": {
      position: "revert",
    },
    "& .css-1pahdxg-control:hover": {
      borderColor: '#3F51B5',
      boxShadow: 'none',
      borderWidth: 2
    },
    "& .css-1pahdxg-control": {
      borderColor: '#3F51B5',
      boxShadow: 'none',
      borderWidth: 2
    },
    "& .css-1hb7zxy-IndicatorsContainer": {
      height: "42px",
      borderRadius: "8px",
      borderColor: "rgba(214, 214, 214, 1)",
      fontWeight: 500,
      fontFamily: "SFProDisplay",
      fontSize: "16px",
      with: "100%",
    },
    "& .css-26l3qy-menu": {
      fontFamily: "SFProDisplay",
      fontSize: "14px",
      fontWeight: 500,
    }
  },
})(Grid);

const TermsDialog = withStyles({
  root: {
    "& .MuiDialog-paperScrollPaper": {
      minWidth: '700px',
      padding: '0px 0px 0px 30px',
      borderRadius: '10px'
    },
    "& .MuiDialogActions-root": {
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px',
      paddingBottom: '8px',
    },
    "& .WithStyles\(ForwardRef\(Button\)\)-root-10": {
      "@media (maxWidth: 600px)": {
        width: 300
      }
    }
  }
})(Dialog);

const AcceptButton = withStyles({
  root: {
    width: "140px",
    color: "rgba(255, 255, 255, 1)",
    backgroundColor: "#1A3B71",
    opacity: 1,
    boxShadow: "none",
    height: "42px",
    borderRadius: "4px",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    fontSize: "18px",
    margin: "30px 30px 30px 0px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1A3B71",
      boxShadow: "none",
    },
  },
})(Button);

const IconBox = withStyles({
  root: {
    background: '#DAE0EC',
    height: '26px',
    width: '26px',
    borderRadius: '50%',
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 0px 0px 0px',
    "& .MuiSvgIcon-root": {
      height: '20px',
      width: '20px'
    }
  }
})(Box)