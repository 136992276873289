import React from "react";
// Customizable Area Start
import { Typography, Grid } from "@material-ui/core";
import AppFooterController, { Props } from "./AppFooterController";

//appfooter.web

import CallOutlinedIcon from "@material-ui/icons/CallOutlined";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

const logo = require("./Logo.png");
const Connectlogo = require("./BioconnectLogo-Reverse.png");


// Customizable Area End

export default class AppFooter extends AppFooterController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <Grid className={this.props.isLoading ? "footerLoad" : "footer1"}>
        <Grid container spacing={2}>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <img src={logo} className="logofooter" />
          </Grid>
          <Grid item xl={3} lg={4} md={4} sm={6} xs={12} style={{ padding: '8px 30px 8px 10px', paddingLeft: '15px', }}>
            <Typography className="headerTitles">API</Typography>
            <Typography className="fontfiza2">
              {this.state.footerData.api}
            </Typography>
          </Grid>

          <Grid item lg={2} md={2} sm={6} xs={12} style={{ paddingLeft: '15px' }}>
            <Typography className="headerTitles">Company</Typography>
            <Typography onClick={() => window.open(this.state.footerData.terms_of_use)} className="fontfiza">
              Terms of Use
            </Typography>
            <Typography onClick={() => window.open(this.state.footerData.cookie_policy)} className="fontfiza">
              Cookie Policy
            </Typography>
            <Typography onClick={() => window.open(this.state.footerData.privacy_policy)} className="fontfiza">
              Privacy Policy
            </Typography>
          </Grid>

          <Grid item lg={3} md={3} sm={6} xs={12} style={{ paddingLeft: '15px' }} >
            <Typography className="headerTitles">Contact Us</Typography>
            <Grid container>
              <MailOutlineIcon style={{ margin: '5px 10px 0px 0px', fill: 'white', fontSize: '18px', cursor: 'pointer' }} />
              <Typography className="fontfiza2" component={"span"} style={{ cursor: 'pointer' }} onClick={() => window.open(`mailto:${this.state.footerData.email}`)}>
                {this.state.footerData.email}
              </Typography>
            </Grid>
            <Grid container>
              <CallOutlinedIcon style={{ margin: '5px 10px 0px 0px', fill: 'white', fontSize: '18px' }} />
              <Typography className="fontfiza2" component={"span"}>
                {this.state.footerData.phone_number}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" style={{ padding: '20px 0px 20px 0px' }}>
          <Typography className="copyRight" align="center">
            {this.state.footerData.content}
          </Typography>
          <img src={Connectlogo} className="logofooter2" />
        </Grid>
      </Grid>
    );
  }
}


