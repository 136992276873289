import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { apiCall, getHeaders, isEmployee, isLoggedIn, handleUpdateAuthToken , events, autoLogOut} from "../../../components/src/common";
import fileDownload from 'js-file-download';
import axios from 'axios';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

export interface IDocument {
  file_id: number;
  download_file: boolean;
  file_name: string;
  doc_file: string;
  grant_access: boolean;
  product_document_id: number;
  product_document_name: string;
  confidential: boolean;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  departmentList: any[];
  document: number;
  file: any;
  documentsList: IDocument[];
  selectedDocument: IDocument | null;
  numPages: number;
  loading: boolean;
  selectDocumentPlaceholder: string;
  noDataText: string;
  departmentIdText: string;
  BackButtonText: string;
  loadingText: string;
  DownloadButtonText: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DownloadPermissionsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  catalogueId: string;
  bucketId: string;
  authToken: string;
  getFilesListApiCallId: string;
  downloadDocumentSentEmailId: string;
  generateViewReportAPICallId: string;
  FromPage: string;
  // token: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      departmentList: [],
      document: 0,
      file: '',
      documentsList: [],
      selectedDocument: null,
      numPages: 0,
      loading: false,
      selectDocumentPlaceholder: configJSON.selectDocumentPlaceholder,
      noDataText: configJSON.noDataText,
      departmentIdText: configJSON.departmentIdText,
      BackButtonText: configJSON.BackButtonText,
      loadingText: configJSON.loadingText,
      DownloadButtonText: configJSON.DownloadButtonText,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (this.getFilesListApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
        if (responseJson.result) {
          this.setState({
            documentsList: responseJson.result,
            selectedDocument: responseJson.result[0],
          })
          this.generateReportForDocument();
          setTimeout(() => {
            this.setState({ loading: false });
          }, 1000)
        } else {
          if (responseJson.errors && responseJson.errors[0]?.token === "Token has Expired" && JSON.parse(localStorage.getItem("userInfo") || "")?.attributes?.is_customer === 'True') {
            this.setState({
              loading: true
            })
            await handleUpdateAuthToken()
            const authToken = isLoggedIn(this.props.navigation);
            if (typeof (authToken) === "string") {
              this.authToken = authToken;
            }
            if (this.authToken) {
              this.getFolderFiles();
            }
          }
        }
      }
      if (this.downloadDocumentSentEmailId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        // successNotification(responseJson.message)
        setTimeout(() => {
          this.setState({ loading: false });
        }, 1000)
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        if(isLoggedIn(this.props.navigation)) {
          autoLogOut();
        }
      });
    });
    document.addEventListener("keydown", function (e) {
      if (e.ctrlKey && (e.key == "p" || e.key == "s" || e.charCode == 16 || e.charCode == 112 || e.keyCode == 80)) {
        e.cancelBubble = true;
        e.preventDefault();
        e.stopImmediatePropagation();
      }
    });
    const fromPage = localStorage.getItem("fromPage");
    this.FromPage = fromPage || "";
    window.addEventListener('contextmenu', function (e) {
      e.preventDefault();
      return false;
    });
    //@ts-ignore

    const catalogueId = this.props.navigation.getParam('CatalogueId');
    const bucketId = this.props.navigation.getParam('BucketId');

    this.catalogueId = catalogueId;
    this.bucketId = bucketId;

    const authToken = isLoggedIn(this.props.navigation);
    if (typeof (authToken) === "string") {
      this.setState({ loading: true });
      this.authToken = authToken;
      if (!isEmployee()) {
        this.getFolderFiles();
      } else {
        this.getEmployeeFolderFiles();
      }

    }
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  // Customizable Area Start
  handleDocumentSelect = (event: any) => {
    this.setState({ loading: true });
    this.setState({
      selectedDocument: this.state.documentsList.find((doc: IDocument) => doc.file_id === event.target.value) || null,
    });
    setTimeout(() => {
      this.setState({ loading: false });
      this.generateReportForDocument();
    }, 1000);
  };

  getFolderFiles = () => {
    this.setState({ loading: true })
    const header = getHeaders({ token: this.authToken });

    const getFiles = apiCall({
      httpBody: {},
      header: header,
      url: configJSON.getFilesListApiEndpoint + `?id=${this.bucketId}&catalogue_id=${this.catalogueId}`,
      httpMethod: configJSON.getFilesListApiMethod,
    });

    this.getFilesListApiCallId = getFiles.messageId;
    runEngine.sendMessage(getFiles.id, getFiles);
  }

  getEmployeeFolderFiles = () => {
    const authToken = localStorage.getItem("authToken") || '';
    const header = getHeaders({ token: authToken });

    const getEmpFiles = apiCall({
      httpBody: {},
      header: header,
      url: configJSON.getEmployeeFilesListApiEndpoint + `?id=${this.bucketId}&catalogue_id=${this.catalogueId}`,
      httpMethod: configJSON.getEmployeeFilesListApiMethod,
    });

    this.getFilesListApiCallId = getEmpFiles.messageId;
    runEngine.sendMessage(getEmpFiles.id, getEmpFiles);
  }

  onImageChange = (e: any) => {
    this.setState({
      file: URL.createObjectURL(e.target.files[0])
    })
  };

  handleBackClick = () => {
    if (this.props.navigation) {
      let productName = localStorage.getItem('productName')
      this.props.navigation.navigate("ProductDetails", { id: productName });
      localStorage.setItem("from", "document");
    }
  }

  disableRightclick = (evt: any) => {
    window.addEventListener('contextmenu', function (e) {
      // do something here... 
      e.preventDefault();
    }, false); return false;
  }

  onDocumentLoadSuccess = ({ numPages }: any) => {
    this.setState({
      numPages: numPages,
    })
    this.setState({ loading: false });
  }

  handleDownloadClick = () => {
    axios.get(this.state.selectedDocument?.doc_file || '', {
      responseType: 'blob',
    })
      .then((res) => {
        fileDownload(res.data, `${this.state.selectedDocument?.file_name}.pdf` || '')
      })

      const authToken = localStorage.getItem("authToken") || '';
      const downloadDocumentEmail = apiCall({
        url: configJSON.downloadDocumentSentEmail + `?product_document_file_id=${this.state.selectedDocument?.file_id}`,
        header: getHeaders({ token: authToken }),
        httpBody: {},
        httpMethod: configJSON.downloadDocumentSentEmailMethod
      })
      this.downloadDocumentSentEmailId = downloadDocumentEmail.messageId;
      runEngine.sendMessage(downloadDocumentEmail.id, downloadDocumentEmail);
  }

  generateReportForDocument = () => {
    const authToken = localStorage.getItem("authToken") || '';
    const header = getHeaders({ token: authToken });

    if (this.state.selectedDocument?.confidential) {
    const getEmpFiles = apiCall({
      httpBody: {
        product_document_file_id: this.state.selectedDocument?.file_id,
      },
      header: header,
      url: configJSON.generateViewReport,
      httpMethod: configJSON.downloadDocumentSentEmailMethod,
    });

    this.generateViewReportAPICallId = getEmpFiles.messageId;
    runEngine.sendMessage(getEmpFiles.id, getEmpFiles);
   }
  }
  // Customizable Area End

}
