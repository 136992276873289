import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Box,
  Grid,
  Popover
} from "@material-ui/core";
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';
import { IProductListing } from "./ProductListingController";
import { withStyles } from '@material-ui/core/styles';
import { NavLink } from "react-router-dom";
import { getHighlightedText } from "../../../components/src/common";
import DevelopementProductcard from "./DevelopementProductCard.web";

interface Iprops {
  item: IProductListing,
  searchTerm: string,
  index: number,
  handleClick: (id: string, productName: string) => void,
}

const Productcard = (props: Iprops) => {
  const { item, index } = props;
  let value = item.attributes;
  const [innerWidth, setWidth] = useState(window.innerWidth);
  const [openToolTip, setOpenToolTip] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popUPText, setPopUPText] = useState(null);
  const [CardClass, setCardClass] = useState(webStyle.productTitleOdd);
  const [CardValueClass, setCardValueClass] = useState(webStyle.ProDetailValueOdd);
  const [CardButtonClass, setCardButtonClass] = useState(webStyle.ActionCardButtonOdd);

  const setCardStyles = () => {
    if (index % totalCards === 0 || index % totalCards !== 1) {
      setCardClass(webStyle.productTitleOdd);
      setCardValueClass(webStyle.ProDetailValueOdd);
      setCardButtonClass(webStyle.ActionCardButtonOdd);
    } else {
      setCardClass(webStyle.productTitleEven);
      setCardValueClass(webStyle.ProDetailValueEven);
      setCardButtonClass(webStyle.ActionCardButtonEven);
    }
  };

  React.useEffect(() => {
    setCardStyles()
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  const updateWidth = () => {
    setWidth(window.innerWidth);
    setCardStyles();
  };

  let totalCards = 3;
  if (innerWidth < 2100 && innerWidth > 1830) {
    totalCards = 3;
  } else if (innerWidth < 1279) {
    totalCards = 2;
  }

  const handleToolTip = (event: any, text: any) => {
    setOpenToolTip(!openToolTip);
    setAnchorEl(event.currentTarget)
    setPopUPText(text)
  };

  const ChemicalNameToolTip = () => {
    return (
      <Box>
        <Grid>
          <CancelTwoToneIcon
          data-testid='CancelTwoToneIcon'
            style={{ fill: "rgba(4, 60, 116, 1)", float: 'right' }}
            onClick={(e: any) => handleToolTip(e, "")}
          >
          </CancelTwoToneIcon>
        </Grid>
        <Grid item lg={12}>
          <Typography style={webStyle.chemicalToolTip}>
            {popUPText}
          </Typography>
        </Grid>
      </Box>
    )
  };

  const ReadMoreLabels = () => {
    return (
      <>
        <Typography
          data-testid='ReadMoreLabels'
          component={"span"}
          style={webStyle.dotMoreVal}
          aria-owns={openToolTip ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={(e: any) => handleToolTip(e, value.therapeutic_area)}
          onMouseLeave={(e: any) => handleToolTip(e, value.therapeutic_area)}
        >...</Typography>
        <Typography
          data-testid='ReadMoreLabelClass'
          component={"span"}
          style={webStyle.ReadMoreLabelClass}
          aria-owns={openToolTip ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={(e: any) => handleToolTip(e, value.therapeutic_area)}
        >
          Read More
        </Typography>
      </>
    )
  }

  return (
    item?.attributes?.if_pipeline_product != "No" ?
      <DevelopementProductcard
        item={item}
        searchTerm={props.searchTerm}
        CardClass={CardClass}
        CardValueClass={CardValueClass}
        CardButtonClass={CardButtonClass}
      />
      :
      <CustomCardWithoutHover key={`product-item-${item.id}`} style={{ height: '100%' }}>
        <CardHeader
          style={{ ...webStyle.productTitle, ...(CardClass) }}
          title={getHighlightedText(value.name, props.searchTerm)}
        />
        <CustomCardContent>
          <Box display="flex" alignItems="center" justifyContent={"center"}>
            <Typography style={webStyle.ProDetailLabel} align="center" className="details">
              Manufacturing Platform: <span
                style={CardValueClass}
              >
                {getHighlightedText(value?.manufacturing_platform?.slice(0, 40), props.searchTerm)}
                {value.manufacturing_platform !== undefined &&
                  value.manufacturing_platform.length > 40 && (
                    <>
                      {ReadMoreLabels()}
                    </>
                  )
                }
              </span>
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" justifyContent={"center"}>
            <Typography style={webStyle.ProDetailLabel2} align="center" className="details">
              Therapeutic Area: <span
                className="textWrap"
                style={CardValueClass}
              >
                {getHighlightedText(value.therapeutic_area.slice(0, 40), props.searchTerm)}
                {value.therapeutic_area !== undefined &&
                  value.therapeutic_area.length > 40 && (
                    <>
                      {ReadMoreLabels()}
                    </>
                  )
                }
              </span>
            </Typography>
            <ChemicalDetailPopover
             data-testid='ChemicalDetailPopover'
              id="mouse-over-popover"
              open={openToolTip}
              anchorEl={anchorEl}
              disableRestoreFocus
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              onClose={(e: any) => handleToolTip(e, value.therapeutic_area)}
            >
              {ChemicalNameToolTip()}
            </ChemicalDetailPopover>
          </Box>
        </CustomCardContent>
        <CustomCardAction>
          <NavLink to={`/ProductDetail/${item.attributes.name.replace(/\s+/g, '-')}`}>
            <Button
            data-testid='btnKnowMore'
              style={{
                ...webStyle.ActionCardButton,
                ...(CardButtonClass),
              }}
              onClick={() => localStorage.setItem('ProductId', item.id)}
              variant="contained"
              className="knowMoreButton knowMoreButtonHover"
            >
              Know More
            </Button>
          </NavLink>
        </CustomCardAction>
      </CustomCardWithoutHover >
  );
};

const ChemicalDetailPopover: any = withStyles({
  paper: {
    color: 'rgba(4, 60, 116, 1)',
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '20px',
    backgroundColor: "rgba(255, 255, 255, 1)",
    boxShadow: "3px 4px 25px 2px lightGray",
    minWidth: 300,
    maxWidth: 300,
    borderRadius: 8,
    padding: '5px 10px',
    overflowX: "unset",
    overflowY: "unset",
    "&::before": {
      backgroundColor: "rgba(255, 255, 255, 1)",
      content: '""',
      display: "block",
      position: "absolute",
      width: 14,
      height: 14,
      top: -6,
      transform: "rotate(45deg)",
      left: "calc(50% - 10px)",
    },
    "@media (max-width: 980px)": {
      width: '68%',
    }
  }
})(Popover);

const webStyle = {
  productTitle: {
    color: "rgba(4, 60, 116, 1)",
    textAlign: "center" as "center",
    height: "75px",
  },
  productTitleEven: {
    backgroundColor: "#DCDFF0",
  },
  productTitleOdd: {
    backgroundColor: "#D7E8F9",
  },
  chemicalToolTip: {
    color: 'rgba(4, 60, 116, 1)',
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '14px',
    padding: '12px 5px 10px 5px'
  },
  ProDetailLabel: {
    color: "#6a74a5",
    fontSize: '16px',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    margin: '20px 24px 5px 24px',
  },
  ProDetailLabel2: {
    color: "#6a74a5",
    fontSize: '16px',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    margin: '0px 24px 0px 24px',
  },
  ProDetailValueOdd: {
    marginLeft: "5px",
    color: "#459eff",
    fontSize: '16px',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    margin: '0px 0px 0px 0px',
  },
  ProDetailValueEven: {
    marginLeft: "5px",
    color: "#459eff",
    fontSize: '16px',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    margin: '0px 0px 0px 0px',
  },
  ActionCardButton: {
    borderRadius: '21px',
    height: "34px",
    width: '146px',
    fontFamily: 'SFProDisplay',
    fontWeight: 500,
    margin: '56px 104px 0',
    boxShadow: 'none',
  },
  ActionCardButtonEven: {
    background: "#043c74",
    color: "#fff",
    fontSize: '16px',
    borderRadius: "21px",
    fontFamily: 'SFProDisplay',
    fontWeight: 500,
    margin: '30px 0px 25px 0',
    boxShadow: 'none',
  },
  ActionCardButtonOdd: {
    background: "#c6e4f7",
    color: "rgba(4, 60, 116, 1)",
    fontSize: '16px',
    borderRadius: "21px",
    margin: '25px 0px 30px 0',
    fontFamily: 'SFProDisplay',
    fontWeight: 500,
    boxShadow: 'none',
  },
  dotMoreVal: {
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.85)',
    padding: '0px 0px 0px 0px',
  },
  ReadMoreLabelClass: {
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '14px',
    color: 'rgba(0, 169, 255, 1)',
    padding: '0px 0px 0px 1px',
    textDecoration: 'none',
    marginLeft: '2px',
    "&:hover": {
      textDecoration: 'underline',
    },
    cursor: 'hand',
  },
};

export default Productcard;

const CustomCardContent: any = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0px',
  }
})(CardContent);

const CustomCardAction: any = withStyles({
  root: {
    justifyContent: "center",
    padding: '0px'
  }
})(CardActions);

const CustomCardWithoutHover: any = withStyles({
  root: {
    borderRadius: "16px",
    borderColor: "rgba(185, 187, 197, 1)",
    borderWidth: '2px',
    margin: '0px 0px 0px 0px',
    "& .MuiPaper-elevation1": {
      boxShadow: `0px 2px 1px - 1px #dae0f0 ,
      0px 1px 1px 0px #dae0f0 ,
      0px 1px 3px 0px #dae0f0`,
    },
    "& .MuiCardHeader-root": {
      padding: '0px'
    },
    "@media (min-width: 1280px) and (max-width: 1381px)": {
      "& .details": {
        inlineSize: '60%',
      }
    },
  }
})(Card)

