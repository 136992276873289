import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  apiCall,
  getHeaders,
  isLoggedIn,
  getUserdetails,
  IUserdetails
} from "../../../components/src/common";
//import { default as updatesData } from "./UpdatesData.json";

let BannerApiCallId: string;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  route: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  isSearchError: boolean;
  bannerVideo: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userDetails: IUserdetails;
  searchApiCallId: string;
  authToken: string;
  video: any;
  progressBar: any;
  BannerApiCallId: string;

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      isLoading: false,
      isSearchError: false,
      bannerVideo: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (
        BannerApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
        if (
          responseJson.upload_media.data[0].attributes.file_attached ===
          "Media attached is video"
        ) {
          this.setState({
            bannerVideo: responseJson.upload_media.data[0].attributes.media
          });
        }
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getBanners();
    // const authToken = isLoggedIn(this.props.navigation);
    // if (typeof authToken === "string") {
    //   this.authToken = authToken;
    //   const userDetailToken = getUserdetails();
    //   if (userDetailToken) {
    //     this.userDetails = userDetailToken;
        
    //   }
    // }
  }

  getBanners() {
    const header = getHeaders({ token: this.authToken });

    const homePageApi = apiCall({
      url: configJSON.showBannerAPIEndPoint,
      httpBody: {},
      httpMethod: configJSON.homePageContentApiMethod,
      header: header
    });

    this.BannerApiCallId = homePageApi.messageId;
    runEngine.sendMessage(homePageApi.id, homePageApi);
  }

  // Customizable Area End
}
