import React, { useEffect, useState } from "react";
import {
    AppBar,
    Box,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    MenuItem,
    Grid,
    Button,
    Drawer,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import MenuIcon from "@material-ui/icons/Menu";
import { NavLink as RLink } from "react-router-dom";
import { getUserdetails, logout } from "./common";
import AboutUsRedirectionConfirmationPopup from "./AboutUsRedirectionConfirmationPopup";
import { CookieConsentPopup } from "./AppHeader.web";
import HeaderTiles from "../../blocks/catalogue/src/HeaderTiles.web";
import axios from 'axios';
export const configJSON = require("./config");

const logo = require("./Logo.png");
interface IProps {
    isSticky?: boolean;
    navigation?: any;
    getQueryType?: any;
    getCompanyList?: any;
}
const AppHeader = ({ isSticky, navigation, getQueryType, getCompanyList }: IProps) => {
    const userDetails = getUserdetails();
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
        null
    );
    const [anchorEl2Nav, setAnchorElMaster] = React.useState<any>(
        null
    );
    const [anchorEl2Ticket, setAnchorElTicket] = React.useState<any>(
        null
    );
    const [MasterList, setMasterList] = React.useState<boolean>(false);
    const [TicketList, setTicketList] = React.useState<boolean>(false);
    const [anchorElUser, setAnchorElUser] = React.useState<any>(null);
    const [employeeMenu, setEmployeeMenu] = React.useState<any>([]);
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleOpenMasterMenu = (event: React.MouseEvent<HTMLElement>) => {
        // setMasterList(true);
        setAnchorElMaster(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
        setAnchorElMaster(null);
        // setMasterList(true);
    };

    const handleOpenTicketsMenu = (event: any) => {
        // setTicketList(true);
        setAnchorElTicket(event.currentTarget);
    }

    const handleCloseTicketMenu = () => {
        setAnchorElTicket(null);
        // setTicketList(true);
    };

    useEffect(() => {
        console.log(window.location.pathname, 'navigation')
        if (MasterList || window.location.pathname === "/MasterCustomer" || window.location.pathname === "/MasterContact") {
            setMasterList(true);
        }
        if (TicketList || window.location.pathname === "/TicketList") {
            setTicketList(true);
        }
    }, [])

    return (
        <React.Fragment>
            <AppBar position={isSticky ? "sticky" : "static"}
                style={webStyle.appBar}
                className="app-bar"
            >
                <HeaderTiles navigation={undefined} id={""} />
                <Box sx={{
                    padding: {
                        xs: "10px 10px", sm: "10px 10px", md: '8px 50px', lg: '8px 45px'
                    }
                }}>
                    <Toolbar disableGutters>
                        <RLink style={{ ...webStyle.NavLink, display: 'flex' }} to="/">
                            <img style={webStyle.logoStyle} className="logo" src={logo} />
                        </RLink>
                        <Grid container justifyContent="flex-end" alignItems="center">
                            <Box sx={{ display: { xs: "none", sm: "none", md: 'none', lg: 'flex' } }} className="appBarRoute">
                                <ResponsiveAppBarRoutes
                                    setMasterList={setMasterList}
                                    setTicketList={setTicketList}
                                    handleOpenMasterMenu={handleOpenMasterMenu}
                                    anchorEl2Nav={anchorEl2Nav}
                                    anchorEl2Ticket={anchorEl2Ticket}
                                    MasterList={MasterList}
                                    TicketList={TicketList}
                                    handleCloseUserMenu={handleCloseUserMenu}
                                    handleOpenTicketsMenu={handleOpenTicketsMenu}
                                    handleCloseTicketMenu={handleCloseTicketMenu}
                                    handleCloseNavMenu={handleCloseNavMenu}
                                    getQueryType={getQueryType}
                                    getCompanyList={getCompanyList}
                                    navigation={navigation}
                                    employeeMenu={employeeMenu}
                                />
                            </Box>
                            <Box>
                                <IconButton
                                    style={webStyle.profileBTN}
                                    id="basic-button"
                                    onClick={handleOpenUserMenu}
                                    aria-controls={Boolean(anchorElUser) ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={Boolean(anchorElUser) ? 'true' : undefined}
                                    className="account-circle"
                                >
                                    {`${userDetails?.attributes.first_name || ''} ${userDetails?.attributes.last_name || ''} `}
                                    <ArrowDropDownOutlinedIcon style={webStyle.arrowIcon}></ArrowDropDownOutlinedIcon>
                                </IconButton>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorElUser}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    keepMounted
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={() => {
                                        handleCloseUserMenu();
                                        navigation.navigate("EmployeeProfile");
                                    }
                                    } style={{ height: '35px' }}>
                                        <Typography style={{ textAlign: "center", ...webStyle.linkName }}>
                                            My Profile
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        handleCloseUserMenu();
                                        logout(navigation);
                                        navigation.navigate("/");
                                    }}
                                        style={{ height: '35px' }}
                                    >
                                        <Typography style={{ textAlign: "center", ...webStyle.linkName }}>
                                            Log Out
                                        </Typography>
                                    </MenuItem>
                                </Menu>
                            </Box>
                            <Box
                                sx={{
                                    display: { xs: "flex", lg: "none" },
                                }}
                            >
                                <IconButton
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                >
                                    <MenuIcon />
                                </IconButton>

                                <Drawer
                                    anchor={'right'}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                >
                                    <SideDrawer
                                        setMasterList={setMasterList}
                                        setTicketList={setTicketList}
                                        customerMasterMenu
                                        contactMasterMenu
                                        handleOpenMasterMenu={handleOpenMasterMenu}
                                        anchorEl2Nav={anchorEl2Nav}
                                        anchorEl2Ticket={anchorEl2Ticket}
                                        MasterList={MasterList}
                                        TicketList={TicketList}
                                        handleCloseUserMenu={handleCloseUserMenu}
                                        handleOpenTicketsMenu={handleOpenTicketsMenu}
                                        handleCloseTicketMenu={handleCloseTicketMenu}
                                        handleCloseNavMenu={handleCloseNavMenu}
                                        getQueryType={getQueryType}
                                        getCompanyList={getCompanyList}
                                        navigation={navigation}
                                        employeeMenu={employeeMenu}
                                    />
                                </Drawer>
                            </Box>
                        </Grid>
                    </Toolbar>
                </Box>
                <CookieConsentPopup />
            </AppBar>
        </React.Fragment>
    );
};

const MasterButton = ({setMasterList, navigation, handleOpenMasterMenu, anchorEl2Nav, MasterList, handleCloseUserMenu, employeeMenu }: any) => {
    return (<Box className="routeIcon">
        <RLink
            id="basic-button"
            onClick={handleOpenMasterMenu}
            aria-controls={Boolean(anchorEl2Nav) ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={Boolean(anchorEl2Nav) ? 'true' : undefined}
            style={webStyle.linkName}
            className={MasterList ? 'is-active' : ''}
            // activeClassName={MasterList ? 'is-active' : ''}
            exact={true}
            to="#"
        >
            Master
        </RLink>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl2Nav}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                keepMounted
                open={Boolean(anchorEl2Nav)}
                onClose={handleCloseUserMenu}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <div>
                {localStorage.getItem("Contact_Master") &&
                    localStorage.getItem("Customer_Master") === "true" &&
                    <MenuItem onClick={() => {
                                handleCloseUserMenu();
                                localStorage.setItem("FromTicket", "menu");
                                navigation.navigate("MasterCustomer");
                                setMasterList(true);
                                if (localStorage.getItem("previousPath") === '/MasterCustomer') {
                                    window.location.reload();
                                }
                            }
                            }
                                style={{ height: '35px', borderBottom: '1px solid #E1E0E6' }}
                            >
                                <Typography style={{ textAlign: "center", ...webStyle.linkName }}>
                                    Customer Master
                                </Typography>
                     </MenuItem>
                }
                    {localStorage.getItem("Contact_Master") &&
                        (localStorage.getItem("Contact_Master") === "true") && 
                            <MenuItem
                                onClick={() => {
                                    handleCloseUserMenu();
                                    localStorage.setItem("FromTicket", "menu");
                                    navigation.navigate("MasterContact");
                                    setMasterList(true);
                                    if (localStorage.getItem("previousPath") === '/MasterContact') {
                                        window.location.reload();
                                    }
                                }}
                                style={{ height: '35px', borderBottom: '1px solid #E1E0E6' }}
                            >
                                <Typography style={{ ...webStyle.linkName, textAlign: "center" }}>
                                    Contact Master
                                </Typography>
                            </MenuItem>
                    }
                </div>
            </Menu>
    </Box>)
}

const TicketsButton = ({
    getCompanyList,
    getQueryType,
    handleCloseNavMenu,
    navigation,
    setTicketList,
    handleOpenTicketsMenu, anchorEl2Ticket, TicketList, handleCloseTicketMenu, employeeMenu }: any) => {
    return <Box className="routeIcon">
        <RLink
            id="basic-button"
            onClick={handleOpenTicketsMenu}
            aria-controls={Boolean(anchorEl2Ticket) ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={Boolean(anchorEl2Ticket) ? 'true' : undefined}
            style={webStyle.linkName}
            className={TicketList ? 'is-active' : ''}
            // activeClassName={TicketList ? 'is-active' : ''}
            exact={true}
            to="#"
        >
            My Tickets
        </RLink>
        <Menu
                id="basic-menu"
                anchorEl={anchorEl2Ticket}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                keepMounted
                open={Boolean(anchorEl2Ticket)}
                onClose={handleCloseTicketMenu}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <div>
                    {(JSON.parse(localStorage.getItem('View_My_Tickets') || "").can_read) && 
                    <MenuItem
                        onClick={() => {
                            localStorage.setItem('createNewTicket', 'false');
                            handleCloseTicketMenu();
                            handleCloseNavMenu();
                            navigation.navigate("TicketList")
                            setTicketList(true);
                            if (localStorage.getItem("previousPath") === '/TicketList') {
                                window.location.reload();
                                }
                            }}
                            style={{ height: '35px', borderBottom: '1px solid #E1E0E6' }}
                            >
                        <Typography style={{ ...webStyle.linkName, textAlign: "center" }}>
                            View My Tickets
                        </Typography>
                    </MenuItem>
                    }
                    {(localStorage.getItem("Create_New_Ticket") && localStorage.getItem("View_My_Tickets")) && 
                    (JSON.parse(localStorage.getItem("Create_New_Ticket") || "").can_create) && 
                        <MenuItem
                                onClick={() => {
                                    localStorage.setItem('createNewTicket', 'true');
                                    handleCloseTicketMenu();
                                    handleCloseNavMenu();
                                    setTimeout(() => {
                                        getQueryType();
                                        getCompanyList();
                                    }, 1000)
                                    navigation.navigate("TicketList")
                                    if (localStorage.getItem("previousPath") === '/TicketList' && localStorage.getItem("createNewTicket") === 'false') {
                                        window.location.reload();
                                    }
                                }}
                                style={{ height: '35px', borderBottom: '1px solid #E1E0E6' }}
                            >
                                <Typography style={{ ...webStyle.linkName, textAlign: "center" }}>
                                    Create New Ticket
                                </Typography>
                        </MenuItem>
                    }
                </div>
            </Menu>
    </Box>
}


const ResponsiveAppBarRoutes = ({ setMasterList, setTicketList, navigation, getCompanyList, getQueryType, handleCloseNavMenu, handleOpenMasterMenu, anchorEl2Nav, anchorEl2Ticket, MasterList, TicketList, handleCloseUserMenu, handleCloseTicketMenu, handleOpenTicketsMenu, employeeMenu }: any) => {
    const [isAboutUsPopup, setIsAboutUsPopup] = useState(false);

    const handleAboutUsClick = () => {
        setIsAboutUsPopup(true);
    }

    return (
        <React.Fragment>
            <CustomGrid container alignItems="center">
                <RLink to="/" style={webStyle.linkName}
                    activeClassName='is-active'
                    exact={true}
                >
                    Home
                </RLink>
                <RLink to="/APIView"
                    style={webStyle.linkName}
                    activeClassName='is-active'
                    exact={true}
                >
                    API Overview
                </RLink>
                {(localStorage.getItem("Create_New_Ticket") && localStorage.getItem("View_My_Tickets")) &&
                    (JSON.parse(localStorage.getItem("Create_New_Ticket") || "").can_create || JSON.parse(localStorage.getItem('View_My_Tickets') || "").can_read) && (
                <TicketsButton
                    setTicketList={setTicketList}
                    handleOpenTicketsMenu={handleOpenTicketsMenu}
                    anchorEl2Ticket={anchorEl2Ticket}
                    TicketList={TicketList}
                    handleCloseTicketMenu={handleCloseTicketMenu}
                    handleCloseNavMenu={handleCloseNavMenu}
                    getQueryType={getQueryType}
                    getCompanyList={getCompanyList}
                    navigation={navigation}
                    employeeMenu={employeeMenu}
                />
                    )
                }
                {(localStorage.getItem("Contact_Master") && localStorage.getItem("Customer_Master")) &&
                    (localStorage.getItem("Contact_Master") === "true" || localStorage.getItem("Customer_Master") === "true") && (
                    <MasterButton 
                    setMasterList={setMasterList}
                    navigation={navigation}
                    handleOpenMasterMenu={handleOpenMasterMenu}
                    anchorEl2Nav={anchorEl2Nav}
                    MasterList={MasterList}
                    handleCloseUserMenu={handleCloseUserMenu}
                    employeeMenu={employeeMenu} 
                    />
                )}
                <RLink to="/ProductListing/1"
                    style={webStyle.linkName}
                    className={window.location.pathname.split('/')[1] === "ProductListing" ? 'is-active' : ""}
                    exact={true}
                >
                    Products
                </RLink>
                <Button
                    style={{ ...webStyle.linkName, textTransform: 'none' }}
                    onClick={handleAboutUsClick}
                >
                    About Us
                </Button>
            </CustomGrid>
            {<AboutUsRedirectionConfirmationPopup isOpen={isAboutUsPopup} setIsOpen={setIsAboutUsPopup} />}
        </React.Fragment>
    );
};

const SideDrawer = ({setMasterList, setTicketList,navigation, getCompanyList, getQueryType, handleCloseNavMenu, handleOpenMasterMenu, anchorEl2Nav, anchorEl2Ticket, MasterList, TicketList, handleCloseUserMenu, handleCloseTicketMenu, handleOpenTicketsMenu, employeeMenu }: any) => {
    const [isAboutUsPopup, setIsAboutUsPopup] = useState(false);
    const handleAboutUsClick = () => {
        setIsAboutUsPopup(true);
    }

    return (
        <React.Fragment>
            <CustomGrid container alignItems="flex-start" direction="column" style={{ minWidth: 200 }}>
                <RLink to="/" style={{ ...webStyle.sideNavlinkName, marginTop: 30 }}
                    activeClassName='is-active'
                    exact={true}
                >
                    Home
                </RLink>
                <RLink to="/APIView"
                    style={webStyle.sideNavlinkName}
                    activeClassName='is-active'
                    exact={true}
                >
                    API Overview
                </RLink>
                {(localStorage.getItem("Create_New_Ticket") && localStorage.getItem("View_My_Tickets")) &&
                ((JSON.parse(localStorage.getItem("Create_New_Ticket") || "").can_create) || JSON.parse(localStorage.getItem('View_My_Tickets') || "").can_read) && (
                    <TicketsButton
                      setTicketList={setTicketList}
                      handleOpenTicketsMenu={handleOpenTicketsMenu}
                      anchorEl2Ticket={anchorEl2Ticket}
                      TicketList={TicketList}
                      handleCloseTicketMenu={handleCloseTicketMenu}
                      handleCloseNavMenu={handleCloseNavMenu}
                      getQueryType={getQueryType}
                      getCompanyList={getCompanyList}
                      navigation={navigation}
                      employeeMenu={employeeMenu}
                    />
                )
                }
                {(localStorage.getItem("Contact_Master") && localStorage.getItem("Customer_Master")) &&
                    (localStorage.getItem("Customer_Master") === 'true' || localStorage.getItem("Contact_Master") === 'true') && (
                        <MasterButton setMasterList={setMasterList} navigation={navigation} handleOpenMasterMenu={handleOpenMasterMenu} anchorEl2Nav={anchorEl2Nav} MasterList={MasterList} handleCloseUserMenu={handleCloseUserMenu} employeeMenu={employeeMenu} />
                )}
                <RLink to="/ProductListing/1"
                    style={{ ...webStyle.sideNavlinkName, marginTop: 20 }}
                    className={window.location.pathname.split('/')[1] === "ProductListing" ? 'is-active' : ""}
                    exact={true}
                >
                    Products
                </RLink>
                <Button
                    style={{ ...webStyle.sideNavlinkName, padding: 0, textTransform: 'none' }}
                    onClick={handleAboutUsClick}
                // activeClassName='is-active'
                // exact={true}
                >
                    About Us
                </Button>
            </CustomGrid>
            {<AboutUsRedirectionConfirmationPopup isOpen={isAboutUsPopup} setIsOpen={setIsAboutUsPopup} />}
        </React.Fragment>
    );
};


const webStyle = {
    appBar: {
        backgroundColor: "#fff",
        display: 'flex',
        // alignItems: 'center',
        justifyContent: 'center'
    },
    queryPopUpText: {
        fontWeight: 500,
        color: 'rgba(33, 43, 54, 1)',
        fontFamily: 'SFProDisplay',
        fontSize: '20px',
        margin: '0px 15px',
    },
    NavLink: {
        textDecoration: "none",
        color: "inherit",
        fontSize: "inherit",
    },
    logoStyle: {
        height: "45px",
        width: 'auto',
    },
    linkName: {
        color: "#003e76",
        display: "block",
        margin: "10px 20px",
        textDecoration: "none",
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '15px',
        boxShadow: 'none'
    },
    sideNavlinkName: {
        color: "#003e76",
        display: "block",
        margin: "10px 20px",
        textDecoration: "none",
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '15px',
        boxShadow: 'none',
    },
    connect: {
        backgroundColor: "#F19049",
        padding: "10px 10px",
        borderRadius: "5px",
        height: '15px',
        paddingTop: '7px',
        margin: "5px",
        marginTop: '9px',
        fontWeight: 500,
        color: 'white'
    },
    profileBTN: {
        backgroundColor: "#5F9CF9",
        display: 'flex',
        padding: "0px 10px 0px 10px",
        borderRadius: "5px",
        height: '30px',
        margin: "5px 5px 5px 10px",
        color: 'white',
        fontFamily: 'SFProDisplay',
        fontWeight: 500,
        fontSize: '15px',
        alignItems: 'center'
    },
    arrowIcon: {
        fontSize: '35px',
        height: '35px',
        // marginTop: '-8px'
    },
    IconButton: {
        color: "rgb(4, 60, 116)",
    },
    tilesHeading: {
        margin: '11px 24px 11px 0',
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '28px',
        color: '#fff',
    },
    downloadButton: {
        width: '200px',
        height: '56px',
        margin: '0 0 0 24px',
        padding: '11px 34px',
        borderRadius: '28px',
        boxShadow: `0 2px 4px 0 rgba(4, 60, 116, 0.51)`,
        backgroundColor: '#ebf5ff',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '28px',
        color: '#043c74',
    }
};

const CustomGrid: any = withStyles({
    root: {
        "& .MuiList-padding": {
            paddingBottom: `0px !important`
        }
    }
})(Grid)


export default AppHeader;
