import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area Start
import {
    apiCall,
    authorizeEmployee,
    errorNotification,
    getHeaders,
    isAuthorisedUser,
    isLoggedIn,
    IUserdetails,
    warningNotification,
} from "../../../components/src/common";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    userDetailLabel: string;
    labelCustomerName: string;
    parentCustomerLabel: string;
    sapCodeLabel: string;
    customerTypeLabel: string;
    channelLabel: string;
    privacyPolicyLabel: string;
    selectedTab: number;
    ClassificationLabel: string;
    customerContactLabel: string;
    territoryLabel: string;
    ModeOfBusinessLabel: string;
    CurrencyLabel: string;
    websiteLabel: string;
    billingStreetLabel: string;
    shippingStreetLabel: string;
    billingCityLabel: string;
    shippingCityLabel: string;
    shippingPostalCodeLabel: string;
    billingPostalCodeLabel: string;
    billingStateLabel: string;
    shippingStateLabel: string;
    billingCountryLabel: string;
    shippingCountryLabel: string;
    accountManagerLabel: string;
    alternateEmailLabel: string;
    departmentList: any[];
    codeList: any[];
    firstName: string;
    lastName: string;
    companyName: string;
    emailId: string;
    phoneNumber: string;
    phoneCountryCode: string;
    agreedTermsAndCondition: boolean;
    address: string;
    department: number;
    designation: string;
    landlineNumber: string;
    areaOfBusiness: string;
    subsidiaryName: string;
    alternateEmail: string;
    isTermsCondition: boolean;
    isLoading: boolean;
    privacyPolicyText: string;
    termslabelText: string;
    termsLabel: string;
    bamEmailIdLabel: string;
    bamPhoneNoLabel: string;
    companyList: any[];
    role_id: number;
    employee_territory_id: number;
    created_at: any;
    updated_at: any;
    email: string;
    lastNameLabel: string;
    customerCompanyLabel: string;
    emailLabel: string;
    countryCodeLabel: string;
    phoneNumLabel: string;
    additionalDetailLabel: string;
    addressLabel: string;
    designationLabel: string;
    departmentLabel: string;
    landlinelabel: string;
    contactUniqueLabel: string;
    comments: string;
    allSalutations: [];
    country: string;
    countryLabel: string;
    contactUniqueId: string;
    selectedCompanyId: number;
    isCreatedPopup: boolean;
    createdCustomerPopupText: string;
    selectedCompanyObject: any;
    selectCompnayData: any;
    isReject: boolean;
    rejectPopupText: string;
    companyNotSameError: string;
    customer_id: string;
    companyNotSameErrorPopUp: boolean;
    phoneNumberPlaceholder: string;
    countryNamePlaceholder: string;
    completeAddressPlaceholder: string;
    countryPlaceHolder: string;
    noDataText: string;
    designationPlaceholder: string;
    landlineNumberPlaceholder: string;
    alternateEmailPlaceholder: string;
    rejectButtonText: string;
    approveButtonText: string;
    commentFieldLabel: string;
    noOptionMessage: string;
    customerNameSelectPlaceholder: string;
    noOptionMessageText: string;
    firstNamePlaceholder: string;
    lastNamePlaceholder: string;
    companyNamePlaceholder: string;
    emailIdPlaceholder: string;
    countryCodePlaceholder: string;
    addNewbuttonLabel: string;
    firstNameId: string;
    lastNameId: string;
    companyNameId: string;
    emailLabelId: string;
    countryCodeId: string;
    commentPlaceholder: string;
    CancelButtonText: string;
    yesButtonText: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class HandleSignupRequestController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    userDetails: IUserdetails;
    authToken: string;
    getDepartmentListApiCallId: string;
    getCompaniesListApiCallId: string;
    getSignupTicketDetailApiCallId: string;
    ticketId: string;
    approveRequestApiCallId: string;
    rejectRequestApiCallId: string;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            selectedCompanyId: 0,
            userDetailLabel: configJSON.userDetailLabel,
            createdCustomerPopupText: configJSON.createdCustomerPopupText,
            labelCustomerName: configJSON.labelCustomerName,
            parentCustomerLabel: configJSON.parentCustomerLabel,
            sapCodeLabel: configJSON.sapCodeLabel,
            customerTypeLabel: configJSON.customerTypeLabel,
            channelLabel: configJSON.channelLabel,
            privacyPolicyLabel: configJSON.privacyPolicyLabel,
            privacyPolicyText: configJSON.privacyPolicyText,
            termslabelText: configJSON.termslabelText,
            termsLabel: configJSON.termsLabel,
            selectedTab: 0,
            customer_id: "",
            ClassificationLabel: configJSON.ClassificationLabel,
            customerContactLabel: configJSON.customerContactLabel,
            ModeOfBusinessLabel: configJSON.ModeOfBusinessLabel,
            CurrencyLabel: configJSON.CurrencyLabel,
            websiteLabel: configJSON.websiteLabel,
            billingStreetLabel: configJSON.billingStreetLabel,
            shippingStreetLabel: configJSON.shippingStreetLabel,
            billingCityLabel: configJSON.billingCityLabel,
            shippingCityLabel: configJSON.shippingCityLabel,
            billingPostalCodeLabel: configJSON.billingPostalCodeLabel,
            shippingPostalCodeLabel: configJSON.shippingPostalCodeLabel,
            billingStateLabel: configJSON.billingStateLabel,
            shippingStateLabel: configJSON.shippingStateLabel,
            billingCountryLabel: configJSON.billingCountryLabel,
            shippingCountryLabel: configJSON.shippingCountryLabel,
            accountManagerLabel: configJSON.accountManagerLabel,
            alternateEmailLabel: configJSON.alternateEmailLabel,
            countryLabel: configJSON.countryLabel,
            departmentList: [],
            codeList: [],
            firstName: "",
            lastName: "",
            companyName: "",
            emailId: "",
            country: "India",
            phoneCountryCode: "91",
            companyList: [],
            phoneNumber: "",
            agreedTermsAndCondition: true,
            address: "",
            department: 0,
            designation: "",
            landlineNumber: "",
            areaOfBusiness: "",
            subsidiaryName: "",
            alternateEmail: "",
            contactUniqueId: "",
            isTermsCondition: true,
            isLoading: false,
            bamEmailIdLabel: configJSON.bamEmailIdLabel,
            bamPhoneNoLabel: configJSON.bamPhoneNoLabel,
            territoryLabel: configJSON.territoryLabel,
            role_id: 0,
            employee_territory_id: 0,
            created_at: '',
            updated_at: '',
            email: '',
            isReject: false,
            lastNameLabel: configJSON.lastnameLabel,
            customerCompanyLabel: configJSON.companynameLabel,
            emailLabel: configJSON.emailLabel,
            countryCodeLabel: configJSON.countryCodeLabel,
            phoneNumLabel: configJSON.phoneNumLabel,
            additionalDetailLabel: configJSON.additionalDetailLabel,
            addressLabel: configJSON.addressLabel,
            designationLabel: configJSON.designationLabel,
            departmentLabel: configJSON.departmentLabel,
            landlinelabel: configJSON.landlinelabel,
            contactUniqueLabel: configJSON.contactUniqueLabel,
            comments: '',
            allSalutations: [],
            isCreatedPopup: false,
            selectedCompanyObject: null,
            selectCompnayData: "",
            companyNotSameErrorPopUp: false,
            rejectPopupText: configJSON.rejectPopupText,
            companyNotSameError: configJSON.companyNotSameError,
            phoneNumberPlaceholder: configJSON.phoneNumberPlaceholder,
            countryNamePlaceholder: configJSON.countryNamePlaceholder,
            completeAddressPlaceholder: configJSON.completeAddressPlaceholder,
            countryPlaceHolder: configJSON.countryPlaceHolder,
            noDataText: configJSON.noDataText,
            designationPlaceholder: configJSON.designationPlaceholder,
            landlineNumberPlaceholder: configJSON.landlineNumberPlaceholder,
            alternateEmailPlaceholder: configJSON.alternateEmailPlaceholder,
            rejectButtonText: configJSON.rejectButtonText,
            approveButtonText: configJSON.approveButtonText,
            commentFieldLabel: configJSON.commentFieldLabel,
            noOptionMessage: configJSON.noOptionMessage,
            customerNameSelectPlaceholder: configJSON.customerNameSelectPlaceholder,
            noOptionMessageText: configJSON.noOptionMessageText,
            firstNamePlaceholder: configJSON.firstNamePlaceholder,
            lastNamePlaceholder: configJSON.lastNamePlaceholder,
            companyNamePlaceholder: configJSON.companyNamePlaceholder,
            emailIdPlaceholder: configJSON.emailIdPlaceholder,
            countryCodePlaceholder: configJSON.countryCodePlaceholder,
            addNewbuttonLabel: configJSON.addNewbuttonLabel,
            firstNameId: configJSON.firstNameId,
            lastNameId: configJSON.lastNameId,
            companyNameId: configJSON.companyName,
            emailLabelId: configJSON.emailLabelId,
            countryCodeId: configJSON.countryCode,
            commentPlaceholder: configJSON.commentPlaceholder,
            CancelButtonText: configJSON.CancelButtonText,
            yesButtonText: configJSON.yesButtonText,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.setIsReject = this.setIsReject.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleIsCreatedClose = this.handleIsCreatedClose.bind(this);
        this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
        this.handleChangeTab = this.handleChangeTab.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handlePhoneCountryCode = this.handlePhoneCountryCode.bind(this);
        this.validateSignupRequest = this.validateSignupRequest.bind(this);
        this.setCompaniesList = this.setCompaniesList.bind(this);
        this.handleSignupApproval = this.handleSignupApproval.bind(this);
        this.handleSignupApproval = this.handleSignupApproval.bind(this);
        this.handleSignupReject = this.handleSignupReject.bind(this);
        this.handleSignupReject = this.handleSignupReject.bind(this);
        this.handleIsCreatedClose = this.handleIsCreatedClose.bind(this);
        this.companyErrorPopUpClose = this.companyErrorPopUpClose.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleDepartmentChange = this.handleDepartmentChange.bind(this);
        this.handlePhoneCountryCode = this.handlePhoneCountryCode.bind(this);
        this.handleSinupOk = this.handleSinupOk.bind(this);
        this.getSignupTicketDetail = this.getSignupTicketDetail.bind(this);
        this.getCompaniesList = this.getCompaniesList.bind(this);
        this.getDepartmentList = this.getDepartmentList.bind(this);
        this.setInputValue = this.setInputValue.bind(this);
        this.setEnableField = this.setEnableField.bind(this);
        this.doButtonPressed = this.doButtonPressed.bind(this);
        this.handleSignupReject = this.handleSignupReject.bind(this);
        this.setCompaniesList = this.setCompaniesList.bind(this);
        this.setDepartmentList = this.setDepartmentList.bind(this);
        this.setTicketDetails = this.setTicketDetails.bind(this);
        this.approveSignupResponse = this.approveSignupResponse.bind(this);
        this.rejestRequestRes = this.rejestRequestRes.bind(this);
        this.getAllDataApiCalls = this.getAllDataApiCalls.bind(this);
        this.getAllDataApiCalls = this.getAllDataApiCalls.bind(this);
        this.signupRequestActionAPICall = this.signupRequestActionAPICall.bind(this);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
            let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

            this.showAlert(
                "Change Value",
                "From: " + this.state.txtSavedValue + " To: " + value
            );

            this.setState({ txtSavedValue: value });
        }

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId && responseJson) {
                this.setState({isLoading: false})
                isAuthorisedUser(responseJson, this.props.navigation);
                this.getAllDataApiCalls(apiRequestCallId, responseJson)
                this.signupRequestActionAPICall(apiRequestCallId, responseJson)
            }
        }
        // Customizable Area End
    }

    txtInputWebProps = {
        onChangeText: (text: string) => {
          this.setState({ txtInputValue: text });
        },
        secureTextEntry: false
      };
    
      txtInputMobileProps = {
        ...this.txtInputWebProps,
        autoCompleteType: "email",
        keyboardType: "email-address"
      };
    
      txtInputProps = this.isPlatformWeb()
        ? this.txtInputWebProps
        : this.txtInputMobileProps;

    btnShowHideProps = {
        onPress: () => {
            this.setState({ enableField: !this.state.enableField });
            this.txtInputProps.secureTextEntry = !this.state.enableField;
            this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
                ? imgPasswordVisible
                : imgPasswordInVisible;
        }
    };

    btnShowHideImageProps = {
        source: this.txtInputProps.secureTextEntry
          ? imgPasswordVisible
          : imgPasswordInVisible
      };
    
    btnExampleProps = {
        onPress: () => this.doButtonPressed()
    };

    doButtonPressed = () => {
        let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
        msg.addData(
            getName(MessageEnum.AuthTokenDataMessage),
            this.state.txtInputValue
        );
        this.send(msg);
    }

    // web events
    setInputValue = (text: string) => {
        this.setState({ txtInputValue: text });
    };

    setEnableField = () => {
        this.setState({ enableField: !this.state.enableField });
    };

    // Customizable Area Start
    signupRequestActionAPICall = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.approveRequestApiCallId) {
            this.approveSignupResponse(responseJson)
        }

        if (apiRequestCallId === this.rejectRequestApiCallId) {
           this.rejestRequestRes(responseJson)
        }
    }

    getAllDataApiCalls = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.getDepartmentListApiCallId) {
            this.setDepartmentList(responseJson)
         }

        if (apiRequestCallId === this.getCompaniesListApiCallId) {
             this.setCompaniesList(responseJson)
        }

        if (apiRequestCallId === this.getSignupTicketDetailApiCallId) {
            this.setTicketDetails(responseJson);
        }
    }
    setTicketDetails = (responseJson: any) => {
        if (!responseJson.error && !responseJson.errors) {
            const ticketDetail = responseJson.tickets
            const selectedCompanyObject = {
                id: ticketDetail.company_id ? ticketDetail.company_id : 0,
            }
            this.setState({
                firstName: ticketDetail.first_name,
                lastName: ticketDetail.last_name,
                companyName: ticketDetail.company_name,
                email: ticketDetail.email,
                phoneNumber: ticketDetail.mobile_number,
                phoneCountryCode: String(ticketDetail.country_code),
                country: ticketDetail.country,
                address: ticketDetail.address || '',
                designation: ticketDetail.designation || '',
                landlineNumber: ticketDetail.landline || '',
                department: ticketDetail.department_id || 0,
                alternateEmail: ticketDetail.alternate_email || '',
                contactUniqueId: ticketDetail.contact_unique_id,
                selectedCompanyId: ticketDetail.company_id,
            })
            this.setState({selectedCompanyObject: selectedCompanyObject})
        }
    }

    rejestRequestRes = (responseJson: any) => {
        if (!responseJson.error && !responseJson.errors) {
            this.setState({
                isCreatedPopup: true
            })
        }
    }
    approveSignupResponse = (responseJson: any) => {
        if (!responseJson.error && !responseJson.errors) {
            this.setState({
                isCreatedPopup: true,
                customer_id: responseJson.contact_unique_id
            })
        }
    }

    setCompaniesList = (responseJson: any) => {
        if (!responseJson.error && !responseJson.errors) {
            const companies = responseJson.all_companies.map((company: any) => {
                return {
                    label: company.customer_name,
                    id: company.id
                }
            })
            this.setState({
                companyList: companies
            })
        }
    }

    setDepartmentList = (responseJson: any) => {
        if (!responseJson.error && !responseJson.errors) {
            this.setState({
                departmentList: responseJson.department
            })
        }
    }

    async componentDidMount() {
        const authToken = isLoggedIn(this.props.navigation);
        authorizeEmployee(this.props.navigation);
        if (typeof (authToken) === "string") {
            this.authToken = authToken;
            const ticketId = this.props.navigation.getParam("id");
            this.ticketId = ticketId;
            this.getSignupTicketDetail();
            this.getCompaniesList();
            this.getDepartmentList();
        }
    }

    handleChange = (prop: any) => (event: any) => {
        if (prop === 'firstName' || prop === 'lastName') {
            const regEx = /^[a-zA-Z][a-z\s]*$/;
            if (event?.target?.value?.length === 0) {
                this.setState({ ...this.state, [prop]: event?.target?.value });
                return true;
            }
            if (event?.target?.value.match(regEx)) {
                this.setState({ ...this.state, [prop]: event?.target?.value });
                return true;
            } else {
                return false;
            }
        } else if (prop === 'phoneNumber' || prop === "landlineNumber") {
            const regEx2 = /^[0-9\b]+$/;
            if (event.target.value.length === 0) {
                this.setState({ ...this.state, [prop]: event.target.value });
                return true;
            }
            if (event.target.value.match(regEx2)) {
                this.setState({ ...this.state, [prop]: event.target.value });
                return true;
            } else {
                return false;
            }
        } else {
            this.setState({ ...this.state, [prop]: event.target.value });
        }
    };

    handlePhoneCountryCode(event: any, value: any, data: any) {
        const filtered = data.find((country: any) => country.id === event.value)
        if (value) {
            this.setState({
                phoneCountryCode: event.value,
                country: filtered?.value
            });
        }
    }


    handleDepartmentChange = (event: any, value: any) => {
        this.setState({
            department: value?.props?.value,
        });
    };

    getDepartmentList = () => {
        const header = getHeaders({ token: this.authToken });

        const getDepartments = apiCall({
            httpBody: {},
            header: header,
            url: configJSON.getDepartsmentListApiEndpoint,
            httpMethod: configJSON.getDepartmentListApiMethod,
        });

        this.getDepartmentListApiCallId = getDepartments.messageId;
        runEngine.sendMessage(getDepartments.id, getDepartments);
    };


    getCompaniesList = () => {
        const header = getHeaders({ token: this.authToken });

        const companies = apiCall({
            httpBody: {},
            header: header,
            url: configJSON.getCompaniesListApiEndPoint,
            httpMethod: configJSON.getCompaniesListApiMethod,
        });

        this.getCompaniesListApiCallId = companies.messageId;
        runEngine.sendMessage(companies.id, companies);
    };


    getSignupTicketDetail = () => {
        this.setState({isLoading: true});
        const getDetail = apiCall({
            httpBody: {},
            header: getHeaders({ token: this.authToken }),
            url: configJSON.getSignupRequestApiEndpoint + `?ticket_id=${this.ticketId}`,
            httpMethod: configJSON.getSignupRequestApiMethod
        });

        this.getSignupTicketDetailApiCallId = getDetail.messageId;
        runEngine.sendMessage(getDetail.id, getDetail);
    }

    handleCompanyChange = (event: any, value: any) => {
        if (event.id !== 0) {
            this.setState({
                selectedCompanyObject: event,
            })
        } else if (event?.label === "" || ( this.state.companyName && event?.label !== this.state.companyName)) {
            this.setState({
                selectedCompanyObject: {
                    id: 0
                },
            })
            if (this.state.selectedCompanyId !== null) {
                this.setState({
                    selectedCompanyId: event?.id,
                })
            }
        }
        this.setState({
            selectCompnayData: event?.label,
        });
    }

    companyErrorPopUpClose = () => {
        this.setState({
            companyNotSameErrorPopUp: false
        })
    }
    
    validateSignupRequest = () => {
        if (this.state.selectCompnayData === "") {
            warningNotification(configJSON.companyError);
            return false;
        }

        if (!RegExp(configJSON.emailRegexExp).exec(String(this.state.email)
            .toLowerCase())) {
            warningNotification(configJSON.emailError);
            return false;
        }

        if (this.state.comments.trim().length === 0) {
            return false;
        }

        if (this.state.alternateEmail.trim().length > 0 && !RegExp(configJSON.emailRegexExp).exec(String(this.state.alternateEmail)
            .toLowerCase())) {
            errorNotification(configJSON.emailError);
            return false;
        }
        return true;
    }

    handleSinupOk = () => {
        this.setState({isLoading: true});
        this.setState({companyNotSameErrorPopUp: false})
        const httpBody = {
            ticket_id: this.ticketId,
            comments: this.state.comments,
            customer_name: this.state.selectCompnayData,
            customer_profile_attributes: {
                address: this.state.address,
                department_id: this.state.department,
                designation: this.state.designation,
                landline: this.state.landlineNumber,
                alternate_email: this.state.alternateEmail,
                company_id: this.state.selectedCompanyId,
            }
        };
        const approve = apiCall({
            httpBody,
            httpMethod: configJSON.approveSignupRequestApiMethod,
            url: configJSON.approveSignupRequestApiEndpoint,
            header: getHeaders({ token: this.authToken })
        });
        this.approveRequestApiCallId = approve.messageId;
        runEngine.sendMessage(approve.id, approve);
    }

    handleSignupApproval = () => {
        const isValid = this.validateSignupRequest();
        if (!isValid) {
            return false;
        } else if (this.state.selectedCompanyId !== null && this.state.companyName !== null && this.state.selectedCompanyObject.id === 0) {
            errorNotification(`${this.state.selectCompnayData} do not exist in customer master`)
        } else if (this.state.selectedCompanyId !== null && this.state.companyName !== null && this.state.selectCompnayData !== this.state.companyName) {
            this.setState({companyNotSameErrorPopUp: true})
        } else if (this.state.selectedCompanyId === null && this.state.companyName !== null && this.state.selectedCompanyObject.id === 0) {
            errorNotification(`${this.state.selectCompnayData} do not exist in customer master`)
        } else {
            this.handleSinupOk()
        }
    }

    handleSignupReject = () => {
        if (this.state.comments.trim().length === 0) {
            return false;
        }
        this.setState({isLoading: true});
        const httpBody = {
            ticket_id: this.ticketId,
            comments: this.state.comments,
            customer_name: this.state.companyName,
            customer_profile_attributes: {
                address: this.state.address,
                department_id: this.state.department,
                designation: this.state.designation,
                landline: this.state.landlineNumber,
                alternate_email: this.state.alternateEmail
            }
        };
        this.setIsReject()
        const reject = apiCall({
            httpBody,
            httpMethod: configJSON.rejectSignupRequestApiMethod,
            url: configJSON.rejectSignupRequestApiEndpoint,
            header: getHeaders({ token: this.authToken })
        });

        this.rejectRequestApiCallId = reject.messageId;
        runEngine.sendMessage(reject.id, reject);

    }

    setIsReject = () => {
        this.setState({isReject: true});
    }

    handleIsCreatedClose = () => {
        this.props.navigation.navigate("TicketList");
    }

    handleAddNewClick = () => {
        if (this.props.navigation) {
            this.props.navigation.navigate("CustomerAdd");
        }
    }

    handleFormSubmit = (e: any) => {
       e.preventDefault()
    }

    handleChangeTab = (event: any, newValue: any) => {
        this.setState({ selectedTab: newValue });
    };

    isStringNullOrBlank(str: string) {
        return str === null || str.trim().length === 0;
    }

    // Customizable Area End
}