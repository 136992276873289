import React from "react";

// Customizable Area Start
import {
    Container,
    Box,
    Button,
    InputLabel,
    Typography,
    Grid,
    TextField,
    Select,
    MenuItem,
    FormControl,
    FormControlLabel,
    Switch,
    Tab,
    Tabs,
    Card,
    CardContent,
    InputAdornment,
    IconButton,
} from "@material-ui/core";
import {
    withStyles,
} from "@material-ui/core/styles";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector";
import AppHeader from "../../../components/src/EmployeeAppHeader.web";
import AppFooter from "../../../components/src/AppFooter.web";
import SwitchConfirmationPopup from "./SwitchConfirmationPopup";
import EditIcon from '@material-ui/icons/Edit';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CalendarMonthIcon from '@material-ui/icons/CalendarTodayOutlined';
import CustomPagination from "../../../components/src/CustomPagination.web";
import { NavLink as RLink } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import moment from "moment";

function TabPanel(props: {
    [x: string]: any;
    children: any;
    value: any;
    index: any;
}) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <CustomBox sx={{ p: 3 }}>{children}</CustomBox>}
        </div>
    );
}
// Customizable Area End
import CustomerContactAddController, { Props } from "./userDetailsController";
import { CustomGrid } from "../../../components/src/common";
import Loader from "../../../components/src/Loader.web";
import CompanyNameSelector from "../../../components/src/CompanyNameSelectorAutocomplete";

export class CustomerContactAdd extends CustomerContactAddController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    companyListSelect = () => {
        const { classes } = this.props
        return (
            <Grid item lg={6} md={6} sm={6} xs={12} style={webStyle.grids}>
                <InputLabel htmlFor="departmentLabel" style={webStyle.inputLabel}>
                    {this.state.departmentLabel}
                </InputLabel>
                <SelectField>
                    <CustomSelect
                        labelId="departmentLabel"
                        data-testid="department"
                        displayEmpty
                        id="departmentLabel"
                        variant="outlined"
                        fullWidth
                        value={this.state.department}
                        {...this.getDisabled(this.isUpdate && this.state.isOnlyAdditionalView)}
                        style={webStyle.select}
                        onChange={this.handleDepartmentChange.bind(this)}
                        defaultValue={""}
                        disabled={this.isUpdate && this.state.isOnlyAdditionalView}
                    >
                        <MenuItem value={0} className={classes?.selectPlaceholder} >
                            <em style={webStyle.countryPlaceHolder}>
                                {this.state.chooseDepartmentText}
                            </em>
                        </MenuItem>
                        {this.state.departmentList.map((data) => {
                            return (
                                <MenuItem value={data.id} key={data.id}>
                                    {data.name}
                                </MenuItem>
                            );
                        })}
                        {this.state.departmentList.length === 0 && (
                            <MenuItem value="noData" disabled>
                                {this.state.noDataText}
                            </MenuItem>
                        )}
                    </CustomSelect>
                </SelectField>
            </Grid>
        )
    }
    perSonalDetails() {
        const { classes } = this.props
        return (
            <>
                <Grid container spacing={7} alignItems="center" >
                    <Grid container spacing={2} item lg={6} md={6} sm={6} xs={12}
                        style={{ ...webStyle.grids, ...webStyle.paddingRight }}>
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                            <InputLabel htmlFor="firstName" style={webStyle.inputLabel}>
                                {this.state.parentCustomerLabel}<span style={webStyle.errorText}>*</span>
                            </InputLabel>
                            <SelectField>
                                <CustomSelect
                                    labelId="departmentLabel"
                                    id="departmentLabel"
                                    data-testid="salutions"
                                    variant="outlined"
                                    fullWidth
                                    value={this.state.selectedSolutation}
                                    style={webStyle.select}
                                    placeholder={this.state.solutationText}
                                    onChange={this.handleSolutationChange.bind(this)}
                                    defaultValue={0}
                                    {...this.getDisabled(this.isUpdate)}
                                >
                                    <MenuItem value={0}
                                        disabled
                                        className={classes?.selectPlaceholder}>
                                        <em style={webStyle.countryPlaceHolder}>
                                            {this.state.chooseSolutionText}
                                        </em>
                                    </MenuItem>
                                    {this.state.allSalutations.map((data: any) => {
                                        return (
                                            <MenuItem value={data.id} key={data.id}>
                                                {data.name}
                                            </MenuItem>
                                        );
                                    })}
                                    {this.state.departmentList.length === 0 && (
                                        <MenuItem value="noData" disabled>
                                            {this.state.noDataText}
                                        </MenuItem>
                                    )}
                                </CustomSelect>
                            </SelectField>
                        </Grid>
                        <Grid item lg={9} md={6} sm={6} xs={12} className={classes?.firstName}>
                            <InputField
                                id="firstName"
                                data-testid="firstname"
                                variant="outlined"
                                value={this.state.firstName}
                                onChange={this.handleChange("firstName").bind(this)}
                                required
                                placeholder={this.state.firstNamePlaceholder}
                                fullWidth
                                disabled={this.isUpdate}
                            />
                        </Grid>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12} style={webStyle.grids} className={classes?.lastName}>
                        <InputLabel htmlFor="lastName" style={webStyle.inputLabel}>
                            {this.state.lastNameLabel}<span style={webStyle.errorText}>*</span>
                        </InputLabel>
                        <InputField
                            id="lastName"
                            data-testid="lastName"
                            variant="outlined"
                            value={this.state.lastName}
                            required
                            onChange={this.handleChange("lastName").bind(this)}
                            placeholder={this.state.lastNamePlaceholder}
                            fullWidth
                            disabled={this.isUpdate}
                        />
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12} style={{ ...webStyle.grids, ...webStyle.companyNameGrid }}>
                        <InputLabel htmlFor="companyName" style={{ ...webStyle.inputLabel, ...webStyle.companyNameLabel }}>
                            {this.state.customerCompanyLabel}<span style={webStyle.errorText}>*</span>
                        </InputLabel>
                        {this.state.selected_company_id || !this.isUpdate ? (
                            <CompanyNameSelector
                                data-testid="company"
                                isClearable={true}
                                dataSource={this.state.allCompanies}
                                selectedValue={this.state.selected_company_id}
                                handleChange={this.handleCompanyChange}
                                isDisabled={this.isUpdate}
                                placeHolder={this.state.chooseCompanyPlaceholder}
                                style={{...webStyle.select, noOptionsMessage: (base: any, state: any) => ({...base,})}}
                                isCustomFilter={true}
                            />
                        ) :
                        (
                            <InputField
                                id="companyName"
                                variant="outlined"
                                value={this.state.selectedCompanyName}
                                disabled={true}
                                required
                                placeholder={this.state.chooseCompanyPlaceholder}
                                fullWidth
                            />
                        )}
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="emailLabel" style={webStyle.inputLabel}>
                            {this.state.emailLabel}<span style={webStyle.errorText}>*</span>
                        </InputLabel>
                        <InputField
                            id="emailLabel"
                            data-testid="email"
                            variant="outlined"
                            value={this.state.email}
                            disabled={this.isUpdate}
                            required
                            onChange={this.handleChange("email").bind(this)}
                            placeholder={this.state.emailIdPlaceholder}
                            fullWidth
                        />
                    </Grid>
                    <Grid container item spacing={2} lg={6} md={6} sm={6} xs={12}
                        style={{ ...webStyle.grids, ...webStyle.paddingRight }}
                        className={this.state.isLoading === true ? 'countryCodeIndex' : 'countryCodeIndex2'}
                    >
                        <CustomGrid item lg={4} md={6} sm={12} xs={12}>
                            <InputLabel htmlFor="bamPhoneNo" style={webStyle.inputLabel}>
                                {this.state.countryCodeLabel}
                            </InputLabel>
                            <CountryCodeSelector
                                navigation={""}
                                style={{ }}
                                id="countryCode"
                                data-testid="countryCode"
                                disable={this.isUpdate}
                                allowPropChange={false}
                                onChange={this.handlePhoneCountryCode}
                                value={this.state.phoneCountryCode}
                                placeHolder={this.state.countryCodePlaceholder}
                            />
                        </CustomGrid>
                        <Grid item lg={8} md={6} sm={12} xs={12} className={classes?.countryCode}>
                            <InputField
                                id="phoneNumber"
                                data-testid="phoneNumber"
                                variant="outlined"
                                inputMode="numeric"
                                inputProps={{
                                    maxLength: 14,
                                }}
                                type="tel"
                                value={this.state.phoneNumber}
                                onChange={this.handleChange("phoneNumber").bind(this)}
                                required
                                placeholder={this.state.phoneNumberPlaceholder}
                                fullWidth
                                disabled={this.isUpdate}
                            />
                        </Grid>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12} style={webStyle.grids} className={classes?.country}>
                        <InputLabel htmlFor="mobileNum" style={webStyle.inputLabel}>
                            {this.state.countryLabelText}<span style={webStyle.errorText}>*</span>
                        </InputLabel>
                        <InputField
                            id="country"
                            data-testid="country"
                            variant="outlined"
                            value={this.state.country}
                            onChange={this.handleChange("country").bind(this)}
                            required
                            placeholder={this.state.countryNamePlaceholder}
                            fullWidth
                            disabled={this.isUpdate}
                        />
                    </Grid>
                    <Grid item md={12} sm={12} xs={12} style={webStyle.additionalDetailsGrid}>
                        <Typography
                            style={webStyle.detailTitle}>
                            {this.state.additionalDetailLabel}
                            {this.state.isOnlyView && !location.href.includes("Add") &&
                                (<EditIcon style={webStyle.editIcon} onClick={this.handleAdditionalEditUserDetails} />)
                            }
                        </Typography>
                    </Grid>

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="address" style={webStyle.inputLabel}>
                            {this.state.addressLabel}
                        </InputLabel>
                        <InputField
                            id="address"
                            variant="outlined"
                            value={this.state.address}
                            onChange={this.handleChange("address").bind(this)}
                            placeholder={this.state.completeaddressPlaceholder}
                            fullWidth
                            disabled={this.isUpdate && this.state.isOnlyAdditionalView}
                        />
                    </Grid>

                    {this.companyListSelect()}

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="designationLabel" style={webStyle.inputLabel}>
                            {this.state.designationLabel}
                        </InputLabel>
                        <InputField
                            id="designationLabel"
                            variant="outlined"
                            value={this.state.designation}
                            onChange={this.handleChange("designation").bind(this)}
                            placeholder={this.state.designationPlaceholder}
                            fullWidth
                            disabled={this.isUpdate && this.state.isOnlyAdditionalView}
                        />
                    </Grid>

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor={this.state.landlinelabelIdText} style={webStyle.inputLabel}>
                            {this.state.landlinelabel}
                        </InputLabel>
                        <InputField
                            id={this.state.landlinelabelIdText}
                            variant="outlined"
                            value={this.state.landlineNumber}
                            onChange={this.handleChange("landlineNumber").bind(this)}
                            placeholder={this.state.landlineNumberPlaceholder}
                            fullWidth
                            disabled={this.isUpdate && this.state.isOnlyAdditionalView}
                        />
                    </Grid>

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor={this.state.alternateEmailLabelIdText} style={webStyle.inputLabel}>
                            {this.state.alternateEmailLabel}
                        </InputLabel>
                        <InputField
                            id={this.state.alternateEmailLabelIdText}
                            variant="outlined"
                            value={this.state.alternateEmail}
                            onChange={this.handleChange("alternateEmail").bind(this)}
                            placeholder={this.state.alternateEmailIdPlaceholder}
                            fullWidth
                            disabled={this.isUpdate && this.state.isOnlyAdditionalView}
                        />
                    </Grid>

                    {this.isUpdate && <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor={this.state.contactUniqueLabelIdText} style={webStyle.inputLabel}>
                            {this.state.contactUniqueLabel}
                        </InputLabel>
                        <InputField
                            id={this.state.contactUniqueLabelIdText}
                            variant="outlined"
                            value={this.state.contactUniqueId}
                            placeholder={this.state.uniqueIdPlaceholder}
                            disabled
                            fullWidth
                        />
                    </Grid>}
                </Grid>

                <Grid container style={webStyle.switchGrid}>
                    <Grid item xs={12} style={webStyle.switchMainGrid}>
                        <Grid>
                            <SwitchConfirmationPopup
                                data-testid="popup"
                                isOpen={this.state.isPopup}
                                accStatus={this.state.activated}
                                handleActiveYes={this.handleActiveYesClick}
                                handleActiveNo={this.handleActiveNoClick}
                                handleCloseIcon={this.handleCloseIcon}
                                handleDeactiveYesClick={this.handleDeactiveYesClick}
                                handleDeactiveNoClick={this.handleDeactiveNoClick}
                            />
                            <FormControlLabel
                                data-testid="switch"
                                control={<IOSSwitch
                                    checked={this.state.activated === true}
                                    onClick={this.handleSwitchClick}
                                    disabled={!this.state.isOnlyView}
                                />
                                }
                                label={this.state.activated === true ? this.state.activeOptionText : this.state.InactiveOptionText}
                            />
                        </Grid>
                    </Grid>
                    {
                        ((this.isUpdate && !this.state.isOnlyAdditionalView) || location.href.includes("Add")) && (
                            <Grid>
                                <SaveButton type="submit" variant="contained" style={webStyle.saveBtnMargin}>
                                    {this.state.saveButtonText}
                                </SaveButton>
                            </Grid>
                        )
                    }
                </Grid>
            </>
        );
    };

    Invoices() {
        return (
            <>
                <Grid container justifyContent="space-between">
                    <Grid item lg={3} md={3} sm={4} xs={10} style={webStyle.invoicesGrid}>
                        <InputField
                            id={this.state.searchFolderIdText}
                            data-testid="searchinvoice"
                            variant="outlined"
                            onChange={this.handleSearchInvoices}
                            placeholder={this.state.searchInvoicePlaceholder}
                            fullWidth
                            InputProps={{
                                startAdornment:
                                    <InputAdornment position="start">
                                        <IconButton
                                            edge="end"
                                        >
                                            <SearchIcon style={webStyle.searchIcon}></SearchIcon>
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    </Grid>
                    {
                        localStorage.getItem("employee_invoice_permission") === "true" && (
                            <EditButton onClick={() => this.handleInvoiceAddNewClickCustomer()} variant="contained" style={webStyle.invoiceEditIcon} data-testid="addnewinvoice">
                                {this.state.addNewButtonText}
                            </EditButton>
                        )
                    }
                </Grid>
                <CustomBox>
                    {!this.state.isLoading ?
                        <Grid container spacing={3} style={webStyle.invoiceGrid}>
                            {this.state.allcontectInvioce && this.state.allcontectInvioce.map((data: any) => {
                                return (
                                    <Grid item lg={3} md={4} sm={6} style={webStyle.RlinkStyle} key={data.id}>
                                        <RLink
                                            to={`/AddContactInvoice/edit/${data.attributes.customer_master_id}/${data.id}`}
                                            exact={true}
                                        >
                                            <CustomCard>
                                                <CardContent>
                                                    <Grid container justifyContent="space-between">
                                                        <Typography style={webStyle.customerName}>
                                                            {data.attributes.customer_invoice_catalogue} -
                                                            <span style={webStyle.invoiceNumber}>
                                                                {data.attributes.invoice}
                                                            </span>
                                                        </Typography>
                                                        <Typography style={webStyle.creationDate}>
                                                            {moment(data.attributes.invoice_created_date, "DD/MM/YYYY").format("DD-MM-YYYY")}
                                                        </Typography>
                                                    </Grid>
                                                    <Typography style={webStyle.alignItems}>
                                                        <MailOutlineIcon style={webStyle.detailsIcon} />
                                                        <Typography component="span" style={webStyle.emailText}>
                                                            {data.attributes.customer_invoice_person}
                                                        </Typography>
                                                    </Typography>
                                                    <Typography align="left" style={webStyle.alignItems}>
                                                        <CalendarMonthIcon style={webStyle.detailsIcon} />
                                                        <Typography component="span" style={webStyle.emailText}>
                                                            {data.attributes.invoice_date}
                                                        </Typography>
                                                    </Typography>
                                                </CardContent>
                                            </CustomCard>
                                        </RLink>
                                    </Grid>
                                )
                            })}
                            {this.state.allcontectInvioce.length === 0 &&<span style={webStyle.noInvoiceDataText}>{this.state.noInvoiceData}</span>}
                        </Grid> : <Loader loading={this.state.isLoading} />}
                    {this.state.allcontectInvioce.length !== 0 && <Grid item xs={12} sm={12} lg={12} style={webStyle.paginationGrid}>
                        <CustomPagination
                            data-testid="customepaginaion"
                            per_page={this.state.perPageRecord}
                            page={this.state.contactInvoiceListingMeta?.pagination_details.current_page}
                            count={this.state.contactInvoiceListingMeta?.pagination_details.total_pages}
                            arrayLength={this.state.contactInvoiceListingMeta?.pagination_details.total_count}
                            handlePageChange={(e: any, p: any) => this.handlePageChange(e, p)}
                        />
                    </Grid>}
                </CustomBox>
            </>
        )
    }

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <AppHeader navigation={this.props.navigation} />
                <MainContainer>
                    <Loader loading={this.state.isLoading} />
                    <Grid container justifyContent="space-between">
                        <KeyboardBackspaceIcon
                            onClick={() => this.props.navigation.goBack()}
                            style={webStyle.backIcon}
                        />
                        {
                            this.state.isResetPasswordPermission && (
                                <SaveButton variant="contained" style={webStyle.resetPasswordBtn} onClick={() => this.sendResetPasswordLink()}>{this.state.resetPasswordBtnText}</SaveButton>
                            )
                        }
                    </Grid>
                    <CustomTabs
                        onChange={this.handleChangeTab}
                        value={this.state.selectedTab}
                        aria-label={this.state.tabsLabelText}
                        className="tabs-wrapper"
                        data-testid="tabs"
                    >
                        <CustomTab
                            label={this.state.userDetailsTabPanel}
                            style={this.state.isViewInvoice ? webStyle.Tab1 : webStyle.tab4}
                            disableRipple
                        />
                        {
                            this.state.isViewInvoice && (
                                <CustomTab
                                    label={this.state.invoicesTabLabel}
                                    disabled={!this.userId}
                                    style={webStyle.tab3}
                                    disableRipple
                                />
                            )
                        }
                    </CustomTabs>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault()
                            this.onSaveUserDetails(this.state.activated)
                        }}
                        data-testid="userDetailsForm"
                    >
                        <TabPanel value={this.state.selectedTab} index={0}>
                            {this.perSonalDetails()}
                        </TabPanel>
                        <TabPanel value={this.state.selectedTab} index={1}>
                            {this.Invoices()}
                        </TabPanel>
                    </form>
                </MainContainer>
                <AppFooter isLoading={this.state.isLoading} />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle = {
    resetPasswordBtn: {
        width: 'auto',
        fontSize: '16px',
        height: '35px',
    },
    paginationGrid: {
        marginTop: "10px"
    },
    noInvoiceDataText: {
        marginLeft: "15px", 
        transform: "translate(0px, -25px)", 
        marginTop: '5%', 
        paddingBottom: '15%', 
        fontFamily: "SFProDisplay"
    },
    detailsIcon: {
        fill: '#1D3D66'
    },
    alignItems: {
        display: 'flex',
        alignItems: 'center',
    },
    invoiceNumber: {
        fontWeight: 400, 
        color: "#6a6ea5"
    },
    RlinkStyle: {
        width: 'inherit' 
    },
    backIcon: {
        paddingLeft: '20px',
        cursor: 'pointer'
    },
    invoiceGrid: {
        marginTop: '20px'
    },
    invoiceEditIcon: {
        float: 'right', 
        marginButtom: '30px'
    },
    searchIcon: {
        fill: 'rgb(4, 60, 116)', 
        fontSize: '20px'
    },
    invoicesGrid: {
        marginTop: '-5px'
    },
    saveBtnMargin: {
        marginRight: '10px'
    },
    switchMainGrid: {
        margin: "0px 0px 20px 0px"
    },
    switchGrid: {
        marginTop: '56px'
    },
    editIcon: {
        backgroundColor: "transparent",
        fill: '#043c74', 
        width: '18px', 
        marginBottom: '-7px', 
        marginLeft: '7px', 
        cursor: 'pointer'
    },
    additionalDetailsGrid: {
        padding: '5px 28px'
    },
    companyNameLabel: {
        paddingBottom: '10px'
    },
    companyNameGrid: {
        paddingRight: 36
    },
    errorText: {
        color: "red"
    },
    paddingRight: {
        paddingRight: '20px'
    },
    Tab1: {
        borderTopLeftRadius: "8px",
        borderBottomLeftRadius: "8px",
    },
    emailText: {
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '14px',
        margin: '10px 10px',
        marginBottom: '5px'
    },
    customerName: {
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '15px',
        color: '#1D3D66',
        margin: '5px 0px 5px 0px',
        width: '65%'
    },
    creationDate: {
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '12px',
        color: '#1D3D66',
        margin: '7px 0px 5px 0px'
    },
    tab3: {
        borderEndEndRadius: "8px",
        borderTopRightRadius: "8px",
    },
    tab4: {
        borderRadius: '8px',
    },
    PassinputLabel: {
        fontSize: '16px',
        fontFamily: "SFProDisplay",
        fontWeight: 400,
        color: "#3a3f63",
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        margin: '2.4% 36px 1.9% 0',
    },
    detailTitle: {
        color: "rgba(33, 43, 54, 1)",
        fontFamily: "SFProDisplay",
        fontWeight: 700,
        fontSize: "18px",
        textDecoration: 'underline',
        padding: '0px 0px', 
        marginLeft: '0px', 
        marginTop: '15px'
    },
    inputLabel: {
        fontSize: "14px",
        fontFamily: "SFProDisplay",
        fontWeight: 700,
        color: "#1A3B71",
        paddingBottom: "5px",
    },
    termsText: {
        color: "#727096",
        fontFamily: "SFProDisplay",
        fontWeight: 400,
        fontSize: "16px",
        paddingTop: "4px",
    },
    buttonStyle: {
        width: "100%",
        height: "45px",
        marginTop: "40px",
        border: "none",
        backgroundColor: "rgb(98, 0, 238)",
    },
    checkedIcon: {
        borderRadius: 4,
        border: "1px solid #727096",
        width: 15,
        height: 15,
        backgroundColor: "white",
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='black'/%3E%3C/svg%3E\")",
        "input:hover ~ &": {},
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    icon: {
        borderRadius: 4,
        width: 15,
        height: 15,
        backgroundColor: "white",
        border: "1px solid #727096",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2,
        },
        "input:hover ~ &": {
            backgroundColor: "white",
        },
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    countryPlaceHolder: {
        fontStyle: "normal",
        fontFamily: "SFProDisplay",
        fontWeight: 400,
        fontSize: "16px",
        color: "rgba(218, 224, 241, 1)",
    },
    select: {
        height: "37px",
        borderRadius: "8px",
        marginTop: '5px',
    },
    privacyText: {
        color: "rgba(69, 158, 255, 1)",
    },
    DetailBox: {
        height: '230px',
    },
    ButtonBox: {
        height: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        paddingBottom: '30px'
    },
    grids: {
        paddingTop: '18px',
        paddingBottom: '0px'
    },
    addNew: {
        boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.18)'
    }
};

const CustomCard: any = withStyles({
    root: {
        boxShadow: 'none',
        border: '1px solid rgba(218, 224, 241, 1)',
        borderRadius: '8px',
        height: '100%',
        "& .MuiSvgIcon-root": {
            height: '20px',
            width: '20px',
        }
    }
})(Card);

const EditButton: any = withStyles({
    root: {
        color: "rgba(255, 255, 255, 1)",
        backgroundColor: "rgba(4, 60, 116, 1)",
        height: "40px",
        width: 110,
        borderRadius: "8px",
        fontFamily: "SFProDisplay",
        fontSize: "14px",
        fontWeight: 700,
        boxShadow: "none",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "rgba(4, 60, 116, 1)",
            boxShadow: "none",
        },
        "@media (max-width: 600px)": {
            width: "100%",
            marginLeft: 0,
        },
    },
})(Button);

const InputField: any = withStyles({
    root: {
        background: "inherite",
        margin: "5px 0px 0px 0px",
        "& ::placeholder": {
            color: 'rgba(218, 224, 241, 1)',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
            fontSize: '14px',
            opacity: 1,
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(214, 214, 214, 1)",
        },
        "& .MuiInputBase-input": {
            height: '15px',
        },
        "& .MuiOutlinedInput-input": {
            padding: `12px 14px !important`
        },
        "& .MuiOutlinedInput-root": {
            fontFamily: "SFProDisplay",
            fontWeight: 400,
            backgroundColor: "rgba(255, 255, 255, 1)",
            fontSize: "14px",
            "& fieldset": {
                height: '40px',
                borderColor: "rgba(218, 224, 241, 1)",
                borderRadius: "8px",
                borderWidth: 1,
            },
            ".Mui-focused": {
                borderColor: "rgba(218, 224, 241, 1)",
            },
        },
        "& .MuiFormHelperText-contained": {
            marginLeft: "0px !important",
        },
        "& .MuiInputBase-input.Mui-disabled": {
            background: "#F1F4FA",
            borderRadius: "8px",
            height: 13,
            color: 'rgba(106, 116, 165, 1)',
            fontSize: '14px',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
        },
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(218, 224, 241, 1)",
            borderWidth: '1px'
        }
    },
})(TextField);

const SaveButton: any = withStyles({
    root: {
        color: "rgba(255, 255, 255, 1)",
        height: "40px",
        width: 110,
        borderRadius: "8px",
        fontFamily: "SFProDisplay",
        fontSize: "18px",
        fontWeight: 700,
        marginTop: "5px",
        boxShadow: "none",
        textTransform: "none",
        "&.MuiButton-contained": {
            backgroundColor: 'rgba(0, 61, 120, 1)',
            boxShadow: 'none',
            "&:hover": {
                backgroundColor: 'rgba(0, 61, 120, 1)',
            }
        },
        "&.MuiButton-outlined": {
            border: '1px solid rgba(0, 61, 120, 1)',
            color: 'rgba(0, 61, 120, 1)',
            padding: '5px 15px',
            "&:hover": {
                backgroundColor: 'transparent',
            }
        },
        "&:hover": {
            backgroundColor: "rgba(4, 60, 116, 1)",
            boxShadow: "none",
        },
        "@media (max-width: 600px)": {
            width: "100%",
            marginLeft: 0,
        },
    },
})(Button);

const MainContainer: any = withStyles({
    root: {
        "& ::placeholder": {
            color: 'rgba(218, 224, 241, 1)',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
            fontSize: '16px',
            opacity: 1,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: `rgba(218, 224, 241, 1) !important`,
        },
        "& .css-yk16xz-control": {
            borderRadius: '8px',
            height: '43px',
            borderColor: 'rgba(218, 224, 241, 1)',
            marginTop: '4px',
        },
        padding: "24px 36px 24px 48px",
        "& .css-view-1dbjc4n": {
            marginBottom: `0px !important`,
        },
        "& .css-1hb7zxy-IndicatorsContainer": {
            height: '36px',
        },
        "& .css-1fhf3k1-control": {
            backgroundColor: `#F1F4FA !important`,
            borderColor: "rgba(218, 224, 241, 1)",
            borderRadius: '8px',
            color: 'rgba(106, 116, 165, 1)',
            fontSize: '16px',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
        },
        "@media (max-width: 600px)": {
            padding: "24px 0px 24px 0px",
            "& .MuiBox-root-8": {
                padding: "24px 0px 24px 0px",
            },
            "& ..MuiBox-root-30": {
                padding: "24px 0px 24px 0px",
            },
            "& .MuiTypography-body1": {
                // marginLeft: "0px !important",
            },
        },
    },
})(Container);

const CustomSelect: any = withStyles({
    root: {
        "&.MuiOutlinedInput-input": {
            padding: '13px 14px',
        },
        "&.MuiInputBase-input.Mui-disabled": {
            background: "#F1F4FA",
            borderRadius: "8px",
            height: 13,
            color: 'rgba(106, 116, 165, 1)',
            fontSize: '16px',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
        },
        "& .MuiInputBase-input.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(218, 224, 241, 1)",
            borderWidth: '1px'
        }
    }
})(Select);

const IOSSwitch: any = withStyles((theme) => ({
    root: {
        width: 50,
        height: 25,
        padding: 0,
        margin: theme.spacing(1),
        "& .MuiSwitch-track": {
            height: '92% !important',
        },
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(26px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 21,
        height: 21,
        marginLeft: '1px'
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }: any) => {
    return (
        <Switch
            focusVisibleClassName={classes?.focusVisible}
            disableRipple
            classes={{
                root: classes?.root,
                switchBase: classes?.switchBase,
                thumb: classes?.thumb,
                track: classes?.track,
                checked: classes?.checked,
            }}
            {...props}
        />
    );
});

const SelectField: any = withStyles({
    root: {
        "& .MuiOutlinedInput-root": {
            fontFamily: "SFProDisplay",
            fontWeight: 500,
            backgroundColor: "rgba(255, 255, 255, 1)",
            fontSize: "14px",
            color: '#1F436D',
        },
        "& .MuiInputBase-input": {
            fontFamily: "SFProDisplay",
            fontWeight: 500,
            backgroundColor: "rgba(255, 255, 255, 1)",
            fontSize: "14px",
            color: '#1F436D',
        },
        width: '100%',
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(218, 224, 241, 1)",
            borderWidth: 1,
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(218, 224, 241, 1)",
            borderWidth: 1,
        },
        "& .MuiSelect-icon": {
            fill: "rgba(4, 60, 116, 1)",
        },
        "& .MuiOutlinedInput-input": {
            padding: '9px 14px'
        },
    }
})(FormControl);

const TextArea = withStyles({
    root: {
        width: '100%',
        paddingTop: '10px',
        "& .MuiOutlinedInput-root": {
            fontFamily: "SFProDisplay",
            fontWeight: 400,
            backgroundColor: "rgba(255, 255, 255, 1)",
            fontSize: "16px",
            color: 'rgba(106, 116, 165, 1)',
            "& fieldset": {
                fontSize: "16px",
                borderColor: "rgba(214, 214, 214, 1)",
                borderRadius: "8px",
                borderWidth: 1,
                fontWeight: 400,
                color: 'rgba(106, 116, 165, 1)',
            },
        },
    }
})(TextField);

const PasswordField = withStyles({
    root: {
        borderRadius: '8px',
        backgroundColor: '#fff',
        "& ::placeholder": {
            color: ' #d6d6d6',
            opacity: 1,
            fontSize: '16px',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
            padding: '17px 0px 19px 0px',
        },
        "& .MuiInputBase-input": {
            height: '15px',
        },
        "& .MuiOutlinedInput-input": {
            padding: `14.2px 14px !important`
        },
        "& .MuiOutlinedInput-root": {
            fontFamily: "SFProDisplay",
            fontWeight: 400,
            backgroundColor: "rgba(255, 255, 255, 1)",
            fontSize: "16px",
            borderRadius: "8px",
            "& fieldset": {
                border: 'solid 1px #d6d6d6',
                // height: '55px',
                borderRadius: '8px',
            },
        },
    },
})(TextField);

const CustomTab: any = withStyles({
    root: {
        border: "1px solid rgba(4, 60, 116, 1)",
        width: "100%",
        color: "rgba(4, 60, 116, 1)",
        fontFamily: "SFProDisplay",
        fontWeight: 700,
        fontSize: "14px",
        textTransform: "none",
        "& .PrivateTabIndicator-colorSecondary-4": {
            display: "none",
        },
        "@media (max-width: 1024px)": {
            width: "25%",
        },
    },
})(Tab);

const CustomTabs: any = withStyles({
    root: {
        marginLeft: "24px",
        "& .MuiTab-root": {
            minHeight: '30px',
            maxWidth: '240px',
        },
        "& .MuiTabs-indicator": {
            display: "none",
        },
        "& .Mui-selected": {
            background: "rgba(225, 239, 255, 1)",
        },
        "@media (max-width: 470px)": {
            maxHeight: "80px",
        },
        "@media (max-width: 600px)": {
            marginLeft: "0px",
        },
    },
})(Tabs);

const CustomBox: any = withStyles({
    root: {

    }
})(Box)

const styles = () => ({
    firstName: {
        paddingRight: '0px',
        marginTop: '20px',
        "@media (max-width: 600px)": {
            marginTop: 0
        }
    },
    lastName: {
        paddingLeft: '42px !important',
        paddingRight: '18px !important',
        "@media (max-width: 600px)": {
            paddingLeft: '28px !important',
            paddingRight: '34px !important',
        }
    },
    countryCode: {
        paddingRight: '0px',
        marginTop: '20px',
        '& .MuiFormControl-root.MuiTextField-root': {
            marginTop: '0px'
        },
        "@media (max-width: 600px)": {
            marginTop: '0px'
        }
    },
    country: {
        paddingLeft: '42px !important',
        paddingRight: '18px !important',
        "@media (max-width: 600px)": {
            paddingLeft: '28px !important',
            paddingRight: '34px !important',
        }
    },
    selectPlaceholder: {
        backgroundColor: '#fff !important',
        '&.Mui-disabled': {
            opacity: 1
        }
    }
})

export default withStyles(styles)(CustomerContactAdd)

// Customizable Area End
