Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.homePageContentApiEndPoint = "bx_block_landingpage3/landing_pages/";
exports.homePageContentApiMethod = "GET";
exports.searchLandingPageApiEndPoint = "/bx_block_search/search/search_all";
exports.searchLandingPageApiMethod = "GET";
exports.showBannerAPIEndPoint = "bx_block_landingpage3/landing_pages/upload_medias";
exports.emailError = "Please enter valid email id";
exports.EmployeeHeaderOptionPermissionAPIEndPoint = "/bx_block_landingpage3/landing_pages/employee_menu";

// Customizable Area End