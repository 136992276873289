import React, { useEffect, useState } from "react";
import { Grid, FormControl, Select, MenuItem, Typography, IconButton } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import {
  makeStyles,
} from "@material-ui/core/styles";

const useStyles = makeStyles({
  customOutline: {
    width: '100%',
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#dae0f1",
      borderWidth: 1,
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#dae0f1",
      borderWidth: 1,
    },
    "& .MuiSelect-icon": {
      fill: "rgba(4, 60, 116, 1)",
    },
    "& .MuiOutlinedInput-input": {
      padding: '11px 14px'
    },
    "& .MuiTypography-body1": {
      fontFamily: 'SFProDisplay',
      fontWeight: 500,
      fontSize: '16px'
    }
  },
  menuPaper: {
    maxHeight: 300,
    maxWidth: 280
  }
});

const CustomDropDown = (props: any) => {
  const classes = useStyles();
  const [displayName, setDisplayName] = useState("");
  const filterValues = (e: any) => {
    props.FilterByDropType(e);
    props.uniqueProducts.map((data: any)=> {
      if(data.id === e.target.value) {
        setDisplayName(data.value)
      }
    })
  }
  useEffect(()=> {
    if (props.DropdownValue === 0) {
      setDisplayName("")
    }
  }, [props.DropdownValue])
  return (
    <Grid item lg={4} md={4} sm={4} xs={12} className="menu_item">
      <FormControl
        variant="outlined"
        classes={{ root: classes.customOutline }}
      >
        <Select
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            classes: { paper: classes.menuPaper },
            getContentAnchorEl: null
          }}
          labelId="department"
          displayEmpty
          id="department"
          variant="outlined"
          fullWidth
          value={props.DropdownValue}
          style={webStyle.select}
          onChange={(e: any) => filterValues(e)}
          defaultValue={props.displayName}
          className="menu_item"
          endAdornment={
            displayName && (
            <IconButton
              onClick={(e) => {filterValues(e); setDisplayName("")}}
            >
              <ClearIcon style={webStyle.clearIcon} />
            </IconButton>
            )
          }
          renderValue={(p: any) => {
            return props.DropdownValue === 0 ?  
            <em style={webStyle.PlaceHolder}>
              {props.displayName}
            </em> : displayName
          }}
        >
          <MenuItem value={props.isProduct ? '' : 0}>
            <em style={webStyle.PlaceHolder}>
              {props.displayName}
            </em>
          </MenuItem>
          {props.uniqueProducts.map((data: any) => {
            return (
              <MenuItem className="menu_item" value={props.isProduct ? data.value : data.id} key={data.id}>
                <Typography style={webStyle.menuText} className="knowMoreButton">{data.value}</Typography>
              </MenuItem>
            );
          })}
          {props.uniqueProducts.length === 0 && (
            <MenuItem value="noData" disabled>
              No Data
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </Grid >
  );
};

export default CustomDropDown;

const webStyle = {
  PlaceHolder: {
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "16px",
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.35px',
    color: '#6a74a5',
  },
  menuText: {
    fontWeight: 400, 
    fontSize: '16px', 
    fontFamily: 'SFProDisplay', 
    color: 'rgb(4, 60, 116) none repeat scroll 0% 0%'
  },
  select: {
    height: "35px",
    borderRadius: "8px",
    borderColor: 'rgba(4, 60, 116, 1)',
    background: 'white',
    fontFamily: 'SFProDisplay',
    fontWeight: 500,
    fontSize: '16px'
  },
  clearIcon: {
    fontSize: '18px', 
    marginRight: '4px'
  }
};
