import { toast } from "react-toastify";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { withStyles } from "@material-ui/core/styles";
import { Grid, TextField , Tabs, Tab, Box } from "@material-ui/core";
import React from "react";
import { IAttachements } from "../../blocks/DataStorage/src/QueryDetailController";
import axios from 'axios'
export const configJSON = require("./config");
// API CALL MIDDLEWARE START

interface IApiCall {
  header: any;
  httpBody: any;
  url: string;
  httpMethod: string;
}

interface IPathRequest {
  pathName: string;
  pathObject: any;
}

export interface IUserdetails {
  id: string;
  type: string;
  attributes: {
    profile: string;
    role: string;
    role_id: number;
    activated: string;
    created_at: string;
    device_id: null | string;
    email: string;
    full_name: string;
    type: string;
    unique_auth_id: string;
    updated_at: string;
    customer_profile_id: number;
    first_name?: string;
    last_name?: string;
  };
}

export function getHeaders(obj: Record<string, string>): Headers {
  const headers: any = {};
  headers["Content-Type"] = "application/json";
  for (let key in obj) {
    headers[key] = obj[key];
  }
  return headers;
}

export function getUserdetails(): null | IUserdetails {
  let userDetails = localStorage.getItem("userInfo");
  if (userDetails) {
    const parsedUserDetails: IUserdetails = JSON.parse(userDetails);
    return parsedUserDetails;
  }
  return null;
}

export function apiCall({ header, httpBody, url, httpMethod }: IApiCall) {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    url
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  if (Object.keys(httpBody).length > 0) {
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  }


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    httpMethod
  );

  return requestMessage;
}

//API CALL MIDDLEWARE ENDS

/* START -------------------------------------------------------------- Notification Area */
const toastProperties: any = {
  position: toast.POSITION.TOP_RIGHT,
  theme: "colored",
  autoClose: 6000,
};

export function successNotification(message: any) {
  toast.success(message, toastProperties);
}

export function errorNotification(message: any) {
  toast.error(message, toastProperties);
}

export function warningNotification(message: any) {
  toast.warn(message, toastProperties);
}

export function infoNotification(message: any) {
  toast.info(message, toastProperties);
}
/* END -------------------------------------------------------------- Notification Area */

/* START -------------------------------------------------------------- Show api errors Area */
export function showApiErrorResponse(responseJson: any) {
  if (!responseJson || !responseJson.errors) {
    return;
  }
  const errors: any[] = responseJson.errors;

  let allerrors = "";
  errors.forEach((obj: any) => {
    for (const key in obj) {
      errorNotification(`${obj[key]}`);
    }

  });


}

export const CustomGrid: any = withStyles({
  root: {
    "& .css-26l3qy-menu": {
      fontFamily: "SFProDisplay",
      fontSize: "14px",
      fontWeight: 500,
    },
    "& .css-tlfecz-indicatorContainer": {
      color: '#000000',
      paddingRight: '12px',
    },
    "& .css-1okebmr-indicatorSeparator": {
      display: 'none',
    },
    "& .css-1wa3eu0-placeholder": {
      color: 'rgba(218, 224, 241, 1)',
      fontFamily: 'SFProDisplay',
      fontSize: '16px',
      fontWeight: 500,
      opacity: 1,
    },
    "& .css-yk16xz-control": {
      height: "42px",
      borderRadius: "8px",
      borderColor: "rgba(214, 214, 214, 1)",
      fontWeight: 500,
      fontFamily: "SFProDisplay",
      fontSize: "16px",
      with: "100%",
    },
    "& .css-g1d714-ValueContainer": {
      position: "revert",
    },
    "& .css-1hb7zxy-IndicatorsContainer": {
      height: "42px",
      borderRadius: "8px",
      borderColor: "rgba(214, 214, 214, 1)",
      fontWeight: 500,
      fontFamily: "SFProDisplay",
      fontSize: "16px",
      with: "100%",
    },
  },
})(Grid);

export function handlePrivacyPolicyRedirection() {
  window.open("https://www.biocon.com/privacy-policy-biocon/");
}

export function handleTermsOfUseRedirection() {
  // window.open("https://www.biocon.com/terms-of-use/");
  window.open("https://www.biocon.com/privacy-policy-biocon/");
}

export function HandleAboutUsRedirection() {
  window.open("https://www.biocon.com/about-us/");
}

export function handleBannerClick(url: string) {
  window.open(url);
}

export function handleFooterClick(url: string) {
  window.open(url);
}

export function isLoggedIn(navigation: any): boolean | string {
  const authToken = localStorage.getItem("authToken");
  if (!authToken) {
    if (navigation) {
      navigation.navigate("Login");
      return false;
    } else {
      return false;
    }
  } else {
    return authToken;
  }
}

export function logout(navigation: any) {
  localStorage.removeItem('authToken');
  localStorage.removeItem('userInfo');
  localStorage.removeItem('isEmployee');
  localStorage.removeItem('previousPath');
  localStorage.removeItem('fromPage');
  localStorage.removeItem('footerData');
  // localStorage.removeItem("guestData");
  window.location.reload();
}

export function showTextWithValidation(text: string | null | undefined) {
  if (!text || text.length === 0) {
    return '-'
  }
  return text;
}

export function isGuest(): boolean {
  const authToken = localStorage.getItem("authToken");
  if (!authToken) {
    return true;
  } else {
    return false;
  }
}

export function navigateToSignup(req: any): void {
  savePreviousPathBeforeLogin(true)
  if (req) {
    req.navigate("Signup");
  }
}

export function navigateToLogin(navigation: any): void {
  savePreviousPathBeforeLogin(true)
  if (navigation) {
    navigation.navigate("Login");
  }
}

// const handleUpdateAuthToken = () => {
export async function handleUpdateAuthToken() {
  const getRefreshToken = localStorage.getItem("refreshToken");
  let url = require('../../framework/src/config.js').baseURL + configJSON.newAccessToken + `?token=${getRefreshToken}`;

  await axios.get(url).then((res: any) => {
    localStorage.setItem("authToken", res.data.meta.token)
  })
  return true;
}

export function isAuthorisedUser(response: any, navigation: any): void {
  let isEmployee: any = JSON.parse(localStorage.getItem("userInfo") || "");
  if (response.errors && response.errors.length > 0 && response.errors[0]?.token) {
    if (response.errors[0]?.token === "Token has Expired" && isEmployee?.attributes?.is_customer === 'False') {
      logout(navigation)
    }
  }
}

export function isEmployee() {
  const isEmployee = localStorage.getItem("isEmployee");
  if (isEmployee === "true") {
    return true;
  }
  return false;
}

export function authorizeEmployee(navigation: any) {
  const isAuthorized = isEmployee();
  if (!isAuthorized) {
    navigation.navigate("Home");
  }
}

export function getHighlightedText(text: string = '', highlight: string) {
  // Split on highlight term and include term into parts, ignore case

  if (/^[%)(*+-]/.test(highlight)) {
    return <span>{text}</span>;
  } else {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return <span> {parts.map((part, i) =>
      <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold', background: 'yellow' } : {}}>
        {part}
      </span>)
    } </span>;
  }

}

export const downloadAttachmentFile = (attachment: IAttachements) => {
  fetch(attachment.doc_file, {
    method: 'GET'
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        attachment.file_name,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link?.parentNode?.removeChild(link);
    });
}

export function savePreviousPathBeforeLogin(req: boolean) {
  localStorage.setItem('previousPath', JSON.stringify(true));
}
export function removePreviousPath() {
  localStorage.removeItem('previousPath');
}
export function getPreviousPath() {
  const path = localStorage.getItem('previousPath');
  if (path) {
    return path;
  } else {
    return false;
  }
}

export const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

let timer: any;
export function autoLogOut() {
  timer = setTimeout(() => {
    // clears any pending timer.
    resetTimer();
    // Listener clean up. Removes the existing event listener from the window
    Object.values(events).forEach((item) => {
      window.removeEventListener(item, resetTimer);
    });
    // logs out user
    logout("");
  }, 15 * 60 * 1000); // 10000ms = 10secs. You can change the time.
} 

export const resetTimer = () => {
  if (timer) clearTimeout(timer);
}

export const SearchContainer: any = withStyles({
  root: {
      padding: '25px 0px 10px 0px'
  }
})(Grid);

export const InputField: any = withStyles({
  root: {
      background: "inherite",
      margin: "5px 0px 0px 0px",
      "& ::placeholder": {
          color: 'rgba(185, 187, 197, 1)',
          fontFamily: 'SFProDisplay',
          fontWeight: 400,
          fontSize: '14px',
          opacity: 1,
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: 'rgba(185, 187, 197, 1)',
      },
      "& .MuiInputBase-input": {
          height: '15px',
      },
      "& .MuiOutlinedInput-input": {
          padding: `11px 14px !important`
      },
      "& .MuiOutlinedInput-root": {
          fontFamily: "SFProDisplay",
          fontWeight: 400,
          backgroundColor: "rgba(255, 255, 255, 1)",
          fontSize: "16px",
          "& fieldset": {
              height: '40px',
              borderColor: 'rgba(185, 187, 197, 1)',
              borderRadius: "8px",
              borderWidth: 1,
          },
          ".Mui-focused": {
              borderColor: 'rgba(185, 187, 197, 1)',
          },
      },
      "& .MuiFormHelperText-contained": {
          marginLeft: "0px !important",
      },
      "& .MuiInputBase-input.Mui-disabled": {
          background: "#F1F4FA",
          borderRadius: "8px",
          height: 13,
          color: 'rgba(106, 116, 165, 1)',
          fontSize: '14px',
          fontFamily: 'SFProDisplay',
          fontWeight: 400,
      },
      "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
          borderColor: 'rgba(185, 187, 197, 1)',
          borderWidth: '1px'
      },
      "& .MuiIconButton-root": {
          paddingLeft: '0px'
      },
      "& .MuiOutlinedInput-adornedStart": {
          paddingLeft: '5px'
      },
      "& .MuiSvgIcon-root": {
          width: '20px',
          height: '30px',
      },
  },
})(TextField);

export const CustomTab: any = withStyles({
  root: {
      border: "1px solid rgba(218, 224, 241, 1)",
      width: "100%",
      color: "rgba(4, 60, 116, 1)",
      fontFamily: "SFProDisplay",
      fontWeight: 700,
      fontSize: "14px",
      textTransform: "none",
      borderRadius: '16px',
      "& .PrivateTabIndicator-colorSecondary-4": {
          display: "none",
      },
      "@media (max-width: 670px)": {
          width: "auto",
          fontSize: "12px"
      },
  },
})(Tab);

export const CustomTabs: any = withStyles({
  root: {
      maxHeight: "40px",
      backgroundColor: 'rgba(255, 255, 255, 1)',
      "& .MuiTab-root": {
          minHeight: '42px',
          maxHeight: "49px",
          maxWidth: '280px',
      },
      "& .MuiTabs-indicator": {
          display: "none",
      },
      "& .Mui-selected": {
          background: "#DCDFF0",
          color: '#8093B3',
          maxWidth: '280px',
          "& .MuiTab-textColorInherit": {
              opacity: 0.7,
          },
      },
      "& .MuiTab-textColorInherit": {
          opacity: 1,
      },
      "@media (max-width: 470px)": {
          maxHeight: "80px",
      },
      "@media (max-width: 600px)": {
          marginLeft: "0px",
      },
  },
})(Tabs);

export function TabPanel(props: {
  [x: string]: any;
  children: any;
  value: any;
  index: any;
}) {
  const { children, value, index, ...other } = props;
  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`full-width-tabpanel-${index}`}
          aria-labelledby={`full-width-tab-${index}`}
          {...other}
      >
          {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
  );
}

export const TextArea:any = withStyles({
  root: {
      width: '100%',
      paddingTop: '10px',
      "& .MuiOutlinedInput-root": {
          fontWeight: 400,
          fontFamily: "SFProDisplay",
          backgroundColor: "rgba(255, 255, 255, 1)",
          fontSize: "16px",
          color: 'rgba(106, 116, 165, 1)',
          "& fieldset": {
              fontSize: "16px",
              borderColor: "rgba(214, 214, 214, 1)",
              borderRadius: "8px",
              borderWidth: 1,
              fontWeight: 400,
              color: 'rgba(106, 116, 165, 1)',
          },
      },
  }
})(TextField);
/* End -------------------------------------------------------------- Show api errors Area */
