import React from "react";

// Customizable Area Start
import {
    Container,
    Box,
    Button,
    InputLabel,
    Typography,
    Tab,
    Tabs,
    Grid,
    TextField,
    FormControlLabel,
    Select,
    FormControl,
    Card,
    Dialog,
} from "@material-ui/core";
import {
    withStyles,
} from "@material-ui/core/styles";
import AppHeader from "../../../components/src/EmployeeAppHeader.web";
import AppFooter from "../../../components/src/AppFooter.web";
import { AppHeader as AppHeaderUser } from '../../../components/src/AppHeader'

function CustomTabPanel(props: {
    [x: string]: any;
    children: any;
    value: any;
    index: any;
}) {
    const { children, value, classes, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }} className={classes.container} >{children}</Box>}
        </div>
    );
}
// Customizable Area End

import ProfileDetailsController, {
    Props,
} from "./TicketDetailsController";
import { downloadAttachmentFile, handlePrivacyPolicyRedirection, handleTermsOfUseRedirection } from "../../../components/src/common";
import Loader from "../../../components/src/Loader.web";
import CompanyNameSelector from "../../email-account-registration/src/CompanyNameSelector";
import { classNames } from "react-select/src/utils";
import TicketDetailsController from "./TicketDetailsController";

export class TicketDetails extends TicketDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    ProductQueryDetails() {
        return (
            <Box minHeight='calc(100vh - 394px)'>
                <Grid container spacing={2} style={{ marginBottom: '5px' }}>
                    <Grid item xs={6}>
                        <Typography style={webStyle.detailTitle}>
                            {"Product Query"}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <EditButton onClick={this.handleBackClick} variant="contained" style={{ float: 'right' }}>
                            Back
                        </EditButton>
                    </Grid>
                </Grid>
                <Grid container spacing={7} alignItems="center" style={{ paddingTop: '20px' }}>
                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="firstName" style={webStyle.inputLabel}>
                            {'Name'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <InputField
                            id="firstName"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.name}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="company" style={webStyle.inputLabel}>
                            {'Company'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <InputField
                            id="company"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.company_name}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="date" style={webStyle.inputLabel}>
                            {'Date'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <InputField
                            id="date"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.date}
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="type" style={webStyle.inputLabel}>
                            {'Query Type'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <InputField
                            id="type"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.type}
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="product" style={webStyle.inputLabel}>
                            {'Product'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <InputField
                            id="product"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.product}
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="endMarkets" style={webStyle.inputLabel}>
                            {'End Markets'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <InputField
                            id="endMarkets"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.end_markets}
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="additionalEmail" style={webStyle.inputLabel}>
                            {'Additional Email'}
                        </InputLabel>
                        <InputField
                            id="additionalEmail"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.additional_emails.map((additional: any) => additional.name).join(", ")}
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="documentName" style={webStyle.inputLabel}>
                            {'Document Name'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <TextArea
                            id="documentName"
                            variant="outlined"
                            required
                            multiline
                            className="documentField"
                            rowsMax={4}
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.document_name.map((doc: any) => doc.name).join(", ")}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="comments" style={webStyle.inputLabel}>
                            {'Comments'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <TextArea
                            id="comments"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder="Enter First Name"
                            value={this.state.ticketDetails?.comments}
                            multiline
                            rows={4}
                            maxRows={20}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} style={webStyle.grids}>
                        <Typography style={webStyle.DetailDataLabel}>
                            Attachments
                        </Typography>
                        <Typography style={webStyle.SubmittedName}>
                            {this.state.ticketDetails?.attachments.map((item: any, index: number) => {
                                return <Button onClick={() => downloadAttachmentFile(item)} key={`attachement${item.id}`} style={{ ...webStyle.SubmittedName, color: 'black', textDecoration: 'underline' }}>
                                    {item.file_name} {this.state.ticketDetails?.attachments.length !== (index + 1) ? ", " : null}
                                </Button>
                            })}

                        </Typography>
                    </Grid>

                </Grid>
            </Box>
        )
    }

    SampleQueryDetails() {
        return (
            <Box minHeight='calc(100vh - 394px)'>
                <Grid container spacing={2} style={{ marginBottom: '5px' }}>
                    <Grid item xs={6}>
                        <Typography style={webStyle.detailTitle}>
                            {"Sample Request"}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <EditButton onClick={this.handleBackClick} variant="contained" style={{ float: 'right' }}>
                            Back
                        </EditButton>
                    </Grid>
                </Grid>
                <Grid container spacing={7} alignItems="center" style={{ paddingTop: '20px' }}>
                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="firstName" style={webStyle.inputLabel}>
                            {'Name'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <InputField
                            id="firstName"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.name}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="company" style={webStyle.inputLabel}>
                            {'Company'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <InputField
                            id="company"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.company_name}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="date" style={webStyle.inputLabel}>
                            {'Date'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <InputField
                            id="date"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.date}
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="type" style={webStyle.inputLabel}>
                            {'Query Type'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <InputField
                            id="type"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={'Sample Request'}
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="product" style={webStyle.inputLabel}>
                            {'Product'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <InputField
                            id="product"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.product}
                            fullWidth
                        />
                    </Grid>

                    {this.state.ticketDetails?.api_request && <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="apiRequest" style={webStyle.inputLabel}>
                            {'Details of API Available'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <InputField
                            id="apiRequest"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.api_request ? 'Yes' : 'No'}
                            fullWidth
                        />
                    </Grid>}

                    {this.state.ticketDetails?.api_quantity.trim().length > 0 && <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="apiQuantity" style={webStyle.inputLabel}>
                            {'API Quantity'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <InputField
                            id="endMarkets"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.api_quantity}
                            fullWidth
                        />
                    </Grid>}

                    {
                        this.state.ticketDetails?.api_uom && (
                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="apiUom" style={webStyle.inputLabel}>
                                    {'API UOM'}<span style={{ color: "red" }}>*</span>
                                </InputLabel>
                                <InputField
                                    id="apiUom"
                                    variant="outlined"
                                    required
                                    disabled={true}
                                    placeholder=""
                                    value={this.state.ticketDetails?.api_uom}
                                    fullWidth
                                />
                            </Grid>
                        )
                    }

                    {
                        this.state.ticketDetails?.batch_requirement && (
                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="batchRequirement" style={webStyle.inputLabel}>
                                    {'Batch Requirement'}<span style={{ color: "red" }}>*</span>
                                </InputLabel>
                                <InputField
                                    id="batchRequirement"
                                    variant="outlined"
                                    required
                                    disabled={true}
                                    placeholder=""
                                    value={this.state.ticketDetails?.batch_requirement}
                                    fullWidth
                                />
                            </Grid>
                        )
                    }


                    {
                        this.state.ticketDetails?.customer_specification && (
                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="customerSpecification" style={webStyle.inputLabel}>
                                    {'Customer Specification / PSD'}<span style={{ color: "red" }}>*</span>
                                </InputLabel>
                                <InputField
                                    id="customerSpecification"
                                    variant="outlined"
                                    required
                                    disabled={true}
                                    placeholder=""
                                    value={this.state.ticketDetails?.customer_specification}
                                    fullWidth
                                />
                            </Grid>
                        )
                    }

                    {
                        this.state.ticketDetails?.pharmacopeia_grade && (
                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="pharmacopeiaReference" style={webStyle.inputLabel}>
                                    {'Pharmacopeia Reference / Grade'}<span style={{ color: "red" }}>*</span>
                                </InputLabel>
                                <InputField
                                    id="pharmacopeiaReference"
                                    variant="outlined"
                                    required
                                    disabled={true}
                                    placeholder=""
                                    value={this.state.ticketDetails?.pharmacopeia_grade}
                                    fullWidth
                                />
                            </Grid>
                        )
                    }

                    {
                        this.state.ticketDetails?.end_markets && (
                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="endMarket" style={webStyle.inputLabel}>
                                    {'End Market'}<span style={{ color: "red" }}>*</span>
                                </InputLabel>
                                <InputField
                                    id="endMarket"
                                    variant="outlined"
                                    required
                                    disabled={true}
                                    placeholder=""
                                    value={this.state.ticketDetails?.end_markets || ''}
                                    fullWidth
                                />
                            </Grid>
                        )
                    }

                    {
                        this.state.ticketDetails?.working_standard_quantity && (
                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="workingStandardsQuantity" style={webStyle.inputLabel}>
                                    {'Working Standards Quantity'}
                                </InputLabel>
                                <InputField
                                    id="workingStandardsQuantity"
                                    variant="outlined"
                                    required
                                    disabled={true}
                                    placeholder=""
                                    value={this.state.ticketDetails?.working_standard_quantity || ''}
                                    fullWidth
                                />
                            </Grid>
                        )
                    }

                    {
                        this.state.ticketDetails?.working_standard_uom && (
                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="workingStandardsUom" style={webStyle.inputLabel}>
                                    {'Working Standards UOM'}
                                </InputLabel>
                                <InputField
                                    id="workingStandardsUom"
                                    variant="outlined"
                                    required
                                    disabled={true}
                                    placeholder=""
                                    value={this.state.ticketDetails?.working_standard_uom || ''}
                                    fullWidth
                                />
                            </Grid>
                        )
                    }

                    {
                        this.state.ticketDetails?.impurity_quantity && (
                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="impurityQuantity" style={webStyle.inputLabel}>
                                    {'Impurity Quantity'}
                                </InputLabel>
                                <InputField
                                    id="impurityQuantity"
                                    variant="outlined"
                                    required
                                    disabled={true}
                                    placeholder=""
                                    value={this.state.ticketDetails?.impurity_quantity || ''}
                                    fullWidth
                                />
                            </Grid>
                        )
                    }

                    {
                        this.state.ticketDetails?.impurity_uom && (
                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="impurityUom" style={webStyle.inputLabel}>
                                    {'Impurity UOM'}
                                </InputLabel>
                                <InputField
                                    id="impurityUom"
                                    variant="outlined"
                                    required
                                    disabled={true}
                                    placeholder=""
                                    value={this.state.ticketDetails?.impurity_uom || ''}
                                    fullWidth
                                />
                            </Grid>
                        )
                    }

                    {
                        this.state.ticketDetails?.lab_standard_quantity && (
                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="labStandardsQuantity" style={webStyle.inputLabel}>
                                    {'Lab Standards Quantity'}
                                </InputLabel>
                                <InputField
                                    id="labStandardsQuantity"
                                    variant="outlined"
                                    required
                                    disabled={true}
                                    placeholder=""
                                    value={this.state.ticketDetails?.lab_standard_quantity || ''}
                                    fullWidth
                                />
                            </Grid>
                        )
                    }

                    {
                        this.state.ticketDetails?.lab_standard_uom && (
                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="labStandardsUom" style={webStyle.inputLabel}>
                                    {'Lab Standards UOM'}
                                </InputLabel>
                                <InputField
                                    id="labStandardsUom"
                                    variant="outlined"
                                    required
                                    disabled={true}
                                    placeholder=""
                                    value={this.state.ticketDetails?.lab_standard_uom || ''}
                                    fullWidth
                                />
                            </Grid>
                        )
                    }

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="billToAddress" style={webStyle.inputLabel}>
                            {'Bill To Address'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <InputField
                            id="billToAddress"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.bill_to_address}
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="consigneeAddress" style={webStyle.inputLabel}>
                            {'Consignee Address'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <InputField
                            id="consigneeAddress"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.consignee_address}
                            fullWidth
                        />
                    </Grid>

                    {
                        this.state.ticketDetails?.additional_emails.length > 0 && (
                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="additionalEmails" style={webStyle.inputLabel}>
                                    {'Additional Emails'}<span style={{ color: "red" }}>*</span>
                                </InputLabel>
                                <InputField
                                    id="additionalEmails"
                                    variant="outlined"
                                    required
                                    disabled={true}
                                    placeholder=""
                                    value={this.state.ticketDetails?.additional_emails.map((email: any) => email.name).join(", ")}
                                    fullWidth
                                />
                            </Grid>
                        )
                    }

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="preShipmentCoa" style={webStyle.inputLabel}>
                            {'Pre-Shipment COA'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <InputField
                            id="preShipmentCoa"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.pre_shipment_coa}
                            fullWidth
                        />
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} container spacing={3}>
                        <Grid item lg={6} md={6} sm={6} xs={12} style={webStyle.grids}>
                            <InputLabel htmlFor="comments" style={webStyle.inputLabel}>
                                {'Comments'}<span style={{ color: "red" }}>*</span>
                            </InputLabel>
                            <TextArea
                                id="comments"
                                variant="outlined"
                                required
                                disabled={true}
                                placeholder=""
                                value={this.state.ticketDetails?.comments}
                                multiline
                                rows={4}
                                maxRows={20}
                                fullWidth
                            />
                        </Grid>
                    </Grid>

                    {
                        this.state.ticketDetails?.attachments.length > 0 && (
                            <Grid item lg={12} md={12} style={webStyle.grids} xs={12}>
                                <Typography style={webStyle.DetailDataLabel}>
                                    Attachments
                                </Typography>
                                <Typography style={webStyle.SubmittedName}>
                                    {this.state.ticketDetails?.attachments.map((attachment: any, index: number) => {
                                        return <Button data-testid='downloadAttachmentFile' onClick={() => downloadAttachmentFile(attachment)} key={`attachement${attachment.id}`} style={{ ...webStyle.SubmittedName, color: 'black', textDecoration: 'underline' }}>
                                            {attachment.file_name} {this.state.ticketDetails?.attachments.length !== (index + 1) ? ", " : null}
                                        </Button>
                                    })}

                                </Typography>
                            </Grid>
                        )
                    }
                </Grid>
            </Box>
        )
    }

    MeetingDetails() {
        return (
            <Box minHeight='calc(100vh - 394px)'>
                <Grid container spacing={2} style={{ marginBottom: '5px' }}>
                    <Grid item xs={6}>
                        <Typography style={webStyle.detailTitle}>
                            {"Meeting Request"}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <EditButton onClick={this.handleBackClick} variant="contained" style={{ float: 'right' }}>
                            Back
                        </EditButton>
                    </Grid>
                </Grid>
                <Grid container spacing={7} alignItems="center" style={{ paddingTop: '20px' }}>
                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="firstName" style={webStyle.inputLabel}>
                            {'First Name'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <InputField
                            id="firstName"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.first_name}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="lastName" style={webStyle.inputLabel}>
                            {'Last Name'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <InputField
                            id="lastName"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.last_name}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="companyName" style={webStyle.inputLabel}>
                            {'Company Name'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <InputField
                            id="companyName"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.company_name}
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="email" style={webStyle.inputLabel}>
                            {'Email ID'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <InputField
                            id="email"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.email}
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="country" style={webStyle.inputLabel}>
                            {'Country'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <InputField
                            id="country"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.country}
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="phoneNumber" style={webStyle.inputLabel}>
                            {'Phone No'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <InputField
                            id="phoneNumber"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.mobile_number}
                            fullWidth
                        />
                    </Grid>


                    <Grid item xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="comments" style={webStyle.inputLabel}>
                            {'Comments'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <TextArea
                            id="comments"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.comments}
                            multiline
                            rows={4}
                            maxRows={20}
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="product" style={webStyle.inputLabel}>
                            {'Product'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <InputField
                            id="product"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.product}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Box>
        )
    }

    ConnectWithUs() {
        return (
            <Box minHeight='calc(100vh - 394px)'>
                <Grid container spacing={2} style={{ marginBottom: '5px' }}>
                    <Grid item xs={6}>
                        <Typography style={webStyle.detailTitle}>
                            {"Connect With Us"}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <EditButton onClick={this.handleBackClick} variant="contained" style={{ float: 'right' }}>
                            Back
                        </EditButton>
                    </Grid>
                </Grid>
                <Grid container spacing={7} alignItems="center" style={{ paddingTop: '20px' }}>
                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="firstName" style={webStyle.inputLabel}>
                            {'First Name'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <InputField
                            id="firstName"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.first_name}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="lastName" style={webStyle.inputLabel}>
                            {'Last Name'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <InputField
                            id="lastName"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.last_name}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="companyName" style={webStyle.inputLabel}>
                            {'Company Name'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <InputField
                            id="companyName"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.company_name}
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="email" style={webStyle.inputLabel}>
                            {'Email ID'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <InputField
                            id="email"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.email}
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="country" style={webStyle.inputLabel}>
                            {'Country'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <InputField
                            id="country"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.country}
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="phoneNumber" style={webStyle.inputLabel}>
                            {'Phone No'}<span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <InputField
                            id="phoneNumber"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.mobile_number}
                            fullWidth
                        />
                    </Grid>


                    <Grid item xs={12} style={webStyle.grids}>
                        <InputLabel htmlFor="comments" style={webStyle.inputLabel}>
                            {'Comments'}
                        </InputLabel>
                        <TextArea
                            id="comments"
                            variant="outlined"
                            required
                            disabled={true}
                            placeholder=""
                            value={this.state.ticketDetails?.comments}
                            multiline
                            rows={4}
                            maxRows={20}
                            fullWidth
                        />
                    </Grid>
                    {
                        this.state.ticketDetails?.product !== undefined &&
                        (
                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="product" style={webStyle.inputLabel}>
                                    {'Product'}<span style={{ color: "red" }}>*</span>
                                </InputLabel>
                                <InputField
                                    id="product"
                                    variant="outlined"
                                    required
                                    disabled={true}
                                    placeholder=""
                                    value={this.state.ticketDetails?.product.map((data: any) => data?.products ? data?.products : data?.other_products).join(", ")}
                                    fullWidth
                                />
                            </Grid>
                        )
                    }
                </Grid>
            </Box>
        )
    }


    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                {
                    localStorage.getItem('isEmployee') === 'true' ? (
                        <AppHeader navigation={this.props.navigation} />
                    ) : (
                        <AppHeaderUser navigation={this.props.navigation} />
                    )
                }

                <MainContainer>
                    <Loader loading={this.state.isLoading} />
                    <Grid container spacing={2} style={{ paddingBottom: 50 }}>
                        {(!this.state.isLoading && this.state.ticketDetails?.type === "Product Query") && this.ProductQueryDetails()}
                        {!this.state.isLoading && this.state.ticketDetails?.sample_request_id && this.SampleQueryDetails()}
                        {!this.state.isLoading && this.state.ticketDetails?.set_meeting_id && this.MeetingDetails()}
                        {(!this.state.isLoading && this.state.ticketDetails?.type === "Connect With Us" && !this.state.ticketDetails?.set_meeting_id) && this.ConnectWithUs()}
                    </Grid>
                </MainContainer>
                {this.state.ticketDetails && (
                    <AppFooter isLoading={this.state.isLoading} />
                )}
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const QueryDialog = withStyles({
    root: {
        "& .MuiDialogTitle-root": {
            padding: '16px 0px'
        },
        "& .MuiDialog-paperScrollPaper": {
            padding: '0px 10px 0px 10px',
            borderRadius: '10px',
        },
        "& .MuiDialogContent-root": {
            padding: '8px 24px',
            textAlign: 'center',
            paddingBottom: '25px'
        },
        "& .MuiDialog-paperWidthSm": {
            maxWidth: '500px',
        },
        "& .MuiDialogActions-root": {
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0px',
            paddingBottom: '30px',
        },
        "& .WithStyles\(ForwardRef\(Button\)\)-root-10": {
            "@media (maxWidth: 600px)": {
                width: 300
            }
        }
    }
})(Dialog);

const webStyle = {
    Tab1: {
        borderTopLeftRadius: "8px",
        borderBottomLeftRadius: "8px",
    },
    DetailDataLabel: {
        color: 'rgba(0, 73, 131, 1)',
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '16px',
        paddingTop: '10px',
    },
    SubmittedName: {
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '14px',
        color: '#5A9CF0',
        paddingTop: '5px',
        paddingLeft: 0
    },
    queryPopUpText: {
        fontWeight: 700,
        color: 'rgba(33, 43, 54, 1)',
        fontFamily: 'SFProDisplay',
        fontSize: '20px',
        margin: '0px 15px',
    },
    PopUpText: {
        fontWeight: 700,
        color: '#000000',
        fontFamily: 'SFProDisplay',
        fontSize: '20px',
        margin: '0px 15px',
    },
    tab3: {
        borderEndEndRadius: "8px",
        borderTopRightRadius: "8px",
    },
    detailTitle: {
        color: "rgba(33, 43, 54, 1)",
        fontFamily: "SFProDisplay",
        fontWeight: 700,
        fontSize: "18px",
        paddingTop: '10px'
    },
    inputLabel: {
        fontSize: "14px",
        fontFamily: "SFProDisplay",
        fontWeight: 700,
        color: "#1A3B71",
        paddingBottom: "5px",
    },
    termsText: {
        color: "#727096",
        fontFamily: "SFProDisplay",
        fontWeight: 400,
        fontSize: "16px",
        paddingTop: "4px",
    },
    buttonStyle: {
        width: "100%",
        height: "45px",
        marginTop: "40px",
        border: "none",
        backgroundColor: "rgb(98, 0, 238)",
    },
    checkedIcon: {
        borderRadius: 4,
        border: "1px solid #727096",
        width: 15,
        height: 15,
        backgroundColor: "white",
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='black'/%3E%3C/svg%3E\")",
        "input:hover ~ &": {},
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    icon: {
        borderRadius: 4,
        width: 15,
        height: 15,
        backgroundColor: "white",
        border: "1px solid #727096",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2,
        },
        "input:hover ~ &": {
            backgroundColor: "white",
        },
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    countryPlaceHolder: {
        fontStyle: "normal",
        fontFamily: "SFProDisplay",
        fontWeight: 400,
        fontSize: "16px",
        color: "rgba(218, 224, 241, 1)",
    },
    select: {
        height: "44px",
        borderRadius: "8px",
        marginTop: '5px',
    },
    privacyText: {
        color: "rgba(69, 158, 255, 1)",
    },
    DetailBox: {
        // height: `-webkit-fill-available`,
    },
    ButtonBox: {
        // display: 'flex',
        // alignItems: 'flex-end',
        paddingLeft: '33px',
        paddingTop: '8%',
        paddingBottom: '30px'
    },
    grids: {
        paddingTop: '18px',
        paddingBottom: '0px'
    },
    customerName: {
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '18px',
        color: '#1D3D66',
        margin: '10px 0px 10px 30px'
    },
    emailText: {
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '16px',
        margin: '10px 10px'
    },
    dataText: {
        fontFamily: 'SFProDisplay',
        fontWeight: 500,
        fontSize: '16px',
        margin: '6px 10px'
    },
    DetailHeading: {
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '20px',
        color: '#1D3D66',
        margin: '5px 0px 10px 10px'
    }
};

const CustomTab = withStyles({
    root: {
        border: "1px solid rgba(4, 60, 116, 1)",
        width: "100%",
        color: "rgba(4, 60, 116, 1)",
        fontFamily: "SFProDisplay",
        fontWeight: 700,
        fontSize: "16px",
        textTransform: "none",
        "& .PrivateTabIndicator-colorSecondary-4": {
            display: "none",
        },
        "@media (max-width: 600px)": {
            width: "25%",
        },
    },
})(Tab);

const CustomTabs = withStyles({
    root: {
        maxHeight: "48px",
        marginLeft: "24px",
        "& .MuiTab-root": {
            minHeight: '34px',
            maxWidth: '240px',
        },
        "& .MuiTabs-indicator": {
            display: "none",
        },
        "& .Mui-selected": {
            background: "rgba(225, 239, 255, 1)",
        },
        "@media (max-width: 470px)": {
            maxHeight: "80px",
        },
        "@media (max-width: 600px)": {
            marginLeft: "0px",
        },
    },
})(Tabs);

const InputField: any = withStyles({
    root: {
        background: "inherit",
        margin: "5px 0px 0px 0px",
        "& ::placeholder": {
            color: 'rgba(218, 224, 241, 1)',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
            fontSize: '14px',
            opacity: 1,
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(214, 214, 214, 1)",
        },
        "& .MuiInputBase-input": {
            height: '15px',
        },
        "& .MuiOutlinedInput-input": {
            padding: `12px 14px !important`
        },
        "& .MuiOutlinedInput-root": {
            fontFamily: "SFProDisplay",
            fontWeight: 400,
            backgroundColor: "rgba(255, 255, 255, 1)",
            fontSize: "14px",
            "& fieldset": {
                height: '40px',
                borderColor: "rgba(218, 224, 241, 1)",
                borderRadius: "8px",
                borderWidth: 1,
            },
            ".Mui-focused": {
                borderColor: "rgba(218, 224, 241, 1)",
            },
        },
        "& .MuiFormHelperText-contained": {
            marginLeft: "0px !important",
        },
        "& .MuiInputBase-input.Mui-disabled": {
            background: "#F1F4FA",
            borderRadius: "8px",
            height: 13,
            color: 'rgba(106, 116, 165, 1)',
            fontSize: '14px',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
        },
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(218, 224, 241, 1)",
            borderWidth: '1px'
        }
    },
})(TextField);

const TextArea: any = withStyles({
    root: {
        background: "inherit",
        margin: "5px 0px 0px 0px",
        "& ::placeholder": {
            color: 'rgba(218, 224, 241, 1)',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
            fontSize: '16px',
            opacity: 1,
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(214, 214, 214, 1)",
        },
        "& .MuiInputBase-input": {
            height: '15px',
        },
        "& .MuiOutlinedInput-input": {
            padding: `14.2px 14px !important`
        },
        "& .MuiOutlinedInput-multiline": {
            padding: 0
        },
        "& .MuiOutlinedInput-root": {
            fontFamily: "SFProDisplay",
            fontWeight: 400,
            backgroundColor: "rgba(255, 255, 255, 1)",
            fontSize: "16px",
            "& fieldset": {
                borderColor: "rgba(218, 224, 241, 1)",
                borderRadius: "8px",
                borderWidth: 1,
            },
            ".Mui-focused": {
                borderColor: "rgba(218, 224, 241, 1)",
            },
        },
        "& .MuiFormHelperText-contained": {
            marginLeft: "0px !important",
        },
        "& .MuiInputBase-input.Mui-disabled": {
            background: "#F1F4FA",
            borderRadius: "8px",
            height: 13,
            color: 'rgba(106, 116, 165, 1)',
            fontSize: '16px',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
        },
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(218, 224, 241, 1)",
            borderWidth: '1px'
        }
    },
})(TextField);

const CheckBoxLabel = withStyles({
    root: {
        // alignItems: "flex-start",
        "& .MuiTypography-body1": {
            fontFamily: "SFProDisplay",
            fontWeight: 400,
            fontSize: "18px",
            color: "rgba(58, 63, 99, 1)",
        },
        "&.MuiFormControlLabel-root": {
            marginRight: `1px !important`
        },
        "& .MuiIconButton-label": {
            marginBottom: `-5px !important`
        }
    },
})(FormControlLabel);

const SaveButton = withStyles({
    root: {
        color: "rgba(255, 255, 255, 1)",
        // backgroundColor: "rgba(4, 60, 116, 1)",
        height: "40px",
        width: 150,
        borderRadius: "8px",
        fontFamily: "SFProDisplay",
        fontWeight: 700,
        fontSize: "18px",
        marginTop: "5px",
        boxShadow: "none",
        textTransform: "none",
        marginRight: '10px',
        "&.MuiButton-contained": {
            backgroundColor: 'rgba(4, 60, 116, 1)',
            "&:hover": {
                backgroundColor: 'rgba(4, 60, 116, 1)',
            }
        },
        "&.MuiButton-outlined": {
            border: '1px solid rgba(4, 60, 116, 1)',
            color: 'rgba(4, 60, 116, 1)',
            padding: '5px 15px',
            "&:hover": {
                backgroundColor: 'transparent',
            }
        },
        "&:hover": {
            backgroundColor: "rgba(4, 60, 116, 1)",
            boxShadow: "none",
        },
        "@media (max-width: 600px)": {
            width: "100%",
            marginLeft: 0,
        },
    },
})(Button);

const EditButton: any = withStyles({
    root: {
        color: "rgba(255, 255, 255, 1)",
        backgroundColor: "rgba(4, 60, 116, 1)",
        height: "34px",
        width: 110,
        borderRadius: "8px",
        fontFamily: "SFProDisplay",
        fontWeight: 700,
        fontSize: "16px",
        marginTop: "5px",
        boxShadow: "none",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "rgba(4, 60, 116, 1)",
            boxShadow: "none",
        },
        "@media (max-width: 600px)": {
            width: "100%",
            marginLeft: 0,
        },
    },
})(Button);

const CUSButton = withStyles({
    root: {
        color: "#71769D",
        backgroundColor: "#F3F4F9",
        height: "44px",
        width: 150,
        borderRadius: "8px",
        fontFamily: "SFProDisplay",
        fontSize: "16px",
        fontWeight: 700,
        marginTop: "5px",
        boxShadow: "none",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#F3F4F9",
            boxShadow: "none",
        },
        "@media (max-width: 600px)": {
            width: "100%",
            marginLeft: 0,
        },
    },
})(Button);

const MainContainer: any = withStyles({
    root: {
        "& .css-yk16xz-control": {
            borderRadius: '8px',
            borderColor: 'rgba(218, 224, 241, 1)',
            marginTop: '3px',
            height: '40px'
        },
        padding: "24px 36px 24px 48px",
        "@media (max-width: 600px)": {
            padding: "24px 24px 24px 24px",
            "& .MuiBox-root-8": {
                padding: "24px 0px 24px 0px",
            },
            "& ..MuiBox-root-30": {
                padding: "24px 0px 24px 0px",
            },
            "& .MuiTypography-body1": {
                marginLeft: "0px !important",
            },
        },
        "& .noField": {
            display: 'flex',
            justifyContent: 'center',
            "& .MuiInputBase-input.Mui-disabled": {
                textAlign: 'center'
            }
        }
    },
})(Container);

const CustomSelect = withStyles({
    root: {
        "&.MuiOutlinedInput-input": {
            padding: '13px 14px',
        }
    }
})(Select);

const SelectField = withStyles({
    root: {
        "& .MuiOutlinedInput-root": {
            fontFamily: "SFProDisplay",
            fontWeight: 500,
            backgroundColor: "rgba(255, 255, 255, 1)",
            fontSize: "16px",
            color: '#1F436D',
        },
        "& .MuiInputBase-input": {
            fontFamily: "SFProDisplay",
            fontWeight: 500,
            backgroundColor: "rgba(255, 255, 255, 1)",
            fontSize: "16px",
            color: '#1F436D',
        },
        width: '100%',
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(218, 224, 241, 1)",
            borderWidth: 1,
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(218, 224, 241, 1)",
            borderWidth: 1,
        },
        "& .MuiSelect-icon": {
            fill: "rgba(4, 60, 116, 1)",
        },
        "& .MuiOutlinedInput-input": {
            padding: '11px 14px'
        },
    }
})(FormControl);

const CustomCard = withStyles({
    root: {
        boxShadow: 'none',
        border: '1px solid rgba(218, 224, 241, 1)',
        borderRadius: '8px',
        "& .MuiSvgIcon-root": {
            height: '20px',
            width: '20px',
        }
    }
})(Card);

const IconBox = withStyles({
    root: {
        background: '#DAE0EC',
        height: '26px',
        width: '26px',
        borderRadius: '50%',
        float: 'right',
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'center',
        padding: '0px 0px 0px 0px',
        "& .MuiSvgIcon-root": {
            height: '20px',
            width: '20px'
        }
    }
})(Box)

const TabPanel = withStyles({
    container: {
        padding: 24,
        "@media (max-width: 600px)": {
            padding: '24px 0px 24px 0px'
        },
    },
})(CustomTabPanel)

const styles = () => ({
    container: {
        "@media (max-width: 600px)": {
            paddingLeft: 20,
            paddingRight: 20
        }
    },
    bamEditSave: {
        "@media (max-width: 600px)": {
            paddingLeft: '28px !important',
            paddingRight: 18
        }
    },
    productsAddCancel: {
        "@media (max-width: 600px)": {
            marginLeft: '0px !important',
            marginTop: '20px'
        }
    },
    addMoreProducts: {
        marginLeft: '5px',
        "@media (max-width: 600px)": {
            marginLeft: '0px !important',
            marginRight: '0px !important'
        }
    }
})

export default withStyles(styles)(TicketDetails)
// Customizable Area End
