import React from "react";
// Customizable Area Start
import {
  Container, Typography, Grid, Box, CardContent, Card, TextField, InputAdornment,
  IconButton,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { AppHeader } from "../../../components/src/AppHeader.web";
import AppFooter from "../../../components/src/AppFooter.web";
import { withStyles } from '@material-ui/core/styles';
import MyInvoicesController, { Props } from "./MyInvoicesController";
import Loader from "../../../components/src/Loader.web";
import { NavLink as RLink } from "react-router-dom";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CalendarMonthIcon from '@material-ui/icons/CalendarTodayOutlined';
import CustomPagination from "../../../components/src/CustomPagination.web";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class MyInvoices extends MyInvoicesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.searchInputRefVar = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  searchInputRefVar: React.RefObject<HTMLInputElement>;
  // Customizable Area End

  render() {
    return (
      <ThemeProvider theme={theme}>
        <AppHeader navigation={this.props.navigation} />
        <Loader loading={this.state.is_loading} />
        <InvoiceGrid style={{ marginBottom: "49px", }}>
          <Grid container justifyContent="space-between" style={{ marginTop: '32px' }}>
            <Typography style={webStyle.detailTitle}>My Invoices</Typography>
            <Grid item lg={4} md={4} sm={5} xs={10}>
              <InputField
                id="searchFolder"
                variant="outlined"
                onChange={this.handleSeachInvoices}
                placeholder="Search Invoice"
                fullWidth
                InputProps={{
                  startAdornment:
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <SearchIcon style={{ fill: 'rgb(4, 60, 116)', fontSize: '20px' }}></SearchIcon>
                      </IconButton>
                    </InputAdornment>
                }}
              />
            </Grid>
          </Grid>
          <Container style={{ padding: 0, position: 'relative' }}>
            <Box>
              {!this.state.is_loading ? <Grid container spacing={3}>
                {this.state?.allcontectInvioce && this.state.allcontectInvioce.map((data: any, index: number) => {
                  return (
                    <Grid item lg={3} md={4} sm={6} style={{ width: 'inherit' }} key={index}>
                      <RLink to={`/InvoiceDetails/${data.attributes.customer_master_id}/${data.id}`}>
                        <InvoiceCard>
                          <CardContent>
                            <Grid container justifyContent="space-between">
                              <Typography style={webStyle.customerName}>
                                {data.attributes.customer_invoice_catalogue} -
                                <span style={{ fontWeight: 400, color: "#6a6ea5" }}>
                                  {data.attributes.invoice}
                                </span>
                              </Typography>
                              <Typography style={webStyle.creationDate}>{moment(data?.attributes?.invoice_created_date, "DD/MM/YYYY").format("DD-MM-YYYY")}</Typography>
                            </Grid>
                            <Typography style={{ display: 'flex', alignItems: 'center' }}>
                              <MailOutlineIcon style={{ fill: '#1D3D66' }} />
                              <Typography component="span" style={webStyle.emailText}>{data.attributes.customer_invoice_person}</Typography>
                            </Typography>
                            <Typography style={{ display: 'flex', alignItems: 'center' }}>
                              <CalendarMonthIcon style={{ fill: '#1D3D66' }} />
                              <Typography component="span" style={webStyle.emailText}>{data.attributes.invoice_date}</Typography>
                            </Typography>
                          </CardContent>
                        </InvoiceCard>
                      </RLink>
                    </Grid>
                  )
                })}
                {
                  this.state?.allcontectInvioce?.length === 0 &&
                  <span style={{ marginLeft: "15px", marginTop: "30px", paddingBottom: '15%', transform: "translate(0px, -25px)", fontFamily: "SFProDisplay" }}>
                    Sorry, No invoices found.
                  </span>}
              </Grid>
                :
                <Loader loading={this.state.is_loading} />
              }
              {
                this.state.allcontectInvioce.length !== 0 &&
                <Grid item xs={12} sm={12} lg={12} style={{ marginTop: "10px" }}>
                  <CustomPagination
                    data-testid="customPaginationId"
                    page={this.state.contactInvoiceListingMeta?.pagination_details.current_page}
                    handlePageChange={(e: any, p: any) => this.handlePageChange(e, p)}
                    count={this.state.contactInvoiceListingMeta?.pagination_details.total_pages}
                    arrayLength={this.state.contactInvoiceListingMeta?.pagination_details.total_count}
                    per_page={this.state.perPageRecord}
                  />
                </Grid>
              }
            </Box>
          </Container>
        </InvoiceGrid>
        <AppFooter isLoading={this.state.is_loading} />
      </ThemeProvider >
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const InvoiceGrid: any = withStyles({
  root: {
    padding: '0px 85px 10px 85px',
    "@media (max-width: 1100px)": {
      padding: `10px 53px 10px 55px !important`,
    },
    "@media (max-width: 600px)": {
      padding: `0px 15px !important`,
    },
    "& .MuiTypography-h5": {
      fontFamily: "SFProDisplay",
      fontWeight: 700,
      fontSize: `32px !important`,
    },
    "& .MuiList-padding": {
      paddingTop: `0px !important`,
      paddingBottom: `0px !important`,
    },
    "& .MuiGrid-item": {
      "@media (max-width: 660px)": {
        justifyContent: "center !important",
        margin: "0px auto",
        padding: "0",
      },
    },
    "& .MuiGrid-container": {
      "@media (max-width: 660px)": {
        flexDirection: 'column !important',
      },
    }
  }
})(Grid);

const InvoiceCard: any = withStyles({
  root: {
    boxShadow: 'none',
    border: '1px solid rgba(218, 224, 241, 1)',
    borderRadius: '8px',
    height: '100%',
    "& .MuiSvgIcon-root": {
      height: '20px',
      width: '20px',
    }
  }
})(Card);

const webStyle = {
  detailTitle: {
    color: "#043c74",
    fontFamily: "SFProDisplay",
    fontSize: "24px",
    paddingBottom: "20px",
    fontWeight: 700,
  },
  emailText: {
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '16px',
    margin: '10px 10px'
  },
  customerName: {
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '16px',
    color: '#1D3D66',
    margin: '5px 0px 5px 0px',
    width: '65%'
  },
  creationDate: {
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '12px',
    color: '#1D3D66',
    margin: '7px 0px 5px 0px'
  },
};

const InputField = withStyles({
  root: {
    background: "inherite",
    margin: "5px 0px 0px 0px",
    "& ::placeholder": {
      color: 'rgba(218, 224, 241, 1)',
      fontFamily: 'SFProDisplay',
      fontWeight: 400,
      fontSize: '14px',
      opacity: 1,
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: "SFProDisplay",
      fontWeight: 400,
      backgroundColor: "rgba(255, 255, 255, 1)",
      fontSize: "14px",
      "& fieldset": {
        height: '35px',
        borderColor: "rgba(218, 224, 241, 1)",
        borderRadius: "8px",
        borderWidth: 1,
      },
      ".Mui-focused": {
        borderColor: "rgba(218, 224, 241, 1)",
      },
    },
    "& .MuiIconButton-root": {
      padding: '0px',
    },
    "& .MuiInputBase-input": {
      height: '15px',
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(214, 214, 214, 1)",
    },
    "& .MuiOutlinedInput-input": {
      padding: `9.2px 14px !important`,
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px !important",
    },
    "& .MuiInputBase-input.Mui-disabled": {
      background: "#F1F4FA",
      borderRadius: "8px",
      height: 13,
      color: 'rgba(106, 116, 165, 1)',
      fontSize: '14px',
      fontFamily: 'SFProDisplay',
      fontWeight: 400,
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(218, 224, 241, 1)",
      borderWidth: '1px'
    }
  },
})(TextField);

// Customizable Area End
