import React from "react";
import AppFooter from "../../../components/src/AppFooter.web";
import { AppHeader } from "../../../components/src/AppHeader.web";
import ConnectWithUsController, { Props } from "./ConnectWithUsController";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Container,
  Typography,
  InputLabel,
  FormControlLabel,
  Box,
  Checkbox,
  TextField,
  Button,
  DialogContent,
  DialogActions,
  MenuItem,
  Dialog,
  Select,
  ListItemText,
  OutlinedInput,
  FormHelperText,
} from "@material-ui/core";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector";
import {
  handlePrivacyPolicyRedirection,
  handleTermsOfUseRedirection,
} from "../../../components/src/common";
import Loader from "../../../components/src/Loader.web";

export default class ConnectWithUsWeb extends ConnectWithUsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area End

  render() {
    return (
      <>
        <AppHeader navigation={this.props.navigation}/>
        <MainContainer>
          <Loader loading={this.state.loading} />
          <Typography style={webStyle.detailTitle}>Connect with us</Typography>
          {this.state.requestSuccess && (
            <QueryDialog
              open={this.state.requestSuccess}
              onClose={this.closeSuccess}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <Typography style={webStyle.queryPopUpText}>
                  Your request has been sent to Biocon team
                </Typography>
              </DialogContent>
              <DialogActions>
                <SaveButton data-testId="quercyclosebutton" onClick={this.closeSuccess}>OK</SaveButton>
              </DialogActions>
            </QueryDialog>
          )}
          <form
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              e.preventDefault();
              this.saveConnectWithUs();
            }}
            data-testId="connectWithUsForm"
          >
            <Grid container spacing={7}>
              <Grid item lg={6} md={6} sm={6} xs={12} style={webStyle.grids}>
                <InputLabel htmlFor="name" style={webStyle.inputLabel}>
                  First Name<span style={{ color: "red" }}>*</span>
                </InputLabel>
                <InputField
                  id="name"
                  data-testId='fname'
                  variant="outlined"
                  required
                  disabled={!this.state.isGuest}
                  placeholder="Enter Your First Name"
                  fullWidth
                  value={this.state.firstName}
                  onChange={this.handleMeetingChange("firstName").bind(this)}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12} style={webStyle.grids}>
                <InputLabel htmlFor="name" style={webStyle.inputLabel}>
                  Last Name<span style={{ color: "red" }}>*</span>
                </InputLabel>
                <InputField
                  id="name"
                  data-testId='lname'
                  variant="outlined"
                  required
                  disabled={!this.state.isGuest}
                  placeholder="Enter Your Last Name"
                  fullWidth
                  value={this.state.lastName}
                  onChange={this.handleMeetingChange("lastName").bind(this)}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                <InputLabel htmlFor="company" style={webStyle.inputLabel}>
                  Company name<span style={{ color: "red" }}>*</span>
                </InputLabel>
                <InputField
                  id="company"
                  data-testId='company'
                  variant="outlined"
                  placeholder="Enter Company Name"
                  fullWidth
                  disabled={!this.state.isGuest}
                  required
                  value={this.state.companyName}
                  onChange={this.handleMeetingChange("companyName").bind(this)}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12} style={webStyle.grids}>
                <InputLabel htmlFor="email" style={webStyle.inputLabel}>
                  Email Id<span style={{ color: "red" }}>*</span>
                </InputLabel>
                <InputField
                  id="email"
                  data-testId='email'
                  variant="outlined"
                  required
                  disabled={!this.state.isGuest}
                  placeholder="Enter Email ID"
                  fullWidth
                  value={this.state.emailId}
                  onChange={this.handleMeetingChange("emailId").bind(this)}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12} style={webStyle.grids}>
                <InputLabel htmlFor="phoneNo" style={webStyle.inputLabel}>
                  Country<span style={{ color: "red" }}>*</span>
                </InputLabel>
                <CustomGrid
                  container
                  item
                  md={12}
                  lg={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "6px", width: "100%" }}
                  className={
                    this.state.loading === true
                      ? "countryCodeIndex"
                      : "countryCodeIndex2"
                  }
                >
                  <Grid
                    item
                    md={2}
                    lg={2}
                    sm={4}
                    xs={3}
                    style={{ height: 44, paddingRight: 15 }}
                  >
                    <InputField
                      style={{ height: 44, margin: 0 }}
                      inputProps={{
                        style: { textAlign: "center" },
                      }}
                      id="country"
                      disabled
                      variant="outlined"
                      value={`+${this.state.phoneCountryCode}`}
                      required
                      placeholder="Enter your country name"
                      fullWidth
                    />
                  </Grid>

                  <Grid item md={10} lg={10} sm={8} xs={9}>
                    <CountryCodeSelector
                      data-testId="countrycodeselector"
                      navigation={""}
                      style={{}}
                      id="countryCode"
                      disable={false}
                      onChange={this.handlePhoneCountryCode}
                      allowPropChange={false}
                      value={this.state.phoneCountryCode}
                      placeHolder="Please select country"
                      isOnlyCountryName={true}
                    />
                  </Grid>
                </CustomGrid>
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12} style={webStyle.grids}>
                <InputLabel htmlFor="phoneNum" style={webStyle.inputLabel}>
                  Phone number<span style={{ color: "red" }}>*</span>
                </InputLabel>
                <InputField
                  id="phoneNum"
                  data-testId='phoneNum'
                  type="tel"
                  inputProps={{maxLength: 14}}
                  variant="outlined"
                  placeholder="Enter Phone No"
                  fullWidth
                  value={this.state.phoneNumber}
                  onChange={this.handleMeetingChange("phoneNumber").bind(this)}
                />
                {(this.state.phoneNumber?.length === 0 && this.state.phoneNumberError) && <span data-testid="phonenumberdigiterror" style={webStyle.allFieldError}>{this.state.phoneNumberError}</span>}
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12} style={webStyle.grids}>
                <InputLabel htmlFor="comments" style={webStyle.inputLabel}>
                  Comments
                </InputLabel>
                <TextArea
                  id="comments"
                  data-testId="comments"
                  multiline
                  rows={4}
                  variant="outlined"
                  placeholder="Enter Your Comments here..."
                  value={this.state.comments}
                  onChange={this.handleMeetingChange("comments").bind(this)}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12} style={webStyle.grids}>
                <InputLabel htmlFor="apiUMO" style={webStyle.inputLabel}>
                  Products<span style={{ color: "red" }}>*</span>
                </InputLabel>
                <Select
                  labelId="apiUMO"
                  displayEmpty
                  id="apiUMO"
                  data-testid="productselect"
                  variant="outlined"
                  fullWidth
                  multiple
                  placeholder="Select Product"
                  style={webStyle.select}
                  value={this.state.selectedProducts}
                  onChange={this.handleProductChange}
                  renderValue={(render) => {
                    if (this.state.selectedProductNames.length === 0) {
                      return (
                        <em style={webStyle.selectPlaceholder } data-testId="slectpp">
                          Select Product
                        </em>
                      );
                    }
                    return this.state.selectedProductNames.join(", ");
                  }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem disabled value={0}>
                    <em style={webStyle.selectPlaceholder}>Select Product</em>
                  </MenuItem>
                  {this.state.productsList.map((data: any, index: number) => {
                    return (
                      <MenuItem key={`product${data.id}`} value={data.id}>
                        <Checkbox
                          checked={
                            this.state.selectedProducts.findIndex(
                              (obj: any) => obj === data.id
                            ) > -1
                          }
                        />
                        <ListItemText primary={data.value} />
                      </MenuItem>
                    );
                  })}
                  {this.state.productsList.length === 0 && (
                    <MenuItem value={-1}>No Data</MenuItem>
                  )}
                </Select>
                {this.state.selectedProducts.length === 0 ? <span style={webStyle.allFieldError}>{this.state.selectedProductsError}</span> : null}
                <FormHelperText>
                  "You can choose multiple products."
                </FormHelperText>
              </Grid>
              {this.state.selectedProductNames.includes("Other") && (
                <Grid item lg={6} md={6} sm={6} xs={12} style={webStyle.grids}>
                  <InputLabel htmlFor="phoneNum" style={webStyle.inputLabel}>
                    Other<span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <InputField
                    id="prodcutName"
                    required
                    variant="outlined"
                    placeholder="Enter Product Name"
                    fullWidth
                    value={this.state.otherProduct}
                    onChange={this.handleMeetingChange("otherProduct").bind(
                      this
                    )}
                  />
                </Grid>
              )}
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  paddingTop: 0,
                  paddingLeft: 30,
                  paddingBottom: "10px",
                }}
              >
                <CheckBoxLabel
                 data-testid="termsconditionlabel"
                  control={
                    <Checkbox
                      color="default"
                      disableRipple
                      checkedIcon={<span style={webStyle.checkedIcon} />}
                      checked={this.state.isTermsAndCondition}
                      onChange={this.handleTermsConditionClick}
                      icon={<span style={webStyle.icon} />}
                      inputProps={{ "aria-label": "decorative checkbox" }}
                    />
                  }
                  label=""
                />
                <Typography style={webStyle.termsText} component={"span"}>
                  {this.state.privacyPolicyLabel}
                  <Typography
                    onClick={handlePrivacyPolicyRedirection}
                    component="span"
                    style={webStyle.privacyText}
                  >
                    {" "}
                    {this.state.privacyPolicyText}
                  </Typography>{" "}
                  and
                  <Typography
                    onClick={handleTermsOfUseRedirection}
                    component="span"
                    style={webStyle.privacyText}
                  >
                    {" "}
                    {this.state.termslabelText}
                  </Typography>
                  <Typography component="span">
                    {" "}
                    {this.state.termsLabel}
                  </Typography><br/>
                  {(this.state.isTermsAndCondition === false && this.state.termsConditionErrorConnect !== "") && <span style={webStyle.allFieldErrorRobot}>{this.state.termsConditionErrorConnect}</span>}
                </Typography>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: 0, paddingLeft: 30 }}>
            <CheckBoxLabel
              control={
                <Checkbox
                  color="default"
                  disableRipple
                  checkedIcon={<span style={webStyle.checkedIcon} />}
                  checked={this.state.isRobot}
                  onChange={this.handleIsRobotClick}
                  icon={<span style={webStyle.icon} />}
                  inputProps={{ "aria-label": "decorative checkbox" }}
                />
              }
              label=""
            />
            <Typography style={webStyle.termsText} component={"span"}>
              I'm not a robot
            </Typography>
            <Grid>{(this.state.isRobot === false && this.state.isRobotErrorMsg !== "") && <span style={webStyle.allFieldErrorRobot}>{this.state.isRobotErrorMsg}</span>}</Grid>
          </Grid>

              <Grid
                container
                style={{ paddingLeft: "20px", paddingRight: "20px" , paddingBottom: '15px'}}
              >
                <SaveButton type="submit" variant="contained" data-testId="submitbutton">
                  Send
                </SaveButton>
              </Grid>
            </Grid>
          </form>
        </MainContainer>
        <AppFooter isLoading={this.state.loading} />
      </>
      // Customizable Area End
    );
  }
}

export const SaveButton: any = withStyles({
  root: {
    color: "rgba(255, 255, 255, 1)",
    backgroundColor: "rgba(4, 60, 116, 1)",
    height: "40px",
    width: 150,
    borderRadius: "8px",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    fontSize: "18px",
    marginBottom: "5px",
    marginLeft: "8px",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "rgba(4, 60, 116, 1)",
      boxShadow: "none",
    },
    "@media (max-width: 600px)": {
      width: "100%",
      marginLeft: 0,
    },
  },
})(Button);

export const QueryDialog: any = withStyles({
  root: {
    "& .MuiDialog-paperScrollPaper": {
      minWidth: "fit-content",
      padding: "0px 10px 0px 10px",
    },
    "& .MuiDialogContent-root": {
      paddingLeft: "2px",
    },
    "& .MuiDialogActions-root": {
      alignItems: "center",
      justifyContent: "center",
      padding: "0px",
      paddingBottom: "8px",
    },
    "& .WithStyles(ForwardRef(Button))-root-10": {
      "@media (maxWidth: 600px)": {
        width: 300,
      },
    },
  },
})(Dialog);

const MainContainer: any = withStyles({
  root: {
    padding: "24px 65px 60px 72px",
    "& .css-1hb7zxy-IndicatorsContainer": {
      height: `42px !important`,
      borderRadius: `12px !important`,
    },
    "& .css-1fhf3k1-control": {
      borderRadius: "8px",
      background: "#F1F4FA",
    },
    "& .css-1wa3eu0-placeholder": {
      color: 'rgba(4, 60, 160, 0.65)',
      fontFamily: 'SFProDisplay',
      fontWeight: 400,
      fontSize: '16px',
      opacity: 1,
    },
    "& .css-109onse-indicatorSeparator": {
      display: "none",
    },
    "& .css-tlfecz-indicatorContainer": {
      color: "#153E78",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#BABED8",
      borderWidth: 1,
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px",
    },
    "@media (max-width: 600px)": {
      padding: "24px 24px 24px 24px",
      "& .MuiBox-root-8": {
        padding: "24px 0px 24px 0px",
      },
      "& ..MuiBox-root-30": {
        padding: "24px 0px 24px 0px",
      },
      "& .MuiTypography-body1": {
        marginLeft: "0px !important",
      },
    },
  },
})(Container);

const TextArea:any = withStyles({
  root: {
    width: "100%",
    paddingTop: "10px",
    "& .MuiOutlinedInput-root": {
      fontWeight: 400,
      fontFamily: "SFProDisplay",
      backgroundColor: "rgba(255, 255, 255, 1)",
      fontSize: "16px",
      color: "rgba(106, 116, 165, 1)",
      "& fieldset": {
        fontSize: "16px",
        borderColor: "rgba(214, 214, 214, 1)",
        borderRadius: "8px",
        borderWidth: 1,
        fontWeight: 400,
        color: 'rgba(106, 116, 165, 1)',
      },
      "& ::placeholder": {
        color: 'rgba(4, 60, 160, 0.65)',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '16px',
        opacity: 1,
      },
    },
  },
})(TextField);

const CheckBoxLabel:any = withStyles({
  root: {
    alignItems: "flex-start",
    marginRight: 0,
    "& .MuiTypography-body1": {
      fontFamily: "SFProDisplay",
      fontWeight: 400,
      fontSize: "18px",
      color: "rgba(58, 63, 99, 1)",
    },
  },
})(FormControlLabel);

const InputField: any = withStyles({
  root: {
    background: "inherite",
    margin: "5px 0px 5px 0px",
    "& ::placeholder": {
      color: 'rgba(4, 60, 160, 0.65)',
      fontFamily: 'SFProDisplay',
      fontWeight: 400,
      fontSize: '16px',
      opacity: 1,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(214, 214, 214, 1)",
    },
    "& .MuiInputBase-input": {
      height: "15px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "14.5px 14px",
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: "SFProDisplay",
      fontWeight: 400,
      backgroundColor: "rgba(255, 255, 255, 1)",
      fontSize: "16px",
      "& fieldset": {
        height: "45px",
        borderColor: "rgba(214, 214, 214, 1)",
        borderRadius: "8px",
        borderWidth: 1,
      },
      ".Mui-focused": {
        borderColor: "red",
      },
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px !important",
    },
    "& .MuiInputBase-input.Mui-disabled": {
      background: "#F1F4FA",
      borderRadius: "8px",
      height: 13,
      color: "rgba(106, 116, 165, 1)",
      fontSize: "16px",
      fontFamily: "SFProDisplay",
      fontWeight: 400,
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(218, 224, 241, 1)",
      borderWidth: "1px",
    },
  },
})(TextField);

const AcceptButton: any = withStyles({
  root: {
    width: "140px",
    color: "rgba(255, 255, 255, 1)",
    backgroundColor: "#1A3B71",
    opacity: 1,
    boxShadow: "none",
    height: "42px",
    borderRadius: "4px",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    fontSize: "18px",
    margin: "30px 30px 30px 0px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1A3B71",
      boxShadow: "none",
    },
  },
})(Button);

const webStyle = {
  detailTitle: {
    color: "rgba(33, 43, 54, 1)",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    fontSize: "24px",
    marginBottom: "25px",
  },
  queryPopUpText: {
    fontWeight: 500,
    color: "rgba(33, 43, 54, 1)",
    fontFamily: "SFProDisplay",
    fontSize: "20px",
    margin: "0px 15px",
  },
  allFieldErrorRobot: {
    color: 'red',
    fontSize: '14px',
    fontFamily: 'SFProDisplay',
    marginLeft:'-3px',
  },
  select: {
    height: "42px",
    marginTop: "5px",
    borderRadius: "8px",
  },
  allFieldError:{
    color:'red',
    fontSize: '14px',
    fontFamily: 'SFProDisplay',
    margin: '5px',
  },
  selectPlaceholder: {
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "16px",
    color: "rgba(4, 60, 160, 0.65)",
    fontStyle: 'normal',
  },
  inputLabel: {
    fontSize: "16px",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    color: "#153E78",
    paddingBottom: "5px",
  },
  privacyText: {
    color: "rgba(69, 158, 255, 1)",
    cursor: "hand",
  },
  termsText: {
    fontStyle: "normal",
    color: "rgba(106, 116, 165, 1)",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "16px",
  },
  grids: {
    paddingBottom: "10px",
    paddingTop: "15px",
  },
  checkedIcon: {
    borderRadius: 3,
    marginLeft: "0px",
    marginBottom: "4px",
    border: "1px solid black",
    width: 18,
    height: 18,
    backgroundColor: "white",
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='black'/%3E%3C/svg%3E\")",
    "input:hover ~ &": {},
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 3,
    marginLeft: "0px",
    marginBottom: "4px",
    width: 18,
    height: 18,
    backgroundColor: "white",
    border: "1px solid black",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "white",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
};

export const CustomGrid: any = withStyles({
  root: {
    minWidth: "38.5%",
    marginRight: "8px",
    "& .css-tlfecz-indicatorContainer": {
      color: "#000000",
      paddingRight: "12px",
      fontFamily: "SFProDisplay",
      fontWeight: 400,
    },
    "& .css-1okebmr-indicatorSeparator": {
      display: "none",
      fontFamily: "SFProDisplay",
      fontWeight: 400,
    },
    "& .css-1wa3eu0-placeholder": {
      display: 'flex',
      color: 'rgba(4, 60, 160, 0.65)',
      fontFamily: 'SFProDisplay',
      fontSize: '16px',
      fontWeight: 500,
      opacity: 1,
    },
    "& .css-1uccc91-singleValue": {
      fontFamily: "SFProDisplay",
      fontSize: "16px",
      fontWeight: 500,
    },
    "& .css-yk16xz-control": {
      borderRadius: "8px",
      borderColor: "rgba(214, 214, 214, 1)",
      fontWeight: 500,
      fontFamily: "SFProDisplay",
      fontSize: "16px",
      width: "100%",
      marginRight: "8px",
    },
    "& .css-g1d714-ValueContainer": {
      position: "revert",
    },
    "& .css-1pahdxg-control:hover": {
      borderColor: "#3F51B5",
      boxShadow: "none",
      borderWidth: 2,
    },
    "& .css-1pahdxg-control": {
      borderColor: "#3F51B5",
      boxShadow: "none",
      borderWidth: 2,
    },
    "& .css-1hb7zxy-IndicatorsContainer": {
      // height: "3%",
      borderRadius: "8px",
      borderColor: "rgba(214, 214, 214, 1)",
      fontWeight: 500,
      fontFamily: "SFProDisplay",
      fontSize: "16px",
      with: "100%",
    },
    "& .css-26l3qy-menu": {
      fontFamily: "SFProDisplay",
      fontSize: "14px",
      fontWeight: 500,
    },
    "& .css-view-1dbjc4n": {
      margin: `0px !important`,
    },
  },
})(Grid);
