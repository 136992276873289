import React from "react";

import {
    withStyles,
} from "@material-ui/core/styles";
import {
    Grid,
    Tab,
    Tabs,
    Box,
    Container,
    TextField,
    InputAdornment,
    IconButton,
    TableRow,
    TableHead,
    TableCell,
    TableSortLabel,
    TableBody,
    Table,
    Button,
    Typography,
    TableContainer,
    Menu,
    MenuItem,
    FormGroup,
    FormControlLabel,
    Checkbox
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import AppHeader from "../../../components/src/EmployeeAppHeader.web";
import AppFooter from "../../../components/src/AppFooter.web";
import ArrowDropDownSharpIcon from '@material-ui/icons/ArrowDropDownSharp';
import AdminConsole3Controller, {
    ITicketData,
    Props,
} from "./OverdueTicketsController";
import CustomPagination from "../../../components/src/CustomPagination.web";
import Loader from "../../../components/src/Loader.web";
import { DownloadBox } from "./MasterContact.web";
import DownloadIcon from '@material-ui/icons/GetApp';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { NavLink } from "react-router-dom";
import CachedIcon from '@material-ui/icons/Cached';
import { CSVLink } from 'react-csv';
import { FilterIcon } from "./assets";
import CloseIcon from '@material-ui/icons/Close';
const PER_PAGE = 9;

class OverdueTickets extends AdminConsole3Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End


    render() {
        const { classes } = this.props;
        const open = Boolean(this.state.filterMenu);
        const openStatu = Boolean(this.state.filterStatusMenu);
        return (
            // Customizable Area Start
            <>
                <AppHeader navigation={this.props.navigation} />
                <MainContainer className={classes.container} >
                    <Loader loading={this.state.isLoading} />
                    <CustomTabs
                        onChange={this.handleChangeTab}
                        value={this.state.selectedTab}
                        aria-label="Tabs where each tab needs to be selected manually"
                    >
                        <NavLink to={`/TicketList`}>
                        <KeyboardBackspaceIcon
                            style={{
                                ...webStyle.cardButtonBack,
                            }}
                            fontSize="large"
                        />
                        </NavLink>
                        <Grid onClick={(e) => this.handleChangeTab(e, 0)} data-testid="ticketassignmetab">
                            <CustomTab
                                value={0}
                                label={
                                    <Box display='flex' flexDirection='row' alignItems='center' justifyContent='center'>
                                        <p>Overdue Tickets assigned to me</p>
                                        {this.state.TicketListingMeta?.pagination_details && !this.state.isFilteredTicketListing && <CountBox>{this.state.overdue_ticket_count}</CountBox>}
                                    </Box>
                                }
                                style={this.state.selectedTab === 0 ? { ...webStyle.Tab1, position: 'relative' } : webStyle.tab3}
                                disableRipple
                            />
                        </Grid>
                    </CustomTabs>

                    <SearchContainer container spacing={3}>
                        <Grid item lg={4} md={4} sm={4} xs={12}>
                            <InputField
                                id="ticketId"
                                data-testid='ticketIdsearch'
                                variant="outlined"
                                value={this.state.ticketId}
                                onChange={this.handleChange("ticketId").bind(this)}
                                required
                                placeholder="Search by Ticket ID"
                                fullWidth
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.setEnableField}
                                                edge="end"
                                            >
                                                <SearchIcon style={{ fill: '#8B95AF' }}></SearchIcon>
                                            </IconButton>
                                        </InputAdornment>,
                                        endAdornment: this.state.ticketId && (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    edge="end"
                                                    onClick={this.handleChange("ticketId").bind(this)}
                                                >
                                                <CloseIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                }}
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={12}>
                            <InputField
                                id="productName"
                                data-testid="productNameSearch"
                                variant="outlined"
                                value={this.state.productName}
                                onChange={this.handleChange("productName").bind(this)}
                                required
                                placeholder="Search by Product name"
                                fullWidth
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.setEnableField}
                                                edge="end"
                                            >
                                                <SearchIcon style={{ fill: '#8B95AF' }}></SearchIcon>
                                            </IconButton>
                                        </InputAdornment>,
                                        endAdornment: this.state.productName && (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    edge="end"
                                                    onClick={this.handleChange("productName").bind(this)}
                                                >
                                                <CloseIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                }}
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={12}>
                            <InputField
                                id="customerName"
                                data-testid="contactNameSearch"
                                variant="outlined"
                                value={this.state.customerName}
                                onChange={this.handleChange("customerName").bind(this)}
                                required
                                placeholder="Search by Customer Name"
                                fullWidth
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.setEnableField}
                                                edge="end"
                                            >
                                                <SearchIcon style={{ fill: '#8B95AF' }}></SearchIcon>
                                            </IconButton>
                                        </InputAdornment>,
                                    endAdornment: this.state.customerName && (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    edge="end"
                                                    onClick={this.handleChange("customerName").bind(this)}
                                                >
                                                <CloseIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                }}
                            />
                        </Grid>
                    </SearchContainer>
                    <TableContainer style={{ width: '100%', ...webStyle.table }}>
                        <Table aria-labelledby="tableTitle" size={'medium'}>
                            <TableHead style={{ background: '#E3EFFD' }}>
                                <TableRow>
                                    {this.state.headCells.map((headCell,index:number) => (
                                        <TableCell
                                            style={{
                                                maxWidth: '128px', background: '#E3EFFD',
                                                borderColor: 'rgba(170, 188, 206, 1)'
                                            }}
                                            align={headCell.numeric}
                                            key={headCell.id}
                                            padding={headCell.disablePadding ? 'none' : 'normal'}
                                            sortDirection={this.state.orderBy === headCell.label ? this.state.order : false}
                                        >
                                            {
                                                headCell.isSorting ? (
                                                    <TableSortLabel
                                                        active={headCell.isSorting}
                                                        direction={this.state.orderBy === headCell.id ? this.state.order : 'asc'}
                                                        style={{ ...webStyle.headerText, textAlign: headCell.numeric }}
                                                        onClick={() => this.stableSort(headCell.id)}
                                                        data-testid="tablesortlable"
                                                    >
                                                        {headCell.label}
                                                        <div style={{ paddingLeft: '5px' }}>
                                                        {
                                                            this.state.selectedQueryType.length > 0 ?
                                                                <img src={FilterIcon} height={18} width={16}
                                                                    style={{ paddingTop: '5px', marginLeft: '5px' }} />
                                                                :
                                                                <ArrowDropDownSharpIcon></ArrowDropDownSharpIcon>
                                                        }
                                                        </div>
                                                    </TableSortLabel>
                                                ) : headCell.label.toString() === "Ticket ID" ?
                                                <div style={{display: 'flex'}}>
                                                 <Typography
                                                        style={{ ...webStyle.headerText, textAlign: headCell.numeric }}
                                                    >
                                                        {headCell.label}
                                                </Typography>
                                                <CachedIcon 
                                                  onClick={() => this.handleTicketRefresh()}
                                                  style={{fill: 'rgb(0, 62, 118)', marginBottom: '-8px', marginLeft: '5px'}}
                                                />
                                            </div>
                                            : headCell.isFiltering ? (
                                                    <div key={index}>

                                                        {headCell.label.toString() === "Query Type" ? (
                                                            <div key={index}>
                                                                <Typography
                                                                 key={index}
                                                                    style={{ ...webStyle.headerText, textAlign: headCell.numeric, cursor: "pointer", display: "flex", justifyContent: "center" }}
                                                                    onClick={(e: any) => this.filterOptions(e, headCell.label)}
                                                                    id="basic-button"
                                                                    aria-controls={open ? "basic-menu" : undefined}
                                                                    aria-haspopup="true"
                                                                    aria-expanded={open ? "true" : undefined}
                                                                    data-testid="Querytypefilterbtn"
                                                                >
                                                                    {headCell.label} 
                                                                    <div style={{ paddingLeft: '5px' }}>
                                                                    {
                                                                        this.state.selectedQueryType.length > 0 ?
                                                                          <img src={FilterIcon} height={18} width={16}
                                                                               style={{ paddingTop: '5px', marginLeft: '5px' }} />
                                                                         :
                                                                        <ArrowDropDownSharpIcon></ArrowDropDownSharpIcon>
                                                                    }
                                                                </div>
                                                                </Typography>
                                                                <Menu
                                                                    key={index}
                                                                    id="basic-menu"
                                                                    anchorEl={this.state.filterMenu}
                                                                    open={open}
                                                                    onClose={this.handleMenuClose}
                                                                    MenuListProps={{
                                                                        "aria-labelledby": "basic-button"
                                                                    }}
                                                                    getContentAnchorEl={null}
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'center',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'center',
                                                                    }}
                                                                
                                                                >
                                                                    <Grid container item style={{ display: "flex", justifyContent: "flex-end" }}>
                                                                        <Button variant="outlined" style={{ borderColor: "#043c74", color: "#043c74", width: "30%", padding: "0px", marginTop: "10px", marginRight: "10px", marginBottom: "0px", textTransform: "capitalize" }} onClick={this.handleAllClearCheckBox} data-testid="filterclearbtn">Clear</Button>
                                                                    </Grid>
                                                                    {this.state.filterData && this.state.filterData.map((filterDataItem: any) => {
                                                                        return (
                                                                            <FormGroup key={filterDataItem.id}>
                                                                                <MenuItem
                                                                                    // onClick={() => this.handleClose(filterDataItem.value)}
                                                                                    key={filterDataItem.id}
                                                                                    style={{ backgroundColor: '#ffffff', color: '#404056', padding: '10px 15px' }}
                                                                                >
                                                                                    <FormControlLabel
                                                                                        control={
                                                                                            <Checkbox style={{ padding: "0px 3px 0px 5px", color: "#043c74", fill: "#043c74" }} value={filterDataItem.label} checked={this.state.selectedQueryType.includes(filterDataItem.label)} onChange={(event) => { this.handleCheckBoxChange(event) }} />
                                                                                        }
                                                                                        label={filterDataItem.label}
                                                                                    />
                                                                                </MenuItem>
                                                                            </FormGroup>
                                                                        )
                                                                    })
                                                                    }
                                                                    <Grid container item style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px", padding: "10px 10px 0px 10px" }}>
                                                                        <Grid item>
                                                                            <Button variant="outlined" style={{ borderColor: "#043c74", color: "#043c74", width: "30%", padding: "0px", marginBottom: "0px", textTransform: "capitalize" }} onClick={this.handleCancleQuerytypeFilter}>Cancel</Button>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Button variant="outlined" style={{ borderColor: "#043c74", backgroundColor: "#043c74", color: "#ffffff", width: "30%", padding: "0px", marginBottom: "0px", textTransform: "capitalize" }} onClick={this.handleCheckboxQuerySave}  data-testid="QueryfilterSavebtn">Save</Button>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Menu>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <Typography
                                                                    style={{ ...webStyle.headerText, textAlign: headCell.numeric, cursor: "pointer", display: "flex", justifyContent: "center" }}
                                                                    onClick={(e: any) => this.filterStatusOptions(e, headCell.label)}
                                                                    id="basic-button-status"
                                                                    aria-controls={openStatu ? "status-basic-menu" : undefined}
                                                                    aria-haspopup="true"
                                                                    aria-expanded={openStatu ? "true" : undefined}
                                                                    data-testid="Statusfilterbtn"
                                                                >
                                                                    {headCell.label} <div style={{ paddingLeft: '5px' }}>
                                                                    {
                                                                        this.state.selectedStatus.length > 0 ?
                                                                          <img src={FilterIcon} height={18} width={16}
                                                                               style={{ paddingTop: '5px', marginLeft: '5px' }} />
                                                                         :
                                                                        <ArrowDropDownSharpIcon></ArrowDropDownSharpIcon>
                                                                    }
                                                                </div>
                                                                </Typography>
                                                                <Menu
                                                                    id="status-basic-menu"
                                                                    anchorEl={this.state.filterStatusMenu}
                                                                    open={openStatu}
                                                                    onClose={this.handleMenuClose}
                                                                    MenuListProps={{
                                                                        "aria-labelledby": "basic-button-status"
                                                                    }}
                                                                    getContentAnchorEl={null}
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'center',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'center',
                                                                    }}
                                                                >
                                                                    <Grid container item style={{ display: "flex", justifyContent: "flex-end" }}>
                                                                        <Button variant="outlined" style={{ borderColor: "#043c74", color: "#043c74", width: "20%", padding: "0px", marginTop: "10px", marginRight: "10px", marginBottom: "0px", textTransform: "capitalize" }} onClick={this.handleAllClearStatus}>Clear</Button>
                                                                    </Grid>
                                                                    {this.state.filterStatusData.map((filterDataItem: any) => {
                                                                        return (
                                                                            <FormGroup  key={filterDataItem.id}>
                                                                                <MenuItem
                                                                                    onClick={(event) => this.handleStausClose(event)}
                                                                                    data-testid="statusfilter"
                                                                                    id={filterDataItem.label}
                                                                                    key={filterDataItem.id}

                                                                                    style={{ backgroundColor: '#ffffff', color: '#404056', padding: '10px 15px' }}
                                                                                >
                                                                                    {filterDataItem.label}
                                                                                </MenuItem>
                                                                            </FormGroup>
                                                                        )
                                                                    })
                                                                    }

                                                                </Menu>
                                                            </>
                                                        )
                                                        }

                                                    </div>
                                                ) :
                                                    <Typography
                                                        style={{ ...webStyle.headerText, textAlign: headCell.numeric }}
                                                    >
                                                        {headCell.label}
                                                    </Typography>
                                            }

                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.ticketList.map((row: ITicketData, index: number) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.id}
                                        >
                                            <TableCell style={{ ...webStyle.dataText }}>
                                                {row.attributes.ticket_number}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                style={webStyle.dataText}
                                            >
                                                {
                                                    row.attributes.query_type === "Connect With Us" ? (
                                                        row.attributes.product_name.map((data: any, Proindex: number) => {
                                                            return (
                                                                <div key={Proindex}>
                                                                    {data.products ? data.products : data.other_products}
                                                                    {Proindex !== row.attributes.product_name.length - 1 && ","}
                                                                </div>
                                                            )
                                                        })
                                                    ) : (
                                                        row.attributes.product_name ? row.attributes.product_name :
                                                            <div style={{ paddingLeft: "30px" }}>-</div>
                                                    )
                                                }
                                                {/* {row.attributes.product_name ? row.attributes.product_name : <div style={{ paddingLeft: "30px" }}>-</div>} */}
                                            </TableCell>
                                            <TableCell style={{ ...webStyle.dataText, width: 130 }}>
                                                {row.attributes.query_type}
                                            </TableCell>
                                            <TableCell style={{ ...webStyle.dataText, display: 'flex', justifyContent: 'center' }}>
                                                <StatusButton
                                                    style={row.attributes.status === "Open" ? webStyle.Open :
                                                        row.attributes.status === "Closed" ? webStyle.Closed :
                                                            webStyle.inProgress}>
                                                    {row.attributes.status}
                                                </StatusButton>
                                            </TableCell>
                                            <TableCell style={webStyle.dataText}>
                                                {row.attributes.customer_name}
                                            </TableCell>
                                            <TableCell style={{ ...webStyle.dataText, display: 'flex', justifyContent: 'center' }}>
                                                <DetailButoon onClick={() => this.handleTicketListClick(row.id)} variant="contained" style={{ float: 'right' }} data-testid="detailsbtn">
                                                    View Details
                                                </DetailButoon>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        {
                            !this.state.isLoading && this.state.ticketList.length === 0 && (
                                <Grid item lg={12} md={12} sm={12} style={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography style={{ ...webStyle.noData, display: 'flex', justifyContent: 'center' }}>
                                        No Records Found
                                    </Typography>
                                </Grid>
                            )
                        }
                    </TableContainer>
                    {this.state.TicketListingMeta &&
                        <React.Fragment>
                            <Grid container alignItems="center" >
                                <Grid item xs={5} sm={4} lg={5}>
                                    <Grid container alignItems="center" className={classes.downloadContainer} >
                                        <Grid item xs={12} container alignItems="center">
                                            <DownloadBox>
                                                <DownloadIcon style={webStyle.downloadIcon} ></DownloadIcon>
                                            </DownloadBox>
                                            <Button onClick={this.downloadTickets} style={{ marginLeft: "0px" }} data-testid="downloadticketbtn">
                                                <Typography style={{ ...webStyle.downloadText }}>Download</Typography>
                                            </Button>
                                            <CSVLink
                                                data={this.state.csvData}
                                                filename={this.state.csvFileName}
                                                className='hidden'
                                                ref={this.csvRef}
                                                target='_blank'
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={7} sm={8} lg={7}>
                                    <CustomPagination
                                        per_page={PER_PAGE}
                                        page={this.state.TicketListingMeta?.pagination_details.current_page}
                                        count={Math.ceil(this.state.TicketListingMeta?.pagination_details.total_count / PER_PAGE)}
                                        arrayLength={this.state.TicketListingMeta?.pagination_details.total_count}
                                        handlePageChange={(e: any, p: any) => this.handlePageChange(e, p)}
                                        data-testid="customepaginaion"
                                    />
                                </Grid>
                            </Grid>
                        </React.Fragment>}
                </MainContainer>
                <AppFooter isLoading={this.state.isLoading} />
            </>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
const webStyle = {
    downloadText: {
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '16px',
        color: '#043c74',
        textDecoration: 'underline',
    },
    downloadIcon: {
        height: "18px",
        width: "18px",
        margin: "0px",
        color: "#043c74"
    },
    Tab1: {
        background: '#fff',
    },
    activeTransferredTickets: {
        borderTopRightRadius: "16px",
        borderBottomRightRadius: "16px",
        background: '#DCDFF0',
    },
    tab3: {
        borderTopLeftRadius: "16px",
        borderBottomLeftRadius: "16px",
    },
    headerText: {
        color: 'rgba(4, 60, 116, 1)',
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '16px',
    },
    dataText: {
        color: 'rgba(4, 60, 116, 1)',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '16px',
        borderColor: '#F2F2F5',
        maxWidth: '128px'
    },
    table: {
        border: '1px solid rgba(170, 188, 206, 1)',
        boxShadow: 'none',
        borderRadius: '16px',
    },
    Open: {
        backgroundColor: '#EBF7F3',
        color: '#85C67A',
        border: '1px solid #EBF7F3',
        cursor: 'arrow',
    },
    inProgress: {
        backgroundColor: '#FFF7E6',
        color: '#EBC74F',
        border: '1px solid #FFF7E6',
        cursor: 'arrow',
    },
    Closed: {
        backgroundColor: '#F8ECEE',
        color: '#CE4436',
        border: '1px solid #F8ECEE',
        cursor: 'arrow',
    },
    noData: {
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '20px',
    },
    cardButtonBack: {
        color: "rgb(4, 60, 116)",
        margin: "4px 5px 0px 3px",
        paddingBottom: "0px"
    },
};
const CustomTab: any = withStyles({
    root: {
        border: "1px solid rgba(218, 224, 241, 1)",
        width: "100%",
        color: "rgba(4, 60, 116, 1)",
        fontFamily: "SFProDisplay",
        fontWeight: 700,
        fontSize: "16px",
        textTransform: "none",
        borderRadius: '16px',
        "& .PrivateTabIndicator-colorSecondary-4": {
            display: "none",
        },
        "@media (max-width: 670px)": {
            width: "auto",
            fontSize: "12px"
        },
    },
})(Tab);
const CustomTabs: any = withStyles({
    root: {
        maxHeight: "40px",
        backgroundColor: 'rgba(255, 255, 255, 1)',
        "& .MuiTab-root": {
            minHeight: '34px',
            maxHeight: "44px",
            maxWidth: '500px',
        },
        "& .MuiTabs-indicator": {
            display: "none",
        },
        "& .Mui-selected": {
            background: "#DCDFF0",
            color: '#8093B3',
            maxWidth: '280px',
            "& .MuiTab-textColorInherit": {
                opacity: 0.7,
            },
        },
        "& .MuiTab-textColorInherit": {
            opacity: 1,
        },
        "@media (max-width: 470px)": {
            maxHeight: "80px",
        },
        "@media (max-width: 600px)": {
            marginLeft: "0px",
        },
    },
})(Tabs);
const MainContainer: any = withStyles({
    root: {
        padding: "14px 55px 6.5% 75px",
        "& .MuiTableCell-paddingNone": {
            padding: '12px 16px'
        },
        "& .MuiTableCell-alignRight": {
            flexDirection: 'row'
        },
        "& .MuiTableCell-root": {
            padding: '12px 16px',
        },
        "& .MuiTableRow-root": {
            backgroundColor: 'inherit',
            "&:hover": {
                backgroundColor: "inherit",
            },
        },
        "& .MuiTableSortLabel-icon": {
            display: 'none'
        }
    }
})(Container)
const CountBox: any = withStyles({
    root: {
        height: '20px',
        width: '30px',
        backgroundColor: 'rgba(218, 224, 241, 1)',
        color: 'rgba(4, 60, 116, 1)',
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '12px',
        margin: '0px 5px 0px 12px',
        borderRadius: '16px',
        textAlign: 'center',
        padding: '3px 3px'
    }
})(Box)

const SearchContainer: any = withStyles({
    root: {
        padding: '15px 0px 20px 0px'
    }
})(Grid);

const InputField: any = withStyles({
    root: {
        background: "inherite",
        margin: "5px 0px 0px 0px",
        "& ::placeholder": {
            color: 'rgba(185, 187, 197, 1)',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
            fontSize: '16px',
            opacity: 1,
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: 'rgba(185, 187, 197, 1)',
        },
        "& .MuiInputBase-input": {
            height: '15px',
        },
        "& .MuiOutlinedInput-input": {
            padding: `11px 14px !important`
        },
        "& .MuiOutlinedInput-root": {
            fontFamily: "SFProDisplay",
            fontWeight: 400,
            backgroundColor: "rgba(255, 255, 255, 1)",
            fontSize: "14px",
            "& fieldset": {
                height: '40px',
                borderColor: 'rgba(185, 187, 197, 1)',
                borderRadius: "8px",
                borderWidth: 1,
            },
            ".Mui-focused": {
                borderColor: 'rgba(185, 187, 197, 1)',
            },
        },
        "& .MuiFormHelperText-contained": {
            marginLeft: "0px !important",
        },
        "& .MuiInputBase-input.Mui-disabled": {
            background: "#F1F4FA",
            borderRadius: "8px",
            height: 13,
            color: 'rgba(106, 116, 165, 1)',
            fontSize: '14px',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
        },
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: 'rgba(185, 187, 197, 1)',
            borderWidth: '1px'
        },
        "& .MuiIconButton-root": {
            paddingLeft: '0px'
        },
        "& .MuiOutlinedInput-adornedStart": {
            paddingLeft: '5px'
        },
        "& .MuiSvgIcon-root": {
            width: '20px',
            height: '20px',
        },
    },
})(TextField);

const StatusButton: any = withStyles({
    root: {
        backgroundColor: 'inherit',
        borderColor: 'inherit',
        borderRadius: '8px',
        borderWidth: '1px',
        width: '130px',
        height: '40px',
        boxShadow: 'none',
        textTransform: 'none',
        color: 'inherit',
        fontFamily: 'SFProDisplay',
        fontWeight: 500,
        fontSize: '16px',
        float: 'left',
        cursor: 'initial',
        marginLeft: '-2px',
        "&:hover": {
            backgroundColor: "#E3EFFD",
            boxShadow: "none",
        },
    }
})(Button);

const DetailButoon: any = withStyles({
    root: {
        backgroundColor: '#e1efff',
        border: '1px solid #459eff',
        borderRadius: '8px',
        borderWidth: '1px',
        width: '144px',
        height: '40px',
        color: '#459eff',
        fontFamily: 'SFProDisplay',
        fontWeight: 500,
        fontSize: '16px',
        boxShadow: 'none',
        textTransform: 'none',
        "&:hover": {
            backgroundColor: "#e1efff",
            boxShadow: "none",
        },
    }
})(Button);

const styles = () => ({
    container: {
        "@media (max-width: 600px)": {
            paddingLeft: 20,
            paddingRight: 20
        }
    }
})

export default withStyles(styles)(OverdueTickets)
// Customizable Area End