import React, { useEffect } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ILandingPagebanners } from "./LandingPageController";
import { Button } from "@material-ui/core";

interface IProps {
  banners: ILandingPagebanners[] | null;
  showFullVideo: any;
}
export const handleBeforeChange = (prevIndex: number, nextIndex: number, stopVideo: any, setIsPlay: any, playSlide: any, banners: any, playVideo: any, pauseSlide: any) => {
  stopVideo(prevIndex);
  setIsPlay(true);
  playSlide();
  if (
    banners &&
    banners[nextIndex]?.attributes?.file_attached !== "Media attached is image"
  ) {
    playVideo(nextIndex);
    setIsPlay(false);
    pauseSlide();
  }
};


export const stopVideo = (index: number) => {
  if (document && document.getElementById(`video${index}`)) {
    // @ts-ignore
    document.getElementById(`video${index}`).pause();
    // @ts-ignore
    document.getElementById(`video${index}`).currentTime = 0;
  }
};

export const Banners = ({ banners, showFullVideo }: IProps) => {
  const [isPlay, setIsPlay] = React.useState(true);

  const sliderRef: React.LegacyRef<Slider> | undefined = React.createRef();
  const videoRef:
    | React.LegacyRef<HTMLVideoElement>
    | undefined = React.createRef();

  const settings = {
    dots: true,
    infinite: true,
    autoplaySpeed: 5000,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    waitForAnimate: false,
    beforeChange: (prev: number, next: number) => handleBeforeChange(prev, next, stopVideo, setIsPlay, playSlide, banners, playVideo, pauseSlide),
  };

  const handleVideoEnd = () => {
    playSlide();
    setIsPlay(true);
  };


  const pauseSlide = () => {
    sliderRef.current && sliderRef.current.slickPause();
  };

  const playSlide = () => {
    sliderRef.current && sliderRef.current.slickPlay();
  };


  const playVideo = (index: number) => {
    // @ts-ignore
    document.getElementById(`video${index}`)?.play();
  };
  React.useEffect(() => {
    if (
      banners &&
      banners[0]?.attributes?.file_attached !== "Media attached is image"
    ) {
      setIsPlay(false);
      playVideo(0);
    }
  }, [banners]);

  return (
    <div className="heroSection">
      <Slider ref={sliderRef} {...settings} autoplay={isPlay}>
        {banners ? (
          banners.map((banner: ILandingPagebanners, index: number) => {
            return (
              <section key={"banner-" + index} style={webStyle.HeroSection}>
                <div
                  data-testid='showFullVideoData'
                  onClick={() => {
                    showFullVideo();
                  }}
                >
                  {banner.attributes.file_attached ===
                    "Media attached is image" ? (
                    <img
                      // @ts-ignore
                      style={webStyle.HeroImage}
                      src={banner.attributes.file}
                    />
                  ) : (
                    <video
                      data-testid='handleVideoEnd'
                      id={`video${index}`}
                      ref={videoRef}
                      muted={index === 0}
                      controlsList="nodownload"
                      onEndedCapture={handleVideoEnd}
                      style={webStyle.HeroVideo}
                      className="bannerVideo"
                    >
                      <source
                        src={banner.attributes.file}
                        type="video/mp4"
                        className="bannerVideo"
                      />
                    </video>
                  )}
                </div>
                <Button />
              </section>
            );
          })
        ) : (
          <p>Loading...</p>
        )}
      </Slider>
      {/* <MoreButton>Explore more</MoreButton> */}
    </div>
  );
};

const webStyle = {
  HeroContainer: {
    width: "100%",
    height: "460px"
  },
  HeroSection: {
    padding: "2rem",
    cursor: "pointer"
  },
  HeroImage: {
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    cursor: "hand",
    objectFit: 'cover',
    objectPosition: 'center'
  },
  HeroVideo: {
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    cursor: "hand"
  }
};

// const MoreButton = withStyles({
//   root: {
//     position: "absolute",
//     top: "15%",
//     left: "85%",
//     height: "36px",
//     fontWeight: 500,
//     background: "#5F9CF9",
//     color: "white",
//     fontFamily: "SFProDisplay",
//     fontSize: "14px",
//     textTransform: "none",
//     boxShadow: "none",
//     margin: "1px 10px 0px 0px",
//     padding: "5px 20px",
//     borderRadius: 8,
//     "&:hover": {
//       background: "rgba(232, 243, 255, 1)"
//     }
//   }
// })(Button);
