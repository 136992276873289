import React from "react";

// Customizable Area Start
import { Formik } from "formik";
import * as Yup from "yup";
import { NavLink } from 'react-router-dom';
// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordController";

// debug ui new
import {
  Box,
  Grid,
  Button,
  TextField,
  InputLabel,
  IconButton,
  Typography,
  InputAdornment,
  Dialog,
  DialogContent,
  DialogTitle,
  Container,
} from "@material-ui/core";
import {
  signupImage3,
  bioconLogo,
  backgroundImg,
} from "./assets";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { withStyles } from "@material-ui/core/styles";

export class NewPassword extends ForgotPasswordController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.isChangePassword = true;
    // Customizable Area Start
    // Customizable Area End
  }

  TermsPopUp2() {
    return (
      <TermsDialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <NavLink to="/login">
            <IconBox>
              <CloseIcon data-testid="closeIcon"
                onClick={() => this.closePopUp()}
                style={webStyle.closeicon}>
              </CloseIcon>
            </IconBox>
          </NavLink>
        </DialogTitle>
        <DialogContent>
          <Typography style={webStyle.dialogText}>
            {this.state.popUpText}
            <NavLink
              style={webStyle.login2}
              to={"/login"}>
              {this.state.loginText}
            </NavLink>
          </Typography>
        </DialogContent>
      </TermsDialog>
    )
  }

  render() {
    return (
      <>
        <MainContainer container>
          <Grid item lg={6} md={6} sm={false} xs={false}>
            <CustomBox sx={webStyle.backImageSpace} className="leftImg">
              <img
                style={webStyle.signupImage}
                src={signupImage3}
                alt=""
                className="signupImg"
              />
            </CustomBox>
          </Grid>
          <RightBox item lg={6} md={6} sm={6} xs={12}>
            <Container>
              <RightGrid item lg={12} md={12} sm={12} xs={12}>
                <Grid container style={webStyle.maxWidthStyle}>
                  <img
                    src={bioconLogo}
                    style={webStyle.bioconLogo}
                    onClick={this.goToHome}
                  />
                </Grid>
                <Typography style={webStyle.Header}>
                  {this.state.resetPasswordHeader}
                </Typography>
                <Typography style={webStyle.subHeader}>
                  {this.state.subHeaderText}
                </Typography>
                {this.state.sendEmailOk2 && this.state.showPasswordResetPopUp && this.TermsPopUp2()}
                <Formik
                  data-testid="FormikForm"
                  initialValues={{ password: "", confirmPassword: "" }}
                  validationSchema={Yup.object().shape(this.state.passwordSchema)}
                  validateOnMount={true}
                  validateOnChange={true}
                  onSubmit={(values, actions) => {
                    this.goToConfirmationAfterPasswordChange(values);
                    actions?.setSubmitting(false);
                  }}
                >
                  {({
                    handleChange,
                    handleSubmit,
                    errors,
                    setFieldTouched,
                    touched
                  }) => (
                    <Grid>
                      <InputLabel htmlFor="passwordInput" style={webStyle.inputLabel}>
                        {this.state.passwordLabelText}
                      </InputLabel>
                      <InputField
                        data-testid="PasswordInput"
                        id="txtInputPassword"
                        variant="outlined"
                        required
                        onChange={handleChange(this.placeholderIsPassword)}
                        placeholder={this.state.passwordPlaceholderText}
                        fullWidth
                        onBlur={() => setFieldTouched("password")}
                        type={this.state.showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword}
                                edge="end"
                                data-testid="passwordToggleBtn"
                              >
                                {this.state.showPassword ? (
                                  <VisibilityOffOutlinedIcon />
                                ) : (
                                  <VisibilityOutlinedIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {touched.password && errors.password ? (
                        <Typography style={webStyle.errorStyle} data-testid="errorText">{errors.password}</Typography>
                      ) : <Typography style={webStyle.passwordRulesStyle}>
                          {this.state.passwordRules}
                      </Typography>}
                      <InputLabel htmlFor={this.state.htmlForPasswordInput} style={webStyle.inputLabel2}>
                        {this.state.confirmPasswordLabel}
                      </InputLabel>
                      <InputField
                        data-testid="confirmpasswordInput"
                        id="txtInputConfirmPassword"
                        variant="outlined"
                        required
                        type={this.state.confirmShowPassword ? "text" : "password"}
                        placeholder={this.state.confirmPlaceholderText}
                        onChange={handleChange("confirmPassword")}
                        fullWidth
                        onBlur={() => setFieldTouched("confirmPassword")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleConfirmShowPassword}
                                edge="end"
                              >
                                {this.state.confirmShowPassword ? (
                                  <VisibilityOffOutlinedIcon />
                                ) : (
                                  <VisibilityOutlinedIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Grid>
                        {touched.password && errors.password ? (
                          <Typography style={webStyle.errorStyle}>{errors.password}</Typography>
                        ) : <Typography style={webStyle.passwordRulesStyle}>
                        {this.state.passwordRules}
                      </Typography>}
                      </Grid>
                      <Grid>
                        {touched.confirmPassword && errors.confirmPassword ? (
                          <Typography style={webStyle.errorStyle}>
                            {errors.confirmPassword}
                          </Typography>
                        ) : null}
                      </Grid>
                      <Grid style={webStyle.maxWidthStyle}>
                        <ResetButton
                          data-testid="AcceptButton"
                          onClick={() => handleSubmit()}
                        >
                          {this.state.resetButtonText}
                        </ResetButton>
                      </Grid>
                    </Grid>
                  )}
                </Formik>
              </RightGrid>
            </Container>
          </RightBox>
        </MainContainer>
      </>

    );
  }
}

// Customizable Area Start

// debug ui new one
const webStyle = {
  closeicon: {
    fill: '#1A3B71',
    cursor: 'hand'
  },
  dialogText: {
    margin: "0px 0 50px",
    fontFamily: "SFProDisplay",
    fontWeight: 500,
    fontSize: "18px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.4px",
    color: "#043c74",
    display: "flex",
  },
  passwordRulesStyle: {
    margin: "0px !important",
    color: "rgba(106, 116, 165, 1)",
    fontSize: "12px",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
  },
  errorStyle: {
    color: "red",
    fontSize: 12,
  },
  signupImage: {
    width: '95.9%',
    height: '100vh',
  },
  Header: {
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '20px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#043c74',
    margin: "15px 0 10px",
  },
  subHeader: {
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '14px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#6a74a5',
    margin: "0 0 15px",
  },
  bioconLogo: {
    width: '32.6%',
    height: '100%',
    margin: '0px 0px 0 0',
    cursor: 'hand',
  },
  inputLabel: {
    margin: "0 0 10px",
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '16px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#3a3f63',
  },
  login: {
    margin: '2% 8px 0 8px',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '16px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.35px',
    color: '#459eff',
    cursor: 'hand',
  },
  login2: {
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontStretch: 'normal',
    textDecoration: "underline",
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.35px',
    color: '#459eff',
    cursor: 'pointer',
    paddingLeft: '10px'
  },
  inputLabel2: {
    margin: "10px 0",
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '16px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#3a3f63',
  },
  backImageSpace: {
    padding: '0 1.9% 0 0',
  },
  maxWidthStyle: {
    maxWidth: '100%'
  }
}

const RightBox : any = withStyles({
  root: {
    backgroundImage: `url(${backgroundImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '98% 100%',
    margin: '1.2% 0px 1.2% 0px',
    padding: '0% 8.9% 0% 5.4%',
    display: 'flex',
    alignItems: 'center',
    "& .PrivateSwitchBase-root-14": {
      padding: '0px',
    },
    "& .compnaySelect .css-yk16xz-control": {
      maxWidth: '100%',
      borderRadius: '8px'
    },
    "& .compnaySelect .css-1pahdxg-control": {
      maxWidth: '100%',
    },
    "& .compnaySelect .css-26l3qy-menu": {
      maxWidth: '100%',
    }
  }
})(Grid);

const RightGrid : any = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '0px',
  }
})(Grid);

const InputField : any = withStyles({
  root: {
    maxWidth: '100%',
    borderRadius: '8px',
    backgroundColor: '#fff',
    "& .MuiInputBase-input": {
      height: '12px',
    },
    "& ::placeholder": {
      color: '#d6d6d6',
      fontSize: '16px',
      opacity: 1,
      fontFamily: 'SFProDisplay',
      fontWeight: 500,
    },
    "& .MuiOutlinedInput-root": {
      fontWeight: 500,
      fontFamily: "SFProDisplay",
      backgroundColor: "rgba(255, 255, 255, 1)",
      fontSize: "16px",
      borderRadius: "8px",
      height: '100%',
      "& fieldset": {
        border: 'solid 1px #d6d6d6',
        borderRadius: '8px',
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: '3% 14px'
    },
  },
})(TextField);

const MainContainer : any = withStyles({
  root: {
    overflowX: 'inherit',
    "& .css-1pahdxg-control": {
      borderColor: `rgba(218, 224, 241, 1)!important`,
      boxShadow: 'none',
      borderRadius: '8px',
      height: '40px',
    },
    "@media (max-width: 600px)": {
      "& .signupImg": {
        width: `100% !important`,
        height: `95% !important`,
        display: 'none'
      },
      "& .leftImg": {
        padding: `0px !important`,
      }
    },
    "@media (max-height: 1030px)": {
      "@media (max-width: 600px)": {
        height: '100%',
        overflowY: 'inherit',
      },
    },
  }
})(Grid);

const TermsDialog : any = withStyles({
  root: {
    "& .MuiDialog-paperScrollPaper": {
      padding: '0px 0px 0px 0px',
      borderRadius: '10px'
    },
    "& .MuiDialogActions-root": {
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px',
      paddingBottom: '8px',
    },
    "& .WithStyles(ForwardRef(Button))-root-10": {
      "@media (max-width: 600px)": {
        width: 300
      }
    }
  }
})(Dialog);

const IconBox : any = withStyles({
  root: {
    background: '#DAE0EC',
    height: '26px',
    width: '26px',
    borderRadius: '50%',
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 0px 0px 0px',
    "& .MuiSvgIcon-root": {
      height: '20px',
      width: '20px'
    }
  }
})(Box)

const ResetButton: any = withStyles({
  root: {
    maxWidth: '100%',
    height: '5.7%',
    margin: '3.9% 0 0px',
    padding: '1.2% 0px 1.2% 0px',
    borderRadius: '8px',
    backgroundColor: '#459eff',
    fontFamily: 'DMSans-Bold',
    fontSize: '22px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#fff',
    textTransform: "none",
    minWidth: '100%',
    boxShadow: 'none',
    borderWidth: "0px",
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: "#459eff",
      boxShadow: "none",
    },
  }
})(Button);

const CustomBox: any = withStyles({
  root: {
  }
})(Box);

const styles = () => ({
  container: {
    "@media (max-width: 600px)": {
        paddingLeft: 20,
        paddingRight: 20
    }
}
})

export default withStyles(styles)(NewPassword)
// Customizable Area End
