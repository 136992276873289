import React from "react";
import {
  Box,
  Grid,
  Button,
  TextField,
  InputLabel,
  IconButton,
  Typography,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Container,
  Divider,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import { signupImage2, bioconLogo, backgroundImg, ConnectLogo } from "./assets";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import { withStyles } from "@material-ui/core/styles";
import {
  handlePrivacyPolicyRedirection,
  handleTermsOfUseRedirection,
} from "../../../components/src/common";
import SignupWebController, { Props } from "./SignupController.web";
export default class SignUpRegistrationZeplin extends SignupWebController {
  constructor(props: Props) {
    super(props);
  }

  TermsPopUp() {
    return (
      <TermsDialog
        open={this.state.termsPopUp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <IconBox onClick={this.handleTermsConditionClick}>
            <CloseIcon style={{ fill: "#1A3B71", cursor: "hand" }} />
          </IconBox>
        </DialogTitle>
        <DialogContent>
          <Typography style={webStyle.termsPopUpText}>
            By registering on this website, you are allowing Biocon Limited to
            use all information provided by you, specifically for the purposes
            of responding to your queries; sending communications pertaining
            orders placed by you on Biocon Limited and its group companies;
            sharing information about our events and webcasts; and sending
            updates, security alerts and administrative messages. Your use of
            this website and sharing of your personal information shall at all
            times be subject to the Privacy Policy and the Terms of Use.
            <br />
            <br />
            Please provide your confirmation by clicking on the “Accept” button
            to proceed further with registration process
          </Typography>
        </DialogContent>
        <DialogActions>
          <AcceptButton onClick={this.setTermsandCondition}>
            Accept
          </AcceptButton>
        </DialogActions>
      </TermsDialog>
    );
  }

  ProfileConfirmationPopup() {
    return (
      <TermsDialog
        open={this.state.isConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <IconBox onClick={this.onConfirmationClick}>
            <CloseIcon style={{ fill: "#1A3B71", cursor: "hand" }} />
          </IconBox>
        </DialogTitle>
        <DialogContent>
          <Typography style={webStyle.termsPopUpText}>
            Thanks for sign up request to BioConnect. Biocon team wil review
            submitted
            <br />
            details. you can login to BioConnect once received confirmation
            email from <br />
            Biocon
          </Typography>
        </DialogContent>
        <DialogActions>
          <AcceptButton onClick={this.onConfirmationClick}>Ok</AcceptButton>
        </DialogActions>
      </TermsDialog>
    );
  }

  setCompanyDetails() {
    return (
      <>
        <InputLabel
          htmlFor="companyname"
          style={webStyle.inputLabelCompanyName}
        >
          {this.state.labelCompanyName}*
        </InputLabel>
        <SelectField>
          <Select
            labelId="company"
            displayEmpty
            id="company"
            variant="outlined"
            fullWidth
            style={webStyle.select}
            defaultValue={0}
            value={this.state.companyId}
            onChange={this.handleCompanyChange}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem value={0}>
              <em style={webStyle.selectPlaceholder}>Select Company</em>
            </MenuItem>
            {this.state.companyList.map((data: any, index: number) => {
              return (
                <MenuItem value={data.id} key={index}>
                  {data.customer_name}
                </MenuItem>
              );
            })}
            <MenuItem value={-1}>Other</MenuItem>
            {this.state.companyList.length === 0 && (
              <MenuItem value={-2}>No Data</MenuItem>
            )}
          </Select>
        </SelectField>
        {this.state.isOtherCompany && (
          <React.Fragment>
            <InputLabel htmlFor="phoneNum" style={webStyle.inputLabel} />
            <InputField
              id="companyname"
              variant="outlined"
              value={this.state.companyName}
              onChange={this.handleChange("companyName").bind(this)}
              required
              placeholder="Enter your company name"
              fullWidth
            />
          </React.Fragment>
        )}
        <InputLabel htmlFor="phoneNum" style={webStyle.inputLabel}>
          {this.state.phoneNum}*
        </InputLabel>
        <Grid container style={{ maxWidth: "491px" }}>
          <CustomGrid item>
            <CountryCodeSelector
              navigation={""}
              style={{ positin: "inherite" }}
              id="countryCode"
              disable={false}
              allowPropChange={false}
              onChange={this.handlePhoneCountryCode}
              value={this.state.phoneCountryCode}
              placeHolder="Enter Country Code"
            />
          </CustomGrid>
          <Grid item lg={7} md={6} sm={10} xs={12}>
            <InputField
              id="phoneNumber"
              variant="outlined"
              inputMode="numeric"
              inputProps={{
                maxLength: 14,
              }}
              style={{ height: "100%", minWidth: "47.9%" }}
              type="tel"
              value={this.state.phone}
              onChange={this.handleChange("phone").bind(this)}
              required
              placeholder="Enter your phone number"
              fullWidth
            />
          </Grid>
        </Grid>
        <InputLabel htmlFor="country" style={webStyle.inputLabel}>
          {this.state.labelCountry}
        </InputLabel>
        <InputField
          id="country"
          disabled
          variant="outlined"
          value={this.state.country}
          onChange={this.handleChange("country").bind(this)}
          required
          placeholder="Enter your country name"
          fullWidth
        />
        <Box display={"flex"} style={{ maxWidth: "491px" }}>
          <CheckBoxLabel
            control={
              <Checkbox
                color="default"
                disableRipple
                checked={this.state.isTermsCondition}
                onChange={this.handleTermsConditionClick}
                checkedIcon={<span style={webStyle.checkedIcon} />}
                icon={<span style={webStyle.icon} />}
                inputProps={{ "aria-label": "decorative checkbox" }}
              />
            }
            label={""}
          />
          <Typography style={webStyle.termsText} component={"span"}>
            {this.state.privacyPolicyLabel}
            <Typography
              onClick={handlePrivacyPolicyRedirection}
              component="span"
              style={webStyle.privacyText}
            >
              {" "}
              {this.state.privacyPolicyText}
            </Typography>{" "}
            and
            <Typography
              onClick={handleTermsOfUseRedirection}
              component="span"
              style={webStyle.privacyText}
            >
              {" "}
              {this.state.termslabelText}
            </Typography>
            <Typography component="span"> {this.state.termsLabel}</Typography>
          </Typography>
          {this.state.termsPopUp && this.TermsPopUp()}
          {this.state.isConfirmation && this.ProfileConfirmationPopup()}
        </Box>
      </>
    );
  }

  render() {
    //@ts-ignore
    const { navigation } = this.props;
    return (
      <>
        <MainContainer container>
          <Grid
            item
            lg={6}
            md={6}
            sm={5}
            xs={12}
            style={{
              position: "relative",
            }}
          >
            <Box sx={webStyle.backImageSpace}>
              <img
                style={webStyle.signupImage}
                src={signupImage2}
                alt=""
                className="signupImg"
              />
            </Box>
            <WelcomeCard>
              <Typography style={webStyle.welcomeText} className="welcomeText">
                {this.state.welcomeText}
              </Typography>
              <Typography style={webStyle.startedText} className="startedText">
                {this.state.startedText}
              </Typography>
              <Typography style={webStyle.serviceText}>
                {this.state.serviseText}
              </Typography>
            </WelcomeCard>
          </Grid>
          <RightBox
            item
            lg={6}
            md={6}
            sm={7}
            xs={12}
            style={this.state.IscompanyDetails ? webStyle.companyCenterBox : {}}
          >
            <Container style={{ padding: 0 }}>
              <RightGrid item lg={12} md={12} sm={12} xs={12}>
                {this.state.IscompanyDetails && (
                  <Box
                    style={{ marginTop: "0px", maxWidth: "491px" }}
                    display="flex"
                    justifyContent={"end"}
                  >
                    <Typography
                      style={webStyle.backOption}
                      onClick={this.handleBackClick}
                    >
                      Back
                    </Typography>
                  </Box>
                )}
                <Grid container style={{ maxWidth: "491px" }}>
                  <img
                    src={bioconLogo}
                    style={webStyle.bioconLogo}
                    onClick={this.goToHome}
                  />
                  <Divider orientation="vertical" style={webStyle.divider} />
                  <img
                    src={ConnectLogo}
                    style={webStyle.connectLogo}
                    className="blendLogo"
                  />
                </Grid>
                <Typography style={webStyle.Header}>
                  New User Sign Up
                </Typography>
                <Typography style={webStyle.subHeader}>
                  Please share the following details to sign up
                </Typography>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.state.IscompanyDetails
                      ? this.createAccount()
                      : this.handleProceedClick();
                  }}
                  style={{ margin: 0 }}
                >
                  {this.state.IscompanyDetails ? (
                    <>{this.setCompanyDetails()}</>
                  ) : (
                    <>
                      <InputLabel
                        htmlFor="firstName"
                        style={webStyle.inputLabelFirstName}
                      >
                        {this.state.labelFirstName}*
                      </InputLabel>
                      <InputField
                        variant="outlined"
                        type="text"
                        inputProps={{ pattern: "[A-Za-z]{1,32}" }}
                        required
                        placeholder="Enter your first name"
                        value={this.state.firstName}
                        onChange={this.handleChange("firstName").bind(this)}
                        fullWidth
                      />

                      <InputLabel
                        htmlFor="firstName"
                        style={webStyle.inputLabel}
                      >
                        {this.state.labelLastName}*
                      </InputLabel>
                      <InputField
                        variant="outlined"
                        type="text"
                        inputProps={{ pattern: "[A-Za-z]{1,32}" }}
                        value={this.state.lastName}
                        onChange={this.handleChange("lastName").bind(this)}
                        required
                        placeholder="Enter your last name"
                        fullWidth
                      />

                      <InputLabel
                        htmlFor="emailInput"
                        style={webStyle.inputLabel}
                      >
                        {this.state.labelEmail}*
                      </InputLabel>
                      <InputField
                        id="emailInput"
                        type="email"
                        variant="outlined"
                        onChange={this.handleChange("email").bind(this)}
                        value={this.state.email}
                        required
                        placeholder="Enter your email id"
                        fullWidth
                      />

                      <InputLabel
                        htmlFor="createPass"
                        style={webStyle.inputLabel}
                      >
                        {this.state.labelPassword}*
                      </InputLabel>
                      <InputField
                        id="createPass"
                        variant="outlined"
                        required
                        onChange={this.handleChange("password").bind(this)}
                        value={this.state.password}
                        placeholder="Create a strong password"
                        fullWidth
                        type={this.state.showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword}
                                edge="end"
                              >
                                {this.state.showPassword ? (
                                  <VisibilityOffOutlinedIcon />
                                ) : (
                                  <VisibilityOutlinedIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Typography
                        variant="body2"
                        style={webStyle.passwordTypo}
                        className="passwordTypo"
                      >
                        Password must have 8 characters, alphanumeric and must
                        include special character
                      </Typography>

                      <InputLabel
                        htmlFor="confirmPass"
                        style={webStyle.inputLabel}
                      >
                        {this.state.confirmPass}*
                      </InputLabel>
                      <InputField
                        id="confirmPass"
                        variant="outlined"
                        required
                        type={
                          this.state.confirmShowPassword ? "text" : "password"
                        }
                        placeholder="Confirm your password"
                        value={this.state.retypePassword}
                        onChange={this.handleChange("retypePassword").bind(
                          this
                        )}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleConfirmShowPassword}
                                edge="end"
                              >
                                {this.state.confirmShowPassword ? (
                                  <VisibilityOffOutlinedIcon />
                                ) : (
                                  <VisibilityOutlinedIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )}
                  <Grid style={{ maxWidth: "491px" }}>
                    <Buttons
                      type="submit"
                      variant="contained"
                      style={
                        this.state.IscompanyDetails ? { marginTop: "24px" } : {}
                      }
                    >
                      {this.state.IscompanyDetails ? "Sign Up" : "Proceed"}
                    </Buttons>
                  </Grid>
                </form>
                <Box sx={webStyle.haveAccount}>
                  <Typography style={webStyle.AccountText}>
                    {this.state.AccountLabel}
                  </Typography>
                  <Typography
                    style={webStyle.login}
                    data-testid='navigateClick'
                    onClick={() => {
                      navigation.navigate("Login");
                    }}
                  >
                    {this.state.loginText}
                  </Typography>
                </Box>
              </RightGrid>
            </Container>
          </RightBox>
        </MainContainer>
      </>
    );
  }
}

// Customizable Area Start
const webStyle = {
  backImageSpace: {
    // maxWidth: '901px',
    padding: "0 14.2% 0 0",
  },
  select: {
    height: "42px",
    marginTop: "5px",
    borderRadius: "8px",
    maxWidth: "491px",
  },
  selectPlaceholder: {
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "16px",
    color: "#d6d6d6",
    fontStyle: "normal",
  },
  signupImage: {
    width: "99.9%",
    height: "100vh",
    marginLeft: "-1px",
  },
  welcomeText: {
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "48px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#fff",
  },
  startedText: {
    margin: "1.3% 0px 6.7px 0px",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "24px",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    color: "#fff",
  },
  serviceText: {
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "16px",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    color: "#fff",
  },
  bioconLogo: {
    width: "25.6%",
    height: "6.5%",
    margin: "0px 0px 2% 0",
    cursor: "hand",
  },
  Header: {
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "20px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#043c74",
  },
  subHeader: {
    margin: "0.5% 28px 0 0",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "14px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#6a74a5",
  },
  inputLabel: {
    margin: "2.2% 20px 1.2% 0",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "16px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#3a3f63",
  },
  inputLabelFirstName: {
    margin: "2.2% 20px 1.2% 0",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "16px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#3a3f63",
  },
  inputLabelCompanyName: {
    margin: "32px 61px 12px 0px",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "16px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#3a3f63",
  },
  passwordTypo: {
    maxWidth: "491px",
    margin: "0.3% 2px 1.4% 0",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "13px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#6a74a5",
  },
  haveAccount: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "491px",
  },
  AccountText: {
    margin: "2% 0px 0 0px",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "16px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#3a3f63",
  },
  login: {
    margin: "2% 8px 0 8px",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "16px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.35px",
    color: "#459eff",
    cursor: "hand",
  },
  backOption: {
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    fontSize: "18px",
    color: "rgba(4, 60, 116, 1)",
    cursor: "hand",
    padding: "0px 0px 6.6% 0px",
  },
  checkedIcon: {
    borderRadius: "6px",
    border: "solid 1px #3a3f63",
    backgroundColor: "#fbfcff",
    width: 24,
    height: 24,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='black'/%3E%3C/svg%3E\")",
    "input:hover ~ &": {},
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "6px",
    border: "solid 1px #3a3f63",
    backgroundColor: "#fbfcff",
    width: 24,
    height: 24,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#fbfcff",
    },
    "&:hover": {
      backgroundColor: "#fbfcff",
    },
  },
  termsText: {
    color: "rgba(58, 63, 99, 1)",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "16px",
    margin: "24px 0 0px 12px",
  },
  privacyText: {
    color: "rgba(69, 158, 255, 1)",
    cursor: "hand",
  },
  companyCenterBox: {
    display: "flex",
    alignItems: "center",
  },
  termsPopUpText: {
    fontFamily: "SFProDisplay",
    fontWeight: 500,
    fontSize: "16px",
    color: "#494A5F",
    padding: "10px 30px 10px 20px",
  },
  divider: {
    height: "44px",
    width: "1px",
    backgroundColor: "#6a74a5",
    margin: "0px 5.9% 2.2%",
  },
  connectLogo: {
    width: "28.4%",
    height: "6.7%",
    margin: "0 0px 2% 0",
  },
};

const WelcomeCard:any = withStyles({
  root: {
    position: "absolute",
    top: "73%",
    left: "48%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    display: "flex",
    flexDirection: "column",
    "@media (max-height: 800px)": {
      "@media (max-width: 1000px)": {
        width: "90%",
        left: `48%`,
      },
      "& .welcomeText": {
        fontSize: `24px !important`,
      },
      "& .startedText": {
        margin: `5px 0 5px !important`,
      },
    },
    "@media (min-height: 1011px) and (max-height: 1030px)": {
      top: "70%",
    },
  },
})(Grid);

const RightBox:any = withStyles({
  root: {
    backgroundImage: `url(${backgroundImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    margin: "1.2% 0px 1.2% 0px",
    padding: "0% 8.4% 0% 4%",
    display: "flex",
    alignItems: "center",
    "& .PrivateSwitchBase-root-14": {
      padding: "0px",
    },
  },
})(Grid);

const RightGrid:any = withStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    paddingRight: "0px",
  },
})(Grid);

const InputField:any = withStyles({
  root: {
    maxWidth: "491px",
    borderRadius: "8px",
    backgroundColor: "#fff",
    "& ::placeholder": {
      color: "#d6d6d6",
      fontSize: "16px",
      opacity: 1,
      fontFamily: "SFProDisplay",
      fontWeight: 500,
    },
    "& .MuiInputBase-input": {
      height: "12px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "3% 14px",
    },
    "& .MuiOutlinedInput-root": {
      fontWeight: 500,
      fontFamily: "SFProDisplay",
      backgroundColor: "rgba(255, 255, 255, 1)",
      fontSize: "16px",
      borderRadius: "8px",
      height: "100%",
      "& fieldset": {
        border: "solid 1px #d6d6d6",
        // height: '44px',
        borderRadius: "8px",
      },
    },
  },
})(TextField);

const Buttons:any = withStyles({
  root: {
    maxWidth: "100%",
    height: "5.7%",
    margin: "3.9% 0 0px",
    padding: "1.2% 0px 1.2% 0px",
    borderRadius: "8px",
    backgroundColor: "#459eff",
    fontFamily: "DMSans-Bold",
    fontSize: "22px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#fff",
    textTransform: "none",
    minWidth: "100%",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(69, 158, 255, 1)",
      boxShadow: "none",
    },
  },
})(Button);

export const CustomGrid:any = withStyles({
  root: {
    minWidth: "38.5%",
    marginRight: "8px",
    "@media (min-width: 380px) and (max-width: 960px)": {
      minWidth: `72% !important`,
      maxWidth: `72% !important`,
      marginBottom: "8px",
    },
    "& .css-tlfecz-indicatorContainer": {
      color: "#000000",
      paddingRight: "12px",
      fontFamily: "SFProDisplay",
      fontWeight: 400,
    },
    "& .css-1okebmr-indicatorSeparator": {
      display: "none",
      fontFamily: "SFProDisplay",
      fontWeight: 700,
    },
    "& .css-1wa3eu0-placeholder": {
      display: "flex",
      color: "rgba(214, 214, 214, 1)",
      fontFamily: "SFProDisplay",
      fontSize: "16px",
      fontWeight: 500,
      opacity: 1,
    },
    "& .css-1uccc91-singleValue": {
      fontFamily: "SFProDisplay",
      fontSize: "16px",
      fontWeight: 500,
    },
    "& .css-yk16xz-control": {
      borderRadius: "8px",
      borderColor: "rgba(214, 214, 214, 1)",
      fontWeight: 500,
      fontFamily: "SFProDisplay",
      fontSize: "16px",
      width: "100%",
      marginRight: "8px",
    },
    "& .css-g1d714-ValueContainer": {
      position: "revert",
    },
    "& .css-1pahdxg-control:hover": {
      borderColor: "#3F51B5",
      boxShadow: "none",
      borderWidth: 2,
    },
    "& .css-1pahdxg-control": {
      borderColor: "#3F51B5",
      boxShadow: "none",
      borderWidth: 2,
    },
    "& .css-1hb7zxy-IndicatorsContainer": {
      // height: "3%",
      borderRadius: "8px",
      borderColor: "rgba(214, 214, 214, 1)",
      fontWeight: 500,
      fontFamily: "SFProDisplay",
      fontSize: "16px",
      with: "100%",
    },
    "& .css-26l3qy-menu": {
      fontFamily: "SFProDisplay",
      fontSize: "14px",
      fontWeight: 500,
    },
    "& .css-view-1dbjc4n": {
      margin: `0px !important`,
    },
  },
})(Grid);

const CheckBoxLabel:any = withStyles({
  root: {
    alignItems: "flex-start",
    margin: "24px 0px 0px 0px",
    "& .MuiTypography-body1": {
      fontFamily: "SFProDisplay",
      fontWeight: 400,
      fontSize: "16px",
      color: "rgba(58, 63, 99, 1)",
    },
  },
})(FormControlLabel);

const MainContainer:any = withStyles({
  root: {
    overflowX: "inherit",
    "@media (max-height: 1030px)": {
      "@media (max-width: 600px)": {
        height: "100%",
        overflowY: "inherit",
      },
    },
  },
})(Grid);

const TermsDialog:any = withStyles({
  root: {
    "& .MuiDialog-paperScrollPaper": {
      minWidth: "700px",
      padding: "0px 0px 0px 30px",
      borderRadius: "10px",
    },
    "& .MuiDialogActions-root": {
      alignItems: "center",
      justifyContent: "center",
      padding: "0px",
      paddingBottom: "8px",
    },
    "& .WithStyles(ForwardRef(Button))-root-10": {
      "@media (maxWidth: 600px)": {
        width: 300,
      },
    },
  },
})(Dialog);

const AcceptButton:any = withStyles({
  root: {
    width: "140px",
    color: "rgba(255, 255, 255, 1)",
    backgroundColor: "#1A3B71",
    opacity: 1,
    boxShadow: "none",
    height: "42px",
    borderRadius: "4px",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    fontSize: "18px",
    margin: "30px 30px 30px 0px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1A3B71",
      boxShadow: "none",
    },
  },
})(Button);

const IconBox:any = withStyles({
  root: {
    background: "#DAE0EC",
    height: "26px",
    width: "26px",
    borderRadius: "50%",
    float: "right",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 0px 0px 0px",
    "& .MuiSvgIcon-root": {
      height: "20px",
      width: "20px",
    },
  },
})(Box);

const SelectField:any = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      fontFamily: "SFProDisplay",
      fontWeight: 500,
      backgroundColor: "rgba(255, 255, 255, 1)",
      fontSize: "16px",
      color: "#1F436D",
    },
    "& .MuiInputBase-input": {
      fontFamily: "SFProDisplay",
      fontWeight: 500,
      backgroundColor: "rgba(255, 255, 255, 1)",
      fontSize: "16px",
      color: "#1F436D",
    },
    width: "100%",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(214, 214, 214, 1)",
      borderWidth: 1,
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(214, 214, 214, 1)",
      borderWidth: 1,
    },
    "& .MuiSelect-icon": {
      fill: "rgba(214, 214, 214, 1)",
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
    },
  },
})(FormControl);

// Customizable Area End
