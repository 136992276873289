import React, { useEffect } from "react";
import {
  Typography,
  CardMedia,
  Container,
  Grid,
  Card,
  CardContent,
  IconButton
} from "@material-ui/core";
import LandingPageController, {
  ILandingUpdatesSearch
} from "./LandingPageController";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { withStyles } from "@material-ui/core/styles";
import { PlayButton } from "./NewUpdates.web";
import { getHighlightedText } from "../../../components/src/common";

interface IProps {
  IsViewAll: boolean;
  searchNewUpdates: null | ILandingUpdatesSearch[];
  setIsViewAll: () => void;
  searchTerm: string;
}
const NewUpdatesSearch = ({ IsViewAll, searchNewUpdates: propsupdate, setIsViewAll, searchTerm }: IProps) => {

  useEffect(() => {
    setSearchNewUpdates(propsupdate)
  }, [propsupdate])
  const [searchNewUpdates, setSearchNewUpdates] = React.useState(propsupdate);
  const handleSearchNewUpdateClick = (index: number) => {
    if (searchNewUpdates) {
      const updatedArray: ILandingUpdatesSearch[] | null = searchNewUpdates?.map((update: ILandingUpdatesSearch, updateIndex: number) => {
        if (updateIndex === index) {
          update.isClicked = true;
        }
        return update
      }) || null;
      setSearchNewUpdates(updatedArray);
    }
  }

 const showSearch = (item: ILandingUpdatesSearch, index: any) => {
    return (
      <Grid item lg={6} md={6} key={`update-item-search-${index}`}>
        <Card style={webStyle.card} className="mainCard">
          {/* @ts-ignore */}
          {
            item.file_attached === "Media attached is video" ?
              item.isClicked ?
                <div className="video">
                  <CardMedia
                    component="video"
                    src={item.file}
                    autoPlay
                    controlsList="nodownload"
                    controls
                    style={webStyle.cardMedia}
                  />
                </div> :
                <CardMedia
                  component="image"
                  src={item.thumbnail}
                  image={item.thumbnail}
                  style={{ ...webStyle.cardMediaImage, position: 'relative' }}
                /> :
              // @ts-ignore
              <CardMedia
                component="image"
                src={item.file}
                image={item.file}
                controls
                style={{ ...webStyle.cardMediaImage, position: 'relative' }}
              />
          }
          {
            !item.isClicked && item.file_attached === "Media attached is video" && (
              <PlayButton
                data-testid='handleSearchNewUpdateClick'
                onClick={() => handleSearchNewUpdateClick(index)}
              >
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                >
                  <ArrowRightIcon style={webStyle.Playicon}></ArrowRightIcon>
                </IconButton>
              </PlayButton>
            )
          }
          <CardContent style={{ paddingTop: "5px" }}>
            <Typography
              variant="body2"
              component="p"
              style={webStyle.updateTitle}
            >
              {getHighlightedText(item.title, searchTerm)}
            </Typography>
            <Typography
              variant="body2"
              style={webStyle.updateDetail}
              className="person-description"
            >
              {getHighlightedText(item.description, searchTerm)}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  }


  return (
    <>
      <MainGrid>
        {/* <Typography style={webStyle.listName}>Quick Links</Typography> */}
        {IsViewAll ? (
          <Grid container spacing={4} style={webStyle.updateContainer}>
            {searchNewUpdates &&
              searchNewUpdates.map((item: ILandingUpdatesSearch, index: any) =>
                showSearch(item, index)
              )}
          </Grid>
        ) : (
          <Grid container spacing={4} style={webStyle.updateContainer}>
            {searchNewUpdates &&
              searchNewUpdates
                .slice(0, 4)
                .map((item: ILandingUpdatesSearch, index: any) =>
                  showSearch(item, index)
                )}
          </Grid>
        )}
        {!IsViewAll && searchNewUpdates && searchNewUpdates.length > 4 && (
          <Grid container justifyContent="flex-end">
            <Typography
              component="div"
              style={webStyle.viewAll}
              onClick={setIsViewAll}
            >
              View All
            </Typography>
          </Grid>
        )}
      </MainGrid>
    </>
  );
}

export default NewUpdatesSearch

const webStyle = {
  mainContainer: {
    padding: "25px 60px 10px 70px"
  },
  updateContainer: {
    padding: "15px 0px 15px 0px"
  },
  listName: {
    paddingTop: "8px",
    fontSize: "28px",
    color: "rgba(4, 60, 116, 1)",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
  },
  card: {
    borderRadius: "10px",
    width: "100%",
    // display: 'flex',
    boxShadow: '0 0 12px 0 rgba(0, 0, 0, 0.13)',
    backgroundColor: '#fff',
  },
  cardMedia: {
    borderRadius: "10px",
    width: "100%",
    height: "100%",
    border: 0,
  },
  updateTitle: {
    color: "rgba(4, 60, 116, 1)",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "20px",
    paddingBottom: '10px'
  },
  updateDetail: {
    color: "rgba(4, 60, 116, 1)",
    fontFamily: "SFProDisplay",
    fontSize: "16px",
    fontWeight: 500
  },
  cardMediaImage: {
    borderRadius: "10px",
    width: "100%",
    minHeight: "60.9%",
    border: 0,
    paddingBottom: '10px'
  },
  viewAll: {
    textDecoration: "underline",
    color: "rgb(4, 60, 116)",
    fontSize: "14px",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    cursor: "pointer"
  },
  Playicon: {
    fontSize: `50px !important`,
  }
};

const MainGrid:any = withStyles({
  root: {
    padding: "25px 60px 10px 70px",
    "@media (max-width: 700px)": {
      padding: "25px 30px 10px 30px"
    },
    "& .MuiCardMedia-root": {
      backgroundPosition: `top !important`,
      backgroundSize: `100% 100% !important`,
    },
    video: {
      width: "100%",
      height: "100%",
      margin: `0px !important`
    }
  }
})(Grid);

const styles = () => ({
  mainCard: {
    // height: '466px',
    // minHeight: '40.5%',
    height: '370px',
    "@media (max-width: 1024px)": {
      height: "370px"
    }
  }
})

