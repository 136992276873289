import React from "react";
// Customizable Area Start
import { Container, Typography, Grid, TextField,InputAdornment,
  IconButton, } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ProductListingController, { IProductListing, Props } from "./ProductListingController";
import Productcard from "./ProductCard2.web";
import { AppHeader } from "../../../components/src/AppHeader.web";
import CustomPagination from "../../../components/src/CustomPagination.web";
import CustomDropDown from "../../../components/src/CustomDropDown.web";
import CustomSearch from "../../../components/src/CustomSearch.web";
import AppFooter from "../../../components/src/AppFooter.web";
import SortMenuPopup from "./SortMenuPopup";
import { withStyles } from '@material-ui/core/styles';
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class ProductListing extends ProductListingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.searchInputRefVar = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  searchInputRefVar: React.RefObject<HTMLInputElement>;
  // handlePageChange = (e: any, p: any) => {
  //   this.setState({
  //     paginationData: this.state.arrayHolder.slice(
  //       PER_PAGE * (p - 1),
  //       PER_PAGE * p
  //     ),
  //     page: p,
  //   });
  // };

  // Customizable Area End

  render() {
    return (
      <ThemeProvider theme={theme}>
        <AppHeader navigation={this.props.navigation}/>
        <SearchGrid>
        
        </SearchGrid>

        <FilterGrid>
          <Container style={{ padding: 0 }}>
            <Grid container spacing={2}>
              <CustomDropDown
                displayName="Categories"
                DropdownValue={this.state.categoryDropdownValue}
                FilterByDropType={this.filterByProductCategory}
                uniqueProducts={this.state.uniqueProductsCategory}
              />
              <CustomDropDown
                displayName="Therapeutic areas"
                DropdownValue={this.state.therapyDropdownValue}
                FilterByDropType={this.filterByProductTherapy}
                uniqueProducts={this.state.uniqueProductsTherapy}
              />
              <CustomDropDown
                displayName="Products"
                DropdownValue={this.state.productNameDropdownValue}
                FilterByDropType={this.filterByProductName}
                uniqueProducts={this.state.uniqueProductsName}
                isProduct={true}
              />
            </Grid>
          </Container>
        </FilterGrid>

        <ProductGrid data-testid="btnFilter">
          <Container style={{ padding: 0 }}>
            <SortMenuPopup
              sortAscending={this.sortAscending.bind(this)}
              sortDescending={this.sortDescending}
              Ascending={this.state.Ascending}
            />
            <Grid container style={webStyle.productContainer} spacing={4}>
              {this.state.productsList ? this.state.productsList.map((item: IProductListing, index: number) => (
                <Grid item lg={4} md={6} sm={12} xs={12} key={index}>
                  <Productcard
                    key={index}
                    item={item}
                    index={index}
                    handleClick={this.handleKnowMoreClick}
                    searchTerm={this.state.searchString}
                  />
                </Grid>
              )) :
                <Grid
                  style={webStyle.NoDataContainer}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography style={webStyle.noDataTitle}>
                    No Products Found
                  </Typography>
                </Grid>

              }
            </Grid>
            {this.state.productsListingMeta && <CustomPagination
              data-testid="customPagination"
              per_page={PER_PAGE}
              page={this.state.productsListingMeta?.pagination_details.current_page}
              count={Math.ceil(this.state.productsListingMeta?.pagination_details.total_count / PER_PAGE)}
              arrayLength={this.state.productsListingMeta?.pagination_details.total_count}
              handlePageChange={(e: any, p: any) => this.handlePageChange(e, p)}
            />}
          </Container>
        </ProductGrid>
        <AppFooter isLoading={this.state.loading}/>
      </ThemeProvider >
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start

const FilterGrid : any = withStyles({
  root: {
    backgroundColor: "rgb(239 241 245)",
    padding: "15px 126px 25px 143px",
    "@media (max-width: 700px)": {
      padding: `0px 55px 25px 63px !important`,
    }
  }
})(Grid);

const SearchGrid : any = withStyles({
  root: {
    padding: '15px 67px 0px 68px',
    background: 'rgb(239 241 245)',
    "@media (max-width: 700px)": {
      padding: `15px 30px 15px 30px !important`,
    }
  }
})(Grid);

const ProductGrid : any = withStyles({
  root: {
    padding: '8px 115px 10px 134px',
    "@media (max-width: 700px)": {
      padding: `10px 53px 10px 55px !important`,
    },
    "& .MuiTypography-h5": {
      fontFamily: "SFProDisplay",
      fontWeight: 700,
      fontSize: `32px !important`,
    },
    "& .MuiList-padding": {
      paddingTop: `0px !important`,
      paddingBottom: `0px !important`,
    }
  }
})(Grid);

const PER_PAGE = 9;

const webStyle = {
  filterContainer: {
    backgroundColor: "rgb(239 241 245)",
    padding: "15px 126px 25px 143px",
  },
  listName: {
    display: "flex",
    marginTop: "30px",
    marginLeft: "50px",
    fontSize: "x-large",
    fontWeight: 600,
    color: "rgba(4, 60, 116, 1)",
  },
  viewAll: {
    display: "flex",
    justifyContent: "right",
    textDecoration: "underline",
    color: "rgb(4, 60, 116)",
    marginTop: "-1.5rem",
  },
  productContainer: {
    backgroundColor: "white",
    // padding: '0px 12px'
  },
  NoDataContainer: {
    height: '220px'
  },
  noDataTitle: {
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    color: 'rgb(4, 60, 116)',
    fontSize: '24px',
    marginTop: '-20px'
  },
};


// Customizable Area End
