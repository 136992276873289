import React, { FC } from "react";
import {
    Typography,
    Box,
    Checkbox
} from '@material-ui/core';

interface Props {
    isChecked: boolean;
    label: string;
    containerStyle?: any;
    onChange: () => void;
}


export const CheckboxWithLabel: FC<Props> = ({ isChecked, label, containerStyle, onChange }) => {
    return (
        <Box display='flex' style={containerStyle}>
            <Checkbox
                style={{ padding: 0, color: isChecked ? '#73d6b6' : '#141414', height: 24, width: 24 }}
                checked={isChecked}
                onChange={onChange}
                inputProps={{ 'aria-label': 'controlled' }}
            />

            <Typography
                style={Style.checkboxLabel}
            >
                {label}
            </Typography>

        </Box>
    )
}

const Style = {
    checkboxLabel: {
        fontWeight: 400,
        color: 'rgba(0, 0, 0, 0.85)',
        fontFamily: 'SFProDisplay',
        fontSize: '16px',
        marginLeft: '12px'
    },
}