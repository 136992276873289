import React from "react";
import {
  apiCall,
  errorNotification,
  showApiErrorResponse,
  successNotification,
} from "../../../components/src/common";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  isEmpty,
  passwordValidate,
  requireValidate,
} from "../../../framework/src/Utilities";
import { configJSON } from "./EmailAccountLoginController";
// import { commonConfig } from "../../../framework/src/config.js";

export interface Props {
  classes?: any;
  navigation: any;
  id: string;
  loading: boolean;
  history: any;
}

interface S {
  password: string;
  email: string;
  showPassword: boolean;
  loading: boolean;
  isConnected: boolean;
  error: any;
  isFormInvalid: boolean;
  checkedRememberMe: boolean;
  labelTitle: string;
  loginHeader: string;
  emailTitle: string;
  emailPlaceholder: string;
  passwordTitle: string;
  passwordPlaceholder: string;
  btnTxtLogin: string;
  loginSubHeading: string;
  forgotPasslabel: string;
  AccountText: string;
  signUpText: string;
  welcomeText: string;
  startedText: string;
  serviseText: string;
  getTokenFromUrl: string;
}

interface SS {
  id: any;
}

export default class LoginScreenWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  loginToken: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  networkSubscribe: any;
  emailRef: any;
  passwordRef: any;

  /* START -------------------------------------------------------------- constructor */
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.doEmailLoginWeb = this.doEmailLoginWeb.bind(this);
    // this.sendURLTokenForUserValidation = this.sendURLTokenForUserValidation.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      showPassword: false,
      loading: false,
      isFormInvalid: false,
      isConnected: true,
      error: {},
      checkedRememberMe: false,
      labelTitle: configJSON.labelTitle,
      loginHeader: configJSON.loginHeader,
      emailTitle: configJSON.emailTitle,
      emailPlaceholder: configJSON.emailPlaceholder,
      passwordTitle: configJSON.passwordTitle,
      passwordPlaceholder: configJSON.passwordPlaceholder,
      btnTxtLogin: configJSON.btnTxtLogin,
      loginSubHeading: configJSON.loginSubHeading,
      forgotPasslabel: configJSON.forgotPasslabel,
      AccountText: configJSON.AccountText,
      signUpText: configJSON.signUpText,
      welcomeText: configJSON.welcomeText,
      startedText: configJSON.startedText,
      serviseText: configJSON.serviseText,
      getTokenFromUrl: "",
    };

    this.emailReg = new RegExp("");

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  /* END -------------------------------------------------------------- constructor */

  /* START -------------------------------------------------------------- componentDidMount */
  async componentDidMount() {
    this.callGetValidationApi();
    console.log(getName(MessageEnum.RestAPIRequestMessage),"getName(MessageEnum.RestAPIRequestMessage)");
    
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      this.props.navigation.navigate("LandingPage");
    }
    const userCred = localStorage.getItem("userCredentials");
    if (userCred) {
      const parsedCred = JSON.parse(userCred);
      this.setState({
        email: parsedCred.userName,
        password: parsedCred.password,
        checkedRememberMe: true,
      });
      this.CustomCheckBoxProps.isChecked = true;
    }
    // fetch('https://bioconphase1withoutsap-75049-ruby.b75049.dev.eastus.az.svc.builder.cafe//login/login').then(response=>{
    //   console.log('APIResponse',response)
    //   console.log('APIResponseheader',response.headers)
    // })

    var req = new XMLHttpRequest();
    //@ts-ignore
    req.open('GET', document.location, false);
    req.send(null);
    var headers = req.getAllResponseHeaders().toLowerCase();
    console.log("headerbeforesplit", headers);
    //@ts-ignore
    headers = headers.split(/\n|\r|\r\n/g).reduce(function(a, b) {
      if (b.length) {
        var [key, value] = b.split(": ");
        //@ts-ignore
        a[key] = value;
      }
      return a;
    }, {});
    console.log('headeraftersplit', headers);

    let urlToken = window.location.href.split('/')[4];
    if(urlToken){
      this.setState({getTokenFromUrl: urlToken} ,()=>{
        this.sendURLTokenForUserValidation();
      });
    }

    const url:any = (window.location.href).split("/");
    const urlBipspace : any = url.at(-1);
    const LoginEndPointUrl:any = urlBipspace.split("?");
    const endPointLastWord:any = LoginEndPointUrl.at(-1);
    
    if(endPointLastWord === 'err'){
      this.props.navigation.navigate("Login")
      errorNotification(configJSON.errorInvalidTokenBiospace);
    }
  }
  /* END --------------------------------------------------------------  componentDidMount*/

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  handleChange = (prop: any) => (event: any) => {
    this.setState({ ...this.state, [prop]: event.target.value });
  };

  /* START --------------------------------------------------------------  API's responce area*/

  async receive(from: string, message: Message) {
    this.setState({ loading: false });
    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));
      const password = message.getData(getName(MessageEnum.LoginPassword));

      // const countryCode = message.getData(
      //   getName(MessageEnum.LoginCountryCode)
      // );
      if (!userName && !password) {
        this.setState({
          email: userName,
          password: password,
        });
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (
          apiRequestCallId === this.validationApiCallId &&
          responseJson !== undefined
        ) {
          let arrayholder = responseJson.data;

          if (arrayholder && arrayholder.length !== 0) {
            let regexData = arrayholder[0];
            if (regexData && regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        }

        if (apiRequestCallId === this.apiEmailLoginCallId) {
          if (responseJson && responseJson.meta && responseJson.meta.token) {
            runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
            if (this.state.checkedRememberMe) {
              localStorage.setItem(
                "userCredentials",
                JSON.stringify({
                  userName: this.state.email,
                  password: this.state.password,
                })
              );
            } else {
              localStorage.removeItem("userCredentials");
            }
            this.saveLoggedInUserData(responseJson);
            this.sendLoginSuccessMessage();
            successNotification(responseJson.meta.message);
            // this.openInfoPage();
            this.props.navigation.navigate("Home");
          } else {
            //Check Error Response
            showApiErrorResponse(responseJson);
            this.sendLoginFailMessage();
          }

          this.parseApiCatchErrorResponse(errorResponse);
        }

        if(apiRequestCallId === this.loginToken){
          if(responseJson.data){
            if (responseJson && responseJson.meta && responseJson.meta.token) {
              runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
              if (this.state.checkedRememberMe) {
                localStorage.setItem(
                  "userCredentials",
                  JSON.stringify({
                    userName: this.state.email,
                    password: this.state.password,
                  })
                );
              } else {
                localStorage.removeItem("userCredentials");
              }
              if (responseJson.data.attributes) {
                if (responseJson.data.attributes.is_customer === "True") {
                  localStorage.setItem("isEmployee", "false");
                } else {
                  localStorage.setItem("isEmployee", "true");
                }
              }
              this.saveLoggedInUserData(responseJson);
              this.sendLoginSuccessMessage();
              successNotification(responseJson.meta.message);
              // this.openInfoPage();
              this.props.navigation.navigate("Home");
            } else {
              //Check Error Response
              showApiErrorResponse(responseJson);
              this.sendLoginFailMessage();
            }
  
            this.parseApiCatchErrorResponse(errorResponse);
          }
          else{
            errorNotification(responseJson.errors[0]?.token);
          }
        }
      }
    }
  }

  openInfoPage() {
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  /* END --------------------------------------------------------------  API's responce area*/
  /* START -------------------------------------------------------------- Form Input Validation */
  isFormValidate = (fieldValues: any): void => {
    let formValue: any = { ...this.state.error };
    if ("email" in fieldValues) {
      formValue.email = formValue.email ? "" : "Email is required";
    }
    if ("password" in fieldValues) {
      const passwordRegex = /^(?=.*[A-Za-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      formValue.password = formValue.password
        ? passwordRegex.test(fieldValues.password)
          ? ""
          : `The password should contain atleast 8 letters, one uppercase and one special character.`
        : "Password is required";
    }
    this.setState({ error: formValue });
  };
  /* END -------------------------------------------------------------- Form Input Validation */

  /* START -------------------------------------------------------------- Email Login in Web Section */

  doEmailLoginWeb(e: any): boolean {
    e.preventDefault();

    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      errorNotification(configJSON.errorEmailNotValid);
      // this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      errorNotification(configJSON.errorPasswordNotValid);
      // this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    if (
      !RegExp(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
      ).test(this.state.password)
    ) {
      errorNotification(configJSON.passwordError);
      // this.showAlert(configJSON.errorTitle, configJSON.passwordError);
      return false;
    }

    //TODO: Make Api Call Here for login
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const requestBody = {
      email: this.state.email,
      password: this.state.password,
    };
    const requestMessage = apiCall({
      header: header,
      httpBody: requestBody,
      httpMethod: configJSON.loginAPiMethod,
      url: configJSON.loginAPiEndPoint,
    });

    this.apiEmailLoginCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  /* END -------------------------------------------------------------- Email Login in Web Section */

  /* START -------------------------------------------------------------- sendLoginFailMessage */
  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }
  /* END -------------------------------------------------------------- sendLoginFailMessage */

  /* START -------------------------------------------------------------- sendLoginSuccesMessage */
  sendLoginSuccessMessage() {
    const msg = new Message(getName(MessageEnum.LoginSuccessMessage));
    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }
  /* END -------------------------------------------------------------- sendLoginSuccesMessage */

  /* START -------------------------------------------------------------- saveLoggedInUserData */
  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );

      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      localStorage.setItem("userInfo", JSON.stringify(responseJson.data));
      this.send(msg);
    }
  }
  /* END -------------------------------------------------------------- saveLoggedInUserData */

  /* START -------------------------------------------------------------- callGetValidationApi */

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  /* END -------------------------------------------------------------- callGetValidationApi */

  /* START -------------------------------------------------------------- Send token for Checking valid User */
  sendURLTokenForUserValidation() {
    this.setState({
      loading: true,
    });

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const requestBody = {
      token: this.state.getTokenFromUrl,
    };
    
    const requestMessage = apiCall({
      header: header,
      httpBody: {token: this.state.getTokenFromUrl},
      httpMethod: configJSON.loginAPiMethod,
      url: configJSON.sendTokenAPiEndPoint,
    });

    this.loginToken = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  /* END -------------------------------------------------------------- Send token for Checking valid User */
}
