import React, { useEffect } from "react";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  ListSubheader,
  Collapse,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ICategoryProduct } from "../../blocks/catalogue/src/ProductListingController";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles({
  customOutline: {
    width: "100%",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#dae0f1",
      borderWidth: 1,
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#dae0f1",
      borderWidth: 1,
    },
    "& .MuiSelect-icon": {
      fill: "rgba(4, 60, 116, 1)",
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
    },
  },
  menuPaper: {
    maxHeight: 300,
    maxWidth: 280,
    "@media (max-width: 600px)": {
      top: '241px !important',
    }
  },
  groupHeading: {
    backgroundColor: "#dae0f1",
    color: "#6a74a5",
    fontFamily: "SFProDisplay",
    fontSize: "16px",
    fontWeight: 600,
  },
  groupHeading2: {
    color: 'rgb(4, 60, 116) none repeat scroll 0% 0%',
    fontFamily: "SFProDisplay",
    fontSize: "16px",
    fontWeight: 600,
  },
});

interface IProps {
  options: any;
  selectedValue: any;
  handleOnChange: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    node: any
  ) => void;
}

const CategoriesGroupDropdown = (props: IProps) => {
  const classes = useStyles();
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [selectPlaceholder, setSelectPlaceholder] = React.useState(
    <p style={webStyle.PlaceHolder}>Products</p>
  );
  const [openSelect, setOpenSelect] = React.useState(false);
  const [expandIcon1, setExpandIcon1] = React.useState(true);
  const [expandIcon2, setExpandIcon2] = React.useState(true);
  const [expandIcon3, setExpandIcon3] = React.useState(true);
  const [color1, setColor1] = React.useState(false);
  const [color2, setColor2] = React.useState(false);
  const [categoryClick, setCategoryClick] = React.useState(true);
  const [SelectedProductId, setSelectedProductId] = React.useState(0);

  function setOpen11(e: any) {
    e.stopPropagation();
    setOpen1(!open1);
    setOpenSelect(true);
    setExpandIcon1(!expandIcon1);
  }
  function setOpen12(e: any) {
    e.stopPropagation();
    setOpen2(!open2);
    setOpenSelect(true);
    setExpandIcon2(!expandIcon2);
  }
  function setOpen13(e: any) {
    e.stopPropagation();
    setOpen3(!open3);
    setOpenSelect(true);
    setExpandIcon3(!expandIcon3);
  }

  useEffect(() => {
    localStorage.setItem("selectValue", "");
    let count = 0
    props.options.forEach((data: any) => {
      data.products.forEach((products: any)=>{
        if(products.id === SelectedProductId) {
            count++
        }
      })
    })
    if (count === 0) {
      setSelectPlaceholder(<p style={webStyle.PlaceHolder}>Products</p>);
      setSelectedProductId(0)
    }
  }, [props.options])

  const renderSelectGroup = (category: ICategoryProduct) => {
    const items = category.products.map((p) => {
      return (
        <MenuItem
          className="menu_item"
          key={p.id}
          value={p.name}
          onClick={(e: any) => {
            setCategoryClick(false);
            setSelectPlaceholder(<p style={webStyle.selectedProduct}>{p.name}</p>);
            setSelectedProductId(p.id);
            localStorage.setItem("selectValue", `${p.id},${category.id}`);
            props.handleOnChange(e, false);
          }}
        >
          <p style={{ margin: '0px', fontSize: `16px !important`, fontWeight: 400  }}>{p.name}</p>
        </MenuItem>
      );
    });
    return [
      <ListSubheader
        key={category.id}
        className={classes.groupHeading2}
        onClick={(e: any) => {
          category.id === 1
            ? setOpen11(e)
            : category.id === 2
              ? setOpen12(e)
              : setOpen13(e)
          if (category.id === 1) {
            setColor1(!color1)
          }
          if (category.id === 3) {
            setColor2(!color2)
          }
        }}
        style={{ backgroundColor: `${category.id === 1 && color1 ? "white" : category.id === 3 && color2 ? "white" : "white"}` }}
      >
        {category.products.length !== 0 &&
          <div style={webStyle.listSubheaderContainer}>
            {category.name}
              <ExpandMoreIcon
                className="expand_Icon"
                style={{...webStyle.dropdownIcon}}
                onClick={() => setCategoryClick(true)}
              />
          </div> 
        }
      </ListSubheader>,
      <Collapse
        className="menu_item"
        style={{ background: 'white' }}
        in={category.id === 1 ? open1 : category.id === 2 ? open2 : open3}
      >
        {items}
      </Collapse>,
    ];
  };
  return (
    <Grid item lg={4} md={4} sm={4} xs={12}>
      <FormControl variant="outlined" classes={{ root: classes.customOutline }}>
        <Select
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            classes: { paper: classes.menuPaper },
            getContentAnchorEl: null
          }}
          labelId="department"
          displayEmpty={false}
          id="department"
          variant="outlined"
          fullWidth
          value={selectPlaceholder}
          style={webStyle.select}
          defaultValue={"Products"}
          open={openSelect}
          onClick={() => setOpenSelect(!openSelect)}
          renderValue={(p: any) => {
            return selectPlaceholder;
          }}
        >
          <MenuItem value={""}
            onClick={(e: any) => {
              localStorage.setItem("selectValue", "");
              props.handleOnChange(e, false);
              setSelectedProductId(0);
              setSelectPlaceholder(<p style={webStyle.PlaceHolder}>Products</p>);
            }}
          >
            <em style={webStyle.PlaceHolder}>Products</em>
          </MenuItem>
          {props.options.map((option: ICategoryProduct) =>
            renderSelectGroup(option)
          )}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default CategoriesGroupDropdown;

const webStyle = {
  PlaceHolder: {
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "16px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.35px",
    color: "#6a74a5",
    margin: '0px',
  },
  select: {
    height: "35px",
    borderRadius: "8px",
    borderColor: "rgba(4, 60, 116, 1)",
    background: "white",
  },
  dropdownIcon: {},
  listSubheaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center',
    color: 'rgb(4, 60, 116)'
  },
  selectedProduct: {
    fontFamily: 'SFProDisplay',
    fontWeight: 500,
    fontSize: '14px',
    margin: '0px',
  }
};
