// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import DownloadPermissions from '../../blocks/DownloadPermissions/src/DownloadPermissions';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import ForgotPassword from '../../blocks/forgot-password/src/Forgotpassword2.web';
import ResetPassword from "../../blocks/forgot-password/src/ResetPassword.web";
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword2';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import AuditTrail from '../../blocks/AuditTrail/src/AuditTrail';
import AdminConsole3 from '../../blocks/AdminConsole3/src/AdminConsole3';
import DynamicContent from '../../blocks/DynamicContent/src/DynamicContent';
import CustomerProductQuery from '../../blocks/DynamicContent/src/CustomerProductQuery.web';
import SecurityPolicy from '../../blocks/SecurityPolicy/src/SecurityPolicy';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import QueryDetail from '../../blocks/DataStorage/src/QueryDetail.web';
import TicketDetails from '../../blocks/DataStorage/src/TicketDetails.web';
import ProductDetailWeb from '../../blocks/DocumentDistribution/src/ProductDetails.web.tsx';
import DataStorage from '../../blocks/DataStorage/src/DataStorage';
import RequestManagement from '../../blocks/RequestManagement/src/RequestManagement';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import TwofactorAuthentication from '../../blocks/TwofactorAuthentication/src/TwofactorAuthentication';
import LandingPage from '../../blocks/landingpage/src/LandingPage';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import Sorting from '../../blocks/sorting/src/Sorting';
import ReviewAndApproval from '../../blocks/ReviewAndApproval/src/ReviewAndApproval';
import BiospaceSingleSignonForBioconEmployees3 from '../../blocks/BiospaceSingleSignonForBioconEmployees3/src/BiospaceSingleSignonForBioconEmployees3';
import PasswordProtectedPages from '../../blocks/PasswordProtectedPages/src/PasswordProtectedPages';
import Search from '../../blocks/Search/src/Search';
import LoginScreenWeb from '../../blocks/email-account-login/src/LoginScreen.web';
import SignupWeb from '../../blocks/email-account-registration/src/SignupWeb';
import { ToastContainer } from 'react-toastify';
import DocumentDistribution from '../../blocks/DocumentDistribution/src/DocumentDistribution.web';
import CustomerUserProfileUpdate from '../../blocks/CustomisableUserProfiles/src/CustomerUserProfileUpdate.web';
import TicketQueryDetail from '../../blocks/RequestManagement/src/TicketQueryDetail.web';
import ProfileDetails from '../../blocks/AuditTrail/src/profileDetails.web';
import ProductListing from '../../blocks/catalogue/src/ProductListing.web';
import MyQueries from '../../blocks/catalogue/src/MyQueries.web';
import QueryDetails from '../../blocks/catalogue/src/QueryDetails.web';
import TicketReplies from '../../blocks/catalogue/src/TicketReplies.web';
import ProductListing2 from '../../blocks/catalogue/src/ProductListing2.web';
import APIView from '../../blocks/ReviewAndApproval/src/ApiView.web';
import EmployeeProfile from '../../blocks/CustomisableUserProfiles/src/CustomerDetailsInEmployee.web';
import LoginScreenBioSpaceWeb from '../../blocks/email-account-login/src/LoginScreenBioSpace.web';
import ZeplinLoginScreenWeb from '../../blocks/email-account-login/src/ZeplinLogin.web';
import SignUpRegistration from '../../blocks/email-account-registration/src/SignUpRegistration.web';
import SignUpRegistrationZeplin from '../../blocks/email-account-registration/src/SignUpRegistrationZeplin.web';
import ConnectWithUs from '../../blocks/DynamicContent/src/ConnectWithUs.web';
import FullPageBanners from '../../blocks/landingpage/src/FullPageBanner.web';
import TicketList from '../../blocks/AdminConsole3/src/TicketList.web';
import CustomerTicketList from '../../blocks/AdminConsole3/src/CustomerTicketList.web';
import MasterCustomer from '../../blocks/AdminConsole3/src/MasterCustomer.web';
import MasterContact from '../../blocks/AdminConsole3/src/MasterContact.web';
import UserDetails from '../../blocks/AuditTrail/src/userDetail.web';
import CustomerAddUpdateProfile from '../../blocks/AuditTrail/src/profileDetails.web';
import LandingPage2 from '../../blocks/landingpage/src/LandingPage2.web';
import CustomerContactAdd from '../../blocks/AuditTrail/src/userDetail.web';
import HandleSignupRequest from '../../blocks/DataStorage/src/HandleSignupRequest.web';
import AddInvoice from '../../blocks/AuditTrail/src/AddInvoice.web';
import SignUpRegistrationZeplin2 from '../../blocks/email-account-registration/src/zeplinSignUp';
import DocumentView from '../../blocks/DownloadPermissions/src/DocumentView.web';
import OverdueTickets from '../../blocks/AdminConsole3/src/OverdueTickets.web';
import NewTicket from "../../blocks/AdminConsole3/src/NewTicket.web";
import MyInvoice from '../../blocks/catalogue/src/MyInvoices.web';
import InvoiceDetails from '../../blocks/catalogue/src/InvoiceDetails.web';
import DocPreview from "../../blocks/DataStorage/src/DocPreview.web";

const routeMap = {
  Login: {
    component: LoginScreenWeb,
    path: '/login'
  },
  NewLogin: {
    component: ZeplinLoginScreenWeb,
    path: '/newlogin'
  },
  bannerVideo: {
    component: FullPageBanners,
    path: '/bannerVideo'
  },
  LoginScreenBioSpaceWeb: {
    component: LoginScreenBioSpaceWeb,
    path: '/biospaceLogin'
  },
  Signup: {
    component: SignUpRegistrationZeplin2,
    path: '/signup'
  },
  Registration: {
    component: SignUpRegistrationZeplin2,
    path: '/registration'
  },
  Catalogue: {
    component: ProductListing,
    path: '/Catalogue'
  },
  TicketQueryDetail: {
    component: TicketQueryDetail,
    path: '/TicketQueryDetail'
  },
  ProfileDetails: {
    component: ProfileDetails,
    path: '/ProfileDetails'
  },
  EmployeeProfile: {
    component: EmployeeProfile,
    path: '/EmployeeProfile'
  },
  CustomerProductQuery: {
    component: CustomerProductQuery,
    path: '/CustomerProductQuery/:query/:id'
  },
  // ProductListing: {
  //   component: ProductListing,
  //   path: "/ProductListing"
  // },
  ProductListing: {
    component: ProductListing2,
    path: '/ProductListing/:pageNumber'
  },

  MyQueries: {
    component: MyQueries,
    path: '/MyQueries'
  },
  QueryDetails: {
    component: QueryDetails,
    path: '/QueryDetails/:id',
    exact: true
  },
  TicketReplies: {
    component: TicketReplies,
    path: '/TicketReplies/:id'
  },
  DownloadPermissions: {
    component: DownloadPermissions,
    path: '/DownloadPermissions'
  },
  DocumentView: {
    component: DocumentView,
    path: '/DocumentView/Catalogue/:CatalogueId/id/:BucketId'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword/:token'
    // path: '/NewPassword'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  AuditTrail: {
    component: AuditTrail,
    path: '/AuditTrail'
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: '/AdminConsole3'
  },
  DynamicContent: {
    component: DynamicContent,
    path: '/DynamicContent'
  },
  SecurityPolicy: {
    component: SecurityPolicy,
    path: '/SecurityPolicy'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  DocumentDistribution: {
    component: DocumentDistribution,
    path: '/DocumentDistribution'
  },
  ProductDetails: {
    component: ProductDetailWeb,
    path: '/ProductDetail/:id'
  },
  DataStorage: {
    component: QueryDetail,
    path: '/ticket/:id',
    exact: true
  },
  TicketDetails: {
    component: TicketDetails,
    path: '/ticket/:id/details',
    exact: true
  },
  QueryDetailsTicket: {
    component: TicketDetails,
    path: '/QueryDetails/:id/detail',
    exact: true
  },
  APIView: {
    component: APIView,
    path: '/APIView'
  },
  RequestManagement: {
    component: RequestManagement,
    path: '/RequestManagement'
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },
  TwofactorAuthentication: {
    component: TwofactorAuthentication,
    path: '/TwofactorAuthentication'
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage'
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/RolesPermissions2'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  Sorting: {
    component: Sorting,
    path: '/Sorting'
  },
  ReviewAndApproval: {
    component: ReviewAndApproval,
    path: '/ReviewAndApproval'
  },
  CustomisableUserProfiles: {
    component: CustomerUserProfileUpdate,
    path: '/CustomisableUserProfiles'
  },
  BiospaceSingleSignonForBioconEmployees3: {
    component: BiospaceSingleSignonForBioconEmployees3,
    path: '/BiospaceSingleSignonForBioconEmployees3'
  },
  PasswordProtectedPages: {
    component: PasswordProtectedPages,
    path: '/PasswordProtectedPages'
  },
  Search: {
    component: Search,
    path: '/Search'
  },

  Home: {
    component: LandingPage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  ConnectWithUs: {
    component: ConnectWithUs,
    path: '/connectwithus'
  },
  TicketList: {
    component: TicketList,
    path: '/TicketList'
  },
  CustomerTicketList: {
    component: CustomerTicketList,
    path: '/CustomerTicketList'
  },
  MasterCustomer: {
    component: MasterCustomer,
    path: '/MasterCustomer'
  },
  MasterContact: {
    component: MasterContact,
    path: '/MasterContact'
  },
  UserDetails: {
    component: UserDetails,
    path: '/UserDetails'
  },
  CustomerAdd: {
    component: CustomerAddUpdateProfile,
    path: '/customer/add/:id?',
    exact: true
  },
  CustomerUpdate: {
    component: CustomerAddUpdateProfile,
    path: '/customer/edit/:id',
    exact: true
  },
  NewHeader: {
    component: LandingPage2,
    path: '/NewHeader'
  },
  CustomerContactAdd: {
    component: CustomerContactAdd,
    path: '/CustomerContact/Add/:id?'
  },
  CustomerContactEdit: {
    component: CustomerContactAdd,
    path: '/CustomerContact/edit/:id'
  },
  MyInvoice: {
    component: MyInvoice,
    path: '/MyInvoice'
  },
  InvoiceDetails: {
    component: InvoiceDetails,
    path: '/InvoiceDetails/:id/:customerID'
  },
  AddInvoice: {
    component: AddInvoice,
    path: '/AddInvoice/Add/:id?'
  },
  AddInvoiceEdit: {
    component: AddInvoice,
    path: '/AddInvoice/edit/:id/:customerID'
  },
  AddContactInvoice: {
    component: AddInvoice,
    path: '/AddContactInvoice/Add/:id?'
  },
  AddContactInvoiceEdit: {
    component: AddInvoice,
    path: '/AddContactInvoice/edit/:id/:customerID'
  },
  HandleSignupRequest: {
    component: HandleSignupRequest,
    path: '/SignupRequest/:id?'
  },
  OverdueTickets: {
    component: OverdueTickets,
    path: '/OverdueTickets'
  },
  ResetPassword: {
    component: ResetPassword,
    path: "/ResetPassword/:token?"
  },
  NewTicket: {
    component: NewTicket,
    path: '/NewTicket'
  },
  DocPreview: {
    component: DocPreview,
    path: '/documentPreview'
  },
  VerifiedLogin: {
    component: LoginScreenWeb,
    path: '/loginVerify'
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ToastContainer />
        <ModalContainer />
      </View>
    );
  }
}

export default App;
