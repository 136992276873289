import React from "react";
// Customizable Area Start
import {
  Container, Typography, Grid, Box, TextField, InputAdornment,
  IconButton,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import QueryCard from "./QueriesCard.web";
import { AppHeader } from "../../../components/src/AppHeader.web";

import AppFooter from "../../../components/src/AppFooter.web";
import MyQueriesSortMenuPopup from "./MyQueriesSortMenuPopup.web";
import { withStyles } from '@material-ui/core/styles';
import MyQueriesController, { Props } from "./MyQueriesController";
import Loader from "../../../components/src/Loader.web";
import CustomPaginationWeb from "../../../components/src/CustomPagination.web";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class MyQueries extends MyQueriesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.searchInputRefVar = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  searchInputRefVar: React.RefObject<HTMLInputElement>;
  // handlePageChange = (e: any, p: any) => {
  //   this.setState({
  //     paginationData: this.state.arrayHolder.slice(
  //       PER_PAGE * (p - 1),
  //       PER_PAGE * p
  //     ),
  //     page: p,
  //   });
  // };

  // Customizable Area End

  render() {
    return (
      <ThemeProvider theme={theme}>
        <AppHeader navigation={this.props.navigation}/>

        <Loader loading={this.state.is_loading} />
        <ProductGrid style={{ marginBottom: "49px", }}>
          <Typography style={webStyle.detailTitle}>My Request</Typography>

          <Container style={{ padding: 0, position: 'relative' }}>
            <MyQueriesSortMenuPopup
              selectedTab={this.state.selected_tab}
              handleChangeTab={this.handleChangeTab}
              handleSort={this.handleSorting}
              sortKey={this.state.sort_key}
              totalRecords={this.state.tickets_count}
            // Ascending={true}
            />

            <Grid container spacing={6} style={webStyle.productContainer}>
              {this.state.queries_list && this.state.queries_list.length > 0 ? this.state.queries_list.map((item: any, index: number) => (
                <Grid item xl={2} lg={3} md={4} sm={6} xs={12}  key={`query-${index}`} style={{ display: "flex", justifyContent: "center", padding: '15px 0px' }}>
                  <QueryCard
                    key={`query-${index}`}
                    value={item}  
                  />
                </Grid>
              )) :
                <Grid
                  style={webStyle.NoDataContainer}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography style={webStyle.noDataTitle}>
                    {!this.state.is_loading && this.state.queries_list.length === 0 && "No Requests"}
                  </Typography>
                </Grid>


              }
            </Grid>
            {this.state.query_list_meta && <CustomPaginationWeb
              data-testid="customPaginationId"
              per_page={this.state.per_page_records}
              page={this.state.query_list_meta?.current_page}
              count={Math.ceil(this.state.query_list_meta?.total_count / this.state.per_page_records)}
              arrayLength={this.state.query_list_meta?.total_count}
              handlePageChange={(e: any, p: any) => this.handlePageChange(e, p)}
            />}

          </Container>
        </ProductGrid>
        <AppFooter isLoading={this.state.is_loading} />
      </ThemeProvider >
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start



const ProductGrid : any = withStyles({
  root: {
    padding: '0px 85px 10px 85px',
    "@media (max-width: 1100px)": {
      padding: `10px 53px 10px 55px !important`,
    },
    "@media (max-width: 600px)": {
      padding: `0px 15px !important`,
    },
    "& .MuiTypography-h5": {
      fontFamily: "SFProDisplay",
      fontWeight: 700,
      fontSize: `32px !important`,
    },
    "& .MuiList-padding": {
      paddingTop: `0px !important`,
      paddingBottom: `0px !important`,
    },
    "& .MuiGrid-item": {
      "@media (max-width: 660px)": {
        justifyContent: "center !important",
        margin: "0px auto",
        padding: "0",
      },
    },
    "& .MuiGrid-container": {
      "@media (max-width: 660px)": {
        flexDirection: 'column !important',
      },
    }
  }
})(Grid);

const PER_PAGE = 9;

const webStyle = {
  detailTitle: {
    color: "#043c74",
    fontFamily: "SFProDisplay",
    fontSize: "24px",
    paddingBottom: "20px",
    marginTop: "32px",
    fontWeight: 700,
  },
  filterContainer: {
    backgroundColor: "rgb(239 241 245)",
    padding: "15px 126px 25px 143px",
  },
  listName: {
    display: "flex",
    marginTop: "30px",
    marginLeft: "50px",
    fontSize: "x-large",
    fontWeight: 600,
    color: "rgba(4, 60, 116, 1)",
  },
  viewAll: {
    display: "flex",
    justifyContent: "right",
    textDecoration: "underline",
    color: "rgb(4, 60, 116)",
    marginTop: "-1.5rem",
  },
  productContainer: {
    backgroundColor: "white",
    padding: '30px 0px',
    // display: "flex",
    //justifyContent: "center",
    // padding: '0px 12px'
  },
  NoDataContainer: {
    height: '220px'
  },
  noDataTitle: {
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    color: 'rgb(4, 60, 116)',
    fontSize: '24px',
    marginTop: '-20px'
  },


};


// Customizable Area End
