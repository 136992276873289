import React from "react";
import { useEffect, useRef, useState } from 'react'
import { DateRange } from 'react-date-range'

import format from 'date-fns/format'
import { addDays } from 'date-fns'
import CloseIcon from '@material-ui/icons/Close';
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import "./dateRangePicker.css"
import { string } from "yup";

function DateRangeComp(props: any) {

  // date state
  const date = [
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]
  const [range, setRange] = useState(date)
  //saving range in localstorage



  // open close
  const [open, setOpen] = useState(true)

  // get the target element to toggle 
  const refOne = useRef<HTMLDivElement>(null)


  React.useEffect(() => {
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true)
    document.addEventListener("click", hideOnClickOutside, true)

  }, [])

  // hide dropdown on ESC press
  const hideOnEscape = (e: any) => {
    // console.log(e.key)
    if (e.key === "Escape") {
      setOpen(false)
    }
  }

  // Hide on outside click
  const hideOnClickOutside = (e: any) => {
    // console.log(refOne.current)
    // console.log(e.target)
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false)
    }
  }

  // const hideOnSelection = () => {
  //   setOpen(false)
  // }
  const handleClickCloseCalendar = () => {
    setOpen(false)
  };

  const handleClickResetDate = () => {
    const startDate=""
    const endDate=""
    localStorage.setItem('rangeValue', JSON.stringify({ startDate, key: "selection", endDate }))
    setRange(date)
    setOpen(false)
    props.handleSort(2)
  }

  const handleClickOkDate = () => {
    setRange([...range])
    setOpen(false)
    props.handleSort(2)
    props.setShowDatePicker(false)
  }

  return (
    <div className="calendarWrap" style={{ position: "absolute", right: "-10px", top: '60px', zIndex: 999, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', }}>
      <div style={{ backgroundColor: '#ffffff' }} ref={refOne}>
        {open &&
          <>
            <span style={webStyle.close}>
              <CloseIcon data-testid = 'handleClickCloseCalendar' onClick={handleClickCloseCalendar}></CloseIcon>
            </span>
            <DateRange
            data-testid='customPaginationId'
              onChange={(item: any) => {
                setRange([item.selection])
                const startDate = new Date((item.selection.startDate).getTime() - (item.selection.startDate).getTimezoneOffset() * 60000).toISOString()
                const endDate = new Date((item.selection.endDate).getTime() - (item.selection.endDate).getTimezoneOffset() * 60000).toISOString()
                localStorage.setItem('rangeValue', JSON.stringify({ startDate, key: "selection", endDate }))
              }}
              editableDateInputs={true}
              moveRangeOnFirstSelection={false}
              ranges={range}
              months={1}
              direction="horizontal"
              className="calendarElement"
            />
            <div style={{ display: "flex", justifyContent: 'space-evenly', padding: '0px 10px 10px 0px ' }}>
              <button data-testid='backSpaceBtn' onClick={handleClickOkDate} style={webStyle.cardButton} ><span>Ok</span></button>
              <button data-testid='backResetBtn' onClick={handleClickResetDate} style={webStyle.cardButton}><span>Reset</span></button>
            </div>
          </>
        }
      </div>
    </div>
  )


}

const webStyle = {
  mainContainer: {
    position: "absolute",
  },
  close: {
    display: "flex",
    justifyContent: "end",
    transform: 'translate(-10px, 19px)',
    cursor: 'pointer',
  },
  cardButton: {
    borderRadius: '8px',
    height: "40px",
    width: '100px',
    fontFamily: 'SFProDisplay',
    fontWeight: 500,
    boxShadow: 'none',
    backgroundColor: 'rgb(3 60 116)',
    color: 'rgb(198, 228, 247)',
  },

}

export default DateRangeComp