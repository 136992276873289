export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const poster1 = require("../assets/poster1.png");
export const poster2 = require("../assets/poster2.png");
export const Icon1 = require("../assets/icon1.png");
export const Icon2 = require("../assets/icon2.png");
export const Icon3 = require("../assets/icon3.png");
export const Icon4 = require("../assets/icon4.png");
export const Icon5 = require("../assets/icon5.png");
export const Icon6 = require("../assets/icon6.png");
export const Icon7 = require("../assets/Icon72.svg");
export const Icon8 = require("../assets/icon8.png");
export const Icon9 = require("../assets/Icon92.svg");
export const Icon10 = require("../assets/icon10.png");
export const Icon11 = require("../assets/Icon112.svg");
export const Icon12 = require("../assets/icon12.png");
export const Icon13 = require("../assets/Icon13.svg");
