import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall, authorizeEmployee, getHeaders, getUserdetails, isAuthorisedUser, isLoggedIn, warningNotification, events, autoLogOut } from "../../../components/src/common";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

export interface ITicketListMeta {
  pagination_details: {
    current_count: number;
    current_page: number;
    next_page: number;
    prev_page: null | number;
    total_count: number;
    total_pages: number;
  };
}

export interface ICustomerMasterTicket {
  id: string;
  type: string;
  attributes: {
    customer_id: string;
    customer_name: string;
    customer_sap_code: number;
    industry: string;
    parent_customer_name: string;
  }
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedTab: number;
  ticketId: string;
  productName: string;
  customerName: string;
  headCells: any[];
  isAsc: boolean;
  orderBy: string;
  order: any;
  rows: any[];
  customerTicketsMeta: null | ITicketListMeta;
  arrayHolderDuplicate: any[];
  count: number;
  page: number;
  token: string;
  ticketList: any[];
  paginationData: any;
  isSearch: boolean;
  searchTerm: string;
  customerTickets: null | ICustomerMasterTicket[];
  csvData: string;
  csvFileName: string;
  isLoading: boolean;
  isOnlyView: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdminConsole3Controller extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  authToken: string;
  getFilterAndSearchProductsApiCallId: any;
  getProductApiCallId: any;
  perPageRecords: number = 9;
  searchProductApiCallId: any;
  getCustomerMasterTicketsCallId: string;
  getCustomerMasterDownloadTicketsCallId: string;
  csvRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.csvRef = React.createRef();
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      token: "",
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedTab: 1,
      ticketId: '',
      productName: '',
      customerName: '',
      count: 0,
      page: 1,
      ticketList: [],
      isSearch: false,
      searchTerm: '',
      rows: [],
      arrayHolderDuplicate: [],
      orderBy: '',
      order: 'asc',
      isAsc: false,
      headCells: [
        {
          id: 'ticket_number',
          numeric: 'left',
          disablePadding: true,
          isSorting: false,
          label: 'Customer ID',
        },
        {
          id: 'product_name',
          numeric: 'left',
          disablePadding: false,
          isSorting: false,
          label: 'Customer Name',
        },
        {
          id: 'query_type',
          numeric: 'left',
          disablePadding: false,
          isSorting: false,
          label: 'Parent Customer Name',
        },
        {
          id: 'status',
          numeric: 'left',
          disablePadding: false,
          isSorting: false,
          label: 'Customer SAP Code',
        },
        {
          id: 'customer_name',
          numeric: 'left',
          disablePadding: false,
          isSorting: false,
          label: 'Industry',
        },
        {
          id: 'viewDetails',
          numeric: 'center',
          disablePadding: false,
          isSorting: false,
          label: 'Check Details',
        },
      ],
      customerTicketsMeta: null,
      paginationData: [],
      customerTickets: null,
      csvData: "",
      csvFileName: "",
      isLoading: false,
      isOnlyView: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async componentDidMount() {
    super.componentDidMount();
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        if(isLoggedIn(this.props.navigation)) {
          autoLogOut();
        }
      });
    });
    const authToken = isLoggedIn(this.props.navigation);
    authorizeEmployee(this.props.navigation);
    localStorage.setItem('previousPath', window.location.pathname)
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
      this.getCustomerMasterTickets();
    }
    this.setState({ arrayHolderDuplicate: this.state.rows });
    this.setViewDetails()
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  setViewDetails = () => {
    let useDetails = JSON.parse(localStorage.getItem("customr_master_permission") || "")
    if (useDetails?.can_create) {
      this.setState({ isOnlyView: true });
    } else {
      this.setState({ isOnlyView: false });
    }
  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }



    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.setState({
          isLoading: false
        });
        isAuthorisedUser(responseJson, this.props.navigation);
        if (apiRequestCallId === this.getCustomerMasterTicketsCallId) {
          if (responseJson.success) {
            this.setState({
              customerTickets: responseJson.result.data,
              customerTicketsMeta: responseJson.result.meta
            })
          } else {
            this.setState({
              customerTickets: [],
              customerTicketsMeta: null
            })
          }
        }

        if (apiRequestCallId === this.getCustomerMasterDownloadTicketsCallId) {
          if (responseJson.success && responseJson.result) {
            this.setState({
              csvData: responseJson.result,
              csvFileName: responseJson.filename
            }, () => {
              this.csvRef.current.link.click()
            })
          } else {
            warningNotification("Something went wrong");
          }

        }
      }
    }



    // Customizable Area Start
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events



  handlePageChange = (e: any, p: any) => {
    this.setState({
      page: p,
    }, () => {
      this.getCustomerMasterTickets();
    });
  };

  debounce = (func: any, wait: number = 700) => {
    let timer: any;
    return (...args: any[]) => {
      clearTimeout(timer)
      timer = setTimeout(() => func(...args), wait);
    };
  };

  debouncedCallback = this.debounce(() => this.getCustomerMasterTickets(), 700);

  handleChange = (prop: any) => (event: any) => {
    const value = event.target.value;
    let customerId = this.state.ticketId;
    let productName = this.state.productName;
    let industry = this.state.customerName;
    if (prop === "ticketId" && value && value.length > 0) {
      customerId = value;
    } else if (prop === "productName" && value && value.length > 0) {
      productName = value;
    } else if (prop === "customerName" && value && value.length > 0) {
      industry = value;
    }
    if (value && value.length > 0) {
      this.setState({
        ...this.state,
        ticketId: customerId,
        productName: productName,
        customerName: industry,
        searchTerm: value,
        page: 1
      }, () => {
        this.debouncedCallback();
      })
    } else {
      this.setState({
        ...this.state,
        [prop]: "",
        searchTerm: "",
        page: 1
      }, () => {
        this.debouncedCallback();
      })
    }


  }


  getCustomerMasterTickets = () => {
    this.setState({
      isLoading: true
    })
    const customerMasterCall = apiCall({
      url: configJSON.getCustomerMasterTicketsApiCallId + `?per_page=10&page=${this.state.page}&query=${this.state.ticketId}&query_one=${this.state.productName}&query_two=${this.state.customerName}`,
      httpBody: {},
      httpMethod: configJSON.getCustomerMasterTicketsApiMethod,
      header: getHeaders({ token: this.authToken })
    });

    this.getCustomerMasterTicketsCallId = customerMasterCall.messageId;
    runEngine.sendMessage(customerMasterCall.id, customerMasterCall);
  }

  downloadCustomerMasterTickets = () => {
    this.setState({
      isLoading: true
    })

    const downloadMaster = apiCall({
      url: configJSON.getCustomerMasterDownloadApiEndpoint + `?query=${this.state.ticketId}&query_one=${this.state.productName}&query_two=${this.state.customerName}`,
      httpBody: {},
      httpMethod: configJSON.downloadAssignedToMeTicketsApiMethod,
      header: getHeaders({ token: this.authToken })
    });

    this.getCustomerMasterDownloadTicketsCallId = downloadMaster.messageId;
    runEngine.sendMessage(downloadMaster.id, downloadMaster);
  }


  // Customizable Area Start
  handleAddNewClick = () => {
    if (this.props.navigation) {
      this.props.navigation.navigate("CustomerAdd")
    }
  }

  handleTicketRefresh = () => {
    this.getCustomerMasterTickets();
  }
  // Customizable Area End

}
