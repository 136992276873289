import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { handleUpdateAuthToken,apiCall, getHeaders, isAuthorisedUser, isLoggedIn, successNotification } from "../../../components/src/common";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface IQueryReplyDetail {
  employee_name?: string;
  user_name?: string;
  reply: string;
  reply_time: string;
  attachment: any[];
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  is_loading: boolean;
  query_replies: IQueryReplyDetail[];
  close_message: string | null;
  ticket_number: string;
  isAttachment: boolean;
  isSendComment: boolean;
  ticketComment: any;
  uploadedAPIFilesBase: any;
  uploadedAPIFiles: any;
  isUploadError: boolean;
  ticket_status: string;
  closed_status:any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class TicketRepliesController extends BlockComponent<Props, S, SS> {
  getQueryRepliesApiCallId: string;
  ticketId: string;
  authToken: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      is_loading: false,
      query_replies: [],
      close_message: null,
      ticket_number: '',
      isAttachment: false,
      isSendComment: false,
      ticketComment: "",
      uploadedAPIFilesBase: [],
      uploadedAPIFiles: [],
      isUploadError: false,
      ticket_status: 'InProgress',
      // Customizable Area Start
      closed_status:''
      // Customizable Area End 
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.sendComment = this.sendComment.bind(this);
    this.getQueryReplies = this.getQueryReplies.bind(this);
    this.handleAttachmentPopUP = this.handleAttachmentPopUP.bind(this);
    this.handleCloseAttachmentPopUP = this.handleCloseAttachmentPopUP.bind(this);
    this.handleSendComment = this.handleSendComment.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.handleDetailsDocumentUpload = this.handleDetailsDocumentUpload.bind(this);
    this.fileToDataUri = this.fileToDataUri.bind(this);
    this.downloadAPIAttachmentFile = this.downloadAPIAttachmentFile.bind(this);
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const authToken = isLoggedIn(this.props.navigation);
    this.ticketId = this.props.navigation.getParam("id");
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
      this.getQueryReplies();
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }

    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      isAuthorisedUser(responseJson, this.props.navigation);
      this.setState({
        is_loading: false
      });

      if (this.getQueryRepliesApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        if (responseJson && responseJson.ticket_number) {
          this.setState({
            ticket_number: responseJson.ticket_number,
            query_replies: responseJson.user_result,
            close_message: responseJson.closed_message,
            ticket_status: responseJson.ticket_status,
            closed_status: responseJson.ticket_status.status
          })
        }else {
          if (responseJson.errors && responseJson.errors[0]?.token === "Token has Expired" && JSON.parse(localStorage.getItem("userInfo") || "")?.attributes?.is_customer === 'True') {
            this.setState({
              is_loading: true
            })
            await handleUpdateAuthToken()
            const authToken = isLoggedIn(this.props.navigation);
            if (typeof (authToken) === "string") {
              this.authToken = authToken;
            } 
            if (this.authToken){
              this.getQueryReplies();
            }
          }
        }
      }

      if (this.LeaveaCommentApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        if (responseJson && !responseJson.errors) {
          this.setState({
            is_loading: false
          });
          this.setState({
            ticketComment: "",
            uploadedAPIFiles: [],
            uploadedAPIFilesBase: []
          })
          this.getQueryReplies();
          successNotification("Ticket Comment added successfully");
        } else {
          if (responseJson.errors && responseJson.errors[0]?.token === "Token has Expired" && JSON.parse(localStorage.getItem("userInfo") || "")?.attributes?.is_customer === 'True') {
            this.setState({
              is_loading: true
            })
            await handleUpdateAuthToken()
            const authToken = isLoggedIn(this.props.navigation);
            if (typeof (authToken) === "string") {
              this.authToken = authToken;
            } 
            if (this.authToken){
              this.sendComment();
            }
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  LeaveaCommentApiCallId: string;
  getQueryReplies() {
    this.setState({
      is_loading: true
    });
    const header = getHeaders({ token: this.authToken });
    const fetchQueryReplies = apiCall({
      url: configJSON.getMyQueryRepliesApiEndpoint + `?ticket_id=${this.ticketId}`,
      header,
      httpBody: {},
      httpMethod: configJSON.getMyQueryRepliesApiMethod
    });

    this.getQueryRepliesApiCallId = fetchQueryReplies.messageId;
    runEngine.sendMessage(fetchQueryReplies.id, fetchQueryReplies);
  }

  handleAttachmentPopUP = () => {
    this.setState({
      isAttachment: !this.state.isAttachment
    })
  };

  handleCloseAttachmentPopUP = () => {
    this.setState({
      isAttachment: !this.state.isAttachment,
      uploadedAPIFilesBase: []
    });
    this.setFileSizeError(false)
  };

  handleSendComment = () => {
    this.setState({
      isSendComment: !this.state.isSendComment
    })
  };

  handleCommentChange = (e: any) => {
    this.setState({
      ticketComment: e.target.value
    })
  };

  sendComment = () => {
    this.ticketId = this.props.navigation.getParam("id");
    const httpBody = {
      customer_attachments_attributes: this.state.uploadedAPIFilesBase,
      message: this.state.ticketComment,
    }
    const connectWithUs = apiCall({
      url: configJSON.leaveACommenyApiEndPoint + `?ticket_id=${this.ticketId}`,
      httpBody: httpBody,
      httpMethod: configJSON.downloadCatalogSentEmailMethod,
      header: getHeaders({ token: this.authToken })
    });

    this.LeaveaCommentApiCallId = connectWithUs.messageId;
    runEngine.sendMessage(connectWithUs.id, connectWithUs);
    this.setState({
      is_loading: true
    })
  };

  fileToDataUri = (image: any) => {
    return new Promise((res) => {
      const reader = new FileReader();
      const { name, } = image;
      reader.addEventListener('load', () => {
        res({ file_name: name, upload: reader.result })
      });
      reader.readAsDataURL(image);
    })
  };

  handleDetailsDocumentUpload = async (files: any) => {

    const uploadedFiles = [].slice.call(files);
    uploadedFiles.forEach((file: File) => {
      return {
        name: file.name
      }
    })

    for (let i = 0; i < files.length; i++) {
      this.state.uploadedAPIFilesBase.push(this.fileToDataUri(files[i]))
    }

    const fileBase64 = await Promise.all(this.state.uploadedAPIFilesBase)
    this.setState({
      uploadedAPIFilesBase: fileBase64,
      uploadedAPIFiles: uploadedFiles
    });
  };

  setFileSizeError = (value: boolean) => {
    this.setState({
      isUploadError: value
    })
  }

  downloadAPIAttachmentFile = (attachment: any) => {
    fetch(attachment.doc_file, {
      method: 'GET'
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        if (attachment.file_name !== undefined) {
          link.setAttribute(
            'download',
            attachment.file_name,
          );
        }

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link?.parentNode?.removeChild(link);
      });
  }
  // Customizable Area End
}
