import React from "react";
import { Grid, Typography, TextField, InputLabel, FormControlLabel, Checkbox, Button } from "@material-ui/core";
import { LoginImage, BioLogo } from "./assets";
import { withStyles } from "@material-ui/core/styles";
import { responsiveWidth } from 'react-native-responsive-dimensions';

import EmailAccountLoginController, {
    Props
} from "./EmailAccountLoginController";
import { Dimensions, PixelRatio, Platform } from "react-native";

let screenWidth = Dimensions.get('window').width;
let screenHeight = Dimensions.get('window').height;
//Artboard Dimension 
let artBoardHeightOrg = 1024;
let artBoardWidthOrg = 1440;
//Re calculated Artboard Dimension 
let artBoardWidth = isSameRatio() ? artBoardWidthOrg : screenWidth;
let artBoardHeight = isSameRatio() ? artBoardHeightOrg : screenHeight;
// To check if Artboard and Device screen has same ratio 
function isSameRatio(): boolean {
    return artBoardWidthOrg / artBoardHeightOrg < 1 && screenWidth / screenHeight < 1
}
//Top or Bottom nav spaces or any extra space occupied by os e.g Status bar, Notch 
let extraSpace = 0;

function deviceBasedDynamicDimension(originalDimen: number, compareWithWidth: boolean, resizeFactor: number): number | undefined {
    if (originalDimen != null) {
        if (resizeFactor != null) {
            originalDimen *= resizeFactor;
        }
        const compareArtBoardDimenValue = compareWithWidth ? artBoardWidth : artBoardHeight;
        const artBoardScreenDimenRatio = (originalDimen * 100) / compareArtBoardDimenValue;
        let compareCurrentScreenDimenValue = compareWithWidth ? screenWidth : screenHeight - extraSpace;
        if (Platform.OS === 'web') {
            return (responsiveWidth(originalDimen / compareCurrentScreenDimenValue) * 100);
        }
        return PixelRatio.roundToNearestPixel((artBoardScreenDimenRatio * compareCurrentScreenDimenValue) / 100,);
    }
    return 0;
}

export default class LoginScreenWeb extends EmailAccountLoginController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <>
                <Grid container style={{ overflow: 'auto' }}>
                    <Grid item md={6} sm={6} xs={12}>
                        <img src={LoginImage} height={screenHeight} width={'100%'} />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} style={webStyle.rightPart}>
                        <img src={BioLogo} height={60} width={'100%'} style={webStyle.LogoImage} />
                        <Typography style={webStyle.Header} variant="h2">{this.labelTitle}</Typography>
                        <Typography style={webStyle.subHeader}>{this.state.loginSubHeading}</Typography>
                        <InputLabel
                            htmlFor="txtInputEmail"
                            style={webStyle.EmailinputLabel}
                        >
                            Email address
                        </InputLabel>
                        <InputField
                            id="txtInputEmail"
                            variant="outlined"
                            placeholder="example@gmail.com"
                            fullWidth
                        />
                        <InputLabel
                            htmlFor="passwordInput"
                            style={webStyle.PassinputLabel}
                        >
                            Password
                        </InputLabel>
                        <InputField
                            id="passwordInput"
                            variant="outlined"
                            placeholder="........."
                            fullWidth
                        />
                        <Grid container justifyContent="space-between">
                            <CheckBoxLabel
                                control={
                                    <Checkbox
                                        color="default"
                                        disableRipple
                                        checked={this.CustomCheckBoxProps.isChecked}
                                        onChange={this.btnRememberMeProps.onPress}
                                        checkedIcon={<span style={(webStyle.checkedIcon)} />}
                                        icon={<span style={webStyle.icon} />}
                                        inputProps={{ 'aria-label': 'decorative checkbox' }}
                                    />
                                }
                                label="Remember Password"
                            />
                            <Typography style={webStyle.PassText}>{this.state.forgotPasslabel}</Typography>
                        </Grid>
                        <Buttons
                            variant="contained"
                        >
                            Login
                        </Buttons>
                        <Grid container justifyContent="center">
                            <Typography style={webStyle.AccountText}>
                                {this.state.AccountText}
                            </Typography>
                            <Typography style={webStyle.singUp}>
                                {this.state.signUpText}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }
}

// Customizable Area Start
const webStyle = {
    rightPart: {
        paddingRight: '30px',
        paddingTop: '20px',
        paddingLeft: '25px'
    },
    LogoImage: {
        display: 'flex',
        width: '100px',
        paddingTop: '37px',
        paddingBottom: '20px',
    },
    Header: {
        fontStyle: 'normal',
        fontWeight: 500,
        color: 'rgba(4, 60, 116, 1)',
        fontFamily: 'SFProDisplay',
        fontSize: deviceBasedDynamicDimension(30, true, 1),
        marginBottom: '5px'
    },
    subHeader: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontFamily: 'SFProDisplay',
        fontSize: deviceBasedDynamicDimension(16, true, 1),
        color: 'rgba(106, 116, 165, 1)',
        marginBottom: '5px',
    },
    EmailinputLabel: {
        fontSize: deviceBasedDynamicDimension(18, true, 1),
        fontFamily: 'SFProDisplay',
        fontWeight: 500,
        color: 'rgba(58, 63, 99, 1)',
        paddingBottom: '5px',
        paddingTop: '10px',
    },
    PassinputLabel: {
        fontSize: deviceBasedDynamicDimension(18, true, 1),
        fontFamily: 'SFProDisplay',
        fontWeight: 500,
        color: 'rgba(58, 63, 99, 1)',
        paddingBottom: '5px',
        paddingTop: '10px',
    },
    PassText: {
        fontWeight: 500,
        color: 'rgba(69, 158, 255, 1)',
        fontFamily: 'SFProDisplay',
        fontSize: deviceBasedDynamicDimension(18, true, 1),
        paddingTop: '5px'
    },
    AccountText: {
        fontWeight: 400,
        color: 'rgba(58, 63, 99, 1)',
        fontFamily: 'SFProDisplay',
        fontSize: '18px',
        padding: '10px',
    },
    singUp: {
        fontWeight: 400,
        color: 'rgba(69, 158, 255, 1)',
        fontFamily: 'SFProDisplay',
        fontSize: '18px',
        padding: '10px',
    },
    icon: {
        borderRadius: 3,
        width: 15,
        height: 15,
        backgroundColor: 'white',
        border: '1px solid black',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: 'white',
        },
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    checkedIcon: {
        borderRadius: 3,
        border: '1px solid black',
        width: 15,
        height: 15,
        backgroundColor: 'white',
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='black'/%3E%3C/svg%3E\")",
        'input:hover ~ &': {
        },
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
};

const InputField:any = withStyles({
    root: {
        background: "inherite",
        margin: '5px 0px 5px 0px',
        "& .MuiOutlinedInput-root": {
            fontStyle: 'normal',
            color: 'rgba(214, 214, 214, 1)',
            fontFamily: 'SFProDisplay',
            fontWeight: 700,
            fontSize: deviceBasedDynamicDimension(16, true, 1),
            "& fieldset": {
                height: 55,
                borderColor: 'rgba(214, 214, 214, 1)',
                borderRadius: deviceBasedDynamicDimension(8, true, 1),
                borderWidth: deviceBasedDynamicDimension(1, true, 1),
            },
        }
    }
})(TextField);

const Buttons:any = withStyles({
    root: {
        width: '100%',
        color: 'rgba(255, 255, 255, 1)',
        backgroundColor: 'rgba(69, 158, 255, 1)',
        opacity: 1,
        boxShadow: 'none',
        height: '56px',
        borderRadius: '5px',
        fontFamily: 'DMSans-Bold',
        fontSize: deviceBasedDynamicDimension(26, true, 1),
        fontStyle: 'normal',
        fontWeight: 600,
        marginTop: deviceBasedDynamicDimension(10, false, 1),
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'rgba(69, 158, 255, 1)',
            boxShadow: 'none',
        },
    },
})(Button);

const CheckBoxLabel:any = withStyles({
    root: {
        "& .MuiTypography-body1": {
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
            fontSize: '18px',
            color: 'rgba(58, 63, 99, 1)',
        }
    },
})(FormControlLabel);

  // Customizable Area End
