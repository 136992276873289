import React from "react";
import {
  Typography,
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button
} from "@material-ui/core";
import { ILandingProducts } from "./LandingPageController";
import { withStyles } from "@material-ui/core/styles";
import { NavLink as RLink } from "react-router-dom";
import { getHighlightedText } from "../../../components/src/common";

let totalCards = 3;
const innerWidth = window.innerWidth;
if (innerWidth < 2100 && innerWidth > 1830) {
  totalCards = 3;
} else if (innerWidth < 1279) {
  totalCards = 2;
}

interface IProps {
  products: null | ILandingProducts[];
  isProductViewAll: boolean;
  setIsViewAll: () => void;
  handleKnowMore: (id: string) => void;
  searchTerm: string;
}

const Products = ({
  products,
  isProductViewAll,
  setIsViewAll,
  handleKnowMore,
  searchTerm
}: IProps) => {
  const productList = (item: ILandingProducts, index: any) => {
    return (
      <Grid
        key={`product-item-grid-${item.id}`}
        item
        lg={4}
        md={6}
        sm={12}
        xs={12}
      >
        <CustomCard key={`product-item-${item.id}`} style={{ height: '100%' }}>
          <CardHeader
            style={{
              ...webStyle.productTitle,
              ...(index % 2
                ? webStyle.productTitleEven
                : webStyle.productTitleOdd)
            }}
            title={getHighlightedText(item.name, searchTerm)}
          />
          <CustomCardContent>
            <Typography
              style={webStyle.detailLabel}
              align="center"
              className="details"
            >
              Manufacturing platform:{" "}
              <span
                style={
                  index % totalCards === 0
                    ? webStyle.detailValueOdd
                    : index % totalCards === 1
                      ? webStyle.detailValueEven
                      : webStyle.detailValueOdd
                }
              >
                {getHighlightedText(item.manufacturing_platform, searchTerm)}
              </span>
            </Typography>
            <Typography
              style={webStyle.detailLabel2}
              align="center"
              className="details"
            >
              Therapeutic area:{" "}
              <span
                className="textWrap"
                style={
                  index % totalCards === 0
                    ? webStyle.detailValueOdd
                    : index % totalCards === 1
                      ? webStyle.detailValueEven
                      : webStyle.detailValueOdd
                }
              >
                {getHighlightedText(item.therapeutic_area, searchTerm)}
              </span>
            </Typography>
          </CustomCardContent>
          <CustomCardAction>
            <RLink to={`/ProductDetail/${item.id}`}
              // onClick={() => handleKnowMore(`${item.id}`)}
              // activeClassName='is-active'
              exact={true}
            >
              <Button
                style={{
                  ...webStyle.cardButton,
                  ...(index % 2
                    ? webStyle.cardButtonEven
                    : webStyle.cardButtonOdd)
                }}
                // onClick={() => handleKnowMore(`${item.id}`)}
                variant="contained"
                className="knowMoreButton"
              >
                Know More
              </Button>
            </RLink>
          </CustomCardAction>
        </CustomCard>
      </Grid>
    );
  };

  return (
    <>
      <MainGrid>
        <Typography style={webStyle.listName}>Search Results</Typography>
        {isProductViewAll ? (
          <Grid container spacing={4} style={webStyle.updateContainer}>
            {products &&
              products.map((item: ILandingProducts, index: any) =>
                productList(item, index)
              )}
          </Grid>
        ) : (
          <Grid container spacing={4} style={webStyle.updateContainer}>
            {products &&
              products
                .slice(0, 4)
                .map((item: ILandingProducts, index: any) =>
                  productList(item, index)
                )}
          </Grid>
        )}
        {!isProductViewAll && products && products.length > 4 && (
          <Grid container justifyContent="flex-end">
            <Typography
              component="div"
              style={webStyle.viewAll}
              onClick={setIsViewAll}
            >
              View All
            </Typography>
          </Grid>
        )}
      </MainGrid>
    </>
  );
};

export default Products;
const webStyle = {
  mainContainer: {
    padding: "25px 62px 10px 72px"
  },
  updateContainer: {
    padding: "15px 0px 15px 0px"
  },
  detailLabel2: {
    color: "#6a74a5",
    fontSize: '16px',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    margin: '0px 24px 0px 24px',
  },
  listName: {
    paddingTop: "8px",
    fontSize: "18px",
    color: "rgba(4, 60, 116, 1)",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
  },
  card: {
    borderRadius: "10px",
    height: "436px",
    width: "100%"
  },
  cardMedia: {
    borderRadius: "10px",
    width: "100%",
    height: "100%",
    border: 0,
    marginTop: "-15px"
  },
  updateTitle: {
    color: "rgba(4, 60, 116, 1)",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    fontSize: "20px",
    paddingBottom: "10px"
  },
  updateDetail: {
    color: "rgba(4, 60, 116, 1)",
    fontFamily: "SFProDisplay",
    fontSize: "16px",
    fontWeight: 500
  },
  viewAll: {
    textDecoration: "underline",
    color: "rgb(4, 60, 116)",
    fontSize: "14px",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    cursor: "pointer"
  },
  productTitle: {
    color: "rgba(4, 60, 116, 1)",
    textAlign: "center" as "center",
    height: "70px",
    fontSize: '24px',
    fontFamily: 'SFProDisplay',
  },
  productTitleEven: {
    backgroundColor: "#DCDFF0"
  },
  productTitleOdd: {
    backgroundColor: "#D7E8F9"
  },
  detailLabel: {
    color: "#6a74a5",
    fontSize: '16px',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    margin: '20px 24px 5px 24px',
  },
  detailValueOdd: {
    marginLeft: "5px",
    color: "#459eff",
    fontSize: '16px',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    margin: '0px 0px 0px 0px',
  },
  detailValueEven: {
    marginLeft: "5px",
    color: "#459eff",
    fontSize: '16px',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    margin: '0px 0px 0px 0px',
  },
  cardButton: {
    borderRadius: '21px',
    height: "34px",
    width: '146px',
    fontFamily: 'SFProDisplay',
    fontWeight: 500,
    margin: '56px 104px 0',
    boxShadow: 'none',
  },
  cardButtonEven: {
    background: "#043c74",
    color: "#fff",
    fontSize: '16px',
    borderRadius: "21px",
    fontFamily: 'SFProDisplay',
    fontWeight: 500,
    margin: '30px 0px 25px 0',
    boxShadow: 'none',
  },
  cardButtonOdd: {
    backgroundColor: "#c6e4f7",
    color: "rgba(4, 60, 116, 1)",
    fontSize: '16px',
    borderRadius: "21px",
    margin: '25px 0px 30px 0',
    fontFamily: 'SFProDisplay',
    fontWeight: 500,
    boxShadow: 'none',
  },
};

const MainGrid:any = withStyles({
  root: {
    "& .MuiTypography-h5": {
      fontSize: `26px !important`
    },
    padding: "25px 62px 10px 72px",
    "@media (max-width: 700px)": {
      padding: "25px 30px 10px 30px"
    },
    video: {
      width: "100%",
      height: "100%",
      margin: `0px !important`
    }
  }
})(Container);

const CustomCardContent:any = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0px',
    height: '42%'
  }
})(CardContent);

const CustomCardAction:any = withStyles({
  root: {
    justifyContent: "center",
    padding: '0px'
  }
})(CardActions);

const CustomCard:any = withStyles({
  root: {
    borderRadius: "16px",
    borderColor: "rgba(185, 187, 197, 1)",
    borderWidth: '2px',
    margin: '0px 0px 0px 0px',
    "& .MuiPaper-elevation1": {
      boxShadow: `0px 2px 1px - 1px #dae0f0 ,
      0px 1px 1px 0px #dae0f0 ,
      0px 1px 3px 0px #dae0f0`,
    },
    "& .MuiCardHeader-root": {
      padding: '0px'
    },
    "@media (min-width: 1280px) and (max-width: 1381px)": {
      "& .details": {
        // inlineSize: '60%',
        inlineSize: '63%',
      }
    },
  }
})(Card);
