Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.labelTitle = "New User Sign Up";
exports.SubHeading = "Please share the following details to sign up";

exports.labelPassword = "Create Password";
exports.confirmPass = "Confirm Password";
exports.AccountLabel = "Already have an account ?";
exports.loginText = "Login";
exports.labelFirstName = "First Name";
exports.labelLastName = "Last Name";
exports.labelEmail = "Email ID";
exports.companyName = "Company Name";
exports.country = "Country";
exports.phoneNum = "Phone No";
exports.privacyPolicyLabel = "I agree and accept the";
exports.privacyPolicyText = "Privacy Policy";
exports.termslabelText = "Terms of Use";
exports.termsLabel = "of this website";
exports.passwordError =
  "Pasword Must contains alteast 1 upper case ,1 lower case,1 number , 1 special character";
exports.phoneError = "Please enter valid phone number";
exports.emailError = "Please enter valid email id";
exports.termsConditionError = "Please accept terms and condition";
exports.createCustomerProfileApiEndpoint = "bx_block_customer_profile/customer_profiles";
exports.createCustomerProfileApiMethod = "POST";
exports.welcomeText = "Welcome to BioConnect";
exports.startedText = "Let’s get started!";
exports.serviseText = "Your single window to all our API products, services & capabilities.";
exports.getCompanyListApiEndpoint = "account_block/accounts/company_listing";
exports.getCompantListApiMethod = "GET";
exports.loginImagesAPIEndPoint = "bx_block_landingpage3/landing_pages/auth_banner";
exports.termsPopUpDetailsText = `By registering on this website, you are allowing Biocon Limited to use all information
provided by you, specifically for the purposes of responding to your queries; sending
communications pertaining orders placed by you on Biocon Limited and its group companies;
sharing information about our events and webcasts; and sending updates, security alerts and
administrative messages. Your use of this website and sharing of your personal information
shall at all times be subject to the Privacy Policy and the Terms of Use.`;
exports.termsPopUpDetailsText2 = `Please provide your confirmation by clicking on the Accept button to proceed further with registration process`
exports.AcceptButtonText = `Accept`;
exports.thanksTextAfterSignup = "Thanks for signing up. We are working on your request. You will soon hear from us.";
exports.OkButtonText = "Ok";
exports.countryCodePlaceholder = "Enter Country Code";
exports.phoneNumberPlaceholder = "Enter your phone number";
exports.countryPlaceholder = "Enter Country";
exports.andText = "and";
exports.backBtnText = "Back";
exports.newUserSignOption = "New User Sign Up";
exports.signUpSubHeader = "Please share the following details to sign up";
exports.firstNamePlaceholder = "Enter your first name";
exports.lastnamePlaceholder = "Enter your last name";
exports.emailIdPlaceholder = "Enter your email id";
exports.strongPassPlaceholder = "Create a strong password";
exports.togglePasswordlabel = "toggle password visibility";
exports.confirmPassPlaceholder = "Confirm your password";
exports.passwordValidationtext = "Password must be at least 8 characters,with 1 uppercase, 1 lowercase, and 1 special character";
exports.SignUpBtnText = "Sign Up";
exports.retypePasswordFieldName = "retypePassword";
exports.confirmPassFieldId = "confirmPass";
exports.createPassFieldText = "createPass";
exports.passwordFieldLabel = "password";
exports.emailFieldLabel = "email";
exports.emailFieldID = "emailInput";
exports.lastNameFieldLabel = "lastName";
exports.firstNameFieldID = "firstName";
exports.countryFieldId = "country";
exports.phoneFieldId = "phone";
exports.phoneNumberFieldId = "phoneNumber";
exports.countryCodeFieldID = "countryCode";
exports.phoneNumFieldHTML = "phoneNum";
exports.selectCompanyPlaceholder = "Select Company";
exports.companynameHTML = "companyname";
exports.phoneNoRegex = `/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/`;
exports.emailRegexExp = `/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/</>`;