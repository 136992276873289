import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall, getHeaders, isAuthorisedUser, isLoggedIn, warningNotification } from "../../../components/src/common";

// Customizable Area Start
import { handleUpdateAuthToken } from "../../../components/src/common";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export enum MY_QUERIES_SORT_TYPES {
  SORT_BY_OLDEST = 0,
  SORT_BY_NEWEST = 1,
  SORT_BY_DATE = 2
}

export interface IMyTicketDetail {
  product_name: string;
  product_query_id: number;
  query_type: string;
  status: string;
  status_id: number;
  ticket_creation_date: string;
  ticket_id: number;
  ticket_number: string;
  status_message: string;
  doc_name: [];
}

export interface IQueryListMeta {
  current_count: number;
  current_page: number;
  next_page: number;
  prev_page: number;
  total_count: number;
  total_pages: number;
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  is_loading: boolean;
  filter_key: number;
  sort_key: number;
  queries_list: IMyTicketDetail[];
  total_tickets_count: number;
  selected_tab: number;
  per_page_records: number;
  page_number: number;
  query_list_meta: IQueryListMeta | null;
  tickets_count: number | null;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MyQueriesController extends BlockComponent<Props, S, SS> {
  getQueriesApiCallId: string;
  authToken: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.getQueries = this.getQueries.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.handleSorting = this.handleSorting.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      is_loading: false,
      filter_key: 0,
      sort_key: 1,
      queries_list: [],
      total_tickets_count: 0,
      selected_tab: 0,
      per_page_records: 12,
      page_number: 1,
      query_list_meta: null,
      tickets_count: 0,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const authToken = isLoggedIn(this.props.navigation);
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
      this.getQueries();
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }

    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      isAuthorisedUser(responseJson, this.props.navigation);
      this.setState({is_loading: false});
      if (this.getQueriesApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        if (responseJson && responseJson.data && responseJson.success === true) {
          this.setQueriesData(responseJson);
        } else {
          this.getQueriesDataErrorHandle(responseJson)
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  // const startDate = 

  getQueries() {
    const localStorageDate: any = localStorage.getItem('rangeValue')
    this.setState({
      is_loading: true
    });
    const header = getHeaders({ token: this.authToken });
    const fetchQueries = apiCall({
      url: configJSON.getMyQueriesApiEndpoint + `?sorting=${this.state.sort_key}&filter=${this.state.filter_key}&start_date=${JSON.parse(localStorageDate)?.startDate?.slice(0, 10)}&end_date=${JSON.parse(localStorageDate)?.endDate?.slice(0, 10)}&per_page=${this.state.per_page_records}&page=${this.state.page_number}`,
      header,
      httpBody: {},
      httpMethod: configJSON.getMyQueriesApiMethod
    });

    this.getQueriesApiCallId = fetchQueries.messageId;
    runEngine.sendMessage(fetchQueries.id, fetchQueries);
  }

  setQueriesData = (responseJson: any) => {
    this.setState({
      total_tickets_count: responseJson.total_count,
      queries_list: responseJson.data,
      query_list_meta: responseJson.meta.pagination_details,
      tickets_count: responseJson.meta.pagination_details.total_count,
    })
  }

  resetAuthToken = async () => {
    this.setState({is_loading: true});
      await handleUpdateAuthToken();
      const authToken = isLoggedIn(this.props.navigation);
      if (typeof (authToken) === "string") {
        this.authToken = authToken;
      }
      if(this.authToken){
        this.getQueries();
      }
  }

  setQueriesDataTONull = () => {
    this.setState({
      queries_list: [],
      query_list_meta: null,
      total_tickets_count: 0,
      tickets_count: 0,
    })
  }

  getQueriesDataErrorHandle = (responseJson: any) => {
    if (!responseJson.success && responseJson.data.length === 0) {
      this.setQueriesDataTONull()
    } else {
      warningNotification(configJSON.errorMessage);
    }
    if (responseJson.errors && responseJson.errors[0]?.token === "Token has Expired" && JSON.parse(localStorage.getItem("userInfo") || "")?.attributes?.is_customer === 'True') {
      this.resetAuthToken()
    }
  }

  handleChangeTab = (event: any, newValue: any) => {
    this.setState({
      tickets_count: 0,
      selected_tab: newValue,
      filter_key: newValue,
      page_number: 1
    }, () => {
      this.getQueries();
    })
  };

  handleSorting = (sortEvent: MY_QUERIES_SORT_TYPES, data: any) => {
    this.setState({
      sort_key: sortEvent,
      page_number: 1
    }, () => {
      this.getQueries();
    })
  }

  handlePageChange = (e: any, p: any) => {
    this.setState({
      page_number: p
    }, () => {
      this.getQueries();
    })
  }
  // Customizable Area End
}
