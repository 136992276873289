import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {
  apiCall,
  errorNotification,
  getHeaders,
  getUserdetails,
  handleUpdateAuthToken,
  isAuthorisedUser,
  isLoggedIn,
  IUserdetails,
  logout,
  showApiErrorResponse,
  successNotification,
} from "../../../components/src/common";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  userDetailLabel: string;
  countryCodeLabel: string;
  labelFirstName: string;
  lastnameLabel: string;
  companynameLabel: string;
  emailLabel: string;
  phoneNumLabel: string;
  privacyPolicyLabel: string;
  selectedTab: number;
  addressLabel: string;
  departmentLabel: string;
  designationLabel: string;
  landlinelabel: string;
  businessareaLabel: string;
  affilateNameLabel: string;
  alternateEmailLabel: string;
  departmentList: any[];
  codeList: any[];
  firstName: string;
  lastName: string;
  companyName: string;
  emailId: string;
  phoneNumber: string;
  phoneCountryCode: string;
  agreedTermsAndCondition: boolean;
  resetPasswordBtnText: string,
  address: string;
  department: number;
  designation: string;
  landlineNumber: string;
  areaOfBusiness: string;
  subsidiaryName: string;
  alternateEmail: string;
  isTermsCondition: boolean;
  isLoading: boolean;
  privacyPolicyText: string;
  termslabelText: string;
  termsLabel: string;
  parentCustomerLabel: string;
  selectedSolutation: number;
  allSalutations: any[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  updateAccountApiCallId: any;
  sendResetPasswordEmailAPICallId: any;
  updateCustomerProfileApiCallId: any;
  getCustomerProfileApiCallId: any;
  getDepartmentListApiCallId: any;
  getAllSolutationsApiCallId: any;
  userDetails: IUserdetails;
  authToken: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      userDetailLabel: configJSON.userDetailLabel,
      countryCodeLabel: configJSON.countryCodeLabel,
      labelFirstName: configJSON.labelFirstName,
      lastnameLabel: configJSON.lastnameLabel,
      companynameLabel: configJSON.companynameLabel,
      emailLabel: configJSON.emailLabel,
      phoneNumLabel: configJSON.phoneNumLabel,
      privacyPolicyLabel: configJSON.privacyPolicyLabel,
      privacyPolicyText: configJSON.privacyPolicyText,
      termslabelText: configJSON.termslabelText,
      termsLabel: configJSON.termsLabel,
      resetPasswordBtnText: configJSON.resetPasswordBtnText,
      selectedTab: 0,
      addressLabel: configJSON.addressLabel,
      departmentLabel: configJSON.departmentLabel,
      designationLabel: configJSON.designationLabel,
      landlinelabel: configJSON.landlinelabel,
      businessareaLabel: configJSON.businessareaLabel,
      affilateNameLabel: configJSON.affilateNameLabel,
      alternateEmailLabel: configJSON.alternateEmailLabel,
      departmentList: [],
      codeList: [],
      firstName: "",
      lastName: "",
      companyName: "",
      emailId: "",
      phoneCountryCode: "91",
      phoneNumber: "",
      agreedTermsAndCondition: true,
      address: "",
      department: 0,
      designation: "",
      landlineNumber: "",
      areaOfBusiness: "",
      subsidiaryName: "",
      alternateEmail: "",
      isTermsCondition: true,
      isLoading: false,
      parentCustomerLabel: "Salutation",
      selectedSolutation: 0,
      allSalutations: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.onPersonalDetailsSave = this.onPersonalDetailsSave.bind(this);
    this.onAdditionalDetailsSave = this.onAdditionalDetailsSave.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    this.handlePhoneCountryCode = this.handlePhoneCountryCode.bind(this);
    // this.handleTermsConditionClick = this.handleTermsConditionClick.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        isAuthorisedUser(responseJson, this.props.navigation);
        this.setState({
          isLoading: false
        })
        if (apiRequestCallId === this.getCustomerProfileApiCallId) {
          if (!responseJson.error && responseJson.data) {
            const profileData = responseJson.data.attributes;
            this.setState({
              firstName: profileData.first_name || "",
              lastName: profileData.last_name,
              companyName: profileData.company_name || "",
              emailId: profileData.email,
              phoneNumber: profileData.phone_number,
              phoneCountryCode: String(profileData.country_code),
              address: profileData.address || "",
              designation: profileData.designation || "",
              landlineNumber: profileData.landline || "",
              areaOfBusiness: profileData.area_of_business || "",
              subsidiaryName: profileData.subsidiary_name,
              alternateEmail: profileData.alternate_email,
              department: profileData.department_id || 0
            });
          }  else {
            if (responseJson.errors && responseJson.errors[0]?.token === "Token has Expired" && JSON.parse(localStorage.getItem("userInfo") || "")?.attributes?.is_customer === 'True') {
              this.setState({
                isLoading: true
              })
              await handleUpdateAuthToken()
              const authToken = isLoggedIn(this.props.navigation);
              if (typeof (authToken) === "string") {
                this.authToken = authToken;
              } 
              if (this.authToken){
                this.getProfileDetails();
              }
            }
          }
          if (responseJson.message === "Profile not found.") {
            logout(this.props.navigation);
          }
        }

        if (apiRequestCallId === this.getDepartmentListApiCallId) {
          if (!responseJson.errors) {
            this.setState({ departmentList: responseJson.department });
          }
          else {
            if (responseJson.errors && responseJson.errors[0]?.token === "Token has Expired" && JSON.parse(localStorage.getItem("userInfo") || "")?.attributes?.is_customer === 'True') {
              this.setState({
                isLoading: true
              })
              await handleUpdateAuthToken()
              const authToken = isLoggedIn(this.props.navigation);
              if (typeof (authToken) === "string") {
                this.authToken = authToken;
              } 
              if (this.authToken){
                this.getDepartmentList();
              }
            }
          }
        }

        if (apiRequestCallId === this.updateAccountApiCallId) {
          //     /* START --------------------------------------------------------------  Update account Api Call Response Handled*/
          if (!responseJson.errors) {
            successNotification("Personal Details Updated Successfully");
            this.setState({
              selectedTab: 1,
            });
          } else {
            if (responseJson.errors && responseJson.errors[0]?.token === "Token has Expired" && JSON.parse(localStorage.getItem("userInfo") || "")?.attributes?.is_customer === 'True') {
              this.setState({
                isLoading: true
              })
              await handleUpdateAuthToken()
              const authToken = isLoggedIn(this.props.navigation);
              if (typeof (authToken) === "string") {
                this.authToken = authToken;
              } 
              if (this.authToken){
                this.onPersonalDetailsSave();
              }
            }
            errorNotification(responseJson.error);
          }

          this.parseApiCatchErrorResponse(errorReponse);
          //   /* END --------------------------------------------------------------  Update account Api Call Response Handled*/
        }

        if (apiRequestCallId === this.sendResetPasswordEmailAPICallId) {
          if (!responseJson.errors) {
              successNotification(responseJson.message)
              this.setState({isLoading: false})
          } else {
              errorNotification(responseJson?.errors[0])
              this.setState({isLoading: false})
          }
      }

        if (apiRequestCallId === this.updateCustomerProfileApiCallId) {
          //     /* START --------------------------------------------------------------  Update account Api Call Response Handled*/
          if (!responseJson.errors) {
            successNotification("Additional Details Updated Successfully");
            this.setState({
              selectedTab: 0,
            });
          } else {
            if (responseJson.errors && responseJson.errors[0]?.token === "Token has Expired" && JSON.parse(localStorage.getItem("userInfo") || "")?.attributes?.is_customer === 'True') {
              this.setState({
                isLoading: true
              })
              await handleUpdateAuthToken()
              const authToken = isLoggedIn(this.props.navigation);
              if (typeof (authToken) === "string") {
                this.authToken = authToken;
              } 
              if (this.authToken){
                this.onAdditionalDetailsSave();
              }
            }
            showApiErrorResponse(responseJson);
          }
          this.parseApiCatchErrorResponse(errorReponse);
          //   /* END --------------------------------------------------------------  Update account Api Call Response Handled*/
        }

        if (apiRequestCallId === this.getAllSolutationsApiCallId) {
          if (!responseJson.error && !responseJson.errors) {
              this.setState({
                  allSalutations: responseJson.all_salutation
              })
          }
      }
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    const authToken = isLoggedIn(this.props.navigation);
    if (typeof authToken === "string") {
      this.authToken = authToken;
      const userDetailToken = getUserdetails();
      this.getDepartmentList();
      if (userDetailToken) {
        this.userDetails = userDetailToken;
        this.getProfileDetails();
        this.getAllSalutations();
      }
    }
  }
  handleChangeTab = (event: any, newValue: any) => {
    this.setState({ selectedTab: newValue });
  };

  sendResetPasswordLink = () => {
    this.setState({ isLoading: true })
    const CustomerId: any = this?.userDetails?.id;
    const allInvoices = apiCall({
        header: getHeaders({ token: this.authToken }),
        url: configJSON.sendResetPasswordEmailEndPoint,
        httpBody: {
           "account_id": CustomerId
        },
        httpMethod: configJSON.updateCustomerProfileApiMethod
    });
    this.sendResetPasswordEmailAPICallId = allInvoices.messageId;
    runEngine.sendMessage(allInvoices.id, allInvoices);
}


  handleChange = (prop: any) => (event: any) => {
    if (prop === 'firstName' || prop === 'lastName') {
      var regEx = /^[a-zA-Z][a-z\s]*$/;
      if (event.target.value.length === 0) {
        this.setState({ ...this.state, [prop]: event.target.value });
        return true;
      }
      if (event.target.value.match(regEx)) {
        this.setState({ ...this.state, [prop]: event.target.value });
        return true;
      }
      else {
        return false;
      }
    } else if (prop === 'phoneNumber' || prop === "landlineNumber") {
      var regEx2 = /^[0-9\b]+$/;
      if (event.target.value.length === 0) {
        this.setState({ ...this.state, [prop]: event.target.value });
        return true;
      }
      if (event.target.value.match(regEx2)) {
        this.setState({ ...this.state, [prop]: event.target.value });
        return true;
      } else {
        return false;
      }
    }
    this.setState({ ...this.state, [prop]: event.target.value });
  };

  onPersonalDetailsSave = () => {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.emailId)
    ) {
      errorNotification("All fields required");
      return false;
    }

    if (!this.state.isTermsCondition) {
      errorNotification(configJSON.termsConditionError);
      return false;
    }

    const header = getHeaders({ token: this.authToken });
    const body = {
      data: {
        attributes: {
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          email: this.state.emailId,
          phone_number: `${this.state.phoneNumber}`,
          country_code: `${this.state.phoneCountryCode}`,
        },
        type: "email_account",
      },
    };

    const updateAccount = apiCall({
      httpBody: body,
      header: header,
      url: configJSON.updateAccountApiEndpoint + this.userDetails.id,
      httpMethod: configJSON.updateAccountApiMethod,
    });

    this.updateAccountApiCallId = updateAccount.messageId;
    runEngine.sendMessage(updateAccount.id, updateAccount);
    this.setState({
      isLoading: true
    })

    return true;
  };

  onAdditionalDetailsSave = () => {
    //TODO: Validation
    const authToken = localStorage.getItem("authToken") || "";
    const header = getHeaders({ token: authToken });
    if (this.state.alternateEmail !== null) {
      if (this.state.alternateEmail.trim().length > 0 && !String(this.state.alternateEmail)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )) {
        errorNotification(configJSON.emailError);
        return false;
      }
    }

    const body = {
      address: this.state.address,
      department_id: this.state.department,
      designation: this.state.designation,
      landline: this.state.landlineNumber,
      area_of_business: this.state.areaOfBusiness,
      subsidiary_name: this.state.subsidiaryName,
      alternate_email: this.state.alternateEmail,
    };

    const updateCustomerProfile = apiCall({
      httpBody: body,
      header: header,
      url: configJSON.updateCustomerProfileApiEndpoint,
      httpMethod: configJSON.updateCustomerProfileApiMethod,
    });

    this.updateCustomerProfileApiCallId = updateCustomerProfile.messageId;
    runEngine.sendMessage(updateCustomerProfile.id, updateCustomerProfile);
    this.setState({
      isLoading: true
    })
  };

  getProfileDetails = () => {
    const header = getHeaders({ token: this.authToken });
    const getAccount = apiCall({
      httpBody: {},
      header: header,
      url:
        configJSON.getCustomerProfileApiEndpoint +
        this?.userDetails?.attributes?.customer_profile_id,
      httpMethod: configJSON.getCustomerProfileApiMethod,
    });

    this.getCustomerProfileApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
    this.setState({
      isLoading: true
    })
  };

  getDepartmentList = () => {
    const header = getHeaders({ token: this.authToken });

    const getDepartments = apiCall({
      httpBody: {},
      header: header,
      url: configJSON.getDepartsmentListApiEndpoint,
      httpMethod: configJSON.getDepartmentListApiMethod,
    });

    this.getDepartmentListApiCallId = getDepartments.messageId;
    runEngine.sendMessage(getDepartments.id, getDepartments);
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.trim().length === 0;
  }

  handlePhoneCountryCode(event: any, value: any) {
    if (value) {
      this.setState({
        phoneCountryCode: event.value,
      });
    }
  }

  handleTermsConditionClick = () => {
    this.setState({
      isTermsCondition: !this.state.isTermsCondition,
    });
  };

  handleDepartmentChange = (event: any, value: any) => {
    this.setState({
      department: value.props.value,
    });
  };

  handleSolutationChange = (event: any, value: any) => {
    this.setState({
        selectedSolutation: value.props.value
    })
  }

  getAllSalutations = () => {
    const allSalutations = apiCall({
        httpBody: {},
        header: getHeaders({ token: this.authToken }),
        url: configJSON.getAllSalutationsApiEndpoint,
        httpMethod: configJSON.getCustomerProfileApiMethod,
    });

    this.getAllSolutationsApiCallId = allSalutations.messageId;
    runEngine.sendMessage(allSalutations.id, allSalutations);
}
  // Customizable Area End
}
