Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "DynamicContent";
exports.labelBodyText = "DynamicContent Body";

exports.queryLabel = "Raise a Request";
exports.labelName = "Name";
exports.companyName = "Company";
exports.dateLabel = "Date";
exports.typeLabel = "Query Type";
exports.sampleLabel = "Query Type";
exports.productlabel = "Product";
exports.documentNameLabel = "Document Name";
exports.endmarketLabel = "End Markets";
exports.additionalEmailLabel = "Additional Emails";
exports.termsText = "* I agree and accept the Privacy Policy and terms of Use of this website.";
exports.apiRequestlabel = "Details of API Available";
exports.apiQuantityLabel = "API Quantity";
exports.apiUOMLabel = "API UOM";
exports.batchReqLabel = "API Batch Requirement";
exports.customerSpecificationLabel = "Customer specification / PSD";
exports.pharmaRefLabel = "Pharmacopeia Reference / Grade";
exports.standardQuantityLabel = "Working Standard Quantity";
  exports.standardUMO = " Working Standard UOM";
  exports.impurityQuantityLabel = "Impurity Quantity";
  exports.impurityUMOLabel = "Impurity UOM";
  exports.labQuantityLabel = "Lab Standards Quantity";
  exports.labUMOLabel = "Lab Standard UOM";
  exports.billToAddressLabel = "Bill To Address";
  exports.ConsigneeAddressLabel = "Consignee Address";
  exports.commentLabel = "Comments";
  exports.firstNameLabel = "First Name";
  exports.lastNamelabel = "Last Name";
  exports.companyNameLabel = "Company Name";
  exports.EmailIdLabel = "Email ID";
  exports.countrylabel = "Country";
  exports.phoneNumLabel = "Phone No";
  exports.preShippementLabel = "Pre-Shipment COA";
  exports.statuslabel = "Status";

  exports.btnExampleTitle = "CLICK ME";
exports.getCustomerProfileApiEndpoint = "bx_block_customer_profile/customer_profiles/";
exports.getCustomerProfileApiMethod = "GET";
exports.getDepartsmentListApiEndpoint = "bx_block_customer_profile/customer_profiles/get_department";
exports.getDepartmentListApiMethod = "GET";
exports.createProductQueryApiEndpoint = "bx_block_catalogue/product_queries";
exports.createProductQueryApiMethod = "POST";
exports.createSampleRequestApiEndpoint = "bx_block_catalogue/sample_requests";
exports.createSampleRequestApiMethod = "POST";
exports.createSetMeetingApiEndpoint = "bx_block_catalogue/meeting_requests";
exports.createSetMeetingApiMethod = "POST";
exports.getProductDetailApiEndPoint = "catalogue/catalogues/";
exports.getProductDetailApiMethod = "GET";
exports.emailError = "Please enter valid email";
exports.termsConditionError = "Please accept terms and condition";
exports.privacyPolicyLabel = "I agree and accept the";
exports.privacyPolicyText = "Privacy Policy";
exports.termslabelText = "Terms of Use";
exports.termsLabel = "of this website";
exports.getDropdownsListApiEndpoint = "/bx_block_catalogue/catalogues/query_listing";
exports.getDropdownsListApiMethod = "GET";
exports.endMarketError = "End market is required";
exports.documentError = "Document is required";
exports.commentError = "Comments are required";

exports.createConnectWithUsApiEndpoint = "/bx_block_customer_profile/customer_profiles/connect_with_us";
exports.createConnectWithUsApiMethod = "POST";
exports.getProductDocumentsApiEndpoint = "bx_block_catalogue/catalogues/document_folders";
exports.getProductDocumentsApiMethod = "GET";
exports.customerSpecificationError = "Customer Specifications / PSD is required";
exports.pharmacopieaReferenceError = "Pharmacopeia Reference / Grade is required";
exports.queryType = "Please Select Query Type";
exports.impurityQuantity = "Please Enter Impurity Quantity";
exports.batchRequirementError = "Batch Requirement is required";
exports.apiUomError = "API UOM is required";
exports.apiQuantityError = "API Quantity is required";
exports.billAddressError = "Bill to Address is required";
exports.consigneeAddressError = "Consignee Address is required";
exports.preShipmentError = "Pre-shipment COA is required";
exports.getCategoriesApiEndPoint = "bx_block_catalogue/catalogues/listing";
exports.getCategoriesApiType = "GET";
exports.productError = "Product selection is mandatory";
exports.phoneError = "Please enter valid phone number";
exports.otherProductsError = "Please Enter Other Product";
exports.isRobotError = "Please accept I'am not a robot";
exports.product_idError = "Product is required.";
exports.productListApiEndPoint = "account_block/customer_masters/catalogue_listing";
exports.isRobotErrorMsg = "Please accept I'am not a robot";
exports.phoneCountryCodeError = "Country Code is required."
exports.documentUploadMessage = "Please use the comment box to record document in case it is not available in the dropdown"
exports.emailErrorMessage = "Please enter valid email";
exports.multipleEmail = "You can add multiple email addresses add";
exports.space = "comma or space.";
exports.example = "Ex: user1@domain.com, user2@domain.com";
// Customizable Area End