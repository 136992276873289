import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall, authorizeEmployee, getHeaders, isAuthorisedUser, isLoggedIn, warningNotification } from "../../../components/src/common";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

export interface ITicketListMeta {
    pagination_details: {
        current_count: number;
        current_page: number;
        next_page: number;
        prev_page: null | number;
        total_count: number;
        total_pages: number;
    };
}

export interface ITicketData {
    id: string;
    type: string;
    attributes: {
        customer_name: string;
        doc_type: [];
        product_description: string;
        product_name: any[];
        query_detail: string;
        query_type: string;
        status: string;
        sub_type: string;
        submited_by: string;
        ticket_number: string;
    }
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    selectedTab: number;
    ticketId: any;
    productName: string;
    customerName: string;
    headCells: any[];
    isAsc: boolean;
    orderBy: string;
    order: any;
    rows: any[];
    TicketListingMeta: null | ITicketListMeta;
    arrayHolderDuplicate: any[];
    count: number;
    page: number;
    token: string;
    ticketList: ITicketData[];
    paginationData: any;
    isSearch: boolean;
    searchTerm: string;
    isLoading: boolean;
    overdue_ticket_count: number;
    transfered_tickets_count: number;
    isFilteredTicketListing: boolean;
    csvData: string;
    csvFileName: string;
    filterMenu: any,
    filterData: any,
    filterStatusMenu: any,
    filterStatusData: any,
    selectedQueryType: string[],
    selectedStatus: string,
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AdminConsole3Controller extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    authToken: string;
    getFilterAndSearchProductsApiCallId: any;
    getProductApiCallId: any;
    perPageRecords: number = 9;
    searchProductApiCallId: any;
    getTicketCountApiCallId: any;
    downloadAssignedToMeTicketsApiCallId: string;
    downloadTransferredTicketsApiCallId: string;
    csvRef: any;
    getTransferredTicketsFilterAndSearchApiCallId: string;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.handleFilterAndSearch = this.handleFilterAndSearch.bind(this);
        this.handleFilterAndSearchData = this.handleFilterAndSearchData.bind(this);
        this.handleticketData = this.handleticketData.bind(this);
        this.csvRef = React.createRef();

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            token: "",
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            selectedTab: 0,
            ticketId: '',
            productName: '',
            customerName: '',
            count: 0,
            page: 1,
            ticketList: [],
            isSearch: false,
            searchTerm: '',
            headCells: [
                {
                    id: 'ticket_number',
                    numeric: 'left',
                    disablePadding: true,
                    isSorting: false,
                    isFiltering: false,
                    label: 'Ticket ID',
                },
                {
                    id: 'product_name',
                    numeric: 'left',
                    disablePadding: false,
                    isSorting: false,
                    isFiltering: false,
                    label: 'Product Name',
                },
                {
                    id: 'query_type',
                    numeric: 'left',
                    disablePadding: false,
                    isSorting: false,
                    isFiltering: true,
                    label: 'Query Type',
                },
                {
                    id: 'status',
                    numeric: 'center',
                    disablePadding: false,
                    isSorting: false,
                    isFiltering: true,
                    label: 'Status',
                },
                {
                    id: 'customer_name',
                    numeric: 'left',
                    disablePadding: false,
                    isSorting: false,
                    isFiltering: false,
                    label: 'Contact Name',
                },
                {
                    id: 'viewDetails',
                    numeric: 'center',
                    disablePadding: false,
                    isSorting: false,
                    isFiltering: false,
                    label: 'View Details',
                },
            ],
            rows: [],
            arrayHolderDuplicate: [],
            orderBy: '',
            order: 'asc',
            isAsc: false,
            TicketListingMeta: null,
            paginationData: [],
            isLoading: false,
            overdue_ticket_count: 0,
            transfered_tickets_count: 0,
            isFilteredTicketListing: false,
            csvData: '',
            csvFileName: '',
            filterMenu: null,
            filterData: [{}],
            filterStatusMenu: null,
            filterStatusData: [{}],
            selectedQueryType: [],
            selectedStatus: '',
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async componentDidMount() {
        super.componentDidMount();
        let authtoken = isLoggedIn(this.props.navigation);
        authorizeEmployee(this.props.navigation)
        if (typeof (authtoken) === "string") {
            this.authToken = authtoken;
            this.handleticketData(authtoken)
            this.sliceData();
            // this.getTicketCount(authtoken);
            this.setState({
                arrayHolderDuplicate: this.state.rows,
            });
        }

    }

    sliceData = () => {
        this.setState({
            count: this.state.rows.length,
            paginationData: this.state.rows.slice(0, 6),
        });
    };

    getToken = () => {
        const msg: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(msg);
    };

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let token = message.getData(getName(MessageEnum.SessionResponseToken));
            this.setState({ token: token });
            this.handleticketData(token);
        }

        if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
            let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

            this.showAlert(
                "Change Value",
                "From: " + this.state.txtSavedValue + " To: " + value
            );

            this.setState({ txtSavedValue: value });
        }


        var responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            isAuthorisedUser(responseJson, this.props.navigation);
            if (this.getProductApiCallId != null && this.getProductApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
                if (responseJson && !responseJson.errors && responseJson.success) {
                    this.setState({
                        rows: responseJson.data,
                        ticketList: responseJson.result.data,
                        TicketListingMeta: responseJson.result.meta,
                    });
                    this.setState({
                        overdue_ticket_count: responseJson.total_overdue_count,
                    })
                    runEngine.debugLog("arrayHolder", this.state.rows);
                }
                this.setState({isLoading: false});
            }
            if (
                this.getFilterAndSearchProductsApiCallId != null &&
                this.getFilterAndSearchProductsApiCallId ===
                message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            ) {
                if (responseJson && !responseJson.errors && responseJson.success) {
                    this.setState({
                        rows: responseJson.data,
                        ticketList: responseJson.result.data,
                        TicketListingMeta: responseJson.result.meta,
                    });
                } else {
                    this.setState({
                        ticketList: [],
                        TicketListingMeta: null
                    })
                }

                this.setState({
                    isLoading: false
                });
            }

            if (
                this.getTransferredTicketsFilterAndSearchApiCallId != null &&
                this.getTransferredTicketsFilterAndSearchApiCallId ===
                message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            ) {
                if (responseJson && !responseJson.errors && responseJson.success) {
                    this.setState({
                        rows: responseJson.data,
                        ticketList: responseJson.result.data,
                        TicketListingMeta: responseJson.result.meta,
                    })
                } else {
                    this.setState({
                        ticketList: [],
                        TicketListingMeta: null
                    })
                }

                this.setState({
                    isLoading: false
                });
            }

            if (this.downloadAssignedToMeTicketsApiCallId !== null &&
                this.downloadAssignedToMeTicketsApiCallId ===
                message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            ) {
                if (responseJson.success && responseJson.result) {
                    this.setState({
                        csvData: responseJson.result,
                        csvFileName: responseJson.filename
                    }, () => {
                        this.csvRef.current.link.click()
                    })
                } else {
                    warningNotification("Something went wrong");
                }
                this.setState({
                    isLoading: false
                });
            }
        }

        if (this.downloadTransferredTicketsApiCallId !== null &&
            this.downloadTransferredTicketsApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            if (responseJson.success && responseJson.result) {
                this.setState({
                    csvData: responseJson.result,
                    csvFileName: responseJson.filename
                }, () => {
                    this.csvRef.current.link.click();
                })
            } else {
                warningNotification("Something went wrong");
            }

            this.setState({
                isLoading: false
            })
        }

        if (this.getTicketCountApiCallId != null &&
            this.getTicketCountApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            if (responseJson) {
                this.setState({
                    // overdue_ticket_count: responseJson.overdue_ticket_count,
                    transfered_tickets_count: responseJson.transfered_tickets_count
                })
            }
        }



        // Customizable Area Start
        // Customizable Area End

    }

    txtInputWebProps = {
        onChangeText: (text: string) => {
            this.setState({ txtInputValue: text });
        },
        secureTextEntry: false
    };

    txtInputMobileProps = {
        ...this.txtInputWebProps,
        autoCompleteType: "email",
        keyboardType: "email-address"
    };

    txtInputProps = this.isPlatformWeb()
        ? this.txtInputWebProps
        : this.txtInputMobileProps;

    btnShowHideProps = {
        onPress: () => {
            this.setState({ enableField: !this.state.enableField });
            this.txtInputProps.secureTextEntry = !this.state.enableField;
            this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
                ? imgPasswordVisible
                : imgPasswordInVisible;
        }
    };

    btnShowHideImageProps = {
        source: this.txtInputProps.secureTextEntry
            ? imgPasswordVisible
            : imgPasswordInVisible
    };

    btnExampleProps = {
        onPress: () => this.doButtonPressed()
    };

    doButtonPressed() {
        let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
        msg.addData(
            getName(MessageEnum.AuthTokenDataMessage),
            this.state.txtInputValue
        );
        this.send(msg);
    }

    // web events
    setInputValue = (text: string) => {
        this.setState({ txtInputValue: text });
    }

    setEnableField = () => {
        this.setState({ enableField: !this.state.enableField });
    }

    handleChangeTab = (event: any, newValue: any) => {
        let bool;
        if (newValue === 1) {
            bool = true
        } else {
            bool = false
        }

        this.setState({
            isFilteredTicketListing: bool,
            page: 1,
            ticketId: "",
            productName: "",
            customerName: ""
        }, () => {
            if (newValue === 1) {
                this.getTransferredTicketsListing();
            } else {
                this.handleticketData(this.authToken)
            }
        });

        this.setState({ selectedTab: newValue });
    };

    stableSort(property: string) {
        this.setState({ orderBy: property });
        let sorted = []
        if (this.state.order === 'asc') {
            this.setState({ order: 'desc' });
            sorted = this.state.ticketList.sort((a: any, b: any) => b.attributes[property] > a.attributes[property] ? 1 : -1);
        } else {
            this.setState({ order: 'asc' });
            sorted = this.state.ticketList.sort((a: any, b: any) => a.attributes[property] < b.attributes[property] ? -1 : 1);
        }
        this.setState({ ticketList: sorted });
    }

    getComparator(order: string, orderBy: any) {
        return order === 'desc'
            ? (a: any, b: any) => this.descendingComparator(a, b, orderBy)
            : (a: any, b: any) => -this.descendingComparator(a, b, orderBy);
    }

    descendingComparator(a: { [x: string]: number; }, b: { [x: string]: number; }, orderBy: string | number) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    handlePageChange = (e: any, p: any) => {
        this.setState({
            page: p,
        }, () => {
            if (this.state.isFilteredTicketListing) {
                this.handleTransferredTicketsFilterAndSearch();
            } else {
                this.handleFilterAndSearchData(this.state.selectedQueryType, this.state.selectedStatus);
                (this.state.ticketId !== "" || this.state.productName !== "" || this.state.customerName !== "") && this.handleFilterAndSearch();
            }

        });
    };

    handleticketData = (token: any, pageNumber: number = 1) => {
        this.setState({
            isLoading: true
        })
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getProductApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getOverdueTicketsAPIEndPoint +
            `?per_page=${this.perPageRecords}&page=${this.state.page}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getTicketCount = (token: any,) => {
        this.setState({
            isLoading: true
        })
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getTicketCountApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.ticketCountAPIEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    handleChange = (prop: any) => (event: any) => {
        const value = event.target.value;
        let customerId = '';
        let productName = '';
        let industry = '';
        const duplicateCode = () => {
            if (this.state.isFilteredTicketListing) {
                this.handleTransferredTicketsFilterAndSearch();
            } else {
                this.handleFilterAndSearchData(this.state.selectedQueryType, this.state.selectedStatus);
                if (this.state.ticketId !== "" || this.state.productName !== "" || this.state.customerName !== "") {
                    this.handleFilterAndSearch();
                    this.handleAllClearCheckBox();
                    this.handleAllClearStatus();
                }
            }
        }
        if (prop === "ticketId" && value && value.length > 0) {
            customerId = value;
        } else if (prop === "productName" && value && value.length > 0) {
            productName = value;
        } else if (prop === "customerName" && value && value.length > 0) {
            industry = value;
        }
        if (value && value.length > 0) {
            this.setState({
                ...this.state,
                ticketId: customerId,
                productName: productName,
                customerName: industry,
                searchTerm: value,
                page: 1
            }, duplicateCode)
        } else {
            this.setState({
                ...this.state,
                [prop]: "",
                searchTerm: "",
                page: 1
            }, duplicateCode)
        }
    };

    handleFilterAndSearch = (p: number = 1) => {
        this.setState({isLoading: true})

        const header = getHeaders({ token: this.authToken });
        let url = configJSON.overdueTicketSearchAPIEndPoint + `?per_page=${this.perPageRecords}&page=${this.state.page}&query=${this.state.ticketId}&query_one=${this.state.productName}&query_two=${this.state.customerName}`;

        const filterAndSearchApi = apiCall({
            url: url,
            header,
            httpBody: {},
            httpMethod: configJSON.validationApiMethodType
        });

        this.getFilterAndSearchProductsApiCallId = filterAndSearchApi.messageId;
        runEngine.sendMessage(filterAndSearchApi.id, filterAndSearchApi);

    }

    handleFilterAndSearchData = (queryTypeVal: any, statusVal: any) => {
        this.setState({
            isLoading: true
        })
        let seletedQueries;
        if (queryTypeVal.length === 0) {
           seletedQueries = ''
        } else if (queryTypeVal.length === 1) {
           seletedQueries = queryTypeVal[0]
        } else {
            seletedQueries = `['${queryTypeVal.join("' , '")}']`
        }
        const header = getHeaders({ token: this.authToken });
        let url = configJSON.getOverdueTicketsAPIEndPoint + `?per_page=${this.perPageRecords}&page=${this.state.page}&query=${seletedQueries}&status=${statusVal}`;

        const filterAndSearchApi = apiCall({
            url: url,
            header,
            httpBody: {},
            httpMethod: configJSON.validationApiMethodType
        });

        this.getFilterAndSearchProductsApiCallId = filterAndSearchApi.messageId;
        runEngine.sendMessage(filterAndSearchApi.id, filterAndSearchApi);

    }

    handleTransferredTicketsFilterAndSearch = () => {
        this.setState({isLoading: true});
        let seletedQueries;
        if (this.state.ticketId.length === 0) {
           seletedQueries = ''
        } else if (this.state.ticketId.length === 1) {
           seletedQueries = this.state.ticketId[0]
        } else {
            seletedQueries = `['${this.state.ticketId.join("' , '")}']`
        }

        const transferredTicketsSearch = apiCall({
            header: getHeaders({ token: this.authToken }),
            httpMethod: configJSON.getTransferredTicketsSearchApiMethod,
            httpBody: {},
            url: configJSON.getTransferredTicketsSearchEndpoint + `?per_page=${this.perPageRecords}&page=${this.state.page}&query=${seletedQueries}&query_one=${this.state.productName}&query_two=${this.state.customerName}`
        });

        this.getTransferredTicketsFilterAndSearchApiCallId = transferredTicketsSearch.messageId;
        runEngine.sendMessage(transferredTicketsSearch.id, transferredTicketsSearch);
    }


    // Customizable Area Start
    handleTicketListClick = (id: string) => {
        this.setState({
            isLoading: true
        })
        if (this.props.navigation) {
            this.props.navigation.navigate("DataStorage", { id })
        }
    }

    getTransferredTicketsListing = () => {
        this.setState({
            isLoading: true
        })
        const header = getHeaders({ token: this.authToken });
        let url = configJSON.getTrasnferredTicketListingApiEndpoint + `?per_page=${this.perPageRecords}&page=${this.state.page}`;

        const transferredTickets = apiCall({
            url: url,
            header,
            httpBody: {},
            httpMethod: configJSON.getTrasnferredTicketListingApiMethod
        });

        this.getFilterAndSearchProductsApiCallId = transferredTickets.messageId;
        runEngine.sendMessage(transferredTickets.id, transferredTickets);
    }

    downloadAssignedToMeTickets = () => {
        this.setState({
            isLoading: true
        });

        const downloadAssignedToMe = apiCall({
            url: configJSON.downloadOverdueTicketsApiEndpoint,
            header: getHeaders({ token: this.authToken }),
            httpBody: {},
            httpMethod: configJSON.downloadOverdueTicketsApiMethod
        });

        this.downloadAssignedToMeTicketsApiCallId = downloadAssignedToMe.messageId;
        runEngine.sendMessage(downloadAssignedToMe.id, downloadAssignedToMe);
    }

    downloadTransferredTickets = () => {
        this.setState({
            isLoading: true
        });

        const downloadTransferredTickets = apiCall({
            url: configJSON.downloadTransferredTicketsApiEndPoint,
            header: getHeaders({ token: this.authToken }),
            httpBody: {},
            httpMethod: configJSON.downloadTransferredTicketsApiMethod
        });

        this.downloadTransferredTicketsApiCallId = downloadTransferredTickets.messageId;
        runEngine.sendMessage(downloadTransferredTickets.id, downloadTransferredTickets);
    }

    downloadTickets = () => {
        this.downloadAssignedToMeTickets();
    }

    filterOptions = (event: any, val: any) => {
        val === "Query Type" && this.setState({ filterData: [{ id: 1, label: "Signup Request", value: "Signup Request" }, { id: 2, label: "Product Query", value: "Product Query" }, { id: 3, label: "Sample Request", value: "Sample Request" }, { id: 4, label: "Set Meeting", value: "Set Meeting" }] })
        // val === "Status" && this.setState({ filterData: [{ id: 1, label: "Open", value: "Open" }, { id: 2, label: "In Progress", value: "In Progress" }, { id: 3, label: "Closed", value: "Closed" }] })
        this.setState({ filterMenu: event?.currentTarget })
    }

    filterStatusOptions = (event: any, val: any) => {
        // val === "Query Type" && this.setState({ filterData: [{ id: 1, label: "Signup Request", value: "Signup Request" }, { id: 2, label: "Product Query", value: "Product Query" }, { id: 3, label: "Sample Request", value: "Sample Request" }, { id: 4, label: "Set Meeting", value: "Set Meeting" }] })
        val === "Status" && this.setState({ filterStatusData: [{ id: 1, label: "Open", value: "Open" }, { id: 2, label: "In Progress", value: "In Progress" }, { id: 3, label: "Closed", value: "Closed" }] })
        this.setState({ filterStatusMenu: event?.currentTarget })
    }

    // handleClose = () => {
    //   let val = this.state.selectedQueryType[this.state.selectedQueryType.length - 1]
    //   // this.setState({ selectedQueryType: val })
    //   this.setState({ filterMenu: null })
    //   typeof (val) === "string" && this.handleFilterAndSearchData(val, this.state.selectedStatus)
    // }

    handleStausClose = (event: any) => {
        this.setState({ filterStatusMenu: null, selectedStatus: event.target.id, ticketId: "", productName: "", customerName: "" })
        if (event.target.id === "Open" || event.target.id === "In Progress" || event.target.id === "Closed") {
            this.handleFilterAndSearchData(this.state.selectedQueryType, event.target.id)
        }
    }

    handleCheckBoxChange = (event: any) => {
        let selectedOptions = [...this.state.selectedQueryType];
        if (event.target.checked)
            selectedOptions.push(event.target.value);
        else
            selectedOptions.splice(selectedOptions.indexOf(event.target.value), 1);
        this.setState({ selectedQueryType: selectedOptions, ticketId: "", productName: "", customerName: "" })
    }

    handleAllClearCheckBox = () => {
        this.setState({ selectedQueryType: [] })
    }

    handleCheckboxQuerySave = () => {
        this.handleFilterAndSearchData(this.state.selectedQueryType, this.state.selectedStatus)
        this.setState({ filterMenu: null })
    }

    handleCancleQuerytypeFilter = () => {
        this.setState({ filterMenu: null })
    }

    handleAllClearStatus = () => {
        this.setState({ selectedStatus: '', filterStatusMenu: null })
        if (this.state.selectedStatus !== '') {
            this.handleFilterAndSearchData(this.state.selectedQueryType, '')
        }
    }

    handleMenuClose = () => {
        this.setState({ filterMenu: null, filterStatusMenu: null })
    }

    handleTicketRefresh = () => {
        this.handleFilterAndSearchData(this.state.selectedQueryType, this.state.selectedStatus)
    }

    // Customizable Area End

}
