import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import axios from "axios";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { makeStyles } from '@material-ui/core/styles';
import { apiCall, errorNotification, getHeaders, isAuthorisedUser, isLoggedIn, successNotification, warningNotification, events, autoLogOut } from "../../../components/src/common";
import { ReactNode } from "react";
// Customizable Area End

export const configJSON = require("./config");

const useStyles = makeStyles({
  customOutline: {
    width: '100%',
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#BABED8",
      borderWidth: 1
    },
    "& .MuiSelect-icon": {
      fill: "rgba(4, 60, 116, 1)",
    },
  },
  menuPaper: {
    maxHeight: 300,
    maxWidth: 280
  }
});

enum FileSelected {
  BUCKET_FILE = 'bucketFile',
  COMMON_FILE = 'commonFile',
  INVOICE_FILE = 'invoiceFile'
}

export interface IAttachements {
  id: number;
  file_name: string;
  doc_file: string;
}
export interface IAPIAttachements {
  api_file_name?: string;
  api_attachment: string;
}
interface ISubmittedBy {
  isContactMaster: boolean;
  masterId: number;
  masterName: string;
}
interface IQueryData {
  is_reopened: boolean;
  customer_name: string;
  product_description: string;
  product_name: any;
  query_detail: string;
  doc_type: any[];
  query_type: string;
  assigned_to: string[];
  status: string;
  sub_type: string;
  submitted_by: ISubmittedBy[];
  ticket_number: string;
  product_id: number;
  id: number;
  pending_with?: string | null;
  customer_comments: string;
  attachment_files: IAttachements[];
  is_transferred_ticket: boolean;
  grant_access_response: ITicketDetailGrantAccessResponse;
  reply_customer_response: ITicketDetailReplyCustomerResponse;
  transfer_query_response: ITicketDetailTransferQueryResponse;
  status_id: number;
  api_attachment: api_attachment;
  ticket_creation_date: any;
  is_biocon_employee?: any;
  reply_to_customer?: boolean;
}

export interface ITicketDetailGrantAccessResponse {
  data: null | {
    id: string;
    type: string;
    attributes: {
      comments: string;
      grant_permission: [];
      status: string;
      status_id: number;
      ticket: string;
      ticket_id: string;
    }
  }
}

export interface ITicketDetailReplyCustomerResponse {
  data: null | {
    id: string;
    type: string;
    attributes: {
      comments: string;
      image: [];
      mark_as_closed: boolean;
      reply_to_customer: string;
      status: string;
      status_id: number;
      ticket: string;
    }
  }
}

export interface ITicketDetailTransferQueryResponse {
  data: null | {
    id: string;
    type: string;
    attributes: {
      [x: string]: ReactNode;
      comments: string;
      pending_with: string;
      remarks: string;
      status: string;
      status_id: number;
      team_member_name: string;
      transfer_query_to_team: string;
    }
  };
}
export interface api_attachment {
  api_attachment: string;
  api_file_name: string;
}

export interface IProductBucketFileListing {
  doc_file: string;
  download_file: boolean;
  file_id: number;
  file_name: string;
  grant_access: boolean;
  product_document_id: number;
  product_document_name: string;
}

export interface ICommonFolderFile {
  common_folder_id: number;
  common_folder_name: string;
  file_id: number;
  file_name: string;
  doc_file: string;
  grant_access: boolean;
  download_file: boolean;
}

export interface IInvoiceFolderFile {
  grant_access: boolean;
  download_file: boolean;
  doc_file: string;
  file_id: number;
  file_name: string;
  invoice_id: number;
  invoice_name: string;
}
export interface IEmployeeData {
  country_code: number;
  created_at: string;
  email: string;
  employee_trerritory_id: number;
  first_name: string;
  id: number;
  last_name: string;
  phone_number: number;
  role_id: number | string;
  updated_at: string;
  name: string;
}

interface IFolderListing {
  name: string;
  isSelected: boolean;
  files: {
    isSelectedFile: boolean;
    id: number;
    file_name: string;
    doc_file: string;
  }[]
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  documentBtnActive: boolean;
  // Customizable Area Start
  Data: any;
  Heading: string;
  Attachments: string;
  productLabel: string;
  ticketIdLabel: string;
  queryTypeLabel: string;
  docTypeLabel: string;
  pendingWithLabel: string;
  assigntoLabel: string;
  subTypeLabel: string;
  submittedByLabel: string;
  selectedOption: string;
  descriptionlabel: string;
  documentLabel: string;
  passwordTioolTip: string;
  openToolTip: boolean;
  anchorEl: any;
  popUPText: string;
  isactionPopUp: boolean;
  isQuesryOptions: boolean;
  queryOptions: any[];
  selectedTab: number;
  queryTransferLabel: string;
  RemarksLabel: string;
  internalCommentsLabel: string;
  replyCustomerLabel: string;
  MarkLabel: string;
  transferOption: any[];
  additionalFiles: boolean;
  additionalFilesData: any[];
  documentSelect: boolean;
  commonFolder: boolean;
  commonFolderList: null | IFolderListing[];
  invoices: boolean;
  invoicesList: null | IFolderListing[];
  docList: boolean;
  selectedFolder: any;
  goBack: boolean;
  fromPage: string;
  toPage: string;
  queryData: IQueryData | null;
  employeesList: null | IEmployeeData[];
  remarkValue: string;
  selectedEmployeeToTransfer: number;
  customerReply: string;
  markAsClosed: boolean;
  attachedMedia: string;
  uploadedFiles: any[];
  uploadedFilesBase64: any[];
  allRemarks: string[];
  remarkHistoryLabel: string;
  replyHistoryLabel: string;
  isLoading: boolean;
  allReplies: string[];
  productBucketListing: [];
  selectedProductBucket: any;
  productBucketFileListing: IProductBucketFileListing[];
  selectAllView: boolean;
  selectedBucketFiles: IProductBucketFileListing[];
  selectedCommonFolder: any;
  selectedInvoiceFolder: any;
  selectedCommonFolderFiles: ICommonFolderFile[];
  selectedCommonFiles: ICommonFolderFile[];
  selectedInvoiceFolderFiles: IInvoiceFolderFile[];
  selectedInvoiceFiles: IInvoiceFolderFile[];
  grantAccessStatus: string;
  grantAccessComment: string;
  transferTheQueryComment: string;
  replyToCutsomerCommet: string;
  statusList: any[];
  allRoles: any[];
  teamMembers: any[];
  selectedteamMember: any;
  pendingWith: number;
  statusId: number;
  isCustomerExcellence: string;
  isRequiredTeamTransfer: boolean;
  isRequiredMemberTransfer: boolean;
  isReplyToCusomer: boolean;
  isReplyToCusomerNumSc: boolean;
  isReplyToCusomerTrasferQuery: boolean;
  isReplyToCusomerTrasferQueryNumSc: boolean;
  istiketClosed: boolean;
  isHistoryPopUp: boolean;
  isCommentsPopUp: boolean;
  all_comments: any[];
  closed_message: any;
  query_replies: any[];
  all_histories: any[];
  ticket_created_by: any;
  is_biocon_employee: any;
  searchDocValue: any;
  signUpcustomerMasterId: number;
  isActionAvailable: boolean;
  documentAccessTioolTip: any;
  isUserPerformAction: boolean;
  queryIsTransferred: boolean;
  documentSelectionButtonText: string;
  additionalFilesButtonText: string;
  CancelButtonText: string;
  submitButtomText: string;
  reOpenButtonText: string;
  ticketDetailsButtonText: string;
  submmittedBYEmpText: string;
  ticketCreatedByText: string;
  changeStatusText: string;
  selectStatusText: string;
  noDataText: string;
  ticketHistoryText: string;
  commentHistoryText: string;
  documentSelectionText: string;
  additionalFilesText: string;
  cancleButtonText: string;
  submitButtonText: string;
  noBucketsFoundText: string;
  isViewAllText: string;
  downloadText: string;
  noDocumentsText: string;
  commonFolderText: string;
  invoiceText: string;
  transferQueryToTeamText: string;
  selectTransferQueryToText: string;
  mandatoryFieldText: string;
  teamMemberNameText: string;
  selectText: string;
  allText: string;
  mandatoryText: string;
  replyRules: string;
  AttachmentsText: string;
  internalCommentsPlaceholder: string;
  commentPlaceholder: string;
  internalCommentPlaceholder: string;
  takeActionText: string;
  actionButtonText: string;
  productNameText: string;
  readMoreText: string;
  requestRaisedOnText: string;
  submmittedByTeamText: string;
  customerCommentsText: string;
  viewCommentsHistoryText: string;
  coaText: string;
  searchDocPlaceholder: string;
  ViewText: string;
  commentFieldLabel: string;
  statusText: string;
  replyToCustomerText: string;
  phoneNumText: string;
  searchFolderText: string;
  signupRequestConditionText: string;
  sampleRequestConditionText: string;
  ProductQueryConditionText: string;
  customTabsLabelText: string;
  GrantAccessText: string;
  TransferTheQueryText: string;
  ReplyToCustomerTabsText: string;
  CustomerExcellenceConditionText: string;
  ConnectWithUsText: string;
  SetUpMeetingText: string;
  ClosedStatusText: string;
  is_reopened: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class QueryDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  authToken: string;
  ReopenTicketsAPICallID: string;
  SearchDocumentsAPICallID: string;
  getQueryDetailApiCallId: string;
  getEmployeesListApiCallId: string;
  createGrantAccessApiCallId: string;
  createTransferQueryApiCallId: string;
  createReplyToCustomerApiCallId: string;
  getAllAdditionalFilesFolderApiCallId: string;
  getAllRemarksApiCallId: string;
  getAllRepliesApiCallId: string;
  getAllStatusApiCallId: string;
  getAllDocumentBucketListingApiCallId: string;
  getAllSelectedBucketFilesApiCallId: string;
  submitGrantAccessApiCallId: string;
  getAllSelectedCommonFolderFilesApiCallId: string;
  getAllSelectedInvoiceFolderFilesApiCallId: string;
  getAllRoleApiCallId: string;
  getTeamMembersApiCallId: string;
  getQueryRepliesApiCallId: string;
  getTicketHistoryApiCallId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      documentBtnActive: true,
      // Customizable Area Start
      selectedTab: 0,
      Heading: configJSON.Heading,
      Attachments: configJSON.Attachments,
      productLabel: configJSON.productLabel,
      ticketIdLabel: configJSON.ticketIdLabel,
      queryTypeLabel: configJSON.queryTypeLabel,
      docTypeLabel: configJSON.docTypeLabel,
      pendingWithLabel: configJSON.pendingWithLabel,
      assigntoLabel: configJSON.assigntoLabel,
      subTypeLabel: configJSON.subTypeLabel,
      submittedByLabel: configJSON.submittedByLabel,
      selectedOption: "Description",
      descriptionlabel: configJSON.descriptionlabel,
      documentLabel: configJSON.documentLabel,
      passwordTioolTip: configJSON.passwordTioolTip,
      openToolTip: false,
      anchorEl: null,
      popUPText: '',
      is_biocon_employee: '',
      isactionPopUp: false,
      isQuesryOptions: false,
      queryTransferLabel: configJSON.queryTransferLabel,
      RemarksLabel: configJSON.RemarksLabel,
      internalCommentsLabel: configJSON.internalCommentsLabel,
      replyCustomerLabel: configJSON.replyCustomerLabel,
      MarkLabel: configJSON.MarkLabel,
      additionalFiles: false,
      documentSelect: false,
      docList: false,
      isLoading: false,
      replyHistoryLabel: configJSON.remarkHistoryLabel,
      remarkHistoryLabel: configJSON.remarkHistoryLabel,
      productBucketFileListing: [],
      selectAllView: false,
      grantAccessComment: '',
      transferTheQueryComment: '',
      replyToCutsomerCommet: '',
      signUpcustomerMasterId: 0,
      additionalFilesData: [
        {
          fileName: 'Common Folders',
          isSelected: true
        },
        {
          fileName: 'Invoices',
          isSelected: false
        }
      ],
      transferOption: [
        {
          id: 1,
          value: 'Team 1'
        },
        {
          id: 2,
          value: 'Team 2'
        },
        {
          id: 3,
          value: 'Team 3'
        }
      ],
      Data: {
        ticketId: 'EH - 2484ER93304',
        docType: 'Regulatory Document',
        subType: 'Product specification',
        productName: 'Lorem Ipsum is simply dummy text of the printing and one or more typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standardd',
        queryType: 'Document Request',
        submittedBy: [{ name: 'Iruka Akuchi' }, { name: 'Sun Sharma' }],
      },
      queryData: null,
      employeesList: null,
      remarkValue: "",
      selectedEmployeeToTransfer: 0,
      customerReply: "",
      markAsClosed: false,
      attachedMedia: "",
      uploadedFiles: [],
      uploadedFilesBase64: [],
      queryOptions: [
        {
          option: 'Product Query'
        },
        {
          option: 'Sample Request'
        },
        {
          option: 'Meeting Request'
        }
      ],
      commonFolder: false,
      commonFolderList: null,
      invoices: false,
      invoicesList: null,
      selectedFolder: {},
      goBack: false,
      fromPage: "",
      toPage: "",
      allRemarks: [],
      allReplies: [],
      productBucketListing: [],
      selectedProductBucket: null,
      selectedBucketFiles: [],
      selectedCommonFolder: null,
      selectedInvoiceFolder: null,
      selectedCommonFolderFiles: [],
      selectedInvoiceFolderFiles: [],
      selectedCommonFiles: [],
      selectedInvoiceFiles: [],
      grantAccessStatus: '',
      statusList: [],
      allRoles: [],
      teamMembers: [],
      selectedteamMember: 0,
      pendingWith: 0,
      statusId: 0,
      isCustomerExcellence: "",
      isRequiredTeamTransfer: false,
      isRequiredMemberTransfer: false,
      isReplyToCusomer: false,
      isReplyToCusomerNumSc: false,
      isReplyToCusomerTrasferQuery: false,
      isReplyToCusomerTrasferQueryNumSc: false,
      istiketClosed: false,
      isHistoryPopUp: false,
      isCommentsPopUp: false,
      all_comments: [],
      closed_message: '',
      query_replies: [],
      all_histories: [],
      ticket_created_by: {},
      searchDocValue: '',
      isActionAvailable: false,
      documentAccessTioolTip: configJSON.documentAccessTioolTip,
      isUserPerformAction: false,
      queryIsTransferred: false,
      documentSelectionButtonText: configJSON.documentSelectionButtonText,
      additionalFilesButtonText: configJSON.additionalFilesButtonText,
      CancelButtonText: configJSON.CancelButtonText,
      submitButtomText: configJSON.submitButtomText,
      reOpenButtonText: configJSON.reOpenButtonText,
      ticketDetailsButtonText: configJSON.ticketDetailsButtonText,
      submmittedBYEmpText: configJSON.submmittedBYEmpText,
      ticketCreatedByText: configJSON.ticketCreatedByText,
      changeStatusText: configJSON.changeStatusText,
      selectStatusText: configJSON.selectStatusText,
      noDataText: configJSON.noDataText,
      ticketHistoryText: configJSON.ticketHistoryText,
      commentHistoryText: configJSON.commentHistoryText,
      documentSelectionText: configJSON.documentSelectionText,
      additionalFilesText: configJSON.additionalFilesText,
      cancleButtonText: configJSON.cancleButtonText,
      submitButtonText: configJSON.submitButtonText,
      noBucketsFoundText: configJSON.noBucketsFoundText,
      isViewAllText: configJSON.isViewAllText,
      downloadText: configJSON.downloadText,
      noDocumentsText: configJSON.noDocumentsText,
      commonFolderText: configJSON.commonFolderText,
      invoiceText: configJSON.invoiceText,
      transferQueryToTeamText: configJSON.transferQueryToTeamText,
      selectTransferQueryToText: configJSON.selectTransferQueryToText,
      mandatoryFieldText: configJSON.mandatoryFieldText,
      teamMemberNameText: configJSON.teamMemberNameText,
      selectText: configJSON.selectText,
      allText: configJSON.allText,
      mandatoryText: configJSON.mandatoryText,
      replyRules: configJSON.replyRules,
      AttachmentsText: configJSON.AttachmentsText,
      internalCommentsPlaceholder: configJSON.internalCommentsPlaceholder,
      commentPlaceholder: configJSON.commentPlaceholder,
      internalCommentPlaceholder: configJSON.internalCommentPlaceholder,
      takeActionText: configJSON.takeActionText,
      actionButtonText: configJSON.actionButtonText,
      productNameText: configJSON.productNameText,
      readMoreText: configJSON.readMoreText,
      requestRaisedOnText: configJSON.requestRaisedOnText,
      submmittedByTeamText: configJSON.submmittedByTeamText,
      customerCommentsText: configJSON.customerCommentsText,
      viewCommentsHistoryText: configJSON.viewCommentsHistoryText,
      coaText: configJSON.coaText,
      searchDocPlaceholder: configJSON.searchDocPlaceholder,
      ViewText: configJSON.ViewText,
      commentFieldLabel: configJSON.commentFieldLabel,
      statusText: configJSON.statusText,
      replyToCustomerText: configJSON.replyToCustomerText,
      phoneNumText: configJSON.phoneNumText,
      searchFolderText: configJSON.searchFolderText,
      signupRequestConditionText: configJSON.signupRequestConditionText,
      sampleRequestConditionText: configJSON.sampleRequestConditionText,
      ProductQueryConditionText: configJSON.ProductQueryConditionText,
      customTabsLabelText: configJSON.customTabsLabelText,
      GrantAccessText: configJSON.GrantAccessText,
      TransferTheQueryText: configJSON.TransferTheQueryText,
      CustomerExcellenceConditionText: configJSON.CustomerExcellenceConditionText,
      ReplyToCustomerTabsText: configJSON.ReplyToCustomerTabsText,
      ConnectWithUsText: configJSON.ConnectWithUsText,
      SetUpMeetingText: configJSON.SetUpMeetingText,
      ClosedStatusText: configJSON.ClosedStatusText,
      is_reopened: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.showComments = this.showComments.bind(this);
    this.checkForSubmmittedBy = this.checkForSubmmittedBy.bind(this);
    this.proceedForBucketList = this.proceedForBucketList.bind(this);
    this.proceedToDownloadAPIDoc = this.proceedToDownloadAPIDoc.bind(this);
    this.handleTicketDetailsClick = this.handleTicketDetailsClick.bind(this);
    this.handleGrantAccessComment = this.handleGrantAccessComment.bind(this);
    this.closeActionPopUp = this.closeActionPopUp.bind(this);
    this.navigateToDetailsPage = this.navigateToDetailsPage.bind(this);
    this.handleSelectAllViews = this.handleSelectAllViews.bind(this);
    this.SubmitGrantAccess = this.SubmitGrantAccess.bind(this);
    this.handleGrantAccessStatusChange = this.handleGrantAccessStatusChange.bind(this);
    this.handlePendingWithChange = this.handlePendingWithChange.bind(this);
    this.handleSeachDocument = this.handleSeachDocument.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.setAdditionalFiles = this.setAdditionalFiles.bind(this);
    this.setSelectDocuments = this.setSelectDocuments.bind(this);
    this.setCommonFolder = this.setCommonFolder.bind(this);
    this.setInvoiceList = this.setInvoiceList.bind(this);
    this.setDocList = this.setDocList.bind(this);
    this.handleProductBucketListingClick = this.handleProductBucketListingClick.bind(this);
    this.handleCommonFolderClick = this.handleCommonFolderClick.bind(this);
    this.handleInvoiceFolderClick = this.handleInvoiceFolderClick.bind(this);
    this.goBack = this.goBack.bind(this);
    this.handleGrantAccessCancelClick = this.handleGrantAccessCancelClick.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);
    this.handleTeamMemberChange = this.handleTeamMemberChange.bind(this);
    this.submitTransferQuery = this.submitTransferQuery.bind(this);
    this.submitReplyToCustomer = this.submitReplyToCustomer.bind(this);
    this.onDocPreview = this.onDocPreview.bind(this);
    this.setActionPopUp = this.setActionPopUp.bind(this);
    this.setEnableField = this.setEnableField.bind(this);
    this.setInputValue = this.setInputValue.bind(this);
    this.doButtonPressed = this.doButtonPressed.bind(this);
    this.createdReplyToCustomer = this.createdReplyToCustomer.bind(this);
    this.submmitedGrantAccess = this.submmitedGrantAccess.bind(this);
    this.createdTransferQuery = this.createdTransferQuery.bind(this);
    this.getStatusList = this.getStatusList.bind(this);
    this.getRoleList = this.getRoleList.bind(this);
    this.getAllDocumentSelectionBucketsListing = this.getAllDocumentSelectionBucketsListing.bind(this);
    this.getAllAdditionalFilesFolders = this.getAllAdditionalFilesFolders.bind(this);
    this.submitGrantAccess = this.submitGrantAccess.bind(this);
    this.fetchListOfEmployees = this.fetchListOfEmployees.bind(this);
    this.setEmptyQueryDetails = this.setEmptyQueryDetails.bind(this);
    this.duplicateCode = this.duplicateCode.bind(this);
    this.afterSetQueryDetails = this.afterSetQueryDetails.bind(this);
    this.setQueryDetails = this.setQueryDetails.bind(this);
    this.handleProductBucketFileSelectionGrantAccess = this.handleProductBucketFileSelectionGrantAccess.bind(this);
    this.handleProductBucketFileSelectionDownloadPermission = this.handleProductBucketFileSelectionDownloadPermission.bind(this);
    this.handleCommonFileSelectionGrantAccess = this.handleCommonFileSelectionGrantAccess.bind(this);
    this.handleCommonFileSelectionDownloadPermission = this.handleCommonFileSelectionDownloadPermission.bind(this);
    this.handleInvoiceFileSelectionGrantAccess = this.handleInvoiceFileSelectionGrantAccess.bind(this);
    this.handleInvoiceFileSelectionDownloadPermission = this.handleInvoiceFileSelectionDownloadPermission.bind(this);
    this.setTransferQueryDataResponse = this.setTransferQueryDataResponse.bind(this);
    this.setSelectedBucketsResponseData = this.setSelectedBucketsResponseData.bind(this);
    this.setAllBucketListData = this.setAllBucketListData.bind(this);
    this.setEmployeeList = this.setEmployeeList.bind(this);
    this.setQueryDetailResponse = this.setQueryDetailResponse.bind(this);
    this.setReopenTicketResponseData = this.setReopenTicketResponseData.bind(this);
    this.searchDocumentResponseData = this.searchDocumentResponseData.bind(this);
    this.setTicketHistoryResponse = this.setTicketHistoryResponse.bind(this);
    this.setQueryRepliesResponse = this.setQueryRepliesResponse.bind(this);
    this.setAllAdditionalFilesResponse = this.setAllAdditionalFilesResponse.bind(this);
    this.setAllSelectedInvoiceResponse = this.setAllSelectedInvoiceResponse.bind(this);
    this.setAllSelectedCommonFolderResponse = this.setAllSelectedCommonFolderResponse.bind(this);
    this.setAllStatusResponseData = this.setAllStatusResponseData.bind(this);
    this.setTeamMemberResponseData = this.setTeamMemberResponseData.bind(this);
    this.setAllRolesResponseData = this.setAllRolesResponseData.bind(this);
    this.setAllRepliesResponseData = this.setAllRepliesResponseData.bind(this);
    this.setAllRemarkResponseData = this.setAllRemarkResponseData.bind(this);
    this.setReplyToCustomerResponse = this.setReplyToCustomerResponse.bind(this);
    this.submitGrantAccessResponse = this.submitGrantAccessResponse.bind(this);
    this.setDocumentAPICalls = this.setDocumentAPICalls.bind(this);
    this.setDetailsAPICalls = this.setDetailsAPICalls.bind(this);
    this.setAllActionAPICalls = this.setAllActionAPICalls.bind(this);
    this.setAllListAPICalls = this.setAllListAPICalls.bind(this);
    this.fetchQueryDetail = this.fetchQueryDetail.bind(this);
    this.setSelectedBuscketFiles = this.setSelectedBuscketFiles.bind(this);
    this.setPermissionForActionButton = this.setPermissionForActionButton.bind(this);
    this.setQueryData = this.setQueryData.bind(this);
    this.reOpenTicket = this.reOpenTicket.bind(this);
    this.showHistory = this.showHistory.bind(this);
    this.handleToolTip = this.handleToolTip.bind(this);
    // Customizable Area End

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );
      this.setState({ txtSavedValue: value });
    }
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId && responseJson) {
        isAuthorisedUser(responseJson, this.props.navigation);
        this.setState({ isLoading: false })
        this.setDetailsAPICalls(apiRequestCallId, responseJson);
        this.setAllActionAPICalls(apiRequestCallId, responseJson);
        this.setAllListAPICalls(apiRequestCallId, responseJson);
        this.setDocumentAPICalls(apiRequestCallId, responseJson);
        if (apiRequestCallId === this.ReopenTicketsAPICallID) {
          this.setState({
            isLoading: false,
            is_reopened: true
          })
          this.setReopenTicketResponseData(responseJson);
        }
      }
    }
    // Customizable Area End

  }

  setQueryDetails = (responseJson: any) => {
    this.setQueryData(responseJson);
    if (responseJson.data.attributes.query_type === "Sample Request") {
      if (localStorage.getItem("employee_sample_request_permission") === "true") {
        this.setState({
          isActionAvailable: true
        })
      }
    }
    if (responseJson.data.attributes.query_type === "Product Query") {
      if (localStorage.getItem("employee_product_request_permission") === "true") {
        this.setState({
          isActionAvailable: true
        })
      }
    }
    if (responseJson.data.attributes.query_type === "Meeting Request") {
      if (localStorage.getItem("employee_meeting_request_permission") === "true") {
        this.setState({
          isActionAvailable: true
        })
      }
    }
    if (responseJson.data.attributes.query_type === "Connect With Us") {
      if (localStorage.getItem("employee_connectwithus_request_permission") === "true") {
        this.setState({
          isActionAvailable: true
        })
      }
    }
    responseJson.data.attributes.submitted_by.forEach((element: any) => {
      if (element.isContactMaster) {
        this.setState({
          signUpcustomerMasterId: element.masterId
        })
      }
    });

    this.setState({
      grantAccessStatus: responseJson.data.attributes.status.toLowerCase(),
      statusId: responseJson.data.attributes.status_id,
    }, () => {
      this.afterSetQueryDetails(responseJson)
    });
  }

  setDetailsAPICalls = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getQueryDetailApiCallId) {
      this.setQueryDetailResponse(responseJson);
    }
    if (apiRequestCallId === this.getEmployeesListApiCallId) {
      this.setEmployeeList(responseJson)
    }

    if (apiRequestCallId === this.getAllDocumentBucketListingApiCallId) {
      this.setAllBucketListData(responseJson);
    }

    if (apiRequestCallId === this.getAllSelectedBucketFilesApiCallId) {
      this.setSelectedBucketsResponseData(responseJson);
    }
  }

  setAllActionAPICalls = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.createTransferQueryApiCallId) {
      this.setTransferQueryDataResponse(responseJson);
    }

    if (apiRequestCallId === this.submitGrantAccessApiCallId) {
      this.submitGrantAccessResponse(responseJson)
    }
    if (apiRequestCallId === this.createReplyToCustomerApiCallId) {
      this.setReplyToCustomerResponse(responseJson);
    }

    if (apiRequestCallId === this.getAllRemarksApiCallId) {
      this.setAllRemarkResponseData(responseJson);
    }

    if (apiRequestCallId === this.getAllRepliesApiCallId) {
      this.setAllRepliesResponseData(responseJson);
    }
  }

  setAllListAPICalls = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getAllRoleApiCallId) {
      this.setAllRolesResponseData(responseJson);
    }

    if (apiRequestCallId === this.getTeamMembersApiCallId) {
      this.setTeamMemberResponseData(responseJson);
    }

    if (apiRequestCallId === this.getAllStatusApiCallId) {
      this.setAllStatusResponseData(responseJson);
    }

    if (apiRequestCallId === this.getAllSelectedCommonFolderFilesApiCallId) {
      this.setAllSelectedCommonFolderResponse(responseJson);
    }
  }

  setDocumentAPICalls = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getAllSelectedInvoiceFolderFilesApiCallId) {
      this.setAllSelectedInvoiceResponse(responseJson);
     }

     if (apiRequestCallId === this.getAllAdditionalFilesFolderApiCallId) {
       this.setAllAdditionalFilesResponse(responseJson);
     }

     if (apiRequestCallId === this.getQueryRepliesApiCallId) {
       this.setQueryRepliesResponse(responseJson);
     }

     if (apiRequestCallId === this.getTicketHistoryApiCallId) {
       this.setTicketHistoryResponse(responseJson);
     }

    if (apiRequestCallId === this.SearchDocumentsAPICallID) {
       this.searchDocumentResponseData(responseJson);
     }
  }

  setReopenTicketResponseData = (responseJson: any) => {
    if (responseJson.data) {
      this.fetchQueryDetail();
      successNotification(configJSON.ticketOpenSuccessMsg)
    } else {
      errorNotification(responseJson.errors[0].status)
    }
  }

  searchDocumentResponseData = (responseJson: any) => {
    if (responseJson.result) {
      this.setState({
        productBucketListing: responseJson.result
      })
    }
  }

  setTicketHistoryResponse = (responseJson: any) => {
    if (responseJson.all_histories) {
      this.setState({
        all_histories: responseJson.all_histories,
        ticket_created_by: responseJson.ticket_created_by
      })
    }
  }

  setQueryRepliesResponse = (responseJson: any) => {
    if (responseJson.employee_result) {
      this.setState({
        all_comments: responseJson.employee_result,
        closed_message: responseJson?.closed_message
      })
    }
  }

  setAllAdditionalFilesResponse = (responseJson: any) => {
    if (responseJson.all_common_folders && responseJson.all_invoices) {
      const duplicateCode = (folder: any) => {
        return {
          name: folder.name,
          id: folder.id
        }
      }
      const commonFoldersList = responseJson.all_common_folders.map(duplicateCode);
      const invoiceList = responseJson.all_invoices.map(duplicateCode);
      this.setState({
        invoicesList: invoiceList,
        commonFolderList: commonFoldersList
      })
    }
  }

  setAllSelectedInvoiceResponse = (responseJson: any) => {
    if (!responseJson.error && !responseJson.errors && responseJson.file_listing) {
      const apiResponse = [...responseJson.file_listing];
      apiResponse.forEach((data) => {
        data.grant_access = true
      })
      if (this.state.selectedInvoiceFiles.length > 0) {
        const selectedFiles = [...this.state.selectedInvoiceFiles];
        selectedFiles.forEach((file: IInvoiceFolderFile, index: number) => {
          const userFileIndex = apiResponse.findIndex((userFile: IInvoiceFolderFile) => file.file_id === userFile.file_id && file.invoice_id === userFile.invoice_id);
          if (userFileIndex > -1) {
            apiResponse[userFileIndex] = file
          }
        });
      }
      this.setState({
        selectedInvoiceFolderFiles: apiResponse
      })
    }
  }

  setAllSelectedCommonFolderResponse = (responseJson: any) => {
    if (!responseJson.error && !responseJson.errors && responseJson.file_listing) {
      const apiResponse = [...responseJson.file_listing];
      apiResponse.forEach((data) => {
        data.grant_access = true
      })
      if (this.state.selectedCommonFiles.length > 0) {
        const selectedFiles = [...this.state.selectedCommonFiles];
        selectedFiles.forEach((file: ICommonFolderFile, index: number) => {
          const userFileIndex = apiResponse.findIndex((userFile: ICommonFolderFile) => file.file_id === userFile.file_id && file.common_folder_id === userFile.common_folder_id);
          if (userFileIndex > -1) {
            apiResponse[userFileIndex] = file
          }
        });
      }
      this.setState({
        selectedCommonFolderFiles: apiResponse
      })
    }
  }

  setAllStatusResponseData = (responseJson: any) => {
    if (!responseJson.error && !responseJson.errors) {
      const responseObject = { ...responseJson.status };
      const convertedArray = Object.keys(responseObject).map((key: string) => {
        return { id: responseObject[key], name: key }
      });

      this.setState({
        statusList: convertedArray
      })
    }
  }

  setTeamMemberResponseData = (responseJson: any) => {
    if (!responseJson.error && !responseJson.errors) {
      this.setState({
        teamMembers: responseJson.all_team_names
      })
    }
  }

  setAllRolesResponseData = (responseJson: any) => {
    if (!responseJson.error && !responseJson.errors) {
      this.setState({
        allRoles: responseJson.all_roles
      })
    }
  }

  setAllRepliesResponseData = (responseJson: any) => {
    if (!responseJson.error && !responseJson.errors) {
      this.setState({
        allReplies: responseJson.all_replies
      })
    }
  }

  setAllRemarkResponseData = (responseJson: any) => {
    if (!responseJson.error && !responseJson.errors) {
      this.setState({
        allRemarks: responseJson.all_remarks
      })
    }
  }

  setReplyToCustomerResponse = (responseJson: any) => {
    if (responseJson.meta && responseJson.data) {
      successNotification(responseJson.meta.message);
      this.createdReplyToCustomer();
    }
  }

  submitGrantAccessResponse = (responseJson: any) => {
    if (responseJson.data && !responseJson.error && !responseJson.errors) {
      successNotification("Ticket updated successfully");
      this.submmitedGrantAccess()
    }
  }

  setTransferQueryDataResponse = (responseJson: any) => {
    if (responseJson.data) {
      successNotification("Query Transferred");
      this.createdTransferQuery()
    } else if (responseJson.message) {
      this.setState({
        isactionPopUp: false,
        remarkValue: "",
        selectedEmployeeToTransfer: 0,
        isLoading: false,
        queryIsTransferred: false,
      })
      warningNotification(responseJson.message);
    } else {
      warningNotification("Something went wrong");
    }
  }

  setSelectedBucketsResponseData = (responseJson: any) => {
    if (responseJson.document_listing) {
      const apiResponse = [...responseJson.document_listing];
      if (this.state.selectedBucketFiles.length > 0) {
        this.setSelectedBuscketFiles(apiResponse);
      }
      this.setState({
        productBucketFileListing: apiResponse
      })
    }
  }

  setAllBucketListData = (responseJson: any) => {
    if (responseJson.result) {
      this.setState({
        productBucketListing: responseJson.result
      });
    } else {
      errorNotification("Something went wrong")
    }
  }

  setEmployeeList = (responseJson: any) => {
    this.setState({
      employeesList: responseJson.biocon_account_managers
    })
  }

  setQueryDetailResponse = (responseJson: any) => {
    if (responseJson.data) {
      this.setQueryDetails(responseJson);
    } else {
      this.setEmptyQueryDetails();
    }
  }

  setQueryData = (responseJson: any) => {
    this.setPermissionForActionButton(responseJson.data?.attributes);
    this.setState({
      queryData: {
        is_reopened: responseJson?.data?.attributes?.is_reopened,
        customer_name: responseJson.data.attributes.customer_name || "",
        doc_type: responseJson.data.attributes.doc_type || [],
        product_description: responseJson.data.attributes.product_description || "",
        product_name: responseJson.data.attributes.product_name,
        query_detail: responseJson.data.attributes.query_detail || "",
        assigned_to: responseJson.data?.attributes?.assigned_to || [],
        query_type: responseJson.data.attributes.query_type,
        status: responseJson.data.attributes.status || "",
        sub_type: responseJson.data.attributes.sub_type || "",
        submitted_by: responseJson.data.attributes.submitted_by || [],
        ticket_number: responseJson.data.attributes.ticket_number || "",
        product_id: responseJson.data.attributes.product_id || 0,
        id: responseJson.data.id || 0,
        pending_with: responseJson.data.attributes.pending_with || "",
        customer_comments: responseJson.data.attributes.customer_comments || "",
        attachment_files: (responseJson.data.attributes.attachments && responseJson.data.attributes.attachments !== 'Empty') ? responseJson.data.attributes.attachments : [],
        is_transferred_ticket: responseJson.data.attributes.is_transferred_ticket,
        grant_access_response: responseJson.data.attributes.grant_access_response,
        reply_customer_response: responseJson.data.attributes.reply_customer_response,
        transfer_query_response: responseJson.data.attributes.transfer_query_response,
        status_id: responseJson.data.attributes.status_id,
        api_attachment: responseJson.data.attributes.api_attachment,
        ticket_creation_date: responseJson.data.attributes.ticket_creation_date,
        is_biocon_employee: responseJson.data.attributes.is_biocon_employee,
        reply_to_customer: responseJson.data.attributes.reply_to_customer,
      }
    })
  }

  afterSetQueryDetails = (responseJson: any) => {
    if (responseJson.data.attributes.query_type === "Product Query") {
      this.getAllDocumentSelectionBucketsListing();
    }
    if (this.state.statusId === 2) {
      this.setState({
        istiketClosed: true
      })
    } else {
      this.setState({
        istiketClosed: false
      })
    }
  }

  setEmptyQueryDetails = () => {
    errorNotification("Something went wrong")
    this.setState({
      queryData: {
        ...this.state.queryData,
        is_reopened: false,
        customer_name: "-",
        product_description: "-",
        product_name: "-",
        query_detail: "-",
        doc_type: [],
        assigned_to: [],
        query_type: "-",
        status: "-",
        sub_type: "-",
        submitted_by: [],
        ticket_number: "-",
        product_id: 0,
        id: 0,
        pending_with: "",
        customer_comments: "",
        is_biocon_employee: '',
        attachment_files: [],
        is_transferred_ticket: false,
        grant_access_response: { data: null },
        reply_customer_response: { data: null },
        transfer_query_response: { data: null },
        status_id: 0,
        ticket_creation_date: "",
        reply_to_customer: false,
        api_attachment: {
          api_attachment: '',
          api_file_name: ''
        }
      }
    })
  }

  setSelectedBuscketFiles = (apiResponse: any) => {
    const selectedFiles = [...this.state.selectedBucketFiles];
    selectedFiles.forEach((file: IProductBucketFileListing, index: number) => {
      const userFileIndex = apiResponse.findIndex((userFile: IProductBucketFileListing) => file.file_id === userFile.file_id && file.product_document_id === userFile.product_document_id);
      if (userFileIndex > -1) {
        apiResponse[userFileIndex] = file
      }
    });
  }

  createdTransferQuery = () => {
    this.getAllRemarks();
    this.setState({
      remarkValue: "",
      selectedEmployeeToTransfer: 0,
      isactionPopUp: false,
      customerReply: "",
      grantAccessComment: "",
      transferTheQueryComment: '',
      isLoading: false,
      queryIsTransferred: true,
    })
    this.fetchQueryDetail();
    this.getQueryReplies();
    this.getTicketHistory();
  }

  submmitedGrantAccess = () => {
    this.setState({
      isactionPopUp: false,
      commonFolder: false,
      invoices: false,
      documentSelect: false,
      additionalFiles: false,
      selectedProductBucket: null,
      selectedInvoiceFolder: null,
      selectedCommonFolder: null,
      customerReply: "",
      grantAccessComment: "",
      isLoading: false,
    });
    this.fetchQueryDetail();
    this.getQueryReplies();
    this.getTicketHistory();
  }

  createdReplyToCustomer = () => {
    this.getAllReplies();
    this.setState({
      customerReply: "",
      selectedEmployeeToTransfer: 0,
      uploadedFiles: [],
      uploadedFilesBase64: [],
      isactionPopUp: false,
      isLoading: false,
      grantAccessComment: "",
      replyToCutsomerCommet: "",
    })
    this.fetchQueryDetail();
    this.getQueryReplies();
    this.getTicketHistory();
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    keyboardType: "email-address",
    autoCompleteType: "email",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  // Customizable Area Start
  setActionPopUp = () => {
    this.setState({ isRequiredTeamTransfer: false })
    this.setState({ isRequiredMemberTransfer: false })

    if (this.state.queryData?.query_type !== "Signup Request") {
      this.setState({ isactionPopUp: !this.state.isactionPopUp });
    } else {
      const queryId = this.props.navigation.getParam('id');
      this.props.navigation.navigate("HandleSignupRequest", { id: queryId })
    }

    this.setState({ selectedBucketFiles: [] })
  };

  handleChangeTab = (event: any, newValue: any) => {
    this.setState({ selectedTab: newValue });
  };

  setAdditionalFiles = () => {
    this.setState({
      documentSelect: false,
      fromPage: 'Document Selection',
      toPage: 'Document'
    });
    this.setState({
      additionalFiles: true,
      fromPage: 'Additional Files',
      toPage: 'Categories'
    });
    this.setState({ documentBtnActive: false })
    this.setState({ goBack: true });
  };

  setSelectDocuments = () => {
    this.setState({
      additionalFiles: false,
      fromPage: 'Additional Files',
      toPage: 'Categories'
    });
    this.setState({
      documentSelect: true,
      fromPage: 'Document Selection',
      toPage: 'Document'
    });
    this.setState({ documentBtnActive: true })
    this.setState({ goBack: true });
  };

  setCommonFolder = () => {
    this.setState({
      commonFolder: true,
      fromPage: 'Additional Files',
      toPage: 'Common Folder'
    });
    this.setState({ invoices: false });
    this.setState({ docList: false });
  };

  setInvoiceList = () => {
    this.setState({
      invoices: !this.state.invoices,
      fromPage: 'Additional Files',
      toPage: 'Invoices'
    })
    this.setState({ commonFolder: false })
    this.setState({ docList: false })
  };

  setDocList = (data: any) => {
    this.setState({
      docList: true,
      fromPage: this.state.commonFolder ? 'Common Folder' : 'Invoices',
      toPage: 'Documents'
    });
    this.setState({ selectedFolder: data })
  };

  handleProductBucketListingClick = (data: any) => {
    this.setState({ productBucketFileListing: [] })
    if (data?.name?.toLowerCase() === 'dmf') {
      const userDetails = JSON.parse(localStorage.getItem("userInfo") || "")
      if (userDetails.attributes.role_id === 12) {
        this.handleProductDocBucketList(data);
      }
    } else {
      this.handleProductDocBucketList(data);
    }
  }

  handleProductDocBucketList = (data: any) => {
    this.setState({
      documentSelect: true,
      fromPage: 'Document Selection',
      toPage: data.name,
      commonFolder: false,
      invoices: false,
      selectedProductBucket: data
    }, () => {
      this.getAllFilesOfSelectedBucket();
    });
  }


  handleCommonFolderClick = (data: any) => {
    if (data.name.toLowerCase() === 'dmf') {
      const userDetails = JSON.parse(localStorage.getItem("userInfo") || "")
      if (userDetails.attributes.role_id === 12) {
        this.handleCommonFoldersDocsList(data);
      }
    } else {
      this.handleCommonFoldersDocsList(data);
    }
  }

  handleCommonFoldersDocsList = (data: any) => {
    this.setState({
      fromPage: 'Common Folder',
      toPage: data.name,
      commonFolder: true,
      invoices: false,
      selectedCommonFolder: data
    }, () => {
      this.getAllFilesOfSelectedCommonFolder();
    });
  }

  handleInvoiceFolderClick = (data: any) => {
    this.setState({
      fromPage: 'Invoices',
      toPage: data.name,
      commonFolder: false,
      invoices: true,
      selectedInvoiceFolder: data
    }, () => {
      this.getAllFilesOfSelectedInvoiceFolder();
    });
  }

  goBack = () => {
    if (this.state.docList) {
      this.setState({
        docList: false,
        fromPage: 'Additional Files',
        toPage: this.state.invoices ? 'Invoices' : 'Common Folders',
      });
      this.setState({ goBack: true });
    } else if (this.state.additionalFiles && !this.state.invoices && !this.state.commonFolder) {
      this.setState({
        additionalFiles: false,
        fromPage: 'Additional Files',
        toPage: this.state.invoices ? 'Invoices' : 'Common Folders',
      });
      this.setState({ goBack: false });
    } else if (this.state.documentSelect && !this.state.selectedProductBucket) {
      this.setState({ invoices: false });
      this.setState({ commonFolder: false });
      this.setState({ documentSelect: false, additionalFiles: false });
      this.setState({ goBack: false });
    } else if (this.state.selectedProductBucket) {
      this.setState({ documentSelect: true, selectedProductBucket: null, toPage: "Document" });
    }
    else if ((this.state.additionalFiles && this.state.commonFolder && !this.state.selectedCommonFolder) || (this.state.additionalFiles && this.state.invoices && !this.state.selectedInvoiceFolder)) {
      this.setState({
        commonFolder: false,
        fromPage: 'Additional Files',
        toPage: 'Options',
      });
      this.setState({ additionalFiles: true });
      this.setState({ invoices: false });
      this.setState({ goBack: true });
    } else if (this.state.commonFolder && !this.state.selectedCommonFolder) {
      this.setState({ commonFolder: false })
    } else if (this.state.selectedCommonFolder) {
      this.setState({ commonFolder: true, selectedCommonFolder: null, toPage: "Common Folder" });
    } else if (this.state.selectedInvoiceFolder) {
      this.setState({ invoices: true, selectedInvoiceFolder: null, toPage: "Invoices" });
    } else if (this.state.additionalFiles) {
      this.setState({ additionalFiles: false });
      this.setState({ commonFolder: false });
      this.setState({ invoices: false });
      this.setState({ goBack: false });
    }
  }

  handleGrantAccessCancelClick = () => {
    this.setState({
      additionalFiles: false,
      commonFolder: false,
      invoices: false,
      goBack: false,
      selectedInvoiceFolder: false,
      selectedCommonFolder: false,
      selectedProductBucket: false
    })
  }

  handleRoleChange = (event: any, value: any) => {
    this.setState({
      selectedEmployeeToTransfer: value.props.value, pendingWith: value.props.value
    })
    this.getMemberList(value.props.value);
  }

  handleTeamMemberChange = (event: any, value: any) => {
    this.setState({
      selectedteamMember: value.props.value
    })
  }

  handleChange = (prop: any) => (event: any) => {
    if (prop === "markAsClosed") {
      this.setState({ markAsClosed: !this.state.markAsClosed })
    } else {
      this.setState({ ...this.state, [prop]: event.target.value });
    }
  };


  async componentDidMount() {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        if (isLoggedIn(this.props.navigation)) {
          autoLogOut();
        }
      });
    });
    const authToken = isLoggedIn(this.props.navigation);
    if (typeof authToken === "string") {
      localStorage.setItem('previousPath', window.location.pathname)
      this.authToken = authToken;
      this.fetchQueryDetail();
      this.fetchListOfEmployees();
      this.getAllAdditionalFilesFolders();
      this.getAllRemarks();
      this.getAllReplies();
      this.getStatusList();
      this.getRoleList();
      this.setSelectDocuments();
      this.getQueryReplies();
      this.getTicketHistory();
    }
    localStorage.getItem("userInfo") && this.setState({ isCustomerExcellence: JSON.parse(localStorage.getItem("userInfo") || "").attributes.role })
  }

  fetchListOfEmployees = () => {
    const fetchEmployees = apiCall({
      url: configJSON.getEmployeesListApiEndpoint,
      header: getHeaders({ token: this.authToken }),
      httpBody: {},
      httpMethod: configJSON.getEmployeesListApiMethod
    });

    this.getEmployeesListApiCallId = fetchEmployees.messageId;
    runEngine.sendMessage(fetchEmployees.id, fetchEmployees);
  }

  fetchQueryDetail = () => {
    this.setState({ isLoading: true })
    const queryId = this.props.navigation.getParam('id');
    const fetchQueryMessage = apiCall({
      url: configJSON.getQueryDetailApiEndpoint + queryId,
      header: getHeaders({ token: this.authToken }),
      httpBody: {},
      httpMethod: configJSON.getQueryDetailApiMethod
    });

    this.getQueryDetailApiCallId = fetchQueryMessage.messageId;
    runEngine.sendMessage(fetchQueryMessage.id, fetchQueryMessage);
  }

  submitGrantAccess = () => {
    const grantAccessCall = apiCall({
      url: configJSON.createGrantAcessApiEndpoint,
      header: getHeaders({ token: this.authToken }),
      httpBody: {},
      httpMethod: configJSON.createGrantAccessApiMethod
    });

    this.createGrantAccessApiCallId = grantAccessCall.messageId;
    runEngine.sendMessage(grantAccessCall.id, grantAccessCall);
  }


  submitTransferQuery = () => {
    const ticketId = this.props.navigation.getParam('id');
    if (this.state.selectedEmployeeToTransfer === 0) {
      this.setState({ isRequiredTeamTransfer: true })
      return false;
    } else {
      this.setState({ isRequiredTeamTransfer: false })
    }
    if (this.state.selectedteamMember === 0) {
      this.setState({ isRequiredMemberTransfer: true })
      return false;
    } else {
      this.setState({ isRequiredMemberTransfer: false })
    }
    let httpBody = {};
    if (this.state.selectedteamMember === 'All') {
      this.setState({ isLoading: true, isactionPopUp: false })
      httpBody = {
        ticket_id: ticketId,
        role_id: this.state.selectedEmployeeToTransfer,
        remarks: this.state.customerReply,
        team_member: this.state.selectedteamMember,
        comments: this.state.transferTheQueryComment,
        pending_with_id: this.state.pendingWith
      }
    } else {
      this.setState({ isLoading: true, isactionPopUp: false })
      httpBody = {
        ticket_id: ticketId,
        role_id: this.state.selectedEmployeeToTransfer,
        remarks: this.state.customerReply,
        team_member_id: this.state.selectedteamMember,
        comments: this.state.transferTheQueryComment,
        pending_with_id: this.state.pendingWith
      }
    }

    const transferQueryCall = apiCall({
      url: configJSON.createTransferQueryApiEndpoint +
        `?status=${this.state.statusId}`,
      header: getHeaders({ token: this.authToken }),
      httpBody: httpBody,
      httpMethod: configJSON.createTransferQueryApiMethod
    });

    this.createTransferQueryApiCallId = transferQueryCall.messageId;
    runEngine.sendMessage(transferQueryCall.id, transferQueryCall);
  }

  submitReplyToCustomer = async () => {
    const newImagesPromises = []
    for (let row of this.state.uploadedFiles) {
      newImagesPromises.push(this.fileToDataUri(row))
    }
    const fileBase64 = await Promise.all(newImagesPromises)
    const ticketId = this.props.navigation.getParam('id');
    const pattern = /^[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~\d]*$/g;
    const res = pattern.test(this.state.customerReply);
    if (res) {
      if (res && this.state.customerReply.trim().length === 0) {
        this.setState({
          isReplyToCusomer: true,
          isReplyToCusomerNumSc: false
        })
        return false;
      }
      else if (res && this.state.customerReply.trim().length !== 0) {
        this.setState({
          isReplyToCusomerNumSc: true,
          isReplyToCusomer: false,
        })
        return false;
      }
      else {
        return false;
      }
    } else {
      this.setState({
        isReplyToCusomer: false,
        isReplyToCusomerNumSc: false
      })
    }
    this.setState({ isLoading: true, isactionPopUp: false })
    const replyToCustomerCall = apiCall({
      url: configJSON.createReplyCustomerApiEndpoint +
        `?status=${this.state.statusId}`,
      header: getHeaders({ token: this.authToken }),
      httpBody: {
        ticket_id: ticketId,
        reply_to_customer: this.state.customerReply,
        mark_as_closed: this.state.markAsClosed,
        upload_image: fileBase64,
        comments: this.state.replyToCutsomerCommet
      },
      httpMethod: configJSON.createReplyCustomerApiMethod
    });

    this.createReplyToCustomerApiCallId = replyToCustomerCall.messageId;
    runEngine.sendMessage(replyToCustomerCall.id, replyToCustomerCall);
  }

  handleDocumentUpload = async (e: any) => {
    const uploadedFiles = [].slice.call(e.target.files);
    let appendUpload = uploadedFiles.length > 1 ? [...uploadedFiles] : [...this.state.uploadedFiles, e.target.files[0]]
    appendUpload.forEach((file: File) => {
      return {
        name: file.name
      }
    })

    this.setState({
      uploadedFiles: appendUpload
    });
  }

  removeFile = (fileName: string) => {
    this.setState({
      uploadedFiles: this.state.uploadedFiles.filter(file => file.name !== fileName),
      uploadedFilesBase64: this.state.uploadedFilesBase64.filter(file => file.name !== fileName)
    })
  }

  fileToDataUri = (image: any) => {
    return new Promise((res) => {
      const reader = new FileReader();
      const { name, } = image;
      reader.addEventListener('load', () => {
        res({ upload: reader.result, file_name: name })
      });
      reader.readAsDataURL(image);
    })
  }

  getAllAdditionalFilesFolders = () => {

    const getAllFolders = apiCall({
      url: configJSON.getFolderListingsApiEndpoint,
      header: getHeaders({ token: this.authToken }),
      httpBody: {},
      httpMethod: configJSON.getFolderListingApiMethod
    });

    this.getAllAdditionalFilesFolderApiCallId = getAllFolders.messageId;
    runEngine.sendMessage(getAllFolders.id, getAllFolders);
  }

  getAllRemarks = () => {
    const allRemarks = apiCall({
      httpMethod: configJSON.getAllRemarksApiMethod,
      header: getHeaders({ token: this.authToken }),
      httpBody: {},
      url: configJSON.getAllRemarksApiEndpoint + `?ticket_id=${38}`
    });

    this.getAllRemarksApiCallId = allRemarks.messageId;
    runEngine.sendMessage(allRemarks.id, allRemarks);
  }

  getAllReplies = () => {
    const ticketId = this.props.navigation.getParam('id');
    const allReplies = apiCall({
      httpMethod: configJSON.getAllRepliesApiMethod,
      header: getHeaders({ token: this.authToken }),
      httpBody: {},
      url: configJSON.getAllRepliesApiEndpoint + `?ticket_id=${ticketId}`
    });

    this.getAllRepliesApiCallId = allReplies.messageId;
    runEngine.sendMessage(allReplies.id, allReplies);
  }

  getStatusList = () => {
    const allReplies = apiCall({
      httpMethod: configJSON.getAllRepliesApiMethod,
      header: getHeaders({ token: this.authToken }),
      httpBody: {},
      url: configJSON.getstatusListAPIEndPoint
    });

    this.getAllStatusApiCallId = allReplies.messageId;
    runEngine.sendMessage(allReplies.id, allReplies);
  };

  getRoleList = () => {
    const allReplies = apiCall({
      httpMethod: configJSON.getAllRepliesApiMethod,
      header: getHeaders({ token: this.authToken }),
      httpBody: {},
      url: configJSON.getRoleListingAPIEndPoint
    });

    this.getAllRoleApiCallId = allReplies.messageId;
    runEngine.sendMessage(allReplies.id, allReplies);
  }

  getMemberList = (role_id: any) => {
    const allReplies = apiCall({
      httpMethod: configJSON.getAllRepliesApiMethod,
      header: getHeaders({ token: this.authToken }),
      httpBody: {},
      url: configJSON.teamMembersAPIEndPoint + `?role_id=${role_id}`
    });

    this.getTeamMembersApiCallId = allReplies.messageId;
    runEngine.sendMessage(allReplies.id, allReplies);
  }

  getAllDocumentSelectionBucketsListing = () => {
    const allBuckets = apiCall({
      httpMethod: configJSON.getDocumentSelectionBucketListingApiMethod,
      header: getHeaders({ token: this.authToken }),
      httpBody: {},
      url: configJSON.getDocumentSelectionBucketListingApiEndpoint + `?ticket_id=${this.state.queryData?.id}`
    });

    this.getAllDocumentBucketListingApiCallId = allBuckets.messageId;
    runEngine.sendMessage(allBuckets.id, allBuckets);
  }

  getAllFilesOfSelectedBucket = () => {
    const allBucketFiles = apiCall({
      httpMethod: configJSON.getAllSelectedBucketFilesApiMethod,
      header: getHeaders({ token: this.authToken }),
      httpBody: {},
      url: configJSON.getAllSelectedBucketFilesApiEndpoint + `?catalogue_id=${this.state.queryData?.product_id}&id=${this.state.selectedProductBucket.product_document_id}&ticket_id=${this.state.queryData?.id}`
    });

    this.getAllSelectedBucketFilesApiCallId = allBucketFiles.messageId;
    runEngine.sendMessage(allBucketFiles.id, allBucketFiles);
  }

  getAllFilesOfSelectedCommonFolder = () => {
    const allCommonFolderFiles = apiCall({
      httpMethod: configJSON.getAllCommonFolderFilesApiMethod,
      header: getHeaders({ token: this.authToken }),
      httpBody: {},
      url: configJSON.getAllCommonFolderFilesApiEndpoint + `?common_folder_id=${this.state.selectedCommonFolder.id}`
    });

    this.getAllSelectedCommonFolderFilesApiCallId = allCommonFolderFiles.messageId;
    runEngine.sendMessage(allCommonFolderFiles.id, allCommonFolderFiles);

  }

  getAllFilesOfSelectedInvoiceFolder = () => {
    const allInvoiceFolderFiles = apiCall({
      httpMethod: configJSON.getAllInoiceFolderFilesApiMethod,
      header: getHeaders({ token: this.authToken }),
      httpBody: {},
      url: configJSON.getAllInvoiceFolderFilesApiEndpoint + `?invoice_id=${this.state.selectedInvoiceFolder.id}`
    });

    this.getAllSelectedInvoiceFolderFilesApiCallId = allInvoiceFolderFiles.messageId;
    runEngine.sendMessage(allInvoiceFolderFiles.id, allInvoiceFolderFiles);

  }

  handleProductBucketFileSelectionGrantAccess = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const bucketFiles = [...this.state.productBucketFileListing];
    bucketFiles[index].grant_access = e?.target?.checked;
    if (e.target.checked === false) {
      bucketFiles[index].download_file = e.target.checked;
    }
    this.setState({
      productBucketFileListing: bucketFiles,
    }, () => {
      this.setUserResponse(FileSelected.BUCKET_FILE, bucketFiles);
    });

  }

  handleSelectAllViews = () => {
    this.setState({
      selectAllView: !this.state.selectAllView
    })
    let temp = [...this.state.productBucketFileListing]
    temp.forEach((data) => {
      data.grant_access = !this.state.selectAllView
    })
    this.setState({
      productBucketFileListing: temp
    });
    this.setUserResponse(FileSelected.BUCKET_FILE, temp);
  }

  handleCheckctAllViews = () => {
    let tempBool = true;
    let temp = [...this.state.productBucketFileListing]
    temp.forEach((data) => {
      if (data.grant_access === false) {
        tempBool = false;
      }
    })
    return tempBool;
  }

  handleProductBucketFileSelectionDownloadPermission = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const bucketFiles = [...this.state.productBucketFileListing];
    bucketFiles[index].download_file = e.target.checked;
    if (e.target.checked === true) {
      bucketFiles[index].grant_access = e?.target?.checked;
    }
    this.setState({
      productBucketFileListing: bucketFiles
    });
    this.setUserResponse(FileSelected.BUCKET_FILE, bucketFiles);
  }

  handleCommonFileSelectionGrantAccess = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const commonFiles = [...this.state.selectedCommonFolderFiles];
    commonFiles[index].grant_access = e?.target?.checked;
    if (e.target.checked === false) {
      commonFiles[index].download_file = e.target.checked;
    }
    this.setState({
      selectedCommonFolderFiles: commonFiles
    });
    this.setUserResponse(FileSelected.COMMON_FILE, commonFiles);

  }

  handleCommonFileSelectionDownloadPermission = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const commonFiles = [...this.state.selectedCommonFolderFiles];
    commonFiles[index].download_file = e.target.checked;
    if (e.target.checked === true) {
      commonFiles[index].grant_access = e?.target?.checked;
    }
    this.setState({
      selectedCommonFolderFiles: commonFiles
    });
    this.setUserResponse(FileSelected.COMMON_FILE, commonFiles);
  }

  handleInvoiceFileSelectionGrantAccess = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const invoiceFiles = [...this.state.selectedInvoiceFolderFiles];
    invoiceFiles[index].grant_access = e?.target?.checked;
    this.setState({
      selectedInvoiceFolderFiles: invoiceFiles,
      selectedInvoiceFiles: invoiceFiles
    });
    this.setUserResponse(FileSelected.INVOICE_FILE, invoiceFiles);

  }

  handleInvoiceFileSelectionDownloadPermission = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const invoiceFiles = [...this.state.selectedInvoiceFolderFiles];
    invoiceFiles[index].download_file = e.target.checked;
    if (e.target.checked === true) {
      invoiceFiles[index].grant_access = e?.target?.checked;
    }
    this.setState({
      selectedInvoiceFolderFiles: invoiceFiles,
      selectedInvoiceFiles: invoiceFiles
    });
    this.setUserResponse(FileSelected.INVOICE_FILE, invoiceFiles);
  }

  setUserResponse = (fileType: FileSelected, files: any) => {
    const userSelectedFiles = [...this.state.selectedBucketFiles];
    const userSelectedCommonFiles = [...this.state.selectedCommonFiles];
    const userSelectedInvoiceFiles = [...this.state.selectedInvoiceFiles];
    const requestedFiles = [...files];
    switch (fileType) {
      case FileSelected.BUCKET_FILE:
        requestedFiles.forEach((file: IProductBucketFileListing, index: number) => {
          const userFileIndex = userSelectedFiles.findIndex((userFile: IProductBucketFileListing) => file.file_id === userFile.file_id && file.product_document_id === userFile.product_document_id);
          if (userFileIndex === -1) {
            userSelectedFiles.push(file);
          } else {
            userSelectedFiles[userFileIndex] = file
          }

        });

        this.setState({
          selectedBucketFiles: userSelectedFiles
        })
        break;
      case FileSelected.COMMON_FILE:

        requestedFiles.forEach((file: ICommonFolderFile, index: number) => {
          const userFileIndex = userSelectedCommonFiles.findIndex((userFile: ICommonFolderFile) => file.file_id === userFile.file_id && file.common_folder_id === userFile.common_folder_id);
          if (userFileIndex === -1) {
            userSelectedCommonFiles.push(file);
          } else {
            userSelectedCommonFiles[userFileIndex] = file
          }
        });
        this.setState({
          selectedCommonFiles: userSelectedCommonFiles
        })
        break;
      case FileSelected.INVOICE_FILE:
        requestedFiles.forEach((file: IInvoiceFolderFile, index: number) => {
          const userFileIndex = userSelectedInvoiceFiles.findIndex((userFile: IInvoiceFolderFile) => file.file_id === userFile.file_id && file.invoice_id === userFile.invoice_id);
          if (userFileIndex === -1) {
            userSelectedInvoiceFiles.push(file);
          } else {
            userSelectedInvoiceFiles[userFileIndex] = file
          }
        });

        this.setState({
          selectedInvoiceFiles: userSelectedInvoiceFiles
        })
        break;
    }
  };

  SubmitGrantAccess = () => {
    const ticketId = this.props.navigation.getParam('id');
    this.setState({ isLoading: true, isactionPopUp: false });
    const httpBody = {
      grant_access_folders_attributes: [...this.state.selectedBucketFiles.map((file: IProductBucketFileListing) => {
        return {
          product_document_file_id: file.file_id,
          download_file: file.download_file || false,
          grant_permission: file.grant_access || false
        }
      }), ...this.state.selectedCommonFiles.map((file: ICommonFolderFile) => {
        return {
          common_folder_file_id: file.file_id,
          download_file: file.download_file || false,
          grant_permission: file.grant_access || false
        }
      }), ...this.state.selectedInvoiceFiles.map((file: IInvoiceFolderFile) => {
        return {
          invoice_file_id: file.file_id,
          download_file: file.download_file || false,
          grant_permission: file.grant_access || false
        }
      })],
      comments: this.state.grantAccessComment
    };
    const submitAccess = apiCall({
      url: configJSON.grantAccessToTicketApiEndpoint +
        `?ticket_id=${ticketId} 
      &status=${this.state.statusId}`,
      header: getHeaders({ token: this.authToken }),
      httpBody: httpBody,
      httpMethod: configJSON.grantAccessToTicketApiMethod
    });

    this.submitGrantAccessApiCallId = submitAccess.messageId;
    runEngine.sendMessage(submitAccess.id, submitAccess);
  };

  handleGrantAccessStatusChange = (event: any, value: any) => {
    this.setState({
      statusId: value.props.value
    })
  };

  handleToolTip = (event: any, text: any) => {
    this.setState({ openToolTip: !this.state.openToolTip });
    this.setState({ anchorEl: event.currentTarget });
    this.setState({ popUPText: text });
  };

  handleTicketDetailsClick = () => {
    if (this.props.navigation) {
      this.props.navigation.navigate("TicketDetails", { id: this.props.navigation.getParam('id') })
    }
  }

  handleGrantAccessComment = (event: any,) => {
    this.setState({
      grantAccessComment: 'alue.props.value'
    })
  };

  closeActionPopUp = () => {
    this.setState({ isactionPopUp: false });
  };

  handlePendingWithChange = (event: any, value: any) => {
    this.setState({
      pendingWith: value.props.value
    })
  };

  navigateToDetailsPage = () => {
    this.props.navigation.navigate("TicketDetails");
  }

  handleSubmittedByClick = (req: ISubmittedBy) => {
    if (req.isContactMaster) {
      this.props.navigation.navigate("CustomerContactEdit", { id: req.masterId })
      localStorage.setItem('fromToContacts', "fromUserName");
      localStorage.setItem("FromTicket", "ticketDetail");
    } else {
      localStorage.setItem("FromTicket", "ticketDetail");
      this.props.navigation.navigate("CustomerUpdate", { id: req.masterId })
    }
  }

  downloadAttachmentFile = (attachment: IAttachements) => {
    axios({url: attachment.doc_file, method: 'GET', responseType: 'blob'})
      .then(response => {
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', attachment.file_name,);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    })
  }

  downloadAPIAttachmentFile = (attachment: IAPIAttachements) => {
    axios({url: attachment.api_attachment, method: 'GET', responseType: 'blob'})
      .then(response => {
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        if (attachment.api_file_name !== undefined) {
          link.setAttribute('download', attachment.api_file_name,);
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    })
  }

  showHistory = () => {
    this.setState({
      isHistoryPopUp: !this.state.isHistoryPopUp
    })
  }

  showComments = () => {
    this.setState({
      isCommentsPopUp: !this.state.isCommentsPopUp
    })
  }

  getQueryReplies = () => {
    const ticketId = this.props.navigation.getParam('id');
    this.setState({ isLoading: true });
    const header = getHeaders({ token: this.authToken });
    const fetchQueryReplies = apiCall({
      url: configJSON.getMyQueryRepliesApiEndpoint + `?ticket_id=${ticketId}`,
      header,
      httpBody: {},
      httpMethod: configJSON.getTicketDetailsApiMethod
    });

    this.getQueryRepliesApiCallId = fetchQueryReplies.messageId;
    runEngine.sendMessage(fetchQueryReplies.id, fetchQueryReplies);
  };

  getTicketHistory = () => {
    const ticketId = this.props.navigation.getParam('id');
    this.setState({ isLoading: true });
    const header = getHeaders({ token: this.authToken });
    const fetchQueryReplies = apiCall({
      url: configJSON.getTicketHistoryApiEndpoint + `?ticket_id=${ticketId}`,
      header,
      httpBody: {},
      httpMethod: configJSON.getTicketDetailsApiMethod
    });

    this.getTicketHistoryApiCallId = fetchQueryReplies.messageId;
    runEngine.sendMessage(fetchQueryReplies.id, fetchQueryReplies);
  }

  handleSeachDocument = (event: any) => {
    this.setState({
      ...this.state,
      searchDocValue: event.target.value
    })
    if (event.target.value.length >= 1) {
      this.setState({
        ...this.state,
        searchDocValue: event.target.value
      }, this.debouncedCallback)
    } else if (event.target.value.length === 0) {
      setTimeout(() => {
        this.getAllDocumentSelectionBucketsListing();
      }, 800)
    }
  };


  debounce = (func: any, wait: number = 700) => {
    let timer: any;
    return (...args: any[]) => {
      clearTimeout(timer)
      timer = setTimeout(() => func(...args), wait);
    };
  };

  duplicateCode = () => {
    const ticketId = this.props.navigation.getParam('id')
    const SearchDocuments = apiCall({
      header: getHeaders({ token: this.authToken }),
      httpMethod: configJSON.getAllSelectedBucketFilesApiMethod,
      httpBody: {},
      url: configJSON.bucketSearchApiEndPoint + `?ticket_id=${ticketId}&query=${this.state.searchDocValue}`
    });

    this.SearchDocumentsAPICallID = SearchDocuments.messageId;
    runEngine.sendMessage(SearchDocuments.id, SearchDocuments);
  }
  debouncedCallback = this.debounce(() => this.duplicateCode(), 700);

  onDocPreview = (doc_file: any) => {
    localStorage.setItem('fromPage', "QueryDetail")
    localStorage.setItem("doc_file", doc_file)
    window.open("/documentPreview", '_blank', 'noreferrer')
  }

  setPermissionForActionButton = (data: any) => {
    const userDetails = JSON.parse(localStorage.getItem("userInfo") ?? "")
    if (userDetails.id === data?.transfer_query_response?.data?.attributes?.team_member_ids || data?.is_transferred_ticket) {
      this.setState({
        isUserPerformAction: true
      })
    } else {
      this.setState({
        isUserPerformAction: false
      })
    }
  }

  reOpenTicket = () => {
    this.setState({isLoading: false})
    const ticketId = this.props.navigation.getParam('id')
    const body = {
      data: {
        attributes: {
          status: 0
        }
      }
    }
    const ReopenTickets = apiCall({
      header: getHeaders({ token: this.authToken }),
      httpMethod: configJSON.reOpenTicketApiMethodType,
      httpBody: body,
      url: configJSON.reopenTicketAPIEndPoint + `${ticketId}`
    });

    this.ReopenTicketsAPICallID = ReopenTickets.messageId;
    runEngine.sendMessage(ReopenTickets.id, ReopenTickets);
  }

  checkForSubmmittedBy = (submitter: any) => {
    if (submitter.masterId !== null) {
      this.handleSubmittedByClick(submitter)
    }
  }

  proceedToDownloadAPIDoc = () => {
    if (this.state.queryData?.api_attachment !== undefined) {
      this.downloadAPIAttachmentFile(this.state.queryData?.api_attachment)
    }
  }

  proceedForBucketList = (data: any) => {
    data?.files_present && this.handleProductBucketListingClick(data)
  }

  clearPopUp = () => {
    this.setActionPopUp()
    this.getAllFilesOfSelectedBucket()
  }
  // Customizable Area End

}
