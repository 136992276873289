import React from "react";

// Customizable Area Start
import {
    Container,
    InputLabel,
    Typography,
    Grid,
    TextField,
} from "@material-ui/core";
import {
    withStyles,
} from "@material-ui/core/styles";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { AppHeader } from "../../../components/src/AppHeader.web";
import AppFooter from "../../../components/src/AppFooter.web";
import InvoiceDetailsController, { Props } from "./InvoiceDetailsController";
import Loader from "../../../components/src/Loader.web";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
// Customizable Area End


export class InvoiceDetails extends InvoiceDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    InvoiceDetails() {
        const { classes } = this.props

        return (
            <>
                {!this.state.is_loading &&
                    <form
                        data-testid='formTestId'
                        style={{ marginTop: "30px", marginBottom: "20px" }}
                    >
                        <Grid container spacing={7} alignItems="center" >
                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="invoice" style={webStyle.inputLabel}>
                                    {this.state.contactNameLabel}
                                </InputLabel>
                                <InputField
                                    id="account_id"
                                    variant="outlined"
                                    name="account_id"
                                    value={this.state.account_id}
                                    fullWidth
                                    disabled
                                />
                            </Grid>

                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="catalogue_id" style={{ ...webStyle.inputLabel, paddingBottom: '10px' }}>
                                    {this.state.productNameLabel}
                                </InputLabel>
                                <InputField
                                    id="catalogue_id"
                                    variant="outlined"
                                    name="catalogue_id"
                                    value={this.state.catalogue_id}
                                    fullWidth
                                    disabled
                                />
                            </Grid>

                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="invoice" style={webStyle.inputLabel}>
                                    {this.state.invoiceLabel}
                                </InputLabel>
                                <InputField
                                    id="invoice"
                                    variant="outlined"
                                    name="invoice"
                                    value={this.state.invoice}
                                    fullWidth
                                    disabled
                                />
                            </Grid>

                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="invoice_date" style={webStyle.inputLabel}>
                                    {this.state.invoiceDateLabel}
                                </InputLabel>
                                <InputField
                                    id="invoice_date"
                                    name="invoice_date"
                                    variant="outlined"
                                    type="date"
                                    value={this.state.invoice_date}
                                    fullWidth
                                    disabled
                                />
                            </Grid>

                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="invoice_amount" style={webStyle.inputLabel}>
                                    {this.state.invoiceAmountLabel}
                                </InputLabel>
                                <InputField
                                    id="invoice_amount"
                                    variant="outlined"
                                    name="invoice_amount"
                                    value={this.state.invoice_amount}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="account_currency_id" style={webStyle.inputLabel}>
                                    {this.state.invoiceCurrencyLabel}
                                </InputLabel>
                                <InputField
                                    id="account_currency_id"
                                    variant="outlined"
                                    name="account_currency_id"
                                    value={this.state.account_currency_id}
                                    fullWidth
                                    disabled
                                />
                            </Grid>

                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="p_o" style={webStyle.inputLabel}>
                                    {this.state.invoicePOLabel}
                                </InputLabel>
                                <InputField
                                    id="p_o"
                                    variant="outlined"
                                    name="p_o"
                                    value={this.state.p_o}
                                    fullWidth
                                    disabled
                                />
                            </Grid>


                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="due_date" style={webStyle.inputLabel}>
                                    {this.state.invoiceDueDateLabel}
                                </InputLabel>
                                <InputField
                                    id="due_date"
                                    variant="outlined"
                                    name="due_date"
                                    type="date"
                                    value={this.state.due_date}
                                    fullWidth
                                    disabled
                                />
                            </Grid>


                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="payment_term_id" style={{ ...webStyle.inputLabel, paddingBottom: '10px' }}>
                                    {this.state.invoicePaymentTermsLabel}
                                </InputLabel>
                                <InputField
                                    id="payment_term_id"
                                    variant="outlined"
                                    name="payment_term_id"
                                    value={this.state.payment_term_id}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="inco_term_id" style={{ ...webStyle.inputLabel, paddingBottom: '10px' }}>
                                    {this.state.invoiceIncoTermsLabel}
                                </InputLabel>
                                <InputField
                                    id="inco_term_id"
                                    variant="outlined"
                                    name="inco_term_id"
                                    value={this.state.inco_term_id}
                                    fullWidth
                                    disabled
                                />
                            </Grid>

                            <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
                                <InputLabel htmlFor="payment_status" style={{ ...webStyle.inputLabel, paddingBottom: '10px' }}>
                                    {this.state.invoicePaymentStatusLabel}
                                </InputLabel>
                                <InputField
                                    id="payment_status"
                                    variant="outlined"
                                    name="payment_status"
                                    value={this.state.payment_status}
                                    fullWidth
                                    disabled
                                />

                            </Grid>
                            <Grid item md={12} lg={12} sm={12} xs={12} style={{
                                paddingBottom: '5px',
                                paddingTop: '15px',
                                position: 'relative',
                            }}>
                                <Typography component="label">
                                    <AttachFileIcon
                                        style={webStyle.attachmentIcon}
                                    >
                                    </AttachFileIcon>
                                    <span style={webStyle.attachmentText}>Attachment</span>
                                    <input
                                        type="file"
                                        name="attachment"
                                        id="attachment"
                                        value=""
                                        hidden
                                        accept="application/pdf"
                                        //@ts-ignore
                                        disabled
                                    />
                                </Typography>
                            </Grid>

                            <ul>
                                {this.state?.getAttachment && <Grid container spacing={2} style={{ alignItems: "center" }}>
                                    <li data-testid="docNameDawonlodClick" style={webStyle.docName} onClick={() => this.downloadAPIAttachmentFile(this.state.getAttachment)}>{this.state.getAttachment.file_name}</li>
                                </Grid>}
                            </ul>
                        </Grid>
                    </form>
                }
            </>
        );
    }

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <AppHeader navigation={this.props.navigation} />
                <MainContainer>
                    <Loader loading={this.state.is_loading} />
                    <div style={{ display: "flex" }}>
                        <KeyboardBackspaceIcon
                            style={{
                                ...webStyle.cardButtonBack,
                            }}
                            fontSize="large"
                            onClick={() => this.props.navigation.goBack()}
                            data-testid="backSpaceBtn"
                        />
                        <Typography style={webStyle.detailTitle}>
                            {this.state.invoiceAddLabel}
                        </Typography>
                    </div>
                    <form>
                        {this.InvoiceDetails()}
                    </form>
                </MainContainer>
                <AppFooter isLoading={this.state.is_loading} />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle = {
    cardButtonBack: {
        color: "rgb(4, 60, 116)",
        cursor: 'pointer',
    },
    detailTitle: {
        color: "rgba(33, 43, 54, 1)",
        fontFamily: "SFProDisplay",
        fontWeight: 700,
        fontSize: "18px",
        marginLeft: "15px",
        marginTop: '5px',
        textDecoration: 'underline'
    },
    inputLabel: {
        fontSize: "14px",
        fontFamily: "SFProDisplay",
        fontWeight: 700,
        color: "#1A3B71",
        paddingBottom: "5px",
    },
    select: {
        height: "37px",
        borderRadius: "8px",
        marginTop: '5px',
    },
    grids: {
        paddingTop: '18px',
        paddingBottom: '0px'
    },
    attachmentIcon: {
        marginBottom: '-4px',
        marginRight: '6px',
        fill: "rgba(4, 60, 116, 1)",
        marginLeft: '-6px',
        fontSize: '16px',
        cursor: 'pointer'
    },
    attachmentText: {
        fontSize: '13px',
        color: 'rgba(4, 60, 116, 1)',
        fontFamily: 'SFPRODisplay',
        fontWeight: 700,
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    docName: {
        fontSize: '14px',
        color: 'rgb(69, 158, 255)',
        fontFamily: 'SFPRODisplay',
        fontWeight: 700,
        margin: '0px 5px 0px 0px',
        cursor: 'pointer',
        padding: "0 10px",
        display: "flex",
        textDecoration: "underline",
        textUnderlinePosition: 'under',
    },
};

const InputField: any = withStyles({
    root: {
        background: "inherite",
        margin: "5px 0px 0px 0px",
        "& ::placeholder": {
            color: 'rgba(218, 224, 241, 1)',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
            fontSize: '14px',
            opacity: 1,
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(214, 214, 214, 1)",
        },
        "& .MuiInputBase-input": {
            height: '15px',
        },
        "& .MuiOutlinedInput-input": {
            padding: `12px 14px !important`
        },
        "& .MuiOutlinedInput-root": {
            fontFamily: "SFProDisplay",
            fontWeight: 400,
            backgroundColor: "rgba(255, 255, 255, 1)",
            fontSize: "14px",
            "& fieldset": {
                height: '40px',
                borderColor: "rgba(218, 224, 241, 1)",
                borderRadius: "8px",
                borderWidth: 1,
            },
            ".Mui-focused": {
                borderColor: "rgba(218, 224, 241, 1)",
            },
        },
        "& .MuiFormHelperText-contained": {
            marginLeft: "0px !important",
        },
        "& .MuiInputBase-input.Mui-disabled": {
            background: "#F1F4FA",
            borderRadius: "8px",
            height: 13,
            color: 'rgba(106, 116, 165, 1)',
            fontSize: '14px',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
        },
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(218, 224, 241, 1)",
            borderWidth: '1px'
        }
    },
})(TextField);

const MainContainer: any = withStyles({
    root: {
        "& ::placeholder": {
            color: 'rgba(218, 224, 241, 1)',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
            fontSize: '16px',
            opacity: 1,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: `rgba(218, 224, 241, 1) !important`,
        },
        "& .css-yk16xz-control": {
            borderRadius: '8px',
            height: '43px',
            borderColor: 'rgba(218, 224, 241, 1)',
            marginTop: '4px',
        },
        padding: "24px 36px 24px 48px",
        "& .css-view-1dbjc4n": {
            marginBottom: `0px !important`,
        },
        "& .css-1hb7zxy-IndicatorsContainer": {
            height: '36px',
        },
        "& .css-1fhf3k1-control": {
            backgroundColor: `#F1F4FA !important`,
            borderColor: "rgba(218, 224, 241, 1)",
            borderRadius: '8px',
            color: 'rgba(106, 116, 165, 1)',
            fontSize: '16px',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
        },
        "@media (max-width: 600px)": {
            padding: "24px 0px 24px 0px",
            "& .MuiBox-root-8": {
                padding: "24px 0px 24px 0px",
            },
            "& ..MuiBox-root-30": {
                padding: "24px 0px 24px 0px",
            },
        },
    },
})(Container);

const styles = () => ({
    selectPlaceholder: {
        backgroundColor: '#fff !important',
        '&.Mui-disabled': {
            opacity: 1
        }
    }
})

export default withStyles(styles)(InvoiceDetails)

// Customizable Area End
