import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  DialogContent,
  DialogActions,
  Dialog
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

interface IProps {
  isOpen: boolean;
  accStatus: any;
  handleActiveYes: any;
  handleActiveNo: any;
  handleCloseIcon: any;
  handleDeactiveNoClick: any;
  handleDeactiveYesClick: any;
}

const SwitchConfirmationPopup = ({ isOpen, accStatus, handleActiveYes, handleActiveNo, handleCloseIcon, handleDeactiveYesClick, handleDeactiveNoClick }: IProps) => {
  return isOpen ? (
    <>
    <QueryDialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
    <span onClick={handleCloseIcon} style={{textAlign:"end",margin:'10px 5px 0px 0px'}} data-testid="closeicon">
       <CloseIcon style={webStyle.CloseIcon} />
    </span>
      <DialogContent style={{ padding:'30px 50px 40px 25px' }}>
        <Typography style={webStyle.queryPopUpText}>
          {accStatus ? "Are you sure you want to deactivate this contact?" : "Are you sure you want to activate this contact?"}
        </Typography>
      </DialogContent>
     {accStatus === true ? (<DialogActions style={{ padding: '15px 0px' }}>
        <SaveButton 
          onClick={handleDeactiveYesClick}
          style={{color:"#043c74",backgroundColor:"#ffffff",border: "solid 1px #043c74"}}
          data-testid="deactiveyes"
        >
         No, don't deactivate 
        </SaveButton>
        <SaveButton 
          onClick={handleActiveNo}
          data-testid="activeno"
        >
         Yes, deactivate
        </SaveButton>
      </DialogActions>):(<DialogActions style={{ padding: '15px 0px' }}>
        <SaveButton 
          onClick={handleDeactiveNoClick}
          data-testid="deactiveno"
          style={{color:"#043c74",backgroundColor:"#ffffff",border: "solid 1px #043c74"}}
        >
        No, don't activate
        </SaveButton>
        <SaveButton 
          onClick={handleActiveYes}
          data-testid="activeyes"
        >
         Yes, activate
        </SaveButton>
      </DialogActions>)}
      
    </QueryDialog>
    </>
  ) : <React.Fragment></React.Fragment>
}

const webStyle = {
  queryPopUpText: {
    color: '#141414',
    fontFamily: 'SFProDisplay',
    fontWeight: 500,
    fontSize: '20px',
    margin: '0px 15px',
    display:"flex",
    justifyContent: "center",
  },
  CloseIcon:{
    color:"#043c74",
    backgroundColor:"#dae0f1",
    borderRadius:"12px",
    fontSize:"20px",
    padding:"2px",
    cursor: 'pointer'
  }
};

export const SaveButton:any = withStyles({
  root: {
    color: "#ffffff",
    backgroundColor: "#043c74",
    height: "56px",
    width: 256,
    borderRadius: "8px",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "18px",
    marginBottom: "5px",
    marginLeft: "8px",
    boxShadow: "none",
    textTransform: "none",
    marginRight: '10px',
    "&:hover": {
      backgroundColor: "rgba(4, 60, 116, 1)",
      boxShadow: "none",
    },
    "@media (max-width: 600px)": {
      width: "100%",
      marginLeft: 0,
      height: "52px",
    },
  },
})(Button);

export const QueryDialog: any = withStyles({
  root: {
    "& .MuiDialog-paperScrollPaper": {
      minWidth: "fit-content",
      padding: "0px 10px 0px 10px",
      borderRadius:"12px",
    },
    "& .MuiDialogContent-root": {
      paddingLeft: "2px",
    },
    "& .MuiDialogActions-root": {
      alignItems: "center",
      justifyContent: "center",
      padding: "0px",
      paddingBottom: "8px",
    },
    "& .WithStyles(ForwardRef(Button))-root-10": {
      "@media (maxWidth: 600px)": {
        width: 300,
      },
    },
  },
})(Dialog);

export default SwitchConfirmationPopup