import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Icon7, Icon8, Icon9, Icon10, Icon11, Icon12, imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {
  apiCall,
  errorNotification,
  events,
  autoLogOut,
  isGuest
} from "../../../components/src/common";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface IApiData {
  id: string;
  type: string;
  attributes: {
    api_portfolio_block_five: string;
    api_portfolio_block_four: string;
    api_portfolio_block_one: string;
    api_portfolio_block_six: string;
    api_portfolio_block_three: string;
    api_portfolio_block_two: string;
    business_model_block_one: string;
    business_model_block_three: string;
    business_model_block_two: string;
    sub_guiding_principle: string;
    api_portfolio_block_seven: string;
    business_sub_model: string;
    enquiry: string;
    guiding_principle_block_one: string;
    guiding_principle_block_three: string;
    guiding_principle_block_two: string;
    banner_file_attached: string;
    banner: string;
    description: string;
    overview_file_attached: string;
    overview_file: string;
    overview: string;
    guiding_principle: string;
    business_model: string;
    api_strategy: string;
    overview_heading: string;
    path_heading: string;
    api_heading: string;
    guiding_principle_heading: string;
    business_model_heading: string;
    api_portfolio_heading: string;
  };
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  apiData: IApiData | null;
  loading: boolean;
  mediaConditionText: string;
  condition1: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class APIViewController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getApiDataApiCallId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.doButtonPressed = this.doButtonPressed.bind(this);
    this.setInputValue = this.setInputValue.bind(this);
    this.fetchApiData = this.fetchApiData.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      apiData: null,
      loading: false,
      mediaConditionText: configJSON.mediaConditionText,
      condition1: configJSON.condition1,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getApiDataApiCallId) {
          this.setState({loading: false,});
          if (responseJson.api) {
            this.setState({apiData: responseJson.api.data[0]});
          } else {
            errorNotification("Something went wrong");
          }
        }
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        if(!isGuest()) {
          autoLogOut();
        }
      });
    });
    this.fetchApiData();
  }

  fetchApiData = () => {
    const header = {
      "Content-Type": "application/json",
    };

    const apiData = apiCall({
      url: configJSON.getApiDataEndpoint,
      header: header,
      httpBody: {},
      httpMethod: configJSON.getApiDataApiMethod,
    });

    this.getApiDataApiCallId = apiData.messageId;
    runEngine.sendMessage(apiData.id, apiData);
    this.setState({loading: true});
  };
  // Customizable Area End
}
