import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { isEmployee, apiCall, errorNotification, handleUpdateAuthToken, getHeaders, getUserdetails, isAuthorisedUser, isLoggedIn, IUserdetails, events, autoLogOut } from "../../../components/src/common";
interface ICreateProductQuery {
  catalogue_id: number;
  date: string;
  query_type: string;
  end_market_id: any[];
  additional_emails_attributes: any[];
  comments: string;
  upload_attachments: any[];
}

interface ICreateSampleRequest {
  date: Date;
  catalogue_id: number;
  query_type: string;
  request_for_api_placed: string;
  api_quantity: string;
  api_uom_id: number;
  batch_requirement_id: number;
  customer_specification: string;
  pharmacopeia_grade: string;
  end_market_id: [];
  working_standard_quantity: string;
  working_standard_uom_id: number;
  impurity_quantity: string;
  impurity_uom_id: number;
  lab_standard_quantity: string;
  lab_standard_uom_id: number;
  bill_to_address: string;
  consignee_address: string;
  additional_email: string;
  comments: string;
  selectedSampleTypes: string[];
  pre_shipment_coa: string;
}

interface ICreateMeeting {
  firstName: string;
  lastName: string;
  company: string;
  emailId: string;
  phoneNumber: any;
  comments: string;
  phoneCountryCode: any;
  catalogue_id: string;
  account_id: string;
}

interface IProductDocuments {
  bucket: string;
  bucket_id: number;
  catalogue_id: number;
  confidential: boolean;
  product_document_id: number;
  rank: number;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  queryLabel: string;
  selectedTab: number;
  labelName: string;
  companyName: string;
  dateLabel: string;
  typeLabel: string;
  sampleLabel: string;
  productlabel: string;
  documentNameLabel: string;
  endmarketLabel: string;
  productList: any[];
  additionalEmailLabel: string;
  termsText: string;
  apiRequestlabel: string;
  apiQuantityLabel: string;
  apiUOMLabel: string;
  batchReqLabel: string;
  customerSpecificationLabel: string;
  pharmaRefLabel: string;
  standardQuantityLabel: string;
  standardUMO: string;
  impurityQuantityLabel: string;
  impurityUMOLabel: string;
  labQuantityLabel: string;
  labUMOLabel: string;
  billToAddressLabel: string;
  ConsigneeAddressLabel: string;
  firstNameLabel: string;
  commentLabel: string;
  lastNamelabel: string;
  companyNameLabel: string;
  EmailIdLabel: string;
  countrylabel: string;
  phoneNumLabel: string;
  meetingSetUpPopUP: boolean;
  sampleDate: string,
  preShippementLabel: string;
  statuslabel: string;
  date: string,
  codeList: any[],
  documentList: any[];
  documents: any[];
  APIUMO: any[];
  BatchRequirement: any[];
  shipmentValues: any[];
  sampleTypes: any[];
  additionalEmails: any[];
  requestSuccess: boolean;
  userName: string;
  productQuery: ICreateProductQuery;
  departmentList: any[];
  sampleRequest: ICreateSampleRequest;
  meeting: ICreateMeeting;
  privacyPolicyLabel: string;
  privacyPolicyText: string;
  termslabelText: string;
  termsLabel: string;
  productDetail: null | {
    id: string;
    type: string;
    attributes: {
      name: string;
      therapeutic_area: string;
      composition: string;
      api_technology: string;
      product_type: string;
      image: null | string;
      category: string;
      chemical_name: string;
      product_dmf: string;
      product_description: string | null;
      product_documents: {
        data: {
          attributes: {
            confidential: boolean;
            doc_file_name: string[];
            name: string;
            rank: null
          },
          id: string;
          type: string;
        }[];
      };
    };
  },
  isTermsAndCondition: boolean;
  endMarkets: any[];
  impurityUoms: any[];
  labStandardUoms: any[];
  workingStandardUoms: any[];
  uploadedFiles: any[];
  uploadedFilesBase64: any[];
  uploadedAPIFilesBase: any[];
  uploadedAPIFiles: any[];
  productDocuments: IProductDocuments[];
  selectedDocuments: any[];
  isLoading: boolean;
  isRobot: boolean;
  isDoc: any;
  isAPIAvailable: boolean;
  isAPIQuantityVisible: boolean;
  isApiUomAvailable: boolean;
  isBatchRequirements: boolean;
  isCustomerSpecificAvailable: boolean;
  isPahrmaRefAvailable: boolean;
  isEndMarketsAvailable: boolean;
  isWorkQuantityAvailable: boolean;
  isWorkUomAvailable: boolean;
  isImpurityQuantityAvailable: boolean;
  isimpurityUomAvailable: boolean;
  isLabQuantityAvailable: boolean;
  isLabUomAvailable: boolean;
  isImpuritySelected: boolean;
  isAPISelected: boolean;
  isWorkingStandardSelected: boolean;
  isLabStandardSelected: boolean;
  isGuest: boolean;
  isEmployees: boolean;
  isAttachment: boolean;
  selectedFile: any;
  isUploadError: boolean;
  isEmployeeLogin: boolean;
  product_name: any;
  product_id: any;
  IsCommentError: any;
  IsMarketError: any;
  sampleCommentError: any;
  QueryType: any;
  apiUomErrorMessage: any;
  uploadedAPIFile: any;
  consigneeAddressErrorMessage: any;
  billAddressErrorMassage: any;
  commentErrorSetMeeting: any;
  apiQuantity: any;
  batchRequirementMassage: any;
  pharmacopieaReference: any;
  customerSpecification: any;
  endMarketErrorMsg: any;
  impurityQuantity: any;
  impurityUomMassage: any;
  workingStandardQuantity: any;
  workingStandardUom: any;
  labStandardUomId: any;
  labStandardQuantity: any;
  requestForApiPlaced: any;
  termsConditionErrorSample: any;
  isTermsAndConditionSave: any,
  termsConditionErrorMeeting: any,
  productDetailError: any,
  productDetailQueryError: any,
  sampleRequestSubmitData: any,
  passCatalogueId: any,
  CaoAttachment: boolean;
  email : string;
  apiAttachmentRequired: boolean;
  phoneCountryCodeError: any;
  phoneError: any;
  additionalEmailError:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomerProductQueryController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getCustomerProfileApiCallId: string;
  authToken: string;
  getDepartmentListApiCallId: string;
  userDetails: IUserdetails;
  createProductQueryApiCallId: string;
  createSampleRequestApiCallId: string;
  createMeetingRequestApiCallId: string;
  getProductDetailsApiCallId: string;
  getDropdownDataApiCallId: string;
  getDocumentsListApiCallId: string;
  getProductListApiCalled: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handlePhoneCountryCode = this.handlePhoneCountryCode.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    // const signupSchema = Yup.object().shape({
    //   consignee_address: Yup.string().required('This field is required'),
    // });


    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      queryLabel: configJSON.queryLabel,
      selectedTab: 0,
      labelName: configJSON.labelName,
      companyName: configJSON.companyName,
      dateLabel: configJSON.dateLabel,
      typeLabel: configJSON.typeLabel,
      sampleLabel: configJSON.sampleLabel,
      productlabel: configJSON.productlabel,
      documentNameLabel: configJSON.documentNameLabel,
      endmarketLabel: configJSON.endmarketLabel,
      productList: [],
      additionalEmailLabel: configJSON.additionalEmailLabel,
      termsText: configJSON.termsText,
      apiRequestlabel: configJSON.apiRequestlabel,
      apiQuantityLabel: configJSON.apiQuantityLabel,
      apiUOMLabel: configJSON.apiUOMLabel,
      batchReqLabel: configJSON.batchReqLabel,
      customerSpecificationLabel: configJSON.customerSpecificationLabel,
      pharmaRefLabel: configJSON.pharmaRefLabel,
      standardQuantityLabel: configJSON.standardQuantityLabel,
      standardUMO: configJSON.standardUMO,
      impurityQuantityLabel: configJSON.impurityQuantityLabel,
      impurityUMOLabel: configJSON.impurityUMOLabel,
      labQuantityLabel: configJSON.labQuantityLabel,
      labUMOLabel: configJSON.labUMOLabel,
      billToAddressLabel: configJSON.billToAddressLabel,
      ConsigneeAddressLabel: configJSON.ConsigneeAddressLabel,
      commentLabel: configJSON.commentLabel,
      firstNameLabel: configJSON.firstNameLabel,
      lastNamelabel: configJSON.lastNamelabel,
      companyNameLabel: configJSON.companyNameLabel,
      EmailIdLabel: configJSON.EmailIdLabel,
      countrylabel: configJSON.countrylabel,
      phoneNumLabel: configJSON.phoneNumLabel,
      date: new Date().toISOString(),
      meetingSetUpPopUP: false,
      sampleDate: new Date().toISOString(),
      preShippementLabel: configJSON.preShippementLabel,
      statuslabel: configJSON.statuslabel,
      codeList: [],
      documents: [],
      additionalEmails: [],
      privacyPolicyLabel: configJSON.privacyPolicyLabel,
      privacyPolicyText: configJSON.privacyPolicyText,
      termslabelText: configJSON.termslabelText,
      termsLabel: configJSON.termsLabel,
      phoneError: "",
      APIUMO: [
        {
          id: 1,
          value: 'KG'
        },
        {
          id: 1,
          value: 'MG'
        },
        {
          id: 1,
          value: 'GM'
        }
      ],
      BatchRequirement: [
        {
          id: 1,
          value: 1,
        },
        {
          id: 2,
          value: 2,
        },
        {
          id: 3,
          value: 3,
        }
      ],
      shipmentValues: [
        {
          id: 1,
          value: 'Yes'
        },
        {
          id: 2,
          value: 'No'
        }
      ],
      sampleTypes: [
        {
          id: 1,
          value: 'API'
        },
        {
          id: 2,
          value: 'Impurity'
        },
        {
          id: 3,
          value: 'Working Standard'
        },
        {
          id: 4,
          value: ' Lab Standard'
        },
      ],
      documentList: [
        {
          value: 'doc 1',
          isChecked: false,
        },
        {
          value: 'doc 2',
          isChecked: false
        }
      ],
      endMarkets: [],
      impurityUoms: [],
      labStandardUoms: [],
      workingStandardUoms: [],
      productQuery: {
        catalogue_id: 1,
        date: new Date().toISOString(),
        query_type: "Product Query",
        end_market_id: [],
        additional_emails_attributes: [],
        comments: "",
        upload_attachments: []
      },
      departmentList: [],
      userName: "",
      sampleRequest: {
        date: new Date(),
        catalogue_id: 1,
        query_type: "",
        request_for_api_placed: "",
        api_quantity: "",
        api_uom_id: 0,
        batch_requirement_id: 0,
        customer_specification: "",
        pharmacopeia_grade: "",
        end_market_id: [],
        working_standard_quantity: "",
        working_standard_uom_id: 0,
        impurity_quantity: "",
        impurity_uom_id: 0,
        lab_standard_quantity: "",
        lab_standard_uom_id: 0,
        bill_to_address: "",
        consignee_address: "",
        additional_email: "",
        comments: "",
        selectedSampleTypes: [],
        pre_shipment_coa: ""
      },
      meeting: {
        firstName: "",
        lastName: "",
        company: "",
        emailId: "",
        phoneCountryCode: "91",
        phoneNumber: "",
        comments: "",
        catalogue_id: "1",
        account_id: "1"
      },
      requestSuccess: false,
      productDetail: null,
      isTermsAndCondition: false,
      uploadedFiles: [],
      uploadedFilesBase64: [],
      uploadedAPIFilesBase: [],
      uploadedAPIFiles: [],
      productDocuments: [],
      selectedDocuments: [],
      isLoading: false,
      isRobot: false,
      isDoc: [],
      isAPIAvailable: false,
      isAPIQuantityVisible: false,
      isApiUomAvailable: false,
      isBatchRequirements: false,
      isCustomerSpecificAvailable: false,
      isPahrmaRefAvailable: false,
      isEndMarketsAvailable: false,
      isWorkQuantityAvailable: false,
      isWorkUomAvailable: false,
      isImpurityQuantityAvailable: false,
      isimpurityUomAvailable: false,
      isLabQuantityAvailable: false,
      isLabUomAvailable: false,
      isImpuritySelected: false,
      isAPISelected: false,
      isWorkingStandardSelected: false,
      isLabStandardSelected: false,
      isGuest: false,
      isEmployees: false,
      isAttachment: false,
      selectedFile: "",
      isUploadError: false,
      isEmployeeLogin: false,
      product_name: "",
      product_id: "",
      IsCommentError: '',
      IsMarketError: '',
      QueryType: '',
      sampleCommentError: '',
      apiUomErrorMessage: '',
      uploadedAPIFile: '',
      consigneeAddressErrorMessage: '',
      billAddressErrorMassage: '',
      commentErrorSetMeeting: '',
      apiQuantity: '',
      batchRequirementMassage: '',
      pharmacopieaReference: '',
      customerSpecification: '',
      endMarketErrorMsg: '',
      impurityQuantity: '',
      impurityUomMassage: '',
      workingStandardQuantity: '',
      workingStandardUom: '',
      labStandardUomId: '',
      labStandardQuantity: '',
      requestForApiPlaced: '',
      termsConditionErrorSample: '',
      isTermsAndConditionSave: '',
      termsConditionErrorMeeting: '',
      productDetailError: '',
      productDetailQueryError: '',
      sampleRequestSubmitData: {},
      passCatalogueId: '',
      CaoAttachment: false,
      apiAttachmentRequired: false,
      phoneCountryCodeError: "",
      email : '',
      additionalEmailError: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.showAlert("Change Value", "From: " + this.state.txtSavedValue + " To: " + value);
      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId && responseJson) {
        this.checkUserAuthorised(responseJson)
        this.getCustomerProfileAPIResponse(apiRequestCallId, responseJson);
        this.getDepartmentListAPIResponse(apiRequestCallId, responseJson);
        this.createQueryAPIResponse(apiRequestCallId, responseJson);
        this.createSampleRequestAPIResponse(apiRequestCallId, responseJson);
        this.createSetUpMeetingAPIResponse(apiRequestCallId, responseJson);

        if (apiRequestCallId === this.getDocumentsListApiCallId) {
          this.setState({ productDocuments: responseJson.data });
          if (localStorage.getItem("viewFolderId")) {
            let selectedDoc = JSON.parse(localStorage.getItem("viewFolderId") || "");
            responseJson.data.map((data: any) => {
              if (data.attributes.name === selectedDoc?.bucket) {
                selectedDoc.id = data.id;
              }
            })
            let obj = {
              id: selectedDoc?.id,
              type: "document_folder",
              attributes: {
                name: selectedDoc?.bucket,
                confidential: selectedDoc?.confidential,
              }
            }
            if (selectedDoc) {
              this.state.selectedDocuments.push(obj);
              this.setState({ selectedDocuments: this.state.selectedDocuments })
            }
          }
          if (responseJson.errors && responseJson.errors[0]?.token === "Token has Expired" && JSON.parse(localStorage.getItem("userInfo") || "")?.attributes?.is_customer === 'True') {
            this.afterTokenExpired()
            if (this.authToken) {
              this.getProductsDocuments(this.state.passCatalogueId);
            }
          }
        }

        if (apiRequestCallId === this.getDropdownDataApiCallId) {
          this.setDataForDropdown(responseJson);
          if (responseJson.errors && responseJson.errors[0]?.token === "Token has Expired" && JSON.parse(localStorage.getItem("userInfo") || "")?.attributes?.is_customer === 'True') {
            this.afterTokenExpired()
            if (this.authToken) {
              this.getDropdownLists();
            }
          }
        }

        if (apiRequestCallId && responseJson) {
          if (apiRequestCallId === this.getProductDetailsApiCallId) {
            if (responseJson.data) {
              this.setProductDetailsData(responseJson)
            }
          }
        }

        if (this.getProductListApiCalled != null && this.getProductListApiCalled === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          if (responseJson && !responseJson.errors) {
            this.setState({ isLoading: false, productList: responseJson.products });
          }
        }
      }
    }
    // Customizable Area End

  }
  /* @ts-ignore */
  async componentDidMount() {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        if (isLoggedIn(this.props.navigation)) {
          autoLogOut();
        }
      });
    });
    const authToken = isLoggedIn(this.props.navigation);
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
      const userDetailToken = getUserdetails();
      this.getDepartmentList();
      this.fetchProductDetail();
      this.getDropdownLists();
      this.getProductsDocuments(0);
      if (isEmployee()) {
        this.setState({ isEmployeeLogin: true })
        let profileDetails = localStorage.getItem("CustomerProfileDetails");
        if (profileDetails !== null) {
          let profileDetails2 = JSON.parse(profileDetails);
          this.setState({
            userName: profileDetails2?.contact_name,
            companyName: profileDetails2?.customer_name,
            meeting: {
              ...this.state.meeting,
              company: profileDetails2?.customer_name,
              firstName: profileDetails2?.first_name,
              lastName: profileDetails2?.last_name,
              emailId: profileDetails2?.email,
              phoneNumber: profileDetails2?.phone_number === null ? "" : profileDetails2?.phone_number,
            }
          })
        }
        this.getProductList();
      } else {
        this.setState({ isEmployeeLogin: false })
        if (userDetailToken) {
          this.userDetails = userDetailToken;
          this.getProfileDetails();
        }
      }
    }
    let isEmployees = localStorage.getItem("isEmployee");
    if (isEmployees !== null) {
      if (JSON.parse(isEmployees)) {
        this.setState({
          isEmployees: true
        })
      } else {
        this.setState({
          isEmployees: false
        })
      }
    }
    const query = this.props.navigation.getParam('query');
    if (query === 'product') {
      this.setState({
        selectedTab: 0
      });
    } else if (query === 'sample') {
      this.setState({
        selectedTab: 1
      });
    } else {
      this.setState({
        selectedTab: 2
      })
    }

    window.addEventListener('popstate', this.onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', this.onBackButtonEvent);
    }
  }

  getCustomerProfileAPIResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getCustomerProfileApiCallId) {
      if (!responseJson.errors) {
        this.setProfileDetails(responseJson.data.attributes)
      } else {
        if (responseJson.errors && responseJson.errors[0]?.token === "Token has Expired" && JSON.parse(localStorage.getItem("userInfo") || "")?.attributes?.is_customer === 'True') {
          this.afterTokenExpired()
          if (this.authToken) {
            this.getProfileDetails();
          }
        }
      }
    }
  };

  getDepartmentListAPIResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getDepartmentListApiCallId) {
      if (!responseJson.errors) {
        this.setState({ departmentList: responseJson.department });
      } else {
        if (responseJson.errors && responseJson.errors[0]?.token === "Token has Expired" && JSON.parse(localStorage.getItem("userInfo") || "")?.attributes?.is_customer === 'True') {
          this.afterTokenExpired()
          if (this.authToken) {
            this.getDepartmentList();
          }
        }
      }
    }
  }

  createQueryAPIResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.createProductQueryApiCallId) {
      if (!responseJson.errors && !responseJson.data.attributes.errors) {
        this.createProductQueryAPIData()
      } else {
        if (responseJson.errors && responseJson.errors[0]?.token === "Token has Expired" && JSON.parse(localStorage.getItem("userInfo") || "")?.attributes?.is_customer === 'True') {
          this.afterTokenExpired()
          if (this.authToken) {
            this.saveProductQuery();
          }
        }
      }
    }
  }

  createSampleRequestAPIResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.createSampleRequestApiCallId) {
      if (!responseJson.errors && !responseJson.data.attributes.errors) {
        this.createSampleRequestAPIData()
      } else {
        errorNotification('Something went wrong!');
        if (responseJson.errors && responseJson.errors[0]?.token === "Token has Expired" && JSON.parse(localStorage.getItem("userInfo") || "")?.attributes?.is_customer === 'True') {
          this.afterTokenExpired()
          if (this.authToken) {
            this.isAuthTokenForCreateSampleRequest()
          }
        }
        return;
      }
    }
  }

  createSetUpMeetingAPIResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.createMeetingRequestApiCallId) {
      if (!responseJson.data.attributes.errors && !responseJson.errors && !responseJson.error) {
        this.createMeetingAPIData()
      } else {
        if (responseJson.errors && responseJson.errors[0]?.token === "Token has Expired" && JSON.parse(localStorage.getItem("userInfo") || "")?.attributes?.is_customer === 'True') {
          this.afterTokenExpired()
          if (this.authToken) {
            this.saveMeetingRequest();
          }
        }
      }
    }
  }

  onBackButtonEvent = () => {
    if (window.location.pathname === '/TicketList') {
      this.props.navigation.navigate("LandingPage")
    }
  }

  // txtInputWebProps = {
  //   onChangeText: (text: string) => {
  //     this.setState({ txtInputValue: text });
  //   },
  //   secureTextEntry: false
  // };

  // txtInputMobileProps = {
  //   ...this.txtInputWebProps,
  //   autoCompleteType: "email",
  //   keyboardType: "email-address"
  // };

  // txtInputProps = this.isPlatformWeb()
  //   ? this.txtInputWebProps
  //   : this.txtInputMobileProps;

  // btnShowHideProps = {
  //   onPress: () => {
  //     this.setState({ enableField: !this.state.enableField });
  //     this.txtInputProps.secureTextEntry = !this.state.enableField;
  //     this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
  //       ? imgPasswordVisible
  //       : imgPasswordInVisible;
  //   }
  // };

  // btnShowHideImageProps = {
  //   source: this.txtInputProps.secureTextEntry
  //     ? imgPasswordVisible
  //     : imgPasswordInVisible
  // };

  // btnExampleProps = {
  //   onPress: () => this.doButtonPressed()
  // };

  // doButtonPressed() {
  //   let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
  //   msg.addData(
  //     getName(MessageEnum.AuthTokenDataMessage),
  //     this.state.txtInputValue
  //   );
  //   this.send(msg);
  // }

  // web events
  // setInputValue = (text: string) => {
  //   this.setState({ txtInputValue: text });
  // }

  // setEnableField = () => {
  //   this.setState({ enableField: !this.state.enableField });
  // }

  // Customizable Area Start
  checkUserAuthorised = (responseJson: any) => {
    isAuthorisedUser(responseJson, this.props.navigation);
    this.setState({ isLoading: false })
  }

  isAuthTokenForCreateSampleRequest = () => {
    if (this.state.sampleRequest.request_for_api_placed === 'Yes') {
      if (this.state.uploadedAPIFilesBase.length === 0) {
        return false;
      } else {
        this.saveSampleRequest(this.state.sampleRequestSubmitData);
      }
    } else {
      this.saveSampleRequest(this.state.sampleRequestSubmitData);
    }
  }

  afterTokenExpired = async () => {
    this.setState({ isLoading: true })
    await handleUpdateAuthToken();
    const authToken = isLoggedIn(this.props.navigation);
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
    }
  }

  setProductDetailsData = (responseJson: any) => {
    this.setState({
      productDetail: responseJson.data,
      meeting: {
        ...this.state.meeting,
        catalogue_id: responseJson.data.id,
        account_id: this.userDetails.id
      },
      productQuery: {
        ...this.state.productQuery,
        catalogue_id: responseJson.data.id
      }
    });
  }

  setDataForDropdown = (responseJson: any) => {
    this.setState({
      APIUMO: responseJson.api_uoms,
      BatchRequirement: responseJson.batch_requirements,
      endMarkets: responseJson.end_markets,
      impurityUoms: responseJson.impurity_uoms,
      labStandardUoms: responseJson.lab_standard_uoms,
      workingStandardUoms: responseJson.working_standard_uoms
    })
  }

  createMeetingAPIData = () => {
    this.setState({
      requestSuccess: true,
      meeting: {
        ...this.state.meeting,
        comments: ""
      }
    });
  }

  createSampleRequestAPIData = () => {
    this.setState({
      requestSuccess: true,
      sampleRequest: {
        ...this.state.sampleRequest,
        date: new Date(),
        catalogue_id: 1,
        query_type: "",
        request_for_api_placed: "",
        api_quantity: "",
        api_uom_id: 0,
        batch_requirement_id: 0,
        customer_specification: "",
        pharmacopeia_grade: "",
        end_market_id: [],
        working_standard_quantity: "",
        working_standard_uom_id: 0,
        impurity_quantity: "",
        impurity_uom_id: 0,
        lab_standard_quantity: "",
        lab_standard_uom_id: 0,
        bill_to_address: "",
        consignee_address: "",
        additional_email: "",
        comments: ""
      }
    });
  }

  createProductQueryAPIData = () => {
    this.setState({
      requestSuccess: true,
      productQuery: {
        ...this.state.productQuery,
        additional_emails_attributes: [],
        comments: "",
        query_type: ""
      }
    });
  }

  setProfileDetails = (profileData: any) => {
    this.setState({
      userName: this.userDetails.attributes.full_name,
      companyName: profileData.company_name,
      meeting: {
        ...this.state.meeting,
        firstName: profileData.first_name,
        lastName: profileData.last_name,
        company: profileData.company_name,
        emailId: profileData.email,
        phoneCountryCode: String(profileData.country_code),
        phoneNumber: profileData.phone_number === null ? "" : profileData.phone_number,
      }
    })
  }

  getDepartmentList = () => {
    const header = getHeaders({ token: this.authToken });
    const getDepartments = apiCall({
      httpBody: {},
      header: header,
      url: configJSON.getDepartsmentListApiEndpoint,
      httpMethod: configJSON.getDepartmentListApiMethod,
    });

    this.getDepartmentListApiCallId = getDepartments.messageId;
    runEngine.sendMessage(getDepartments.id, getDepartments);
  };

  handleTermsConditionClick = (e: any) => {
    this.setState({
      isTermsAndCondition: !this.state.isTermsAndCondition,
    })
  };

  handleIsRobotClick = (e: any) => {
    this.setState({
      isRobot: !this.state.isRobot,
    })
  };

  getProfileDetails = () => {
    const header = getHeaders({ token: this.authToken });
    const getAccount = apiCall({
      httpBody: {},
      header: header,
      url:
        configJSON.getCustomerProfileApiEndpoint +
        this.userDetails?.attributes?.customer_profile_id,
      httpMethod: configJSON.getCustomerProfileApiMethod,
    });

    this.getCustomerProfileApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  };

  fetchProductDetail = () => {

    const header = {
      "Content-Type": "application/json",
      token: this.authToken
    };

    const productId = this.props.navigation.getParam('id')
    const fetchProductMessage = apiCall({
      url: configJSON.getProductDetailApiEndPoint + productId,
      header: header,
      httpBody: {},
      httpMethod: configJSON.getProductDetailApiMethod
    });

    this.getProductDetailsApiCallId = fetchProductMessage.messageId;
    runEngine.sendMessage(fetchProductMessage.id, fetchProductMessage);

  }

  handleProductQueryChange = (prop: any) => (event: any) => {
    this.setState({ ...this.state, productQuery: { ...this.state.productQuery, [prop]: event.target.value } });
  };

  handleMeetingChange = (prop: any) => (event: any) => {
    this.setState({ ...this.state, meeting: { ...this.state.meeting, [prop]: event.target.value } });
  }

  handleChangeTab = (event: any, newValue: any) => {
    this.setState({ selectedTab: newValue });
  };

  setUpMetting = () => {
    this.setState({ meetingSetUpPopUP: !this.state.meetingSetUpPopUP });
  };

  handleDateChange = (date: any) => {
    this.setState({ date: date })
  }

  handlePhoneCountryCode(event: any, value: any) {
    if (value) {
      this.setState({
        ...this.state,
        meeting: {
          ...this.state.meeting,
          phoneCountryCode: event.value
        },
      });
    }
  }

  // validateAdditionalEmail(value: string) {
  //   const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  //   if ((value.trim().length > 0) && (!String(value)
  //     .toLowerCase()
  //     .match(emailRegex))) {
  //     errorNotification(configJSON.emailError);
  //     // this.showAlert(configJSON.errorTitle, configJSON.passwordError);
  //     return false;
  //   }
  // }
  saveProductQuery = () => {
    if (this.state.productQuery.end_market_id.length === 0 || this.state.productQuery.comments.length === 0 || !this.state.isTermsAndCondition || (this.state.isEmployeeLogin && this.state.product_id === '')) {
      this.setState({ IsMarketError: configJSON.endMarketError, IsCommentError: configJSON.commentError, isTermsAndConditionSave: configJSON.termsConditionError, productDetailQueryError: configJSON.product_idError })
      return false
    }

    const httpBody = {
      ...this.state.productQuery,
      end_market_ids: this.state.productQuery.end_market_id.map((data: any) => data.id),
      upload_attachments_attributes: this.state.uploadedFilesBase64,
      api_attachment: this.state.uploadedAPIFilesBase,
      catalogue_id: this.state.productDetail ? + this.state.productDetail?.id : isEmployee() ? this.state.product_id.id : 0,
      document_folder_ids: this.state.selectedDocuments.map((doc: any) => doc.id),
      product_name: isEmployee() ? this.state.product_name : this.state.productDetail?.attributes.name,
      additional_emails_attributes: this.state.additionalEmails.map((email: any) => {
        return email
      }),
      new_ticket_id: isEmployee() ? parseInt(this.props.navigation.getParam('id')) : 0,
    }

    const saveQuery = apiCall({
      httpBody: httpBody,
      url: configJSON.createProductQueryApiEndpoint,
      httpMethod: configJSON.createProductQueryApiMethod,
      header: getHeaders({ token: this.authToken })
    });

    this.createProductQueryApiCallId = saveQuery.messageId;
    runEngine.sendMessage(saveQuery.id, saveQuery);
    this.setState({
      isLoading: true
    })
    return true
  }

  OnsaveSampleRequest = (values: any) => {
    let emailRegex = /^[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/;
    let isValidRegex = emailRegex.test(this.state.email);
    if(this.state.email.length > 0 ) {
    if (isValidRegex) {
      if (values.request_for_api_placed === 'Yes') {
        if (this.state.uploadedAPIFilesBase.length === 0) {
          return false;
        } else {
          this.saveSampleRequest(values);
        }
      } else {
        this.saveSampleRequest(values);
      }
    }
    else {
      this.setState({ additionalEmailError: this.state.email ? configJSON.emailErrorMessage : "" });
        if (isValidRegex) {
          this.saveSampleRequest(values);
        }
      }
    }
    else {
      this.saveSampleRequest(values);
    }
  }

  saveSampleRequest = (values: any) => {
    if (values.request_for_api_placed === 'Yes' && this.state.uploadedAPIFilesBase.length === 0) {
      return false;
    }
    this.setState({ sampleRequestSubmitData: values })
    //For current httpbody
    let bodyValues = {
      ...this.state.sampleRequest,
      catalogue_id: this.state.productDetail ? + this.state.productDetail?.id : isEmployee() ?
        values?.product_name?.id : 0,
      additional_emails_attributes: this.state.additionalEmails.map((email: any) => {
        return email
      }),
      end_market_ids: this.state.sampleRequest.end_market_id.map((data: any) => data.id),
      upload_attachments_attributes: this.state.uploadedFilesBase64,
      api_attachment: this.state.uploadedAPIFilesBase,
      new_ticket_id: isEmployee() ? parseInt(this.props.navigation.getParam('id')) : 0,
      // new_ticket_id: 0,
      //  product_name: isEmployee() ? this.state.product_name : this.state.productDetail?.attributes.name,
      sample_types_attributes: this.state.sampleRequest.selectedSampleTypes.map((type: string) => {
        return {
          name: type
        }
      })
    };

    //for merge current and formik httpbody
    let addtionalHttpBody = { ...bodyValues, ...values };

    const saveSample = apiCall({
      header: getHeaders({ token: this.authToken }),
      url: configJSON.createSampleRequestApiEndpoint,
      httpMethod: configJSON.createSampleRequestApiMethod,
      httpBody: addtionalHttpBody,
    });

    this.createSampleRequestApiCallId = saveSample.messageId;
    runEngine.sendMessage(saveSample.id, saveSample);
    this.setState({
      isLoading: true
    })
    return true
  }

  saveMeetingRequest = () => {
    if (this.state.meeting.phoneCountryCode === null || this.state.meeting.phoneCountryCode === "null" || this.state.meeting?.comments?.length === 0 || this.state.meeting?.phoneNumber?.length === 0 || !this.state.isTermsAndCondition || (this.state.isEmployeeLogin && this.state.product_id === '')) {
      this.setState({ phoneError: configJSON.phoneError, phoneCountryCodeError: configJSON.phoneCountryCodeError, commentErrorSetMeeting: configJSON.commentError, termsConditionErrorMeeting: configJSON.termsConditionError, productDetailError: configJSON.product_idError })
      return false
    }
    const httpBody = {
      first_name: this.state.meeting.firstName,
      last_name: this.state.meeting.lastName,
      phone_number: `${this.state.meeting.phoneCountryCode}${this.state.meeting.phoneNumber}`,
      catalogue_id: isEmployee() ? this.state.product_id.id : this.state.meeting.catalogue_id,
      account_id: this.state.meeting.account_id,
      comments: this.state.meeting.comments,
      new_ticket_id: isEmployee() ? parseInt(this.props.navigation.getParam('id')) : 0,
      // new_ticket_id: 0,
    }
    const saveMeeting = apiCall({
      header: getHeaders({ token: this.authToken }),
      url: configJSON.createSetMeetingApiEndpoint,
      httpMethod: configJSON.createSetMeetingApiMethod,
      httpBody: httpBody
    });
    this.createMeetingRequestApiCallId = saveMeeting.messageId;
    runEngine.sendMessage(saveMeeting.id, saveMeeting);
    return true
  }

  closeSuccess = () => {
    this.setState({ requestSuccess: !this.state.requestSuccess });
    if (this.props.navigation) {
      this.props.navigation.navigate("ProductDetails", { id: this.state.productDetail?.id });
      localStorage.setItem("from", "product");
    }
  };

  addEmail = (ev: any) => {
    if (ev.key === 'Enter') {
      let Reg = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      if (ev.target.value.match(Reg)) {
        const tempEmail = {
          additional_email: ev.target.value
        }
        this.setState({
          additionalEmails: [...this.state.additionalEmails, tempEmail]
        })
        ev.target.value = "";
        ev.preventDefault();
      }
    }
  }

  removeEmail = (index: any) => {
    const filterEmail = this.state.additionalEmails.filter(item => item.additional_email !== this.state.additionalEmails[index].additional_email);

    this.setState({
      additionalEmails: [...filterEmail]
    })
  }

  getDropdownLists = () => {

    const dropdownsList = apiCall({
      header: getHeaders({ token: this.authToken }),
      url: configJSON.getDropdownsListApiEndpoint,
      httpMethod: configJSON.getDropdownsListApiMethod,
      httpBody: {}
    });

    this.getDropdownDataApiCallId = dropdownsList.messageId;
    runEngine.sendMessage(dropdownsList.id, dropdownsList);

  }

  getProductsDocuments = (catalogue_Id: any) => {
    this.setState({ passCatalogueId: catalogue_Id });
    const catalogueId = isEmployee() ? catalogue_Id : localStorage.getItem("ProductId")
    const documentList = apiCall({
      header: getHeaders({ token: this.authToken }),
      url: configJSON.getProductDocumentsApiEndpoint,
      httpBody: {},
      httpMethod: configJSON.getProductDocumentsApiMethod
    });

    this.getDocumentsListApiCallId = documentList.messageId;
    runEngine.sendMessage(documentList.id, documentList);
  }
  handleOnMarketEndChange = (event: any, e: any, isSampleRequest: boolean = false) => {
    const {
      target: { value },
    } = event;
    if (isSampleRequest) {
      this.setState({
        sampleRequest: {
          ...this.state.sampleRequest,
          end_market_id: value
        }
      });
    } else {
      this.setState({
        productQuery: {
          ...this.state.productQuery,
          end_market_id: value
        }
      });
    }
  }

  handleDocumentUpload = async (e: any) => {
    const uploadedFiles = [].slice.call(e.target.files);
    uploadedFiles.forEach((file: File) => {
      return {
        name: file.name
      }
    })

    const newImagesPromises = []
    for (let row of e.target.files) {
      newImagesPromises.push(this.fileToDataUri(row))
    }

    const fileBase64 = await Promise.all(newImagesPromises)
    this.setState({
      uploadedFilesBase64: fileBase64,
      uploadedFiles: uploadedFiles
    });
  }

  returnBoolenValue = (condition: any) => {
    if (condition) {
      return true;
    } else {
      return false
    }
  }

  handleDetailsDocumentUpload = async (files: any) => {
    const uploadedFiles = [].slice.call(files);
    uploadedFiles.forEach((file: File) => {
      return {
        name: file.name
      }
    })

    const newImagesPromises = []
    for (let i = 0; i < files.length; i++) {
      newImagesPromises.push(this.fileToDataUri(files[i]))
    }

    const fileBase64 = await Promise.all(newImagesPromises)
    this.setState({
      uploadedAPIFilesBase: fileBase64,
      uploadedAPIFiles: uploadedFiles
    });
  }

  removeFile = (fileName: string) => {
    this.setState({
      uploadedFiles: this.state.uploadedFiles.filter(file => file.name !== fileName),
      uploadedFilesBase64: this.state.uploadedFilesBase64.filter(file => file.name !== fileName)
    })
  }

  removeAPIFile = () => {
    if (this.state.uploadedAPIFilesBase[0] !== undefined) {
      this.setState({
        uploadedAPIFiles: [],
        uploadedAPIFilesBase: []
      })
    }
    this.handleAttachmentPopUP();
    this.setFileSizeError(false);
  }

  fileToDataUri = (image: any) => {
    return new Promise((res) => {
      const reader = new FileReader();
      const { name, } = image;
      reader.addEventListener('load', () => {
        res({ file_name: name, upload: reader.result })
      });
      reader.readAsDataURL(image);
    })
  }

  handleDocumentSelectChange = (event: any) => {
    const {
      target: { value },
    } = event;
    this.setState({
      ...this.state,
      selectedDocuments: value
    })
  };

  goBack = () => {
    this.props.navigation.goBack()
  }

  goBackToCreateTicket = () => {
    localStorage.setItem("createNewTicket", "true");
    this.props.navigation.navigate("TicketList");
  }

  goBackToTicketList = () => {
    localStorage.setItem("createNewTicket", "false")
    this.props.navigation.navigate("TicketList")
  }

  handleAttachmentPopUP = () => {
    this.setState({ isAttachment: !this.state.isAttachment })
  }

  setFile = (files: any) => {
    this.setState({ selectedFile: files })
  }

  setSelectedFile = (files: any) => {
    this.setState({
      selectedFile: files[0]
    })
  }

  submitAPICOAFile = () => {
    this.setState({
      uploadedAPIFilesBase: this.state.uploadedAPIFilesBase,
      uploadedAPIFiles: this.state.uploadedAPIFiles,
      isAttachment: !this.state.isAttachment
    })
  };

  setFileSizeError = (value: boolean) => {
    this.setState({
      isUploadError: value
    })
  }

  getProductList = () => {
    const documentList = apiCall({
      header: getHeaders({ token: this.authToken }),
      url: configJSON.productListApiEndPoint,
      httpBody: {},
      httpMethod: configJSON.getProductDocumentsApiMethod
    });

    this.getProductListApiCalled = documentList.messageId;
    runEngine.sendMessage(documentList.id, documentList);
  };

  handleProductChange = (e: any) => {
    this.setState({
      product_name: e.target.value.name,
      product_id: e.target.value,
    })
    this.getProductsDocuments(e.target.value.id)
  }

  setAPISelected = (e: any) => {
    this.setState({
      isAPISelected: false
    })
    e.target.value.forEach((data: any) => {
      if (data === 'API') {
        this.setState({
          isAPISelected: true
        })
      }
    })
  }

  // Customizable Area End

}
