export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const BoiConLogo = require("../assets/bioconLogo.png");
export const LoginImage2 = require("../assets/log2.png");
export const LoginImage = require("../assets/signupImage.png");
export const Background = require("../assets/backgroundImg.png");

export const backgroundImg = require("../assets/backgroundImg.png");
export const signupImage = require("../assets/signupImage.png");
export const signupImage2 = require("../assets/LoginImage.png");
export const signupImage3 = require("../assets/signupImage1.png");
export const bioconLogo = require("../assets/bioconLogo.png");
export const ConnectLogo = require('../assets/Bio-connectLogo.png');

