import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {
  apiCall,
  authorizeEmployee,
  errorNotification,
  getHeaders,
  isAuthorisedUser,
  isLoggedIn,
  IUserdetails,
  showApiErrorResponse,
  successNotification,
  warningNotification,
  events,
  autoLogOut
} from "../../../components/src/common";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface IBioconManager {
  country_code: number,
  created_at: string;
  email: string;
  name: string;
  id: number;
  last_name: string;
  phone_number: number;
  role_id: string;
  updated_at: string;
  employee_territory_id: number;
}

export interface IBamDetails {
  biocon_account_manager_country_code: number;
  biocon_account_manager_email: string;
  biocon_account_manager_name: string;
  biocon_account_manager_phone_number: number;
  territory: string;
  biocon_account_manager_id: number;
  territory_id: number;
}

export interface ICustomerContacts {
  id: number;
  name: string;
  email: string;
  company: string;
}

export interface ICustomerContactGet {
  contact_person_email_id: string;
  customer_contact_person: string;
  customer_name: string;
}

interface IBioconManagerTerritory {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  userDetailLabel: string;
  labelCustomerName: string;
  parentCustomerLabel2: string;
  sapCodeLabel: string;
  customerTypeLabel: string;
  channelLabel: string;
  privacyPolicyLabel: string;
  selectedTab: number;
  ClassificationLabel: string;
  customerContactLabel: string;
  territoryLabel: string;
  ModeOfBusinessLabel: string;
  CurrencyLabel: string;
  websiteLabel: string;
  billingStreetLabel: string;
  shippingStreetLabel: string;
  billingCityLabel: string;
  accountManagerLabel: string;
  alternateEmailLabel: string;
  industriesList: any[];
  designation: string;
  landlineNumber: string;
  areaOfBusiness: string;
  subsidiaryName: string;
  alternateEmail: string;
  isTermsCondition: boolean;
  isLoading: boolean;
  privacyPolicyText: string;
  termslabelText: string;
  termsLabel: string;
  codeList: any[];
  firstName: string;
  firstNameError: string;
  lastName: string;
  companyName: string;
  emailId: string;
  phoneNumber: string;
  phoneCountryCode: string;
  agreedTermsAndCondition: boolean;
  address: string;
  industry: number;
  shippingCityLabel: string;
  shippingPostalCodeLabel: string;
  billingPostalCodeLabel: string;
  billingStateLabel: string;
  shippingStateLabel: string;
  billingCountryLabel: string;
  shippingCountryLabel: string;
  classification: number;
  mode_of_business: number;
  account_currency: number;
  customer_contact: number;
  website: string;
  billing_street: string;
  billing_city: string;
  billing_postal_code: string;
  billing_state: string;
  billing_country: string;
  shipping_street: string;
  shipping_city: string;
  shipping_postal_code: string;
  shipping_state: string;
  shipping_country: string;
  is_same_as_billing_address: boolean;
  customerContact: ICustomerContactGet | [];
  mappedProducts: IProductGet[];
  bam_details: any[];
  biocon_manager_id: any;
  biocon_manager_territories: IBioconManagerTerritory[];
  isView: boolean;
  multipleProductForm: any;
  multipleBAMForm: any;
  isBamEdit: boolean;
  isProductMapUpdate: boolean;
  stateUserId: null | number;
  stateIsUpdate: boolean;
  isCreatedPopup: boolean;
  createdCustomerPopupText: string;
  customerMaster: string;
  parentCompanyList: [];
  selectedParentCompanyId: number;
  isCustomerContactUpdate: boolean;
  contactPersonEmail: string;
  contactPersonName: string;
  contactPersonCompany: string;
  selectedContactCustomerId: number;
  selectedCustomerContacts: any[];
  isDeletePopup: boolean;
  deleteCustomerContactId: number;
  deleteCustomerContactPopupText: string;
  deleteProductMappedPopupText: string;
  personalDetailsId: string;
  isProductMapDelete: boolean;
  territory_name: string;
  isEditDetails: boolean;
  contactNameLabel: string;
  productNameLabel: string;
  invoiceLabel: string;
  invoiceDateLabel: string;
  invoiceAmountLabel: string;
  invoiceCurrencyLabel: string;
  invoicePOLabel: string;
  invoiceDueDateLabel: string;
  invoicePaymentTermsLabel: string;
  allPaymentTerms: any;
  invoiceIncoTermsLabel: string;
  invoicePaymentStatusLabel: string;
  invoiceAttachmentLabel: string;
  invoiceAddLabel: string;
  attachmentLabel: string;
  uploadedFiles: any[];
  uploadedFilesBase64: any[];
  allIncoTerms: any[];
  productsList: any[],
  invoiceDetail: any[];
  contactName: any;
  deleteInvoiceId: number;
  isInvoiceDelete: boolean;
  isInvoiceEdit: boolean;
  deleteInvoicePopupText: string;
  paymentStatus: any;
  attachmentError: any;
  allcustomerInvioce: any[];
  editUserId: any;
  customerParamId: any;
  invoiceFormDetails: any;
  getAttachment: any;
  isInvoiceLoading: boolean;
  customerInvoiceListingMeta: any;
  page: any;
  perPageRecord: any;
  searchInvoiceText: any;
  all_customer_types: any[];
  all_channels: any[];
  all_classifications: any[];
  all_territories: any[];
  all_mode_of_businesses: any[];
  all_account_currencies: any[];
  allCustomerContacts: ICustomerContacts[];
  bamEmailIdLabel: string;
  bamPhoneNoLabel: string;
  companySapCode: string;
  customer_type: number;
  channel: number;
  territory: any[];
  role_id: number;
  employee_territory_id: number;
  created_at: any;
  updated_at: any;
  email: string;
  industryLabel: string;
  biocon_account_managers: IBioconManager[];
  mappedProductLabel: string;
  products: IProduct[];
  catlogIds: number;
  biocon_manager: any;
  territory_id: number;
  categories: string;
  isViewOnly: boolean;
  productMappedError: boolean;
  accountManagerError: boolean;
  territoryIdError: boolean;
  personalDetailsError: boolean;
  accountCurrencyError: boolean;
  modeOfBusinessError: boolean;
  customerTypeError: boolean;
  territoryError: boolean;
  Can_delete_permission: boolean;
  editBtnText: string;
  firstNamePlaceholder: string;
  customerNameRequiredText: string;
  parentCompanyNamePlaceholder: string;
  customerSapCodePlaceholder: string;
  chooseIndustryPlaceholder: string;
  noDataText: string;
  chooseCustomerTypePlaceholder: string;
  CustomerTypeisRequired: string;
  chooseChannelPlaceholder: string;
  chooseTerritoryPlaceholder: string;
  TerritoryisRequired: string;
  chooseClassificationPlaceholder: string;
  chooseModeBusinessPlaceholder: string;
  BusinessModeIsrequired: string;
  ChooseCurrencyPlaceholder: string;
  CurrencyisRequired: string;
  enterWebsitePlaceholder: string;
  billingStreetPlaceholder: string;
  billingStreetisRequried: string;
  billingAddressCheckboxLabel: string;
  shippingStreetPlaceholder: string;
  shippingStreetisRequired: string;
  billingCityPlaceholder: string;
  billingCityisRequired: string;
  shippingCityPlaceholder: string;
  shippingCityisRequired: string;
  billingPostalCodePlaceholder: string;
  billingPostalCodeisRequired: string;
  shippingPostalPlaceholder: string;
  shippingPostalisRequired: string;
  billingStatePlaceholder: string;
  billingStateisRequired: string;
  shippingStatePlaceholder: string;
  shippingStateisRequired: string;
  addCustomerheader: string;
  // Customizable Area End
}
export interface IProduct {
  name: string;
  isSelected: boolean;
  isAlreadySelected: boolean;
  id: number
}

export interface IProductGet {
  id: string;
  type: string;
  attributes: {
    product_id: number;
    api_technology: string;
    product_name: string;
    therapeutic_area: string;
  }
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProfileDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createAccountApiCallId: any;
  updateAccountApiCallId: any;
  updateCustomerProfileApiCallId: any;
  updateProductMappedApiCallId: any;
  updateBAMProfileApiCallId: any;
  getCustomerPersonalApiCallId: any;
  getUserPersonalApiCallId: any;
  getCustomerBAMApiCallId: any;
  getMappedProductAPICallId: any;
  getProductMappedDetailAPICallId: any;
  saveCustomerContactApiCallId: string;
  deleteCustomerContactApiCallId: string;
  deleteProductMappedApiCallId: string;
  userDetails: IUserdetails;
  authToken: string;
  isUpdate: boolean;
  userId: number;
  editUserId: number;
  customerParamId: any;
  getCompaniesListApiCallId: string;
  getIncoTermsListApiCallId: string;
  getProductNameListApiCallId: string;
  getInvoiceCurrencyListApiCallId: string;
  getIncoTeamListApiCallId: string;
  getCategoriesApiCallId: any;
  getPaymentTermsListApiCallId: string;
  saveInvoiceApiCallId: string;
  createInvoiceApiCallId: any;
  getAllCustomerInvioceApiCallId: any;
  createInvoiceEditApiCallId: any;
  createContactInvoiceApiCallId: any;
  getByIdCustomerInvioceApiCallId: any;
  getAllListApiCallId: any;
  getAllBioconManagerApiCallId: any;
  getAllEmployeeTerritoriesCallId: any;
  getAllParentCompanyListCallId: any;
  getAllCustomerContactsApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isProductMapDelete: false,
      createdCustomerPopupText: configJSON.createCustomerPopupText,
      userDetailLabel: configJSON.userDetailLabel,
      labelCustomerName: configJSON.labelCustomerName,
      termslabelText: configJSON.termslabelText,
      termsLabel: configJSON.termsLabel,
      selectedTab: 0,
      ClassificationLabel: configJSON.ClassificationLabel,
      customerContactLabel: configJSON.customerContactLabel,
      ModeOfBusinessLabel: configJSON.ModeOfBusinessLabel,
      CurrencyLabel: configJSON.CurrencyLabel,
      websiteLabel: configJSON.websiteLabel,
      shippingPostalCodeLabel: configJSON.shippingPostalCodeLabel,
      billingStreetLabel: configJSON.billingStreetLabel,
      billingPostalCodeLabel: configJSON.billingPostalCodeLabel,
      shippingStateLabel: configJSON.shippingStateLabel,
      shippingStreetLabel: configJSON.shippingStreetLabel,
      billingCityLabel: configJSON.billingCityLabel,
      shippingCityLabel: configJSON.shippingCityLabel,
      billingStateLabel: configJSON.billingStateLabel,
      billingCountryLabel: configJSON.billingCountryLabel,
      shippingCountryLabel: configJSON.shippingCountryLabel,
      accountManagerLabel: configJSON.accountManagerLabel,
      alternateEmailLabel: configJSON.alternateEmailLabel,
      allCustomerContacts: [],
      industriesList: [],
      codeList: [],
      emailId: "",
      phoneCountryCode: "91",
      phoneNumber: "",
      agreedTermsAndCondition: true,
      address: "",
      industry: 0,
      designation: "",
      landlineNumber: "",
      areaOfBusiness: "",
      subsidiaryName: "",
      alternateEmail: "",
      isTermsCondition: true,
      isLoading: false,
      isInvoiceLoading: false,
      companySapCode: "",
      customer_type: 0,
      channel: 0,
      classification: 0,
      mode_of_business: 0,
      territory: [],
      account_currency: 0,
      customer_contact: 0,
      website: "",
      billing_street: "",
      billing_city: "",
      billing_postal_code: "",
      billing_state: "",
      billing_country: "",
      shipping_street: "",
      shipping_city: "",
      shipping_postal_code: "",
      shipping_state: "",
      shipping_country: "",
      mappedProductLabel: configJSON.mappedProductLabel,
      is_same_as_billing_address: false,
      role_id: 0,
      employee_territory_id: 0,
      created_at: '',
      updated_at: '',
      email: '',
      industryLabel: configJSON.industryLabel,
      biocon_account_managers: [],
      products: [],
      catlogIds: 0,
      biocon_manager: {},
      territory_id: 0,
      categories: '',
      biocon_manager_id: [],
      biocon_manager_territories: [],
      customerMaster: "",
      isCustomerContactUpdate: false,
      customerContact: [],
      mappedProducts: [],
      multipleProductForm: [{ product_id: 0 }],
      multipleBAMForm: [{ attributes: { id: 0, email: "", country_code: "", phone_number: "", territory_name: "" } }],
      bam_details: [],
      parentCompanyList: [],
      selectedParentCompanyId: 0,
      contactPersonEmail: "",
      contactPersonName: "",
      contactPersonCompany: "",
      selectedContactCustomerId: 0,
      selectedCustomerContacts: [],
      territory_name: "",
      firstName: "",
      firstNameError: '',
      lastName: "",
      companyName: "",
      isEditDetails: false,
      isDeletePopup: false,
      deleteCustomerContactId: 0,
      deleteCustomerContactPopupText: configJSON.deleteCustomerContactPopupText,
      deleteProductMappedPopupText: configJSON.deleteProductMappedPopupText,
      personalDetailsId: "",
      contactNameLabel: configJSON.contactNameLabel,
      productNameLabel: configJSON.productNameLabel,
      invoiceLabel: configJSON.invoiceLabel,
      invoiceDateLabel: configJSON.invoiceDateLabel,
      invoiceAmountLabel: configJSON.invoiceAmountLabel,
      invoiceCurrencyLabel: configJSON.invoiceCurrencyLabel,
      invoicePOLabel: configJSON.invoicePOLabel,
      invoicePaymentStatusLabel: configJSON.invoicePaymentStatusLabel,
      invoiceAttachmentLabel: configJSON.invoiceAttachmentLabel,
      invoiceDueDateLabel: configJSON.invoiceDueDateLabel,
      invoicePaymentTermsLabel: configJSON.invoicePaymentTermsLabel,
      invoiceIncoTermsLabel: configJSON.invoiceIncoTermsLabel,
      invoiceAddLabel: configJSON.invoiceAddLabel,
      attachmentLabel: configJSON.attachmentLabel,
      uploadedFiles: [],
      uploadedFilesBase64: [],
      allIncoTerms: [],
      productsList: [],
      allPaymentTerms: [],
      invoiceDetail: [],
      contactName: "",
      deleteInvoiceId: 0,
      isInvoiceDelete: false,
      isInvoiceEdit: true,
      attachmentError: '',
      allcustomerInvioce: [],
      editUserId: '',
      customerParamId: '',
      deleteInvoicePopupText: configJSON.deleteInvoicePopupText,
      bamEmailIdLabel: configJSON.bamEmailIdLabel,
      bamPhoneNoLabel: configJSON.bamPhoneNoLabel,
      territoryLabel: configJSON.territoryLabel,
      all_customer_types: [],
      all_channels: [],
      all_classifications: [],
      all_territories: [],
      all_mode_of_businesses: [],
      all_account_currencies: [],
      isView: false,
      isBamEdit: false,
      isProductMapUpdate: false,
      stateUserId: null,
      stateIsUpdate: false,
      isCreatedPopup: false,
      productMappedError: false,
      paymentStatus: [
        {
          id: 0,
          value: 'Pending'
        },
        {
          id: 1,
          value: 'Paid'
        }
      ],
      getAttachment: "",
      customerInvoiceListingMeta: null,
      page: 1,
      perPageRecord: 20,
      searchInvoiceText: '',
      invoiceFormDetails: {
        account_id: '',
        catalogue_id: '',
        invoice: '',
        invoice_date: '',
        invoice_amount: '',
        account_currency_id: '',
        p_o: '',
        due_date: '',
        payment_term_id: '',
        inco_term_id: '',
        payment_status: '',
        attachment: "",
      },
      parentCustomerLabel2: configJSON.parentCustomerLabel2,
      sapCodeLabel: configJSON.sapCodeLabel,
      customerTypeLabel: configJSON.customerTypeLabel,
      channelLabel: configJSON.channelLabel,
      privacyPolicyLabel: configJSON.privacyPolicyLabel,
      privacyPolicyText: configJSON.privacyPolicyText,
      isViewOnly: false,
      accountManagerError: false,
      territoryIdError: false,
      personalDetailsError: false,
      accountCurrencyError: false,
      modeOfBusinessError: false,
      customerTypeError: false,
      territoryError: false,
      Can_delete_permission: false,
      editBtnText: configJSON.editBtnText,
      firstNamePlaceholder: configJSON.firstNamePlaceholder,
      customerNameRequiredText: configJSON.customerNameRequiredText,
      parentCompanyNamePlaceholder: configJSON.parentCompanyNamePlaceholder,
      customerSapCodePlaceholder: configJSON.customerSapCodePlaceholder,
      chooseIndustryPlaceholder: configJSON.chooseIndustryPlaceholder,
      noDataText: configJSON.noDataText,
      chooseCustomerTypePlaceholder: configJSON.chooseCustomerTypePlaceholder,
      CustomerTypeisRequired: configJSON.CustomerTypeisRequired,
      chooseChannelPlaceholder: configJSON.chooseChannelPlaceholder,
      chooseTerritoryPlaceholder: configJSON.chooseTerritoryPlaceholder,
      TerritoryisRequired: configJSON.TerritoryisRequired,
      chooseClassificationPlaceholder: configJSON.chooseClassificationPlaceholder,
      chooseModeBusinessPlaceholder: configJSON.chooseModeBusinessPlaceholder,
      BusinessModeIsrequired: configJSON.BusinessModeIsrequired,
      ChooseCurrencyPlaceholder: configJSON.ChooseCurrencyPlaceholder,
      CurrencyisRequired: configJSON.CurrencyisRequired,
      enterWebsitePlaceholder: configJSON.enterWebsitePlaceholder,
      billingStreetPlaceholder: configJSON.billingStreetPlaceholder,
      billingStreetisRequried: configJSON.billingStreetisRequried,
      billingAddressCheckboxLabel: configJSON.billingAddressCheckboxLabel,
      shippingStreetPlaceholder: configJSON.shippingStreetPlaceholder,
      shippingStreetisRequired: configJSON.shippingStreetisRequired,
      billingCityPlaceholder: configJSON.billingCityPlaceholder,
      billingCityisRequired: configJSON.billingCityisRequired,
      shippingCityPlaceholder: configJSON.shippingCityPlaceholder,
      shippingCityisRequired: configJSON.shippingCityisRequired,
      billingPostalCodePlaceholder: configJSON.billingPostalCodePlaceholder,
      billingPostalCodeisRequired: configJSON.billingPostalCodeisRequired,
      shippingPostalPlaceholder: configJSON.shippingPostalPlaceholder,
      shippingPostalisRequired: configJSON.shippingPostalisRequired,
      billingStatePlaceholder: configJSON.billingStatePlaceholder,
      billingStateisRequired: configJSON.billingStateisRequired,
      shippingStatePlaceholder: configJSON.shippingStatePlaceholder,
      shippingStateisRequired: configJSON.shippingStateisRequired,
      addCustomerheader: configJSON.addCustomerheader,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.onPersonalDetailsSave = this.onPersonalDetailsSave.bind(this);
    this.onBAMDetailsSave = this.onBAMDetailsSave.bind(this);
    this.onProductMappedDetailsSave = this.onProductMappedDetailsSave.bind(this);
    this.personalDetailsValidation = this.personalDetailsValidation.bind(this);
    this.onPersonalDetailsUpdate = this.onPersonalDetailsUpdate.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    this.handlechangeProducts = this.handlechangeProducts.bind(this);
    this.goTOBack = this.goTOBack.bind(this);
    this.SaveCustomerContactDetails = this.SaveCustomerContactDetails.bind(this);
    this.addMultipleBamForm = this.addMultipleBamForm.bind(this);
    this.addMultipleForm = this.addMultipleForm.bind(this);
    this.allCustomerInvoiceAPIData = this.allCustomerInvoiceAPIData.bind(this);
    this.setAllListData = this.setAllListData.bind(this);
    this.setCategories = this.setCategories.bind(this);
    this.setCustomerBamDetails = this.setCustomerBamDetails.bind(this);
    this.setCustomerPersonalDetails = this.setCustomerPersonalDetails.bind(this);
    this.setDataAfterCreateAccount = this.setDataAfterCreateAccount.bind(this);
    this.setEnableField = this.setEnableField.bind(this);
    this.setPersonalDetailsObjects = this.setPersonalDetailsObjects.bind(this);
    this.setSelectedProductDetails = this.setSelectedProductDetails.bind(this);
    this.setUserPersonalDetails = this.setUserPersonalDetails.bind(this);
    this.handleSearchInvoices = this.handleSearchInvoices.bind(this);
    this.handleAddressCheck = this.handleAddressCheck.bind(this);
    this.handleBamEdit = this.handleBamEdit.bind(this);
    this.handleBioconManagerTerritoryChange = this.handleBioconManagerTerritoryChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlechangeTerritory = this.handlechangeTerritory.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.handleChannelChange = this.handleChannelChange.bind(this);
    this.handleClassificationChange = this.handleClassificationChange.bind(this);
    this.handleContactMasterChange = this.handleContactMasterChange.bind(this);
    this.handleCustomerContactAddNewClick = this.handleCustomerContactAddNewClick.bind(this);
    this.handleCustomerTypeChange = this.handleCustomerTypeChange.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleProductMappedCancel = this.handleProductMappedCancel.bind(this);
    this.handleProductMapEdit = this.handleProductMapEdit.bind(this);
    this.handlePersonalDetailEdit = this.handlePersonalDetailEdit.bind(this);
    this.handleModeOfBusinessChange = this.handleModeOfBusinessChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleParentCompanyChange = this.handleParentCompanyChange.bind(this);
    this.doButtonPressed = this.doButtonPressed.bind(this);
    this.setInputValue = this.setInputValue.bind(this);
    this.deleteProductMappedAPIData = this.deleteProductMappedAPIData.bind(this);
    this.getPersonalDetails = this.getPersonalDetails.bind(this);
    this.deleteCustomerContactAPIData = this.deleteCustomerContactAPIData.bind(this);
    this.companiesListAPIData = this.companiesListAPIData.bind(this);
    this.allCustomerInvoiceAPIData = this.allCustomerInvoiceAPIData.bind(this);
    this.updateBamProfileAPIData = this.updateBamProfileAPIData.bind(this);
    this.productMappedUpdateAPIData = this.productMappedUpdateAPIData.bind(this);
    this.getListAPICalls = this.getListAPICalls.bind(this);
    this.getAllList = this.getAllList.bind(this);
    this.getAllBioconManagers = this.getAllBioconManagers.bind(this);
    this.getAllManagerTerritories = this.getAllManagerTerritories.bind(this);
    this.getAllProductsListing = this.getAllProductsListing.bind(this);
    this.getAllCompaniesList = this.getAllCompaniesList.bind(this);
    this.getCategoriesList = this.getCategoriesList.bind(this);
    this.checkForFirstName = this.checkForFirstName.bind(this);
    this.isShippingAddressChange = this.isShippingAddressChange.bind(this);
    this.removeBamDetails = this.removeBamDetails.bind(this);
    this.getUserPersonalDetails = this.getUserPersonalDetails.bind(this);
    this.onCustomerContactSave = this.onCustomerContactSave.bind(this);
    this.handleInvoiceAddNewClick = this.handleInvoiceAddNewClick.bind(this);
    this.handleInvoiceEdit = this.handleInvoiceEdit.bind(this);
    this.handleIndustryChange = this.handleIndustryChange.bind(this);
    this.handleManagerChange = this.handleManagerChange.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    this.deleteFormElement = this.deleteFormElement.bind(this);
    this.handleInvoiceByIdClick = this.handleInvoiceByIdClick.bind(this);
    this.getAllParentCompanyListing = this.getAllParentCompanyListing.bind(this);
    this.handleDeleteProductMapped = this.handleDeleteProductMapped.bind(this);
    this.handleIsDeleteClose = this.handleIsDeleteClose.bind(this);
    this.handleIsCreatedClose = this.handleIsCreatedClose.bind(this);
    this.debounce = this.debounce.bind(this);
    this.debouncedCallback = this.debouncedCallback.bind(this);
    this.duplicateCode = this.duplicateCode.bind(this);
    this.handleSaveInvoice = this.handleSaveInvoice.bind(this);
    this.deleteProductMapped = this.deleteProductMapped.bind(this);
    this.deleteCustomerContact = this.deleteCustomerContact.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );
      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (apiRequestCallId && responseJson) {
        isAuthorisedUser(responseJson, this.props.navigation)
        if (apiRequestCallId === this.getCustomerPersonalApiCallId) {
          this.setState({ isLoading: false })
          if (responseJson && responseJson.data) {
            this.setPersonalDetailsObjects(responseJson)
          }
        }
        if (apiRequestCallId === this.getAllListApiCallId) {
          this.setState({ isLoading: false })
          if (!responseJson.error) {
            this.setAllListData(responseJson);
          }
        }
        if (apiRequestCallId === this.getUserPersonalApiCallId) {
          this.setState({ isLoading: false })
          if (!responseJson.error) {
            this.setUserPersonalDetails(responseJson);
          }
        }

        if (apiRequestCallId === this.getAllCustomerContactsApiCallId) {
          if (!responseJson.error && !responseJson.errors) {
            this.setState({ allCustomerContacts: responseJson.all_accounts })
          }
        }

        if (apiRequestCallId === this.saveCustomerContactApiCallId) {
          if (!responseJson.error && !responseJson.errors) {
            this.SaveCustomerContactDetails();
          } else {
            this.setState({ isLoading: false })
            errorNotification("Something went wrong");
          }
        }

        if (apiRequestCallId === this.createAccountApiCallId) {
          this.setState({ isLoading: false })
          if (!responseJson.error && !responseJson.errors) {
            this.setDataAfterCreateAccount(responseJson);
          } else if (responseJson.errors) {
            showApiErrorResponse(responseJson)
          } else {
            this.setState({ isLoading: false })
            errorNotification(responseJson.error);
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }

        if (apiRequestCallId === this.updateBAMProfileApiCallId) {
          this.updateBamProfileAPIData(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        }

        if (apiRequestCallId === this.updateProductMappedApiCallId) {
          this.productMappedUpdateAPIData(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        }

        if (apiRequestCallId === this.getAllBioconManagerApiCallId) {
          if (!responseJson.error && !responseJson.errors) {
            this.setState({ biocon_account_managers: responseJson.biocon_account_managers })
          }
        }

        if (apiRequestCallId === this.getAllCustomerInvioceApiCallId) {
          this.setState({ isInvoiceLoading: false, isLoading: false })
          this.allCustomerInvoiceAPIData(responseJson);
        }

        if (apiRequestCallId === this.getCompaniesListApiCallId) {
          this.companiesListAPIData(responseJson);
        }

        if (apiRequestCallId === this.deleteCustomerContactApiCallId) {
          this.deleteCustomerContactAPIData(responseJson);
        }

        if (apiRequestCallId === this.deleteProductMappedApiCallId) {
          this.deleteProductMappedAPIData(responseJson);
        }

        if (apiRequestCallId === this.getAllEmployeeTerritoriesCallId) {
          if (!responseJson.error && !responseJson.errors) {
            this.setState({ biocon_manager_territories: responseJson.employee_territories })
          }
        }
      }

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getMappedProductAPICallId) {
          if (!responseJson.error) {
            const ProductsData = responseJson.products.map((product: any) => {
              return { ...product, isSelected: false }
            });
            this.setState({ products: ProductsData });
          }
        }
      }

      if (apiRequestCallId === this.getCategoriesApiCallId) {
        if (!responseJson.errors) {
          this.setCategories(responseJson);
        }
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = { ...this.txtInputWebProps, autoCompleteType: "email", keyboardType: "email-address", };

  txtInputProps = this.isPlatformWeb() ? this.txtInputWebProps : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = { source: this.txtInputProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible, };

  btnExampleProps = { onPress: () => this.doButtonPressed(), };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  deleteProductMappedAPIData = (responseJson: any) => {
    this.setState({ isDeletePopup: false, isProductMapDelete: false })
    if (!responseJson.error && !responseJson.errors) {
      successNotification("Mapped Product Deleted Successfully");
      this.getPersonalDetails();
    } else {
      errorNotification("Something went wrong")
    }
  }

  deleteCustomerContactAPIData = (responseJson: any) => {
    this.setState({ isDeletePopup: false })
    if (!responseJson.error && !responseJson.errors) {
      successNotification("Customer Contact Deleted Successfully");
      this.getPersonalDetails();
    } else {
      errorNotification("Something went wrong")
    }
  }

  companiesListAPIData = (responseJson: any) => {
    if (!responseJson.error && !responseJson.errors) {
      const companies = responseJson.all_companies.map((company: any) => {
        return {
          id: company.id,
          label: company.customer_name
        }
      });
      this.setState({ parentCompanyList: companies })
    }
  }

  allCustomerInvoiceAPIData = (responseJson: any) => {
    if (responseJson.message === "Sorry, no customer invoices found") {
      this.setState({
        allcustomerInvioce: [],
        customerInvoiceListingMeta: [],
      })
    } else if (!responseJson.error && !responseJson.errors) {
      this.setState({
        allcustomerInvioce: responseJson.result.data,
        customerInvoiceListingMeta: responseJson.result.meta,
      })
    }
  }

  updateBamProfileAPIData = (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error) {
      successNotification("BAM Details Updated Successfully");
      this.getPersonalDetails();
      this.setState({ selectedTab: 2, isBamEdit: false });
    } else {
      showApiErrorResponse(responseJson);
    }
  }

  productMappedUpdateAPIData = (responseJson: any) => {
    if (!responseJson.errors) {
      successNotification("Product Mapped Updated Successfully");
      if (this.isUpdate) {
        this.setState({ selectedTab: 3, isProductMapUpdate: false });
      } else {
        this.setState({ isCreatedPopup: true })
      }
      this.getPersonalDetails();
    } else {
      showApiErrorResponse(responseJson);
    }
  }

  setDataAfterCreateAccount = (responseJson: any) => {
    let bool;
    if (this.isUpdate) {
      this.getPersonalDetails();
      bool = false;
      successNotification("Personal Details Updated Successfully");
    } else {
      successNotification("Personal Details Created Successfully");
      bool = true;
      this.props.navigation.navigate("CustomerAdd", { id: responseJson.data.attributes.customer_master_id })
    }
    this.userId = responseJson.data.attributes.customer_master_id;
    this.setState({
      stateUserId: responseJson.data.attributes.customer_master_id,
      selectedTab: 1,
      isView: true,
      isProductMapUpdate: true,
      stateIsUpdate: true,
      isBamEdit: bool,
    });
    if (this.userId) {
      this.getPersonalDetails();
    }
  }

  setCategories = (responseJson: any) => {
    const products = responseJson.products.map((product: any) => {
      return {
        id: product[0],
        value: product[1]
      }
    });
    products.push({
      id: -1,
      value: 'Other'
    })
    this.setState({ productsList: products })
  }

  SaveCustomerContactDetails = () => {
    successNotification("Customer Contact Added Successfully");
    this.setState({
      isCustomerContactUpdate: false,
      selectedContactCustomerId: 0,
      contactPersonEmail: "",
      contactPersonName: ""
    });
    this.getPersonalDetails();
  }

  setUserPersonalDetails = (responseJson: any) => {
    this.setState({ contactName: responseJson.data.attributes.first_name + " " + responseJson.data.attributes.last_name });
    if (location.href.includes("AddContactInvoice")) {
      this.setState(() => ({
        invoiceFormDetails: {
          ...this.state.invoiceFormDetails,
          account_id: parseInt(responseJson.data.id),
        },
      }));
      this.setState({ isLoading: false })
    }
  }

  setAllListData = (responseJson: any) => {
    this.setState({
      all_customer_types: responseJson.all_customer_types,
      all_channels: responseJson.all_channels,
      all_classifications: responseJson.all_classifications,
      all_territories: responseJson.all_territories,
      all_mode_of_businesses: responseJson.all_mode_of_businesses,
      all_account_currencies: responseJson.all_account_currencies,
      industriesList: responseJson.all_industries
    });
  }

  setPersonalDetailsObjects = (responseJson: any) => {
    const customerContactDetail = responseJson.data.attributes.customer_contact || [];
    const bamDetails = responseJson.data.attributes.bam_detail.data?.attributes?.biocon_account_managers?.data || [];
    const personalDetails = responseJson.data.attributes.personal_detail.data.attributes;
    const productMapped = responseJson.data.attributes.product_mapped?.data?.attributes.product_mapped.data || [];
    this.setState({
      selectedParentCompanyId: personalDetails?.parent_customer_name_id || 0,
      personalDetailsId: responseJson.data.attributes.personal_detail.data.id || "",
      selectedCustomerContacts: responseJson.data.attributes.customer_contact,
      customerContact: customerContactDetail,
      invoiceDetail: responseJson.data.attributes.customer_contact,
      mappedProducts: productMapped,
    })
    this.setCustomerPersonalDetails(personalDetails);
    this.setCustomerBamDetails(bamDetails);
    this.setSelectedProductDetails(productMapped);
  }

  setCustomerBamDetails = (bamDetails: any) => {
    this.setState({
      bam_details: bamDetails,
      employee_territory_id: bamDetails?.territory_id || 0,
      biocon_manager: {
        email: bamDetails?.biocon_account_manager_email || '',
        country_code: bamDetails?.biocon_account_manager_country_code || '',
        phone_number: bamDetails?.biocon_account_manager_phone_number || ''
      },
    })
  }

  setCustomerPersonalDetails = (personalDetails: any) => {
    this.setState({
      classification: personalDetails.classification_id || 0,
      territory: personalDetails.territory,
      territory_id: personalDetails.territory_id,
      mode_of_business: personalDetails.mode_of_business_id || 0,
      account_currency: personalDetails.account_currency_id || 0,
      customer_contact: personalDetails.customer_contact,
      website: personalDetails.website,
      emailId: personalDetails.email,
      firstName: personalDetails.customer_name || "",
      companySapCode: personalDetails.customer_sap_code || "",
      customer_type: personalDetails.customer_type_id || 0,
      channel: personalDetails.channel_id || 0,
      billing_street: personalDetails.billing_street,
      billing_city: personalDetails.billing_city,
      billing_postal_code: String(personalDetails.billing_postal_code) || "",
      billing_state: personalDetails.billing_state,
      billing_country: personalDetails.billing_country,
      phoneNumber: personalDetails.phone_number,
      phoneCountryCode: String(personalDetails.country_code),
      address: personalDetails.address || "",
      alternateEmail: personalDetails.alternate_email,
      customerMaster: personalDetails.customer_master || "",
      industry: personalDetails.industry_id || 0,
      shipping_street: personalDetails.shipping_street,
      shipping_city: personalDetails.shipping_city,
      shipping_postal_code: String(personalDetails.shipping_postal_code) || "",
      shipping_state: personalDetails.shipping_state,
      shipping_country: personalDetails.shipping_country,
      is_same_as_billing_address: personalDetails.is_same_as_billing_address,
      subsidiaryName: personalDetails.subsidiary_name,
      designation: personalDetails.designation || "",
      landlineNumber: personalDetails.landline || "",
      areaOfBusiness: personalDetails.area_of_business || "",
    });
  }

  setSelectedProductDetails = (productMapped: any) => {
    if (productMapped && productMapped.length > 0) {
      productMapped.map((product: IProductGet) => {
        return {
          product_id: product.attributes.product_id
        }
      });
      const newList = [...this.state.products];
      newList.forEach((product: IProduct) => {
        const isSelected = productMapped.find((mapped: IProductGet) => mapped.attributes.product_id === product.id);
        if (isSelected) {
          product.isAlreadySelected = true
          product.isSelected = true
        }
        return product;
      })
      this.setState({ multipleProductForm: [{ product_id: 0 }] });
    }
  }

  async componentDidMount() {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        if (isLoggedIn(this.props.navigation)) {
          autoLogOut();
        }
      });
    });
    const authToken = isLoggedIn(this.props.navigation);
    authorizeEmployee(this.props.navigation);
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
      this.getListAPICalls();
      const userId = this.props.navigation.getParam('id');
      const userInfo = JSON.parse(localStorage.getItem("customr_master_permission") || "");
      if (userInfo?.can_update) {
        this.setState({ isViewOnly: true });
      } else {
        this.setState({ isViewOnly: false });
      }
      if (userInfo?.can_delete) {
        this.setState({ Can_delete_permission: true });
      } else {
        this.setState({ Can_delete_permission: false });
      }
      const FromTicket = localStorage.getItem("FromTicket");
      if (userId) {
        this.userId = userId;
        this.getAllDataForUser(userId);
        const isUpdate = location.href.includes("edit");
        if (isUpdate) {
          this.isUpdate = true;
          this.setState({ isView: true, stateIsUpdate: true, stateUserId: userId, isInvoiceEdit: false });
        }
        if (FromTicket === 'ticketDetail') {
          this.setState({ isEditDetails: false })
        } else {
          this.setState({ isEditDetails: true })
        }
      }
    }
  }

  getAllDataForUser = (userId: any) => {
    this.getUserPersonalDetails();
    this.getAllCustomerInvioce();
    this.getAllCustomerContacts();
    this.setState({ stateUserId: userId });
    this.getPersonalDetails();
  }

  getListAPICalls = () => {
    this.getAllList();
    this.getAllBioconManagers();
    this.getAllManagerTerritories();
    this.getAllProductsListing();
    this.getAllCompaniesList();
    this.getCategoriesList()
  }

  handleChange = (prop: any) => (event: any) => {
    if (prop === "firstName") {
      this.checkForFirstName(event, prop);
    }
    if (prop === "companySapCode") {
      const regEx2 = /^[0-9\b]+$/;
      if (event.target.value.length === 0) {
        this.setState({ ...this.state, [prop]: event.target.value });
        return true;
      }
      if (event.target.value.match(regEx2)) {
        this.setState({ ...this.state, [prop]: event.target.value });
        return true;
      } else {
        return false;
      }
    }
    if (prop === "billing_postal_code" || prop === "shipping_postal_code") {
      const regex = /^[0-9\b]+$/;
      if (regex.test(event.target.value)) {
        this.setState({ ...this.state, [prop]: event.target.value })
        return true;
      } else {
        return false;
      }
    } else {
      this.setState({ ...this.state, [prop]: event?.target?.value, is_same_as_billing_address: this.isShippingAddressChange(prop) ? false : this.state.is_same_as_billing_address });
    }
  };

  checkForFirstName = (event: any, prop: any) => {
    let usernameRegex = /^([a-zA-Z0-9]+(-| )?)+$/i;
    if (event?.target?.value?.match(usernameRegex)) {
      if (event.target.value.length < 16) {
        if (isNaN(event.target.value[0])) {
          this.setState({ ...this.state, [prop]: event.target.value, firstNameError: "" });
        } else {
          this.setState({ ...this.state, [prop]: event.target.value, firstNameError: "Please Start Name From Char" });
        }
      } else {
        this.setState({ firstNameError: "Please Enter Name Less Than 30 Character " })
      }
      return true;
    } else {
      this.setState({ ...this.state, [prop]: event?.target?.value });
      event?.target?.value !== '' && this.setState({ firstNameError: "Please Enter Valid Name" })
      return false;
    }
  }

  handleChangeTab = (event: any, newValue: any) => {
    this.setState({ selectedTab: newValue });
  };

  isShippingAddressChange = (prop: string): boolean => {
    if (prop === "shipping_street" || prop === "shipping_city" || prop === "shipping_postal_code" || prop === "shipping_state" || prop === "shipping_country") {
      return true;
    } else {
      return false;
    }
  }

  removeBamDetails = (index: number) => {
    this.state.multipleBAMForm.splice(index, 1)
    this.setState({
      multipleBAMForm: this.state.multipleBAMForm
    })
  }

  personalDetailsValidation() {
    if (this.isStringNullOrBlank(this.state.firstName) || !this.isStringNullOrBlank(this.state.firstNameError) ||
      this.isStringNullOrBlank(this.state.billing_city) || this.isStringNullOrBlank(this.state.billing_country) ||
      this.isStringNullOrBlank(this.state.billing_state) || this.isStringNullOrBlank(this.state.billing_street) ||
      this.isStringNullOrBlank(this.state.shipping_city) || this.isStringNullOrBlank(this.state.shipping_country) ||
      this.isStringNullOrBlank(this.state.shipping_state) || this.isStringNullOrBlank(this.state.shipping_street)
      || this.state.territory_id < 1 || this.state.customer_type < 1 || this.state.mode_of_business < 1 || this.state.account_currency < 1) {
      this.setState({ personalDetailsError: true })
      return false;
    } else {
      this.setState({ personalDetailsError: false })
      this.setState({ firstNameError: '' })
    }
    const websiteRegex = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/gm;
    if (this.state.website && this.state.website.trim().length > 0 && !String(this.state.website).toLocaleLowerCase().match(websiteRegex)) {
      errorNotification(configJSON.websiteError);
      return false;
    }
    return true;
  }

  onPersonalDetailsSave = () => {
    const isValid = this.personalDetailsValidation();
    if (!isValid) {
      return false
    }
    this.setState({ isLoading: true });
    const header = getHeaders({ token: this.authToken });
    const body = {
      customer_name: this.state.firstName,
      parent_customer_name_id: this.state.selectedParentCompanyId === 0 ? "" : this.state.selectedParentCompanyId,
      customer_sap_code: this.state.companySapCode,
      customer_type_id: this.state.customer_type,
      industry_id: this.state.industry,
      channel_id: this.state.channel,
      territory_id: this.state.territory_id,
      mode_of_business_id: this.state.mode_of_business,
      account_currency_id: this.state.account_currency,
      website: this.state.website,
      is_same_as_billing_address: this.state.is_same_as_billing_address,
      classification_id: this.state.classification,
      billing_street: this.state.billing_street,
      billing_city: this.state.billing_city,
      billing_postal_code: this.state.billing_postal_code,
      billing_state: this.state.billing_state,
      billing_country: this.state.billing_country,
      shipping_street: this.state.shipping_street,
      shipping_city: this.state.shipping_city,
      shipping_postal_code: this.state.shipping_postal_code,
      shipping_state: this.state.shipping_state,
      shipping_country: this.state.shipping_country,
    };
    const createAccount = apiCall({
      httpBody: body,
      header: header,
      url: configJSON.addPersonalApiEndpoint,
      httpMethod: configJSON.exampleAPiMethod,
    });
    this.createAccountApiCallId = createAccount.messageId;
    runEngine.sendMessage(createAccount.id, createAccount);
    return true;
  };

  getUserPersonalDetails = () => {
    this.setState({ isLoading: true });
    const header = getHeaders({ token: this.authToken });
    let userId: any = this.props.navigation.getParam('id')
    let url = configJSON.getPersonalDetailsApiEndPoint + `${userId}/show_user_detail`;
    const getAccount = apiCall({
      httpBody: {},
      header: header,
      url: url,
      httpMethod: configJSON.getCustomerProfileApiMethod,
    });
    this.getUserPersonalApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  };

  onBAMDetailsSave = () => {
    this.setState({biocon_manager_id: []})
    if(this.state.multipleBAMForm.length === 1) {
      if (this.state.multipleBAMForm[0].attributes.id === 0) {
        this.setState({ accountManagerError: true })
        return false;
      }
    }
    this.state.multipleBAMForm.map((data: any) => {
      if (data.attributes.id !== 0) {
        this.state.biocon_manager_id.push(data.attributes.id)
      }
    })
    if (this.state.biocon_manager_id.length === 0) {
      this.setState({ accountManagerError: true })
      return false;
    }
    this.setState({ isLoading: true });
    const body = {
      biocon_account_manager_ids: this.state.biocon_manager_id
    };
    const updateCustomerProfile = apiCall({
      httpBody: body,
      header: getHeaders({ token: this.authToken }),
      url: configJSON.createUpdateBamDetailsApiEndPoint + `customer_master_id=${this.userId}`,
      httpMethod: configJSON.createUpdateBamDetailsApiMethod,
    });
    this.updateBAMProfileApiCallId = updateCustomerProfile.messageId;
    runEngine.sendMessage(updateCustomerProfile.id, updateCustomerProfile);
  };

  onPersonalDetailsUpdate = () => {
    const isValid = this.personalDetailsValidation();
    if (!isValid) {
      return false
    }
    this.setState({ isLoading: true });
    const header = getHeaders({ token: this.authToken });
    const body = {
      customer_name: this.state.firstName,
      parent_customer_name_id: this.state.selectedParentCompanyId === 0 ? "" : this.state.selectedParentCompanyId,
      customer_sap_code: String(this.state.companySapCode),
      industry_id: this.state.industry,
      customer_type_id: this.state.customer_type,
      channel_id: this.state.channel,
      account_currency_id: this.state.account_currency,
      mode_of_business_id: this.state.mode_of_business,
      classification_id: this.state.classification,
      territory_id: this.state.territory_id,
      website: this.state.website,
      shipping_street: this.state.shipping_street,
      shipping_city: this.state.shipping_city,
      shipping_postal_code: this.state.shipping_postal_code,
      shipping_state: this.state.shipping_state,
      shipping_country: this.state.shipping_country,
      billing_street: this.state.billing_street,
      billing_city: this.state.billing_city,
      billing_postal_code: this.state.billing_postal_code,
      billing_state: this.state.billing_state,
      is_same_as_billing_address: this.state.is_same_as_billing_address,
      billing_country: this.state.billing_country,
    };
    const updateAccount = apiCall({
      httpBody: body,
      header: header,
      url: configJSON.updatePersonalDetailsApiEndPoint + this.userId,
      httpMethod: configJSON.updatePersonalDetailsApiMethod,
    });
    this.createAccountApiCallId = updateAccount.messageId;
    runEngine.sendMessage(updateAccount.id, updateAccount);
    return true;
  }

  onProductMappedDetailsSave = () => {
    let isValidated = true;
    this.state.multipleProductForm.forEach((form: any) => {
      if (form.product_id === 0) {
        isValidated = false;
      }
      this.setState({ productMappedError: false })
    });
    if (!isValidated) {
      this.setState({ productMappedError: true })
      return false;
    }
    this.setState({ isLoading: true });
    const body = {
      product_mapped_catalogues_attributes: this.state.multipleProductForm.map((form: any) => {
        return {
          catalogue_id: form.product_id
        }
      })
    };
    const updateProductMapped = apiCall({
      httpBody: body,
      header: getHeaders({ token: this.authToken }),
      url: configJSON.updateProductMappedDetailApiEndPoint + `customer_master_id=${this.userId}`,
      httpMethod: configJSON.updateProductMappedDetailApiMethod,
    });
    this.updateProductMappedApiCallId = updateProductMapped.messageId;
    runEngine.sendMessage(updateProductMapped.id, updateProductMapped);
  };

  onCustomerContactSave = () => {
    this.setState({ isLoading: true });
    if (this.state.selectedContactCustomerId === 0) {
      warningNotification(configJSON.customerMasterMandatory);
      return false
    }
    const httpBody = {
      account_id: this.state.selectedContactCustomerId
    }
    const saveCustomerContact = apiCall({
      header: getHeaders({ token: this.authToken }),
      httpBody: httpBody,
      url: configJSON.saveCustomerContactApiEndpoint + `customer_master_id=${this.userId}`,
      httpMethod: configJSON.saveCustomerContactApiMethod
    });
    this.saveCustomerContactApiCallId = saveCustomerContact.messageId;
    runEngine.sendMessage(saveCustomerContact.id, saveCustomerContact);
  }

  getPersonalDetails = () => {
    this.setState({ isLoading: true });
    const header = getHeaders({ token: this.authToken });
    const getAccount = apiCall({
      httpBody: {},
      header: header,
      url:
        configJSON.getPersonalDetailsApiEndPoint + this.userId,
      httpMethod: configJSON.getCustomerProfileApiMethod,
    });
    this.getCustomerPersonalApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  };

  getAllProductsListing = () => {
    const header = getHeaders({ token: this.authToken });
    const getAccount = apiCall({
      httpBody: {},
      header: header,
      url:
        configJSON.productMapListAPIEndPoint,
      httpMethod: configJSON.getCustomerProfileApiMethod,
    });
    this.getMappedProductAPICallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getAllList = () => {
    const header = getHeaders({ token: this.authToken });
    const getAllList = apiCall({
      httpBody: {},
      header: header,
      url: configJSON.getAllListApiEndpoint,
      httpMethod: configJSON.getAllListApiMethod,
    });

    this.getAllListApiCallId = getAllList.messageId;
    runEngine.sendMessage(getAllList.id, getAllList);
  };

  isStringNullOrBlank(str: string) {
    return str === null || str?.trim().length === 0;
  }

  handlePersonalDetailEdit() {
    this.setState({ isView: false })
  }

  handleBamEdit() {
    this.setState({
      isBamEdit: true,
      multipleBAMForm: this.state.bam_details.length > 0 ? this.state.bam_details : this.state.multipleBAMForm
    });
  }

  handleProductMapEdit() {
    this.setState({ isProductMapUpdate: true })
  }

  getCategoriesList = () => {
    const categoryApi = apiCall({
      header: getHeaders({}),
      url: configJSON.getCategoriesApiEndPoint,
      httpMethod: configJSON.getCategoriesApiEndMethod,
      httpBody: {}
    });
    this.getCategoriesApiCallId = categoryApi.messageId;
    runEngine.sendMessage(categoryApi.id, categoryApi);
  }

  handleCustomerContactAddNewClick = () => {
    if (this.props.navigation) {
      this.props.navigation.navigate("CustomerContactAdd");
    }
  }

  handleInvoiceAddNewClick = () => {
    this.setState({ isInvoiceEdit: true })
    if (this.props.navigation) {
      this.props.navigation.navigate("AddInvoice", { id: this.userId })
    }
  }

  handleInvoiceEdit = () => {
    this.setState({ isInvoiceEdit: true, })
  }

  handleAddressCheck = () => {
    this.setState({ is_same_as_billing_address: !this.state.is_same_as_billing_address, });
    if (!this.state.is_same_as_billing_address) {
      this.setState({
        shipping_street: this.state.billing_street, shipping_city: this.state.billing_city, shipping_postal_code: this.state.billing_postal_code,
        shipping_state: this.state.billing_state, shipping_country: this.state.billing_country,
      })
    }
  };

  handleIndustryChange = (event: any, value: any) => {
    this.setState({ industry: value?.props?.value });
  };

  handleContactMasterChange = (event: any, value: any) => {
    const result = this.state.allCustomerContacts.find((contact: ICustomerContacts) => contact.id === value.props.value);
    this.setState({ selectedContactCustomerId: value.props.value, contactPersonEmail: result?.email || "", contactPersonName: result?.name || "" })
  }

  handleParentCompanyChange = (event: any, value: any) => {
    this.setState({ selectedParentCompanyId: event ? event.id : 0 })
  }

  handleManagerChange = (event: any, index: any) => {
    const selectedEmployee: any = this.state.biocon_account_managers.find(manager => manager.id === event.target.value) || {}
    const Obj = {
      attributes: {
        biocon_manager: selectedEmployee,
        id: event.target.value,
        email: selectedEmployee.email,
        phone_number: selectedEmployee.phone_number,
        country_code: selectedEmployee.country_code,
        territory_name: selectedEmployee.territory,
        employee_territory_id: selectedEmployee?.territory_id
      }
    }
    this.state.multipleBAMForm[index] = Obj
    this.setState({
      multipleBAMForm: this.state.multipleBAMForm
    })
  };

  handleBioconManagerTerritoryChange = (event: any, value: any) => {
    this.setState({ employee_territory_id: value.props.value, });
  };

  handleCurrencyChange = (event: any, value: any) => {
    this.setState({ account_currency: value.props.value });
  };

  addMultipleForm = () => {
    this.setState({ multipleProductForm: [...this.state.multipleProductForm, { product_id: 0 }] })
  }

  addMultipleBamForm = () => {
    this.setState({ multipleBAMForm: [...this.state.multipleBAMForm, { attributes: { id: 0 } }] })
  }

  deleteFormElement = (index: number) => {
    const newForm = [...this.state.multipleProductForm];
    const deletedElemet: any = newForm.splice(index, 1);
    const newProducts = [...this.state.products];
    newProducts.forEach((product: IProduct) => {
      if (product.id === deletedElemet[0].product_id) {
        product.isSelected = false;
      }
      return product
    });
    this.setState({ products: newProducts, multipleProductForm: newForm })
  }

  handleModeOfBusinessChange = (event: any, value: any) => {
    this.setState({ mode_of_business: value.props.value, });
  };

  handleClassificationChange = (event: any, value: any) => {
    this.setState({ classification: value.props.value, });
  };

  handleChannelChange = (event: any, value: any) => {
    this.setState({ channel: value.props.value, });
  };

  handleCustomerTypeChange = (event: any, value: any) => {
    this.setState({ customer_type: value.props.value, });
  };

  handlechangeTerritory = (event: any, value: any) => {
    this.setState({ territory_id: value.props.value })
  };

  handlechangeProducts = (event: any, value: any, productIndex: number) => {
    const selectedValue = value.props.value;
    const updatedForm = [...this.state.multipleProductForm];
    const newProducts = [...this.state.products];
    updatedForm.forEach((form: any, i: number) => {
      if (i === productIndex) {
        form.product_id = selectedValue;
      }
      return form;
    });
    newProducts.forEach((product: IProduct) => {
      const isSelected = updatedForm.find((mapped: any) => mapped.product_id === product.id);
      if (isSelected) {
        product.isSelected = true
      } else if (!product.isAlreadySelected) {
        product.isSelected = false
      }
      return product;
    });
    this.setState({ multipleProductForm: updatedForm, products: newProducts });
  };

  handleInvoiceByIdClick = (id: any, paramsId: any) => {
    this.setState({ isInvoiceEdit: true })
  }

  getAllCustomerInvioce = () => {
    if (this.state.searchInvoiceText === '') {
      this.setState({ isInvoiceLoading: true })
    }
    const customerUserId: any = this.props.navigation.getParam('id')
    const allManagers = apiCall({
      header: getHeaders({ token: this.authToken }),
      url: configJSON.getAllCustomerInvioceApiEndPoint + `?customer_master_id=${customerUserId}&page=${this.state.page}&per_page=${this.state.perPageRecord}&query=${this.state.searchInvoiceText}`,
      httpBody: {},
      httpMethod: configJSON.getAllCustomerInvioceApiMethod
    });

    this.getAllCustomerInvioceApiCallId = allManagers.messageId;
    runEngine.sendMessage(allManagers.id, allManagers);
  }

  handlePageChange = (e: any, p: any) => {
    this.setState({ page: p, }, () => { this.getAllCustomerInvioce() });
  };

  getAllBioconManagers = () => {
    const allManagers = apiCall({
      header: getHeaders({ token: this.authToken }),
      url: configJSON.getAllBioconManagersApiEndPoint,
      httpMethod: configJSON.getAllBioconManagersApiMethod,
      httpBody: {},
    });

    this.getAllBioconManagerApiCallId = allManagers.messageId;
    runEngine.sendMessage(allManagers.id, allManagers);
  }

  getAllParentCompanyListing = () => {
    const allParentCompanies = apiCall({
      header: getHeaders({ token: this.authToken }),
      url: configJSON.getParentCompanyListingApiEndPoint,
      httpBody: {},
      httpMethod: configJSON.getParentCompanyListingApiMethod
    });
    this.getAllParentCompanyListCallId = allParentCompanies.messageId;
    runEngine.sendMessage(allParentCompanies.id, allParentCompanies);
  }

  getAllManagerTerritories = () => {
    const allManagerTerritories = apiCall({
      header: getHeaders({ token: this.authToken }),
      url: configJSON.getAllEmployeeTerritoriesApiEndPoint,
      httpBody: {},
      httpMethod: configJSON.getAllEmployeeTerritoriesApiMethod
    });
    this.getAllEmployeeTerritoriesCallId = allManagerTerritories.messageId;
    runEngine.sendMessage(allManagerTerritories.id, allManagerTerritories);
  }

  deleteCustomerContact = () => {
    const deleteContact = apiCall({
      header: getHeaders({ token: this.authToken }),
      url: configJSON.deleteCustomerContactApiEndpoint + `?customer_master_id=${this.userId}&customer_contact_id=${this.state.deleteCustomerContactId}`,
      httpBody: {},
      httpMethod: configJSON.deleteCustomerContactApiMethod
    });
    this.deleteCustomerContactApiCallId = deleteContact.messageId;
    runEngine.sendMessage(deleteContact.id, deleteContact);
  }

  getAllCustomerContacts = () => {
    this.setState({ isLoading: true })
    const getAllContacts = apiCall({
      header: getHeaders({ token: this.authToken }),
      url: configJSON.getAllApprovedCustomerMastersApiEndpoint + `?customer_master_id=${this.userId}`,
      httpBody: {},
      httpMethod: configJSON.getAllApprovedCustomreMastersApiMethod
    });
    this.getAllCustomerContactsApiCallId = getAllContacts.messageId;
    runEngine.sendMessage(getAllContacts.id, getAllContacts);
  }

  deleteProductMapped = () => {
    const deleteProduct = apiCall({
      header: getHeaders({ token: this.authToken }),
      url: configJSON.deleteProductMappedApiEndpoint + `?customer_master_id=${this.userId}&product_mapped_id=${this.state.deleteCustomerContactId}`,
      httpBody: {},
      httpMethod: configJSON.deleteProductMappedApiMethod
    });
    this.deleteProductMappedApiCallId = deleteProduct.messageId;
    runEngine.sendMessage(deleteProduct.id, deleteProduct);
  }

  handleDeleteClick = (deleteId: number) => {
    this.setState({ isDeletePopup: true, deleteCustomerContactId: deleteId });
  }

  handleDeleteProductMapped = (deleteId: number) => {
    this.setState({ isDeletePopup: true, deleteCustomerContactId: deleteId, isProductMapDelete: true });
  }

  handleIsDeleteClose = () => {
    this.setState({ isDeletePopup: false, isProductMapDelete: false, isInvoiceDelete: false })
  }

  handleIsCreatedClose = () => {
    this.props.navigation.navigate("MasterCustomer");
  }

  handleProductMappedCancel = () => {
    const newProducts = [...this.state.products];
    newProducts.forEach((product: IProduct) => {
      if (product.isSelected && !product.isAlreadySelected) {
        product.isSelected = false
      }
      return product;
    });
    this.setState({ isProductMapUpdate: false, multipleProductForm: [{ product_id: 0 }], products: newProducts })
  }

  getAllCompaniesList = () => {
    const header = getHeaders({ token: this.authToken });
    const getCompanies = apiCall({
      httpBody: {},
      header: header,
      url: configJSON.getAllCompaniesApiEndpoint,
      httpMethod: configJSON.getAllCompaniesApiMethod,
    });
    this.getCompaniesListApiCallId = getCompanies.messageId;
    runEngine.sendMessage(getCompanies.id, getCompanies);
  };

  debounce = (func: any, wait: number = 700) => {
    let Waittimer: any;
    return (...args: any[]) => {
      clearTimeout(Waittimer);
      Waittimer = setTimeout(() => func(...args), wait);
    };
  };

  debouncedCallback = this.debounce(() => this.duplicateCode(), 700);

  handleSearchInvoices = (event: any) => {
    if (event.target.value.length > 0) {
      this.setState({
        ...this.state, searchInvoiceText: event.target.value, page: 1
      }, this.debouncedCallback)
    } else {
      this.setState({ page: 1, }, () => { this.getAllCustomerInvioce() });
      this.setState({ searchInvoiceText: '' })
    }
  }

  duplicateCode = () => { this.getAllCustomerInvioce() };

  goTOBack = () => {
    this.props.navigation.goBack()
  }

  handleSaveInvoice = () => {
    if (this.state.isInvoiceDelete) {
      this.setState({ isLoading: false})
    } else if (this.state.isProductMapDelete) {
      this.deleteProductMapped();
      return;
    } else {
      this.deleteCustomerContact();
      return
    }
  }

  // Customizable Area End
}
