Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "DownloadPermissions";
exports.labelBodyText = "DownloadPermissions Body";

exports.btnExampleTitle = "CLICK ME";
exports.getFilesListApiEndpoint = "bx_block_catalogue/catalogues/open_folder";
exports.getFilesListApiMethod = "GET";
exports.getEmployeeFilesListApiEndpoint = "bx_block_catalogue/catalogues/employee_open_folder";
exports.getEmployeeFilesListApiMethod = "GET";
exports.downloadDocumentSentEmail = "bx_block_catalogue/catalogues/download_open_folder_notification";
exports.downloadDocumentSentEmailMethod = "POST";
exports.generateViewReport = "/bx_block_catalogue/catalogues/view_file_report";
exports.selectDocumentPlaceholder = "Select Document";
exports.noDataText = "No Data";
exports.departmentIdText = "department";
exports.BackButtonText = "Back";
exports.loadingText = "Loading...";
exports.DownloadButtonText = "Download";
// Customizable Area End