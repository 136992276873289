import React from "react";
import {
  Button,
  Typography,
  Card,
  CardContent,
  CardActions,
  Box,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import { IMyTicketDetail } from "./MyQueriesController";

interface IProps {
  value: IMyTicketDetail;
}
const QueryCard = (props: IProps) => {
  const { value: item } = props;
  return (
    <CustomCard>
      <CustomCardContent>
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent={"space-around"}
          style={{ width: "100%" }}
          pt={1}
        >
          <Typography
            style={webStyle.deteLabel}
            align="center"
            className="details"
          >
            {item.ticket_creation_date}
          </Typography>
          <Typography
            style={webStyle.deteLabel}
            align="center"
          >
            <Box
              style={{
                ...webStyle.cardBadge,
                ...(item.status === "Open" ? webStyle.Open : item.status === "In Progress" ? webStyle.inProgress : webStyle.Closed),
              }}
            >
              {item.status}
            </Box>
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent={"flexStart"}
          style={{ width: "100%" }}
          pt={1}
        >
          <Box style={{ width: '100%' }}>

            <CardRowWrap>
              <Typography style={webStyle.propertyLabel}>
                Ticket ID
              </Typography>
              <Typography style={webStyle.valueLabel}>
                {item.ticket_number}
              </Typography>
            </CardRowWrap>
            <CardRowWrap>
              <Typography style={webStyle.propertyLabel}>
                Request Type
              </Typography>
              <Typography style={webStyle.valueLabel}>
                {item.query_type}
              </Typography>
            </CardRowWrap>
            <CardRowWrap>
              <Typography style={webStyle.propertyLabel}>
                Sub Type
              </Typography>
              <Typography style={webStyle.valueLabel}>
                {item?.doc_name === undefined ? "General" : (item.doc_name?.length === 0 ? "General" : "Document")}
              </Typography>
            </CardRowWrap>
            <CardRowWrap>
              {item.product_name && <Typography style={webStyle.propertyLabel}>
                Product
              </Typography>
              }
              <Typography style={webStyle.valueLabel}>
                {item.product_name}
              </Typography>
            </CardRowWrap>
          </Box>
        </Box>
        {item.status_id === 2 && <Typography
          style={{
            ...webStyle.propertyLabelClose,
            alignSelf: "flex-start",
            marginLeft: "20px",
          }}
        id="statusMessage"
        >
          {item.status_message}
        </Typography>}
      </CustomCardContent>
      <CustomCardAction>
        <NavLink to={`/QueryDetails/${item.ticket_id}`}>
        <Button
          // @ts-ignore
          style={{
            ...webStyle.cardButton,
          }}
          variant="contained"
          className="knowMoreButton"
        >
          View Details
        </Button>
        </NavLink>
      </CustomCardAction>
    </CustomCard >
  );
};

const webStyle = {
  productTitle: {
    color: "rgba(4, 60, 116, 1)",
    textAlign: "center" as "center",
    height: "90px",
  },
  productTitleEven: {
    backgroundColor: "#DCDFF0",
  },
  productTitleOdd: {
    backgroundColor: "#D7E8F9",
  },
  chemicalToolTip: {
    color: "rgba(4, 60, 116, 1)",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "14px",
    padding: "12px 5px 10px 5px",
  },
  detailLabel: {
    color: "#6a74a5",
    fontSize: "18px",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    margin: "20px 24px 5px 24px",
  },
  // n:
  deteLabel: {
    color: "rgba(4, 60, 116, 1)",
    fontSize: "15px",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
  },
  propertyLabel: {
    color: "#6a74a5",
    fontSize: "15px",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    lineHeight: "30px",
  },
  propertyLabelClose: {
    color: "#6a74a5",
    fontSize: "14px",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    lineHeight: "30px",
  },
  valueLabel: {
    color: "rgba(4, 60, 116, 1)",
    fontSize: "15px",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    lineHeight: "30px",
  },
  detailLabel2: {
    color: "#6a74a5",
    fontSize: "18px",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    margin: "0px 24px 0px 24px",
  },
  detailValueOdd: {
    marginLeft: "5px",
    color: "#459eff",
    fontSize: "18px",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    margin: "0px 0px 0px 0px",
  },
  detailValueEven: {
    marginLeft: "5px",
    color: "#459eff",
    fontSize: "18px",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    margin: "0px 0px 0px 0px",
  },
  cardButton: {
    borderRadius: "8px",
    fontSize: '14px',
    top: '86%',
    height: '32px',
    fontFamily: "SFProDisplay",
    fontWeight: 500,
    boxShadow: "none",
    backgroundColor: "rgba(4, 60, 116, 1)",
    color: "#c6e4f7",
    position: 'absolute',
    bottom: '15px',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  cardBadge: {
    borderRadius: "21px",
    fontFamily: "SFProDisplay",
    fontSize: '14px',
    fontWeight: 500,
    boxShadow: "none",
    padding: "5px 15px",
    color: "#d73f3a",
    backgroundColor: "#f7edec",
  },
  cardButtonEven: {
    background: "#043c74",
    color: "#fff",
    fontSize: "16px",
    borderRadius: "8px",
    fontFamily: "SFProDisplay",
    fontWeight: 500,
    margin: "59px 0",
    boxShadow: "none",
  },
  cardButtonOdd: {
    background: "#043c74",
    color: "#fff",
    fontSize: "18px",
    borderRadius: "8px",
    margin: "59px 0",
    fontFamily: "SFProDisplay",
    fontWeight: 500,
    boxShadow: "none",
  },
  dotVal: {
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    fontSize: "24px",
    color: "rgba(0, 0, 0, 0.85)",
    padding: "0px 0px 0px 0px",
  },
  ReadMoreLabel: {
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    fontSize: "15px",
    color: "rgba(0, 169, 255, 1)",
    padding: "0px 0px 0px 1px",
    textDecoration: "none",
    marginLeft: "2px",
    "&:hover": {
      textDecoration: "undeNavLinke",
    },
    cursor: "hand",
  }, Open: {
    backgroundColor: '#EBF7F3',
    color: '#85C67A',
    border: '1px solid #EBF7F3',
    cursor: 'arrow',
  },
  inProgress: {
    backgroundColor: '#FFF7E6',
    color: '#EBC74F',
    border: '1px solid #FFF7E6',
    cursor: 'arrow',
  },
  Closed: {
    backgroundColor: '#F8ECEE',
    color: '#CE4436',
    border: '1px solid #F8ECEE',
    cursor: 'arrow',
  },
};

export default QueryCard;

const CustomCardContent : any = withStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px",
  },
})(CardContent);

const CustomCardAction : any = withStyles({
  root: {
    justifyContent: "center",
    padding: "0px",
  },
})(CardActions);

const CardRowWrap: any = withStyles({
  root: {
    display: 'flex',
    gap: '10px',
    '& > p:nth-child(1)': {
      width: '40%',
    },
  },
})(Typography);

const CustomCard : any = withStyles({
  root: {
    padding: '15px',
    borderRadius: "16px",
    borderColor: "rgba(185, 187, 197, 1)",
    borderWidth: "2px",
    margin: "0px 15px",
    position: 'relative',
    paddingBottom: '56px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    width: '100%',
    "& .MuiBox-root-24": {
      paddingTop: '0px'
    },
    "& .MuiPaper-elevation1": {
      boxShadow: `0px 2px 1px - 1px #dae0f0 ,
      0px 1px 1px 0px #dae0f0 ,
      0px 1px 3px 0px #dae0f0`,
    },
    "& .MuiCardHeader-root": {
      padding: "0px",
    },
    "&:hover": {
      boxShadow: "0px 4px 30px 0 rgba(0, 0, 0, 0.18)",
      "& .knowMoreButton": {
        boxShadow: `0 2px 4px 0 rgba(0, 0, 0, 0.4) !important`,
      },
    },
    "@media (min-width: 1280px) and (max-width: 1381px)": {
      "& .details": {
      },
    },
  },
})(Card);
