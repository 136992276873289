import React, { useEffect, useState } from "react";
import Select, { components, createFilter } from "react-select";

interface ICompanyNameSelector {
    dataSource: any[];
    selectedValue: any;
    handleChange: (event: any, value: any) => void;
    style: any;
    placeHolder: string;
    isDisabled: boolean;
    isClearable?: boolean;
    noOptionMessage?: string;
    noOptionMessageColor?: string | 'rgba(4, 60, 116, 1)';
    onFocus?: () => void;
    onMenuClose?: () => void;
    isCustomFilter?: boolean;
    setCustomName?: (value: string) => void;
}

const filterOption = createFilter({});



const CompanyNameSelector = ({ dataSource, selectedValue, noOptionMessageColor, onMenuClose, handleChange, onFocus, style, placeHolder, isDisabled, isClearable, noOptionMessage = "No Options", isCustomFilter, setCustomName }: ICompanyNameSelector) => {
    const [selected, setSelected] = useState(null);
    const [options, setOptions] = useState<any[]>([]);
    useEffect(() => {
        const selectedObj = dataSource.find((company: any) => String(company.id) === String(selectedValue));
        setSelected(selectedObj);
        setOptions(dataSource);

    }, [selectedValue, dataSource]);

    const filterAllOptions = (inputValue: string) => {
        const filteredOptions = dataSource.filter((o) => filterOption(o, inputValue));
        if (filteredOptions.length === 0) {
            if (isCustomFilter) {
                filteredOptions.push({ id: -1, label: "Others" });
                if (setCustomName) {
                    setCustomName(inputValue);
                }
            }
        }
        setOptions(filteredOptions)
    };

    const NoOptionsMessage = (props: any) => {
        return (
            <components.NoOptionsMessage {...props} style={{ textAlign: 'left' }}>
                <span className="custom-css-class"
                    style={{ color: noOptionMessageColor }}
                >{noOptionMessage}
                </span>
            </components.NoOptionsMessage>
        );
    };

    const customStyles = {
        menu: (provided: any, state: any) => ({
            ...provided,
            zIndex: 100
        }),
        singleValue: (provided: any, state: any) => {
            const transition = 'opacity 300ms';
            const color = state.isDisabled ? 'rgba(106, 116, 165, 1)' : 'black';
            return { ...provided, transition, color };
        },
        indicatorSeparator: () => ({ display: 'none' }),
        control: (provided: any, state: any) => ({
            ...provided,
            border: '1px solid #dae0f1',
            boxShadow: 'none',
            backgroundColor: state.isDisabled ? '#F1F4FA' : 'white',
            borderRadius: '8px',
            fontSize: '14px',
            height: 35,
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
            '&:hover': {
                border: '1px solid black',
            }
        })
    }

    return (
        <Select
            data-testid="companyselect"
            style={style}
            options={options}
            placeholder={placeHolder}
            onChange={handleChange}
            value={selected}
            isDisabled={isDisabled}
            getOptionValue={(option) => option.id}
            isClearable={isClearable}
            filterOption={() => true}
            onInputChange={filterAllOptions}
            noOptionsMessage={() => noOptionMessage}
            components={{ NoOptionsMessage }}
            onFocus={onFocus}
            onMenuClose={onMenuClose}
            styles={customStyles}
        />
    )
}

export default CompanyNameSelector