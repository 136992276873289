Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ReviewAndApproval";
exports.labelBodyText = "ReviewAndApproval Body";

exports.btnExampleTitle = "CLICK ME";
exports.getApiDataEndpoint = "bx_block_landingpage3/landing_pages/api";
exports.getApiDataApiMethod = "GET";
exports.mediaConditionText = "Media attached is video";
exports.condition1 = "<p>&nbsp;</p>\r\n\r\n<ul>\r\n</ul>\r\n";
// Customizable Area End