import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  apiCall,
  errorNotification,
  getHeaders,
  events,
  isLoggedIn,
  autoLogOut,
  isGuest,
} from "../../../components/src/common";
//import { default as updatesData } from "./UpdatesData.json";
const updatesData = [
  {
    id: "1",
    type: "new_update",
    attributes: {
      title: "New Drugs for cardiology treatment",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua,Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua,s",
      priority: "1",
      video: "https://www.youtube.com/embed/AAmba8JMtAg"
    }
  },
  {
    id: "2",
    type: "new_update",
    attributes: {
      title: "New Drugs for cardiology treatment",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua,Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua,Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua,Lorem ipsum dolor sit amet, consectetur adipiscing elit, ",
      priority: "1",
      video: "https://www.youtube.com/embed/muuK4SpRR5M"
    }
  },
  {
    id: "3",
    type: "new_update",
    attributes: {
      title: "New Drugs for cardiology treatment",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua,",
      priority: "1",
      video: "https://www.youtube.com/embed/muuK4SpRR5M"
    }
  },
  {
    id: "4",
    type: "new_update",
    attributes: {
      title: "New Drugs for cardiology treatment",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua,",
      priority: "1",
      video: "https://www.youtube.com/embed/muuK4SpRR5M"
    }
  }
];

export interface ILandingPagebanners {
  id: string;
  type: string;
  attributes: {
    redirect_url: string;
    file: string;
    file_attached: string;
    rank: string;
  };
}

export interface ILandingProducts {
  manufacturing_platform: string;
  category_id: number;
  chemical_name: string;
  composition: string;
  id: number;
  name: string;
  product_description: string;
  product_dmf: string;
  product_type: string;
  therapeutic_area: string;
}

export interface ILandingUpdatesSearch {
  id: number;
  description: string;
  title: string;
  file: string;
  thumbnail: string;
  file_attached: string;
  isClicked: boolean;
}
export interface ILandingPageUpdate {
  id: string;
  type: string;
  isClicked: boolean;
  attributes: {
    title: string;
    description: string;
    priority: string;
    file: string;
    file_attached: string;
    thumbnail: string;
  };
}

export interface ILandigPageTeamDetails {
  id: string;
  type: string;
  attributes: {
    full_name: string;
    designation: string;
    description: string;
    rank: number;
    image: string;
  };
}
let homePageContentApiCallId: string;

export interface IProductListingMeta {
  pagination_details: {
    current_count: number;
    current_page: number;
    next_page: number;
    prev_page: null | number;
    total_count: number;
    total_pages: number;
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  updatesArray: any;
  banners: ILandingPagebanners[] | null;
  updates: ILandingPageUpdate[] | null;
  teamDetails: ILandigPageTeamDetails[] | null;
  screenSlides: 6;
  IsViewAll: boolean;
  productsListingMeta: null | IProductListingMeta;
  products: null | ILandingProducts[];
  page: number;
  searchTerm: string;
  isSeach: boolean;
  productsViewAll: boolean;
  isLoading: boolean;
  searchNewUpdates: null | ILandingUpdatesSearch[];
  isSearchError: boolean;
  redirect_url: string;
  loading: boolean;
  showFlashLogin: boolean;
  phoneCountryCode: any;
  phoneNumber: any;
  email: any;
  emailError: boolean;
  firstName: any;
  companyName: any;
  userDetails: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  BannerApiCallId: string;
  searchApiCallId: string;
  GuestLoginApiCallId: string;
  authToken: string;
  homePageContentApiCallId: any;
  InvoicePermissionAPICallID: any;
  QueryPermissionAPICallID: any;
  HeaderOptionPermissionAPICallID: any;

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      updatesArray: updatesData,
      banners: null,
      updates: null,
      teamDetails: null,
      screenSlides: 6,
      IsViewAll: false,
      page: 1,
      productsListingMeta: null,
      searchTerm: "",
      isSeach: false,
      products: null,
      productsViewAll: false,
      isLoading: false,
      searchNewUpdates: null,
      isSearchError: false,
      redirect_url: "/bannerVideo",
      loading: false,
      showFlashLogin: false,
      phoneCountryCode: "",
      phoneNumber: "",
      email: "",
      emailError: false,
      firstName: "",
      companyName: "",
      userDetails: "",
    };
    this.setBannerData = this.setBannerData.bind(this);
    this.setGuestAPIData = this.setGuestAPIData.bind(this);
    this.setPermissionData = this.setPermissionData.bind(this);
    this.setCondition = this.setCondition.bind(this);
    this.setConditions = this.setConditions.bind(this);
    this.handlePhoneCountryCode = this.handlePhoneCountryCode.bind(this);
    this.showFlashLogin = this.showFlashLogin.bind(this);
    this.showFullVideo = this.showFullVideo.bind(this);
    this.setProductIsViewAll = this.setProductIsViewAll.bind(this);
    this.setIsViewAll = this.setIsViewAll.bind(this);
    this.handleKnowMoreClick = this.handleKnowMoreClick.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handlePhoneCountryCode = this.handlePhoneCountryCode.bind(this);
    this.goToHome = this.goToHome.bind(this);
    this.getBanners = this.getBanners.bind(this);
    this.returnBoolenValue = this.returnBoolenValue.bind(this);
    this.getHomePageContent = this.getHomePageContent.bind(this);
    this.handleSearchApi = this.handleSearchApi.bind(this);
    this.employeeHeaderOptionPermission = this.employeeHeaderOptionPermission.bind(this);
    this.setTeamDetails = this.setTeamDetails.bind(this);
    this.setBannerDetails = this.setBannerDetails.bind(this);
    this.setSearchData = this.setSearchData.bind(this);
    this.setUpdatesData = this.setUpdatesData.bind(this);
    this.getQueryPermissions = this.getQueryPermissions.bind(this);
    this.getInvoicesPermission = this.getInvoicesPermission.bind(this);
    this.setNextCondition = this.setNextCondition.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (this.homePageContentApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        this.setState({loading: false})
        this.setHomeContent(responseJson);
      }

      if (this.searchApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        this.setState({ isLoading: false, loading: false });
        this.setSearchData(responseJson);
      }
    }

    if (this.BannerApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.setState({loading: false});
      this.setBannerData(responseJson);
    }

    if (this.GuestLoginApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.setState({loading: false});
      this.setGuestAPIData(responseJson);
    }

    if (this.InvoicePermissionAPICallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.setState({loading: false});
      if (!responseJson.errors) {
        localStorage.setItem("customer_invoice", responseJson.invoice_permission.visible)
      }
    }

    if (this.QueryPermissionAPICallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.setState({loading: false});
      if (!responseJson.errors) {
        this.setQueryPermission(responseJson)
      }
    }

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.HeaderOptionPermissionAPICallID) {
      this.setPermissionData(responseJson.data, this.state.userDetails)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  setBannerData = (responseJson: any) => {
    if (responseJson.upload_media?.data[0].attributes.file_attached === "Media attached is video") {
      this.setState({
        redirect_url:
          responseJson.upload_media?.data[0].attributes.redirect_url
      });
      const newWindow = window.open(
        this.state.redirect_url ||
        responseJson.upload_media?.data[0].attributes.redirect_url,
        "noopener,noreferrer"
      );
      if (newWindow) {
        newWindow.opener = null;
      }
    }
  }

  setGuestAPIData = (responseJson: any) => {
    if (!responseJson.errors) {
      localStorage.setItem("guestData", JSON.stringify(responseJson?.data?.attributes));
      this.setState({
        showFlashLogin: false
      })
    } else {
      errorNotification("something went wronge");
    }
  }

  setSearchData = (responseJson: any) => {
    if (responseJson.success) {
      const teamDetails = responseJson.our_team.map((teamDetail: any) => {
        const newTeamDetail = {
          id: teamDetail.id,
          type: "team",
          attributes: {
            full_name: teamDetail.full_name,
            designation: teamDetail.designation,
            description: teamDetail.description,
            rank: teamDetail.rank,
            image: teamDetail.image
          }
        }
        if (!newTeamDetail.attributes.rank) {
          newTeamDetail.attributes.rank = 100000;
        }
        return newTeamDetail;
      })
        .sort((a: any, b: any) => {
          if (a.attributes.rank > b.attributes.rank) {
            return 1;
          }
          if (a.attributes.rank < b.attributes.rank) {
            return -1;
          }
          return 0;
        });
      const newSearchUpdates = responseJson.new_updates.map(
        (update: ILandingUpdatesSearch) => {
          update.isClicked = false;
          return update;
        }
      );
      this.setState({
        products: responseJson.products,
        searchNewUpdates: newSearchUpdates,
        teamDetails: teamDetails,
        isSearchError: false
      });
    } else if (responseJson.error || responseJson.errors) {
      errorNotification(responseJson.error);
      this.setState({
        searchNewUpdates: [],
        products: [],
        teamDetails: [],
        isSearchError: this.returnBoolenValue(responseJson.error)
      });
    }
  }

  setHomeContent = (responseJson: any) => {
    if (responseJson.all_banners && responseJson.all_quick_links && responseJson?.all_team_details) {
      const newUpdates = this.setUpdatesData(responseJson)
      const teamDetails = this.setTeamDetails(responseJson)
      const banners = this.setBannerDetails(responseJson);
      this.setState({
        banners: banners,
        updates: newUpdates,
        teamDetails: teamDetails
      });
    }
  }

  setTeamDetails = (responseJson: any) => {
    const teamDetails = responseJson.all_team_details?.data
        .map((teamDetail: ILandigPageTeamDetails) => {
          if (!teamDetail.attributes.rank) {
            teamDetail.attributes.rank = 100000;
          }
          return teamDetail;
        })
        .sort((a: ILandigPageTeamDetails, b: ILandigPageTeamDetails) => {
          if (a.attributes.rank > b.attributes.rank) {
            return 1;
          }
          if (a.attributes.rank < b.attributes.rank) {
            return -1;
          }
          return 0;
        });
        return teamDetails;
  }

  setUpdatesData = (responseJson: any) => {
    const newUpdates = responseJson.all_quick_links?.data
      .map((update: ILandingPageUpdate) => {
          update.isClicked = false;
          return update;
      })
      .sort((a: ILandingPageUpdate, b: ILandingPageUpdate) => {
          if (a.attributes.priority > b.attributes.priority) {
            return 1;
          }
          if (a.attributes.priority < b.attributes.priority) {
            return -1;
          }
          return 0;
      });
    return newUpdates;
  }

  setBannerDetails = (responseJson: any) => {
      const banners = responseJson.all_banners?.data
        .map((bannerDetail: ILandingPagebanners) => {
          if (!bannerDetail.attributes.rank) {
            bannerDetail.attributes.rank = "100000";
          }
          return bannerDetail;
        })
        .sort((a: ILandingPagebanners, b: ILandingPagebanners) => {
          if (a.attributes.rank > b.attributes.rank) {
            return 1;
          }
          if (a.attributes.rank < b.attributes.rank) {
            return -1;
          }
          return 0;
        });
      return banners;
  }

  async componentDidMount() {
    if(!isGuest()) {
      Object.values(events).forEach((item) => {
        window.addEventListener(item, () => {
          autoLogOut();
        });
      });
      this.getInvoicesPermission();
      this.getQueryPermissions();
      this.employeeHeaderOptionPermission();
    }
      document.addEventListener(
      "play",
      function (e) {
        var videos = document.getElementsByTagName("video");
        for (var i = 0, len = videos.length; i < len; i++) {
          if (videos[i] !== e.target) {
            videos[i].pause();
          }
        }
      },
      true
    );
    const temp = localStorage.getItem('landingPageApiCount')
    if (temp === '0') {
      localStorage.setItem("landingPageApiCount", '1')
      this.getHomePageContent();
    } else if (temp === '1') {
      localStorage.setItem("landingPageApiCount", '0')
      this.getHomePageContent();
    }
    const userDetails = JSON.parse(localStorage.getItem("userInfo") || "");
    this.setState({userDetails: userDetails})
  }


  returnBoolenValue = (condition: any) => {
    if (condition) {
      return true;
    } else {
      return false
    }
  }

  setQueryPermission = (responseJson: any) => {
    localStorage.setItem("employee_invoice_permission", 'false')
    localStorage.setItem("employee_document_view_permission", 'false')
    localStorage.setItem("employee_product_request_permission", 'false')
    localStorage.setItem("employee_sample_request_permission", 'false')
    localStorage.setItem("employee_meeting_request_permission", 'false')
    localStorage.setItem("employee_connectwithus_request_permission", 'false')

    responseJson.data?.forEach((element: any) => {
      element.attributes.roles.forEach((element2: any) => {
        if(element2.id === this.state.userDetails.attributes.role_id) {
          this.setConditions(element)
        }
      });
    });
  }

  setPermissionData = (responseJson: any, userDetails: any) => {
    localStorage.setItem("Contact_Master", 'false');
    localStorage.setItem("View_My_Tickets", 'false');
    localStorage.setItem("Create_New_Ticket", 'false');
    localStorage.setItem("Customer_Master", 'false');
    let permission = {
      can_delete: false,
      can_read: false,
      can_create: false,
      can_update: false,
    }
    localStorage.setItem("customr_master_permission", JSON.stringify(permission))
    localStorage.setItem("contact_master_permission", JSON.stringify(permission))
    responseJson?.forEach((element: any) => {
      element.attributes.sub_menu.data.forEach((data: any) => {
        data.attributes.roles?.data?.forEach((roles: any) => {
          if(userDetails.attributes.role_id === roles.attributes.role_id) {
                this.setCondition(data, roles)
              }
          });
      });
  });
  }

  setConditions = (element:any) => {
    if (element.attributes?.query_type === 'BxBlockDynamiccontent::InvoicePermission') {
      localStorage.setItem("employee_invoice_permission", 'true')
    }
    if (element.attributes?.query_type === 'BxBlockCatalogue::ProductDocument') {
      localStorage.setItem("employee_document_view_permission", 'true')
    }
    if (element.attributes?.query_type === 'BxBlockCustomerProfile::ConnectWithU') {
      localStorage.setItem("employee_connectwithus_request_permission", 'true')
    }
    this.setNextCondition(element)
  }

  setNextCondition = (element: any) => {
    if (element.attributes?.query_type === 'BxBlockCatalogue::SampleRequest') {
      localStorage.setItem("employee_sample_request_permission", 'true')
    }
    if (element.attributes?.query_type === 'BxBlockCatalogue::ProductQuery') {
      localStorage.setItem("employee_product_request_permission", 'true')
    }
    if (element.attributes?.query_type === 'BxBlockCatalogue::MeetingRequest') {
      localStorage.setItem("employee_meeting_request_permission", 'true')
    }
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  getHomePageContent() {
    const headers = getHeaders({});
    const homePageApi = apiCall({
      url: configJSON.homePageContentApiEndPoint,
      httpBody: {},
      httpMethod: configJSON.homePageContentApiMethod,
      header: headers
    });

    this.homePageContentApiCallId = homePageApi.messageId;
    runEngine.sendMessage(homePageApi.id, homePageApi);
    this.setState({
      loading: true
    });
  }

  setIsViewAll = () => {
    this.setState({ IsViewAll: !this.state.IsViewAll });
  };

  setProductIsViewAll = () => {
    this.setState({ productsViewAll: !this.state.productsViewAll });
  };
  handleKnowMoreClick = (id: string) => {
    if (this.props.navigation) {
      this.props.navigation.navigate("ProductDetails", { id });
      localStorage.setItem("from", "product");
    }
  };

  handlePageChange = (e: any, p: any) => {
    this.setState(
      {
        page: p
      },
      () => {
        // this.handleFilterAndSearch();
        // console.log("");
      }
    );
  };

  handleSearch = (e: any) => {
    const value = e.target.value;
    this.setState(
      {
        searchTerm: value ? value.trim() : "",
        isSeach: value ? (value.trim().length > 0 ? true : false) : false
      },
      () => {
        if (value.trim().length > 0) {
          this.handleSearchApi();
        } else {
          this.getHomePageContent();
        }
      }
    );
  };

  handleSearchApi = () => {
    const searchApi = apiCall({
      header: getHeaders({}),
      url:
        configJSON.searchLandingPageApiEndPoint +
        `?query=${this.state.searchTerm}&per_page=6&page=${this.state.page}`,
      httpBody: {},
      httpMethod: configJSON.searchLandingPageApiMethod
    });

    this.searchApiCallId = searchApi.messageId;
    this.setState({
      isLoading: true,
      isSearchError: false
    });
    runEngine.sendMessage(searchApi.id, searchApi);
    this.setState({
      loading: true
    })
  };

  showFullVideo = () => {
    this.getBanners();
  };

  getBanners() {
    const header = getHeaders({ token: this.authToken });

    const homePageApi = apiCall({
      url: configJSON.showBannerAPIEndPoint,
      httpBody: {},
      httpMethod: configJSON.homePageContentApiMethod,
      header: header
    });
    this.BannerApiCallId = homePageApi.messageId;
    runEngine.sendMessage(homePageApi.id, homePageApi);
    this.setState({
      loading: true
    })
  };

  showFlashLogin = () => {
    this.setState({
      showFlashLogin: !this.state.showFlashLogin
    })
  };

  handlePhoneCountryCode(event: any, value: any) {
    if (value) {
      this.setState({
        phoneCountryCode: event.value,
      });
    }
  };

  getInvoicesPermission = () => {
    const authToken = localStorage.getItem("authToken");
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": authToken
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.InvoicePermissionAPICallID = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/customer_masters/invoice_permission`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getQueryPermissions = () => {
    const authToken = localStorage.getItem("authToken");
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": authToken
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.QueryPermissionAPICallID = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/catalogues/product_query_types`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  employeeHeaderOptionPermission = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.HeaderOptionPermissionAPICallID = getValidationsMsg.messageId;

    getValidationsMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.EmployeeHeaderOptionPermissionAPIEndPoint);
    getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  setCondition = (element2: any, roles: any) => {
    if (element2.attributes?.name === 'View My Tickets') {
      let ticketPermission = {
        can_delete: roles.attributes.can_delete,
        can_create: roles.attributes.can_create,
        can_read: roles.attributes.can_read,
        can_update: roles.attributes.can_update,
      }
      localStorage.setItem("View_My_Tickets", JSON.stringify(ticketPermission))
    }
    if (element2.attributes?.name === 'Create New Ticket') {
      let permission = {
        can_create: roles.attributes.can_create,
        can_delete: roles.attributes.can_delete,
        can_read: roles.attributes.can_read,
        can_update: roles.attributes.can_update,
      }
      localStorage.setItem("Create_New_Ticket", JSON.stringify(permission))
    }
    if (element2.attributes?.name === 'Contact Master') {
      localStorage.setItem("Contact_Master", JSON.stringify(roles.attributes.can_read))
      let permission = {
        can_delete: roles.attributes.can_delete,
        can_create: roles.attributes.can_create,
        can_read: roles.attributes.can_read,
        can_update: roles.attributes.can_update,
      }
      localStorage.setItem("contact_master_permission", JSON.stringify(permission))
    }
    if (element2.attributes?.name === 'Customer Master') {
      let permission = {
        can_delete: roles.attributes.can_delete,
        can_create: roles.attributes.can_create,
        can_update: roles.attributes.can_update,
        can_read: roles.attributes.can_read,
      }
      localStorage.setItem("customr_master_permission", JSON.stringify(permission))
      localStorage.setItem("Customer_Master", JSON.stringify(roles.attributes.can_read))
    }
  }
  // Customizable Area End
}
