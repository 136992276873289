import React from "react";
import {
  Grid,
  Typography,
  TextField,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Button,
  InputAdornment,
  IconButton,
  Box,
  Divider,
} from "@material-ui/core";
import {
  // LoginImage,
  BioLogo,
  Background,
  ConnectLogo,
  LoginImage4,
} from "./assets";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import { withStyles } from "@material-ui/core/styles";
import LoginScreenWebController, {
  Props,
} from "./LoginScreenZeplinController.web";

export default class ZeplinLoginScreenWeb extends LoginScreenWebController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    //@ts-ignore
    const { navigation } = this.props;
    return (
      <>
        <MainContainer container>
          <Grid
            item
            style={{
              position: "relative",
              width: "53.4%",
            }}
          >
            <Box sx={webStyle.backImageSpace}>
              <img
                style={webStyle.signupImage}
                src={LoginImage4}
                alt=""
                className="signupImg"
              />
            </Box>
            {/* <WelcomeCard>
                            <Typography style={webStyle.welcomeText} className="welcomeText">
                                {this.state.welcomeText}
                            </Typography>
                            <Typography style={webStyle.startedText}>
                                {this.state.startedText}
                            </Typography>
                            <Typography style={webStyle.serviceText}>
                                {this.state.serviseText}
                            </Typography>
                        </WelcomeCard> */}
          </Grid>
          <RightBox item lg={6} md={6} sm={6} xs={12}>
            <RightGrid item lg={12} md={12} sm={12} xs={12}>
              <Grid container style={{ maxWidth: "491px" }}>
                <img src={BioLogo} style={webStyle.LogoImage} />
                <Divider orientation="vertical" style={webStyle.divider} />
                <img
                  src={ConnectLogo}
                  style={webStyle.connectLogo}
                  className="blendLogo"
                />
              </Grid>
              <Typography style={webStyle.Header}>
                {this.state.loginHeader}
              </Typography>
              <Typography style={webStyle.subHeader}>
                {this.state.loginSubHeading}
              </Typography>

              <form onSubmit={this.doEmailLoginWeb}>
                <InputLabel
                  htmlFor="txtInputEmail"
                  style={webStyle.EmailinputLabel}
                >
                  Email address
                </InputLabel>
                <InputField
                  id="txtInputEmail"
                  variant="outlined"
                  placeholder="example@gmail.com"
                  required
                  onChange={this.handleChange("email").bind(this)}
                  value={this.state.email}
                  fullWidth
                />

                <InputLabel
                  htmlFor="passwordInput"
                  style={webStyle.PassinputLabel}
                >
                  Password
                </InputLabel>
                <PasswordField
                  id="passwordInput"
                  variant="outlined"
                  required
                  value={this.state.password}
                  onChange={this.handleChange("password").bind(this)}
                  placeholder="• • • • • • • • • • • • •"
                  type={this.state.showPassword ? "text" : "password"}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          edge="end"
                          style={{ color: "lightgray" }}
                        >
                          {this.state.showPassword ? (
                            <VisibilityOffOutlinedIcon />
                          ) : (
                            <VisibilityOutlinedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Grid
                  container
                  justifyContent="space-between"
                  style={{ maxWidth: "491px" }}
                >
                  <Box display={"flex"} style={{ marginTop: "2.2%" }}>
                    <CheckBoxLabel
                      control={
                        <Checkbox
                          color="default"
                          disableRipple
                          checked={this.CustomCheckBoxProps.isChecked}
                          onChange={this.btnRememberMeProps.onPress}
                          checkedIcon={<span style={webStyle.checkedIcon} />}
                          icon={<span style={webStyle.icon} />}
                          inputProps={{ "aria-label": "decorative checkbox" }}
                        />
                      }
                      label=""
                    />
                    <Typography
                      style={webStyle.termsText}
                      component={"span"}
                      align="center"
                    >
                      Remember Password
                    </Typography>
                  </Box>
                  <Typography
                    onClick={() => {
                      navigation.navigate("ForgotPassword");
                    }}
                    style={webStyle.PassText}
                  >
                    {this.state.forgotPasslabel}
                  </Typography>
                </Grid>

                <Grid container style={{ maxWidth: "491px" }}>
                  <Buttons type="submit" variant="contained">
                    Login
                  </Buttons>
                </Grid>

                <Grid
                  container
                  justifyContent="center"
                  style={{ maxWidth: "491px", margin: "0px 8px 0 0px" }}
                >
                  <Typography style={webStyle.AccountText}>
                    {this.state.AccountText}
                  </Typography>
                  <Typography
                    onClick={() => {
                      navigation.navigate("Registration");
                    }}
                    style={webStyle.singUp}
                  >
                    {this.state.signUpText}
                  </Typography>
                </Grid>
              </form>
            </RightGrid>
          </RightBox>
        </MainContainer>
      </>
    );
  }
}

// Customizable Area Start
const webStyle = {
  backImageSpace: {
    // maxWidth: '901px',
    padding: "0 1.9% 0 0",
  },
  signupImage: {
    width: "99.0%",
    height: "100vh",
  },
  serviceText: {
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "16px",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    color: "#fff",
  },
  welcomeText: {
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "48px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#fff",
  },
  startedText: {
    margin: "1.3% 0 1.6%",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "24px",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    color: "#fff",
  },
  serviseText: {
    color: "#fff",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: 1.25,
    paddingBottom: "30px",
    letterSpacing: "normal",
    paddingLeft: "30px",
    fontStyle: "normal",
  },
  LogoImage: {
    width: "25.6%",
    height: "44px",
    margin: "0px 0px 3% 0",
  },
  Header: {
    margin: "0 10px 1.1% 0",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "20px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#043c74",
  },
  subHeader: {
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "14px",
    color: "#6a74a5",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    margin: "1.1% 32px 3.4% 0",
  },
  EmailinputLabel: {
    fontSize: "16px",
    fontFamily: "DMSans-Regular",
    color: "#3a3f63",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    margin: "0px 10px 1.6% 0",
  },
  PassinputLabel: {
    fontSize: "16px",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    color: "#3a3f63",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    margin: "2.4% 36px 1.9% 0",
  },
  icon: {
    borderRadius: "6px",
    border: "solid 1px #3a3f63",
    backgroundColor: "#fbfcff",
    width: 24,
    height: 24,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    color: "rgba(58, 63, 99, 1)",
    "input:hover ~ &": {
      backgroundColor: "#fbfcff",
    },
    "&:hover": {
      backgroundColor: "#fbfcff",
    },
  },
  checkedIcon: {
    borderRadius: "6px",
    border: "solid 1px #3a3f63",
    backgroundColor: "#fbfcff",
    width: 24,
    height: 24,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='black'/%3E%3C/svg%3E\")",
    "input:hover ~ &": {},
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  termsText: {
    color: "#3a3f63",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "16px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    margin: "2.2% 0px 3.4% 12px",
  },
  PassText: {
    color: "#459eff",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "16px",
    cursor: "pointer",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    margin: "3.2% 0 4.3% 0px",
  },
  AccountText: {
    color: "#3a3f63",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "16px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },
  singUp: {
    color: "#459eff",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "16px",
    cursor: "pointer",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    margin: "0px 0 0px 15px",
  },
  divider: {
    height: "42px",
    width: "1px",
    backgroundColor: "#6a74a5",
    margin: "0px 5.9% 5.4%",
  },
  connectLogo: {
    width: "28.4%",
    height: "44px",
    margin: "0 0px 5.6% 0",
  },
};

// const WelcomeCard: any = withStyles({
//   root: {
//     position: "absolute",
//     top: "75%",
//     left: "48%",
//     transform: "translate(-50%, -50%)",
//     width: "80%",
//     display: "flex",
//     flexDirection: "column",
//     "@media (max-height: 800px)": {
//       "@media (max-width: 1000px)": {
//         width: "90%",
//         left: `48%`,
//       },
//       "& .welcomeText": {
//         fontSize: `26px !important`,
//       },
//     },
//   },
// })(Grid);

const RightBox: any = withStyles({
  root: {
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    margin: "3.9% 0 2% 0px",
    padding: "1.7% 50px 0% 138px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& .PrivateSwitchBase-root-14": {
      padding: "0px",
    },
  },
})(Grid);

const RightGrid: any = withStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingRight: "0px",
  },
})(Grid);

// const InputField = withStyles({
//     root: {
//         maxWidth: '491px',
//         borderRadius: '8px',
//         backgroundColor: '#fff',
//         "& ::placeholder": {
//             color: '#d6d6d6',
//             fontSize: '16px',
//             opacity: 1,
//             fontFamily: 'DMSans-Bold',
//         },
//         "& .MuiInputBase-input": {
//             height: '13px',
//         },
//         "& .MuiOutlinedInput-input": {
//             padding: '19px 14px'
//         },
//         "& .MuiOutlinedInput-root": {
//             fontWeight: 500,
//             fontFamily: "SFProDisplay-Regular",
//             backgroundColor: "rgba(255, 255, 255, 1)",
//             fontSize: "16px",
//             borderRadius: "8px",
//             "& fieldset": {
//                 border: 'solid 1px #d6d6d6',
//                 height: '55px',
//                 borderRadius: '8px',
//             },
//         },
//     },
// })(TextField);

const InputField: any = withStyles({
  root: {
    maxWidth: "491px",
    borderRadius: "8px",
    backgroundColor: "#fff",
    "& ::placeholder": {
      color: "#d6d6d6",
      fontSize: "16px",
      opacity: 1,
      fontFamily: "SFProDisplay",
      fontWeight: 500,
    },
    "& .MuiInputBase-input": {
      height: "14px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "3.3% 14px",
    },
    "& .MuiOutlinedInput-root": {
      fontWeight: 400,
      fontFamily: "SFProDisplay",
      backgroundColor: "rgba(255, 255, 255, 1)",
      fontSize: "16px",
      borderRadius: "8px",
      height: "100%",
      "& fieldset": {
        border: "solid 1px #d6d6d6",
        // height: '44px',
        borderRadius: "8px",
      },
    },
  },
})(TextField);

export const PasswordField: any = withStyles({
  root: {
    maxWidth: "491px",
    borderRadius: "8px",
    backgroundColor: "#fff",
    "& ::placeholder": {
      color: " #d6d6d6",
      opacity: 1,
      fontSize: "16px",
      fontFamily: "SFProDisplay",
      fontWeight: 400,
      padding: "17px 0px 19px 0px",
    },
    // "& .MuiInputBase-input": {
    //     height: '13px',
    // },
    // "& .MuiOutlinedInput-input": {
    //     padding: '19px 14px'
    // },
    "& .MuiInputBase-input": {
      height: "14px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "3.3% 14px",
    },
    "& .MuiOutlinedInput-root": {
      fontWeight: 500,
      fontFamily: "SFProDisplay-Regular",
      backgroundColor: "rgba(255, 255, 255, 1)",
      fontSize: "16px",
      borderRadius: "8px",
      "& fieldset": {
        border: "solid 1px #d6d6d6",
        // height: '55px',
        borderRadius: "8px",
      },
    },
  },
})(TextField);

const CheckBoxLabel: any = withStyles({
  root: {},
})(FormControlLabel);

const Buttons: any = withStyles({
  root: {
    maxWidth: "100%",
    minWidth: "100%",
    color: "rgba(255, 255, 255, 1)",
    backgroundColor: "#459eff",
    opacity: 1,
    boxShadow: "none",
    height: "42px",
    borderRadius: "8px",
    fontFamily: "DMSans-Bold",
    fontSize: "22px",
    textTransform: "none",
    margin: "2% 0 3.2% 0",
    "&:hover": {
      backgroundColor: "#459eff",
      boxShadow: "none",
    },
  },
})(Button);

const MainContainer: any = withStyles({
  root: {
    "& .MuiFormControlLabel-root": {
      marginLeft: "0px",
      marginRight: "0px",
      display: "flex",
      marginTop: "-6px",
    },
    "& .welcomeText": {
      "@media (max-width: 1200px)": {
        fontSize: `34px !important`,
      },
    },
  },
})(Grid);

// Customizable Area End
