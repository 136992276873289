Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.productSearchApiEndPoint = "bx_block_search/search/search/";
exports.productSearchApiType = "GET";
exports.productSortApiEndPoint = "sorting/sorting?sort[order_by]=name";
exports.productSortApiType = "GET";
exports.getCategoriesApiEndPoint = "bx_block_catalogue/catalogues/listing";
exports.getCategoriesApiType = "GET";
exports.getFilterAndSearchApiEndPoint = "filter_items/filtering";
exports.getFilterAndSearchApiType = "GET";
exports.getCategoriesWithProductsApiEndpoint = "bx_block_catalogue/catalogues/product_category_listing";
exports.getCategoriesWithProductsApiMethod = "GET";
exports.downloadCatalogApiEndpoint = "bx_block_landingpage3/landing_pages/upload_catalogue";
exports.downloadCatalogSentEmail = "bx_block_catalogue/catalogues/download_upload_catalogue";
exports.downloadCatalogSentEmailMethod = "POST";
exports.downloadCatalogApiMethod = "GET";
exports.errorMessage = "Something went wrong";
exports.getMyQueriesApiEndpoint = "account_block/accounts/user_ticket";
exports.getMyQueriesApiMethod = "GET";
exports.getMyQueryDetailApiEndpoint = "bx_block_catalogue/tickets/";
exports.getMyQueryDetailApiMethod = "GET";
exports.getMyQueryRepliesApiEndpoint = "account_block/accounts/employee_replies";
exports.getMyQueryRepliesApiMethod = "GET";
exports.leaveACommenyApiEndPoint = "account_block/accounts/leave_comment";
exports.guestLoginApiEndPoint = "account_block/accounts/guest_user";
exports.successMsg = "User Details Submitted Successfully";
exports.isActiveFlashUserAPIEndPoint = "account_block/accounts/active_flash";
exports.getAllContectInvioceApiEndPoint = "account_block/customer_masters/all_contact_invoice";
exports.getAllContectInvioceApiMethod = "GET";
exports.getByIdCustomerInvioceApiEndPoint = "/account_block/customer_masters/show_customer_invoice";
exports.getByIdCustomerInvioceApiMethod = "GET";
exports.contactNameLabel="Contact Name";
exports.productNameLabel="Product Name";
exports.invoiceLabel="Invoice Number";
exports.invoiceDateLabel="Invoice Date";
exports.invoiceAmountLabel="Invoice Amount";
exports.invoiceCurrencyLabel="Currency";
exports.invoicePOLabel="PO";
exports.invoiceDueDateLabel="Due Date";
exports.invoicePaymentTermsLabel="Payment Terms";
exports.invoiceIncoTermsLabel="Inco Terms";
exports.invoicePaymentStatusLabel="Payment Status";
exports.invoiceAttachmentLabel="Attachmnet";
exports.invoiceAddLabel="Invoice Details";
exports.attachmentLabel="Attachment";
exports.noProductData = "No Products Found";
exports.therapeuticAreaText = "Therapeutic Areas";
exports.manufacturingPlatformText = "Manufacturing Platform";
exports.tokenexperiedText = "Token has Expired";
exports.ShowingText = "Showing";
exports.resultsText = "results";
exports.emailTestRegex = `/^(([^<>()[]\\.,;:s@"]+(.[^<>()[]\\.,;:s@"]+)*)|(".+"))@(([d{1,3}.d{1,3}.d{1,3}.{1,3}])|(([a-zA-Z\0-9]+.)+[a-zA-Z]{2,}))$/</>`;
exports.reopenTicketAPIEndPoint = "bx_block_catalogue/tickets/";
exports.reOpenTicketApiMethodType = "PUT";
exports.ProductText = "Product";
exports.DocumentText = "Document"
exports.uploadText = "Upload";
exports.fileSizeText = "Max. file size should be 10 Mb";
exports.CancelBtnText = "Cancel";
exports.SubmitBtnText = "Submit";
exports.ReadMoreText = "Read More";
exports.SendButtonText = "Send";
exports.LeavecommentBtnText = "Leave a comment";
exports.SeeAllRepliesText = "See All Replies";
exports.requestDetailsText = "Request Details";
exports.ticketdetailsText = "Ticket Details";
exports.reOpenTicketText = "Reopen";
exports.SignupRequestText= "Sign Up Request";
exports.TicketIDText = " Ticket ID";
exports.RequestTypeText ="Request Type";
exports.RequestRaisedOnText = "Request Raised On";
exports.RequestDescriptionText = "Request Description";
exports.TypeMessagePlaceholder = "Type a message...";
exports.ProductQueryText = "Product Query";
exports.ConnectWithUsText = "Connect With Us";
exports.GeneralText = "General";
exports.TokenhasExpired = "Token has Expired";
exports.SearchPlaceholder = "Search";
exports.togglePasswordText = "toggle password visibility";
// Customizable Area End