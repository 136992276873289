import React from "react";

// Customizable Area Start
import {
  Container,
  Typography,
  Box,
  Button,
  Grid,
  TextField,
  Dialog,
  DialogContent,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { withStyles } from '@material-ui/core/styles';
// Customizable Area End

import TicketQueryDetailController, {
  Props,
} from "./TicketQueryDetailController";

function TabPanel(props: {
  [x: string]: any;
  children: any;
  value: any;
  index: any;
}) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default class TicketQueryDetail extends TicketQueryDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainContainer>
        <Box flexDirection={'row'} display="flex" justifyContent={'space-between'}>
          <Typography style={webStyle.QueryTitle}>
            {this.state.detailsTitle}
          </Typography>
        </Box>


        <Grid container>
          <Grid item lg={4} md={4} style={{ paddingBottom: '20px' }} sm={12} xs={12}>
            <Typography style={webStyle.DetailDataLabel}>
              {this.state.ticketIdLabel}
            </Typography>
            <Typography
              style={webStyle.DataValue}
            >
              {this.state.Data.ticketId}
            </Typography>
          </Grid>

          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Typography style={webStyle.DetailDataLabel}>
              {this.state.queryTypeLabel}
            </Typography>
            <Typography
              style={webStyle.DataValue}
            >
              {this.state.Data.queryType}
            </Typography>
          </Grid>

          <Grid item lg={4} md={4} sm={12} xs={12}
            style={{
              alignItems: 'center',
              display: 'flex'
            }}>
            <ActionButtons>Action</ActionButtons>
          </Grid>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography style={webStyle.DetailDataLabel}>
            {this.state.detailsTitle}
          </Typography>
          <Typography
            style={webStyle.DataValue}
          >
            {this.state.Data.details}
          </Typography>
        </Grid>

        <Grid container spacing={2}>
          <Grid item md={9} lg={9} sm={8} xs={8}>
            <Typography style={webStyle.DetailDataLabel}>
              {this.state.submittedByLabel}
            </Typography>
            <Typography style={webStyle.SubmittedName}>
              {this.state.Data.submittedBy.map((data: any, index: number) => {
                return (
                  <Typography key={index} component="span" style={{ textDecoration: 'underline' }}>
                    {data.name}
                    {
                      index !== this.state.Data.submittedBy.length - 1 && (
                        <span>,</span>
                      )
                    }
                  </Typography>
                )
              })}
            </Typography>
          </Grid>
        </Grid>
      </MainContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  proImageName: {
    fontSize: '26px',
    fontWeight: 700,
    color: 'white',
    fontFamily: 'SFProDisplay',
    padding: '5px 10px',
  },
  QueryTitle: {
    fontSize: '28px',
    color: 'rgba(33, 43, 54, 1)',
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    padding: '5px 10px 20px 0px'
  },
  Tab1: {
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
  },
  tab3: {
    borderEndEndRadius: "8px",
    borderTopRightRadius: "8px",
  },
  productImage: {
    height: '100%',
    width: '100%',
    paddingRight: 5,
  },
  DataLabel: {
    fontWeight: 700,
    color: 'rgba(15, 72, 127, 1)',
    fontFamily: 'SFProDisplay',
    fontSize: '24px',
  },
  DetailDataLabel: {
    fontWeight: 700,
    color: 'rgba(0, 73, 131, 1)',
    fontFamily: 'SFProDisplay',
    fontSize: '24px',
    paddingTop: '10px',
  },
  DataValue: {
    fontWeight: 500,
    fontFamily: 'SFProDisplay',
    fontSize: '20px',
    color: 'rgba(0, 0, 0, 1)',
  },
  SubmittedName: {
    fontWeight: 500,
    fontFamily: 'SFProDisplay',
    fontSize: '20px',
    color: '#5A9CF0',
    paddingTop: '5px',
  },
  ActionPopUpText: {
    fontWeight: 700,
    color: 'rgba(4, 60, 116, 1)',
    fontFamily: 'SFProDisplay',
    fontSize: '24px',
    margin: '0px 15px',
    paddingBottom: '10px'
  },
  select: {
    height: '38px',
    borderRadius: '8px',
    borderColor: 'rgba(4, 60, 116, 1)',
    color: '#6D84A9',
    "& .MuiSelect-root": {
      borderColor: 'rgba(4, 60, 116, 1)',
    }
  },
  selectPlaceHolder: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontFamily: 'SFProDisplay',
    fontSize: '16px',
    color: 'rgba(214, 214, 214, 1)',
  },
};

const MainContainer:any = withStyles({
  root: {
    overflow: 'auto',
    padding: '24px 48px 24px 48px',
    "@media (max-width: 600px)": {
      padding: '24px 24px 24px 24px',
    }
  }
})(Container);

const ActionButtons:any = withStyles({
  root: {
    background: 'rgba(4, 60, 116, 1)',
    height: '50px',
    width: '180px',
    color: 'rgba(255, 255, 255, 1)',
    fontFamily: 'SFProDisplay',
    fontSize: '22px',
    fontWeight: 700,
    textTransform: 'none',
    "&:hover": {
      background: 'rgba(4, 60, 116, 1)',
    },
  }
})(Button);



// Customizable Area End
