import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { isLoggedIn } from "../../../components/src/common";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  documentBtnActive: boolean;
  // Customizable Area Start
  doc_file: any;
  numPages: number;
  BackButtonText: string;
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class QueryDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  authToken: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      documentBtnActive: true,
      // Customizable Area Start
      doc_file: "",
      numPages: 0,
      BackButtonText: configJSON.BackButtonText,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.onDocPreview = this.onDocPreview.bind(this);
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    // Customizable Area End

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );
      this.setState({ txtSavedValue: value });
    }
    // Customizable Area Start
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => { this.setState({ txtInputValue: text });},
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps, keyboardType: "email-address", autoCompleteType: "email",
  };

  txtInputProps = this.isPlatformWeb() ? this.txtInputWebProps : this.txtInputMobileProps;

  btnShowHideImageProps = { source: this.txtInputProps.secureTextEntry ? imgPasswordVisible: imgPasswordInVisible };

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible;
    }
  };

  // web events

  // Customizable Area Start
  async componentDidMount() {
    const authToken = isLoggedIn(this.props.navigation);
    if (typeof authToken === "string") {
      const doc_file = localStorage.getItem("doc_file")
      this.setState({
        doc_file: doc_file
      })
      document.addEventListener("keydown", function (e) {
        if (e.ctrlKey && (e.key == "p" || e.key == "s")) {
          e.preventDefault();
          e.stopImmediatePropagation();
        }
      });
      window.addEventListener('contextmenu', function (e) {
        e.preventDefault();
        return false;
      });
    }
  }

  onDocPreview = (doc_file: any) => {
    localStorage.setItem('fromPage', "QueryDetail")
    this.props.navigation.navigate("DocPreview")
  }

  onDocumentLoadSuccess = ({ numPages }: any) => {
    this.setState({
      numPages: numPages,
    })
  }
  // Customizable Area End

}
