import React from "react";
// Customizable Area Start
import { SafeAreaView } from "react-native";
import {
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grid,
  Box,
  Button,
  TextField,
  InputLabel,
  Container,
} from "@material-ui/core";
import { Banners } from "./Banners.web";
import NewUpdates from "./NewUpdates.web";
import KnowOurTeam from "./KnowOurTeam";
import AppFooter from "../../../components/src/AppFooter.web";
import { AppHeader } from "../../../components/src/AppHeader.web";
import EmployeeAppHeader from "../../../components/src/EmployeeAppHeader.web";
import CustomSearch from "../../../components/src/CustomSearch.web";
import LandingPageController, { Props } from "./LandingPageController";
import Products from "./products.web";
import NewUpdatesSearch from "./NewUpdatesSearch.web";
import Loader from "../../../components/src/Loader.web";
import { isEmployee } from "../../../components/src/common";

// Customizable Area End

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // FlashLoginPopUp() {
  //   return (
  //     <LoginDialog
  //       open={this.state.showFlashLogin}
  //       // onClose={this.handleIsCreatedClose}
  //       aria-labelledby="alert-dialog-title"
  //       aria-describedby="alert-dialog-description"
  //     >
  //       <DialogTitle>
  //         <Typography style={webStyle.dialogTitle} align="center">
  //           Please share the following details to proceed
  //         </Typography>
  //         <IconBox onClick={this.closeFlashLogin}>
  //           <CloseIcon style={{ fill: '#1A3B71', cursor: 'hand' }}>
  //           </CloseIcon>
  //         </IconBox>
  //       </DialogTitle>
  //       <DialogContent>
  //         <form
  //           onSubmit={(e) => {
  //             e.preventDefault();
  //             this.onSave();
  //           }}
  //         >
  //           <Grid item lg={12} md={12} sm={12} xs={12}>
  //             <InputLabel htmlFor="name" style={webStyle.inputLabel}>
  //               Your Name<span style={{ color: "red" }}>*</span>
  //             </InputLabel>
  //             <InputField
  //               id="name"
  //               variant="outlined"
  //               required
  //               placeholder="Enter your full name"
  //               fullWidth
  //               value={this.state.firstName}
  //               onChange={this.handleChange("firstName").bind(this)}
  //             />
  //           </Grid>
  //           <Grid item lg={12} md={12} sm={12} xs={12}>
  //             <InputLabel htmlFor="name" style={webStyle.inputLabel}>
  //               Company Name<span style={{ color: "red" }}>*</span>
  //             </InputLabel>
  //             <InputField
  //               id="name"
  //               variant="outlined"
  //               required
  //               placeholder="Enter your company name"
  //               fullWidth
  //               value={this.state.companyName}
  //               onChange={this.handleChange("companyName").bind(this)}
  //             />
  //           </Grid>
  //           <Grid item lg={12} md={12} sm={12} xs={12}>
  //             <InputLabel htmlFor="name" style={webStyle.inputLabel}>
  //               Email ID<span style={{ color: "red" }}>*</span>
  //             </InputLabel>
  //             <InputField
  //               id="name"
  //               variant="outlined"
  //               required
  //               placeholder="Enter your email Id"
  //               fullWidth
  //               value={this.state.email}
  //               onChange={this.handleChange("email").bind(this)}
  //             />
  //             {
  //               this.state.emailError && (
  //                 <Typography style={webStyle.error_text}>
  //                   Please enter valid email id
  //                 </Typography>
  //               )
  //             }
  //           </Grid>
  //           <InputLabel htmlFor="phoneNo" style={webStyle.inputLabel}>
  //             Mobile number<span style={webStyle.optionalLabel}>(Optional)</span>
  //           </InputLabel>
  //           <Grid container spacing={2}>
  //             <Grid item lg={6} md={6} sm={6} xs={12}>
  //               <Box display="flex">
  //                 <CustomGrid item md={12} lg={12} sm={12} xs={12} className={this.state.isLoading === true ? 'countryCodeIndex' : 'countryCodeIndex2'} style={{ marginTop: "5px" }}>
  //                   <CountryCodeSelector
  //                     navigation={""}
  //                     style={{}}
  //                     id="countryCode"
  //                     disable={false}
  //                     allowPropChange={true}
  //                     onChange={this.handlePhoneCountryCode}
  //                     value={this.state.phoneCountryCode}
  //                     placeHolder="Country code"
  //                   />
  //                 </CustomGrid>
  //               </Box>
  //             </Grid>

  //             <Grid item lg={6} md={6} sm={6} xs={12}>
  //               <InputField
  //                 id="phoneNo"
  //                 variant="outlined"
  //                 onChange={this.handleChange("phoneNumber").bind(this)}
  //                 value={this.state.phoneNumber}
  //                 placeholder="Enter Phone No"
  //                 fullWidth
  //                 inputMode="numeric"
  //                 inputProps={{
  //                   maxLength: 14,
  //                 }}
  //                 type="tel"
  //               />
  //             </Grid>
  //           </Grid>
  //           <Grid item lg={12} md={12} sm={12}>
  //             <Button
  //               type="submit"
  //               variant="contained"
  //               fullWidth
  //               style={{ ...webStyle.submitButton, textTransform: "none" }}
  //             >
  //               Proceed
  //             </Button>
  //           </Grid>
  //         </form>
  //       </DialogContent>
  //     </LoginDialog>
  //   )
  // };
  // Customizable Area End

  render() {
    return (
      <SafeAreaView>
        {isEmployee() ? <EmployeeAppHeader isSticky={true} navigation={this.props.navigation} /> : <AppHeader isSticky={true} navigation={this.props.navigation} />}
        {/* <Container className="home"> */}
        <Loader loading={this.state.loading} />
        <Banners
          banners={this.state.banners}
          showFullVideo={this.showFullVideo}
        />
        <CustomSearch  searchByProductName={(e: any) => this.handleSearch(e)}  data-testid='CustomSearch'/>

        {this.state.isSeach ? (
          this.state.isSearchError ? (
            <Typography style={webStyle.NODataText}>
              Search results are not possible at the moment. Please try again
            </Typography>
          ) : this.state.isLoading ? (
            <Typography style={webStyle.NODataText}>Loading...</Typography>
          ) : (
            <React.Fragment>
              {" "}
              {this.state.products && this.state.products.length > 0 && (
                <Products
                  handleKnowMore={this.handleKnowMoreClick}
                  isProductViewAll={this.state.productsViewAll}
                  setIsViewAll={this.setProductIsViewAll}
                  products={this.state.products}
                  searchTerm={this.state.searchTerm}
                />
              )}
              {this.state.searchNewUpdates &&
                this.state.searchNewUpdates.length > 0 ? (
                <NewUpdatesSearch
                  setIsViewAll={this.setIsViewAll}
                  IsViewAll={this.state.IsViewAll}
                  searchNewUpdates={this.state.searchNewUpdates}
                  searchTerm={this.state.searchTerm}
                />
              ) : (
                <Typography style={webStyle.NODataText}>

                </Typography>
              )}
            </React.Fragment>
          )
        ) : (
          <React.Fragment>
            <NewUpdates navigation={undefined} id={"6"} />
          </React.Fragment>
        )}
        {this.state.teamDetails && this.state.teamDetails?.length > 0 &&
          (
            <KnowOurTeam searchTerm={this.state.searchTerm} teams={this.state.teamDetails} />
          )
        }
        {
          this.state.teamDetails && this.state.teamDetails?.length === 0
          && this.state.products && this.state.products.length === 0 &&
          this.state.searchNewUpdates && this.state.searchNewUpdates.length === 0
          && (
            <Typography style={webStyle.NODataText}>
              No Results Found
            </Typography>
          )
        }
        <AppFooter isLoading={false} />
        {/* </Container> */}
      </SafeAreaView>
    );
  }
}

const webStyle = {
  NODataText: {
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    fontSize: "20px",
    padding: "20px 50px 20px 75px"
  },
  dialogTitle: {
    margin: '8px 2px 0px 0',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: 'normal',
    color: '#3a3f63',
    width: '100%'
  },
  inputLabel: {
    fontSize: "18px",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    color: "#3a3f63",
    margin: '23px 0px 12px 0px',
  },
  optionalLabel: {
    margin: '4px 0px 13px 4px',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    fontSize: '14px',
    color: '#3a3f63',
  },
  error_text: {
    color: 'red',
    fontSize: '14px',
    fontFamily: 'SFProDisplay-Regular'
  }
};
