import React, { useEffect, useState } from "react";
import { QueryDialog } from "../../blocks/DynamicContent/src/ConnectWithUs.web";
import {
  Typography,
  Button,
  DialogContent,
  DialogActions,
  Box,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';

interface IProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AboutUsRedirectionConfirmationPopup = ({ isOpen, setIsOpen }: IProps) => {

  const handleOkClick = () => {
    setIsOpen(false);
    window.open("https://www.biocon.com/about-us/")
  }

  return isOpen ? (
    <QueryDialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent style={{ paddingTop: '25px', display: 'flex', justifyContent: 'space-between' }}>
        <Typography style={webStyle.queryPopUpText}>
          You're being re-directed to www.biocon.com. Kindly click OK to Proceed
        </Typography>
        <IconBox onClick={() => setIsOpen(false)} data-testid="closeicon" >
          <CloseIcon style={{ fill: '#1A3B71', cursor: 'hand' }}>
          </CloseIcon>
        </IconBox>
      </DialogContent>
      <DialogActions style={{ padding: '15px 0px' }}>
        <SaveButton onClick={handleOkClick} variant="outlined">
          OK
        </SaveButton>
      </DialogActions>
    </QueryDialog>
  ) : <React.Fragment></React.Fragment>
}

const webStyle = {
  queryPopUpText: {
    color: 'rgba(33, 43, 54, 1)',
    fontFamily: 'SFProDisplay',
    fontSize: '20px',
    margin: '0px 15px',
  }
};

export const SaveButton: any = withStyles({
  root: {
    color: "rgba(4, 60, 116, 1)",
    backgroundColor: "rgba(255, 255, 255, 1)",
    borderColor: 'rgba(4, 60, 116, 1)',
    height: "40px",
    width: 90,
    borderRadius: "8px",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "18px",
    marginBottom: "5px",
    marginLeft: "8px",
    boxShadow: "none",
    textTransform: "none",
    marginRight: '10px',
    "&:hover": {
      backgroundColor: "rgba(4, 60, 116, 1)",
      color: "rgba(255, 255, 255, 1)",
      boxShadow: "none",
    },
    "@media (max-width: 600px)": {
      width: "100%",
      marginLeft: 0,
    },
  },
})(Button);

const IconBox: any = withStyles({
  root: {
    background: '#DAE0EC',
    height: '24px',
    width: '24px',
    borderRadius: '50%',
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '-15px',
    padding: '0px 0px 0px 0px',
    marginTop: '-13px',
    cursor: 'pointer',
    "& .MuiSvgIcon-root": {
      height: '16px',
      width: '16px'
    },
  }
})(Box);

export default AboutUsRedirectionConfirmationPopup