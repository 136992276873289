import React from "react";
import {
    Box,
    Grid,
    Button,
    TextField,
    InputLabel,
    IconButton,
    Typography,
    FormControlLabel,
    InputAdornment,
} from "@material-ui/core";
import {
    signupImage,
    bioconLogo,
    backgroundImg
} from "./assets";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { withStyles } from "@material-ui/core/styles";
import { Dimensions } from "react-native";
import SignupController, { Props } from "./SignupController.web";

export default class SignUpRegistration extends SignupController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        //@ts-ignore
        const { navigation } = this.props;
        return (
            <>
                <Grid container
                    style={{
                        overflow: "auto"
                    }}
                >
                    <LeftGrid item lg={5} md={5} sm={5} xs={12}
                        style={{
                            position: 'relative',
                            marginRight: '7%',
                        }}
                    >
                        <Box sx={webStyle.backImageSpace}>
                            <img
                                style={webStyle.leftImage}
                                src={signupImage}
                                className="img"
                            />
                        </Box>
                        <WelcomeCard>
                            <Typography style={webStyle.welcomeText}>
                                {this.state.welcomeText}
                            </Typography>
                            <Typography style={webStyle.startedText}>
                                {this.state.startedText}
                            </Typography>
                            <Typography style={webStyle.serviceText}>
                                {this.state.serviseText}
                            </Typography>
                        </WelcomeCard>
                    </LeftGrid>
                    <RightBox item lg={6} md={6} sm={6} xs={12}>
                        <RightGrid item lg={10} md={10} sm={10} xs={12}>
                            <img
                                src={bioconLogo}
                                style={webStyle.LogoImage}
                            />
                            <Typography style={webStyle.Header}>
                                New User Sign Up
                            </Typography>
                            <Typography style={webStyle.subHeader}>
                                Please share the following details to singup
                            </Typography>
                            <form onSubmit={() => {}}>
                                <InputLabel htmlFor="firstName" style={webStyle.inputLabelFirstName}>
                                    {this.state.labelFirstName}*
                                </InputLabel>
                                <InputField
                                    variant="outlined"
                                    type="text"
                                    inputProps={{ pattern: "[A-Za-z]{1,32}" }}
                                    required
                                    placeholder="Enter your first name"
                                    fullWidth
                                />
                                
                                <InputLabel htmlFor="firstName" style={webStyle.inputLabelLastName}>
                                    {this.state.labelLastName}*
                                </InputLabel>
                                <InputField
                                    variant="outlined"
                                    type="text"
                                    inputProps={{ pattern: "[A-Za-z]{1,32}" }}
                                    required
                                    placeholder="Enter your last name"
                                    fullWidth
                                />

                                <InputLabel htmlFor="emailInput" style={webStyle.inputLabelEmail}>
                                    {this.state.labelEmail}*
                                </InputLabel>
                                <InputField
                                    id="emailInput"
                                    type="email"
                                    variant="outlined"
                                    required
                                    placeholder="Enter your email id"
                                    fullWidth
                                />

                                <InputLabel htmlFor="createPass" style={webStyle.inputLabelPassword}>
                                    {this.state.labelPassword}*
                                </InputLabel>
                                <InputField
                                    id="createPass"
                                    variant="outlined"
                                    required
                                    placeholder="Create a strong password"
                                    fullWidth
                                    type={this.state.showPassword ? "text" : "password"}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {this.state.showPassword ? (
                                                        <VisibilityOffOutlinedIcon />
                                                    ) : (
                                                        <VisibilityOutlinedIcon />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Typography variant="body2" style={webStyle.passwordTypo}>
                                    Password must have 8 characters, alphanumeric and must include special character
                                </Typography>

                                <InputLabel htmlFor="confirmPass" style={webStyle.inputLabelConfPassword}>
                                    {this.state.confirmPass}*
                                </InputLabel>
                                <InputField
                                    id="confirmPass"
                                    variant="outlined"
                                    required
                                    type={this.state.confirmShowPassword ? "text" : "password"}
                                    placeholder="Confirm your password"
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleConfirmShowPassword}
                                                    edge="end"
                                                >
                                                    {this.state.confirmShowPassword ? (
                                                        <VisibilityOffOutlinedIcon />
                                                    ) : (
                                                        <VisibilityOutlinedIcon />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Buttons type="submit" variant="contained">
                                    {this.state.IscompanyDetails ? "Sign Up" : "Proceed"}
                                </Buttons>
                            </form>
                            <Box sx={webStyle.haveAccount}>
                                <Typography style={webStyle.AccountText}>
                                    {this.state.AccountLabel}
                                </Typography>
                                <Typography style={webStyle.login}
                                >
                                    {this.state.loginText}
                                </Typography>
                            </Box>
                        </RightGrid>
                    </RightBox>
                </Grid>
            </>
        );
    }
}

// Customizable Area Start
const webStyle = {
    backImageSpace: {
        width: '846px',
        height: '1023.7px',
        // transform: 'rotate(-48deg)',
        padding: '0 120px 0 0',
    },
    leftImage: {
        // height: Dimensions.get("window").height,
        // width: "100%",
        // marginTop: '0px',
        // marginLeft: '-2px'
        width: '725px',
        height: '1023px',
    },
    welcomeText: {
        width: '561px',
        height: '58px',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '48px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#fff',
    },
    startedText: {
        width: '576px',
        height: '28px',
        margin: '13px 0 16px',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '24px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        color: '#fff',
    },
    serviceText: {
        width: '576px',
        height: '20px',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '16px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        color: '#fff',
    },
    LogoImage: {
        width: '174px',
        height: '67px',
        margin: '0 143px 40px 0',
    },
    Header: {
        width: '230px',
        height: '36px',
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '30px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#043c74',
    },
    subHeader: {
        width: '289px',
        height: '19px',
        margin: '11px 28px 0 0',
        fontFamily: 'SFProDisplay',
        fontWeight: 500,
        fontSize: '16px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#6a74a5',
    },
    inputLabelFirstName: {
        width: '91px',
        height: '21px',
        margin: '33px 20px 14px 0',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '18px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#3a3f63',
    },
    inputLabelLastName: {
        width: '90px',
        height: '21px',
        margin: '24px 21px 12px 0',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '18px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#3a3f63',
    },
    inputLabelEmail: {
        width: '70px',
        height: '21px',
        margin: '24px 41px 12px 0',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '18px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#3a3f63',
    },
    inputLabelPassword: {
        width: '138px',
        height: '21px',
        margin: '24px 179px 12px 0',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '18px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#3a3f63',
    },
    inputLabelConfPassword: {
        width: '147px',
        height: '21px',
        margin: '24px 169px 12px 0',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '18px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#3a3f63',
    },
    textField: {
        width: '491px',
        height: '55px',
        margin: '14px 0 24px',
        padding: '18px 334px 18px 14px',
        borderRadius: '8px',
        border: 'solid 1px #d6d6d6',
        backgroundColor: '#fff',
    },
    passwordTypo: {
        width: '489px',
        height: '16px',
        margin: '8px 2px 24px 0',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '14px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#6a74a5',
    },
    haveAccount: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    AccountText: {
        width: '198px',
        height: '21px',
        margin: '8px 8px 0 32px',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '18px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#3a3f63',
    },
    login: {
        width: '41px',
        height: '21px',
        margin: '8px 8px 0 4px',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '18px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '-0.35px',
        color: '#459eff',
    },
};

const PasswordField = withStyles({
    root: {
        background: "inherite",
        margin: "5px 0px 5px 0px",
        "& ::placeholder": {
            color: 'rgba(214, 214, 214, 1)',
            opacity: 1,
            fontSize: '20px',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
        },
            "& .MuiInputBase-input": {
            height: '15px',
        },
            "& .MuiOutlinedInput-input": {
            padding: '14px 14px'
        },
        "& .MuiOutlinedInput-root": {
            fontFamily: "SFProDisplay",
            fontSize: '16px',
            fontWeight: 400,
            "& fieldset": {
                height: '45px',
                borderColor: "rgba(214, 214, 214, 1)",
                borderRadius: '8px',
                borderWidth: '1px',
            },
        },
    },
})(TextField);

const InputField:any = withStyles({
    root: {
        width: '491px',
        height: '55px',
        borderRadius: '8px',
        border: 'solid 1px #d6d6d6',
        backgroundColor: '#fff'
        // background: "inherite",
        // width: '80%',
        // margin: "5px 0px 5px 0px",
        // "& ::placeholder": {
        //     color: 'rgba(214, 214, 214, 1)',
        //     opacity: 1,
        //     fontSize: '12px',
        //     fontFamily: 'DMSans-Bold',
        // },
        // "& .MuiInputBase-input": {
        //     height: '15px',
        // },
        // "& .MuiOutlinedInput-input": {
        //     padding: '11px 11px'
        // },
        // "& .MuiOutlinedInput-root": {
        //     fontFamily: "SFProDisplay",
        //     fontSize: '12px',
        //     fontWeight: 200,
        //     "& fieldset": {
        //         height: '45px',
        //         borderColor: "rgba(214, 214, 214, 1)",
        //         borderRadius: '8px',
        //         borderWidth: '1px',
        //     },
        // },
    },
})(TextField);

const Buttons:any = withStyles({
    root: {
        width: '491px',
        height: '56px',
        margin: '40px 0 8px',
        padding: '11px 192px 11px 193px',
        borderRadius: '8px',
        backgroundColor: '#459eff',

        fontSize: '26px',
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#fff',
        textTransform: "none",
        // width: "80%",
        // color: "rgba(255, 255, 255, 1)",
        // backgroundColor: "rgba(69, 158, 255, 1)",
        // opacity: 1,
        // boxShadow: "none",
        // borderRadius: "5px",
        // padding: '3px',
        // fontFamily: 'DMSans-Bold',
        // fontSize: '20px',
        // marginTop: '25px',
        // textTransform: "none",
        // "&:hover": {
        //     backgroundColor: "rgba(69, 158, 255, 1)",
        //     boxShadow: "none",
        // },
    },
})(Button);

const CheckBoxLabel = withStyles({
    root: {
        "& .MuiTypography-body1": {
            fontFamily: "SFProDisplay",
            fontWeight: 400,
            fontSize: "18px",
            color: "rgba(58, 63, 99, 1)",
        },
    },
})(FormControlLabel);

const WelcomeCard:any = withStyles({
    root: {
        position: 'absolute',
        top: '75%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
    }
})(Grid);

const LeftGrid:any = withStyles({
    root: {
        marginRight: '7%',
        "& .img": {
          "@media (max-height: 800px)": {
            height: `100% !important`,
          },
          "@media (max-width: 820px)": {
            width: `100% !important`
          },
        },
        "@media (max-width: 640px)": {
          marginRight: '0px',
        },
    }
})(Grid);

const RightBox:any = withStyles({
    root: {
        backgroundImage: `url(${backgroundImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        width: '679px',
        height: '1001px',
        margin: '12px 0 10.7px 16px',
        padding: '41.7px 57px 42.3px 131px',
    }
})(Grid);

const RightGrid:any = withStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        paddingRight: '0px',
        "@media (max-width: 600px)": {
            padding: `0px 30px 0px 30px !important`,
        },
    }
})(Grid);

// Customizable Area End
