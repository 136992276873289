import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { handleUpdateAuthToken, isLoggedIn, isGuest, apiCall, downloadAttachmentFile, errorNotification, getHeaders, warningNotification, events, autoLogOut } from "../../../components/src/common";

export interface IProductListing {
  attributes: {
    if_pipeline_product: any;
    manufacturing_platform: string;
    category: {
      id: number;
      name: string;
    }[];
    image: string | null;
    name: string;
    product_description: {
      data: {
        id: string;
        type: string;
        attributes: {
          chemical_name: string;
          product_dmf: string;
        };
      };
    };
    product_documents: any;
    sub_category: {
      id: number;
      name: string;
    }[];
    therapeutic_area: string;
  };
  id: string;
  type: string;
}

export interface ICategory {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
  }
}

export interface ICategoryProduct {
  id: number;
  name: string;
  products: {
    id: number;
    name: string;
  }[];
}
export interface IProductListingMeta {
  pagination_details: {
    current_count: number;
    current_page: number;
    next_page: number;
    prev_page: null | number;
    total_count: number | any;
    total_pages: number;
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  arrayHolderDuplicate: any;
  count: number;
  Ascending: boolean;
  paginationData: any;
  page: number;
  sortMenu: boolean;
  searchTerm?: string;
  uniqueProductsCategory: any;
  uniqueProductsTherapy: any;
  uniqueProductsName: any;
  categoryDropdownValue: any;
  therapyDropdownValue: number;
  productDropdownValue: any;
  productNameDropdownValue: number;
  productsList: null | IProductListing[];
  productsListingMeta: null | IProductListingMeta;
  isSearch: boolean;
  searchString: string;
  isSort: boolean;
  sortDirection: string;
  isFilter: boolean;
  loading: boolean;
  categoriesWithProductList: [];
  selectedCategoryGroupValue: any;
  isFlashLogin: boolean;
  firstName: string;
  companyName: string;
  email: string;
  phoneNumber: string;
  phoneCountryCode: string;
  emailError: boolean;
  isProductFlashLogin: boolean;
  isProductDownload: boolean;
  noData: boolean;
  categoryClick: any;
  noProductData: any;
  therapeuticAreaText: any;
  manufacturingPlatformText: any;
  SearchPlaceholder: string;
  togglePasswordText: string;
  // Customizable Area End
}

interface SS {
  id: any;
}
const PER_PAGE = 9;
export default class ProductListingController extends BlockComponent<
  Props,
  S,
  SS
> {
  authToken: string;
  getProductApiCallId: any;
  searchProductApiCallId: any;
  sortProductApiCallId: any;
  perPageRecords: number = 9;
  getCategoriesApiCallId: any;
  getFilterAndSearchProductsApiCallId: any;
  getCategoriesWithProductsCallId: any;
  downloadCatalogApiCallId: any;
  downloadCatalogSentEmailId: any;
  GuestLoginApiCallId: any;
  IsFlashUserApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.getListRequest = this.getListRequest.bind(this);
    this.handleSearchByProductName = this.handleSearchByProductName.bind(this);
    this.handleFilterAndSearch = this.handleFilterAndSearch.bind(this);
    this.isAnyFilterApplied = this.isAnyFilterApplied.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      arrayHolderDuplicate: [],
      Ascending: true,
      token: "",
      count: 0,
      page: 1,
      sortMenu: false,
      searchTerm: "",
      uniqueProductsCategory: [],
      uniqueProductsTherapy: [],
      uniqueProductsName: [],
      categoryDropdownValue: 0,
      therapyDropdownValue: 0,
      productNameDropdownValue: 0,
      paginationData: [],
      productsList: null,
      productsListingMeta: null,
      isSearch: false,
      searchString: "",
      isSort: true,
      sortDirection: 'asc',
      isFilter: false,
      loading: false,
      categoriesWithProductList: [],
      productDropdownValue: 0,
      selectedCategoryGroupValue: '',
      isFlashLogin: false,
      firstName: "",
      companyName: "",
      email: "",
      phoneNumber: "",
      phoneCountryCode: "",
      emailError: false,
      isProductFlashLogin: false,
      isProductDownload: false,
      noData: false,
      categoryClick: false,
      noProductData: configJSON.noProductData,
      therapeuticAreaText: configJSON.therapeuticAreaText,
      manufacturingPlatformText: configJSON.manufacturingPlatformText,
      SearchPlaceholder: configJSON.SearchPlaceholder,
      togglePasswordText: configJSON.togglePasswordText,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.handlePhoneCountryCode = this.handlePhoneCountryCode.bind(this);
    this.getCategoriesProductListing = this.getCategoriesProductListing.bind(this);
    this.downloadCatalogApiCall = this.downloadCatalogApiCall.bind(this);
    this.filterByProductName = this.filterByProductName.bind(this);
    this.filterByProductCategory = this.filterByProductCategory.bind(this);
    this.filterByProductCategory2 = this.filterByProductCategory2.bind(this);
    this.filterByProductTherapy = this.filterByProductTherapy.bind(this);
    this.handleKnowMoreClick = this.handleKnowMoreClick.bind(this);
    this.handleFilterAndSearch = this.handleFilterAndSearch.bind(this);
    this.getToken = this.getToken.bind(this);
    this.handleSorting = this.handleSorting.bind(this);
    this.getCategoriesList = this.getCategoriesList.bind(this);
    this.onSave = this.onSave.bind(this);
    this.sliceData = this.sliceData.bind(this);
    this.setDefaultProductLength = this.setDefaultProductLength.bind(this);
    this.sortDescending = this.sortDescending.bind(this);
    this.sortAscending = this.sortAscending.bind(this);
    this.isAnyFilterApplied = this.isAnyFilterApplied.bind(this);
    this.downloadProduct = this.downloadProduct.bind(this);
    this.isActiveFlashUser = this.isActiveFlashUser.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeFlashLogin = this.closeFlashLogin.bind(this);
    this.setCategoriesWithProductId = this.setCategoriesWithProductId.bind(this);
    this.setDownloadProductData = this.setDownloadProductData.bind(this);
    this.sendDownloadEmail = this.sendDownloadEmail.bind(this);
    this.setGuestData = this.setGuestData.bind(this);
    this.setForIsGuestLogin = this.setForIsGuestLogin.bind(this);
    this.getIfLogged = this.getIfLogged.bind(this);
    this.setFlashUserData = this.setFlashUserData.bind(this);
    this.setTokenData = this.setTokenData.bind(this);
    this.categoriesData = this.categoriesData.bind(this);
    this.setFilterProductData = this.setFilterProductData.bind(this);
    this.AllAPICalls = this.AllAPICalls.bind(this);
    this.setSortData = this.setSortData.bind(this);
    this.setListData = this.setListData.bind(this);
    // Customizable Area End
  }
  async componentDidMount() {
    super.componentDidMount();
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        if(!isGuest()) {
          autoLogOut();
        }
      });
    });
    this.setState({ page: this.props.navigation.getParam('pageNumber') }, () => this.handleFilterAndSearch())
    this.getCategoriesList();
    this.getCategoriesProductListing();
    this.sliceData();
    const uniqueCategoryProducts = [...new Set(
        this.state.arrayHolder.map(
          (prod: any) => prod.attributes.category[0].name
        )
      ),
    ];
    const uniqueTherapyProducts = [
      ...new Set(
        this.state.arrayHolder.map(
          (prod: any) => prod.attributes.therapuetic_class
        )
      ),
    ];
    const uniqueNameProducts = [
      ...new Set(
        this.state.arrayHolder.map((prod: any) => prod.attributes.name)
      ),
    ];
    this.setState({
      arrayHolderDuplicate: this.state.arrayHolder,
      uniqueProductsCategory: uniqueCategoryProducts,
      uniqueProductsTherapy: uniqueTherapyProducts,
      uniqueProductsName: uniqueNameProducts,
    });
    this.isActiveFlashUser();
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getListRequest = (token: any, pageNumber: number = 1) => {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token
    };

    const updateAccount = apiCall({
      httpBody: {},
      header: headers,
      url: configJSON.productAPiEndPoint + `?per_page=${this.perPageRecords}&page=${pageNumber}`,
      httpMethod: configJSON.apiMethodTypeGet,
    });

    this.getProductApiCallId = updateAccount.messageId;
    runEngine.sendMessage(updateAccount.id, updateAccount);
    this.setState({loading: true})
    return true;
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      this.setTokenData(message);
    }
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (this.getProductApiCallId != null && this.getProductApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        this.setListData(responseJson)
      } else if (this.searchProductApiCallId != null && this.searchProductApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        this.setListData(responseJson)
      } else if (this.sortProductApiCallId != null && this.sortProductApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        this.setSortData(responseJson);
      }
      this.AllAPICalls(responseJson, message)
    }
    if (this.IsFlashUserApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.setFlashUserData(responseJson)
    }
    // Customizable Area End
  }

  // Customizable Area Start

  AllAPICalls = (responseJson: any, message: any) => {
    if (this.getCategoriesApiCallId != null && this.getCategoriesApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.categoriesData(responseJson)
    } else if (this.getFilterAndSearchProductsApiCallId != null && this.getFilterAndSearchProductsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.setFilterProductData(responseJson)
    } else if (this.getCategoriesWithProductsCallId != null && this.getCategoriesWithProductsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.setCategoriesWithProductId(responseJson);
    } else if (this.downloadCatalogApiCallId != null && this.downloadCatalogApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.setDownloadProductData(responseJson)
    } else if (this.downloadCatalogSentEmailId != null && this.downloadCatalogSentEmailId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.sendDownloadEmail(responseJson)
    } else if (this.GuestLoginApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.setGuestData(responseJson)
    }
  }

  sliceData = () => {
    this.setState({
      count: this.state.arrayHolder.length,
      paginationData: this.state.arrayHolder.slice(0, 6),
    });
  };

  categoriesData = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setCategoryData(responseJson)
    }
  }

  setTokenData = (message: any) => {
    let token = message.getData(getName(MessageEnum.SessionResponseToken));
    this.setState({ token: token });
    // this.getListRequest(token);
  }

  setFlashUserData = (responseJson: any) => {
    if (responseJson && responseJson.result) {
      if ((localStorage.getItem("guestData") === '' && isGuest()) || (localStorage.getItem("guestData") === 'null' && isGuest()) || (isGuest() && localStorage.getItem("guestData") === null)) {
        this.setForIsGuestLogin(responseJson)
      } else {
        this.getIfLogged()
      }
    } else {
      this.setState({
        isFlashLogin: false,
        isProductFlashLogin: false
      });
      errorNotification("something went wronge");
    }
  }

  getIfLogged = () => {
    if (this.state.isProductDownload) {
      this.downloadCatalogApiCall()
    }
    this.setState({
      isFlashLogin: false,
      isProductFlashLogin: false
    })
  }

  setForIsGuestLogin = (responseJson: any) => {
    if (responseJson?.result[0]?.enable) {
      this.setState({
        isFlashLogin: true,
        isProductFlashLogin: true
      })
    } else {
      if (this.state.isProductDownload) {
        this.downloadCatalogApiCall()
      }
      this.setState({
        isFlashLogin: false,
        isProductFlashLogin: false
      })
    }
  }

  setGuestData = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({
        isFlashLogin: false,
        isProductFlashLogin: false,
        emailError: false,
      })
      if (this.state.isProductDownload) {
        this.downloadCatalogApiCall();
      } else {
        window.location.reload();
      }
      localStorage.setItem("guestData", JSON.stringify(responseJson?.data?.attributes));
    } else {
      this.setState({
        emailError: responseJson.errors[0].email
      })
    }
  }

  sendDownloadEmail = (responseJson: any) => {
    if (responseJson) {
      this.setState({
        isProductDownload: false
      })
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000)
    }
  }

  setDownloadProductData = async (responseJson: any) => {
    this.setState({loading: false})
        if (responseJson.result) {
          this.setState({
            isProductDownload: false
          })
          const response = { ...responseJson.result };
          downloadAttachmentFile({
            id: response.id,
            file_name: response.file_name,
            doc_file: response.file
          });
        } else if (responseJson.errors && responseJson.errors[0]?.token === configJSON.tokenexperiedText && JSON.parse(localStorage.getItem("userInfo") || "")?.attributes?.is_customer === 'True') {
            this.setState({loading: false})
            await handleUpdateAuthToken()
            const authToken = isLoggedIn(this.props.navigation);
            if (typeof (authToken) === "string") {
              this.authToken = authToken;
            }
            if (this.authToken) {
              this.downloadCatalogApiCall();
            }
        }
  }

  setCategoriesWithProductId = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({
        categoriesWithProductList: responseJson.categories,
      })
      let count = 0
      responseJson.categories.forEach((data: any) => {
        data.products.forEach((products: any)=>{
          if(this.state.productDropdownValue !== 0 && products.id === parseInt(this.state.productDropdownValue)) {
            count++
          }
        })
      })
      if (count === 0) {
        this.setState({
          productDropdownValue: 0,
          categoryDropdownValue: 0
        }, () => {
          this.handleFilterAndSearch() 
        })
      } else {
        this.handleFilterAndSearch()
      }
    } else {
      warningNotification(responseJson.message);
      this.setState({
        loading: false
      })
    }
  }

  setFilterProductData = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.success) {
      this.setState({
        arrayHolder: responseJson.data,
        productsList: responseJson.result.data,
        productsListingMeta: responseJson.result.meta,
        loading: false,
        noData: false
      });
    } else {
      warningNotification(responseJson.message);
      this.setState({
        productsList: [],
        productsListingMeta: null,
        loading: false,
        noData: true
      })
    }
  }

  setListData = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.success) {
      this.setState({
        arrayHolder: responseJson.data,
        productsList: responseJson.result.data,
        productsListingMeta: responseJson.result.meta,
      });
      runEngine.debugLog("arrayHolder", this.state.arrayHolder);
    } else {
      warningNotification(responseJson.message);
      this.setState({productsList: [],productsListingMeta: null})
    }
  }

  setCategoryData = (responseJson: any) => {
    const categories = responseJson.categories.map((category: any) => {
      return {
        id: category[0],
        value: category[1]
      };
    });

    const therapies = responseJson.therapeutic_area.map((therapy: any) => {
      return {
        id: therapy[0],
        value: therapy[1]
      }
    });
    const products = responseJson.api_technologies.map((product: any) => {
      return {
        id: product[0],
        value: product[1]
      }
    });
    this.setState({
      uniqueProductsCategory: categories,
      uniqueProductsName: products,
      uniqueProductsTherapy: therapies,
    });
  }

  setSortData = (responseJson: any) => {
    this.setState({
      arrayHolder: responseJson?.data,
      productsList: responseJson?.result?.data,
      productsListingMeta: responseJson?.result?.meta,
    });
  }

  debounce = (func: any, wait: number = 700) => {
    let timer: any;
    return (...args: any[]) => {
      clearTimeout(timer)
      timer = setTimeout(() => func(...args), wait);
    };
  };
  debouncedCallback = this.debounce(() => this.handleSearchByProductName(), 700);

  searchByProductName = (event: any) => {
    const value = event?.target?.value;
    if (value && value?.length > 0) {
      this.setState({
        isSearch: true,
        searchString: value,
        productNameDropdownValue: 0,
        categoryDropdownValue: 0,
        therapyDropdownValue: 0,
        page: 1
      }, () => {
        this.debouncedCallback();
      });
    }
    else {
      this.setState({
        isSearch: false,
        searchString: "",
      }, () => {
        this.handleFilterAndSearch();
      });
    }
  };

  duplicateSortAndFilter = () => {
    if (this.state.isFilter) {
      this.handleFilterAndSearch();
    } else {
      this.handleSearchByProductName();
    }
  }

  handlePageChange = (e: any, p: any) => {
    this.setState({
      page: p,
    },
      () => this.duplicateSortAndFilter()
    );
    this.props.navigation.navigate('ProductListing', { pageNumber: p })
  };

  sortAscending = async () => {
    this.setState({ Ascending: true, isSort: true, sortDirection: 'asc' }, () => this.duplicateSortAndFilter());
  };

  sortDescending = async () => {
    this.setState({ Ascending: false, isSort: true, sortDirection: 'desc' }, () => this.duplicateSortAndFilter());
  };
  
  setDefaultProductLength = async () => {
    this.setState({
      arrayHolder: this.state.arrayHolderDuplicate,
      count: this.state.arrayHolderDuplicate.length,
    });
  };

  handleSearchByProductName = () => {
    const header = getHeaders({ token: this.authToken });

    const searchApi = apiCall({
      header,
      url: configJSON.productSearchApiEndPoint + `?query=${this.state.searchString}&per_page=${PER_PAGE}&page=${this.state.page}&sort[direction]=${this.state.sortDirection}&sort[order_by]=name`,
      httpBody: {},
      httpMethod: configJSON.productSearchApiType,
    });

    this.searchProductApiCallId = searchApi.messageId;
    runEngine.sendMessage(searchApi.id, searchApi);
  };

  handleSorting = () => {
    this.setState({loading: true})
    const header = getHeaders({ token: this.authToken });
    const sortApi = apiCall({
      header,
      url: `${configJSON.productSortApiEndPoint}&sort[direction]=${this.state.sortDirection}&per_page=${this.perPageRecords}&page=${this.state.page}`,
      httpBody: {},
      httpMethod: configJSON.productSortApiType
    });

    this.sortProductApiCallId = sortApi.messageId;
    runEngine.sendMessage(sortApi.id, sortApi);
  }

  filterByProductName = (event: React.ChangeEvent<{ value: any }>) => {
    const productNameValue = event?.target?.value ? event?.target?.value : 0;
    this.setState({ productNameDropdownValue: productNameValue, page: 1, selectedCategoryGroupValue: '', categoryDropdownValue: this.state.categoryDropdownValue }, () => {
      this.isAnyFilterApplied();
      this.getCategoriesProductListing();
    });
  };

  filterByProductCategory = (event: React.ChangeEvent<{ value: any }>) => {
    let productValue;
    let categoryValue;
    const selectedValue = event?.target?.value ? event?.target?.value : '';
    if (selectedValue !== '') {
      productValue = selectedValue.split(',')[0] || 0;
      categoryValue = selectedValue.split(',')[1] || 0;
    } else {
      productValue = 0;
      categoryValue = 0
    }
    this.setState({ selectedCategoryGroupValue: selectedValue, categoryDropdownValue: categoryValue, productDropdownValue: productValue, page: 1 }, () => {
      this.handleFilterAndSearch();
      this.isAnyFilterApplied();
    });
  };

  filterByProductCategory2 = (event: React.ChangeEvent<{ value: any }>, value: any) => {
    let productValue;
    let categoryValue;
    const selectedValue = localStorage.getItem("selectValue") ? localStorage.getItem("selectValue") : '';
    if (selectedValue !== '' && selectedValue !== null) {
      productValue = selectedValue?.split(',')[0] || 0;
      categoryValue = selectedValue?.split(',')[1] || 0;
    } else {
      productValue = 0;
      categoryValue = 0
    }
    if (!value) {
      this.setState({ selectedCategoryGroupValue: selectedValue, categoryDropdownValue: categoryValue, productDropdownValue: productValue, page: 1, therapyDropdownValue: this.state.therapyDropdownValue, productNameDropdownValue: this.state.productNameDropdownValue }, () => {
        this.handleFilterAndSearch();
        this.isAnyFilterApplied();
      });
    }
  };

  filterByProductTherapy = (event: React.ChangeEvent<{ value: any }>) => {
    const therapyValue = event?.target?.value ? event?.target?.value : 0;
    this.setState({ therapyDropdownValue: therapyValue, page: 1, selectedCategoryGroupValue: '', categoryDropdownValue: this.state.categoryDropdownValue });
    this.setState({ therapyDropdownValue: therapyValue, selectedCategoryGroupValue: '' }, () => {
      this.isAnyFilterApplied();
      this.getCategoriesProductListing();
    });
  };

  handleKnowMoreClick = (id: string) => {
    if (this.props.navigation) {
      this.props.navigation.navigate("ProductDetails", { id });
      localStorage.setItem("from", "product");
    }
  };

  isAnyFilterApplied = () => {
    if (this.state.categoryDropdownValue === 0 && this.state.therapyDropdownValue === 0 && this.state.productNameDropdownValue === 0) {
      this.setState({
        isFilter: false
      })
    } else {
      this.setState({
        isFilter: true,
        searchString: ""
      })
    }
  }

  getCategoriesList = () => {
    const categoryApi = apiCall({
      header: getHeaders({}),
      url: configJSON.getCategoriesApiEndPoint,
      httpMethod: configJSON.getCategoriesApiType,
      httpBody: {}
    });

    this.getCategoriesApiCallId = categoryApi.messageId;
    runEngine.sendMessage(categoryApi.id, categoryApi);
  }

  handleFilterAndSearch = (p: number = 1) => {
    this.setState({loading: true})
    let productSearchVal = `&category_id=${this.state.categoryDropdownValue}&catalogue_id=${this.state.productDropdownValue}`
    const filterAndSearchApi = apiCall({
      url: configJSON.getFilterAndSearchApiEndPoint + `?per_page=${this.perPageRecords}&page=${this.state.page}${this.state.categoryDropdownValue !== 0 ? productSearchVal : ''}${this.state.therapyDropdownValue !== 0 ? '&therapeutic_area_id=' + this.state.therapyDropdownValue : ''}${this.state.productNameDropdownValue !== 0 ? '&api_technology_id=' + this.state.productNameDropdownValue : ''}&sort[direction]=${this.state.sortDirection}&sort[order_by]=name`,
      header: getHeaders({}),
      httpBody: {},
      httpMethod: configJSON.getFilterAndSearchApiType
    });

    this.getFilterAndSearchProductsApiCallId = filterAndSearchApi.messageId;
    runEngine.sendMessage(filterAndSearchApi.id, filterAndSearchApi);
  }

  getCategoriesProductListing = () => {
    this.setState({loading: true});
    const selectedTherapeuticAreaId = this.state.therapyDropdownValue ? this.state.therapyDropdownValue : "";
    const selectedApiTechnology = this.state.productNameDropdownValue ? this.state.productNameDropdownValue : "";
    const categoriesWithProducts = apiCall({
      url: configJSON.getCategoriesWithProductsApiEndpoint +`?therapeutic_area_id=${selectedTherapeuticAreaId}&api_technology_id=${selectedApiTechnology}`,
      header: getHeaders({}),
      httpBody: {},
      httpMethod: configJSON.getCategoriesWithProductsApiMethod
    });

    this.getCategoriesWithProductsCallId = categoriesWithProducts.messageId;
    runEngine.sendMessage(categoriesWithProducts.id, categoriesWithProducts);
  }

  downloadCatalogApiCall = () => {
    this.setState({loading: true})

    const downloadCatalog = apiCall({
      url: configJSON.downloadCatalogApiEndpoint,
      header: getHeaders({}),
      httpBody: {},
      httpMethod: configJSON.downloadCatalogApiMethod
    });

    const authToken = localStorage.getItem("authToken") || '';

    const downloadCatalogEmail = apiCall({
      url: configJSON.downloadCatalogSentEmail,
      header: getHeaders({ token: authToken }),
      httpBody: {},
      httpMethod: configJSON.downloadCatalogSentEmailMethod
    })

    this.downloadCatalogApiCallId = downloadCatalog.messageId;
    this.downloadCatalogSentEmailId = downloadCatalogEmail.messageId;
    runEngine.sendMessage(downloadCatalog.id, downloadCatalog);
    runEngine.sendMessage(downloadCatalogEmail.id, downloadCatalogEmail);
  }

  downloadProduct = () => {
    this.isActiveFlashUser()
    this.setState({
      isProductDownload: true
    })
  };

  onSave = () => {
    if (this.state.email.trim().length === 0 && !RegExp(configJSON.emailTestRegex).exec(String(this.state.email)
      .toLowerCase())) {
      this.setState({
        emailError: true
      })
      return false;
    } else {
      this.setState({
        emailError: false
      })
    }
    const headers = {
      "Content-Type": configJSON.productApiContentType,
    };
    const body = {
      full_name: this.state.firstName,
      company_name: this.state.companyName,
      email: this.state.email,
      phone_number: parseInt(`${this.state.phoneNumber}`),
      country_code: parseInt(`${this.state.phoneCountryCode}`),
    };

    const updateAccount = apiCall({
      httpBody: body,
      header: headers,
      url: configJSON.guestLoginApiEndPoint,
      httpMethod: configJSON.downloadCatalogSentEmailMethod,
    });

    this.GuestLoginApiCallId = updateAccount.messageId;
    runEngine.sendMessage(updateAccount.id, updateAccount);
    this.setState({loading: true})
    return true;
  }

  closeFlashLogin = () => {
    let new_window = open('/noAccess', '_self');
    if (new_window !== null) {
      new_window?.close();
      return false;
    }
    this.setState({
      isFlashLogin: false,
      isProductFlashLogin: false
    })
  };

  handlePhoneCountryCode(event: any, value: any) {
    if (value) {
      this.setState({
        phoneCountryCode: event.value,
      });
    }
  };

  handleChange = (prop: any) => (event: any) => {
    if (prop === 'phoneNumber') {
      let regEx2 = /^[0-9\b]+$/;
      if (event?.target?.value.length === 0) {
        this.setState({ ...this.state, [prop]: event.target.value });
        return true;
      }
      if (event?.target?.value.match(regEx2)) {
        this.setState({ ...this.state, [prop]: event.target.value });
        return true;
      } else {
        return false;
      }
    }
    if (prop === 'firstName') {
      if (event.target.value.length === 0) {
        this.setState({ ...this.state, [prop]: event.target.value });
        return true;
      }
    }
    this.setState({ ...this.state, [prop]: event.target.value });
  };

  isActiveFlashUser = () => {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
    };

    const updateAccount = apiCall({
      httpBody: {},
      header: headers,
      url: configJSON.isActiveFlashUserAPIEndPoint,
      httpMethod: configJSON.getMyQueryRepliesApiMethod,
    });

    this.IsFlashUserApiCallId = updateAccount.messageId;
    runEngine.sendMessage(updateAccount.id, updateAccount);
    this.setState({loading: true})
    return true;
  }
  // Customizable Area End
}
