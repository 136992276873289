import React from "react";
import {
  Typography,
  CardMedia,
  Container,
  Grid,
  Card,
  CardContent,
  Box,
  IconButton,
} from "@material-ui/core";
import LandingPageController, {
  ILandingPageUpdate
} from "./LandingPageController";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { withStyles } from "@material-ui/core/styles";

export class NewUpdates extends LandingPageController {
  handleUpdateClick = (index: number) => {
    const updatedArray: ILandingPageUpdate[] | null = this.state.updates?.map((update: ILandingPageUpdate, updateIndex: number) => {
      if (updateIndex === index) {
        update.isClicked = true;
      }
      return update
    }) || null;
    this.setState({
      updates: updatedArray
    })
  }

  getUpdatesList(item: ILandingPageUpdate, index: any) {
    let value = item.attributes;
    const { classes } = this.props
    return (
      <Grid item lg={6} md={6} sm={12} xs={12} key={`update-item-${index}`}>
        <Card style={{ ...webStyle.card, flexDirection: 'column' }}
          className={[classes?.mainCard, 'video'].join(' ')}
        >
          {/* @ts-ignore */}
          {
            value.file_attached === "Media attached is video" ?
              item.isClicked ?
                <div className="video">
                  <CardMedia
                    component="video"
                    src={value.file}
                    autoPlay={true}
                    controls
                    controlsList="nodownload"
                    style={webStyle.cardMedia}
                    className="bannerVideo"
                    height="60.9%"
                  />
                </div> :
                <CardMedia
                  component="image"
                  src={value.thumbnail}
                  image={value.thumbnail}
                  height="60.9%"
                  style={{ ...webStyle.cardMediaImage, position: 'relative' }}
                /> :
              // @ts-ignore
              <CardMedia
                component="image"
                src={value.file}
                image={value.file}
                height="60.9%"
                style={{ ...webStyle.cardMediaImage, position: 'relative' }}
              />
          }
          {
            !item.isClicked && value.file_attached === "Media attached is video" && (
              <PlayButton
                data-testid='handleUpdateClick'
                onClick={() => this.handleUpdateClick(index)}
              >
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                >
                  <ArrowRightIcon style={webStyle.Playicon}></ArrowRightIcon>
                </IconButton>
              </PlayButton>
            )
          }
          <CardContent style={{ paddingTop: "24px" }}>
            <Typography
              variant="body2"
              component="p"
              style={webStyle.updateTitle}
            >
              {value.title}
            </Typography>
            <Typography
              variant="body2"
              style={webStyle.updateDetail}
              className="person-description"
            >
              {value.description}
            </Typography>
          </CardContent>
        </Card>
      </Grid >
    );
  }



  render() {
    return (
      <>
        <MainGrid>
          <Container style={{ padding: 0 }}>
            {/* <Typography style={webStyle.listName}>Quick Links</Typography> */}
            {this.state.IsViewAll ? (
              <Grid container spacing={4} style={webStyle.updateContainer}>
                {this.state.updates &&
                  this.state.updates.map((item: ILandingPageUpdate, index: any) =>
                    this.getUpdatesList(item, index)
                  )}
              </Grid>
            ) : (
              <Grid container spacing={4} style={webStyle.updateContainer}>
                {this.state.updates &&
                  this.state.updates
                    .slice(0, 4)
                    .map((item: ILandingPageUpdate, index: any) =>
                      this.getUpdatesList(item, index)
                    )}
              </Grid>
            )}
            {!this.state.IsViewAll && this.state.updates && this.state.updates.length > 4 && (
              <Grid container justifyContent="flex-end">
                <Typography
                  component="div"
                  style={webStyle.viewAll}
                  onClick={this.setIsViewAll}
                >
                  View All
                </Typography>
              </Grid>
            )}
          </Container>
        </MainGrid>
      </>
    );
  }
}

const webStyle = {
  mainContainer: {
    padding: "25px 60px 10px 70px"
  },
  updateContainer: {
    padding: "15px 0px 15px 0px"
  },
  listName: {
    paddingTop: "8px",
    fontSize: "28px",
    color: "rgba(4, 60, 116, 1)",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
  },
  card: {
    borderRadius: "10px",
    width: "100%",
    display: 'flex',
    boxShadow: '0 0 12px 0 rgba(0, 0, 0, 0.13)',
    backgroundColor: '#fff',
  },
  cardMedia: {
    borderRadius: "10px",
    width: "100%",
    height: "100%",
    border: 0,
  },
  cardMediaImage: {
    borderRadius: "10px",
    width: "100%",
    minHeight: "60.9%",
    border: 0,
  },
  updateTitle: {
    color: "rgba(4, 60, 116, 1)",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "20px",
    paddingBottom: '10px'
  },
  updateDetail: {
    color: "rgba(4, 60, 116, 1)",
    fontFamily: "SFProDisplay",
    fontSize: "16px",
    fontWeight: 500
  },
  viewAll: {
    textDecoration: "underline",
    color: "rgb(4, 60, 116)",
    fontSize: "14px",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    cursor: "pointer"
  },
  Playicon: {
    fontSize: `50px !important`,
  }
};

const MainGrid:any = withStyles({
  root: {
    padding: "25px 60px 10px 70px",
    "@media (max-width: 700px)": {
      padding: "25px 30px 10px 30px"
    },
    "& .MuiCardMedia-root": {
      backgroundPosition: `top !important`,
      backgroundSize: `100% 100% !important`,
    },
    video: {
      width: "100%",
      height: "100%",
      margin: `0px !important`
    }
  }
})(Grid);

export const PlayButton: any = withStyles({
  root: {
    position: 'absolute',
    background: 'white',
    left: '50%',
    top: '35%',
    height: '56px',
    width: '56px',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    "& .MuiSvgIcon-root": {
      fontSize: '60px !important',
      fill: '#459eff'
    }
  }
})(Box);

const styles = () => ({
  mainCard: {
    // height: '466px',
    // minHeight: '40.5%',
    height: '370px',
    "@media (max-width: 1024px)": {
      height: "370px"
    }
  }
})

export default withStyles(styles)(NewUpdates)
