import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall, getHeaders, isAuthorisedUser, isLoggedIn, warningNotification } from "../../../components/src/common";

// Customizable Area Start
import { handleUpdateAuthToken } from "../../../components/src/common";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

export enum MY_QUERIES_SORT_TYPES {
  SORT_BY_OLDEST = 0,
  SORT_BY_NEWEST = 1,
  SORT_BY_DATE = 2
}

export interface IMyTicketDetail {
  product_name: string;
  product_query_id: number;
  query_type: string;
  status: string;
  status_id: number;
  ticket_creation_date: string;
  ticket_id: number;
  ticket_number: string;
  status_message: string;
  doc_name: [];
}

export interface IQueryListMeta {
  current_count: number;
  current_page: number;
  next_page: number;
  prev_page: number;
  total_count: number;
  total_pages: number;
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  is_loading: boolean;
  filter_key: number;
  sort_key: number;
  queries_list: IMyTicketDetail[];
  total_tickets_count: number;
  selected_tab: number;
  per_page_records: number;
  page_number: number;
  query_list_meta: IQueryListMeta | null;
  tickets_count: number | null;
  allcontectInvioce: any[];
  contactInvoiceListingMeta: any;
  page: any;
  perPageRecord: any;
  account_id: any;
  catalogue_id: any;
  invoice: any;
  invoice_date: any;
  invoice_amount: any;
  account_currency_id: any;
  p_o: any;
  due_date: any;
  payment_term_id: any;
  inco_term_id: any;
  payment_status: any;
  attachment: any;
  contactNameLabel: any;
  productNameLabel: string;
  invoiceLabel: string;
  invoiceDateLabel: string;
  invoiceAmountLabel: string;
  invoiceCurrencyLabel: string;
  invoicePOLabel: string;
  invoiceDueDateLabel: string;
  invoicePaymentTermsLabel: string;
  invoiceIncoTermsLabel: string;
  invoicePaymentStatusLabel: string;
  invoiceAttachmentLabel: string;
  invoiceAddLabel: string;
  attachmentLabel: string;
  getAttachment: any;
  getDataaaa: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MyInvoicesController extends BlockComponent<Props, S, SS> {
  getQueriesApiCallId: string;
  getAllContectInvioceApiCallId: string;
  getUserPersonalApiCallId: string;
  authToken: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      is_loading: false,
      filter_key: 0,
      sort_key: 1,
      queries_list: [],
      total_tickets_count: 0,
      selected_tab: 0,
      per_page_records: 12,
      page_number: 1,
      query_list_meta: null,
      tickets_count: 0,
      allcontectInvioce: [],
      contactInvoiceListingMeta: null,
      page: 1,
      perPageRecord: 20,
      account_id: '',
      catalogue_id: '',
      invoice: '',
      invoice_date: '',
      invoice_amount: '',
      account_currency_id: '',
      p_o: '',
      due_date: '',
      payment_term_id: '',
      inco_term_id: '',
      payment_status: '',
      attachment: "",
      contactNameLabel: configJSON.contactNameLabel,
      productNameLabel: configJSON.productNameLabel,
      invoiceLabel: configJSON.invoiceLabel,
      invoiceDateLabel: configJSON.invoiceDateLabel,
      invoiceAmountLabel: configJSON.invoiceAmountLabel,
      invoiceCurrencyLabel: configJSON.invoiceCurrencyLabel,
      invoicePOLabel: configJSON.invoicePOLabel,
      invoiceDueDateLabel: configJSON.invoiceDueDateLabel,
      invoicePaymentTermsLabel: configJSON.invoicePaymentTermsLabel,
      invoiceIncoTermsLabel: configJSON.invoiceIncoTermsLabel,
      invoicePaymentStatusLabel: configJSON.invoicePaymentStatusLabel,
      invoiceAttachmentLabel: configJSON.invoiceAttachmentLabel,
      invoiceAddLabel: configJSON.invoiceAddLabel,
      attachmentLabel: configJSON.attachmentLabel,
      getAttachment: "",
      getDataaaa: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.downloadAPIAttachmentFile = this.downloadAPIAttachmentFile.bind(this);
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const authToken = isLoggedIn(this.props.navigation);
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
    }
    this.getUserPersonalDetails();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }

    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      isAuthorisedUser(responseJson, this.props.navigation);
      this.setState({
        is_loading: false
      });

      // if (this.getQueriesApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      //   if (responseJson && responseJson.data && responseJson.success === true) {
      //     this.setState({
      //       total_tickets_count: responseJson.total_count,
      //       queries_list: responseJson.data,
      //       query_list_meta: responseJson.meta.pagination_details,
      //       tickets_count: responseJson.meta.pagination_details.total_count,
      //     })
      //   } else {
      //     if (responseJson.errors[0].token === "Token has Expired" && JSON.parse(localStorage.getItem("userInfo") || "")?.attributes?.is_customer === 'True') {
      //       this.setState({is_loading: true});
      //       await handleUpdateAuthToken();
      //       const authToken = isLoggedIn(this.props.navigation);
      //       if (typeof (authToken) === "string") {
      //         this.authToken = authToken;
      //       }
      //     }
      //     if (!responseJson.success && responseJson.data.length === 0) {
      //       this.setState({
      //         queries_list: [],
      //         query_list_meta: null,
      //         total_tickets_count: 0,
      //         tickets_count: 0,
      //       })
      //     } else {
      //       warningNotification(configJSON.errorMessage);
      //     }
      //   }
      // }

      if (this.getUserPersonalApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        this.setState({ is_loading: false })
        if (!responseJson.error && !responseJson.errors) {
          this.setState({
            account_id: responseJson.data.attributes.customer_invoice_person,
            catalogue_id: responseJson.data.attributes.customer_invoice_catalogue,
            invoice: responseJson.data.attributes.invoice,
            invoice_date: responseJson.data.attributes.invoice_date,
            invoice_amount: responseJson.data.attributes.invoice_amount,
            account_currency_id: responseJson.data.attributes.currency,
            p_o: responseJson.data.attributes.p_o,
            due_date: responseJson.data.attributes.due_date,
            payment_term_id: responseJson.data.attributes.payment_term,
            inco_term_id: responseJson.data.attributes.inco_term,
            payment_status: responseJson.data.attributes.payment_status,
            attachment: responseJson.data.attributes.attachment,
            is_loading: false,
            getAttachment: responseJson.data.attributes.attachment,
          })
        }
        else {
          if (responseJson.errors && responseJson.errors[0]?.token === "Token has Expired" && JSON.parse(localStorage.getItem("userInfo") || "")?.attributes?.is_customer === 'True') {
            this.setState({ is_loading: true });
            await handleUpdateAuthToken();
            const authToken = isLoggedIn(this.props.navigation);
            if (typeof (authToken) === "string") {
              this.authToken = authToken;
            }
            if (this.authToken) {
              this.getUserPersonalDetails();
            }
          }
        }
      }

    }
    // Customizable Area End
    console.log("allcontectInvioce", this.state.getDataaaa);
  }

  // Customizable Area Start
  getUserPersonalDetails = () => {
    this.setState({
      is_loading: true
    });
    const header = getHeaders({ token: this.authToken });
    let customerUrlId: any = window.location.href.split("/")
    let userId: any = JSON.parse(localStorage.getItem("userInfo") || "")?.id;

    let url = configJSON.getByIdCustomerInvioceApiEndPoint + `?customer_master_id=${customerUrlId.at(-2)}` + `&customer_invoice_id=${customerUrlId.at(-1)}`;
    const getAccount = apiCall({
      httpBody: {},
      header: header,
      url: url,
      httpMethod: configJSON.getByIdCustomerInvioceApiMethod,
    });

    this.getUserPersonalApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  };

  downloadAPIAttachmentFile = (attachment: any) => {
    fetch(attachment.attachment, { method: 'GET' })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        if (attachment.file_name !== undefined) {
          link.setAttribute(
            'download',
            attachment.file_name,
          );
        }
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link?.parentNode?.removeChild(link);
      });
  }
  // Customizable Area End
}
