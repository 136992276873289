import React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  MenuItem,
  Grid,
  Button,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import { NavLink as RLink } from "react-router-dom";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { HandleAboutUsRedirection, isGuest, logout } from "./common";

const logo = require("./Logo.png");
const ConnectLogo = require('./Bio-connectLogo.png');

export const NewAppHeader = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<any>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" style={webStyle.appBar} className="app-bar">
      <Container>
        <Toolbar disableGutters>
          <RLink style={{ ...webStyle.NavLink, display: 'flex' }} to="/">
            <img style={webStyle.logoStyle} src={logo} />
          </RLink>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              marginLeft: "-11rem",
            }}
          >
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              <ResponsiveAppBarRoutes />
            </Menu>
          </Box>

          {/* <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} /> */}

          <Grid container justifyContent="flex-end" alignItems="center">


            <Box sx={{ display: { xs: "none", sm: "none", md: 'flex' } }} alignItems="center" className="appBarRoute">
              <ResponsiveAppBarRoutes />
            </Box>

            {!isGuest() &&
              <>
                <Box className="routeIcon">
                  <IconButton
                    id="basic-button"
                    onClick={handleOpenUserMenu}
                    aria-controls={Boolean(anchorElUser) ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={Boolean(anchorElUser) ? 'true' : undefined}
                    style={webStyle.IconButton}
                    className="account-circle"
                  >
                    <AccountCircle />
                    <ArrowDropDownIcon />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorElUser}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    keepMounted
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <React.Fragment>
                      <MenuItem onClick={handleCloseUserMenu} style={{ height: '35px', borderBottom: '1px solid #E1E0E6' }}>
                        <Typography style={{ textAlign: "center" }}>
                          <RLink to="/CustomisableUserProfiles" style={webStyle.linkName}>
                            My Profile
                          </RLink>
                        </Typography>
                      </MenuItem>
                      <MenuItem onClick={handleCloseUserMenu} style={{ height: '35px', borderBottom: '1px solid #E1E0E6' }}>
                        <Typography style={{ textAlign: "center" }}>
                          <RLink to="/CustomerTicketList" style={webStyle.linkName}>
                            My Requests
                          </RLink>
                        </Typography>
                      </MenuItem>
                      <MenuItem onClick={handleCloseUserMenu} style={{ height: '35px' }}>
                        <Typography style={{ textAlign: "center" }}>
                          <RLink onClick={logout} to="/" style={webStyle.linkName}>
                            Log Out
                          </RLink>
                        </Typography>
                      </MenuItem>
                    </React.Fragment>
                  </Menu>
                </Box>
                <DividerBox></DividerBox>
              </>
            }

            <Box style={{ marginLeft: '26px' }}>
              <RLink style={{ ...webStyle.NavLink, display: 'flex', marginRight: '7px' }} to="/">
                <img style={webStyle.logoStyle2} src={ConnectLogo} />
              </RLink>
            </Box>
          </Grid>


        </Toolbar>
      </Container>
    </AppBar>
  );
};


const ResponsiveAppBarRoutes = () => {

  return (
    <CustomGrid container alignItems="center">
      <RLink to="/NewHeader" style={webStyle.linkName}
        activeClassName='is-active'
        exact={true}
      >
        Home
      </RLink>
      <RLink to="/ProductListing/1"
        style={webStyle.linkName}
        activeClassName='is-active'
        exact={true}
      >
        Products
      </RLink>
      <RLink to="/APIView"
        style={webStyle.linkName}
        activeClassName='is-active'
        exact={true}
      >
        API
      </RLink>
      <RLink to="/"
        style={webStyle.linkName}
        onClick={HandleAboutUsRedirection}
        // activeClassName='is-active'
        exact={true}
      >
        About Us
      </RLink>
      <RLink
        to="/connectwithus"
        style={{
          alignItems: 'center',
          display: 'flex'
        }}
        exact={true}
      >
        <ConnectWithUsBtn>
          Connect with us
        </ConnectWithUsBtn>
      </RLink>
      {
        isGuest() && (
          <>
            <RLink
              to="/login"
              exact={true}
              style={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <LoginButton>
                Login
              </LoginButton>
            </RLink>
            <DividerBox></DividerBox>
          </>
        )
      }
    </CustomGrid>
  );
};
const webStyle = {
  appBar: {
    backgroundColor: "#fff",
  },
  queryPopUpText: {
    fontWeight: 500,
    color: 'rgba(33, 43, 54, 1)',
    fontFamily: 'SFProDisplay',
    fontSize: '20px',
    margin: '0px 15px',
  },
  NavLink: {
    textDecoration: "none",
    color: "inherit",
    fontSize: "inherit",
  },
  logoStyle: {
    height: "auto",
    width: '180px',
    // marginLeft: "20px",
  },
  logoStyle2: {
    height: "auto",
    width: '180px',
  },
  linkName: {
    color: "#003e76",
    display: "block",
    margin: "0px 20px",
    textDecoration: "none",
    fontFamily: 'SFProDisplay',
    fontWeight: 700,
    fontSize: '18px',
    boxShadow: 'none'
  },
  connect: {
    backgroundColor: "#ff8b33",
    borderRadius: "8px",
    maxHeight: '35px',
    padding: '7px 10px 0px 10px',
    margin: "9px 5px 9px 5px",
    fontWeight: 500,
    color: 'white',
    fontFamily: 'SFProDisplay',
    fontSize: '16px'
  },
  IconButton: {
    color: "rgb(4, 60, 116)",
  },
  divider: {
    height: '185px',
    margin: "4px 20px 0px 20px",
  },
  login: {
    color: 'rgba(255, 255, 255, 1)',
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    fontSize: "18px",
  },
  signup: {
    color: 'rgba(4, 60, 116, 1)',
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    fontSize: "18px",
  }
};

const CustomGrid:any = withStyles({
  root: {
    "& .MuiIconButton-root": {
      padding: '12px 20px'
    }
  }
})(Grid);

const LoginButton:any = withStyles({
  root: {
    width: "120px",
    height: "35px",
    color: '#ff8b33',
    opacity: 1,
    margin: '0 6px 0 20px',
    padding: '9px 38px 9px 39px',
    borderRadius: '19.5px',
    border: ' solid 1px #ff8b33',
    backgroundColor: '#fff',
    boxShadow: "none",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    fontSize: "18px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#fff",
      boxShadow: "none",
    },
    "&.MuiButton-outlined": {
      border: '1px solid #ff8b33',
      color: '#ff8b33',
      padding: '9px 38px 9px 39px',
      "&:hover": {
        backgroundColor: '#fff',
      }
    },
    "@media (max-width: 500px)": {
      width: '100%',
    }
  },
})(Button);

const DividerBox:any = withStyles({
  root: {
    width: '1px',
    height: '49px',
    margin: '0px 0px 0 20px',
    backgroundColor: '#6a74a5',
  }
})(Box);

const ConnectWithUsBtn:any = withStyles({
  root: {
    width: "136px",
    height: "35px",
    color: '#fff',
    margin: '0 20px 0 20px',
    borderRadius: '8px',
    border: 'solid 1px #ff8b33',
    backgroundColor: '#ff8b33',
    boxShadow: "none",
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    fontSize: "16px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#ff8b33",
      boxShadow: "none",
    },
    "@media (max-width: 500px)": {
      width: '100%',
    }
  },
})(Button);
