import React from "react";
// Customizable Area Start
import {
    withStyles,
} from "@material-ui/core/styles";
import {
    Grid,
    Tab,
    Tabs,
    Box,
    Container,
    TextField,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Typography,
    InputLabel,
    FormControl,
    Select,
    MenuItem,
} from "@material-ui/core";
import AdminConsole3Controller, {
    Props,
} from "./TicketListController";
import Alert from '@material-ui/lab/Alert';
import TicketList from "./TicketList.web";

const PER_PAGE = 9;

class NewTicket extends AdminConsole3Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <TicketList id="" navigation={this.props.navigation} />
               
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    downloadText: {
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '16px',
        color: '#043c74',
        textDecoration: 'underline',
        // textTransform: 'none',
    },
    downloadIcon: {
        height: "18px",
        width: "18px",
        margin: "0px",
        color: "#043c74"
    },
    Tab1: {
        background: '#fff',
    },
    Tab2: {
        borderTopLeftRadius: "16px",
        borderBottomLeftRadius: "16px",
        background: '#DCDFF0',
    },
    activeTransferredTickets: {
        borderTopRightRadius: "16px",
        borderBottomRightRadius: "16px",
        background: '#DCDFF0',
    },
    tab3: {
        borderTopLeftRadius: "16px",
        borderBottomLeftRadius: "16px",
    },
    headerText: {
        color: 'rgba(4, 60, 116, 1)',
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '16px',
    },
    dataText: {
        color: 'rgba(4, 60, 116, 1)',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '16px',
        borderColor: '#F2F2F5',
    },
    QueryTypeData: {
        color: 'rgba(4, 60, 116, 1)',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '16px',
        borderColor: '#F2F2F5',
        width: "130px"
    },
    dataTextflag: {
        color: '#ff0000',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '16px',
        borderColor: '#F2F2F5',
    },
    table: {
        border: '1px solid rgba(170, 188, 206, 1)',
        boxShadow: 'none',
        borderRadius: '16px',
    },
    Open: {
        backgroundColor: '#EBF7F3',
        color: '#85C67A',
        border: '1px solid #EBF7F3',
        cursor: 'arrow',
    },
    inProgress: {
        backgroundColor: '#FFF7E6',
        color: '#EBC74F',
        border: '1px solid #FFF7E6',
        cursor: 'arrow',
    },
    Closed: {
        backgroundColor: '#F8ECEE',
        color: '#CE4436',
        border: '1px solid #F8ECEE',
        cursor: 'arrow',
    },
    noData: {
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '20px',
    },
    alertBox: {
        color: "#3a3f63",
        borderRadius: "16px",
        border: 'solid 1px #f6e5ae',
        backgroundColor: '#fff9d7',
        fontSize: '16px',
        fontFamily: 'SFProDisplay',
        alignItems: 'center',
    },
    dialogTitle: {
        margin: `0px 28px 32px 0`,
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: 1.33,
        color: '#043c74',
    },
    inputLabel: {
        fontSize: "16px",
        fontFamily: "SFProDisplay",
        fontWeight: 700,
        lineHeight: 2,
        color: '#043c74',
        paddingBottom: "12px",
    },
    select: {
        height: "56px",
        borderRadius: "8px",
        marginTop: '5px',
    },
    countryPlaceHolder: {
        fontStyle: "normal",
        fontFamily: "SFProDisplay",
        fontWeight: 400,
        fontSize: "16px",
        color: "rgba(218, 224, 241, 1)",
    },
    grid: {
        paddingBottom: '25px'
    },
    errorText: {
        fontSize: '14px',
        color: 'red',
        fontFamily: 'SFProDisplay-Regular',
        marginTop: '6px',
        marginLeft: '5px'
    }
};

const MainContainer = withStyles({
    root: {
        padding: "34px 55px 6.5% 75px",
        "& .MuiTableCell-paddingNone": {
            padding: '12px 16px'
        },
        "& .MuiTableCell-alignRight": {
            flexDirection: 'row'
        },
        "& .MuiTableCell-root": {
            padding: '12px 16px',
        },
        "& .MuiTableRow-root": {
            backgroundColor: 'inherit',
            "&:hover": {
                backgroundColor: "inherit",
            },
        },
        "& .MuiTableSortLabel-icon": {
            display: 'none'
        },
        "& .MuiAlert-message": {
            padding: '0',
            display: 'contents'
        },
        "& .MuiAlert-standardWarning .MuiAlert-icon": {
            color: "#043c74",
            fontSize: "33px",
            padding: "2px 0 0 0",
        }
    }
})(Container)

const CountBox = withStyles({
    root: {
        height: '20px',
        width: '30px',
        backgroundColor: 'rgba(218, 224, 241, 1)',
        color: 'rgba(4, 60, 116, 1)',
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '12px',
        margin: '0px 5px 0px 12px',
        borderRadius: '16px',
        padding: '3px 3px'
    }
})(Box)

const SearchContainer = withStyles({
    root: {
        padding: '25px 0px 10px 0px'
    }
})(Grid);

const InputField = withStyles({
    root: {
        background: "inherite",
        margin: "5px 0px 0px 0px",
        "& ::placeholder": {
            color: 'rgba(185, 187, 197, 1)',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
            fontSize: '16px',
            opacity: 1,
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: 'rgba(185, 187, 197, 1)',
        },
        "& .MuiInputBase-input": {
            height: '15px',
        },
        "& .MuiOutlinedInput-input": {
            padding: `14.2px 14px !important`
        },
        "& .MuiOutlinedInput-root": {
            fontFamily: "SFProDisplay",
            fontWeight: 400,
            backgroundColor: "rgba(255, 255, 255, 1)",
            fontSize: "16px",
            "& fieldset": {
                height: '45px',
                borderColor: 'rgba(185, 187, 197, 1)',
                borderRadius: "8px",
                borderWidth: 1,
            },
            ".Mui-focused": {
                borderColor: 'rgba(185, 187, 197, 1)',
            },
        },
        "& .MuiFormHelperText-contained": {
            marginLeft: "0px !important",
        },
        "& .MuiInputBase-input.Mui-disabled": {
            background: "#F1F4FA",
            borderRadius: "8px",
            height: 13,
            color: 'rgba(106, 116, 165, 1)',
            fontSize: '16px',
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
        },
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: 'rgba(185, 187, 197, 1)',
            borderWidth: '1px'
        },
        "& .MuiIconButton-root": {
            paddingLeft: '0px'
        },
        "& .MuiOutlinedInput-adornedStart": {
            paddingLeft: '5px'
        },
        "& .MuiSvgIcon-root": {
            width: '30px',
            height: '40px',
        },
    },
})(TextField);

const StatusButton = withStyles({
    root: {
        backgroundColor: 'inherit',
        borderColor: 'inherit',
        borderRadius: '8px',
        borderWidth: '1px',
        width: '130px',
        height: '40px',
        boxShadow: 'none',
        textTransform: 'none',
        color: 'inherit',
        fontFamily: 'SFProDisplay',
        fontWeight: 500,
        fontSize: '16px',
        float: 'left',
        cursor: 'initial',
        marginLeft: '-2px',
        "&:hover": {
            backgroundColor: "#E3EFFD",
            boxShadow: "none",
        },
    }
})(Button);

const DetailButoon = withStyles({
    root: {
        backgroundColor: '#e1efff',
        border: '1px solid #459eff',
        borderRadius: '8px',
        borderWidth: '1px',
        width: '144px',
        height: '40px',
        color: '#459eff',
        fontFamily: 'SFProDisplay',
        fontWeight: 500,
        fontSize: '16px',
        boxShadow: 'none',
        textTransform: 'none',
        "&:hover": {
            backgroundColor: "#e1efff",
            boxShadow: "none",
        },
    }
})(Button);

const OverdueAlert = withStyles({
    root: {
        width: '100%',
        backgroundColor: '#fff',
        padding: '0px',
        "& .MuiAlert-root.MuiAlert-standardWarning": {
            alignItems: 'center',
            backgroundColor: '#fff9d7',
            border: '1px solid #f6e5ae',
            "& .MuiAlert-message": {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }
        },
        "&>.MuiAlert-icon": {
            display: 'none',
        },
        "& .MuiAlert-message": {
            width: '100%',
            padding: '0px',
            color: '#3a3f63',
            "& .MuiButtonBase-root.MuiButton-text": {
                width: 'auto',
                border: '1px solid #043c74',
                color: "#043c74",
            }
        },
        "&:hover": {
            // backgroundColor: "#e1efff",
            // boxShadow: "none",
        },
    }
})(Alert);

const styles = () => ({
    container: {
        "@media (max-width: 600px)": {
            paddingLeft: 20,
            paddingRight: 20

        },
    },
    overdueAlertRes: {
        "@media (max-width: 500px)": {
            flexWrap: 'wrap',
            rowGap: '10px',
            '& div': {
                margin: '0px !important'
            }
        }
    }
})

export default withStyles(styles)(NewTicket)
// Customizable Area End
// Customizable Area End
