import React from "react";

// Customizable Area Start
import { Container, Button, Box, Grid } from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { withStyles } from "@material-ui/core/styles";

// Customizable Area End
import QueryDetailsController, {
    Props,
} from "./DocPreviewController";

export class DocPreview extends QueryDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <MainContainer>
                    <CustomBox display={"flex"} justifyContent="space-between">
                        <BackButtom testID="backbutton" onClick={() => window.close()} variant="outlined" data-testId="backbutton">
                            <ArrowBackIosIcon style={webStyle.Backicon}></ArrowBackIosIcon>
                            {this.state.BackButtonText}
                        </BackButtom>
                    </CustomBox>
                    <Grid container style={webStyle.docContainer} className="document_view">
                        <iframe
                            data-testid="iframe"
                            style={webStyle.docView}
                            id="fraDisabled"
                            src={this.state.doc_file ? `${this.state.doc_file}#toolbar=0` : ''}
                        />
                        <div className="document_cover2" style={webStyle.coverWidth}></div>
                    </Grid>
                </MainContainer>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const MainContainer: any = withStyles({
    root: {
        padding: '20px 60px 0px 75px',
        "& .MuiOutlinedInput-input": {
            padding: '9px 12px',
        },
        "@media (max-width: 600px)": {
            padding: '20px 20px 20px 20px'
        },
        "& .react-pdf__Page__canvas": {
            width: `100% !important`,
            height: `400px !important`,
        },
    }
})(Container);

const BackButtom: any = withStyles({
    root: {
        color: '#193263',
        borderColor: '#193263',
        borderRadius: '5px',
        height: '34px',
        textTransform: 'none',
    }
})(Button)

const CustomBox: any = withStyles({
    root: {
        
    }
})(Box)

const webStyle = {
    coverWidth: {
        width: window.innerWidth - 150
    },
    Backicon: {
        width: '16px',
        height: '16px',
        marginRight: '3px'
    },
    docView: {
        width: '100%',
        height: '50%'
    },
    docContainer: {
        border: '1px solid #D9D9D9',
        height: '550px',
        borderRadius: '8px',
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
};

const styles = () => ({
    country: {
        paddingLeft: '40px !important',
        "@media (max-width: 600px)": {
            paddingLeft: '30px !important'
        }
    }
})

export default withStyles(styles)(DocPreview)
// Customizable Area End
