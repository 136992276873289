import React from "react";

// Customizable Area Start
import {
    Grid,
    Typography,
    TextField,
    InputLabel,
    Button,
    InputAdornment,
    IconButton,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { LoginImage, BoiConLogo } from "./assets";
import { withStyles } from "@material-ui/core/styles";
import { Dimensions } from "react-native";
// Customizable Area End

import EmailAccountRegistrationController, {
    Props
} from "./EmailAccountRegistrationController";

let screenHeight = Dimensions.get('window').height;

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    setCompanyDetails() {
        return (
            <>
                <InputLabel
                    htmlFor="companyname"
                    style={webStyle.inputLabel}
                >
                    {this.state.companyName} *
                </InputLabel>
                <InputField
                    id="companyname"
                    variant="outlined"
                    required
                    placeholder="Enter Your Company Name"
                    fullWidth
                />
                <InputLabel
                    htmlFor="phoneNum"
                    style={webStyle.inputLabel}
                >
                    {this.state.phoneNum} *
                </InputLabel>
                <Grid container spacing={2}>
                    <Grid item md={6} lg={6} sm={6}>
                        <Select
                            labelId="phoneNum"
                            displayEmpty
                            id="phoneNum"
                            variant="outlined"
                            fullWidth
                            style={webStyle.select}
                            value={""}
                        >
                            <MenuItem value="">
                                <em style={webStyle.countryPlaceHolder}>Enter Country Code</em>
                            </MenuItem>
                            <MenuItem value={91}>91</MenuItem>
                            <MenuItem value={20}>+80</MenuItem>
                            <MenuItem value={30}>+23</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} style={{ paddingTop: 3 }}>
                        <InputField
                            id="phoneNumber"
                            variant="outlined"
                            required
                            placeholder="Enter Your Phone Number"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <InputLabel
                    htmlFor="country"
                    style={webStyle.inputLabel}
                >
                    {this.state.country} *
                </InputLabel>
                <InputField
                    id="country"
                    variant="outlined"
                    required
                    placeholder="Enter Your Country Name"
                    fullWidth
                />
                <Grid container justifyContent="space-between">
                    <CheckBoxLabel
                        control={
                            <Checkbox
                                color="default"
                                disableRipple
                                checked={true}
                                // onChange={this.btnRememberMeProps.onPress}
                                checkedIcon={<span style={(webStyle.checkedIcon)} />}
                                icon={<span style={webStyle.icon} />}
                                inputProps={{ 'aria-label': 'decorative checkbox' }}
                            />
                        }
                        label={
                            <Typography style={webStyle.termsText}>{
                                this.state.privacyPolicyLabel}
                                <Typography component="span" style={webStyle.privacyText}>
                                    {" "}{this.state.privacyPolicyText}</Typography>
                                {" "} and
                                <Typography component="span" style={webStyle.privacyText}>
                                    {" "}{this.state.termslabelText}
                                </Typography>
                                <Typography component="span">{" "}{this.state.termsLabel}</Typography>
                            </Typography>
                        }
                    />
                </Grid>
            </>
        )
    }

    render() {
        return (
            <>
                <Grid container style={{ overflow: 'auto' }}>
                    <Grid item md={6} sm={6} xs={12}>
                        <img src={LoginImage} style={webStyle.loginImage} />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} style={webStyle.rightPart}>
                        <img src={BoiConLogo} height={60} width={'100%'} style={webStyle.LogoImage} />
                        <Typography style={webStyle.Header}>{this.state.labelTitle}</Typography>
                        <Typography style={webStyle.subHeader}>{this.state.SubHeading}</Typography>
                        {
                            this.state.IscompanyDetails ?
                                (
                                    <>
                                        {this.setCompanyDetails()}
                                    </>
                                )
                                : (
                                    <>
                                        <InputLabel
                                            htmlFor="firstName"
                                            style={webStyle.inputLabel}
                                        >
                                            {this.labelFirstName} *
                                        </InputLabel>
                                        <InputField
                                            id="firstName"
                                            variant="outlined"
                                            required
                                            placeholder="Enter Your First Name"
                                            fullWidth
                                        />

                                        <InputLabel
                                            htmlFor="lastName"
                                            style={webStyle.inputLabel}
                                        >
                                            {this.state.labelLastName} *
                                        </InputLabel>
                                        <InputField
                                            id="lastName"
                                            variant="outlined"
                                            required
                                            placeholder="Enter Your Last Name"
                                            fullWidth
                                        />

                                        <InputLabel
                                            htmlFor="emailInput"
                                            style={webStyle.inputLabel}
                                        >
                                            {this.state.labelEmail} *
                                        </InputLabel>
                                        <InputField
                                            id="emailInput"
                                            type="mail"
                                            variant="outlined"
                                            required
                                            placeholder="Enter Your Email Id"
                                            fullWidth
                                        />
                                        <InputLabel
                                            htmlFor="createPass"
                                            style={webStyle.inputLabel}
                                        >
                                            {this.state.labelPassword} *
                                        </InputLabel>
                                        <InputField
                                            id="createPass"
                                            variant="outlined"
                                            required
                                            placeholder="Create a stronge password"
                                            fullWidth
                                            helperText="Password must have 8 character, alphanumeric and must include special character"
                                            type={this.state.showPassword ? 'text' : 'password'}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={this.handleClickShowPassword}
                                                            edge="end"
                                                        >
                                                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                            }}
                                        />

                                        <InputLabel
                                            htmlFor="confirmPass"
                                            style={webStyle.inputLabel}
                                        >
                                            {this.state.confirmPass} *
                                        </InputLabel>
                                        <InputField
                                            id="confirmPass"
                                            variant="outlined"
                                            required
                                            type={this.state.confirmShowPassword ? 'text' : 'password'}
                                            placeholder="Confirm your password"
                                            fullWidth
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={this.handleConfirmShowPassword}
                                                            edge="end"
                                                        >
                                                            {this.state.confirmShowPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                            }}
                                        />

                                    </>
                                )
                        }
                        <Buttons
                            variant="contained"
                            onClick={this.gotoCompanyDetails}
                        >
                            {this.state.IscompanyDetails ? 'Sign Up' : 'Proceed'}
                        </Buttons>
                        <Grid container justifyContent="center">
                            <Typography style={webStyle.AccountText}>
                                {this.state.AccountLabel}
                            </Typography>
                            <Typography style={webStyle.login}>
                                {this.state.loginText}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    };
}

// Customizable Area Start
const webStyle = {
    loginImage: {
        height: '100%',
        width: '100%',
        '@media (maxWidth: 500px)': {
            height: '20%',
            width: '50px'
        },
    },
    rightPart: {
        paddingRight: '30px',
        paddingTop: '10px',
        paddingLeft: '25px',
    },
    LogoImage: {
        display: 'flex',
        width: '100px',
        paddingTop: '20px',
        paddingBottom: '15px',
    },
    Header: {
        fontStyle: 'normal',
        fontWeight: 400,
        color: 'rgba(4, 60, 116, 1)',
        fontFamily: 'SFProDisplay',
        fontSize: '30px',
        lineHeight: '36px',
        marginBottom: '5px'
    },
    subHeader: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontFamily: 'SFProDisplay',
        fontSize: '16px',
        lineHeight: '19px',
        color: 'rgba(106, 116, 165, 1)',
        marginBottom: '13px',
    },
    inputLabel: {
        fontSize: '18px',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        color: 'rgba(58, 63, 99, 1)',
        paddingBottom: '5px',
        paddingTop: '10px',
    },
    AccountText: {
        fontWeight: 400,
        color: 'rgba(58, 63, 99, 1)',
        fontFamily: 'SFProDisplay',
        fontSize: '18px',
        padding: '10px',
    },
    login: {
        fontWeight: 400,
        color: 'rgba(69, 158, 255, 1)',
        fontFamily: 'SFProDisplay',
        fontSize: '18px',
        padding: '10px',
    },
    countryPlaceHolder: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontFamily: 'SFProDisplay',
        fontSize: '16px',
        color: 'rgba(214, 214, 214, 1)',
    },
    select: {
        height: '52px',
        borderRadius: '8px',
        "& :focus": {
            background: 'white'
        }
    },
    checkedIcon: {
        borderRadius: 3,
        border: '1px solid black',
        width: 15,
        height: 15,
        backgroundColor: 'white',
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='black'/%3E%3C/svg%3E\")",
        'input:hover ~ &': {
        },
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: 3,
        width: 15,
        height: 15,
        backgroundColor: 'white',
        border: '1px solid black',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: 'white',
        },
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    termsText: {
        fontStyle: 'normal',
        fontWeight: 400,
        color: 'rgba(58, 63, 99, 1)',
        fontFamily: 'SFProDisplay',
        fontSize: '18px',
        paddingTop: '4px',
    },
    privacyText: {
        color: 'rgba(69, 158, 255, 1)',
    },
};

const InputField:any = withStyles({
    root: {
        background: "inherite",
        margin: '5px 0px 5px 0px',
        "& .MuiOutlinedInput-root": {
            fontWeight: 500,
            fontFamily: 'SFProDisplay',
            backgroundColor: 'rgba(255, 255, 255, 1)',
            fontSize: '16px',
            "& fieldset": {
                height: 55,
                borderColor: 'rgba(214, 214, 214, 1)',
                borderRadius: '8px',
                borderWidth: 1,
            },
        },
        "& .MuiFormHelperText-contained": {
            marginLeft: '0px !important'
        }
    }
})(TextField);

const Buttons:any = withStyles({
    root: {
        width: '100%',
        color: 'rgba(255, 255, 255, 1)',
        backgroundColor: 'rgba(69, 158, 255, 1)',
        opacity: 1,
        boxShadow: 'none',
        height: '56px',
        borderRadius: '5px',
        fontFamily: 'SFProDisplay',
        fontSize: '26px',
        fontStyle: 'normal',
        fontWeight: 700,
        marginTop: '10px',
        marginBottom: '5px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'rgba(69, 158, 255, 1)',
            boxShadow: 'none',
        },
    },
})(Button);

const CheckBoxLabel:any = withStyles({
    root: {
        alignItems: 'flex-start',
        "& .MuiTypography-body1": {
            fontFamily: 'SFProDisplay',
            fontWeight: 400,
            fontSize: '18px',
            color: 'rgba(58, 63, 99, 1)',
        },
    },
})(FormControlLabel);

