import React from "react";
// Customizable Area Start
import ProductListingController, { Props } from "./ProductListingController";
import {
    Typography,
    Box,
    Button,
    TextField,
    Dialog,
    DialogTitle,
    InputLabel,
    DialogContent,
    Grid,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import { CustomGrid } from "../../../components/src/common";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector";
import Loader from "../../../components/src/Loader.web";
//appfooter.web

// Customizable Area End

export default class HeaderTiles extends ProductListingController {
    constructor(props: Props) {
        super(props);
    }
    LoginFlashPopUp = () => {
        return (
            <LoginDialog
                data-testid='btnLoginDialog'
                open={this.state.isFlashLogin}
                onClose={() => { this.setState({ isFlashLogin: false }) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    <Typography style={webStyle.dialogTitle} align="center">
                        Please share the following details to proceed
                    </Typography>
                    <IconBox onClick={this.closeFlashLogin}>
                        <CloseIcon style={{ fill: '#1A3B71', cursor: 'hand' }}>
                        </CloseIcon>
                    </IconBox>
                </DialogTitle>
                <DialogContent>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            this.onSave();
                        }}
                    >
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputLabel htmlFor="name" style={webStyle.inputLabel}>
                                Your Name<span style={{ color: "red" }}>*</span>
                            </InputLabel>
                            <InputField
                                id="name"
                                variant="outlined"
                                required
                                placeholder="Enter your full name"
                                fullWidth
                                value={this.state.firstName}
                                onChange={this.handleChange("firstName").bind(this)}
                            />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputLabel htmlFor="name" style={webStyle.inputLabel}>
                                Company Name<span style={{ color: "red" }}>*</span>
                            </InputLabel>
                            <InputField
                                id="name"
                                variant="outlined"
                                required
                                placeholder="Enter your company name"
                                fullWidth
                                value={this.state.companyName}
                                onChange={this.handleChange("companyName").bind(this)}
                            />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputLabel htmlFor="name" style={webStyle.inputLabel}>
                                Email ID<span style={{ color: "red" }}>*</span>
                            </InputLabel>
                            <InputField
                                id="name"
                                variant="outlined"
                                required
                                placeholder="Enter your email Id"
                                fullWidth
                                value={this.state.email}
                                onChange={this.handleChange("email").bind(this)}
                            />
                            {
                                this.state.emailError && (
                                    <Typography style={webStyle.errortext}>
                                        Please enter valid email id
                                    </Typography>
                                )
                            }
                        </Grid>
                        <InputLabel htmlFor="phoneNo" style={webStyle.inputLabel}>
                            Mobile number<span style={webStyle.optionalLabel}>(Optional)</span>
                        </InputLabel>
                        <Grid container spacing={2}>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Box display="flex">
                                    <CustomGrid item md={12} lg={12} sm={12} xs={12} className={this.state.loading === true ? 'countryCodeIndex' : 'countryCodeIndex2'} style={{ marginTop: "5px" }}>
                                        <CountryCodeSelector
                                            navigation={""}
                                            style={{}}
                                            id="countryCode"
                                            disable={false}
                                            allowPropChange={true}
                                            onChange={this.handlePhoneCountryCode}
                                            value={this.state.phoneCountryCode}
                                            placeHolder="Country code"
                                        />
                                    </CustomGrid>
                                </Box>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <InputField
                                    id="phoneNo"
                                    variant="outlined"
                                    onChange={this.handleChange("phoneNumber").bind(this)}
                                    value={this.state.phoneNumber}
                                    placeholder="Enter Phone No"
                                    fullWidth
                                    inputMode="numeric"
                                    inputProps={{
                                        maxLength: 14,
                                    }}
                                    type="tel"
                                />
                            </Grid>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12}>
                            <Button
                                data-testid="btnProceed"
                                type="submit"
                                variant="contained"
                                fullWidth
                                style={{ ...webStyle.submitButton, textTransform: "none" }}
                            >
                                Proceed
                            </Button>
                        </Grid>
                    </form>
                </DialogContent>
            </LoginDialog>
        );
    }
    render() {
        return (
            <React.Fragment>
                <div className="headerTiles">
                    <p style={webStyle.tilesHeading}>
                        To download Biocon API Product Catalogue, please click here
                    </p>
                    <button
                        className="downloadButton"
                        onClick={this.downloadProduct}
                    >
                        Download
                    </button>
                </div>
                {
                    this.state.isFlashLogin && (
                        this.LoginFlashPopUp()
                    )
                }
                <Loader loading={this.state.loading} />
            </React.Fragment>
        );
    }
};

const webStyle = {
    tilesHeading: {
        margin: '7px 0px 7px 0',
        fontFamily: 'SFProDisplay',
        fontWeight: 700,
        fontSize: '14px',
        color: '#fff',
    },
    dialogTitle: {
        margin: '8px 2px 0px 0',
        fontFamily: 'SFProDisplay',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: 'normal',
        color: '#3a3f63',
        width: '100%'
    },
    inputLabel: {
        fontSize: "16px",
        fontFamily: "SFProDisplay",
        fontWeight: 500,
        color: "#3a3f63",
        margin: '13px 0px 12px 0px',
    },
    optionalLabel: {
        margin: '4px 0px 13px 4px',
        fontFamily: 'SFProDisplay',
        fontWeight: 500,
        fontSize: '14px',
        color: '#3a3f63',
    },
    errortext: {
        color: 'red',
        fontSize: '14px',
        fontFamily: 'SFProDisplay',
        fontWeight: 500,
    },
    submitButton: {
        margin: '24px 0px 0 0px',
        padding: '15px 102px 17px',
        borderRadius: '8px',
        backgroundColor: '#043c74',
        fontFamily: 'SFProDisplay',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: 2.29,
        color: '#fff',
        boxShadow: 'none',
        height: '45px'
    },
};

const LoginDialog : any = withStyles({
    root: {
        "& .MuiDialogTitle-root": {
            padding: '0px'
        },
        "& .MuiDialog-paperScrollPaper": {
            padding: '25px 25px',
            borderRadius: '12px',
            "@media (max-width: 720px)": {
                minWidth: 'auto !important',
              },
              "@media (max-width: 500px)": {
                margin: '15px !important',
                padding: '25px 15px !important',
              },
        },
        "& .MuiDialogContent-root": {
            // padding: '0px 90px 0px 85px',
            // textAlign: 'center',
            // paddingBottom: '10px'
            "& .css-156wexg-control": {
                minHeight: '38px',
                border: '1px solid #d6d6d6'
            },
            "& .css-1hb7zxy-IndicatorsContainer": {
                height: '38px'
            },
            "& .css-1wa3eu0-placeholder": {
                color: `#d6d6d6 !important`
            },
            "& .css-26l3qy-menu": {
                position: 'absolute',
                top: '-200%',
                zIndex: 1,
                transition: `all 0.2s`,
            },
            "& .css-1iven0l-control": {
                minHeight: '38px',
            }
        },
        "& .MuiDialog-paperWidthSm": {
            minWidth: '605px',
        },
        "& .MuiDialogActions-root": {
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0px',
            paddingBottom: '30px',
        },
        "& .WithStyles\(ForwardRef\(Button\)\)-root-10": {
            "@media (maxWidth: 600px)": {
                width: 600
            }
        },
        "& .MuiTypography-h6": {
            display: 'flex',
            justifyContent: 'space-between',
        },
    }
})(Dialog);

const IconBox : any = withStyles({
    root: {
        background: '#DAE0EC',
        height: '26px',
        width: '24px',
        borderRadius: '50%',
        float: 'right',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 2px 0px 2px',
        marginTop: '-15px',
        "& .MuiSvgIcon-root": {
            height: '18px',
            width: '18px'
        },
    }
})(Box);

const InputField: any = withStyles({
    root: {
        background: "inherite",
        margin: "5px 0px 0px 0px",
        "& ::placeholder": {
            color: '#d6d6d6',
            fontFamily: 'SFProDisplay',
            fontWeight: 500,
            fontSize: '14px',
            opacity: 1,
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#d6d6d6",
        },
        "& .MuiInputBase-input": {
            height: "19px",
        },
        "& .MuiOutlinedInput-input": {
            padding: "11px 14px",
        },
        "& .MuiOutlinedInput-root": {
            fontFamily: "SFProDisplay",
            fontWeight: 500,
            backgroundColor: "rgba(255, 255, 255, 1)",
            fontSize: "14px",
            "& fieldset": {
                height: "42px",
                borderColor: "#d6d6d6",
                borderRadius: "8px",
                borderWidth: 1,
            },
            ".Mui-focused": {
                borderColor: "#d6d6d6",
            },
        },
        "& .MuiFormHelperText-contained": {
            marginLeft: "0px !important",
        },
        "& .MuiInputBase-input.Mui-disabled": {
            background: "#F1F4FA",
            borderRadius: "8px",
            height: 13,
            color: "rgba(106, 116, 165, 1)",
            fontSize: "16px",
            fontFamily: "SFProDisplay",
            fontWeight: 500,
        },
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(218, 224, 241, 1)",
            borderWidth: "1px",
        },
    },
})(TextField);



