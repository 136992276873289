import React, { useEffect } from "react";
import Autocomplete , { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { withStyles } from "@material-ui/core/styles";

interface ICompanyNameSelector {
    dataSource: any[];
    selectedValue: any;
    handleChange: (event: any, value: any) => void;
    style: any;
    placeHolder: string;
    isDisabled: boolean;
    isClearable?: boolean;
    onFocus?: () => void;
    isCustomFilter?: boolean;
}

const filterOptions = createFilterOptions({});

const CompanyNameSelector = ({ dataSource, selectedValue, handleChange, isDisabled }:
    ICompanyNameSelector) => {
    const [selected, setSelected] = React.useState<any>(0);
    const [, setOptions] = React.useState<any[]>([]);
    const [selectValue, setSelectValue] = React.useState<any>(null);
    const [openAutocomplete, setOpenAutocomplete] = React.useState(false);
    const [inputValue, setInputValue] = React.useState("");
    
    useEffect(() => {
        const selectedObj = dataSource.find((company: any) => String(company.id) === String(selectedValue));
        setSelected(selected);
        setOptions(dataSource);
        setSelectValue(selectedObj);
    }, [dataSource, selectedValue]);

    const handleOpen = () => {
        if (inputValue.length > 4) {
            setOpenAutocomplete(true);
        }
    };
    const url = window.location.href;
    const arrayId: any = url.split('/');
    const id= arrayId;
    const handleInputChange = (event: any, newInputValue: any) => {
        setInputValue(newInputValue);
        handleChange({ id: 0, label: newInputValue }, newInputValue);
        if (id ? inputValue.length >= 3 : newInputValue.length >= 4) {
            setOpenAutocomplete(true)
        } else if (selectedValue != 0) {
            setOpenAutocomplete(false);
        } else {
            setOpenAutocomplete(false);
        }
    };

    const setChangesData = (value: any) => {
        if (value) {
            handleChange(value ?? { id: value.id, label: value }, value);
            setSelectValue(value ?? { id: 0, label: "" })
            setOpenAutocomplete(false)
            setSelected(value ?? { id: value.id, label: value })
        } else {
            setSelectValue({ id: 0, label: "" })
            setOpenAutocomplete(false)
            handleChange({ id: value.id, label: value }, value);
            setSelected(value ?? { id: value.id, label: value })
        }
    }

    return (
        <>
            <CustomAutocomplete
                id="combo-box-demo"
                data-testid="companyautoselector"
                options={dataSource.filter((item) => {
                    if (inputValue === "") {
                        return item;
                    }
                    else if (item.label.toLowerCase().includes(inputValue.toLowerCase())) {
                        return item;
                    }
                })}
                getOptionLabel={(option: any) => option.label || ""}
                fullWidth
                freeSolo
                disabled={isDisabled}
                value={selectValue}
                autoComplete={true}
                onChange={(event: any, value: any) => {
                    setChangesData(value)
                }}
                filterOptions={filterOptions}
                renderInput={(params: any) => {
                    return (<TextField {...params} placeholder="Select Company" variant="outlined" size="small" />)
                }}
                open={openAutocomplete}
                onOpen={handleOpen}
                onClose={() => setOpenAutocomplete(false)}
                inputValue={inputValue}
                onInputChange={handleInputChange}
            />
        </>
    )
}

const CustomAutocomplete: any = withStyles({
    root: {
        backgroundColor: 'inherit',
        "& .MuiInputBase-root": {
            "&.Mui-disabled": {
                backgroundColor: '#F1F4FA',
                color: 'rgba(106, 116, 165, 1)',
                fontSize: '14px'
            }
        },
        "& .MuiAutocomplete-endAdornment": {
            display: "none",
        },
        "& .MuiAutocomplete-inputRoot": {
            borderRadius: "8px"
        }
    }
})(Autocomplete);

export default CompanyNameSelector