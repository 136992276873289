import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {
    apiCall,
    authorizeEmployee,
    getHeaders,
    isAuthorisedUser,
    isLoggedIn,
    IUserdetails,
    showApiErrorResponse,
    successNotification,
    warningNotification,
    events,
    autoLogOut,
    errorNotification,
    getUserdetails,
} from "../../../components/src/common";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

export interface ICompaniesGet {
    id: number;
    customer_name: string;
}
interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    parentCustomerLabel: string;
    selectedTab: number;
    alternateEmailLabel: string;
    departmentList: any[];
    firstName: string;
    lastName: string;
    companyName: string;
    emailId: string;
    phoneNumber: string;
    phoneCountryCode: string;
    agreedTermsAndCondition: boolean;
    address: string;
    department: number;
    designation: string;
    landlineNumber: string;
    areaOfBusiness: string;
    subsidiaryName: string;
    alternateEmail: string;
    isTermsCondition: boolean;
    isLoading: boolean;
    privacyPolicyText: string;
    termslabelText: string;
    termsLabel: string;
    role_id: number;
    created_at: any;
    updated_at: any;
    email: string;
    lastNameLabel: string;
    customerCompanyLabel: string;
    emailLabel: string;
    countryCodeLabel: string;
    phoneNumLabel: string;
    additionalDetailLabel: string;
    addressLabel: string;
    designationLabel: string;
    departmentLabel: string;
    landlinelabel: string;
    contactUniqueLabel: string;
    comments: string;
    allSalutations: any[];
    country: string;
    selectedSolutation: number;
    allCompanies: ICompaniesGet[];
    selectedCompany: number;
    selected_company_id: number;
    selectedCompanyName: string;
    contactUniqueId: string;
    password: string;
    showPassword: boolean;
    isOnlyView: boolean;
    isOnlyAdditionalView: boolean;
    activated: any;
    isPopup: any;
    companyIsFocued: boolean;
    isEditDetails: boolean;
    isInvoiceEdit: any;
    allcontectInvioce: any[];
    contactInvoiceListingMeta: any;
    page: any;
    perPageRecord: any;
    searchInvoiceText: any;
    isViewInvoice: boolean;
    chooseDepartmentText: string;
    noDataText: string;
    chooseSolutionText: string;
    firstNamePlaceholder: string;
    lastNamePlaceholder: string;
    emailIdPlaceholder: string;
    countryCodePlaceholder: string;
    phoneNumberPlaceholder: string;
    countryNamePlaceholder: string;
    completeaddressPlaceholder: string;
    designationPlaceholder: string;
    landlineNumberPlaceholder: string;
    alternateEmailIdPlaceholder: string;
    uniqueIdPlaceholder: string;
    saveButtonText: string;
    searchInvoicePlaceholder: string;
    solutationText: string;
    countryLabelText: string;
    noInvoiceData: string;
    addNewButtonText: string;
    resetPasswordBtnText: string;
    activeOptionText: string;
    InactiveOptionText: string;
    invoicesTabLabel: string;
    userDetailsTabPanel: string;
    tabsLabelText: string;
    searchFolderIdText: string;
    contactUniqueLabelIdText: string;
    alternateEmailLabelIdText: string;
    landlinelabelIdText: string;
    designationLabelIdText: string;
    chooseCompanyPlaceholder: string;
    isResetPasswordPermission: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CustomerContactAddController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    sendResetPasswordEmailAPICallId: any;
    updateAccountApiCallId: any;
    updateCustomerProfileApiCallId: any;
    getCustomerPersonalApiCallId: any;
    getCustomerBAMApiCallId: any;
    getAllListApiCallId: any;
    userDetails: IUserdetails;
    authToken: string;
    getAllSolutationsApiCallId: string;
    getDepartmentListApiCallId: string;
    getCompaniesListApiCallId: string;
    saveUserDetailsApiCallId: string;
    getAllContectInvioceApiCallId: any;
    isUpdate: boolean;
    userId: number;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.onSaveUserDetails = this.onSaveUserDetails.bind(this);
        this.validateUserDetail = this.validateUserDetail.bind(this);
        this.setInvoicesData = this.setInvoicesData.bind(this);
        this.setCompaniesData = this.setCompaniesData.bind(this);
        this.setCustomerPersonalData = this.setCustomerPersonalData.bind(this);
        this.setDeparmentList = this.setDeparmentList.bind(this);
        this.setInvoicesData = this.setInvoicesData.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            parentCustomerLabel: configJSON.parentCustomerLabel,
            privacyPolicyText: configJSON.privacyPolicyText,
            termslabelText: configJSON.termslabelText,
            termsLabel: configJSON.termsLabel,
            selectedTab: 0,
            alternateEmailLabel: configJSON.alternateEmailLabel,
            phoneNumber: "",
            agreedTermsAndCondition: true,
            address: "",
            department: 0,
            designation: "",
            landlineNumber: "",
            areaOfBusiness: "",
            subsidiaryName: "",
            alternateEmail: "",
            selectedSolutation: 0,
            isTermsCondition: true,
            isLoading: false,
            role_id: 0,
            departmentList: [],
            firstName: "",
            lastName: "",
            companyName: "",
            emailId: "",
            country: "India",
            phoneCountryCode: "91",
            created_at: '',
            updated_at: '',
            email: '',
            lastNameLabel: configJSON.lastNameLabel,
            customerCompanyLabel: configJSON.customerCompanyLabel,
            emailLabel: configJSON.emailLabel,
            countryCodeLabel: configJSON.countryCodeLabel,
            phoneNumLabel: configJSON.phoneNumLabel,
            additionalDetailLabel: configJSON.additionalDetailLabel,
            addressLabel: configJSON.addressLabel,
            designationLabel: configJSON.designationLabel,
            departmentLabel: configJSON.departmentLabel,
            landlinelabel: configJSON.landlinelabel,
            contactUniqueLabel: configJSON.contactUniqueLabel,
            comments: '',
            allSalutations: [],
            allCompanies: [],
            selectedCompany: 0,
            selected_company_id: 0,
            selectedCompanyName: "",
            contactUniqueId: "",
            password: "",
            showPassword: false,
            isOnlyView: false,
            isOnlyAdditionalView: true,
            activated: false,
            isPopup: false,
            companyIsFocued: false,
            isEditDetails: false,
            isInvoiceEdit: false,
            allcontectInvioce: [],
            contactInvoiceListingMeta: null,
            page: 1,
            perPageRecord: 20,
            searchInvoiceText: '',
            isViewInvoice: true,
            chooseDepartmentText: configJSON.chooseDepartmentText,
            noDataText: configJSON.noDataText,
            chooseSolutionText: configJSON.chooseSolutionText,
            firstNamePlaceholder: configJSON.firstNamePlaceholder,
            lastNamePlaceholder: configJSON.lastNamePlaceholder,
            emailIdPlaceholder: configJSON.emailIdPlaceholder,
            countryCodePlaceholder: configJSON.countryCodePlaceholder,
            phoneNumberPlaceholder: configJSON.phoneNumberPlaceholder,
            countryNamePlaceholder: configJSON.countryNamePlaceholder,
            completeaddressPlaceholder: configJSON.completeaddressPlaceholder,
            designationPlaceholder: configJSON.designationPlaceholder,
            landlineNumberPlaceholder: configJSON.landlineNumberPlaceholder,
            alternateEmailIdPlaceholder: configJSON.alternateEmailIdPlaceholder,
            uniqueIdPlaceholder: configJSON.uniqueIdPlaceholder,
            saveButtonText: configJSON.saveButtonText,
            searchInvoicePlaceholder: configJSON.searchInvoicePlaceholder,
            solutationText: configJSON.solutationText,
            countryLabelText: configJSON.countryLabelText,
            noInvoiceData: configJSON.noInvoiceData,
            addNewButtonText: configJSON.addNewButtonText,
            resetPasswordBtnText: configJSON.resetPasswordBtnText,
            activeOptionText: configJSON.activeOptionText,
            InactiveOptionText: configJSON.InactiveOptionText,
            invoicesTabLabel: configJSON.invoicesTabLabel,
            userDetailsTabPanel: configJSON.userDetailsTabPanel,
            tabsLabelText: configJSON.tabsLabelText,
            searchFolderIdText: configJSON.searchFolderIdText,
            contactUniqueLabelIdText: configJSON.contactUniqueLabelIdText,
            alternateEmailLabelIdText: configJSON.alternateEmailLabelIdText,
            landlinelabelIdText: configJSON.landlinelabelIdText,
            designationLabelIdText: configJSON.designationLabelIdText,
            chooseCompanyPlaceholder: configJSON.chooseCompanyPlaceholder,
            isResetPasswordPermission: false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.handlePhoneCountryCode = this.handlePhoneCountryCode.bind(this);
        this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
            let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
            this.showAlert(
                "From: " + this.state.txtSavedValue + " To: " + value,
                "Change Value",
            );
            this.setState({ txtSavedValue: value });
        }

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if (apiRequestCallId && responseJson) {
                this.setState({isLoading: false});
                isAuthorisedUser(responseJson, this.props.navigation);
                this.getAllListDataAPICalls(apiRequestCallId, responseJson)
                this.customerAPICalls(apiRequestCallId, responseJson)
            }
        }
        // Customizable Area End
    }

    txtInputWebProps = {
        onChangeText: (text: string) => { this.setState({ txtInputValue: text }) },
        secureTextEntry: false,
    };

    txtInputMobileProps = {
        ...this.txtInputWebProps,
        autoCompleteType: "email",
        keyboardType: "email-address",
    };

    txtInputProps = this.isPlatformWeb() ? this.txtInputWebProps : this.txtInputMobileProps;

    btnShowHideProps = {
        onPress: () => {
            this.setState({ enableField: !this.state.enableField });
            this.txtInputProps.secureTextEntry = !this.state.enableField;
            this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible;
        },
    };

    btnShowHideImageProps = {
        source: this.txtInputProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible,
    };

    btnExampleProps = { onPress: () => this.doButtonPressed() };

    doButtonPressed() {
        let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
        msg.addData(getName(MessageEnum.AuthTokenDataMessage), this.state.txtInputValue);
        this.send(msg);
    }

    setUserDetails = (userDetails: any) => {
        this.setState({
            selectedSolutation: userDetails.salutation_id,
            firstName: userDetails.first_name,
            lastName: userDetails.last_name,
            selectedCompany: userDetails.company_id,
            selected_company_id: userDetails.selected_company_id,
            selectedCompanyName: userDetails.company_name,
            email: userDetails.email || "",
            phoneCountryCode: String(userDetails.country_code) || "91",
            country: userDetails.country || "India",
            phoneNumber: userDetails.phone_number,
            address: userDetails.address,
            department: userDetails.department_id,
            designation: userDetails.designation || "",
            landlineNumber: userDetails.landline || "",
            alternateEmail: userDetails.alternate_email || "",
            contactUniqueId: userDetails.contact_unique_id,
            password: userDetails.password || "",
            activated: userDetails.is_activated || false,
        })
    }

    // web events
    setInputValue = (text: string) => {
        this.setState({ txtInputValue: text });
    };

    setEnableField = () => {
        this.setState({ enableField: !this.state.enableField });
    };

    handleClickShowPassword = () => {
        this.setState({
            showPassword: !this.state.showPassword,
        });
    };

    // Customizable Area Start
    customerAPICalls = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.saveUserDetailsApiCallId) {
            this.setSaveUserDetailAPIRes(responseJson)
        }

        if (apiRequestCallId === this.getAllContectInvioceApiCallId) {
            this.setInvoicesData(responseJson)
        }

        if (apiRequestCallId === this.getCustomerPersonalApiCallId) {
            this.setCustomerPersonalData(responseJson)
        }

        if (apiRequestCallId === this.sendResetPasswordEmailAPICallId) {
            if (!responseJson.errors) {
                successNotification(responseJson.message)
                this.setState({isLoading: false})
            } else {
                errorNotification(responseJson?.errors[0])
                this.setState({isLoading: false})
            }
        }
    }

    getAllListDataAPICalls = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.getDepartmentListApiCallId) {
            this.setDeparmentList(responseJson)
        }

        if (apiRequestCallId === this.getCompaniesListApiCallId) {
            if (!responseJson.error && !responseJson.errors) {
               this.setCompaniesData(responseJson)
            }
        }

        if (apiRequestCallId === this.getAllSolutationsApiCallId) {
            this.setSolutationsData(responseJson);
        }
    }

    setCustomerPersonalData = (responseJson: any) => {
        if (!responseJson.error && !responseJson.errors) {
            const userDetails = responseJson.data.attributes;
            this.setUserDetails(userDetails);
        }
    }

    setInvoicesData = (responseJson: any) => {
        this.setState({ isLoading: false })
        if (responseJson.message === configJSON.noInvoiceData) {
            this.setState({allcontectInvioce: [], contactInvoiceListingMeta: []})
        } else if (!responseJson.error && !responseJson.errors) {
            this.setState({allcontectInvioce: responseJson.result.data,contactInvoiceListingMeta: responseJson.result.meta,})
        }
    }

    setSaveUserDetailAPIRes = (responseJson: any) => {
        if (!responseJson.error && !responseJson.errors) {
            if (this.isUpdate) {
                successNotification(configJSON.contactUpdateSuccessMsg);
            } else {
                successNotification(configJSON.contactAddedSuccessMsg);
            }
            this.getPersonalDetails();
        } else {
            showApiErrorResponse(responseJson)
        }
    }

    setSolutationsData = (responseJson: any) => {
        if (!responseJson.error && !responseJson.errors) {
            this.setState({allSalutations: responseJson.all_salutation})
        }
    }

    setDeparmentList = (responseJson: any) => {
        if (!responseJson.error && !responseJson.errors) {
            this.setState({departmentList: responseJson.department})
        }
    }

    setCompaniesData= (responseJson: any) => {
        const companies = responseJson.all_companies.map((company: any) => {
            return {
                id: company.id,
                label: company.customer_name
            }
        });
        this.setState({allCompanies: companies});
    }

    async componentDidMount() {
        Object.values(events).forEach((item) => {
            window.addEventListener(item, () => {
                if (isLoggedIn(this.props.navigation)) {
                    autoLogOut();
                }
            });
        });
        const FromTicket = localStorage.getItem("FromTicket");
        const authToken = isLoggedIn(this.props.navigation);
        authorizeEmployee(this.props.navigation);
        if (typeof (authToken) === "string") {
            this.authToken = authToken;
            const userId = this.props.navigation.getParam("id");
            if (FromTicket === 'menus') {
                const userInfo = JSON.parse(localStorage.getItem("contact_master_permission") || "");
                if (userInfo?.can_update) {
                    this.setisEditValue(true)
                    this.setViewInvoice(true)
                } else {
                    this.setisEditValue(false)
                    this.setViewInvoice(true)
                }
            }
            if (FromTicket === 'ticketDetail') {
                this.setisEditValue(true)
                this.setViewInvoice(true)
            }
            if (FromTicket === "signUpRequest") {
                this.setisEditValue(true)
                this.setViewInvoice(false)
            }
            this.getAllCompaniesList();
            if (userId) {
                this.getDataForUser(userId)
            }
            this.setResetPasswordcondition();
            this.getDepartmentList();
            this.getAllSalutations();
        }
    }

    getDataForUser = (userId: any) => {
        this.userId = userId;
        const isUpdate = location.href.includes("edit");
        this.getPersonalDetails();
        this.getAllContactInvioce();
        if (isUpdate) {
            this.isUpdate = true;
        } else {
            this.isUpdate = false;
        }
    }

    setViewInvoice = (value: any) => {
        this.setState({isViewInvoice: value})
    }

    setisEditValue = (value: any) => {
        this.setState({ isOnlyView: value })
    }

    handleChangeTab = (event: any, newValue: any) => { this.setState({ selectedTab: newValue }) };

    handleChange = (prop: any) => (event: any) => {
        if (prop === 'firstName' || prop === 'lastName') {
            const regExForName = /^[a-zA-Z][a-z\s]*$/;
            if (event.target.value.length === 0) {
                this.setState({ ...this.state, [prop]: event.target.value });
                return true;
            }
            if (event.target.value.match(regExForName)) {
                this.setState({ ...this.state, [prop]: event.target.value });
                return true;
            }
            else {
                return false;
            }
        } else if (prop === 'phoneNumber' || prop === "landlineNumber") {
            const regExpPhnNo = /^[0-9\b]+$/;
            if (event.target.value.length === 0) {
                this.setState({ ...this.state, [prop]: event.target.value });
                return true;
            }
            if (event.target.value.match(regExpPhnNo)) {
                this.setState({ ...this.state, [prop]: event.target.value });
                return true;
            } else {
                return false;
            }
        } else {
            this.setState({ ...this.state, [prop]: event.target.value });
        }
    };

    returnBoolenValue = (condition: any) => {
        if (condition) {
            return true;
        } else {
            return false
        }
    }

    getPersonalDetails = () => {
        this.setState({
            isLoading: true
        });
        const header = getHeaders({ token: this.authToken });
        let url = configJSON.getPersonalDetailsApiEndPoint + `${this.userId}/show_user_detail`;
        const getAccount = apiCall({
            httpBody: {},
            header: header,
            url: url,
            httpMethod: configJSON.getCustomerProfileApiMethod,
        });
        this.getCustomerPersonalApiCallId = getAccount.messageId;
        runEngine.sendMessage(getAccount.id, getAccount);
    };

    isStringNullOrBlank(str: string) { return str === null || str.trim().length === 0; }

    handlePhoneCountryCode(event: any, value: any, data: any) {
        const filtered = data.find((country: any) => country.id === event.value)
        if (value) {
            this.setState({ phoneCountryCode: event.value, country: filtered.value });
        }
    }

    handleDepartmentChange = (event: any, value: any) => {
        this.setState({ department: value.props.value, });
    };

    handleCompanyChange = (event: any, value: any) => {
        this.setState({
            selectedCompany: event.id !== 0 ? event.id : this.state.selectedCompany,
            selected_company_id: event.id !== 0 ? event.id : this.state.selected_company_id,
            selectedCompanyName: event.label !== "" ? event.label : this.state.selectedCompanyName
        });
    };

    handleSwitchClick = () => {
        this.setState({ isPopup: true })
    }

    handleActiveYesClick = () => {
        this.setState({ activated: true, isPopup: false })
        this.onSaveUserDetails(true)
    }

    handleActiveNoClick = () => {
        this.setState({ activated: false, isPopup: false })
        this.onSaveUserDetails(false)
    }

    handleDeactiveYesClick = () => {
        this.setState({ activated: true, isPopup: false })
    }

    handleDeactiveNoClick = () => {
        this.setState({ activated: false, isPopup: false })
    }

    handleCloseIcon = () => {
        this.setState({ isPopup: false })
    }

    getAllSalutations = () => {
        const allSalutations = apiCall({
            httpBody: {},
            header: getHeaders({ token: this.authToken }),
            url: configJSON.getAllSalutationsApiEndpoint,
            httpMethod: configJSON.getAllSalutationsApiMethod,
        });

        this.getAllSolutationsApiCallId = allSalutations.messageId;
        runEngine.sendMessage(allSalutations.id, allSalutations);
    }

    getDepartmentList = () => {
        const header = getHeaders({ token: this.authToken });

        const getDepartments = apiCall({
            httpBody: {},
            header: header,
            url: configJSON.getDepartsmentListApiEndpoint,
            httpMethod: configJSON.getDepartmentListApiMethod,
        });

        this.getDepartmentListApiCallId = getDepartments.messageId;
        runEngine.sendMessage(getDepartments.id, getDepartments);
    };


    getAllCompaniesList = () => {
        const header = getHeaders({ token: this.authToken });

        const getCompanies = apiCall({
            httpBody: {},
            header: header,
            url: configJSON.getAllCompaniesApiEndpoint,
            httpMethod: configJSON.getAllCompaniesApiMethod,
        });

        this.getCompaniesListApiCallId = getCompanies.messageId;
        runEngine.sendMessage(getCompanies.id, getCompanies);
    };

    validateUserDetail = () => {
        if (this.state.selectedSolutation === 0) {
            warningNotification(configJSON.solutationError);
            return false;
        }

        if (this.state.selected_company_id === 0) {
            warningNotification(configJSON.companyError);
            return false;
        }
        
        if (!RegExp(configJSON.EmailregexExp).exec(String(this.state.email).toLowerCase())) {
            warningNotification(configJSON.emailError);
            return false;
        }

        if (this.state.alternateEmail.trim().length > 0 && !RegExp(configJSON.EmailregexExp).exec(String(this.state.alternateEmail).toLowerCase())) {
            warningNotification(configJSON.alternetEmailError);
            return false;
        }

        return true;
    }

    onSaveUserDetails = (switchStatus: any) => {
        const isValid = this.validateUserDetail();
        if (!isValid) {
            return false;
        }
        this.setState({
            isLoading: true,
            isOnlyView: true,
            isOnlyAdditionalView: true
        });
        const httpBody = {
            salutation_id: this.state.selectedSolutation,
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            personal_detail_id: this.state.selectedCompany,
            email: this.state.email,
            country_code: this.state.phoneCountryCode === "null" ? "" : this.state.phoneCountryCode,
            country: this.state.country,
            phone_number: this.state.phoneNumber,
            password: this.state.password,
            is_activated: switchStatus,
            customer_name: this.state.selectedCompanyName,
            customer_profile_attributes: {
                address: this.state.address,
                department_id: this.state.department,
                designation: this.state.designation,
                landline: this.state.landlineNumber,
                alternate_email: this.state.alternateEmail
            }
        }

        let url = configJSON.saveContactMasterDetailApiEndpoint;
        if (this.isUpdate && this.userId) {
            url = url + `?user_detail_id=${this.userId}`;
        }
        const saveUserDetails = apiCall({
            httpMethod: configJSON.saveContactMasterDetailApiMethod,
            httpBody: httpBody,
            url: url,
            header: getHeaders({ token: this.authToken })
        })

        this.saveUserDetailsApiCallId = saveUserDetails.messageId;
        runEngine.sendMessage(saveUserDetails.id, saveUserDetails);
    }


    handleSolutationChange = (event: any, value: any) => {
        this.setState({
            selectedSolutation: value.props.value
        })
    }

    getDisabled = (val: any) => {
        if (val) return { disabled: true };
        return {};
    }

    handleEditUserDetails = () => {
        this.setState({ isOnlyView: false })
    }

    handleAdditionalEditUserDetails = () => {
        this.setState({ isOnlyAdditionalView: false })
    }

    updateOthersValue = (value: string) => {
        this.setState({
            selectedCompanyName: value
        });
    };

    handleCompanySelect = () => {
        this.setState({ companyIsFocued: true })
    }

    handleInvoiceAddNewClickCustomer = () => {
        this.setState({
            isInvoiceEdit: true
        })
        if (this.props.navigation) {
            this.props.navigation.navigate("AddContactInvoice", { id: this.userId })
        }
    }

    getAllContactInvioce = () => {
        this.setState({ isLoading: true })
        const CustomerId: any = this.props.navigation.getParam('id')
        const allInvoices = apiCall({
            header: getHeaders({ token: this.authToken }),
            url: configJSON.getAllContectInvioceApiEndPoint + `?account_id=${CustomerId}&page=${this.state.page}&per_page=${this.state.perPageRecord}&query=${this.state.searchInvoiceText}`,
            httpBody: {},
            httpMethod: configJSON.getAllContectInvioceApiMethod
        });
        this.getAllContectInvioceApiCallId = allInvoices.messageId;
        runEngine.sendMessage(allInvoices.id, allInvoices);
    }

    handlePageChange = (e: any, p: any) => {
        this.setState({ page: p }, () => {
            this.getAllContactInvioce();
        });
    };

    debounce = (func: any, wait: number = 700) => {
        let timer: any;
        return (...args: any[]) => {
            clearTimeout(timer);
            timer = setTimeout(() => func(...args), wait);
        };
    };

    debouncedCallback = this.debounce(() => this.duplicateCode(), 700);

    handleSearchInvoices = (event: any) => {
        if (event.target.value.length > 0) {
            this.setState({
                ...this.state,
                searchInvoiceText: event.target.value,
                page: 1
            }, this.debouncedCallback)
        } else {
            this.setState({ page: 1 }, () => {
                this.getAllContactInvioce();
            });
            this.setState({
                searchInvoiceText: ''
            })
        }
    }

    duplicateCode = () => {
        this.getAllContactInvioce();
    };

    sendResetPasswordLink = () => {
        this.setState({ isLoading: true })
        const CustomerId: any = this.props.navigation.getParam('id')
        const allInvoices = apiCall({
            header: getHeaders({ token: this.authToken }),
            url: configJSON.sendResetPasswordEmailEndPoint,
            httpBody: {
               "account_id": CustomerId
            },
            httpMethod: configJSON.saveInvoiceApiMethod
        });
        this.sendResetPasswordEmailAPICallId = allInvoices.messageId;
        runEngine.sendMessage(allInvoices.id, allInvoices);
    }

    setResetPasswordcondition = () => {
        let userDetails = getUserdetails();
        console.log(userDetails, 'userDetails')
        if(userDetails && (userDetails.attributes?.role === configJSON.CustomerExcellenceText || userDetails.attributes?.profile === configJSON.CustomerExcellenceText || userDetails.attributes?.role === configJSON.ITText || userDetails.attributes?.profile === configJSON.ITText)) {
            this.setState({
                isResetPasswordPermission: true
            })
        } else {
            this.setState({
                isResetPasswordPermission: false
            })
        }
        
    }
    // Customizable Area End
}
