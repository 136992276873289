import React from "react";
// Customizable Area Start
import { SafeAreaView } from "react-native";
import { Typography } from "@material-ui/core";
import { Banners } from "./Banners.web";
import NewUpdates from "./NewUpdates.web";
import KnowOurTeam from "./KnowOurTeam";
import AppFooter from "../../../components/src/AppFooter.web";
import { NewAppHeader } from "../../../components/src/NewAppHeader.web";
import CustomSearch from "../../../components/src/CustomSearch.web";
import LandingPageController, { Props } from "./LandingPageController";
import Products from "./products.web";
import NewUpdatesSearch from "./NewUpdatesSearch.web";
import Loader from "../../../components/src/Loader.web";

// Customizable Area End

export default class LandingPage2 extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <SafeAreaView>
        <NewAppHeader />
        <Loader loading={this.state.loading} data-testid='loader' />
        <Banners
          banners={this.state.banners}
          showFullVideo={this.showFullVideo}
        />
        <CustomSearch data-testid='CustomSearch' searchByProductName={(e: any) => this.handleSearch(e)} />

        {this.state.isSeach ? (
          this.state.isSearchError ? (
            <Typography style={webStyle.NODataText}>
              Search results are not possible at the moment. Please try again
            </Typography>
          ) : this.state.isLoading ? (
            <Typography style={webStyle.NODataText}>Loading...</Typography>
          ) : (
            <React.Fragment>
              {" "}
              {this.state.products && this.state.products.length > 0 ? (
                <Products
                  handleKnowMore={this.handleKnowMoreClick}
                  isProductViewAll={this.state.productsViewAll}
                  setIsViewAll={this.setProductIsViewAll}
                  products={this.state.products}
                  searchTerm={this.state.searchTerm}
                />
              ) : (
                <Typography style={webStyle.NODataText}>
                  No Products Found
                </Typography>
              )}
              {this.state.searchNewUpdates &&
                this.state.searchNewUpdates.length > 0 ? (
                <NewUpdatesSearch
                  setIsViewAll={this.setIsViewAll}
                  IsViewAll={this.state.IsViewAll}
                  searchNewUpdates={this.state.searchNewUpdates}
                  searchTerm={this.state.searchTerm}
                />
              ) : (
                <Typography style={webStyle.NODataText}>
                  
                </Typography>
              )}
            </React.Fragment>
          )
        ) : (
          <React.Fragment>
            <NewUpdates navigation={undefined} id={"6"} />
          </React.Fragment>
        )}
        <KnowOurTeam searchTerm={this.state.searchTerm} teams={this.state.teamDetails} />
        <AppFooter isLoading={this.state.isLoading} />
      </SafeAreaView>
    );
  }
}

const webStyle = {
  NODataText: {
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    fontSize: "20px",
    padding: "20px 50px 20px 75px"
  }
};
