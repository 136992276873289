import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  InputLabel,
  Typography,
  Tab,
  Tabs,
  Grid,
  Checkbox,
  TextField,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import {
  withStyles,
} from "@material-ui/core/styles";
import { AppHeader } from "../../../components/src/AppHeader.web";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

const CustomTabPanel = (props: {
  [x: string]: any;
  children: any;
  value: any;
  index: any;
}) => {
  const { children, value, classes, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ px: 3 }} className={classes?.container} >{children}</Box>}
    </div>
  );
}
// Customizable Area End

import CustomisableUserProfilesControllerWeb, {
  Props,
} from "./CustomerUserProfileController.web";
import { CustomGrid, handlePrivacyPolicyRedirection, handleTermsOfUseRedirection, logout } from "../../../components/src/common";
import AppFooter from "../../../components/src/AppFooter.web";
import Loader from "../../../components/src/Loader.web";

export class CustomerUserProfileUpdate extends CustomisableUserProfilesControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  perSonalDetails() {
    const { classes } = this.props
    return (
      <>
        <Loader loading={this.state.isLoading} />
        <Grid container spacing={7}>
          <Grid item lg={6} md={6} sm={6} xs={12} style={{...webStyle.grids, paddingBottom: '5px'}}>
            <InputLabel htmlFor="firstName" style={webStyle.inputLabel}>
              {this.state.labelFirstName}<span style={{ color: "red" }}>*</span>
            </InputLabel>
            <div style={{display: 'flex'}}>
              <SelectField style={{marginRight: '15px'}}>
              <CustomSelect
                labelId="departmentLabel"
                id="departmentLabel"
                data-testid="salutions"
                variant="outlined"
                fullWidth
                value={this.state.selectedSolutation}
                style={webStyle.select}
                onChange={this.handleSolutationChange.bind(this)}
                defaultValue={0}>
                  <MenuItem value={0} disabled>
                    <em style={webStyle.countryPlaceHolder}>
                      Choose Solutation
                    </em>
                  </MenuItem>
                  {this.state.allSalutations.map((data: any, index: number) => {
                      return (
                        <MenuItem value={data.id} key={data.id}>
                            {data.name}
                        </MenuItem>
                      );
                  })}
                  {this.state.departmentList.length === 0 && (
                    <MenuItem value="noData" disabled>
                        No Data
                    </MenuItem>
                  )}
                </CustomSelect>
              </SelectField>
              <InputField id="firstName" testID="txtFirstName" variant="outlined" required placeholder="Enter First Name" value={this.state.firstName}
                onChange={this.handleChange("firstName").bind(this)}
                fullWidth
              />
            </div>
        </Grid>
          <Grid item md={6} sm={6} xs={12} style={{...webStyle.grids, paddingBottom: '5px'}}>
            <InputLabel htmlFor="lastName" style={webStyle.inputLabel}>
              {this.state.lastnameLabel}<span style={{ color: "red" }}>*</span>
            </InputLabel>
            <InputField id="lastName" data-testID="txtLastName" variant="outlined" value={this.state.lastName} onChange={this.handleChange("lastName").bind(this)} required
              placeholder="Enter Last Name"
              fullWidth
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
            <InputLabel htmlFor="firstName" style={webStyle.inputLabel}>
              {this.state.companynameLabel}<span style={{ color: "red" }}>*</span>
            </InputLabel>
            <InputField id="firstName" data-testID="txtCompanyName" variant="outlined" value={this.state.companyName} onChange={this.handleChange("companyName").bind(this)} required disabled
              placeholder="Enter Company Name"
              fullWidth
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids}>
            <InputLabel htmlFor="emailId" style={webStyle.inputLabel}>
              {this.state.emailLabel}<span style={{ color: "red" }}>*</span>
            </InputLabel>
            <InputField id="lastName" data-testID="txtEmail" variant="outlined" value={this.state.emailId} disabled onChange={this.handleChange("emailId").bind(this)}
              required
              placeholder="Enter Email Id"
              fullWidth
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={{padding: '0px 28px', paddingBottom: '15px'}}>
            <InputLabel htmlFor="phoneNo" style={webStyle.inputLabel}>
              {this.state.countryCodeLabel}
            </InputLabel>
            <Box display="flex">
              <CustomGrid item md={12} lg={12} sm={12} xs={12} className={this.state.isLoading === true ? 'countryCodeIndex' : 'countryCodeIndex2'} style={{ marginTop: "5px" }}>
                <CountryCodeSelector navigation={""} style={{}} id="countryCode" disable={false}
                  allowPropChange={true}
                  onChange={this.handlePhoneCountryCode}
                  value={this.state.phoneCountryCode}
                  placeHolder="Please select country"
                />
              </CustomGrid>
            </Box>
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={{padding: '0px 28px', paddingBottom: '15px'}}>
            <InputLabel htmlFor="phoneNo" style={webStyle.inputLabel}>
              {this.state.phoneNumLabel}
            </InputLabel>
            <InputField id="phoneNo" data-testID="txtPhoneNo" variant="outlined" onChange={this.handleChange("phoneNumber").bind(this)} value={this.state.phoneNumber}
              placeholder="Enter Phone No"
              fullWidth
              inputMode="numeric"
              inputProps={{
                maxLength: 14,
              }}
              type="tel"
            />
          </Grid>
        </Grid>

        <Grid container style={{ flexWrap: 'nowrap', padding: '16px 0px 5px 5px' }}>
          <CheckBoxLabel
            control={
              <Checkbox color="default" data-testID="cbTermsCondition" disableRipple checked={this.state.isTermsCondition} onChange={this.handleTermsConditionClick} checkedIcon={<span style={webStyle.checkedIcon} />}
                icon={<span style={webStyle.icon} />}
                inputProps={{ "aria-label": "decorative checkbox" }}
              />
            }
            label={""}
          />
          <Typography style={webStyle.termsText} component={"span"}>
            {this.state.privacyPolicyLabel}
            <Typography onClick={handlePrivacyPolicyRedirection} component="span" style={webStyle.privacyText}
            >
              {" "}
              {this.state.privacyPolicyText}
            </Typography>{" "}
            and
            <Typography onClick={handleTermsOfUseRedirection} component="span" style={webStyle.privacyText}
            >
              {" "}
              {this.state.termslabelText}
            </Typography>
            <Typography component="span"> {this.state.termsLabel}</Typography>
          </Typography>
        </Grid>
        <Grid container style={{ paddingTop: '8px', paddingLeft: '5px' }}>
          <SaveButton type="submit" id="btnSavePersonalDetails" data-testid="btnSavePersonalDetails" variant="contained">
            Save
          </SaveButton>
        </Grid>
      </>
    );
  }

  additinalDetails() {
    return (
      <>
        <Grid container spacing={7}>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids2}>
            <InputLabel htmlFor="address" style={webStyle.inputLabel}>
              {this.state.addressLabel}
            </InputLabel>
            <InputField id="address" data-testID="txtAddress" variant="outlined" value={this.state.address} onChange={this.handleChange("address").bind(this)} placeholder="Enter Address"
              fullWidth
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids2}>
            <InputLabel htmlFor="department" style={webStyle.inputLabel}>
              {this.state.departmentLabel}
            </InputLabel>
            <Select labelId="department" data-testID="txtDepartment" displayEmpty id="department" variant="outlined" fullWidth
              value={this.state.department}
              style={webStyle.select}
              onChange={this.handleDepartmentChange.bind(this)}
              defaultValue={0}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                getContentAnchorEl: null
              }}
            >
              <MenuItem value={this.state.department}>
                <em style={webStyle.countryPlaceHolder}>
                  Select Your Department
                </em>
              </MenuItem>
              {this.state.departmentList?.map((data, index) => {
                return (
                  <MenuItem value={data.id} key={index}>
                    {data.name}
                  </MenuItem>
                );
              })}
              {this.state.departmentList?.length === 0 && (
                <MenuItem value="noData" disabled>
                  No Data
                </MenuItem>
              )}
            </Select>
          </Grid>
        </Grid>

        <Grid container spacing={7}>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids2}>
            <InputLabel htmlFor="designation" style={webStyle.inputLabel}>
              {this.state.designationLabel}
            </InputLabel>
            <InputField id="designation" data-testID="txtdesignation" variant="outlined" value={this.state.designation} onChange={this.handleChange("designation").bind(this)} placeholder="Enter Your Designation"
              fullWidth
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids2}>
            <InputLabel htmlFor="landlineNo" style={webStyle.inputLabel}>
              {this.state.landlinelabel}
            </InputLabel>
            <InputField id="landlineNo" testID="txtLandlineNo" variant="outlined" value={this.state.landlineNumber} onChange={this.handleChange("landlineNumber").bind(this)} placeholder="Enter Landline No"
              fullWidth
              inputMode="numeric"
              inputProps={{
                maxLength: 14,
              }}
              type="tel"
            />
          </Grid>
        </Grid>

        <Grid container spacing={7}>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids2}>
            <InputLabel htmlFor="businessarea" style={webStyle.inputLabel}>
              {this.state.businessareaLabel}
            </InputLabel>
            <InputField id="businessarea" data-testID="txtBusinessArea" variant="outlined" value={this.state.areaOfBusiness} onChange={this.handleChange("areaOfBusiness").bind(this)} placeholder="Enter Your Area of Business"
              fullWidth
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids2}>
            <InputLabel htmlFor="affiliate" style={webStyle.inputLabel}>
              {this.state.affilateNameLabel}
            </InputLabel>
            <InputField id="affiliate" data-testID="txtAffiliate" variant="outlined" value={this.state.subsidiaryName} onChange={this.handleChange("subsidiaryName").bind(this)} placeholder="Enter Affiliates/Subsidiary Name"
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={7}>
          <Grid item md={6} sm={6} xs={12} style={webStyle.grids2}>
            <InputLabel htmlFor="" style={webStyle.inputLabel}>
              {this.state.alternateEmailLabel}
            </InputLabel>
            <InputField id="alternateEmail" data-testID="txtAlternateEmail" variant="outlined" value={this.state.alternateEmail} onChange={this.handleChange("alternateEmail").bind(this)} placeholder="Enter Alternate Email"
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ padding: '14px 0px 0px 0px' }}>
          <Grid item>
            <LogOutButton onClick={logout} data-testID="btnLogout" variant="outlined">Log Out</LogOutButton>
          </Grid>
          <Grid item>
            <SaveButton type="submit" id="btnSaveAdditionalDetails" variant="contained">
              Save
            </SaveButton>
          </Grid>
        </Grid>
      </>
    );
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <AppHeader navigation={this.props.navigation} />
        <MainContainer>
        <Grid container justifyContent="space-between">
                        <KeyboardBackspaceIcon
                            onClick={() => this.props.navigation.goBack()}
                            style={webStyle.backIcon}
                        />
                                <SaveButton variant="contained" style={webStyle.resetPasswordBtn} onClick={()=>this.sendResetPasswordLink()}>{this.state.resetPasswordBtnText}</SaveButton>
                    </Grid>
          <Typography style={webStyle.detailTitle}>
            {this.state.userDetailLabel}
          </Typography>
         
          <CustomTabs onChange={this.handleChangeTab} value={this.state.selectedTab} aria-label="Tabs where each tab needs to be selected manually"
          >
            <CustomTab label="Personal Details" style={webStyle.Tab1} disableRipple
            />
            <CustomTab label="Additional Details" style={webStyle.tab3} disableRipple
            />
          </CustomTabs>
          <form
            onSubmit={(e:any) => {
              e.preventDefault();
              if (this.state.selectedTab === 0) {
                this.onPersonalDetailsSave();
              } else {
                this.onAdditionalDetailsSave();
              }
            }}
            data-testid="tabChangeForm"
          >
            <TabPanel value={this.state.selectedTab} index={0}>
              {this.perSonalDetails()}
            </TabPanel>

            <TabPanel value={this.state.selectedTab} index={1}>
              {this.additinalDetails()}
            </TabPanel>
          </form>
        </MainContainer>
        <AppFooter isLoading={this.state.isLoading} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  backIcon: {
    paddingLeft: '20px',
    cursor: 'pointer'
},
  Tab1: {
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
  },
  tab3: {
    borderEndEndRadius: "8px",
    borderTopRightRadius: "8px",
  },
  detailTitle: {
    color: "rgba(33, 43, 54, 1)",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    fontSize: "18px",
    paddingBottom: "10px",
    marginLeft: "24px",
  },
  inputLabel: {
    fontSize: "15px",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    color: 'rgba(4, 60, 116, 1)',
    paddingBottom: "5px",
    paddingTop: "3px",
  },
  termsText: {
    fontStyle: "normal",
    color: 'rgba(106, 116, 165, 1)',
    fontFamily: "SFProDisplay",
    fontWeight: 400,
    fontSize: "14px",
    paddingTop: "4px",
    cursor: 'hand',
  },
  resetPasswordBtn: {
    width: 'auto',
    fontSize: '16px',
    height: '35px',
},
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  checkedIcon: {
    borderRadius: 3,
    border: "1px solid #153E78",
    width: 15,
    height: 15,
    backgroundColor: "white",
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='rgba(58, 63, 99, 1)'/%3E%3C/svg%3E\")",
    "input:hover ~ &": {},
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 3,
    width: 15,
    height: 15,
    backgroundColor: "white",
    border: "1px solid #153E78",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto #153E78",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "white",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  countryPlaceHolder: {
    fontFamily: "SFProDisplay",
    fontSize: "14px",
    color: "rgba(218, 224, 241, 1)",
    fontStyle: "normal"
  },
  select: {
    height: "35px",
    marginTop: "6px",
    borderRadius: "8px",
  },
  privacyText: {
    color: "rgba(69, 158, 255, 1)",
  },
  grids: {
    paddingTop: '10px',
    paddingBottom: '15px'
  },
  grids2: {
    paddingTop: '20px',
    paddingBottom: '20px'
  }
};

const CustomTab:any = withStyles({
  root: {
    border: "1px solid rgba(4, 60, 116, 1)",
    width: "100%",
    color: "rgba(4, 60, 116, 1)",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    fontSize: "14px",
    textTransform: "none",
    height: 35,
    "& .PrivateTabIndicator-colorSecondary-4": {
      display: "none",
    },
    "@media (max-width: 670px)": {
      width: "50%",
      fontSize: "14px"
    },
  },
})(Tab);

const CustomTabs:any = withStyles({
  root: {
    maxHeight: "40px",
    marginLeft: "24px",
    "& .MuiTab-root": {
      minHeight: '35px',
      maxWidth: '220px',
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .Mui-selected": {
      background: "rgba(225, 239, 255, 1)",
    },
    "@media (max-width: 470px)": {
      maxHeight: "80px",
    },
    "@media (max-width: 600px)": {
      marginLeft: "0px",
    },
  },
})(Tabs);

const InputField:any = withStyles({
  root: {
    background: "inherite",
    margin: "5px 0px 5px 0px",
    "& ::placeholder": {
      color: 'rgba(218, 224, 241, 1)',
      fontFamily: 'SFProDisplay',
      fontWeight: 400,
      fontSize: '14px',
      opacity: 1,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(214, 214, 214, 1)",
    },
    "& .MuiInputBase-input": {
      height: '15px',
    },
    "& .MuiOutlinedInput-input": {
      padding: '11px 14px'
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: "SFProDisplay",
      fontWeight: 400,
      backgroundColor: "rgba(255, 255, 255, 1)",
      fontSize: "14px",
      "& fieldset": {
        height: '40px',
        borderColor: "rgba(214, 214, 214, 1)",
        borderRadius: "8px",
        borderWidth: 1,
      },
      ".Mui-focused": {
        borderColor: "red",
      },
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px !important",
    },
    "& .MuiInputBase-input.Mui-disabled": {
      background: "#F1F4FA",
      borderRadius: "8px",
      height: 13,
      color: 'rgba(106, 116, 165, 1)',
      fontSize: '14px',
      fontFamily: 'SFProDisplay',
      fontWeight: 400,
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(218, 224, 241, 1)",
      borderWidth: '1px'
    }
  },
})(TextField);

const CheckBoxLabel:any = withStyles({
  root: {
    alignItems: "flex-start",
    marginRight: '0px',
    "& .MuiTypography-body1": {
      fontFamily: "SFProDisplay",
      fontWeight: 400,
      fontSize: "18px",
      color: "rgba(58, 63, 99, 1)",
    },
  },
})(FormControlLabel);

const LogOutButton:any = withStyles({
  root: {
    border: "2px solid rgba(4, 60, 116, 1)",
    color: "rgba(4, 60, 116, 1)",
    height: "36px",
    width: 110,
    borderRadius: "8px",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    fontSize: "14px",
    marginTop: "5px",
    marginBottom: "5px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 1)",
      boxShadow: "none",
    },
    "@media (max-width: 600px)": {
      width: "100%",
      marginLeft: 0,
    },
  },
})(Button);

const SaveButton:any = withStyles({
  root: {
    color: "rgba(255, 255, 255, 1)",
    backgroundColor: "rgba(4, 60, 116, 1)",
    height: "36px",
    width: 110,
    borderRadius: "8px",
    fontSize: "16px",
    fontFamily: "SFProDisplay",
    fontWeight: 700,
    marginTop: "5px",
    marginBottom: "5px",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "rgba(4, 60, 116, 1)",
      boxShadow: "none",
    },
    "@media (max-width: 600px)": {
      width: "100%",
      marginLeft: 0,
    },
  },
})(Button);

const MainContainer:any = withStyles({
  root: {
    padding: "24px 40px 24px 48px",
    "& .MuiInputBase-root": {
      fontSize: '14px',
    },
    "& .MuiOutlinedInput-input": {
      padding: '11.5px 14px',
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#BABED8",
      borderWidth: 1,
    },
    "& .css-j7pb4i-control": {
      minHeight: `37px !important`,
    },
    "& .css-1hb7zxy-IndicatorsContainer": {
      height: '37px'
    },
    "@media (max-width: 600px)": {
      padding: "24px 24px 24px 24px",
      "& .MuiBox-root-8": {
        padding: "24px 0px 24px 0px",
      },
      "& ..MuiBox-root-30": {
        padding: "24px 0px 24px 0px",
      },
      "& .MuiTypography-body1": {
        marginLeft: "0px !important",
      },
    },
  },
})(Container);

const TabPanel:any = withStyles({
  container: {
    padding: '20px 24px 0px 24px',
    marginTop: '10px',
    "@media (max-width: 600px)": {
      padding: '24px 0px 0px 0px'
    }
  }
})(CustomTabPanel);

const SelectField: any = withStyles({
  root: {
      "& .MuiOutlinedInput-root": {
          fontFamily: "SFProDisplay",
          fontWeight: 500,
          backgroundColor: "rgba(255, 255, 255, 1)",
          fontSize: "14px",
          color: '#1F436D',
      },
      "& .MuiInputBase-input": {
          fontFamily: "SFProDisplay",
          fontWeight: 500,
          backgroundColor: "rgba(255, 255, 255, 1)",
          fontSize: "14px",
          color: '#1F436D',
      },
      width: '85%',
      "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "rgba(218, 224, 241, 1)",
          borderWidth: 1,
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "rgba(218, 224, 241, 1)",
          borderWidth: 1,
      },
      "& .MuiSelect-icon": {
          fill: "rgba(4, 60, 116, 1)",
      },
      "& .MuiOutlinedInput-input": {
          padding: '9px 14px'
      },
  }
})(FormControl);

const CustomSelect: any = withStyles({
  root: {
      "&.MuiOutlinedInput-input": {
          padding: '13px 14px',
      },
      "&.MuiInputBase-input.Mui-disabled": {
          background: "#F1F4FA",
          borderRadius: "8px",
          height: 13,
          color: 'rgba(106, 116, 165, 1)',
          fontSize: '16px',
          fontFamily: 'SFProDisplay',
          fontWeight: 400,
      },
      "& .MuiInputBase-input.Mui-disabled .MuiOutlinedInput-notchedOutline": {
          borderColor: "rgba(218, 224, 241, 1)",
          borderWidth: '1px'
      }
  }
})(Select);

const styles = () => ({
  emailInput: {
    "@media (max-width: 600px)": {
      paddingTop: "0px !important"
    }
  }
})

export default withStyles(styles)(CustomerUserProfileUpdate)
// Customizable Area End
