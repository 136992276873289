Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.errorInvalidTokenBiospace = "The user tried to login is not a Biocon employee.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.loginHeader = "Login to Account";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";
exports.labelTitle = "Login to Account";
exports.emailTitle = "Email address";
exports.emailPlaceholder = "example@gmail.com";
exports.passwordTitle = "Password";
exports.passwordPlaceholder = "Please enter password";
exports.loginSubHeading= "Please enter your email and password to continue";
exports.forgotPasslabel = "Forgot Password?";
exports.AccountText = "Don't have an account?";
exports.signUpText = "Sign up";
exports.passwordError="Password must be at least 8 characters,with 1 uppercase, 1 lowercase, and 1 special character"
exports.welcomeText = "Welcome to BioConnect";
exports.startedText = "Let’s get started!";
exports.serviseText = "Your single window to all our API products, services & capabilities.";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.sendTokenAPiEndPoint = "account_block/user_login_by_token";
exports.loginImagesAPIEndPoint = "bx_block_landingpage3/landing_pages/auth_banner";
exports.EmployeeHeaderOptionPermissionAPIEndPoint = "/bx_block_landingpage3/landing_pages/employee_menu";
exports.emailLabelText = "Email address";
exports.loginButtonText = "Login";
// Customizable Area End
