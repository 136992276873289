import React from "react";

// Customizable Area Start
import {
    Container,
    Box,
    Typography,
    Card,
    Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Icon1, Icon2, Icon3, Icon4, Icon5, Icon6, Icon7, Icon8, Icon9, Icon10, Icon13, Icon12 } from "./assets";
import AppFooter from "../../../components/src/AppFooter.web";
import { Markup } from 'interweave';
// Customizable Area End

import APIViewController, {
    Props,
} from "./ApiController.web";
import { AppHeader } from "../../../components/src/AppHeader.web";
import Loader from "../../../components/src/Loader.web";
import EmployeeAppHeader from "../../../components/src/EmployeeAppHeader.web";
import { isEmployee } from "../../../components/src/common";

export class APIView extends APIViewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { classes } = this.props;
        return (
            // Customizable Area Start
            <>
                {isEmployee() ? <EmployeeAppHeader navigation={this.props.navigation} /> : <AppHeader navigation={this.props.navigation} />}
                <Loader loading={this.state.loading} data-testid="loader"/>
                <Container style={webStyle.noPadding}>
                    <Grid container data-testid="container">
                        {this.state.apiData && this.state.apiData?.attributes.banner_file_attached === this.state.mediaConditionText ?
                            <>
                                <video
                                    data-testid="video"
                                    id={`mainvideo`}
                                    autoPlay={true}
                                    muted={true}
                                    style={webStyle.poster1}
                                    className="bannerVideo"
                                >
                                    <source src={this.state.apiData.attributes.banner} type="video/mp4" className="bannerVideo" />
                                </video> </> :
                            <img data-testid="image" src={this.state.apiData?.attributes.banner} className={`left-side-imgages ${classes.poster1}`} />}

                    </Grid>
                    <MainContainer>
                        <CustomBox
                            display={"flex"}
                            flexDirection="column"
                            alignItems={"center"}
                            justifyContent={"center"}>
                            <Typography style={webStyle.apiHeading}
                                align="center"
                                className={classes.apiHeadingRes}
                            >
                                {this.state.apiData?.attributes?.overview_heading}
                            </Typography>
                            <CustomBox style={webStyle.titleBox} data-testid="titleBox"></CustomBox>
                        </CustomBox>
                        <Typography
                            style={webStyle.overviewText}
                            className={[classes.overviewTextAlignment, "overviewText"].join(' ')}
                        >
                            <Markup className={classes.overviewTextAlignment} content={this.state.apiData?.attributes.overview} />
                        </Typography>
                    </MainContainer>
                    <Grid container>
                        {this.state.apiData && this.state.apiData?.attributes.overview_file_attached === this.state.mediaConditionText ?
                            <video
                                id={`video`}
                                autoPlay={true}
                                muted={true}
                                style={webStyle.poster2}
                            >
                                <source src={this.state.apiData.attributes.overview_file} type="video/mp4" className="bannerVideo" />
                            </video> :
                            <img src={this.state.apiData?.attributes.overview_file} className={`left-side-imgages ${classes.poster2}`} />}
                    </Grid>
                    <SecondContainer>
                        <Typography
                            style={webStyle.apiHeading}
                            align="center"
                            className={classes.apiHeadingServingRes}
                        >
                            {this.state.apiData?.attributes?.path_heading}
                        </Typography>
                        <Typography
                            style={webStyle.serviceText}
                            className="serviceText"
                        >
                            <Markup className={classes.overviewTextAlignment} content={this.state.apiData?.attributes.description} />
                        </Typography>
                    </SecondContainer>
                    <StattagyBox>
                        <CustomBox
                            display={"flex"}
                            flexDirection="column"
                            alignItems={"center"}
                            justifyContent={"center"}>
                            <Typography
                                style={webStyle.apiHeading}
                                align="center"
                                className={classes.apiHeadingRes}
                            >
                                {this.state.apiData?.attributes?.api_heading}
                            </Typography>
                            <CustomBox style={webStyle.titleBox}></CustomBox>
                        </CustomBox>
                        <Typography
                            style={webStyle.overviewText}
                            align={"center"}
                            className={["overviewText", classes.apiStrategy].join(' ')}
                        >
                            <Markup className={classes.overviewTextAlignment} content={this.state.apiData?.attributes.api_strategy} />
                        </Typography>
                        <Grid style={webStyle.apiHeadingStyle}>
                            <Typography
                                style={webStyle.apiHeading}
                                align="center"
                                className={classes.apiHeadingRes}
                            >
                                {this.state.apiData?.attributes?.guiding_principle_heading}
                            </Typography>
                        </Grid>
                        <Grid container spacing={4} className={classes.ourGuidelineContainer}>
                            <Grid item lg={4} md={4} sm={4} xs={12}>
                                <PrinciPleCard style={webStyle.CardBackColor}>
                                    <img src={Icon1} style={webStyle.cardIcon} />
                                    <Typography style={webStyle.cardText}>
                                        {this.state.apiData?.attributes.guiding_principle_block_one}
                                    </Typography>
                                </PrinciPleCard>
                            </Grid>

                            <Grid item lg={4} md={4} sm={4} xs={12}>
                                <PrinciPleCard style={webStyle.cardBackColor2}>
                                    <img src={Icon2} style={webStyle.cardIcon} />
                                    <Typography style={webStyle.cardText}>
                                        {this.state.apiData?.attributes.guiding_principle_block_two}
                                    </Typography>
                                </PrinciPleCard>
                            </Grid>

                            <Grid item lg={4} md={4} sm={4} xs={12}>
                                <PrinciPleCard style={webStyle.cardBackColor3}>
                                    <img src={Icon3} style={webStyle.cardIcon} />
                                    <Typography style={webStyle.cardText}>
                                        {this.state.apiData?.attributes.guiding_principle_block_three}
                                    </Typography>
                                </PrinciPleCard>
                            </Grid>
                        </Grid>
                        <Typography
                            style={webStyle.lifecycleText}
                            align="center"
                            className={["lifecycleText", classes.lifecycle].join(' ')}
                        >
                            <Markup className={classes.overviewTextAlignment} content={this.state.apiData?.attributes.guiding_principle} />
                        </Typography>
                        <Grid style={webStyle.businessModelHeading}>
                            <Typography
                                style={webStyle.apiHeading}
                                align="center"
                                className={classes.apiHeadingRes}
                            >
                                {this.state.apiData?.attributes?.business_model_heading}
                            </Typography>
                            <Grid container spacing={4} className={classes.businessModalContainer}>
                                <Grid item lg={4} md={4} sm={4} xs={12}>
                                    <PrinciPleCard style={webStyle.businessModalBack}>
                                        <img src={Icon4} style={webStyle.cardIcon} />
                                        <Typography style={webStyle.cardText}>
                                            {this.state.apiData?.attributes.business_model_block_one}
                                        </Typography>
                                    </PrinciPleCard>
                                </Grid>

                                <Grid item lg={4} md={4} sm={4} xs={12}>
                                    <PrinciPleCard style={webStyle.modalBlock2Back}>
                                        <img src={Icon5} style={webStyle.cardIcon} />
                                        <Typography style={webStyle.cardText}>
                                            {this.state.apiData?.attributes.business_model_block_two}
                                        </Typography>
                                    </PrinciPleCard>
                                </Grid>

                                <Grid item lg={4} md={4} sm={4} xs={12}>
                                    <PrinciPleCard style={webStyle.modalBloack3Back}>
                                        <img src={Icon6} style={webStyle.cardIcon} />
                                        <Typography style={webStyle.cardText}>
                                            {this.state.apiData?.attributes.business_model_block_three}
                                        </Typography>
                                    </PrinciPleCard>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid className={classes.expertiseContainer} >
                            <Typography
                                style={webStyle.subTexts}
                                className="subText"
                            >
                                <Markup content={this.state.apiData?.attributes.business_model} />
                            </Typography>
                        </Grid>

                        <Grid className={classes.apiPortfolioContainer}>
                            <Typography
                                style={webStyle.apiHeading}
                                align="center"
                                className={classes.apiHeadingRes}
                            >
                                {this.state.apiData?.attributes?.api_portfolio_heading}

                            </Typography>
                            <CardBox container spacing={6} className={classes.apiportfolioRes}>
                                {
                                    (this.state.apiData?.attributes?.api_portfolio_block_one !== this.state.condition1 &&
                                        this.state.apiData?.attributes?.api_portfolio_block_one !== "") && (
                                        <Grid item lg={4} md={4} sm={6} xs={12} key={0}>
                                            <PrinciPleCard
                                                style={webStyle.EvenCard}>
                                                <img src={Icon7} style={webStyle.portfolioIcon} />
                                                <Typography style={webStyle.cardHeading} className="princileCard">
                                                    <Markup content={this.state.apiData?.attributes.api_portfolio_block_one} />
                                                </Typography>
                                            </PrinciPleCard>
                                        </Grid>
                                    )
                                }
                                {
                                    (this.state.apiData?.attributes?.api_portfolio_block_two !== this.state.condition1 &&
                                        this.state.apiData?.attributes?.api_portfolio_block_two !== "") && (
                                        <Grid item lg={4} md={4} sm={6} xs={12} key={1}>
                                            <PrinciPleCard style={webStyle.OddCard}>
                                                <img src={Icon8} style={webStyle.portfolioIcon} />
                                                <Typography style={webStyle.cardHeading} className="princileCard">
                                                    <Markup content={this.state.apiData?.attributes.api_portfolio_block_two} />
                                                </Typography>
                                            </PrinciPleCard>
                                        </Grid>
                                    )
                                }
                                {
                                    (this.state.apiData?.attributes?.api_portfolio_block_three !== this.state.condition1 &&
                                        this.state.apiData?.attributes?.api_portfolio_block_three !== "") && (
                                        <Grid item lg={4} md={4} sm={6} xs={12} key={2}>
                                            <PrinciPleCard style={webStyle.EvenCard}>
                                                <img src={Icon9} style={webStyle.portfolioIcon} />
                                                <Typography style={webStyle.cardHeading} className="princileCard">
                                                    <Markup content={this.state.apiData?.attributes.api_portfolio_block_three} />
                                                </Typography>
                                            </PrinciPleCard>
                                        </Grid>
                                    )
                                }
                                {
                                    (this.state.apiData?.attributes?.api_portfolio_block_four !== this.state.condition1 &&
                                        this.state.apiData?.attributes?.api_portfolio_block_four !== "") && (
                                        <Grid item lg={4} md={4} sm={6} xs={12} key={3}>
                                            <PrinciPleCard style={webStyle.OddCard}>
                                                <img src={Icon10} style={webStyle.portfolioIcon} />
                                                <Typography style={webStyle.cardHeading} className="princileCard">
                                                    <Markup content={this.state.apiData?.attributes.api_portfolio_block_four} />
                                                </Typography>
                                            </PrinciPleCard>
                                        </Grid>
                                    )
                                }

                                {
                                    (this.state.apiData?.attributes?.api_portfolio_block_five !== this.state.condition1 &&
                                        this.state.apiData?.attributes?.api_portfolio_block_five !== "") && (
                                        <Grid item lg={4} md={4} sm={6} xs={12} key={4}>
                                            <PrinciPleCard style={webStyle.EvenCard}>
                                                <img src={Icon13} style={webStyle.portfolioIcon} />
                                                <Typography style={webStyle.cardHeading} className="princileCard">
                                                    <Markup content={this.state.apiData?.attributes.api_portfolio_block_five} />
                                                </Typography>
                                            </PrinciPleCard>
                                        </Grid>
                                    )
                                }
                                {
                                    (this.state.apiData?.attributes?.api_portfolio_block_six !== this.state.condition1 &&
                                        this.state.apiData?.attributes?.api_portfolio_block_six !== "") && (
                                        <Grid item lg={4} md={4} sm={6} xs={12} key={5}>
                                            <PrinciPleCard style={webStyle.OddCard}>
                                                <img src={Icon12} style={webStyle.portfolioIcon} />
                                                <Typography style={webStyle.cardHeading} className="princileCard">
                                                    <Markup content={this.state.apiData?.attributes.api_portfolio_block_six} />
                                                </Typography>
                                            </PrinciPleCard>
                                        </Grid>
                                    )
                                }
                                {
                                    (this.state.apiData?.attributes?.api_portfolio_block_seven !== this.state.condition1 &&
                                        this.state.apiData?.attributes?.api_portfolio_block_seven !== "") && (
                                        <Grid item lg={4} md={4} sm={6} xs={12} key={4}>
                                            <PrinciPleCard style={webStyle.EvenCard}>
                                                <img src={Icon8} style={webStyle.portfolioIcon} />
                                                <Typography style={webStyle.portfolioBlock7} className="princileCard">
                                                    <Markup content={this.state.apiData?.attributes.api_portfolio_block_seven} />
                                                </Typography>
                                            </PrinciPleCard>
                                        </Grid>
                                    )
                                }
                            </CardBox>
                            <Typography
                                style={webStyle.bottomText}
                                className="bottomText"
                            >
                                <Markup content={this.state.apiData?.attributes.enquiry} />
                            </Typography>
                        </Grid>
                    </StattagyBox>
                </Container>
                <AppFooter isLoading={false} />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    portfolioBlock7: {
        color: '#fff',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '18px',
        margin: '0px 16px'
    },
    modalBloack3Back: {
        background: '#8AA40F'
    },
    modalBlock2Back: {
        background: '#EC6B1A'
    },
    businessModalBack: {
        background: '#6566A7'
    },
    businessModelHeading: {
        padding: '0px 51px'
    },
    cardBackColor3: {
        background: '#6669B0'
    },
    cardBackColor2: {
        background: '#4196D7'
    },
    CardBackColor: {
        background: '#8AA40D'
    },
    apiHeadingStyle: {
        marginTop: '15px'
    },
    noPadding: {
        padding: '0px'
    },
    imageFit: {
        objectFit: 'cover'
    },
    poster1: {
        width: '100%',
    },
    poster2: {
        height: '665px',
        width: '100%'
    },
    apiHeading: {
        color: '#003e76',
        fontFamily: 'DMSans-Bold',
        fontSize: '20px',
        margin: `50px 0px 16px`,
    },
    titleBox: {
        borderBottom: '3px solid #459eff',
        marginBottom: '20px',
        width: '50px',
        borderRadius: '2.5px'
    },
    overviewText: {
        color: '#003e76',
        fontFamily: 'DMSans-Bold',
        fontSize: '20px',
    },
    serviceText: {
        color: '#262626',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '20px',
        margin: '24px 40px 0px 40px',
    },
    cardIcon: {
        height: '60px',
        width: '64px',
    },
    cardText: {
        color: 'rgba(255, 255, 255, 1)',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '18px',
        margin: '16px 13px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
    },
    lifecycleText: {
        color: 'rgba(0, 0, 0, 0.85)',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '20px',
        margin: ' 84px 49px 64px 49px'
    },
    expertiseText: {
        color: '#043c74',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '20px',
        margin: '72px 25px 26px 25px',
    },
    subTexts: {
        color: 'rgba(0, 0, 0, 0.85)',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '18px',
        margin: '85px 25px 24px 25px',

    },
    EvenCard: {
        background: '#2584C8',
        color: 'rgba(255, 255, 255, 1)',
        marginTop: '10px',
        marginLeft: '-12px',
        marginRight: '-12px',
        marginBottom: '-30px',
    },
    OddCard: {
        background: '#EDECEA',
        color: '#043c74',
        marginTop: '10px',
        marginLeft: '-12px',
        marginRight: '-12px',
        marginBottom: '-30px',
    },
    cardHeading: {
        color: 'inherite',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '18px',
        margin: '0px 16px'
    },
    cardMenus: {
        color: 'inherite',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '16px',
        margin: '18px 16px 8px 16px',
    },
    portfolioIcon: {
        width: '60px',
        height: '60px',
        margin: `0px 21px 16px 16px`,
    },
    bottomText: {
        color: 'rgba(0, 0, 0, 0.85)',
        fontFamily: 'SFProDisplay',
        fontWeight: 400,
        fontSize: '16px',
        margin: '60px 49px 110px 50px',
    }
};

const MainContainer: any = withStyles({
    root: {
        padding: '0px 60px 24px 60px',
        "@media (max-width: 600px)": {
            padding: '0px 20px 24px 20px',
        }
    }
})(Grid)

const StattagyBox: any = withStyles({
    root: {
    }
})(Grid)

const SecondContainer: any = withStyles({
    root: {
    }
})(Grid)

const PrinciPleCard: any = withStyles({
    root: {
        padding: '15px 30px',
        boxShadow: 'none',
        borderRadius: '8px',
        height: '100%',
        "@media (max-width: 600px)": {
            height: 'auto',
        },
    }
})(Card);

const CardBox : any = withStyles({
    root: {
        padding: '0px 49px',
    }
})(Grid);

const CustomBox: any = withStyles({
    root: {
    }
})(Box);

const styles = () => ({
    apiHeadingRes: {
        "@media (max-width: 600px)": {
            marginTop: '30px !important',
        }
    },
    apiHeadingServingRes: {
        "@media (max-width: 600px)": {
            marginTop: '30px !important',
            padding: '0px 15px',
        }
    },
    apiportfolioRes: {
        "@media only screen and (min-width: 600px) and (max-width: 967px)": {
            padding: '0px !important',
        }
    },
    poster1: {
        width: '100%',
        height: '73vh',
        objectPosition: 'center',
        "@media (max-width: 600px)": {
            height: '45vh',
        }
    },
    poster2: {
        height: '350px',
        width: '100%',
        objectPosition: 'center',
        "@media (max-width: 600px)": {
            height: '335px'
        },
    },
    overviewTextAlignment: {
        textAlign: 'center' as const,
        "& > p": {
            "@media (max-width: 600px)": {
                padding: '0px 15px',
                margin: '0px -15px',
                display: 'inline-block',
                textAlign: 'left !important' as const,
                '& span': {
                    fontSize: '20px !important'
                },
            },
        }
    },
    apiStrategy: {
        "@media (max-width: 600px)": {
            paddingLeft: '20px',
            paddingRight: '20px'
        },
    },
    ourGuidelineContainer: {
        padding: '0px 100px',
        "@media (max-width: 600px)": {
            padding: '0px 50px'
        },
    },
    businessModalContainer: {
        padding: '10px 49px',
        "@media (max-width: 600px)": {
            padding: '10px 0px'
        },
    },
    lifecycle: {
        "@media (max-width: 600px)": {
            margin: '84px 0px 64px 0px',
            padding: '0px 0px'
        },
    },
    expertiseContainer: {
        margin: '0px 75px',
        "@media (max-width: 600px)": {
            margin: '0px 20px'
        },
    },
    apiPortfolioContainer: {
        margin: '10px 51px',
        "@media (max-width: 600px)": {
            margin: '10px 0px'
        },
    }
})
export default withStyles(styles)(APIView)
// Customizable Area End