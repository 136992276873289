Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AdminConsole3";
exports.labelBodyText = "AdminConsole3 Body";
exports.getTicketsAPIEndPoint = "bx_block_catalogue/tickets";
exports.getOverdueTicketsAPIEndPoint = "bx_block_catalogue/tickets/overdue_tickets";
exports.overdueTicketSearchAPIEndPoint = "bx_block_catalogue/tickets/overdue_ticket_search";
exports.overdueTicketSearchAPIMethod = "POST";
exports.ticketSearchApiEndPoint = "bx_block_catalogue/tickets/search";
exports.customerTicketsAPIPoint = "account_block/accounts/user_ticket";

exports.btnExampleTitle = "CLICK ME";
exports.getCustomerMasterTicketsApiCallId = "account_block/customer_masters/";
exports.getCustomerMasterTicketsApiMethod = "GET";
exports.getCustomerMasterDownloadApiEndpoint = "account_block/customer_masters/download_customer_master";
exports.getCustomerMasterDownloadApiMethod = "GET";
exports.getContactMasterTicketsApiEndpoint = "account_block/customer_masters/all_user_detail/?";
exports.getContactMasterTicketsApiMethod = "GET";
exports.getContactMasterDownloadApiEndpoint = "account_block/customer_masters/download_contact_master";
exports.getContactMasterDownloadApiMethod = "GET";
exports.ticketCountAPIEndPoint = "bx_block_catalogue/tickets/assign_ticket_count";
exports.getTrasnferredTicketListingApiEndpoint = "bx_block_catalogue/tickets/all_transferred_tickets";
exports.getTrasnferredTicketListingApiMethod = "GET";
exports.downloadAssignedToMeTicketsApiEndpoint = "bx_block_catalogue/tickets/download_ticket_list";
exports.downloadAssignedToMeTicketsApiMethod = "GET";
exports.downloadTransferredTicketsApiEndPoint = "bx_block_catalogue/tickets/download_transferred_ticket_list";
exports.downloadTransferredTicketsApiMethod = "GET";
exports.getTransferredTicketsSearchEndpoint = "bx_block_catalogue/tickets/transferred_ticket_search";
exports.getTransferredTicketsSearchApiMethod = "GET";
exports.downloadOverdueTicketsApiEndpoint = "bx_block_catalogue/tickets/download_overdue_ticket_list";
exports.downloadOverdueTicketsApiMethod = "GET";
exports.getAllCompaniesApiEndpoint = "account_block/accounts/company_listing";
exports.getCustomerContactApiEndpoint = "bx_block_catalogue/tickets/company_user?"
exports.queryTypeApiEndPoint = "bx_block_catalogue/tickets/query_type";
exports.createEmpSideTicketApiEndPoint = "bx_block_catalogue/tickets/ticket_create";
exports.downloadText = "Download";
exports.createNewTicketText = "Create New Ticket";
exports.customerNameLabel = "Customer Name";
exports.customerNamePlaceholder = "Select Customer Name";
exports.NoDataText = "No Data";
exports.selectQueryTypePlaceholder = "Please Select Query Type";
exports.cancelBtnText = "Cancel";
exports.ProceedBtnText = "Proceed";
exports.noProductsFountText = "No Records Found";
exports.QueryTypePlaceholder = "Query Type";
exports.selectCustomerContactPlaceholder = "Please Select Customer Contact Name";
exports.selectContactPlaceholder = "Select Contact Name";
exports.selectContactNameLabel = "Contact Name";
exports.companyNameplaceholder = "Please Select Company Name";
exports.ViewDetailsBtnText = "View Details";
exports.daysText = "days";
exports.searchPlaceholder = "Search by Contact Name";
exports.searchProductPlaceholder = "Search by Product name";
exports.searchTicketIDPlaceholder = "Search by Ticket ID";
exports.resolveTicketsText = "Resolve Overdue Tickets";
exports.transferredTicketsText = "Transferred Tickets";
exports.ticketsAssignedText = "Tickets assigned to me";
exports.tabsName = "Tabs where each tab needs to be selected manually";
exports.saveBtnText = "Save";
// Customizable Area End