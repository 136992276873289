import { getHeaders, getUserdetails,isLoggedIn, apiCall, isGuest, handleUpdateAuthToken, isAuthorisedUser, IUserdetails, warningNotification } from "../../../components/src/common";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  firstName: string;
  lastName: string;
  companyName: string;
  emailId: string;
  phoneNumber: string;
  comments: string;
  phoneCountryCode: string;
  privacyPolicyLabel: string;
  privacyPolicyText: string;
  termslabelText: string;
  termsLabel: string;
  isTermsAndCondition: boolean;
  isGuest: boolean;
  country: string;
  productsList: [];
  selectedProducts: any;
  selectedProductNames: any;
  requestSuccess: boolean;
  loading: boolean;
  otherProduct: string;
  selectedProductsError: any;
  phoneNumberError: any;
  termsConditionErrorConnect: any,
  isRobot: any;
  isRobotErrorMsg: any,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}


export default class ConnectWithUsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  authToken: string;
  userDetails: IUserdetails;
  getCustomerProfileApiCallId: string;
  createConnectWithUsApiCallId: string;
  getCategoriesApiCallId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handlePhoneCountryCode = this.handlePhoneCountryCode.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      firstName: "",
      lastName: "",
      companyName: "",
      emailId: "",
      phoneNumber: "",
      comments: "",
      phoneCountryCode: "91",
      privacyPolicyLabel: configJSON.privacyPolicyLabel,
      privacyPolicyText: configJSON.privacyPolicyText,
      termslabelText: configJSON.termslabelText,
      termsLabel: configJSON.termsLabel,
      isTermsAndCondition: false,
      isGuest: false,
      country: "India",
      productsList: [],
      requestSuccess: false,
      loading: false,
      selectedProducts: [],
      selectedProductNames: [],
      otherProduct: '',
      selectedProductsError: '',
      phoneNumberError: '',
      termsConditionErrorConnect: '',
      isRobot: false,
      isRobotErrorMsg: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      // console.error(errorReponse)
      if (apiRequestCallId && responseJson) {
        if (!isGuest()) {
          isAuthorisedUser(responseJson, this.props.navigation);
        }
        this.setState({
          loading: false
        })
        if (apiRequestCallId === this.getCustomerProfileApiCallId) {

          if (!responseJson.errors) {
            const profileData = responseJson.data.attributes;
            this.setState({
              ...this.state,
              firstName: profileData.first_name,
              lastName: profileData.last_name,
              companyName: profileData.company_name,
              emailId: profileData.email,
              phoneCountryCode: profileData.country_code ? String(profileData.country_code) : '91',
              phoneNumber: profileData.phone_number === null ? "" : profileData.phone_number,
            })
          }
          else {
            if (responseJson.errors && responseJson.errors[0]?.token === "Token has Expired" && JSON.parse(localStorage.getItem("userInfo") || "")?.attributes?.is_customer === 'True') {
              this.setState({
                loading: true
              })
              await handleUpdateAuthToken()
              const authToken = isLoggedIn(this.props.navigation);
              if (typeof (authToken) === "string") {
                this.authToken = authToken;
              } 
              if (this.authToken){
                this.getProfileDetails();
              }
            }
          }
        }

        if (apiRequestCallId === this.createConnectWithUsApiCallId) {
          if (responseJson.data) {
            this.setState({
              comments: "",
              requestSuccess: true,
            });
          }     else {
            if (responseJson.errors && responseJson.errors[0]?.token === "Token has Expired" && JSON.parse(localStorage.getItem("userInfo") || "")?.attributes?.is_customer === 'True') {
              this.setState({
                loading: true
              })
              await handleUpdateAuthToken()
              const authToken = isLoggedIn(this.props.navigation);
              if (typeof (authToken) === "string") {
                this.authToken = authToken;
              } 
              if (this.authToken){
                this.saveConnectWithUs();
              }
            }
          }
        }

        if (apiRequestCallId === this.getCategoriesApiCallId) {
          if (!responseJson.errors) {
            const products = responseJson.products.map((product: any) => {
              return {
                id: product[0],
                value: product[1]
              }
            });
            products.push({
              id: -1,
              value: 'Other'
            })
            this.setState({
              productsList: products
            })
          }
        }
      }

    }
    // Customizable Area End

  }

  async componentDidMount() {
    this.getCategoriesList()
    if (!isGuest()) {
      const authToken = isLoggedIn(this.props.navigation);
      if (typeof (authToken) === "string") {
        this.authToken = authToken;
        const userDetailToken = getUserdetails();
        if (userDetailToken) {
          this.userDetails = userDetailToken;
          this.getProfileDetails();
        }
      }
    } else {
      this.setState({
        isGuest: true
      })
    }

  }

  handlePhoneCountryCode(event: any, value: any, dataSource: any) {

    const filtered = dataSource.find((country: any) => country.id === event.value)

    if (value) {
      this.setState({
        phoneCountryCode: event.value,
        country: filtered.value
      });
    }
  }

  getProfileDetails = () => {
    const header = getHeaders({ token: this.authToken });
    const getAccount = apiCall({
      httpBody: {},
      header: header,
      url:
        configJSON.getCustomerProfileApiEndpoint +
        this.userDetails.attributes.customer_profile_id,
      httpMethod: configJSON.getCustomerProfileApiMethod,
    });

    this.getCustomerProfileApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
    this.setState({
      loading: true
    })
  };

  handleMeetingChange = (prop: any) => (event: any) => {
    if (prop === 'firstName' || prop === 'lastName') {
      var regEx = /^[a-zA-Z][a-zA-Z\s]*$/;
      if (event.target.value.length === 0) {
        this.setState({ ...this.state, [prop]: event.target.value });
        return true;
      }
      if (event.target.value.match(regEx)) {
        this.setState({ ...this.state, [prop]: event.target.value });
        return true;
      }
      else {
        return false;
      }
    } else if (prop === 'phoneNumber') {
      var regEx2 = /^[0-9\b]+$/;
      if (event.target.value.length === 0) {
        this.setState({ ...this.state, [prop]: event.target.value });
        return true;
      }
      if (event.target.value.match(regEx2)) {
        this.setState({ ...this.state, [prop]: event.target.value });
        return true;
      } else {
        return false;
      }
    } else {
      this.setState({ ...this.state, [prop]: event.target.value });
    }

  }

  isStringNullOrBlank(str: string) {
    return str === null || str.trim().length === 0;
  }

  saveConnectWithUs = () => {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.emailId)
      // this.isStringNullOrBlank(String(this.state.phoneNumber))
    ) {
      warningNotification("All fields required");
      return false;
    }

    if (!this.state.emailId.toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )) {
      warningNotification(configJSON.emailError);
      return false;
    }

    // if((this.state.phoneNumber.length < 4 || this.state.phoneNumber.length > 14) || this.state.selectedProducts.length === 0){
    //   this.setState({phoneNumberError : configJSON.phoneError,selectedProductsError :configJSON.productError})
    //   return false;
    // }
    if ((!this.state.isRobot || this.state.phoneNumber.length < 4 || this.state.phoneNumber.length > 14) || this.state.selectedProducts?.length === 0 || !this.state.isTermsAndCondition) {
      this.setState({ isRobotErrorMsg: configJSON.isRobotErrorMsg , phoneNumberError: configJSON.phoneError, selectedProductsError: configJSON.productError, termsConditionErrorConnect: configJSON.termsConditionError })
      return false;
    }


    if (this.state.selectedProductNames.includes("Other") && this.state.otherProduct.trim().length === 0) {
      warningNotification(configJSON.otherProductsError);
      return false;
    }


    if (!this.state.isTermsAndCondition) {
      warningNotification(configJSON.termsConditionError);
      return
    }
    const flag = this.userDetails !== undefined && this.userDetails !== null ? true : false;
    const httpBody = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      company_name: this.state.companyName,
      email: this.state.emailId,
      country_code: this.state.phoneCountryCode,
      phone_number: this.state.phoneNumber,
      country: this.state.country,
      comments: this.state.comments,
      connect_products_attributes: this.state.selectedProducts.filter((id: any) => id !== -1).map((id: any) => {
        return { catalogue_id: id }
      }),
      other_products_attributes: this.state.selectedProductNames.includes("Other") ? this.state.selectedProductNames.map((name: any) => {
        return { name: name }
      }) : [],
      ...(flag && { account_id: this.userDetails.id }),
    };

    const connectWithUs = apiCall({
      url: configJSON.createConnectWithUsApiEndpoint,
      httpBody: httpBody,
      httpMethod: configJSON.createConnectWithUsApiMethod,
      header: getHeaders({ token: this.authToken })
    });

    this.createConnectWithUsApiCallId = connectWithUs.messageId;
    runEngine.sendMessage(connectWithUs.id, connectWithUs);
    this.setState({
      loading: true
    })
  }

  handleTermsConditionClick = (e: any) => {
    this.setState({
      isTermsAndCondition: !this.state.isTermsAndCondition,
    })
  };

  getCategoriesList = () => {

    const categoryApi = apiCall({
      header: getHeaders({}),
      url: configJSON.getCategoriesApiEndPoint,
      httpMethod: configJSON.getCategoriesApiType,
      httpBody: {}
    });

    this.getCategoriesApiCallId = categoryApi.messageId;
    runEngine.sendMessage(categoryApi.id, categoryApi);
  }

  closeSuccess = () => {
    this.setState({ requestSuccess: !this.state.requestSuccess });
    if (this.props.navigation) {
      this.props.navigation.navigate("Home")
    }
  };

  handleProductChange = (event: React.ChangeEvent<{ value: any }>) => {
    const selected = [...event.target.value]
    this.setState({
      selectedProductNames: selected.map((id: any) => {
        const findProduct: any = this.state.productsList.find((product: any) => product.id == id);
        return findProduct.value;
      }),
      selectedProducts: event.target.value
    })
  }

  handleIsRobotClick = (e: any) => {
    this.setState({
      isRobot: !this.state.isRobot,
    })
  };

}