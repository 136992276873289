import React from "react";
import { Button, Box } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import IconButton from '@material-ui/core/IconButton';
import { Typography } from "@material-ui/core";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  padding: {
    paddingTop: "0px",
    paddingBottom: "0px"
  },
  sortResponsive: {
    "@media (max-width: 440px)": {
      alignSelf: "flex-end !important",
    },
  },
}));

export default function SortMenuPopup(props: any) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();
  return (
    <>
      <Box style={webStyle.sortContainer} className={classes.sortResponsive}>
        <Box
          aria-controls={open ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          display="flex"
          style={{ alignItems: "center" }}
        >

          <Typography style={webStyle.sortTitle}>Sort </Typography>
          {
            props.Ascending ?
              (
                <IconButton style={{ padding: '0px' }}>
                  <ArrowUpwardIcon style={webStyle.sortArrow1}></ArrowUpwardIcon>
                  <ArrowDownwardIcon style={webStyle.sortArrow}></ArrowDownwardIcon>
                </IconButton>
              ) :
              (
                <IconButton>
                  <ArrowDownwardIcon style={webStyle.sortArrow1}></ArrowDownwardIcon>
                  <ArrowUpwardIcon style={webStyle.sortArrow}></ArrowUpwardIcon>
                </IconButton>
              )
          }
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            classes: { padding: classes.padding },
          }}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MenuItem onClick={() => { props.sortAscending(); handleClose() }} style={props.Ascending ? webStyle.selected : webStyle.unSelected}>Sort by name A-Z</MenuItem>
          <MenuItem onClick={() => { props.sortDescending(); handleClose() }} style={props.Ascending ? webStyle.unSelected : webStyle.selected}>Sort by name Z-A</MenuItem>
        </Menu>
      </Box>
    </>
  );
}

const webStyle = {
  sortContainer: {
    display: "flex",
    // justifyContent: "end",
    alignSelf: "center",
    // margin: '45px 0px 20px',
    // marginLeft: '10px',
  },
  sortTitle: {
    // marginTop: "11px",
    margin: '0px 8px',
    fontSize: "18px",
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    color: 'rgba(106, 116, 165, 1)'
  },
  sortArrow1: {
    fontSize: "30px",
    width: "20px",
    height: '35px',
    // marginTop: '-9px',
    // marginLeft: '3px',
    fill: 'rgba(106, 116, 165, 1)',
    padding: "0",

  },
  sortArrow: {
    fontSize: "30px",
    width: "20px",
    // marginTop: '-9px',
    // marginLeft: '-4px',
    fill: 'rgba(106, 116, 165, 1)',
    padding: "0",
  },
  selected: {
    background: 'rgba(218, 224, 241, 1)',
    color: 'rgba(4, 60, 116, 1)',
    fontSize: '14px',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    height: '35px',
  },
  unSelected: {
    fontSize: '14px',
    fontFamily: 'SFProDisplay',
    fontWeight: 400,
    height: '35px',
  }
};

