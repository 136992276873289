import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import { errorNotification } from '../../../components/src/common'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  emailSchema: any;
  showSuccessPopUp: any;
  showPasswordResetPopUp: any;
  sendEmailOk: any;
  sendEmailOk2: any;
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  phoneValue: any;
  showPassword: any;
  confirmShowPassword: any;
  token: any;
  enablePasswordField: boolean;
  btnConfirmPasswordShowHide: boolean;
  resetPassword: any;
  loginImage: any;
  imageLoading: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
const passwordInvisibleImage = require("../assets/ic_password_invisible.png");
const passwordVisibleImage = require("../assets/ic_password_visible.png");
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;
  requestEmailOtpCallId: any;
  requestPhoneOtpCallId: any;
  requestChangePasswordCallId: any;
  requestGoToConfirmationCallId: any;
  otpToken: any;
  isChangePassword: boolean;

  //Properties from config
  LoginImagesApiCallId: string = "";
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
  firstInputAutoCompleteType: any = configJSON.firstInputAutoCompleteType;
  buttonColorForNextButton: any = configJSON.buttonColorForNextButton;
  secondInputAutoCompleteType: any = configJSON.secondInputAutoCompleteType;
  secondInputKeyboardType: any = configJSON.secondInputKeyboardType;
  secondInputPlaceholder: string = configJSON.secondInputPlaceholder;
  secondInputErrorColor: any = configJSON.secondInputErrorColor;
  thirdInputPlaceholder: string = configJSON.thirdInputPlaceholder;
  thirdInputErrorColor: any = configJSON.thirdInputErrorColor;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  forthLabelText: string = configJSON.forthLabelText;
  fifthLabelText: string = configJSON.fifthLabelText;
  sixthLabelText: string = configJSON.sixthLabelText;
  buttonTitleIsSMSPhoneAccount: string =
    configJSON.buttonTitleIsSMSPhoneAccount;
  buttonTitleIsEmailAccount: string = configJSON.buttonTitleIsEmailAccount;
  labelTextIsPleaseEnterYourNewPassword: string =
    configJSON.labelTextIsPleaseEnterYourNewPassword;
  labelTextIsYourPasswordHasBeenSuccessfullyChanged: string =
    configJSON.labelTextIsYourPasswordHasBeenSuccessfullyChanged;
  placeholderIsPassword: string = configJSON.placeholderIsPassword;
  firstInputKeyboardStyle: any = configJSON.firstInputKeyboardStyle;
  firstInputPlaceholder: string = configJSON.firstInputPlaceholder;
  firstInputErrorColor: any = configJSON.firstInputErrorColor;
  buttonTextIsNext: string = configJSON.buttonTextIsNext;
  passwordVisibleImage: any = passwordVisibleImage;
  passwordInvisibleImage: any = passwordInvisibleImage;
  placeholderIsReTypePassword: string = configJSON.placeholderIsReTypePassword;
  buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
  buttonColorForOkButton: any = configJSON.buttonColorForOkButton;
  countryCodeSelectorPlaceholder: string =
    configJSON.countryCodeSelectorPlaceholder;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    //email schema
    let emailSchema = {
      aggrement: Yup.boolean().required("this check box require").oneOf([true], 'This field must be checked'),
      email: Yup.string().email(configJSON.pleaseEnterAValidEmail).required(configJSON.emailIsRequired),
    };

    this.state = {
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      emailValue: "",
      phoneValue: "",
      showPassword: false,
      confirmShowPassword: false,
      passwordRules: "",
      emailSchema: emailSchema,
      showSuccessPopUp: false,
      showPasswordResetPopUp: false,
      sendEmailOk: false,
      sendEmailOk2: false,
      token: "",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      resetPassword: "",
      loginImage: "",
      imageLoading: false,
    };
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    this.validationRulesRequest();
    this.getLoginImages();
  }

  validationRulesRequest = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.validationAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileValidationSettingsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    //Customizable Area Start
    if (message?.properties?.RestAPIResponceSuccessMessage?.message) {
      this.setSuccessResponse()
    }
    // This email id is not registered with us,please check	
    if (message?.properties?.RestAPIResponceSuccessMessage?.errors) {
      this.setErrorResponse(message)
    }

    // showPasswordResetPopUp
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      this.setPasswordResetOTP(message);
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id && this.validationAPICallId &&
      this.validationAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.setValidationResponse(message);
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id && this.requestEmailOtpCallId !== null && this.requestEmailOtpCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.setEmailOtpResponse(responseJson);
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id && this.requestGoToConfirmationCallId !== null && this.requestGoToConfirmationCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.setConfirmationData(message);
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.LoginImagesApiCallId && this.LoginImagesApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.setLoginImages(responseJson)
    }
    //Customizable Area End
  }

  //Customizable Area Start
  setEmailOtpResponse = (responseJson: any) => {
    if (responseJson !== undefined && responseJson.meta && responseJson.meta.token) {
      this.setOtpAuthToken(responseJson)
    } else if (responseJson && responseJson.errors) {
      //error handling
      errorNotification(responseJson.errors[0].message);
    }
  }
  setSuccessResponse = () => {
    this.setState({ showSuccessPopUp: true });
    this.setState({ showPasswordResetPopUp: true });
    this.setState({ sendEmailOk: true })
  }

  setConfirmationData = (message: any) => {
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson !== undefined && responseJson.data) {
      this.setState({ accountStatus: "Confirmation" });
    } else if (responseJson !== undefined && responseJson.errors) {
      errorNotification(responseJson.errors[0].message);
      this.props.navigation.navigate("ForgotPassword");
    } else {
      let errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  setOtpAuthToken = (responseJson: any) => {
    this.otpToken = responseJson.meta.token;
    this.setState({ token: this.otpToken });
    //navigate to OTP page
    const msg: Message = new Message(getName(MessageEnum.NavigationMobilePhoneOTPMessage));
    msg.addData(getName(MessageEnum.AuthTokenDataMessage), this.state.token);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.AuthTokenEmailMessage), this.state.emailValue);
    msg.addData(getName(MessageEnum.EnterOTPAsForgotPasswordMessage), true);
    this.send(msg);
  }

  setValidationResponse = (message: any) => {
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson === undefined) {
      return;
    }
    if (responseJson && responseJson.data[0]) {
      const emailRegex = RegExp(responseJson.data[0].email_validation_regexp);
      this.setState({
        //email schema
        emailSchema: {
          email: Yup.string()
            .email(configJSON.pleaseEnterAValidEmail)
            .required(configJSON.emailIsRequired)
            .matches(emailRegex, configJSON.invalidEmailAddress)
        },
      });
    }
  }

  setPasswordResetOTP = (message: any) => {
    const otpAuthTkn = message.getData(getName(MessageEnum.AuthTokenDataMessage));
    if (otpAuthTkn && otpAuthTkn.length > 0) {
      this.setState({ token: otpAuthTkn });
      if (this.isChangePassword) {
        this.setState({ accountStatus: "ChangePassword" });
      }
      this.otpToken = this.state.token;
    } else {
      const accountType = message.getData(getName(MessageEnum.NavigationForgotPasswordPageInfo));
      if (accountType) {
        this.startForgotPassword(accountType);
      }
    }
  }

  setErrorResponse = (message: any) => {
    if (message?.properties?.RestAPIResponceSuccessMessage?.errors[0]?.message.includes("is not registered")) {
      this.setState({ showSuccessPopUp: false });
      this.setState({ showPasswordResetPopUp: false });
    }
  }

  setLoginImages = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        loginImage: responseJson?.data?.attributes?.media
      })
      setTimeout(()=> {
        this.setState({imageLoading: false})
      }, 1000)
    } else {
      this.setState({imageLoading: false})
      errorNotification('No Image found');
    }
  }

  handleChange(e: any) {
    this.setState({ resetPassword: e.target.value })
  }
  handleConfirmShowPassword = () => {
    this.setState({ confirmShowPassword: !this.state.confirmShowPassword, });
  };

  returnBoolenValue = (condition: any) => {
    if (condition) {
      return true;
    } else {
      return false
    }
  }

  startForgotPassword(accountType: string) {
    this.setState({ accountStatus: accountType === "sms" ? "EnterPhone" : "EnterEmail" });
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword, });
  };

  goToOtpAfterEmailValidation(values: { accountType: string; email: string }) {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    this.setState({
      emailValue: values.email ? values.email : ""
    });
    const data = {
      type: values.accountType ? values.accountType : "email_account",
      attributes: { email: values.email ? values.email : "" }
    };
    const httpBody = { data: data };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.requestEmailOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToConfirmationAfterPasswordChange(values: { password: any; confirmPassword: any; }) {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const tokenVal = this.props.navigation.getParam('token')
    const data = {
      attributes: {
        token: tokenVal,
        new_password: values.password,
        confirm_password: values.password,
      }
    };
    const httpBody = {
      data: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.requestGoToConfirmationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryChangePasswordAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    if (values.password === values.confirmPassword) {
      setTimeout(() => {
        this.setState({ sendEmailOk2: true })
      }, 1000);
    }
  }

  getLoginImages() {
    this.setState({imageLoading: true})
    const headers = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.LoginImagesApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginImagesAPIEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  goToChangePasswordAfterOtp(values: { otpCode: string }) {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.requestChangePasswordCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryConfirmOtpAPiEndPoint
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    const data = {
      token: this.otpToken ? this.otpToken : "",
      otp_code: values.otpCode ? values.otpCode : ""
    };
    const httpBody = { data: data };
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  //Customizable Area End
}
